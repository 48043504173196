import { $shop } from '$stores/$shop';
import { $currentPlanName } from '$stores/willy/$subscription';
import { useComputedValue, useStoreValue } from '@tw/snipestate';
import { Flex, Icon, Image, Text } from '@tw/ui-components';
import { getDashPermsManager } from 'components/Willy/dashboardManagment/permissions-management/DashboardPermissionsManager';
import { useAppSelector } from 'reducers/RootType';

export function ShopDetails() {
  const shopName = useComputedValue($shop, (shop) => shop.shopName);
  const shopIcon = useAppSelector((state) => state.shopIcon);
  const shopPlan = useStoreValue($currentPlanName);
  const { count, loading } = useComputedValue(getDashPermsManager().$shopUsers, (u) => ({
    count: u.data?.length,
    loading: u.pending,
  }));

  return (
    <Flex gap="sm" align="center" pb="md">
      <Image src={shopIcon} w={50} h={50} />
      <Flex direction="column">
        <Text size="lg" color="gray.9" weight={500}>
          {shopName}
        </Text>
        <div className="flex items-center gap-3">
          <Text size="sm" color="gray.6">
            {shopPlan}
          </Text>
          {(!loading || typeof count !== 'undefined') && (
            <>
              <Icon name="ellipse-1" size={4} />
              <Text size="sm" color="gray.6">
                {count} Members
              </Text>
            </>
          )}
        </div>
      </Flex>
    </Flex>
  );
}
