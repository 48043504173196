import { subscriptionPlan } from '@tw/types/module/services/subscription-manager';
import { Alert, Button, Flex, Icon, Modal, Text } from '@tw/ui-components';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { AddonsMetaData } from './AddonsMetaData';
import { addAddonToSubscription } from '../ChangePlan/ChangePlan';
import { useStoreValue } from '@tw/snipestate';
import { $shopSubscription } from '$stores/willy/$subscription';
import { SubscriptionDetails } from '../ChangePlan/SubscriptionDetails';
import { ContractTypeAndBillingInterval } from '../ChangePlan/BillingIntervalSwitcher';
import { useNavigate } from 'react-router-dom';

type AddonOrderModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onPurchaseComplete?: (purchaseSuccess: boolean) => void;
  addon: subscriptionPlan;
  contractTypes: ContractTypeAndBillingInterval[];
  selectedType: ContractTypeAndBillingInterval;
  setSelectedType?: (selectedType: ContractTypeAndBillingInterval) => void;
  isManageAddon?: boolean;
};

export const AddonOrderModal: React.FC<AddonOrderModalProps> = ({
  isOpen,
  onClose,
  onPurchaseComplete,
  addon,
  contractTypes,
  selectedType,
  setSelectedType,
  isManageAddon,
}) => {
  const navigate = useNavigate();
  const subscription = useStoreValue($shopSubscription);
  const [quantity, setQuantity] = useState(
    isManageAddon ? addon.upgradeData?.current_quantity || 1 : 1,
  );
  const addonMetaData = useMemo(() => AddonsMetaData[addon.product_id], [addon.product_id]);
  const [newPromotionCodeId, setNewPromotionCodeId] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(false);

  const currentQuantity = useMemo(
    () => addon.upgradeData?.current_quantity,
    [addon.upgradeData?.current_quantity],
  );

  const onConfirm = useCallback(async () => {
    if (!subscription) return;

    setLoading(true);
    try {
      const newQuantity = isManageAddon ? quantity : (currentQuantity || 0) + quantity;
      await addAddonToSubscription(subscription, addon, newQuantity, newPromotionCodeId);
      setLoading(false);
      if (addonMetaData?.logUpgradeEvent) {
        addonMetaData.logUpgradeEvent(newQuantity);
      }
      if (addonMetaData?.postPurchaseLink) {
        navigate(addonMetaData.postPurchaseLink);
      }
      if (onPurchaseComplete) {
        onPurchaseComplete(true);
      }

      onClose();
    } catch (error) {
      console.error('Failed to add addon to subscription:', error);
      if (onPurchaseComplete) {
        onPurchaseComplete(false);
      }
      setLoading(false);
    }
  }, [
    isManageAddon,
    quantity,
    currentQuantity,
    subscription,
    addon,
    newPromotionCodeId,
    onPurchaseComplete,
    onClose,
    navigate,
    addonMetaData,
  ]);

  useEffect(() => {
    if (currentQuantity && isManageAddon && isOpen) {
      setQuantity(currentQuantity);
    } else {
      setQuantity(1);
    }
  }, [currentQuantity, isManageAddon, isOpen]);

  useEffect(() => {
    if (!isOpen) {
      setQuantity(1);
    }
  }, [isOpen]);

  const bannerMessage = useMemo(() => {
    if (addon.upgradeData?.current_plan && !!currentQuantity && !isManageAddon) {
      return `You currently have ${currentQuantity} seats in your plan. By adding 
        ${quantity} more seat${quantity > 1 ? 's' : ''} you will have a total of 
        ${currentQuantity + quantity} seats available for your team.`;
    } else if (isManageAddon && currentQuantity) {
      let message = `You currently have ${currentQuantity} seats.`;
      if (quantity !== currentQuantity) message += ` Your new number of seats will be ${quantity}.`;
      if (quantity > currentQuantity)
        return (
          message +
          ` Upon adding  Moby seats, you will be charged the pro-rated amount for your additional product(s).
          Your billing cycle will otherwise remain the same.`
        );
      return message;
    }
  }, [addon.upgradeData?.current_plan, currentQuantity, isManageAddon, quantity]);

  return (
    <Modal opened={isOpen} onClose={onClose} padding={0} size="lg" withCloseButton={false}>
      <div className="p-[20px]">
        <SubscriptionDetails
          addons={[{ addon, quantity }]}
          setAddons={(addons) => {
            const newQuantity = addons.find((a) => a.addon === addon)?.quantity;
            if (newQuantity != undefined) setQuantity(newQuantity);
          }}
          newPromotionCodeId={newPromotionCodeId}
          setNewPromotionCodeId={setNewPromotionCodeId}
          contractTypes={contractTypes}
          selectedType={selectedType}
          setSelectedType={setSelectedType}
          banner={
            bannerMessage ? (
              <Alert color="one.3" icon={<Icon name="info" color="one.4" />}>
                <Text color="one.5" weight={500} size="sm" fw={500}>
                  {bannerMessage}
                </Text>
              </Alert>
            ) : undefined
          }
          isManageAddon={isManageAddon}
        />
      </div>
      <Flex justify="flex-end" gap="lg" bg="gray.1" p="lg">
        <Button onClick={onClose} variant="activator">
          Cancel
        </Button>
        <Button
          variant="primary"
          loading={loading}
          onClick={onConfirm}
          disabled={quantity < 1 || (isManageAddon && currentQuantity === quantity)}
        >
          Confirm
        </Button>
      </Flex>
    </Modal>
  );
};
