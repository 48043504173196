import { $shopWithSensory } from '$stores/$shopWithSensory';
import { $favoritePrompts } from '$stores/willy/$favoritePrompts';
import { useStoreValue } from '@tw/snipestate';
import { useCallback, useEffect, useState } from 'react';
import { useAppSelector } from 'reducers/RootType';
import { WillyPrompt } from './types/willyTypes';
import { getPaginatedPrompts } from './utils/willyUtils';
import { colors, NumRange } from '@tw/ui-components';
import useDebounce from 'utils/useDebounce';
import { $userId } from '$stores/$user';

export type PromptTopic = {
  topic: string;
  cats: string[];
  color: `${keyof typeof colors}.${NumRange<0, 10>}`;
  labelColor: `${keyof typeof colors}.${NumRange<0, 10>}`;
  badgeColor: `${keyof typeof colors}.${NumRange<0, 10>}`;
};

const staticTopics: PromptTopic[] = [
  {
    topic: 'Yours',
    cats: ['Saved', 'For You', 'From Reports'],
    color: 'one.5',
    labelColor: 'one.1',
    badgeColor: 'one.8',
  },
  {
    topic: 'Discover',
    cats: ['Popular', 'New'],
    color: 'orange.4',
    labelColor: 'orange.1',
    badgeColor: 'orange.6',
  },
];

const otherColorSets: {
  color: `${keyof typeof colors}.${NumRange<0, 10>}`;
  labelColor: `${keyof typeof colors}.${NumRange<0, 10>}`;
  badgeColor: `${keyof typeof colors}.${NumRange<0, 10>}`;
}[] = [
  { color: 'purple.5', labelColor: 'purple.2', badgeColor: 'purple.8' },
  { color: 'teal.5', labelColor: 'green.1', badgeColor: 'green.8' },
];

export const useSamplePrompts = () => {
  const currentShopId = useAppSelector((state) => state.currentShopId);
  const userId = useStoreValue($userId);
  const shopSensory = useStoreValue($shopWithSensory);
  const [activePromptCategory, setActivePromptCategory] = useState<string>('For You');
  const favoritePrompts = useStoreValue($favoritePrompts);
  const [rawPrompts, setRawPrompts] = useState<WillyPrompt[]>([]);
  const [formattedSamplePrompts, setFormattedSamplePrompts] = useState<WillyPrompt[]>([]);
  const [promptTopics, setPrompTopics] = useState<PromptTopic[]>([]);
  const [customizedPrompts, setCustomizedPrompts] = useState<WillyPrompt[]>([]);
  const [loadingPrompts, setLoadingPrompts] = useState(false);
  const [reportPrompts, setReportPrompts] = useState<WillyPrompt[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchPrompt, setSearchPrompt] = useState<string>('');
  const debouncedSearchTerm = useDebounce(searchPrompt, 1000);
  const [hasMore, setHasMore] = useState(false);
  const [totalItemCount, setTotalItemCount] = useState(0);

  const formattedFav = favoritePrompts.map((p) => {
    return { ...p, subCategory: 'Saved' };
  });

  useEffect(() => {
    const formattedPromptsList: WillyPrompt[] = rawPrompts?.map<WillyPrompt>((prompt) => {
      const isFav = false;
      return {
        ...prompt,
        isPopular: (prompt.isPopular as any) === 'TRUE' ? true : false,
        isFeatured: (prompt.isFeatured as any) === 'TRUE' ? true : false,
        category: isFav ? '' : prompt.category,
        subCategory: isFav ? 'Saved' : prompt.subCategory,
      };
    });
    setFormattedSamplePrompts(formattedPromptsList);
  }, [rawPrompts]);

  const getTopics = (topics: { topic: string; subCategories: string[] }[]) => {
    const staticTopicMap = new Map<string, PromptTopic>(
      staticTopics.map(({ topic, cats, color, labelColor, badgeColor }) => [
        topic,
        { topic, cats: [...new Set(cats)], color, labelColor, badgeColor }, // Convert Set to Array
      ]),
    );

    let colorToUse = 0;

    topics.forEach((topic) => {
      const cats = topic.subCategories;
      // If the topic exists in the static topics, merge the categories
      if (staticTopicMap.has(topic.topic)) {
        const existingTopic = staticTopicMap.get(topic.topic);
        if (existingTopic) {
          // Merge new categories, converting existing Set to Array first
          const mergedCats = new Set([...existingTopic.cats, ...cats]);
          existingTopic.cats = [...mergedCats]; // Convert back to Array
        }
      } else {
        const colorSet = otherColorSets[colorToUse];
        colorToUse = (colorToUse + 1) % otherColorSets.length;
        // If it doesn't exist, add it to the map with default colors
        staticTopicMap.set(topic.topic, {
          topic: topic.topic,
          cats: [...new Set(cats)],
          color: colorSet.color,
          labelColor: colorSet.labelColor,
          badgeColor: colorSet.badgeColor,
        });
      }
    });
    // Convert the map back to an array format, ensuring to convert Sets back to arrays
    const mergedTopics: PromptTopic[] = Array.from(
      staticTopicMap,
      ([topic, { cats, color, labelColor, badgeColor }]) => ({
        topic,
        cats, // Already an array
        color,
        labelColor,
        badgeColor,
      }),
    );
    setPrompTopics(mergedTopics);
  };

  const loadPrompts = useCallback(
    async (category: string, searchTerm: string, page) => {
      setLoadingPrompts(true);
      try {
        let { prompts, topics, pagination } = await getPaginatedPrompts(
          currentShopId,
          userId,
          category,
          searchTerm,
          page,
        );
        if (page === 0) {
          setRawPrompts(prompts);
        } else {
          setRawPrompts((prev) => [...prev, ...prompts]);
        }
        setHasMore(pagination.hasNextPage);
        setTotalItemCount(pagination.totalItems);
        if (promptTopics.length === 0) {
          getTopics(topics);
        }
      } catch (e) {
        console.error(e);
      }
      setLoadingPrompts(false);
    },
    [currentShopId, promptTopics, userId],
  );

  const loadNextPage = () => {
    setCurrentPage(currentPage + 1);
    loadPrompts(activePromptCategory, debouncedSearchTerm, currentPage + 1);
  };

  useEffect(() => {
    const fetchPrompts = async () => {
      await loadPrompts(activePromptCategory, debouncedSearchTerm, 0);
    };
    fetchPrompts();
    setCurrentPage(0);
  }, [activePromptCategory, loadPrompts, debouncedSearchTerm]);

  return {
    promptTopics,
    customizedPrompts,
    reportPrompts,
    currentPage,
    promptsToShow: formattedSamplePrompts,
    loadingPrompts,
    activePromptCategory,
    setActivePromptCategory,
    searchPrompt,
    setSearchPrompt,
    hasMore,
    loadNextPage,
    totalItemCount,
  };
};
