import {
  ActionIcon,
  Icon,
  IconName,
  Image,
  Popover,
  Skeleton,
  Tabs,
  Text,
} from '@tw/ui-components';
import {
  Message,
  RelatedResourceResponse,
  RelatedTemplateResponse,
  SimilarResourceDocument,
  SimilarTemplateDocument,
} from './types/willyTypes';
import { WillyPanelTabs } from './WillyPanelTabs';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  getColumnsFromMessage,
  getParametersFromMessage,
  getQueryDatesFromMessage,
  getQuestionFromMessage,
  getSqlFromMessage,
  getTableFromEveryQuery,
  getTablesFromMessage,
} from './utils/willyUtils';
import { useStoreValue } from '@tw/snipestate';
import { $tablesWithBeta } from '$stores/willy/$tables';
import allServices from 'constants/services';
import { formatText } from './utils/formatters';
import { WillySimpleText } from './WillySimpleText';
import axiosInstance from 'utils/axiosInstance';
import TWSlides from 'components/library/TWSlides/TWSlides';
import { SlideshowRef } from 'react-slideshow-image';
import { Link } from 'react-router-dom';
import { baseURL } from 'config';
import { analyticsEvents, chatActions, genericEventLogger } from 'utils/dataLayer';

export const tabsArr = ['details', 'sql', 'related'] as const;
export type PossibleTab = (typeof tabsArr)[number];

const MAX_METRICS = 1;

type MessageToolDetailsTabsProps = {
  message: Message;
  activeTab: PossibleTab | null;
};

export const MessageToolDetailsTabs: React.FC<MessageToolDetailsTabsProps> = ({
  message,
  activeTab,
}) => {
  const allTables = useStoreValue($tablesWithBeta);

  const [showMoreMetrics, setShowMoreMetrics] = useState(false);
  const [loadingRelated, setLoadingRelated] = useState(false);
  const [relatedTemplates, setRelatedTemplates] = useState<RelatedTemplateResponse>();
  const [relatedResources, setRelatedResources] = useState<RelatedResourceResponse>();

  const query = useMemo(() => {
    return getSqlFromMessage(message);
  }, [message]);

  const question = useMemo(() => {
    return getQuestionFromMessage(message);
  }, [message]);

  const tables = useMemo(() => {
    const t = getTablesFromMessage(message);

    return t;
  }, [message]);

  const allTablesFromQuery = useMemo(() => {
    return Object.fromEntries(allTables?.map((table) => [table.id, table]) || []);
  }, [allTables]);

  const allColumnsFromTables = useMemo(() => {
    const cols =
      tables?.flatMap((t) => {
        const a = allTablesFromQuery[t]?.columns;
        return a?.filter((x) => !!x);
      }) || null;

    return Object.fromEntries(cols?.filter((x) => !!x).map((col) => [col.id, col]) || []);
  }, [tables, allTablesFromQuery]);

  const columns = useMemo(() => {
    const cols = getColumnsFromMessage(message);
    return cols
      .map((column) => {
        const col = tables
          ?.flatMap((t) => allTablesFromQuery[t]?.columns)
          .find((c) => c?.id === column);

        return col?.id || column;
      })
      .filter((c) => !!c);
  }, [tables, message, allTablesFromQuery]);

  const dates = useMemo(() => {
    return getQueryDatesFromMessage(message);
  }, [message]);

  const channelParameter = useMemo(() => {
    const allParameters = getParametersFromMessage(message);

    const channelParam = allParameters?.find((p) => p.column === 'channel') || null;

    return {
      ...channelParam,
      value: Array.isArray(channelParam?.value)
        ? channelParam?.value
        : channelParam?.value
          ? [channelParam?.value]
          : [],
    };
  }, [message]);

  const attributionParameter = useMemo(() => {
    const allParameters = getParametersFromMessage(message);

    const attributionParam = allParameters?.find((p) => p.column === 'model') || null;

    return attributionParam;
  }, [message]);

  const firstParameter = useMemo(() => {
    const allParameters = getParametersFromMessage(message);

    return allParameters?.[0] || null;
  }, [message]);

  useEffect(() => {
    (async () => {
      if (!question) {
        return;
      }

      setLoadingRelated(true);
      const { data } = await axiosInstance.post<{
        templates: RelatedTemplateResponse;
        resources: RelatedResourceResponse;
      }>('/v2/willy/get-question-related-content', {
        question: question,
      });

      setRelatedTemplates(data.templates);
      setRelatedResources(data.resources);

      setLoadingRelated(false);
    })();
  }, [question]);

  if (!activeTab) {
    return null;
  }

  if (activeTab === 'details') {
    return (
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 grid-flow-row gap-4">
        {!!tables?.length && (
          <MessageToolDetailsBox>
            <Title>Data Source</Title>
            {tables?.map((table) => (
              <Value key={table}>
                <span className="break-words">{allTablesFromQuery[table]?.name || table}</span>
              </Value>
            ))}
          </MessageToolDetailsBox>
        )}
        {!!dates && (
          <MessageToolDetailsBox>
            <Title>Date Range</Title>
            <Value>{dates}</Value>
          </MessageToolDetailsBox>
        )}
        {!!channelParameter?.value?.length && (
          <MessageToolDetailsBox>
            <Title>Platform Filter</Title>
            {channelParameter.value.map((p) => (
              <div className="flex items-center gap-4" key={p}>
                <Icon name={allServices[p]?.smallIcon as IconName} />
                <Value>{allServices[p]?.name}</Value>
              </div>
            ))}
          </MessageToolDetailsBox>
        )}
        {columns?.length > 0 && (
          <MessageToolDetailsBox>
            <Title>Dimensions</Title>

            <div className="flex gap-1">
              {columns.slice(0, MAX_METRICS).map((column) => (
                <Value key={column}>
                  {allColumnsFromTables[column]?.title || formatText(column)}
                </Value>
              ))}
              {columns.length > MAX_METRICS && (
                <Popover opened={showMoreMetrics} onClose={() => setShowMoreMetrics(false)}>
                  <Popover.Target>
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        setShowMoreMetrics((x) => !x);
                      }}
                    >
                      <Text size="xs">- View {columns?.length - MAX_METRICS} more</Text>
                    </div>
                  </Popover.Target>
                  <Popover.Dropdown>
                    <div className="flex flex-col gap-1">
                      {columns.slice(MAX_METRICS).map((column) => (
                        <Value key={column}>
                          {allColumnsFromTables[column]?.title || formatText(column)}
                        </Value>
                      ))}
                    </div>
                  </Popover.Dropdown>
                </Popover>
              )}
            </div>
          </MessageToolDetailsBox>
        )}

        {attributionParameter && (
          <MessageToolDetailsBox>
            <Title>Attribution Model</Title>
            <Value>{attributionParameter?.value}</Value>
          </MessageToolDetailsBox>
        )}
      </div>
    );
  }

  if (activeTab === 'sql' && query) {
    return (
      <div className="overflow-x-auto">
        <WillySimpleText text={`\`\`\`sql\n${query}\n`} />
      </div>
    );
  }

  if (activeTab === 'related') {
    return (
      <div className="flex flex-col sm:flex-row gap-4 items-center">
        <div className="flex flex-col gap-10">
          <div className="flex flex-col gap-4">
            <Value>Related resources</Value>
            <div className="flex items-center gap-6.5">
              {relatedResources?.similar_documents?.map((doc) => (
                <div className="flex flex-col">
                  <ResourceBox key={doc.url} loading={loadingRelated} type="resource" item={doc} />
                </div>
              ))}
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <Value>Related Templates</Value>
            <div className="flex items-center gap-6.5">
              {relatedTemplates?.similar_documents?.map((template) => (
                <ResourceBox
                  key={template.id}
                  loading={loadingRelated}
                  type="template"
                  item={template}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
};

const Title = ({ children }) => {
  return (
    <Text size="xs" color="gray.5" tt="capitalize">
      {children}
    </Text>
  );
};

const Value = ({ children }) => {
  return (
    <Text size="xs" fw="bold">
      {children}
    </Text>
  );
};

const LinkTitle = ({ url, text, loading }) => {
  return (
    <Link
      to={{
        pathname: url,
        search: window.location.search,
      }}
      style={{
        textDecoration: 'none',
        whiteSpace: 'wrap',
        background: 'white',
      }}
      target="_blank"
    >
      <Text size="sm" fw="bold" p="sm">
        {loading && <Skeleton width="100%" height="10px" />}
        {!loading && <span className="line-clamp-1">{text}</span>}
      </Text>
    </Link>
  );
};

type ResourceBoxProps = {
  loading: boolean;
  type: 'template' | 'resource';
  item: SimilarResourceDocument | SimilarTemplateDocument;
};

const ResourceBox: React.FC<ResourceBoxProps> = ({ loading, type, item }) => {
  const questionSliderRef = useRef<SlideshowRef>(null);

  const loadingTemplate = useMemo(() => {
    return <Skeleton animate width="100%" height="100%" />;
  }, []);

  return (
    <div className="w-95 rounded overflow-hidden shadow-md">
      {type === 'resource' && (
        <div className="flex flex-col gap-2">
          <TWSlides
            properties={{
              // cssClass: 'w-full',
              infinite: true,
              arrows: true,
              canSwipe: true,
              ref: questionSliderRef,
              indicators: false,
              slidesToShow: 1,
              slidesToScroll: 1,
            }}
            items={(item as SimilarResourceDocument).mediaUrls.map((url, i) => {
              return {
                src: url,
                alt: '',
                id: i,
                type: 'ReactNode',
                Children: (
                  <div
                    style={{
                      background: `url(${url}) no-repeat center center/cover`,
                    }}
                    // className="object-contain object-center w-full h-52 max-h-52 flex flex-col"
                    className="block w-full h-0 pb-[60%] overflow-hidden"
                  >
                    {/* {loading && loadingTemplate} */}
                    {/* {!loading && <Image src={url} key={i} />} */}
                  </div>
                ),
              };
            })}
          />
          <LinkTitle
            text={(item as SimilarResourceDocument).header}
            url={(item as SimilarResourceDocument).url}
            loading={loading}
          />
        </div>
      )}
      {type === 'template' && (
        <div className="flex flex-col">
          <div
            className="block w-full h-0 pb-[60%] overflow-hidden"
            style={{
              background: `url(${baseURL}/v2/media/dashboard-image/${
                (item as SimilarTemplateDocument).image
              }) no-repeat center center/cover`,
            }}
          />
          <LinkTitle
            text={(item as SimilarTemplateDocument).name}
            url={`/templates/${(item as SimilarTemplateDocument).id}`}
            loading={loading}
          />
        </div>
      )}
    </div>
  );
};

type MessageToolDetailsBoxProps = {
  children: React.ReactNode;
};

export const MessageToolDetailsBox: React.FC<MessageToolDetailsBoxProps> = ({ children }) => {
  return (
    <div className="flex flex-col p-4 rounded border-gray-200 border border-solid">{children}</div>
  );
};

type MessageToolTabsProps = {
  selectedTab: PossibleTab | null;
  tabChanged: (selectedTab: PossibleTab | null) => void;
  message: Message;
};

export const MessageToolTabs: React.FC<MessageToolTabsProps> = ({
  selectedTab,
  tabChanged,
  message,
}) => {
  return (
    <div className="min-h-8 flex items-center gap-4">
      <WillyPanelTabs
        className="!p-0"
        tabs={[
          {
            value: 'details',
            label: 'Answer Details',
          },
          {
            value: 'sql',
            label: 'View SQL',
          },
          {
            value: 'related',
            label: 'Related',
          },
        ]}
        value={selectedTab!}
        onTabChange={(tab) => {
          if (tab === selectedTab) {
            tabChanged(null);
          } else {
            tabChanged(tab as PossibleTab);
            const derivedAction =
              tab === 'related'
                ? chatActions.EXPAND_RELATED_SECTION
                : tab === 'details'
                  ? chatActions.EXPAND_ANSWER_DETAILS
                  : chatActions.VIEW_SQL;

            genericEventLogger(analyticsEvents.CHAT, {
              action: derivedAction,
              ...message,
            });
          }
        }}
      />
      {/* <Tabs
        value={selectedTab}
        onChange={(value) => {
          if (!value) {
            return;
          }
          tabChanged(value as PossibleTab);
        }}
      >
        <Tabs.List className="border-0">
          <Tabs.Tab value="sources">Sources</Tabs.Tab>
          <Tabs.Tab value="details">Answer Details</Tabs.Tab>
          <Tabs.Tab value="sql">View SQL</Tabs.Tab>
        </Tabs.List>
      </Tabs> */}
    </div>
  );
};
