import { $debouncedEffect, $derived, $mutableDerived } from '@tw/snipestate';
import { $search } from '../$location';
import moment from 'moment-timezone';
import { getPrevDates } from '../../utils/getPreviousDate';
import { $currentShopId, $mspConnected, $shop, $timezone } from '../$shop';
import {
  mapFromPeriodIdToTimeUnit,
  PreviousPeriodIds,
} from '@tw/types/module/datePicker/datePicker';
import {
  getDatePickerOptionValueOptions,
  DatePickerTimePeriods,
} from '../../components/useDatePickerSelectedOptions';
import QueryString from 'qs';
import { $navigate } from '../$navigate';
import { isEqual } from 'lodash';
import { Moment } from '@tw/moment-cached';
import { useNavigate } from 'react-router';
import { Granularity } from '@tw/types';
import store from 'index';
import {
  changeDatesToCompare,
  mainDatePickerOnSelectionChange,
  mainDatePickerSpecialPeriodChange,
} from 'ducks/actions';
import { $user } from '$stores/$user';
import { buildDatePickerCompareOptions } from 'components/useDatePickerCompareOptions';
import { groupBtnPressed } from 'ducks/stats';

export type CurrentDateRange = {
  start: moment.Moment;
  end: moment.Moment;
};
export type PrevDateRange = { start: moment.Moment; end: moment.Moment };
export const PREV_IS_NONE = 'prev-is-none';

export const pushDateToQS = (
  navigate?: ReturnType<typeof useNavigate>,
  start?: Moment,
  end?: Moment,
  isCurrent = true,
  isPrevNone = false,
) => {
  const prevQuery = QueryString.parse(location.search, { ignoreQueryPrefix: true });
  const startKey = isCurrent ? 'start' : 'prev-start';
  const endKey = isCurrent ? 'end' : 'prev-end';
  const query = {
    ...prevQuery,
  };

  if (start && end) {
    query[startKey] = start.format('YYYY-MM-DD');
    query[endKey] = end.format('YYYY-MM-DD');
  } else {
    delete query[startKey];
    delete query[endKey];
  }

  if (isPrevNone) {
    query[PREV_IS_NONE] = 'true';
  } else {
    delete query[PREV_IS_NONE];
  }

  if (!isEqual(prevQuery, query)) {
    navigate?.(
      {
        pathname: location.pathname,
        search: QueryString.stringify({ 'shop-id': $currentShopId.get(), ...query }),
      },
      { replace: true },
    );
  }
};

export const pushFullDateToQS = (
  navigate: ReturnType<typeof useNavigate> | undefined,
  start: Moment,
  end: Moment,
  prevStart?: Moment,
  prevEnd?: Moment,
  isPrevNone = false,
) => {
  const prevQuery = QueryString.parse(location.search, { ignoreQueryPrefix: true });
  const query = {
    ...prevQuery,
  };

  // delete query[CURRENT_DATE_ID]; // Remove the idKey from the query object if removeId is true
  // delete query[PREV_DATE_ID]; // Remove the idKey from the query object if removeId is true

  query['start'] = start.format('YYYY-MM-DD');
  query['end'] = end.format('YYYY-MM-DD');
  query['prev-start'] = prevStart?.format('YYYY-MM-DD');
  query['prev-end'] = prevEnd?.format('YYYY-MM-DD');

  if (isPrevNone) {
    query[PREV_IS_NONE] = 'true';
  } else {
    delete query[PREV_IS_NONE];
  }

  if (!isEqual(prevQuery, query)) {
    navigate?.(
      {
        pathname: location.pathname,
        search: QueryString.stringify({ 'shop-id': $currentShopId.get(), ...query }),
      },
      { replace: true },
    );
  } else {
    refreshDateRanges();
  }
};

export const refreshDateRanges = () => {
  const currentRange = $currentDateRange.get();
  if (!currentRange) return;
  $currentDateRange.set({ start: currentRange.start, end: currentRange.end });
  const prevRange = $prevDateRange.get();
  if (!prevRange) return;
  $prevDateRange.set({ start: prevRange.start, end: prevRange.end });
};

export const getCurrentDateSelectedOption = (currentDateRange: CurrentDateRange | undefined) => {
  if (!currentDateRange) return undefined; //'custom' as DatePickerTimePeriods;
  const { start, end } = currentDateRange;
  const datePickerOptionValueOptions = getDatePickerOptionValueOptions();
  const dateFromId = datePickerOptionValueOptions.find((x) => {
    return x.start.isSame(start, 'day') && x.end.isSame(end, 'day');
  });
  return dateFromId?.id ?? ('custom' as DatePickerTimePeriods);
};

export const getPrevDateSelectedOption = (
  prevDateRange?: PrevDateRange | null,
): PreviousPeriodIds => {
  const currentDateRange = $currentDateRange.get();
  if (!prevDateRange || !currentDateRange) return 'none';
  const { start, end } = prevDateRange;
  const prevDatePickerOptionValueOptions = buildDatePickerCompareOptions(
    currentDateRange,
    prevDateRange,
  );
  const dateFromId = prevDatePickerOptionValueOptions.find((x) => {
    return x.start.isSame(start, 'day') && x.end.isSame(end, 'day');
  });
  return dateFromId?.id ?? ('custom' as PreviousPeriodIds);
};

export const $currentDateRange = $mutableDerived((get) => {
  const mspConnected = get($mspConnected);
  const timezone = get($timezone);
  if (!timezone || !mspConnected) return undefined;

  const search = get($search);
  const navigate = get($navigate);
  moment.tz.setDefault(timezone);
  const searchParams = new URLSearchParams(search);
  let start = searchParams.get('start');
  const end = searchParams.get('end');
  if (!start || !end) {
    pushDateToQS(
      navigate,
      moment()
        .tz(timezone ?? '')
        .startOf('day'),
      moment()
        .tz(timezone ?? '')
        .endOf('day'),
      true,
    );
    return undefined;
  }

  // start =
  //   moment(end).diff(start, 'days') > 365
  //     ? moment(end).subtract(364, 'days').format('YYYY-MM-DD')
  //     : start;

  return {
    start: moment(start).startOf('day'),
    end: moment(end).endOf('day'),
  } as CurrentDateRange;
});

$debouncedEffect((_, get, timesRun) => {
  const currentDateRange = get($currentDateRange);
  if (!timesRun) return;
  if (!currentDateRange) return;
  store.dispatch(mainDatePickerOnSelectionChange(currentDateRange));
});

export const $currentDateSelectedOption = $derived<DatePickerTimePeriods | null>((get) => {
  const currentDateRange = get($currentDateRange);
  return getCurrentDateSelectedOption(currentDateRange) ?? null;
});

$debouncedEffect((_, get, timesRun) => {
  const currentDateSelectedOption = get($currentDateSelectedOption);
  if (!timesRun) return;
  if (!currentDateSelectedOption) return;
  const allOptions = getDatePickerOptionValueOptions();
  const selectedOption = allOptions.find((o) => o.id === currentDateSelectedOption);
  const specialPeriod = selectedOption?.specialPeriod;
  if (specialPeriod) store.dispatch(mainDatePickerSpecialPeriodChange(specialPeriod));
});

export const getDefaultGranularity = (dateRange: CurrentDateRange) => {
  const { start, end } = dateRange;

  const is30DaysOrMore = Math.abs(moment(start).diff(end, 'days')) >= 31;
  const isThreeMonthsOrMore = Math.abs(moment(start).diff(end, 'days')) >= 89;
  const isOneDay = moment(start).diff(end, 'days') === 0;

  if (isOneDay) {
    return 'hour';
  }

  if (isThreeMonthsOrMore) {
    return 'month';
  }

  if (is30DaysOrMore) {
    return 'week';
  }

  return 'day';
};

export const $granularity = $mutableDerived<Granularity>((get) => {
  const dateRange = get($currentDateRange);
  if (!dateRange) {
    return 'day';
  }
  return getDefaultGranularity(dateRange);
});

$debouncedEffect((_, get, timesRun) => {
  const granularity = get($granularity);
  if (!timesRun) return;
  if (!granularity) return;
  store.dispatch(groupBtnPressed(granularity));
});

const $compareDatePickerSelectedOption = $derived(
  (get) => (get($user) as any).compareDatePickerSelectedOption,
);

export const $prevDateSelectedOption = $derived<PreviousPeriodIds | null>((get) => {
  const prevDateRange = get($prevDateRange);
  return getPrevDateSelectedOption(prevDateRange) ?? null;
});

export const $prevDateIsNone = $derived<boolean>((get) => {
  const search = get($search);
  const searchParams = new URLSearchParams(search);
  const isNone = searchParams.get(PREV_IS_NONE);

  return isNone === 'true';
});

export const $prevDateRange = $mutableDerived<PrevDateRange | null>((get) => {
  const mspConnected = get($mspConnected);
  if (!mspConnected) return null;

  const timezone = get($timezone);
  const compareDatePickerSelectedOption = get($compareDatePickerSelectedOption);
  const search = get($search);
  const current = get($currentDateRange);
  const navigate = get($navigate);

  moment.tz.setDefault(timezone);
  const searchParams = new URLSearchParams(search);
  const start = searchParams.get('prev-start');
  const end = searchParams.get('prev-end');
  const isNone = searchParams.get(PREV_IS_NONE);

  if (!current || isNone) return null;

  if (!start || !end) {
    const defaultCompareOption: PreviousPeriodIds =
      compareDatePickerSelectedOption ?? 'previousPeriod';
    const period = mapFromPeriodIdToTimeUnit[defaultCompareOption];
    if (!period) {
      const prev = getPrevDates(current);
      pushDateToQS(navigate, prev.start, prev.end, false);
    } else {
      const prevStart = moment
        .tz(current.start, timezone ?? '')
        .subtract(1, period)
        .startOf('day');
      const prevEnd = moment
        .tz(current.end, timezone ?? '')
        .subtract(1, period)
        .endOf('day');

      pushDateToQS(navigate, prevStart, prevEnd, false);
    }
    return null;
  }
  return {
    start: moment(start).startOf('day'),
    end: moment(end).endOf('day'),
  };
});

$debouncedEffect((_, get, timesRun) => {
  const prevDateRange = get($prevDateRange);
  const prevDateSelectedOption = get($prevDateSelectedOption);
  if (!timesRun) return;
  if (!prevDateSelectedOption) return;
  store.dispatch(
    changeDatesToCompare({
      start: prevDateRange?.start,
      end: prevDateRange?.end,
      id: prevDateSelectedOption,
    }),
  );
});
