import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { type RootState } from 'reducers/RootType';
import { WizardContext } from '../WizardContext';
import SendEmailModal from './SendEmailModal';
import { setOnboardingStatus } from 'ducks/shop';
import { useAppDispatch } from 'index';
import { Button, Collapsible } from '@shopify/polaris';
import { ChevronDownMinor, ChevronUpMinor } from '@shopify/polaris-icons';
import { useIntegrationsConfig } from 'routes/integrations/hooks/useIntegrationsConfig';
import { StepCompletionInfo } from 'types/WizardData';
import { cx } from 'utils/cx';
import { UseSetupProgressArgs } from 'routes/allShopsPinnedSections/hooks/useSetupProgress';
import { ServicesIds } from '@tw/types/module/services';
import { IntegrationWidget } from '../../../routes/integrations/components/IntegrationWidget';

export function WelcomeIntegrations() {
  const [moreAppsDisplay, setMoreAppsDisplay] = useState(false);
  const [isInviteUserModalOpen, setIsInviteUserModalOpen] = useState(false);
  const { nextStep } = useContext(WizardContext);
  const msp = useSelector((state: RootState) => state.msp);

  const defaultIntegrations: ServicesIds[] | 'slack' = useMemo(() => {
    if (msp === 'stripe') {
      return ['hubspot', 'intercom', 'gcp', 'posthog', 'stripe'];
    } else {
      return ['facebook-ads', 'google-ads', 'klaviyo', 'slack'] as ServicesIds[];
    }
  }, [msp]);

  const adsServices: ServicesIds[] = [
    'facebook-ads',
    'google-ads',
    'snapchat-ads',
    'tiktok-ads',
    'twitter-ads',
    'pinterest-ads',
    'bing',
    'amazon',
  ];

  const userAnalytics: ServicesIds[] = ['hubspot', 'intercom', 'gcp', 'posthog', 'stripe'];
  const marketingTool: ServicesIds[] = [
    'klaviyo',
    'recharge',
    'smsbump',
    'gorgias',
    'enquirelabs',
    'kno',
  ];
  const shipping: ServicesIds[] = ['shipstation', 'shipbob'];
  const reporting: ServicesIds[] = ['slack', 'google-sheets'];

  const integrationsWidgets = useIntegrationsConfig();
  return (
    <div className="flex flex-col items-center integrations-step overflow-auto">
      <div className="w-full flex justify-center items-center mt-[20px] mb-10">
        <img src="/wizards/integrations.svg" alt="" />
      </div>

      <h1 className="step-title">Connect Your Data</h1>
      <p className="step-description">
        Triple Whale brings all of your data together to give you a clear view of your customer
        journey. Connect ad platforms and marketing tools to Triple Whale to see every step in your
        customer journey.
      </p>

      <div>
        <Collapsible open={!moreAppsDisplay} id="default-integrations" key={1}>
          <div className="most-popular flex flex-wrap flex-1 w-full items-center flex-col gap-5 mt-[40px]">
            {integrationsWidgets
              .filter((w) => defaultIntegrations.includes(w.serviceId))
              .map((w, i) => (
                <div className={'w-full'} key={i}>
                  <IntegrationWidget {...w} />
                </div>
              ))}
          </div>
        </Collapsible>
      </div>

      <div className="my-[24px]">
        <Button
          plain
          textAlign={'left'}
          icon={moreAppsDisplay ? ChevronUpMinor : ChevronDownMinor}
          onClick={() => {
            setMoreAppsDisplay(!moreAppsDisplay);
          }}
        >
          See All Integrations
        </Button>
      </div>

      <div className="w-full">
        <Collapsible open={moreAppsDisplay} id="more-integrations" key={2}>
          <div className="flex flex-col justify-center mx-[auto] mb-[20px] px-2 lg:grid grid-cols-2 gap-5 xl:max-w-[750px]">
            <IntegrationsSection
              title="Ad Accounts"
              subTitle="Consolidate your view of your paid marketing efforts, from ad spend to cross-channel ROAS"
            >
              {integrationsWidgets
                .filter((w) => adsServices.includes(w.serviceId))
                .map((w, i) => (
                  <div className={'w-full'} key={i}>
                    <IntegrationWidget {...w} />
                  </div>
                ))}
            </IntegrationsSection>
            {msp === 'stripe' && (
              <div>
                {integrationsWidgets
                  .filter((w) => userAnalytics.includes(w.serviceId))
                  .map((w, i) => (
                    <IntegrationsSection key={i} title={w.title ?? ''} subTitle={w.subTitle ?? ''}>
                      <div className={'w-full'}>
                        <IntegrationWidget {...w} />
                      </div>
                    </IntegrationsSection>
                  ))}
              </div>
            )}

            <IntegrationsSection
              title="Marketing & Retention Tools"
              subTitle="Gain full visibility into your customers journeys across marketing & retention tools"
            >
              {integrationsWidgets
                .filter((w) => marketingTool.includes(w.serviceId))
                .map((w, i) => (
                  <div className={'w-full'} key={i}>
                    <IntegrationWidget {...w} />
                  </div>
                ))}
            </IntegrationsSection>

            <IntegrationsSection
              title="Fulfillment Tools"
              subTitle="Calculate your true fulfillment costs and net profit metrics"
            >
              {integrationsWidgets
                .filter((w) => shipping.includes(w.serviceId))
                .map((w, i) => (
                  <div className={'w-full'} key={i}>
                    <IntegrationWidget {...w} />
                  </div>
                ))}
            </IntegrationsSection>

            <IntegrationsSection
              title="Reporting"
              subTitle="Automate daily Triple Whale Reports to you and your team"
            >
              {integrationsWidgets
                .filter((w) => reporting.includes(w.serviceId))
                .map((w, i) => (
                  <div className={'w-full'} key={i}>
                    <IntegrationWidget {...w} />
                  </div>
                ))}
            </IntegrationsSection>
          </div>
        </Collapsible>
      </div>

      <p>
        Not the admin for your Facebook ad manager account?{' '}
        <span
          className="text-blue-500 underline cursor-pointer"
          onClick={() => setIsInviteUserModalOpen(true)}
        >
          Invite another user
        </span>
      </p>

      {isInviteUserModalOpen && (
        <SendEmailModal
          msp={msp}
          wizardStep="integrations"
          onClose={() => {
            setIsInviteUserModalOpen(false);
          }}
        />
      )}
      <button className="step-button" onClick={nextStep}>
        Next
      </button>
    </div>
  );
}

export const IntegrationsSection: React.FC<{
  title: string;
  subTitle: string;
  children?: any;
}> = ({ title, subTitle, children }) => {
  return (
    <div className="mb-10 pt-5">
      <div className="mb-[10px]">
        <h1 className="font-bold pb-2">{title}</h1>
        <p>{subTitle}</p>
      </div>
      <div className="flex flex-wrap gap-5 mt-3 justify-center">{children}</div>
    </div>
  );
};

export function useIntegrationsConnectedInfo({
  isFromPod,
  integrations,
}: UseSetupProgressArgs): StepCompletionInfo<{ connectionsCount: number }> {
  const facebook = useSelector((state: RootState) => state.isFacebookConnected);
  const google = useSelector((state: RootState) => state.isGoogleAdsConnected);
  const tiktok = useSelector((state: RootState) => state.isTiktokConnected);
  const snapchat = useSelector((state: RootState) => state.isSnapchatConnected);
  const pinterest = useSelector((state: RootState) => state.isPinterestConnected);
  const twitter = useSelector((state: RootState) => state.isTwitterConnected);
  const bing = useSelector((state: RootState) => state.isBingConnected);
  const amazonNA = useSelector((state: RootState) => state.isAmazonConnectedNA);
  const amazonEU = useSelector((state: RootState) => state.isAmazonConnectedEU);
  const amazonFE = useSelector((state: RootState) => state.isAmazonConnectedFE);

  const integrationStatusesFromRedux = useMemo(
    () => ({
      facebook,
      google,
      tiktok,
      snapchat,
      pinterest,
      twitter,
      bing,
      amazon: amazonNA || amazonEU || amazonFE,
    }),
    [facebook, google, tiktok, snapchat, pinterest, twitter, bing, amazonNA, amazonEU, amazonFE],
  );

  const integrationsStatuses = useMemo(
    () => ({
      facebook: false,
      pinterest: false,
      google: false,
      tiktok: false,
      snapchat: false,
      twitter: false,
      bing: false,
      amazon: false,
      ...(isFromPod ? integrations : integrationStatusesFromRedux),
    }),
    [isFromPod, integrations, integrationStatusesFromRedux],
  );

  const connectionsCount = useMemo(
    () => Object.values(integrationsStatuses).filter((key) => key).length,
    [integrationsStatuses],
  );

  const overallCount = useMemo(
    () => Object.keys(integrationsStatuses).length,
    [integrationsStatuses],
  );

  const status: StepCompletionInfo['status'] =
    connectionsCount > 1 ? 'complete' : connectionsCount > 0 ? 'in-progress' : 'not-started';

  const text =
    status !== 'not-started'
      ? `${connectionsCount}/${overallCount} Ad Platforms Connected`
      : 'None Connected';

  return { status, text, connectionsCount };
}

export const IntegrationsConnectionsIndication: React.FC<any> = (props) => {
  const { status, text, connectionsCount } = useIntegrationsConnectedInfo({
    isFromPod: props.isFromPod,
    integrations: props.integrations,
  });

  const currentShopId = useSelector((state: RootState) => state.currentShopId);

  const dispatch = useAppDispatch();

  const shopId = props.isFromPod ? props.shopDomain! : currentShopId;

  useEffect(() => {
    dispatch(setOnboardingStatus(shopId, 'integrations', !!connectionsCount));
  }, [shopId, connectionsCount, dispatch]);

  return (
    <div
      className={cx({
        'text-green': status === 'complete',
        'text-yellow-500': status === 'in-progress',
        'text-red-400': status === 'not-started',
      })}
    >
      <span>{text}</span>
    </div>
  );
};
