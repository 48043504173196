import db, { toArray } from '../utils/DB';
import { fetchProduct } from './products';
import { combineReducers } from 'redux';
import axiosInstance from 'utils/axiosInstance';
import { $currentShopId } from '$stores/$shop';

export const CUSTOMERS_RECEIVED = 'CUSTOMERS_RECEIVED';
export const LOADING_CUSTOMERS = 'LOADING_CUSTOMERS';
export const CUSTOMERS_COUNT = 'CUSTOMERS_COUNT';

export const loadCustomersCount = () => {
  return async (dispatch) => {
    const res = await axiosInstance.get(
      `/v2/shopify/mongo/get-customers-count?shopId=${$currentShopId.get()}`,
    );
    dispatch({ type: CUSTOMERS_COUNT, customersCount: res.data.count });
  };
};

export const loadCustomersWithPagination = async (start, end, limit, order, page) => {
  const url = `/v2/shopify/mongo/get-customers`;
  const { data } = await axiosInstance.post(url, {
    shopId: $currentShopId.get(),
    page,
    limit,
    orderBy: 'createdAt',
    order: order === 'desc' ? -1 : 1,
    start,
    end,
  });
  const customersOrderInfo = await getCustomersOrdersInfo(data.map((customer) => +customer.id));
  return data.map((customer) => {
    const orderInfo = customersOrderInfo.find((order) => order._id === customer.id);
    return orderInfo ? { ...customer, ...orderInfo } : customer;
  });
};

export const loadCustomer = async (id) => {
  const url = '/v2/shopify/mongo/get-customers';
  const { data } = await axiosInstance.post(url, {
    shopId: $currentShopId.get(),
    id,
  });
  const customerOrdersInfo = await getCustomersOrdersInfo([data[0].id]);
  const customerData = { ...data[0], ...customerOrdersInfo[0] };

  var customerOrder = await getCustomerOrder(customerData);
  return { ...customerData, customer_order: customerOrder, ...customerOrdersInfo };
};

const getCustomersOrdersInfo = async (customers) => {
  const url = `/v2/shopify/mongo/get-customer-orders-info`;
  const params = {
    shopId: $currentShopId.get(),
    customers: customers.join(','),
  };
  const { data } = await axiosInstance.get(url, {
    params,
  });
  return data;
};

const getCustomerOrder = async (customer) => {
  let orderData;
  const url = `/v2/shopify/mongo/get-orders`;
  const { data } = await axiosInstance.post(url, {
    shopId: $currentShopId.get(),
    id: customer.last_order_id,
  });
  orderData = data[0];
  var updatedLineItems = await getImages(orderData);
  return { ...orderData, line_items: updatedLineItems };
};
const getImages = async (order) => {
  const promise = order.line_items.map(async (item) => {
    const productData = await fetchProduct(item.product_id);
    let productImg = '';
    if (typeof productData?.[0].data() != 'undefined') {
      productImg = productData[0].data().image?.src || '';
    }
    return { ...item, productImg };
  });
  return await Promise.all(promise);
};

// REDUCERS
// REDUCERS
// REDUCERS

const shopCustomers = (state = [], action) => {
  switch (action.type) {
    case CUSTOMERS_RECEIVED:
      return action.customers;
    default:
      return state;
  }
};

const shopCustomersCount = (state = null, action) => {
  switch (action.type) {
    case CUSTOMERS_COUNT:
      return action.customersCount;
    default:
      return state;
  }
};

const loadingCustomers = (state = true, action) => {
  switch (action.type) {
    case CUSTOMERS_RECEIVED:
      return false;
    default:
      return state;
  }
};

export const reducers = combineReducers({
  shopCustomers,
  shopCustomersCount,
  loadingCustomers,
});
