import { $currentShopId } from '$stores/$shop';
import { Button, FormLayout, OptionList, TextField } from '@shopify/polaris';
import { useStoreValue } from '@tw/snipestate';
import { Loader, Modal, Skeleton, Text } from '@tw/ui-components';
import { useState, useCallback, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axiosInstance from 'utils/axiosInstance';
import { genericEventLogger, analyticsEvents, chatActions } from 'utils/dataLayer';
import _db, { Timestamp, toArray } from 'utils/DB';
import { useFilteredItems } from './hooks/useFilteredItems';
import { Conversation } from './types/willyTypes';
import { WillySearchInput } from './WillySearchInput';
import { WillyUserAvatar } from './WillyUserAvatar';
import { User } from 'components/UserProfileManagment/User/constants';
import { CopyToClipboard } from './CopyToClipboard';
import { userFilterOptions } from './constants';
import InfiniteScroll from 'react-infinite-scroll-component';
import { $userId } from '$stores/$user';

type ChatHistoryProps = {
  conversations: Conversation[];
  userFilter: (typeof userFilterOptions)[number]['value'];
  setConversations: React.Dispatch<
    React.SetStateAction<{ conversations: Conversation[]; loading: boolean; error: boolean }>
  >;
  loading: boolean;
  error: boolean;
};

export const ChatHistory: React.FC<ChatHistoryProps> = ({
  conversations,
  userFilter,
  setConversations,
  loading,
  error,
}) => {
  const { search } = useLocation();
  const navigate = useNavigate();

  const currentShopId = useStoreValue($currentShopId);
  const userId = useStoreValue($userId);

  const [freeSearch, setFreeSearch] = useState('');
  const [userToDisplay, setUserToDisplay] = useState<string>('');
  const [conversationInfoModal, setConversationInfoModal] = useState<Conversation>();

  const filteredConversations = useFilteredItems(
    conversations,
    freeSearch,
    ['title', 'id', 'id', 'history[0].text'],
    userFilter,
  );

  const [hasMore, setHasMore] = useState(true);

  const lastConversation = useMemo(() => {
    return conversations[conversations.length - 1]?.createdAt || Timestamp.now();
  }, [conversations]);

  const fetchMore = useCallback(async () => {
    let query = _db()
      .collection('conversations')
      .where('v', 'in', [6])
      .where('source', 'in', ['chat'])
      .orderBy('createdAt', 'desc')
      .startAfter(lastConversation)
      .limit(20);

    if (userFilter === 'user') {
      query = query.where('user', '==', userId);
    }

    const docs = await query.get();
    const conversations = toArray<Conversation>(docs);

    setConversations((old) => ({
      ...old,
      conversations: [...old.conversations, ...conversations],
    }));
    setHasMore(!docs.empty);
  }, [lastConversation, setConversations, userFilter, userId]);

  const filteredConversationsGroupedByDate = useMemo(() => {
    // the idea is to group conversations by date
    // so conversations from today will be in one group called "Today"
    // another groups will be: "Yesterday", "last 7 days", "last 30 days", "older this year", "older"
    const groups = Object.groupBy(filteredConversations, (c) => {
      const date = c.createdAt.toDate();
      const today = new Date();
      if (date.toDateString() === today.toDateString()) {
        return 'TODAY';
      }
      if (date.toDateString() === new Date(today.setDate(today.getDate() - 1)).toDateString()) {
        return 'YESTERDAY';
      }
      if (date > new Date(today.setDate(today.getDate() - 2))) {
        return '2 DAYS AGO';
      }
      if (date > new Date(today.setDate(today.getDate() - 3))) {
        return '3 DAYS AGO';
      }
      if (date > new Date(today.setDate(today.getDate() - 4))) {
        return '4 DAYS AGO';
      }
      if (date > new Date(today.setDate(today.getDate() - 5))) {
        return '5 DAYS AGO';
      }
      if (date > new Date(today.setDate(today.getDate() - 6))) {
        return '6 DAYS AGO';
      }
      if (date > new Date(today.setDate(today.getDate() - 7))) {
        return '7 DAYS AGO';
      }
      if (date > new Date(today.setDate(today.getDate() - 23))) {
        return 'PREVIOUS 30 DAYS';
      }
      if (date > new Date(today.setMonth(today.getMonth() - 1))) {
        return 'OLDER THIS YEAR';
      }
      if (date > new Date(today.setMonth(today.getMonth() - 3))) {
        return 'LAST 3 MONTHS';
      }
      if (date > new Date(today.setMonth(today.getMonth() - 6))) {
        return 'LAST 6 MONTHS';
      }
      if (date > new Date(today.setFullYear(today.getFullYear() - 1))) {
        return 'LAST YEAR';
      }
      return 'OLDER';
    });

    return groups;
  }, [filteredConversations]);

  useEffect(() => {
    if (!conversationInfoModal?.user) {
      return;
    }
    (async () => {
      const { data } = await axiosInstance.get<User>(
        `/v2/willy/get-user-name?shopId=${currentShopId}&userId=${conversationInfoModal.user}`,
      );
      const { firstName, lastName, email } = data;
      setUserToDisplay(`${firstName} ${lastName} (${email})`);
    })();
  }, [conversationInfoModal?.user, currentShopId]);

  return (
    <div className="h-full overflow-scroll no-scrollbar max-w-[85%] m-auto">
      <Text fz={26} fw={600}>
        Chat History
      </Text>
      {!loading && error && (
        <div className="p-4">
          <p>Error loading conversations</p>
        </div>
      )}
      <div className="h-full">
        {!conversations.length && !loading && !error && (
          <div className="p-4">
            <p>Ask a question to get started. You can also choose from the templates</p>
          </div>
        )}

        <WillySearchInput
          value={freeSearch}
          onChange={setFreeSearch}
          placeholder="Search history"
          className="!px-0 !pt-[30px]"
        />

        <div className="h-full overflow-auto" id="chat-history">
          {loading && !error && (
            <OptionList
              options={Array(50)
                .fill(0)
                .map((_, i) => ({
                  value: i.toString(),
                  label: <Skeleton key={i} animate height={'1.5rem'} />,
                }))}
              selected={[]}
              onChange={() => {}}
            />
          )}

          <InfiniteScroll
            dataLength={filteredConversations.length}
            next={fetchMore}
            loader={
              <div className="flex items-center justify-center h-12">
                <Loader size="sm" />
              </div>
            }
            hasMore={hasMore}
            // height={400}
            scrollableTarget="chat-history"
            scrollThreshold={0.95}
            endMessage={
              <p className="flex-container center no-more-orders-msg">
                {filteredConversations?.length
                  ? 'End of Conversation History'
                  : freeSearch.length
                    ? 'No conversations found'
                    : 'Conversation History Empty'}
              </p>
            }
          >
            <div>
              {Object.entries(filteredConversationsGroupedByDate).map(([date, conversations]) => {
                return (
                  <div className="pt-[40px]" key={date}>
                    <Text color="gray.5" fw={500} tt="capitalize" px={10} pb={20}>
                      {date}
                    </Text>
                    <div>
                      {conversations.map((option, i) => {
                        const isLast = i === conversations.length - 1;
                        return (
                          <div
                            key={option.id}
                            className="pt-[16px]  cursor-pointer"
                            onClick={() => {
                              const params = new URLSearchParams(search);
                              params.set('conversationId', option.id);
                              params.delete('sequenceId');
                              params.delete('runId');
                              params.delete('tab');
                              navigate({
                                pathname: window.location.pathname,
                                search: params.toString(),
                                hash: '',
                              });

                              genericEventLogger(analyticsEvents.CHAT, {
                                action: chatActions.CHOOSE_EXISTING,
                                id: option.id,
                                text: option.history?.[0]?.text || '',
                                conversationId: option.id,
                              });
                            }}
                          >
                            <Text px={10} color="gray.7" lineClamp={3}>
                              {option.history?.[0]?.text || ''}
                            </Text>
                            {!isLast && <div className="w-full h-[1px] bg-gray-200 mt-[16px]" />}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </InfiniteScroll>
        </div>

        <Modal
          opened={!!conversationInfoModal}
          onClose={() => {
            setConversationInfoModal(undefined);
          }}
        >
          <FormLayout>
            <FormLayout.Group>
              <TextField
                onChange={() => {}}
                autoComplete="off"
                label="Conversation ID"
                value={conversationInfoModal?.id}
                readOnly
                disabled
                connectedRight={
                  <CopyToClipboard text={conversationInfoModal?.id!} className="flex h-full" />
                }
              />
              <TextField
                onChange={() => {}}
                autoComplete="off"
                label="Created At"
                value={conversationInfoModal?.createdAt.toDate().toLocaleString()}
                readOnly
                disabled
              />
            </FormLayout.Group>
            <FormLayout.Group title="Owner">
              <div className="flex items-start gap-2 w-full">
                <WillyUserAvatar userName={userToDisplay} userId={conversationInfoModal?.user} />
                <div className="flex-auto">
                  <TextField
                    onChange={() => {}}
                    autoComplete="off"
                    label="Owner"
                    labelHidden
                    value={userToDisplay}
                    readOnly
                    disabled
                  />
                </div>
              </div>
            </FormLayout.Group>
            <FormLayout.Group>
              <TextField
                autoComplete="off"
                label="Title"
                value={conversationInfoModal?.title}
                onChange={(v) => {
                  setConversationInfoModal((old) => {
                    if (!old) return old;
                    return {
                      ...old,
                      title: v,
                    };
                  });
                }}
                connectedRight={
                  <Button
                    onClick={async () => {
                      if (!conversationInfoModal) return;
                      await _db()
                        .collection('conversations')
                        .doc(conversationInfoModal.id)
                        .set({ title: conversationInfoModal.title }, { merge: true });
                    }}
                  >
                    Save
                  </Button>
                }
              />
            </FormLayout.Group>
          </FormLayout>
        </Modal>
      </div>
    </div>
  );
};
