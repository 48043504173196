import AttentiveIconBase64 from 'components/AttentiveIconBase64';
import EnquirelabsIconBase64 from 'components/EnquirelabsIconBase64';
import FacebookIconBase64 from 'components/FacebookIconBase64';
import GoogleAdsIconBase64 from 'components/GoogleAdsIconBase64';
import GoogleAnalyticsIconBase64 from 'components/GoogleAnalyticsIconBase64';
import KnoLogoSVG from 'components/Icons/KnoLogoSVG';
import KlaviyoIconBase64 from 'components/KlaviyoIconBase64';
import MailchimpIconBase64 from 'components/MailchimpIconBase64';
import OmnisendIconBase64 from 'components/OmnisendIconBase64';
import PinterestIconBase64 from 'components/PinterestIconBase64';
import PostscriptIconBase64 from 'components/PostscriptBase64';
import DripIconBase64 from 'components/DripBase64';
import ShopifyIconBase64 from 'components/ShopifyIconBase64';
import SmsBumpIconBase64 from 'components/SmsBumpIconBase64';
import SnapchatIconBase64 from 'components/SnapchatIconBase64';
import TiktokIconBase64 from 'components/TiktokIconBase64';
import ViaIconBase64 from 'components/ViaIconBase64';
import { ReactComponent as InfluencersIcon } from '../icons/affluencer-hub.svg';
import fbAnimationData from '../lotties/facebook-blue.json';
import googleAnimationData from '../lotties/google-orange.json';
import klaviyoAnimationData from '../lotties/klaviyo-green.json';
import shopifyAnimationData from '../lotties/shopify-green.json';
import MetaIconBase64 from 'components/MetaIconBase64';

const ALL_CHANNELS_ID = 'ALL_CHANNELS';

export const FB_LIST = [
  'fb',
  'fbig',
  'facebook',
  'ig',
  'Facebook_Ads+ig',
  'Facebook_Ads+fb',
  'Facebook_Ads fb',
  'facebook_ads',
  'facebook-ads',
  'AD FB fb',
  'Facebook',
  'facebook.com',
  'Facebook_Mobile_Feed',
  'Instagram_Feed',
  'Instagram_Explore',
  'Facebook+Pd',
  'Facebook Pd',
  'Facebook_Ads',
  'Facebook_Desktop_Feed',
  'Instagram_Stories',
  'FBIG',
  'FB_Ads',
  'FBIG_AB',
  'fb_ig',
  'CTC+FB',
  'instagram',
  'AD+FB+fb',
  'facebook+instagram',
  'Facebook Ads',
  'FB-Paid',
  'Facebook+Ads',
  'FBPaid',
  'meta',
  'an',
  'msg',
];

export const TIKTOK_LIST = ['tiktok', 'tiktok.com', 'TikTok'];

export const SNAPCHAT_LIST = ['snapchat', 'snapchat.com'];
export const PINTEREST_LIST = ['pinterest', 'pinterest.com', 'Pinterest'];
export const GOOGLE_LIST = ['google', 'google.com', 'google_ads', 'Google', 'google-ads'];

export const KLAVIYO_LIST = ['klaviyo', 'klaviyo.com', 'Klaviyo', 'KlaviyoCampaign'];

export const YOUTUBE_LIST = ['youtube', 'youtube.com'];

interface Ad {
  func: (args: any) => Promise<any[]>;
}

interface Marketing extends Ad {
  renderUrl: (...args: any) => string;
}

type StringFunc = () => string;
interface StatsSelector {
  clicks: StringFunc;
  impressions: StringFunc;
  spend: StringFunc;
  conversionValue: StringFunc;
  purchases: StringFunc;
  conversions: StringFunc;
  allConversions: StringFunc;
  allConversionValue: StringFunc;
  purchase_roas: StringFunc;
  cpc: StringFunc;
  cpm: StringFunc;
  ctr: StringFunc;
  dayViewRoas: StringFunc;
}

export type ChannelPrefixes =
  | typeof ALL_CHANNELS_ID
  | 'fb'
  | 'google'
  | 'pinterest'
  | 'tiktok'
  | 'twitter'
  | 'snapchat'
  | 'klaviyo'
  | 'attentive'
  | 'postscript'
  | 'drip'
  | 'via'
  | 'mailchimp'
  | 'omnisend'
  | 'sms_bump'
  | 'fbSocial'
  | 'youtube'
  | 'tiktokSocial'
  | 'snapchatSocial'
  | 'pinterestSocial'
  | 'instagram'
  | 'shopify'
  | 'recharge'
  | 'enquirelabs'
  | 'kno'
  | 'triplesurvey'
  | 'triplesurvey_email'
  | 'triplesurvey_text'
  | 'organic'
  | 'triplesurvey-none'
  | 'influencers'
  | 'tw_referrer'
  | 'google_analytics'
  | 'meta-shop';

export type ChannelsTypes =
  | 'ads'
  | 'email'
  | 'social'
  | 'influencers'
  | 'goods'
  | 'analytics'
  | 'survey'
  | 'unknown'
  | 'all'
  | 'platforms';

export type ChannelsIds =
  | typeof ALL_CHANNELS_ID
  | 'facebook-ads'
  | 'google-ads'
  | 'tiktok-ads'
  | 'twitter-ads'
  | 'snapchat-ads'
  | 'pinterest-ads'
  | 'klaviyo'
  | 'ATTENTIVE'
  | 'INSTAGRAM'
  | 'YOUTUBE'
  | 'SHOPIFY'
  | 'SHOPIFY_PRODUCTS'
  | 'GOOGLE_ANALYTICS'
  | 'ENQUIRELABS'
  | 'KNO'
  | 'POSTSCRIPT'
  | 'DRIP'
  | 'VIA'
  | 'MAILCHIMP'
  | 'OMNISEND'
  | 'SMSBUMP'
  | 'INFLUENCERS'
  | 'SOCIAL'
  | 'meta-shop';

export const allChannelsOption = {
  [ALL_CHANNELS_ID]: {
    general: {
      prefix: ALL_CHANNELS_ID,
      id: ALL_CHANNELS_ID,
      title: 'All Channels',
      color: 'transparent',
      icon: ({ small }) => <></>,
      type: 'unknown',
      tilesSection: '',
    },
  },
};

export const paidChannels = {
  ...allChannelsOption,
  'facebook-ads': {
    general: {
      id: 'facebook-ads',
      prefix: 'fb',
      type: 'ads',
      showInJourney: true,
      showOverlaps: true,
      statsSelectors: {
        clicks: () => 'facebookClicks',
        impressions: () => 'facebookImpressions',
        spend: () => 'fb_ads_spend',
        conversionValue: () => 'facebookConversionValue',
        purchases: () => 'N/A',
        purchase_roas: () => 'fb_ads_purchase_roas',
        cpc: () => 'averageFacebookCpc',
        cpm: () => 'averageFacebookCpm',
        ctr: () => 'facebookCtr',
        dayViewRoas: () => 'averageFacebookDayViewPurchaseRoas',
        conversions: () => 'facebookPurchases',
        allConversions: () => 'N/A',
        allConversionValue: () => 'N/A',
      },
      campaignsMapKey: 'fb_campaign_id',
      title: 'Facebook',
      color: 'rgb(44,95,183)',
      background: 'rgba(34,75,136,0.5)',
      iconOptions: {
        loop: true,
        autoplay: true,
        animationData: fbAnimationData,
        rendererSettings: {
          preserveAspectRatio: 'xMinYMin slice',
        },
      },
      sourceVariations: FB_LIST,
      icon: ({ small }) => <FacebookIconBase64 small={small} />,
      tilesSection: 'facebookAds',
    },
    campaigns: {
      func: async () => [],
      renderUrl: () => '#',
    },
    adSets: {
      func: async () => [],
    },
    ads: {
      func: async () => [],
    },
  },
  'google-ads': {
    general: {
      id: 'google-ads',
      prefix: 'google',
      type: 'ads',
      showInJourney: true,
      showOverlaps: true,
      statsSelectors: {
        clicks: () => 'totalGoogleAdsClicks',
        impressions: () => 'totalGoogleAdsImpressions',
        spend: () => 'totalGoogleAdsCost',
        conversionValue: () => 'googleConversionValue',
        purchases: () => 'N/A',
        purchase_roas: () => 'googleRoas',
        cpc: () => 'googleCpc',
        cpm: () => 'totalGoogleAdsCpm',
        ctr: () => 'totalGoogleAdsCtr',
        dayViewRoas: () => 'N/A',
        conversions: () => 'ga_all_transactions_adGroup',
        allConversions: () => 'ga_transactions_adGroup',
        allConversionValue: () => 'ga_all_transactionsRevenue_adGroup',
      },
      campaignsMapKey: 'google_campaign_id',
      title: 'Google',
      color: 'rgb(49,169,81)',
      background: 'rgba(49,169,111,0.5)',
      iconOptions: {
        loop: true,
        autoplay: true,
        animationData: googleAnimationData,
        rendererSettings: {
          preserveAspectRatio: 'xMinYMin slice',
        },
      },
      sourceVariations: GOOGLE_LIST,
      icon: ({ small }) => <GoogleAdsIconBase64 small={small} />,
      tilesSection: 'GOOGLE_ADS',
    },
    campaigns: {
      func: async () => [],
      renderUrl: () => '#',
    },
    adSets: {
      func: async () => [],
    },
    ads: {
      func: async () => [],
    },
  },
  'tiktok-ads': {
    general: {
      id: 'tiktok-ads',
      prefix: 'tiktok',
      type: 'ads',
      showInJourney: true,
      showOverlaps: true,
      statsSelectors: {
        clicks: () => 'tiktokClicks',
        impressions: () => 'tiktokImpressions',
        spend: () => 'tiktok_spend',
        conversionValue: () => 'tiktokConversionValue',
        purchases: () => 'tiktokPurchases',
        purchase_roas: () => 'tiktok_complete_payment_roas',
        cpc: () => 'averageTiktokCpc',
        cpm: () => 'averageTiktokCpm',
        ctr: () => 'tiktokCtr',
        dayViewRoas: () => 'N/A',
        conversions: () => 'tiktokPurchases',
        allConversions: () => 'N/A',
        allConversionValue: () => 'N/A',
        tiktokShopConversions: () => 'tiktokShopConversions',
        tiktokShopConversionsValue: () => 'tiktokShopConversionsValue',
      },
      campaignsMapKey: 'tiktok_campaign_id',
      title: 'TikTok',
      color: 'rgb(246,14,78)',
      background: 'rgba(108,32,61,0.5)',
      iconOptions: {
        loop: true,
        autoplay: true,
        animationData: shopifyAnimationData,
        rendererSettings: {
          preserveAspectRatio: 'xMinYMin slice',
        },
      },
      sourceVariations: TIKTOK_LIST,
      icon: ({ small }) => <TiktokIconBase64 small={small} />,
      tilesSection: 'tiktok',
    },
    campaigns: {
      func: async () => [],
      renderUrl: () => '#',
    },
    adSets: {
      func: async () => [],
    },
    ads: {
      func: async () => [],
    },
  },
  'twitter-ads': {
    general: {
      id: 'twitter-ads',
      prefix: 'twitter',
      type: 'ads',
      showInJourney: true,
      showOverlaps: true,
      statsSelectors: {
        clicks: () => 'twitterClicks',
        impressions: () => 'twitterImpressions',
        spend: () => 'twitter_spend',
        conversionValue: () => 'twitterConversionValue',
        purchases: () => 'twitterPurchases',
        purchase_roas: () => 'twitter_complete_payment_roas',
        cpc: () => 'N/A',
        cpm: () => 'twitterCpm',
        ctr: () => 'N/A',
        dayViewRoas: () => 'N/A',
        conversions: () => 'twitterPurchases',
        allConversionValue: () => 'N/A',
        allConversions: () => 'N/A',
      },
      campaignsMapKey: 'twitter_campaign_id',
      title: 'Twitter',
      color: 'rgb(246,14,78)',
      background: 'rgba(108,32,61,0.5)',
      iconOptions: {
        loop: true,
        autoplay: true,
        animationData: shopifyAnimationData,
        rendererSettings: {
          preserveAspectRatio: 'xMinYMin slice',
        },
      },
      sourceVariations: TIKTOK_LIST,
      icon: ({ small }) => <TiktokIconBase64 small={small} />,
      tilesSection: 'tiktok',
    },
    campaigns: {
      func: async () => [],
      renderUrl: () => '#',
    },
    adSets: {
      func: async () => [],
    },
    ads: {
      func: async () => [],
    },
  },
  'snapchat-ads': {
    general: {
      id: 'snapchat-ads',
      prefix: 'snapchat',
      type: 'ads',
      showInJourney: true,
      showOverlaps: true,
      statsSelectors: {
        clicks: () => 'snapchat_swipes',
        impressions: () => 'snapchatImpressions',
        spend: () => 'totalSnapchatSpend',
        conversionValue: () => 'totalSnapchatConversionPurchasesValue',
        purchases: () => 'N/A',
        purchase_roas: () => 'totalSnapchatRoas',
        cpc: () => 'snapchatCpc',
        cpm: () => 'snapchatCpm',
        ctr: () => 'snapchatCtr',
        dayViewRoas: () => 'N/A',
        conversions: () => 'totalSnapchatConversionPurchases',
        allConversions: () => 'N/A',
        allConversionValue: () => 'N/A',
      },
      campaignsMapKey: 'snapchat_campaign_id',
      title: 'Snapchat',
      color: 'rgb(247,244,64)',
      background: 'rgba(247,244,64,0.5)',
      iconOptions: {
        loop: true,
        autoplay: true,
        animationData: shopifyAnimationData,
        rendererSettings: {
          preserveAspectRatio: 'xMinYMin slice',
        },
      },
      sourceVariations: SNAPCHAT_LIST,
      icon: ({ small }) => <SnapchatIconBase64 small={small} />,
      tilesSection: 'snapchat',
    },
    campaigns: {
      func: async () => [],
      renderUrl: () => '#',
    },
    adSets: {
      func: async () => [],
    },
    ads: {
      func: async () => [],
    },
  },
  'pinterest-ads': {
    general: {
      id: 'pinterest-ads',
      prefix: 'pinterest',
      type: 'ads',
      showInJourney: true,
      showOverlaps: true,
      statsSelectors: {
        clicks: () => 'pinterestTotalClicks',
        impressions: () => 'pinterestImpressions',
        spend: () => 'pinterestSpend',
        conversionValue: () => 'pinterestConversionValue',
        purchases: () => 'N/A',
        purchase_roas: () => 'pinterestRoas',
        cpc: () => 'pinterestCpc',
        cpm: () => 'pinterestCpm',
        ctr: () => 'pinterestCtr',
        dayViewRoas: () => 'N/A',
        conversions: () => 'pinterestPurchases',
        allConversions: () => 'N/A',
        allConversionValue: () => 'N/A',
      },
      campaignsMapKey: 'pinterest_campaign_id',
      title: 'Pinterest',
      color: 'rgb(197,34,43)',
      background: 'rgba(197,34,43,0.5)',
      iconOptions: {
        loop: true,
        autoplay: true,
        animationData: shopifyAnimationData,
        rendererSettings: {
          preserveAspectRatio: 'xMinYMin slice',
        },
      },
      sourceVariations: PINTEREST_LIST,
      icon: ({ small }) => <PinterestIconBase64 small={small} />,
      tilesSection: 'pinterest',
    },
    campaigns: {
      func: async () => [],
      renderUrl: () => '#',
    },
    adSets: {
      func: async () => [],
    },
    ads: {
      func: async () => [],
    },
  },
};

export const referrerChannels = {
  SOCIAL: {
    general: {
      prefix: 'tw_referrer',
      id: 'SOCIAL',
      title: 'Organic and Social',
      type: 'social',
      color: 'rgb(217,217,217)',
      background: 'rgba(217,217,217,0.5)',
      sourceVariations: [],
      icon: ({ small }) => (
        <span className="flex-container center middle" style={{ stroke: 'var(--accent-color)' }}>
          <PinterestIconBase64 small={small} />
        </span>
      ),
    },
    campaigns: {
      renderUrl: () => '#',
      func: async () => [],
    },
    adSets: {
      func: async () => [],
    },
    ads: {
      func: async () => [],
    },
  },
};

export const influencersChannels = {
  INFLUENCERS: {
    general: {
      title: 'Affluencers',
      id: 'INFLUENCERS',
      prefix: 'influencers',
      color: 'rgb(217,217,217)',
      background: 'rgba(217,217,217,0.5)',
      type: 'influencers',
      sourceVariations: [],
      icon: ({ small }) => (
        <span className="flex-container center middle" style={{ stroke: 'var(--accent-color)' }}>
          <InfluencersIcon className="" width={14} height={14} />
        </span>
      ),
    },
    campaigns: {
      renderUrl: () => '#',
      func: async () => [],
    },
    adSets: {
      func: async () => [],
    },
    ads: {
      func: async () => [],
    },
  },
};

export const goodsChannels = {
  SHOPIFY: {
    general: {
      id: 'SHOPIFY',
      prefix: 'shopify',
      title: 'Shopify',
      color: 'rgb(149,191,70)',
      background: 'rgb(149,191,70,0.5)',
      type: 'goods',
      icon: ({ small }) => <ShopifyIconBase64 small={small} />,
      sourceVariations: ['shopify', 'shop.app'],
    },
    campaigns: {
      renderUrl: () => '#',
      func: async () => [],
    },
    adSets: {
      func: async () => [],
    },
    ads: {
      func: async () => [],
    },
  },
  SHOPIFY_PRODUCTS: {
    general: {
      id: 'SHOPIFY_PRODUCTS',
      prefix: 'shopify',
      title: 'Shopify',
      color: 'rgb(149,191,70)',
      background: 'rgb(149,191,70,0.5)',
      type: 'goods',
      icon: ({ small }) => <ShopifyIconBase64 small={small} />,
    },
    campaigns: {
      renderUrl: () => '#',
      func: async () => [],
    },
    adSets: {
      func: async () => [],
    },
    ads: {
      func: async () => [],
    },
  },
};

export const analyticsChannels = {
  GOOGLE_ANALYTICS: {
    general: {
      id: 'GOOGLE_ANALYTICS',
      prefix: 'google_analytics',
      title: 'Google Analytics',
      color: 'rgb(240,165,0)',
      background: 'rgb(240,165,0,0.5)',
      type: 'analytics',
      icon: ({ small }) => <GoogleAnalyticsIconBase64 small={small} />,
    },
    campaigns: {
      renderUrl: () => '#',
      func: async () => [],
    },
    adSets: {
      func: async () => [],
    },
    ads: {
      func: async () => [],
    },
  },
};

export const surveyChannels = {
  ENQUIRELABS: {
    general: {
      id: 'ENQUIRELABS',
      prefix: 'enquirelabs',
      title: 'Fairing',
      color: 'rgb(16,185,129)',
      background: 'rgb(16,185,129,0.5)',
      type: 'survey',
      icon: ({ small }) => <EnquirelabsIconBase64 small={small} />,
    },
    campaigns: {
      renderUrl: () => '#',
      func: async () => [],
    },
    adSets: {
      func: async () => [],
    },
    ads: {
      func: async () => [],
    },
  },
  KNO: {
    general: {
      id: 'KNO',
      prefix: 'kno',
      title: 'Kno',
      color: 'rgb(16,185,129)',
      background: 'rgb(16,185,129,0.5)',
      type: 'survey',
      icon: ({ small }) => <KnoLogoSVG small={small} />,
    },
    campaigns: {
      renderUrl: () => '#',
      func: async () => [],
    },
    adSets: {
      func: async () => [],
    },
    ads: {
      func: async () => [],
    },
  },
};

// TODO: add GORGIAS, TRIPLEWHALE

export const emailAndSMSChannels = {
  ...allChannelsOption,
  klaviyo: {
    general: {
      id: 'klaviyo',
      prefix: 'klaviyo',
      type: 'email',
      showInJourney: true,
      showOverlaps: true,
      statsSelectors: {
        clicks: () => 'N/A',
        impressions: () => 'N/A',
        spend: () => 'N/A',
        conversionValue: () => 'klaviyoPlacedOrderSales',
        purchases: () => 'N/A',
        purchase_roas: () => 'N/A',
        cpc: () => 'N/A',
        cpm: () => 'N/A',
        ctr: () => 'N/A',
        dayViewRoas: () => 'N/A',
        conversions: () => 'N/A',
        allConversions: () => 'N/A',
        allConversionValue: () => 'N/A',
      },
      campaignsMapKey: 'klaviyo_campaign_id',
      title: 'Klaviyo',
      color: 'rgb(14,208,129)',
      background: 'rgba(28,125,86,0.5)',
      iconOptions: {
        loop: true,
        autoplay: true,
        animationData: klaviyoAnimationData,
        rendererSettings: {
          preserveAspectRatio: 'xMinYMin slice',
        },
      },
      sourceVariations: KLAVIYO_LIST,
      icon: ({ small }) => <KlaviyoIconBase64 small={small} />,
      tilesSection: 'klaviyo',
    },
    campaigns: {
      func: async () => [],
      renderUrl: () => '#',
    },
    adSets: {
      func: async () => [],
    },
    ads: {
      func: async () => [],
    },
  },
  ATTENTIVE: {
    general: {
      id: 'ATTENTIVE',
      prefix: 'attentive',
      type: 'email',
      showInJourney: true,
      showOverlaps: true,
      sourceVariations: ['attentive', 'attentivemobile.looker.com', 'ui.attentivemobile.com'],
      title: 'Attentive',
      color: 'rgb(157,149,74)',
      background: 'rgb(157,149,74,0.5)',
      icon: ({ small }) => <AttentiveIconBase64 small={small} />,
    },
    campaigns: {
      renderUrl: () => '#',
      func: async () => {
        return [];
      },
    },
    adSets: {
      func: async () => [],
    },
    ads: {
      func: async () => [],
    },
  },
  POSTSCRIPT: {
    general: {
      id: 'POSTSCRIPT',
      prefix: 'postscript',
      showInJourney: true,
      showOverlaps: true,
      type: 'email',
      sourceVariations: ['postscript'],
      title: 'Postscript',
      color: 'rgb(51,21,139)',
      background: 'rgb(51,21,139,0.5)',
      icon: ({ small }) => <PostscriptIconBase64 small={small} />,
    },
    campaigns: {
      renderUrl: () => '#',
      func: async () => [],
    },
    adSets: {
      func: async () => [],
    },
    ads: {
      func: async () => [],
    },
  },
  DRIP: {
    general: {
      id: 'DRIP',
      prefix: 'drip',
      showInJourney: true,
      showOverlaps: true,
      type: 'email',
      sourceVariations: ['drip'],
      title: 'Drip',
      color: 'rgb(51,21,139)',
      background: 'rgb(51,21,139,0.5)',
      icon: ({ small }) => <DripIconBase64 small={small} />,
    },
    campaigns: {
      renderUrl: () => '#',
      func: async () => [],
    },
    adSets: {
      func: async () => [],
    },
    ads: {
      func: async () => [],
    },
  },
  VIA: {
    general: {
      id: 'VIA',
      prefix: 'via',
      showInJourney: true,
      showOverlaps: true,
      sourceVariations: ['via'],
      title: 'Via',
      type: 'email',
      color: 'rgb(161,130,66)',
      background: 'rgb(161,130,66,0.5)',
      icon: ({ small }) => <ViaIconBase64 small={small} />,
    },
    campaigns: {
      renderUrl: () => '#',
      func: async () => [],
    },
    adSets: {
      func: async () => [],
    },
    ads: {
      func: async () => [],
    },
  },
  MAILCHIMP: {
    general: {
      id: 'MAILCHIMP',
      prefix: 'mailchimp',
      showOverlaps: true,
      showInJourney: true,
      sourceVariations: ['mailchimp', 'mc', 'mailchimp.com'],
      title: 'Mailchimp',
      type: 'email',
      color: 'rgb(255,223,27)',
      background: 'rgb(255,223,27, 0.5)',
      icon: ({ small }) => <MailchimpIconBase64 small={small} />,
    },
    campaigns: {
      renderUrl: () => '#',
      func: async () => [],
    },
    adSets: {
      func: async () => [],
    },
    ads: {
      func: async () => [],
    },
  },
  OMNISEND: {
    general: {
      id: 'OMNISEND',
      prefix: 'omnisend',
      showInJourney: true,
      showOverlaps: true,
      sourceVariations: ['omnisend'],
      title: 'Omnisend',
      type: 'email',
      color: 'rgb(85,92,102)',
      background: 'rgb(85,92,102,0.5)',
      icon: ({ small }) => <OmnisendIconBase64 small={small} />,
    },
    campaigns: {
      renderUrl: () => '#',
      func: async () => [],
    },
    adSets: {
      func: async () => [],
    },
    ads: {
      func: async () => [],
    },
  },
  SMSBUMP: {
    general: {
      id: 'SMSBUMP',
      prefix: 'sms_bump',
      showInJourney: true,
      showOverlaps: true,
      sourceVariations: ['smsbump', 'sms_bump'],
      title: 'Yotpo SMS and Email',
      type: 'email',
      color: 'rgb(120,211,84)',
      background: 'rgb(120,211,84,0.5)',
      icon: ({ small }) => <SmsBumpIconBase64 small={small} />,
    },
    campaigns: {
      renderUrl: () => '#',
      func: async () => [],
    },
    adSets: {
      func: async () => [],
    },
    ads: {
      func: async () => [],
    },
  },
};

export const platformShopChannels = {
  // TikTok shops to be added later
  'meta-shop': {
    general: {
      id: 'meta-shop',
      prefix: 'meta-shop',
      type: 'platforms',
      showInJourney: true,
      showOverlaps: false,
      title: 'Meta Shop',
      color: 'rgb(44,95,183)',
      background: 'rgba(34,75,136,0.5)',
      icon: ({ small }) => <MetaIconBase64 small={small} />,
    },
    campaigns: {
      renderUrl: () => '#',
      func: async () => [],
    },
    adSets: {
      func: async () => [],
    },
    ads: {
      func: async () => [],
    },
  },
};

export const allChannels = {
  ...goodsChannels,
  ...paidChannels,
  ...emailAndSMSChannels,
  ...referrerChannels,
  ...influencersChannels,
  ...analyticsChannels,
  ...surveyChannels,
  ...platformShopChannels,
};

export const journeysChannels: any = Object.entries<any>(allChannels)
  .filter(([id, channel]) => channel.general.showInJourney === true)
  .reduce((acc, [id, channel]) => ({ ...acc, [id]: channel }), {});
