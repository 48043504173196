import { v4 as uuidV4 } from 'uuid';
import { Timestamp } from 'utils/DB';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router';
import moment from 'moment-timezone';
import { useStoreValue, useWritableStore } from '@tw/snipestate';
import {
  Menu,
  ActionIcon,
  Button,
  confirm,
  Flex,
  Icon,
  Text,
  Loader,
  MobileDrawer,
  Tooltip,
  Checkbox,
} from '@tw/ui-components';
import DropDown from 'components/ltv/DropDown';
import { useCallback, useMemo, useState } from 'react';
import { MobileVerticalDotsMajor } from '@shopify/polaris-icons';
import { Popover } from '@shopify/polaris';
import {
  analyticsEvents,
  chatActions,
  genericEventLogger,
  sequencesActions,
} from 'utils/dataLayer';
import axiosInstance from 'utils/axiosInstance';
import _db, { firestoreRef } from 'utils/DB';
import { $currentShopId } from '$stores/$shop';
import { $shopSequences } from '$stores/willy/$sequences';
import { $dialect, $isTwGlobalDashboardCreatorClaim } from '$stores/$user';
import { useFilteredItems } from './hooks/useFilteredItems';
import { WillySearchInput } from './WillySearchInput';
import { WillyEmoji } from './types/emojiTypes';
import { emptyEmoji } from './dashboardManagment//WillyDashDescription';
import { createNewSequence, CreateNewSequenceProps, duplicateSequence } from './utils/sequences';
import { WillyElementType, WillyDataSequence } from './types/willyTypes';
import { WillySelect } from './dashboardManagment/WillySelect';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';

export type SequencesListProps = {
  sequenceId?: string;
  opened?: boolean;
  setOpened?: React.Dispatch<React.SetStateAction<boolean>>;
  setRunsOpen?: React.Dispatch<React.SetStateAction<string | undefined>>;
  isPage?: boolean;
};

const userFilterOptions = [
  { value: 'all', label: 'All' },
  { value: 'active', label: 'Active' },
  { value: 'inactive', label: 'Inactive' },
  { value: 'user', label: 'My Workflows' },
];

const userSortOptions = [
  { value: 'edited', label: 'Recently Edited' },
  { value: 'created', label: 'Recently Added' },
];

export const SequencesList: React.FC<SequencesListProps> = ({
  setOpened = () => {},
  setRunsOpen = () => {},
  isPage,
}) => {
  const { search } = useLocation();
  const isSmall = useIsSmall();
  const navigate = useNavigate();
  const [sequences, setSequences] = useWritableStore($shopSequences);
  const currentShopId = useStoreValue($currentShopId);
  const dialect = useStoreValue($dialect);
  const isTwGlobalDashboardCreatorClaim = useStoreValue($isTwGlobalDashboardCreatorClaim);

  const params = new URLSearchParams(search);
  const defaultFilter = params.get('workflowsFilter') || userFilterOptions[0].value;
  const defaultSort = params.get('workflowsSort') || userSortOptions[0].value;
  const [deleting, setDeleting] = useState(false);
  const [userFilter, setUserFilter] = useState(defaultFilter);
  const [userSort, setUserSort] = useState(defaultSort);
  const [freeSearch, setFreeSearch] = useState('');

  const filteredSequences = useFilteredItems(sequences, freeSearch, ['name', 'id'], userFilter);
  const sortedSequences = useMemo(() => {
    return filteredSequences.sort((a, b) => {
      if (userSort === 'edited') {
        const aTime = a.updatedAt?.seconds * 1000 + a.updatedAt?.nanoseconds / 1000000;
        const bTime = b.updatedAt?.seconds * 1000 + b.updatedAt?.nanoseconds / 1000000;
        return bTime - aTime;
      } else if (userSort === 'created') {
        const aTime = a.createdAt?.seconds * 1000 + a.createdAt?.nanoseconds / 1000000;
        const bTime = b.createdAt?.seconds * 1000 + b.createdAt?.nanoseconds / 1000000;
        return bTime - aTime;
      }
      return 0;
    });
  }, [filteredSequences, userSort]);

  const hasSearchFilterOrSort = useMemo(() => {
    return (
      freeSearch !== '' ||
      userFilter !== userFilterOptions[0].value ||
      userSort !== userSortOptions[0].value
    );
  }, [freeSearch, userFilter, userSort]);

  const updateUrlParams = useCallback(
    (key: string, value: string) => {
      const params = new URLSearchParams(search);
      params.set(key, value);

      navigate({
        pathname: location.pathname,
        search: params.toString(),
      });
    },
    [navigate, search],
  );

  const handleFilterChange = (value: string) => {
    setUserFilter(value);
    updateUrlParams('workflowsFilter', value);
  };

  const handleSortChange = (value: string) => {
    setUserSort(value);
    updateUrlParams('workflowsSort', value);
  };

  const toggleSequenceActionMenu = useCallback(
    (id: string, open: boolean) => {
      setSequences((old) => {
        return old.map((x) => {
          return {
            ...x,
            actionsMenuOpen: x.id === id ? open : false,
          };
        });
      });
    },
    [setSequences],
  );

  const deleteSequences = useCallback(
    async (sequences: WillyDataSequence[]) => {
      const promises: Promise<void>[] = [];
      setDeleting(true);
      for (const sequence of sequences) {
        const ref = sequence.isGlobal
          ? firestoreRef().collection('global_data_sequences')
          : _db().collection('data_sequences');

        if (!sequence.isGlobal) {
          const runs = await ref.doc(sequence.id).collection('runs').get();
          runs.forEach((run) => {
            promises.push(run.ref.delete());
          });
        }

        promises.push(ref.doc(sequence.id).set({ deleted: true }, { merge: true }));
        if (sequence.globalDashboardId) {
          promises.push(
            axiosInstance.post('v2/willy/update-stats', {
              shopId: currentShopId,
              sequenceId: sequence.globalDashboardId,
              actionType: 'uninstalled',
            }),
          );
        }
      }
      await Promise.all(promises);
      setDeleting(false);
    },
    [currentShopId],
  );

  const menuItems = useCallback(
    (c) => [
      {
        content: 'View Details',
        onAction: () => {
          navigate({
            pathname: `/workflows/${c.id}`,
          });
          setOpened(false);
        },
      },
      {
        content: 'View Last Run',
        onAction: () => {
          navigate({
            pathname: `/workflows/view/${c.id}`,
          });
          setOpened(false);
        },
      },
      {
        content: 'Edit Workflow',
        // icon: () => <Icon name="edit" />,
        disabled: !c.canEdit,
        onAction: () => {
          navigate({
            pathname: `/workflows/create/${c.id}`,
          });
          setOpened(false);
        },
      },
      {
        content: 'Rename Workflow',
        // icon: () => <Icon name="refresh" />,
        disabled: !c.canEdit,
        onAction: () => {
          setSequences((old) => {
            return old.map((x) => {
              if (x.id === c.id) {
                return {
                  ...x,
                  renameMode: true,
                };
              }
              return x;
            });
          });
        },
      },
      {
        content: 'Run Workflow',
        // icon: () => <Icon name="play-arrow" />,
        onAction: () => {
          const sequence = sequences.find((s) => s.id === c.id);
          if (!sequence) {
            return;
          }

          const params = new URLSearchParams(search);

          params.set('run', 'true');
          navigate({
            pathname: `/workflows/create/${sequence.id}`,
            search: params.toString(),
          });

          setOpened(false);

          genericEventLogger(analyticsEvents.CHAT, {
            action: chatActions.RUN_SEQUENCE,
            id: sequence.id,
            text: sequence.name || '',
            conversationId: sequence.id,
          });
        },
      },
      {
        content: 'Duplicate Workflow',
        onAction: async () => {
          const sequence = sequences.find((s) => s.id === c.id);
          if (!sequence) {
            return;
          }

          const conf = await confirm({
            title: 'Duplicate Workflow?',
            message: 'This will make a copy of the current workflow.',
          });
          if (!conf) return;

          const { error, success, message, conversationData } = await duplicateSequence(sequence);

          if (error) {
            toast.error(error);
          } else if (success) {
            genericEventLogger(analyticsEvents.SEQUENCES, {
              action: sequencesActions.DUPLICATE_SEQUENCE,
              sequence_id: conversationData?.id,
              sequence_name: conversationData?.name,
            });
            toast.success(message);
          }

          toggleSequenceActionMenu(c.id, false);
        },
      },
      // {
      //   content: 'Last Runs',
      //   // icon: () => <Icon name="clock" />,
      //   onAction: () => {
      //     setOpened(false);
      //     setRunsOpen(c.id);
      //   },
      // },
      ...(isTwGlobalDashboardCreatorClaim
        ? [
            {
              content: 'Turn Into Template',
              // icon: () => <Icon name="global" />,
              onAction: async () => {
                const conf = await confirm({
                  title: 'Turn Workflow into template?',
                  message:
                    'This will make a copy in its current state, and publish to the template library.',
                });
                if (!conf) return;

                const newSequenceTemplate: CreateNewSequenceProps = {
                  shopId: currentShopId!,
                  userId: c.user,
                  dialect: c.dialect ?? dialect,
                  conversationId: c.conversationId,
                  messages: c.steps ?? c.messages ?? [],
                  baseMainElement: {
                    id: uuidV4(),
                    type: 'sequence' as WillyElementType,
                    canEdit: false,
                    isGlobal: true,
                    createdAt: Timestamp.now(),
                    updatedAt: Timestamp.now(),
                    name: c.name ?? '',
                    description: c.description ?? '',
                    emoji: c.emoji ?? (emptyEmoji as WillyEmoji),
                    roles: c.roles ?? [],
                    isBeta: c.isBeta ?? false,
                    isHide: c.isHide ?? false,
                    image: c.image,
                    category: c.category ?? '',
                    dialect: c.dialect ?? dialect ?? 'clickhouse',
                    providers: c.providers ?? [],
                    providersBlockingCombination: c.providersBlockingCombination ?? 'AND',
                    packages: c.packages ?? [],
                    defaultPackages: c.defaultPackages ?? [],
                    msps: c.msps ?? '',
                  },
                };

                const { error, success, message, conversationData } =
                  await createNewSequence(newSequenceTemplate);

                if (error) {
                  toast.error(error);
                } else if (success) {
                  genericEventLogger(analyticsEvents.SEQUENCES, {
                    action: sequencesActions.CREATE_SEQUENCE,
                    sequence_id: conversationData?.id,
                    sequence_name: conversationData?.title,
                  });
                  toast.success(message);
                }

                toggleSequenceActionMenu(c.id, false);
              },
            },
          ]
        : []),
    ],
    [
      isTwGlobalDashboardCreatorClaim,
      navigate,
      setOpened,
      setSequences,
      sequences,
      search,
      toggleSequenceActionMenu,
      currentShopId,
      dialect,
    ],
  );

  const scheduleText = useCallback((c: WillyDataSequence) => {
    if (!c.schedule) return '-';

    if (c.schedule.status === 'running') return 'Running';
    if (c.schedule.status === 'paused') return 'Paused';
    if (c.schedule.status === 'scheduled') {
      const now = moment();
      const nextRun = moment();
      const daysOfWeek = [
        'sunday',
        'monday',
        'tuesday',
        'wednesday',
        'thursday',
        'friday',
        'saturday',
      ];

      // Find the next scheduled day
      let daysUntilNext = 7;
      for (const day of c.schedule.days) {
        const dayIndex = daysOfWeek.indexOf(day.toLowerCase());
        const daysUntil = (dayIndex - now.day() + 7) % 7;
        if (daysUntil < daysUntilNext) {
          daysUntilNext = daysUntil;
        }
      }

      nextRun.add(daysUntilNext, 'days');

      // Set the hour for the next run
      const scheduledHours = c.schedule.hours.map(Number);
      scheduledHours.sort((a, b) => a - b);

      let nextHour = scheduledHours[0] || 0;
      for (const hour of scheduledHours) {
        if (daysUntilNext === 0 && hour > now.hour()) {
          nextHour = hour;
          break;
        }
      }

      nextRun.hour(nextHour).minute(0).second(0);

      // If nextRun is in the past, find the next available hour
      if (nextRun.isBefore(now)) {
        const nextHourIndex = scheduledHours.find((hour) => hour > now.hour());
        if (nextHourIndex !== undefined) {
          nextRun.hour(nextHourIndex).minute(0).second(0);
        } else {
          // If no future hour today, move to the first hour of the next scheduled day
          nextRun.add(1, 'days').hour(scheduledHours[0]).minute(0).second(0);
        }
      }

      return `${nextRun.format('MMM D, h:mm A')}`;
    }
  }, []);

  const scheduleDate = useCallback((c: WillyDataSequence) => {
    const date = c.updatedAt ?? c.createdAt;
    return moment(date?.seconds * 1000 + date?.nanoseconds / 1000000)?.fromNow();
  }, []);

  const [bulkActionItems, setBulkActionItems] = useState<WillyDataSequence[]>([]);
  const showNewDesign = useMemo(() => isPage && !isSmall, [isPage, isSmall]);

  return (
    <div className="flex flex-col overflow-hidden h-full">
      <div className={`${showNewDesign ? 'p-8 pb-0' : 'p-4'} flex gap-4 w-full`}>
        {showNewDesign ? (
          <div className="w-full flex gap-6.5">
            <div className="w-full flex items-center gap-4">
              <WillySearchInput
                value={freeSearch}
                onChange={(v) => setFreeSearch(v)}
                placeholder="Search Workflows"
                className="!p-0 overflow-visible w-full"
              />
              {hasSearchFilterOrSort && (
                <div className="flex-shrink-0">
                  <Button
                    variant="white"
                    onClick={() => {
                      setFreeSearch('');
                      handleFilterChange(userFilterOptions[0].value);
                      handleSortChange(userSortOptions[0].value);
                    }}
                  >
                    Clear
                  </Button>
                </div>
              )}
            </div>
            <div className="flex items-center justify-between flex-shrink-0 gap-4">
              <WillySelect
                data={userFilterOptions.map((item) => ({ value: item.value, label: item.label }))}
                value={userFilter}
                onChange={handleFilterChange}
                targetClassName="mr-4"
              />
              <WillySelect
                data={userSortOptions.map((item) => ({ value: item.value, label: item.label }))}
                value={userSort}
                onChange={handleSortChange}
              />
            </div>
          </div>
        ) : (
          <>
            <WillySearchInput
              value={freeSearch}
              onChange={(v) => setFreeSearch(v)}
              placeholder="Search Workflows"
              className="!p-0 overflow-visible w-full"
            />
            <DropDown
              handleSelect={(v) => setUserFilter(v)}
              options={userFilterOptions}
              value={userFilter}
            />
          </>
        )}
      </div>
      <div>
        {!sequences.length && !isPage && (
          <div className="p-4">
            <p>There are no workflows yet. Create one to get started</p>
          </div>
        )}
      </div>

      {!isPage && (
        <div className="flex items-center gap-4 w-full justify-start p-4 pt-0 border-b border-gray-100">
          <Button
            onClick={() => {
              navigate({
                pathname: `/workflows/create`,
              });
            }}
            leftSection="plus-1"
            iconSize={20}
          >
            New Workflow
          </Button>
          <Link
            to={{
              pathname: '/workflows',
              search: '?filter=templates',
            }}
            className="no-underline"
          >
            <Button leftSection={<Icon color="white" name="button-play" />}>
              Workflow Library
            </Button>
          </Link>
        </div>
      )}
      <div
        className={`overflow-auto h-full ${!showNewDesign ? 'h-[calc(100vh-312px)] sm:h-full' : ''}`}
      >
        <div className={showNewDesign ? 'w-full min-w-[768px]' : ''}>
          {showNewDesign && (
            <div className="grid grid-cols-2 gap-4 w-full pt-14 px-8">
              <div className="flex items-center justify-between h-10">
                {bulkActionItems.length > 0 && (
                  <Button
                    loading={deleting}
                    leftSection="trash"
                    variant="white"
                    size="xs"
                    onClick={async () => {
                      const conf = await confirm({
                        title: 'Delete selected workflows?',
                        message: 'This action cannot be undone',
                      });
                      if (!conf) return;
                      await deleteSequences(bulkActionItems);
                      setBulkActionItems([]);
                    }}
                  >
                    Delete Selected
                  </Button>
                )}
              </div>
              <div className="grid grid-cols-4 gap-4">
                <Text color="gray.5" fw="500" size="sm">
                  Active
                </Text>
                <Text color="gray.5" fw="500" size="sm">
                  Scheduled
                </Text>
                <Text color="gray.5" fw="500" size="sm">
                  Last Edited
                </Text>
                <div className="text-right">
                  <Text color="gray.5" fw="500" size="sm">
                    Actions
                  </Text>
                </div>
              </div>
            </div>
          )}
          <div className={`${showNewDesign ? 'p-8' : 'p-4'} flex flex-col gap-6 w-full`}>
            {sortedSequences.length === 0 && (
              <div className="p-4">
                <p>No workflows found</p>
              </div>
            )}
            {sortedSequences.length > 0 && (
              <>
                {sortedSequences.map((c, i) => (
                  <div
                    key={i}
                    className={`justify-between items-center gap-4 overflow-hidden group/conversation-item w-full ${showNewDesign ? 'grid grid-cols-2 p-4 shadow-sm' : 'flex'} ${showNewDesign ? 'rounded-lg border-[1px] border-solid border-gray-200 bg-[#f9fafb]' : ''}`}
                    onClick={(e) => {
                      if (c.renameMode) {
                        e.stopPropagation();
                        e.preventDefault();
                        return;
                      }
                    }}
                    onDoubleClick={(e) => {
                      if (c.renameMode) {
                        e.stopPropagation();
                        e.preventDefault();
                        return;
                      }
                      setSequences((old) => {
                        return old.map((x) => {
                          if (x.id === c.id) {
                            return {
                              ...x,
                              renameMode: true,
                            };
                          }
                          return x;
                        });
                      });
                    }}
                    onKeyDown={(e) => {
                      if (c.renameMode) {
                        e.stopPropagation();
                        e.preventDefault();
                      }
                    }}
                    onKeyUp={(e) => {
                      if (c.renameMode) {
                        e.stopPropagation();
                        e.preventDefault();
                      }
                    }}
                  >
                    <div className="flex items-center gap-4 text-ellipsis whitespace-nowrap w-full max-w-[90%]">
                      <Checkbox
                        checked={bulkActionItems.some((x) => x.id === c.id)}
                        onChange={(checked) => {
                          if (checked) {
                            setBulkActionItems((old) => [...old, c]);
                          } else {
                            setBulkActionItems((old) => old.filter((x) => x.id !== c.id));
                          }
                        }}
                      />
                      {!showNewDesign && (
                        <>
                          {!c.schedule && (
                            <div className="flex-shrink-0 flex">
                              <Tooltip label="No schedule for this sequence yet">
                                <Icon name="minus" color="gray.5" />
                              </Tooltip>
                            </div>
                          )}
                          {!!c.schedule && (
                            <div className="flex items-center">
                              {c.schedule.status === 'running' && (
                                <Tooltip label="Scheduled sequence is running">
                                  <Loader size="xs" />
                                </Tooltip>
                              )}
                              {c.schedule.status === 'error' && (
                                <Tooltip
                                  label={`Scheduled sequence has an error: ${
                                    c.schedule.error || 'Unknown error'
                                  }`}
                                >
                                  <Icon name="info" color="red.5" />
                                </Tooltip>
                              )}
                              {(c.schedule.status === 'scheduled' ||
                                c.schedule.status === 'skipped') && (
                                <Tooltip
                                  label={`Waiting for the next scheduled run ${
                                    c.schedule.status === 'skipped'
                                      ? '(last run skipped due to rules)'
                                      : ''
                                  }`}
                                >
                                  <Icon name="clock" />
                                </Tooltip>
                              )}
                              {c.schedule.status === 'paused' && (
                                <Tooltip label="Scheduled sequence is paused">
                                  <Icon name="play-arrow" />
                                </Tooltip>
                              )}
                            </div>
                          )}
                        </>
                      )}
                      <div
                        className={`focus:outline ${
                          c.renameMode
                            ? 'overflow-auto text-clip'
                            : 'overflow-hidden text-ellipsis whitespace-nowrap cursor-pointer'
                        } ${showNewDesign ? 'p-4 font-[500]' : ''}`}
                        contentEditable={c.renameMode}
                        suppressContentEditableWarning
                        ref={(el) => {
                          if (!el) return;
                          if (c.renameMode) {
                            const range = document.createRange();
                            range.selectNodeContents(el);
                            const selection = window.getSelection();
                            selection?.removeAllRanges();
                            selection?.addRange(range);

                            el.focus();
                          } else {
                            el.blur();
                          }
                        }}
                        onClick={(e) => {
                          if (e.detail === 2) {
                            return;
                          }
                          if (c.renameMode) {
                            e.stopPropagation();
                            e.preventDefault();
                            return;
                          }
                          navigate({
                            pathname: `/workflows/create/${c.id}`,
                          });
                        }}
                        onBlur={(e) => {
                          const ref = c.isGlobal
                            ? firestoreRef().collection('global_data_sequences')
                            : _db().collection('data_sequences');
                          ref.doc(c.id).set({ title: e.target.textContent }, { merge: true });

                          setSequences((old) => {
                            return old.map((x) => {
                              return {
                                ...x,
                                renameMode: false,
                              };
                            });
                          });
                        }}
                        onKeyDown={(e) => {
                          e.stopPropagation();
                          if (e.key === 'Enter') {
                            e.preventDefault();
                            e.currentTarget.blur();
                          } else if (e.key === 'Escape') {
                            e.preventDefault();
                            e.currentTarget.textContent = c.name || 'Untitled';
                            e.currentTarget.blur();
                          }
                        }}
                      >
                        {c.name || 'Untitled'}
                      </div>
                    </div>

                    <div
                      className={`${showNewDesign ? 'grid grid-cols-4 gap-4 items-center' : ''}`}
                    >
                      {showNewDesign && (
                        <>
                          <div className="p-4 !pointer-events-none flex items-center">
                            <span
                              className={`w-4 h-4 rounded-full flex-shrink-0 ml-2 ${
                                !!c.schedule &&
                                (c.schedule.status === 'scheduled' ||
                                  c.schedule.status === 'skipped' ||
                                  c.schedule.status === 'running')
                                  ? 'bg-[#3acf00]'
                                  : 'bg-gray-400'
                              }`}
                            >
                              <Tooltip label={c.schedule?.status ?? 'Disabled'}>
                                <div className="w-full h-full"></div>
                              </Tooltip>
                            </span>
                          </div>
                          <div className="whitespace-nowrap font-[500] text-ellipsis overflow-hidden">
                            {scheduleText(c!)}
                          </div>
                          <div className="whitespace-nowrap font-[500] text-ellipsis overflow-hidden">
                            {scheduleDate(c!)}
                          </div>
                        </>
                      )}

                      <div
                        className="ml-auto"
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                        }}
                      >
                        <Popover
                          active={!!c.actionsMenuOpen}
                          onClose={() => {
                            toggleSequenceActionMenu(c.id, false);
                          }}
                          activator={
                            <MobileVerticalDotsMajor
                              className="flex-shrink-0 flex items-center cursor-pointer opacity-100 fill-gray-500 dark:fill-gray-300 w-8"
                              onClick={(e) => {
                                e.stopPropagation();
                                toggleSequenceActionMenu(c.id, !c.actionsMenuOpen);
                              }}
                            />
                          }
                        >
                          <Menu shadow="md" bg="white">
                            {menuItems(c).map((item) => (
                              <Menu.Item
                                key={item.content}
                                onClick={() => {
                                  item.onAction();
                                  toggleSequenceActionMenu(c.id, false);
                                }}
                                disabled={item.disabled}
                              >
                                <Flex gap="sm" align="center">
                                  {/* {item.icon()} */}
                                  {item.content}
                                </Flex>
                              </Menu.Item>
                            ))}
                            <Menu.Divider />
                            <Menu.Item
                              key="Delete Workflow"
                              onClick={async () => {
                                const conf = await confirm({
                                  title: 'Delete workflows?',
                                  message: 'This action cannot be undone',
                                });
                                if (!conf) return;
                                await deleteSequences([c]);
                                toggleSequenceActionMenu(c.id, false);

                                toast.success('Workflow deleted successfully');
                              }}
                              disabled={!c.canEdit}
                            >
                              <Flex gap="sm" align="center">
                                Delete Workflow
                              </Flex>
                            </Menu.Item>
                          </Menu>
                        </Popover>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

type SequencesListModalProps = {
  isSmall?: boolean;
} & SequencesListProps;

export const SequencesListModal: React.FC<SequencesListModalProps> = ({
  isSmall,
  opened = false,
  setOpened = () => {},
  ...props
}) => {
  const activator = useMemo(
    () => <ActionIcon iconSize={20} icon="button-play" onClick={() => setOpened((x) => !x)} />,
    [setOpened],
  );

  if (isSmall) {
    return (
      <>
        {activator}
        <MobileDrawer
          title="Workflows"
          onClose={() => setOpened?.(false)}
          opened={opened}
          position="bottom"
          withinPortal
        >
          <Flex direction="column" gap="lg" overflow="auto" h="100%">
            <SequencesList opened={opened} setOpened={setOpened} {...props} />
          </Flex>
        </MobileDrawer>
      </>
    );
  }

  return (
    <div>
      <Popover
        active={opened}
        onClose={() => setOpened(false)}
        activator={
          <div>
            <Tooltip label="Workflows">{activator}</Tooltip>
          </div>
        }
      >
        <SequencesList opened={opened} setOpened={setOpened} {...props} />
      </Popover>
    </div>
  );
};
