import { $observer } from '@tw/snipestate';
import { toArray } from 'utils/DB';
import 'firebase/firestore';
import firebase from 'firebase/compat/app';
import { $userId } from '$stores/$user';
import { CategoryListItem } from 'components/Willy/dashboardManagment/category-management/types';

export type Category = {
  id: string;
  name: string;
  order: number;
};

export const $globalDashboardCategories = $observer(
  { loading: true, data: [] as Category[] },
  (get, set) => {
    const userId = get($userId);
    if (!userId) return;

    return firebase
      .firestore()
      .collection('willy_global_dashboard_categories')
      .onSnapshot((querySnapshot) => {
        const arr: Category[] = toArray(querySnapshot).sort((x, y) => x.order - y.order);

        const dashboardCategories = [
          {
            id: 'general',
            name: 'All Reports',
            order: 0,
          },
          ...arr,
        ];

        set({ loading: false, data: dashboardCategories });
      });
  },
);

/** global dashboard categories as CategoryListItem[] */
export const $formattedGlobalCategories = $observer(
  { loading: true, data: [] as CategoryListItem[] },
  (get, set) => {
    const { loading, data } = get($globalDashboardCategories);
    if (loading) return;

    const globalDashCatsHL = data.map((c) => {
      return {
        id: c.id,
        value: c.name || '',
        children: [],
        type: 'folder',
        depth: 0,
      } satisfies CategoryListItem;
    });

    set({ loading: false, data: globalDashCatsHL });
  },
);
