import { facebookConnectOnPress } from '../../ducks/facebook';

export const UNMATCHED_RATE_TOO_HIGH_TOOLTIP = () => (
  <span>
    Your number of unmatched orders is too high. Please reach out to our customer service team via
    the live chat in the bottom right-hand corner.
  </span>
);
export const UNMATCHED_RATE_TOO_HIGH_POPUP_TOOLTIP =
  'Your number of unmatched orders is too high. Save your rule as a draft, then reach out to our customer service team via the live chat in the bottom right-hand corner.';
export const MISSING_PERMISSION_TOOLTIP = (shopId: string, withLink: boolean) => (
  <span>
    Please provide Triple Whale with the correct Facebook permissions to enable the rules.
    {withLink && (
      <>
        {' '}
        <br />
        <div onClick={() => facebookConnectOnPress()}>Click here</div> to do it.
      </>
    )}
  </span>
);
