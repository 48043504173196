import {
  ActionIcon,
  Container,
  Flex,
  Grid,
  IconName,
  Popover,
  Text,
  Tooltip,
} from '@tw/ui-components';
import { FC, useMemo, useState } from 'react';
import {
  ChartType,
  ChartTypeItem,
  ChartWithComboType,
  MessageTypeElement,
  MessageTypes,
  WillyChartLayout,
  WillyMetric,
} from '../types/willyTypes';
import { ReactComponent as DecorativeComboChart } from '../icons/comboChart.svg';

type WidgetVisualizationPopoverProps = {
  queryId?: string;
  widgetType: MessageTypes;
  metrics: WillyMetric[];
  updateMetrics: (id: string, data: WillyMetric[]) => void;
  activator?: (
    onClick: () => void,
    value?: string,
    icon?: IconName | JSX.Element,
  ) => React.ReactNode;
  onChange: (type: MessageTypes) => void;
  availableTypes: {
    chart: {
      label: string;
      items: ChartTypeItem<ChartWithComboType>[];
    };
    other: {
      label: string;
      items: MessageTypeElement<MessageTypes>[];
    };
  };
  stacked: boolean;
  setStacked?: (val: boolean) => void;
  chartLayout?: WillyChartLayout;
  setChartLayout?: (layout: WillyChartLayout) => void;
};

const comboChartItem: ChartTypeItem<'combo'> = {
  id: 'combo',
  title: 'Combo',
  icon: 'combo-chart',
  decorativeIcon: <DecorativeComboChart />,
};

export const WidgetVisualizationPopover: FC<WidgetVisualizationPopoverProps> = ({
  queryId,
  widgetType,
  metrics,
  updateMetrics,
  activator,
  onChange,
  availableTypes,
  stacked,
  setStacked,
  chartLayout,
  setChartLayout,
}) => {
  const [opened, setOpened] = useState<boolean>(false);
  const widgetTypeElement = useMemo(
    () => availableTypes.other.items.find((type) => type.id === widgetType),
    [availableTypes.other.items, widgetType],
  );

  const chartTypeElement: ChartTypeItem<ChartWithComboType> | undefined = useMemo(() => {
    if (widgetType !== 'chart') return;
    let chartType: ChartWithComboType = 'combo';
    const isAllMetricsWithSameChartType = metrics.every(
      (metric) => metric.chartType === metrics[0]?.chartType,
    );
    if (isAllMetricsWithSameChartType && metrics[0]?.chartType) {
      chartType = metrics[0]?.chartType;
    }
    if (chartType === 'bar' && stacked) {
      chartType = 'stacked-bar';
    }
    if (chartLayout === 'vertical' && chartType === 'bar') {
      chartType = 'horizontal-bar';
    }
    if (chartType === 'combo') {
      return comboChartItem;
    }
    return availableTypes.chart.items.find((type) => type.id === chartType);
  }, [availableTypes.chart.items, chartLayout, metrics, stacked, widgetType]);

  const handleTypeChange = (type: MessageTypes, chartType?: ChartType) => {
    onChange(type);
    if (type === 'chart' && chartType) {
      if (!queryId) return;
      let newMetrics = metrics.map((x) => ({ ...x, chartType }));
      if (chartType === 'bar' && stacked) {
        setStacked?.(false);
      }
      if (chartType !== 'horizontal-bar' && chartLayout === 'vertical') {
        setChartLayout?.('horizontal');
      }
      if (chartType === 'stacked-bar') {
        setStacked?.(true);
        newMetrics = newMetrics.map((x) => ({ ...x, yAxisId: 'left', chartType: 'bar' }));
      }
      if (chartType === 'horizontal-bar') {
        setChartLayout?.('vertical');
        newMetrics = newMetrics.map((x) => ({ ...x, yAxisId: 'left', chartType: 'bar' }));
      }
      updateMetrics(queryId, newMetrics);
    }
  };

  return (
    <Popover
      opened={opened}
      onChange={() => {
        setOpened(!opened);
      }}
      shadow="md"
    >
      <Tooltip label="Visualization Type">
        <Popover.Target>
          {activator ? (
            activator(
              () => setOpened((prev) => !prev),
              chartTypeElement ? chartTypeElement.title : widgetTypeElement?.title,
              chartTypeElement ? chartTypeElement.icon : widgetTypeElement?.icon,
            )
          ) : (
            <ActionIcon
              icon={chartTypeElement ? chartTypeElement.icon : widgetTypeElement?.icon}
              onClick={() => setOpened((prev) => !prev)}
            />
          )}
        </Popover.Target>
      </Tooltip>
      <Popover.Dropdown maw={700} overflow="auto">
        <Grid gutter="xl">
          {Object.values(availableTypes).map((mainType) => {
            return (
              <Grid.Col span={6} key={mainType.label}>
                <Flex direction="column" gap="md" key={`main-type-${mainType.label}`} pb="xs">
                  <Text size="sm" weight={500} color="gray.8">
                    {mainType.label}
                  </Text>
                  <Grid gutter="lg">
                    {mainType.items
                      .filter((item) => !!item.decorativeIcon)
                      .concat(
                        mainType.label === 'CHART' && chartTypeElement?.id === 'combo'
                          ? [comboChartItem]
                          : [],
                      )
                      .map(
                        (
                          item:
                            | ChartTypeItem<ChartWithComboType>
                            | MessageTypeElement<MessageTypes>,
                        ) => {
                          return (
                            <Grid.Col span={6} key={item.id}>
                              <Container
                                key={item.id}
                                onClick={() => {
                                  if (item.id === 'combo') return;
                                  const vars =
                                    mainType.label === 'CHART'
                                      ? {
                                          type: 'chart' as MessageTypes,
                                          chartType: item.id as ChartType,
                                        }
                                      : { type: item.id as MessageTypes };
                                  handleTypeChange(vars.type, vars.chartType);
                                  setOpened(false);
                                }}
                                className={`!p-8 relative flex items-center justify-center flex-col cursor-pointer border border-solid ${
                                  (chartTypeElement ?? widgetTypeElement)?.id === item.id
                                    ? 'border-[--secondary-600]'
                                    : 'border-[--gray-light-mode-300]'
                                } rounded`}
                                //w={130}
                                h={80}
                                mx={0}
                              >
                                {item.decorativeIcon}
                                <Text
                                  size="xs"
                                  weight={500}
                                  bg="white"
                                  px="xs"
                                  pos="absolute"
                                  top="90%"
                                >
                                  {item.title}
                                </Text>
                              </Container>
                            </Grid.Col>
                          );
                        },
                      )}
                  </Grid>
                </Flex>
              </Grid.Col>
            );
          })}
        </Grid>
      </Popover.Dropdown>
    </Popover>
  );
};
