import React, {
  Component,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  AnswerNlqQuestionParams,
  Conversation,
  Message,
  FormattedDashboardDataElement,
  FormattedDashboardData,
  FormattedSummaryData,
  FormattedPixelData,
  WillyPrompt,
} from './types/willyTypes';
import { AlanLoaderGray } from 'components/AlanLoader';
import { Allotment } from 'allotment';
import { v4 as uuidV4 } from 'uuid';
import {
  analyticsEvents,
  genericEventLogger,
  willyActions,
  chatActions,
  dashboardsActions,
  sqwhaleActions,
} from 'utils/dataLayer';
import {
  answerNlqQuestion,
  createMessageFromDb,
  getColumnsFromMessage,
  getMetricsDictionary,
  getPaginatedPrompts,
  getPromptsList,
  getQueryIdFromMessage,
  getShopReportPrompts,
  getSqlFromMessage,
} from './utils/willyUtils';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';
import { RootState, useAppSelector } from 'reducers/RootType';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { useDarkMode } from 'dark-mode-control';
import { ChevronDownMinor, ChevronUpMinor } from '@shopify/polaris-icons';
import { useWindowSize } from 'utils/useWindowSize';
import { Mention, MentionsInputProps } from 'react-mentions';
import axiosInstance from 'utils/axiosInstance';
import { User } from 'components/UserProfileManagment/User/constants';
import _db from 'utils/DB';
import { useWillySocket } from './WillySocket';
import { WillyMessageTemplate } from './WillyMessageTemplate';
import { isMobileApp } from 'utils/Device';
import ScrollToBottom, { useAtEnd } from 'react-scroll-to-bottom';
import { WillyScrollToBottom, WillyScrollToBottomRef } from './WillyScrollToBottom';
import { WillySuggestQuestions } from './WillySuggestQuestions';
import { useSelector } from 'react-redux';
import { Tooltip, Text, Anchor, NumRange, colors } from '@tw/ui-components';
import { useAppDispatch } from 'index';
import { ChatIcon } from 'components/Icons/ChatIcon';
import { $activeAccounts, $currency, $industry } from '../../$stores/$shop';
import { $dialect, $isAdminClaim, $userShop } from '$stores/$user';
import { fromPairs, shuffle } from 'lodash';
import { useChatSocket } from './hooks/useChatSocket';
import { useComputedValue, useStoreValue, useWritableStore } from '@tw/snipestate';
import {
  PreloadDashboardData,
  MentionedUser,
  MetricDict,
  WillyMainChatProps,
  WillyMainChatRef,
} from 'components/Willy/types/willyTypes';
import { formatNumber } from 'utils/formatNumber';
import { RoundingOptionsMapper } from 'ducks/summary';
import { BaseSummaryMetric, SummaryMetricIdsTypes } from '@tw/types/module/SummaryMetrics';
import { emptyArray } from 'utils/emptyArray';
import { $shopSequences } from '$stores/willy/$sequences';
import { useSequenceSocket } from './hooks/useSequenceSocket';
import { SequenceRunsModal } from './utils/SequenceRuns';
import {
  $fromPremiumPlusToMobyModal,
  $shouldShowMobyUpgradeCtas,
  $showFromPremiumPlusToMobyModal,
  $showMobyPopup,
  openUnlockMobyModal,
} from '$stores/willy/$upgradePopupManager';
import { LLMModel, userFilterOptions, ForecastModel, FORECAST_MODELS } from './constants';
import { useConversationsStore } from '$stores/willy/$conversation';
import { WillyMainChatModelSelectors } from './WillyMainChatModelSelectors';
import { WillyMainChatForecastModelSelector } from './WillyMainChatForecastModelSelector';
import { useUpgradeButton } from '../UpgradePlan/components/useUpgradeButton';
import { ChatInput } from './ChatInput';
import { WillyChatLP } from './WillyChatLP';
import { $mainChatStore, setSqlGeneratingModel } from '$stores/willy/$mainChat';
import { WillyMainChatWrapper } from './WillyMainChatWrapper';
import { $shopWithSensory } from '$stores/$shopWithSensory';
import { services } from '@tw/types/module/services';
import { gradualReleaseFeatures } from 'ducks/shop';
import { WillyScrollQuestionsLP } from './WillyScrollQuestionsLP';

const chatTitleCarouselOptions = [
  'What can we do for you today?',
  'Analyze your data',
  'Discover insights',
  'Get recommendations',
  'Write new copy',
];

const carouselHeight = 30;

const mainChatPillPrompts = [
  {
    name: 'Prompt Guide',
    link: 'https://kb.triplewhale.com/en/articles/9117737-prompt-guide',
    prompt:
      'Give me tips and best practices on how to prompt Moby, including:\n1) What categories of information should I include in my prompts?\n2) How can I specify metrics from the Triple Whale Metric Dictionary?\n3) What sources are available for getting inspiration for prompts?\n4) What should I do if I get a bad response?\n5) When should I start a new conversation?\n6) What’s the best way to ask for analysis, insights, or recommendations?\n7) Can I ask questions about Triple Whale, the pixel, or attribution?\n8) How can I get forecasts?\n\nGet information from the URL https://kb.triplewhale.com/en/articles/9117737-prompt-guide',
  },
  {
    name: 'Getting Started',
    link: 'https://kb.triplewhale.com/en/articles/9118137-getting-started',
    prompt:
      'Give me guidance on how to start using Moby, including:\n1) What to expect\n2) How to ask for data\n3) How to validate/check the data\n4) How to make visualizations\n5) How to add widgets to boards\n6) How to get analysis, insights, and recommendations\n7) What are sequences, why are they so valuable, how to run them and how to save them\n8) How to generate forecasts\n9) Where to find inspiration for prompts.\n\nGet information from the URL https://kb.triplewhale.com/en/articles/9118137-getting-started',
  },
];

export type PromptTopic = {
  topic: string;
  cats: string[];
  color: `${keyof typeof colors}.${NumRange<0, 10>}`;
  labelColor: `${keyof typeof colors}.${NumRange<0, 10>}`;
  badgeColor: `${keyof typeof colors}.${NumRange<0, 10>}`;
};

const staticTopics: PromptTopic[] = [
  {
    topic: 'Yours',
    cats: ['Saved', 'For You', 'From Reports'],
    color: 'one.5',
    labelColor: 'one.1',
    badgeColor: 'one.8',
  },
  {
    topic: 'Discover',
    cats: ['Popular', 'New'],
    color: 'orange.4',
    labelColor: 'orange.1',
    badgeColor: 'orange.6',
  },
];

const otherColorSets: {
  color: `${keyof typeof colors}.${NumRange<0, 10>}`;
  labelColor: `${keyof typeof colors}.${NumRange<0, 10>}`;
  badgeColor: `${keyof typeof colors}.${NumRange<0, 10>}`;
}[] = [
  { color: 'purple.5', labelColor: 'purple.2', badgeColor: 'purple.8' },
  { color: 'teal.5', labelColor: 'green.1', badgeColor: 'green.8' },
];

export const WillyMainChat = forwardRef<WillyMainChatRef, WillyMainChatProps>(
  (
    {
      asPage = false,
      showLogo = true,
      messagesWrapperClassName = '',
      messages,
      conversationId,
      initialQuery,
      isReadyForChat = true,
      returnQueryOnly = false,
      codeActions,
      withoutInputBackground,
      withoutInputShadow,
      source,
      hideSuggestions,
      isDashboardPage,
      setConversationId,
      setMessages,
      chatSourceIds,
      activeVersion,
      hidePills = false,
      dashboardId,
      dashboardName,
      dashboardData,
      summaryData,
      pixelData,
      buildMode,
      setBuildMode,
      workflowIdToRun,
      setWorkflowIdToRun,
      workflowPanelOpen,
      setWorkflowPanelOpen,
    },
    ref,
  ) => {
    const inputRef = useRef<Component<MentionsInputProps, any, any>>(null);
    const scrollToBottomRef = useRef<WillyScrollToBottomRef>(null);
    const shouldSetConversationRef = useRef(true);
    const isMounted = useRef(false);
    const isScrolledToMessage = useRef(false);

    const atEnd = useAtEnd();
    const { search, hash } = useLocation();
    const searchParams = useMemo(() => new URLSearchParams(search), [search]);
    const activeTab = searchParams.get('tab') || 'chat';
    const navigate = useNavigate();
    const doDarkMode = useDarkMode();
    const windowSize = useWindowSize();
    const dispatch = useAppDispatch();

    const dialect = useStoreValue($dialect);
    const industry = useStoreValue($industry);
    const activeAccounts = useStoreValue($activeAccounts);
    const [sequences] = useWritableStore($shopSequences);
    const { linkText, action } = useUpgradeButton('chat', true);
    const currentShopId = useAppSelector((state) => state.currentShopId);
    const user = useAppSelector((state) => state.user);
    const currency = useStoreValue($currency);
    const shopTimezone = useAppSelector((state) => state.shopTimezone);
    const currencyConversionRate = useSelector((state: RootState) => state.currencyConversionRate);
    const defaultRoundingOption = useSelector((state: RootState) => state.defaultRoundingOption);
    const isUserAdmin = useStoreValue($isAdminClaim);
    const shouldShowMobyUpgradeCtas = useStoreValue($shouldShowMobyUpgradeCtas);
    const userShopCustomMobyTags = useComputedValue($userShop, (s) => s?.customPromptTags);
    const shopSensory = useStoreValue($shopWithSensory);
    const [userFilter, setUserFilter] = useState<(typeof userFilterOptions)[number]['value']>(
      userFilterOptions[0].value,
    );
    const [runWorkflowOnInit, setRunWorkflowOnInit] = useState(false);
    const [runWorkflowIfPossible, setRunWorkflowIfPossible] = useState(false);
    const [{ conversations, loading, error }, setConversations] = useConversationsStore({
      userFilter,
    });
    const [activeChatTitleCarouselIndex, setActiveChatTitleCarouselIndex] = useState(0);

    const [value, setValue] = useState<string>('');
    const [isSequenceMode, setIsSequenceMode] = useState(false);
    const [loadingSequence, setLoadingSequence] = useState(false);
    const [loadingSequenceText, setLoadingSequenceText] = useState('');
    const [sequenceId, setSequenceId] = useState<string>('');
    const [modelPickerOpen, setModelPickerOpen] = useState(false);
    const [activeModel, setActiveModel] = useState<LLMModel>('default');
    const sqlGeneratingModel = useComputedValue($mainChatStore, (r) => r.sqlGeneratingModel);
    const [customModel, setCustomModel] = useState<string>('');
    const [forecastModel, setForecastModel] = useState<ForecastModel>(FORECAST_MODELS[0]);
    const [customSqlModel, setCustomSqlModel] = useState<string>('');
    const [followUpQuestions, setFollowUpQuestions] = useState<string[]>([]);
    const [showFollowUpQuestions, setShowFollowUpQuestions] = useState(true);
    const [conversationUser, setConversationUser] = useState<string>(user.uid || '');
    const [shopUsers, setShopUsers] = useState<MentionedUser[]>([
      {
        id: user.uid!,
        display: user.email!,
      },
    ]);
    const [loadingShopUsers, setLoadingShopUsers] = useState(false);
    const [mentionedUsers, setMentionedUsers] = useState<MentionedUser[]>([]);
    const [loadingConversation, setLoadingConversation] = useState(false);
    const [conversationNotFound, setConversationNotFound] = useState(false);
    const [userName, setUserName] = useState<string>('');
    const [lastMessageId, setLastMessageId] = useState<string>('');
    const [willyLoading, setWillyLoading] = useState(false);
    const [currentConversation, setCurrentConversation] = useState<Conversation>();
    const [metricsDict, setMetricsDict] = useState<Record<string, MetricDict>>();
    const [runId, setRunId] = useState<string>('');
    const [runsOpen, setRunsOpen] = useState<string>();

    const conversationMembers = useMemo(() => {
      const mentionedUsers = currentConversation?.users || [];
      return [conversationUser, ...mentionedUsers];
    }, [conversationUser, currentConversation]);

    const lastMessageWithData = useMemo(() => {
      return messages?.findLast(
        (x) => !!x.toolResults?.name && ['TextToSQL'].includes(x.toolResults.name),
      );
    }, [messages]);

    const currentAnalyticsEvent = useMemo(() => {
      return source === 'chat'
        ? analyticsEvents.CHAT
        : source === 'editor'
          ? analyticsEvents.SQWHALE
          : analyticsEvents.DASHBOARDS;
    }, [source]);

    const currentAnalyticsActionSet = useMemo(() => {
      return source === 'chat' || source === 'sequence'
        ? chatActions
        : source === 'editor'
          ? sqwhaleActions
          : dashboardsActions;
    }, [source]);

    const ExpandOrCollapse = showFollowUpQuestions ? ChevronDownMinor : ChevronUpMinor;

    const isEmptyState = useMemo(() => messages.length === 0, [messages]);
    const { moby_lp: lpVersion } = useSelector(gradualReleaseFeatures);
    const showNewLP = useMemo(() => {
      return (
        !isSequenceMode &&
        !isDashboardPage &&
        !dashboardId &&
        !initialQuery &&
        isEmptyState &&
        !loadingConversation &&
        !loadingSequence
      );
    }, [
      dashboardId,
      initialQuery,
      isDashboardPage,
      isEmptyState,
      isSequenceMode,
      loadingConversation,
      loadingSequence,
    ]);

    const { socket, isConnected } = useWillySocket();

    const showMobyPopup = useStoreValue($showMobyPopup);
    const showFromPremiumPlusToMobyModal = useStoreValue($showFromPremiumPlusToMobyModal);

    useEffect(() => {
      let timeoutId1, timeoutId2;
      if (showMobyPopup && !showFromPremiumPlusToMobyModal && source === 'chat') {
        timeoutId1 = setTimeout(() => {
          openUnlockMobyModal(true);
        }, 3000); // 3000 milliseconds = 3 seconds
      }
      if (showFromPremiumPlusToMobyModal && source === 'chat') {
        timeoutId2 = setTimeout(() => {
          $fromPremiumPlusToMobyModal.set({
            show: true,
          });
        }, 3000); // 3000 milliseconds = 3 seconds
      }
      return () => {
        if (timeoutId1) {
          clearTimeout(timeoutId1);
        }
        if (timeoutId2) {
          clearTimeout(timeoutId2);
        }
      };
    }, [showFromPremiumPlusToMobyModal, showMobyPopup, source]);

    const streamingMode = useMemo(() => {
      return isConnected;
    }, [isConnected]);

    const lastMessageFromUser = useMemo(() => {
      return messages.findLast((message) => message.role === 'user');
    }, [messages]);

    const inputPlaceholder = useMemo(() => {
      if (isSequenceMode) {
        return 'Run a sequence to start';
      }
      if (!conversationMembers.includes(user.uid!) && !isUserAdmin) {
        return `You are not part of this conversation`;
      }

      if (asPage) {
        return 'Ask Anything...';
      }

      return undefined;
    }, [conversationMembers, user.uid, isSequenceMode, isUserAdmin, asPage]);

    const metricsData = useMemo(() => {
      if (!metricsDict) {
        return [];
      }
      return Object.values(metricsDict)
        .map((colInfo) => {
          return {
            id: colInfo.Name,
            display: colInfo.Name,
          };
        })
        .sort((a, b) => a.display.localeCompare(b.display));
    }, [metricsDict]);

    const addMessage = useCallback(
      (message: Message, appendText?: boolean) => {
        setMessages((messages) => {
          if (messages.some((m) => m.id === message.id)) {
            return messages.map((m) => {
              if (m.id === message.id) {
                return {
                  ...m,
                  ...message,
                  text: appendText ? `${m.text}${message.text}` : message.text || m.text,
                };
              }
              return m;
            });
          } else {
            return messages.concat(message);
          }
        });
        // scrollToBottomRef.current?.scrollToBottom();
      },
      [setMessages],
    );

    const { workflowInMobyStatus } = useChatSocket({
      setMessages,
      setWillyLoading,
      setWorkflowIdToRun,
      setWorkflowPanelOpen,
      setRunWorkflowOnInit,
      source,
      currentConversationId: conversationId || runId,
      workflowIdToRun,
    });

    useSequenceSocket({
      sequenceId,
      runId,
      setMessages,
      setLastMessageId,
      setLoadingSequence,
      setLoadingSequenceText,
    });

    const resetLoading = useCallback(() => {
      setWillyLoading(false);
    }, []);

    const uniqueMentioned = useMemo(() => {
      const mentioned = mentionedUsers.filter((x) => value.includes(`${x.display}`));
      const uniqueById = mentioned.reduce((acc, current) => {
        const x = acc.find((item) => item.id === current.id);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, emptyArray<MentionedUser>());

      return uniqueById;
    }, [mentionedUsers, value]);

    const showSuggestions = useMemo(() => {
      if (!isEmptyState) {
        return false;
      }

      if (loadingConversation) {
        return false;
      }

      if (loadingSequence) {
        return false;
      }

      if (hideSuggestions) {
        return false;
      }

      return true;
    }, [isEmptyState, loadingConversation, loadingSequence, hideSuggestions]);

    const computedBackground = useMemo(() => {
      return {
        background: withoutInputBackground ? 'transparent' : '#ffffff',
      };
    }, [withoutInputBackground]);

    const currencyRounding = useCallback(
      (metric: BaseSummaryMetric<SummaryMetricIdsTypes>) => {
        if (metric?.type === 'currency') {
          return RoundingOptionsMapper[defaultRoundingOption];
        }
        return null;
      },
      [defaultRoundingOption],
    );

    const formattedDashboardData: FormattedDashboardData = useMemo(() => {
      const dd = {};
      for (let key in dashboardData) {
        const element = dashboardData[key] as PreloadDashboardData;
        const { generatedQuery, ...rest } = element;
        dd[key] = rest;
      }
      return {
        id: dashboardId ?? '',
        name: dashboardName ?? 'Dashboard',
        data: dd,
      };
    }, [dashboardData, dashboardId, dashboardName]);

    const formattedSummaryData: FormattedSummaryData = useMemo(() => {
      const newData = Object.keys(summaryData?.calculatedStats || {})
        ?.map((s) => {
          const stat = summaryData?.activeMetrics?.find((m) => m.id === s);
          if (stat) {
            const rawValue = summaryData?.calculatedStats[s];
            const value = formatNumber(
              (stat.type === 'percent' ? rawValue / 100 : rawValue) * currencyConversionRate,
              {
                style: stat.type || 'decimal',
                currency,
                minimumFractionDigits:
                  currencyRounding({ id: stat.id, type: stat.type } as any) ??
                  stat.valueToFixed ??
                  0,
                maximumFractionDigits:
                  currencyRounding({ id: stat.id, type: stat.type } as any) ??
                  stat.valueToFixed ??
                  0,
              },
            );

            const prevRawValue = summaryData?.previousPeriodCalculatedStats[s];
            const prevValue = formatNumber(
              (stat.type === 'percent' ? prevRawValue / 100 : prevRawValue) *
                currencyConversionRate,
              {
                style: stat.type || 'decimal',
                currency,
                minimumFractionDigits:
                  currencyRounding({ id: stat.id, type: stat.type } as any) ??
                  stat.valueToFixed ??
                  0,
                maximumFractionDigits:
                  currencyRounding({ id: stat.id, type: stat.type } as any) ??
                  stat.valueToFixed ??
                  0,
              },
            );

            return {
              id: s,
              sqlConfig: stat.willyConfig,
              title: stat.title,
              value,
              previousPeriodValue: prevValue,
            };
          }

          return null;
        })
        .filter((v) => v?.id)
        .filter((item): item is NonNullable<typeof item> => item !== null);

      return { id: 'summary', name: 'Summary', data: newData };
    }, [currency, currencyConversionRate, currencyRounding, summaryData]);

    const formattedPixelData: FormattedPixelData = useMemo(() => {
      const filteredData = (pixelData ?? [])
        ?.filter((d) => {
          return d.active && d.pixelAov && d.pixelAov !== 0;
        })
        .map((d) => {
          const { metricsBreakdown, ...rest } = d;
          return rest;
        });

      return { id: 'pixel', name: 'Pixel', data: filteredData };
    }, [pixelData]);

    const activeSequence = useMemo(() => {
      return sequences.find((c) => c.id === sequenceId);
    }, [sequenceId, sequences]);

    const activeSequenceDialect = useMemo(() => {
      return activeSequence?.dialect === 'both' ? 'clickhouse' : $dialect.get();
    }, [activeSequence?.dialect]);

    const chatTitleCarouselStyles = useMemo(() => {
      return {
        transform: `translateY(-${carouselHeight * activeChatTitleCarouselIndex}px)`,
      };
    }, [activeChatTitleCarouselIndex]);

    const conversationIdFromQS = useMemo(() => {
      const searchParams = new URLSearchParams(search);
      return searchParams.get('conversationId');
    }, [search]);

    const sequenceIdFromQS = useMemo(() => {
      const searchParams = new URLSearchParams(search);
      return searchParams.get('sequenceId');
    }, [search]);

    const runIdFromQS = useMemo(() => {
      const searchParams = new URLSearchParams(search);
      return searchParams.get('runId');
    }, [search]);

    const messageIdFromHash: string | undefined = useMemo(() => {
      return hash.split('#')[1];
    }, [hash]);

    const promptFromLibrary: string | null = useMemo(() => {
      const searchParams = new URLSearchParams(search);
      return searchParams.get('prompt');
    }, [search]);

    useEffect(() => {
      if (!!promptFromLibrary) {
        setValue(promptFromLibrary);
      }
    }, [promptFromLibrary]);

    const handleSubmit = useCallback(
      async (text: string, skipUserMessage?: boolean, userMessage?: string) => {
        const v = text;
        if (!v?.trim()?.length) {
          return;
        }
        if (willyLoading || !dialect) {
          return;
        }
        if (!activeAccounts) {
          return;
        }
        let newConversationId = conversationId || uuidV4();
        if (!conversationId) {
          shouldSetConversationRef.current = false;

          setConversationId(newConversationId);

          if (!isDashboardPage) {
            const params = new URLSearchParams(search);
            params.set('conversationId', newConversationId);
            navigate({
              pathname: location.pathname,
              search: params.toString(),
            });
          }

          setTimeout(() => {
            shouldSetConversationRef.current = true;
          }, 500);
        }

        setWillyLoading(true);
        setShowFollowUpQuestions(false);

        let currentMessageId = uuidV4();

        setLastMessageId(currentMessageId);
        setValue('');

        if (!skipUserMessage) {
          // add the message user submitted
          addMessage({
            id: uuidV4(),
            text: userMessage || v,
            userId: user.uid,
            role: 'user',
            conversationId: newConversationId,
          });
        }

        genericEventLogger(analyticsEvents.WILLY, {
          action: willyActions.SEND_MESSAGE,
          prompt: v,
        });

        genericEventLogger(currentAnalyticsEvent, {
          action: currentAnalyticsActionSet.SEND_MESSAGE,
          prompt_text: v,
          is_first_message: !messages.length,
          conversationId: newConversationId,
        });

        try {
          if (streamingMode) {
            addMessage({
              id: currentMessageId,
              role: 'assistant',
              conversationId: newConversationId,
              loading: true,
            });
          } else {
            addMessage({
              id: currentMessageId,
              role: 'assistant',
              conversationId: newConversationId,
            });
          }

          const requestParams: AnswerNlqQuestionParams = {
            source: source === 'summary' || source === 'pixel' ? 'dashboard' : source,
            shopId: currentShopId,
            additionalShopIds: activeAccounts,
            messageId: currentMessageId,
            question: v,
            conversationId: newConversationId,
            generateInsights: true,
            stream: true,
            returnQueryOnly,
            dialect,
            modelToolsName:
              activeModel === 'custom'
                ? customModel
                : activeModel === 'default'
                  ? undefined
                  : activeModel,
            sqlGeneratingModel:
              sqlGeneratingModel === 'custom'
                ? customSqlModel
                : sqlGeneratingModel === 'default'
                  ? ''
                  : sqlGeneratingModel,
            query: initialQuery || getSqlFromMessage(lastMessageWithData) || undefined,
            metrics: getColumnsFromMessage(lastMessageWithData),
            widgetId: getQueryIdFromMessage(lastMessageWithData) || undefined,
            dashboardId: 'chat',
            mentionedUsers: uniqueMentioned.map((x) => x.id),
            currency,
            timezone: shopTimezone,
            industry: industry || 'other',
            dashboardData:
              source === 'chat' || source === 'sequence' || source === 'editor'
                ? undefined
                : JSON.stringify(
                    (source === 'pixel'
                      ? formattedPixelData
                      : source === 'summary'
                        ? formattedSummaryData
                        : formattedDashboardData) as FormattedDashboardDataElement,
                  ),
            forecastModel,
            runWorkflowIfPossible,
          };

          if (streamingMode) {
            await answerNlqQuestion({ ...requestParams, stream: true });
          } else {
            const response = await answerNlqQuestion({ ...requestParams, stream: false });

            setWillyLoading(false);

            addMessage({
              id: currentMessageId,
              role: 'assistant',
              conversationId: newConversationId,
              text: response.text,
              loading: false,
            });
          }
        } catch (error) {
          console.error('ERROR', error);
        }
      },
      [
        willyLoading,
        dialect,
        activeAccounts,
        conversationId,
        currentAnalyticsEvent,
        currentAnalyticsActionSet.SEND_MESSAGE,
        messages.length,
        setConversationId,
        isDashboardPage,
        search,
        navigate,
        addMessage,
        user.uid,
        streamingMode,
        source,
        currentShopId,
        returnQueryOnly,
        activeModel,
        customModel,
        initialQuery,
        lastMessageWithData,
        uniqueMentioned,
        currency,
        shopTimezone,
        industry,
        formattedPixelData,
        formattedSummaryData,
        formattedDashboardData,
        sqlGeneratingModel,
        customSqlModel,
        forecastModel,
        runWorkflowIfPossible,
      ],
    );

    const clearConversation = useCallback(() => {
      setMessages([]);
      setLastMessageId('');

      setShowFollowUpQuestions(false);
      setFollowUpQuestions([]);

      setWillyLoading(false);
      setConversationNotFound(false);
      setLoadingConversation(false);
      setLoadingSequence(false);

      setUserName(user.firstName || user.lastName || user.email || user.uid!);
      setConversationUser(user.uid || '');
      setWorkflowPanelOpen?.(false);

      if (currentAnalyticsEvent !== 'dashboards') {
        const params = new URLSearchParams(search);
        params.delete('conversationId');
        params.delete('sequenceId');
        params.delete('runId');
        params.delete('prompt');
        navigate({
          pathname: location.pathname,
          search: params.toString(),
          hash: '',
        });
      }

      setValue('');
      genericEventLogger(analyticsEvents.WILLY, {
        action: willyActions.CLEAR_CHAT,
      });
      genericEventLogger(currentAnalyticsEvent, {
        action: currentAnalyticsActionSet.CLEAR_CHAT,
        conversationId,
      });
    }, [
      setMessages,
      user.firstName,
      user.lastName,
      user.email,
      user.uid,
      search,
      navigate,
      currentAnalyticsEvent,
      currentAnalyticsActionSet.CLEAR_CHAT,
      conversationId,
      setWorkflowPanelOpen,
    ]);

    const runSequence = useCallback(async () => {
      const id = sequenceId;

      if (!currentShopId) {
        return;
      }
      if (!id) {
        return;
      }
      if (!activeAccounts) {
        return;
      }
      if (runId) {
        return;
      }
      setMessages([]);
      setLastMessageId('');
      // clearConversation();

      shouldSetConversationRef.current = false;
      let currentMessageId = uuidV4();
      const newRunId = uuidV4();
      setSequenceId(id);
      setRunId(newRunId);

      const params = new URLSearchParams(search);

      params.set('sequenceId', id);
      params.set('runId', newRunId);
      params.delete('conversationId');
      navigate({
        pathname: location.pathname,
        search: params.toString(),
        hash: '',
      });

      setTimeout(() => {
        shouldSetConversationRef.current = true;
      }, 500);

      const requestParams: AnswerNlqQuestionParams = {
        source: 'sequence',
        shopId: currentShopId,
        conversationId: newRunId,
        additionalShopIds: activeAccounts,
        messageId: currentMessageId,
        question: '<question will generate from sequence>',
        generateInsights: true,
        stream: true,
        currency,
        timezone: shopTimezone,
        dialect: activeSequenceDialect,
        industry: industry || 'other',
        conversationLink: window.location.href,
        forecastModel,
        runWorkflowIfPossible,
      };

      setLoadingSequence(true);

      socket.emit('run-sequence', {
        ...requestParams,
        sequenceId: id,
      });
    }, [
      currency,
      currentShopId,
      shopTimezone,
      socket,
      sequenceId,
      activeSequenceDialect,
      runId,
      activeAccounts,
      search,
      navigate,
      shouldSetConversationRef,
      setMessages,
      runWorkflowIfPossible,
      industry,
      forecastModel,
    ]);

    const onSuggestionClick = useCallback(
      (q) => {
        if (!q) {
          return;
        }
        setValue(q);
        (inputRef?.current as any)?.inputElement?.focus();
        genericEventLogger(currentAnalyticsEvent, {
          action: currentAnalyticsActionSet.CHOOSE_SUGGESTED_MESSAGE,
          conversationId,
          suggested_prompt: q,
        });
      },
      [conversationId, currentAnalyticsActionSet.CHOOSE_SUGGESTED_MESSAGE, currentAnalyticsEvent],
    );

    useEffect(() => {
      runSequence();
    }, [runSequence]);

    const focusTextInput = useCallback(() => {
      (inputRef as any)?.current?.inputElement?.focus();
    }, []);

    useEffect(() => {
      (async () => {
        if (!conversationId) {
          return;
        }
        const conversationDoc = await _db().collection('conversations').doc(conversationId).get();
        const conversation = conversationDoc.data() as Conversation;
        if (conversationDoc.exists) {
          setCurrentConversation({ ...conversation, id: conversationDoc.id });
        } else {
          setCurrentConversation(undefined);
        }
      })();
    }, [conversationId]);

    useEffect(() => {
      (async () => {
        const metricsDict: MetricDict[] = await getMetricsDictionary();

        const asObject = fromPairs(
          metricsDict.map((x) => {
            return [x.Name, x];
          }),
        );
        setMetricsDict(asObject);
      })();
    }, []);

    useEffect(() => {
      (async () => {
        if (!currentShopId || !sequenceId) {
          return;
        }
        const seqDoc = await _db().collection('data_sequences').doc(sequenceId).get();

        if (!seqDoc.exists) {
          return;
        }
        try {
          const globalDashboardId = seqDoc.data()?.globalDashboardId;
          if (globalDashboardId) {
            await axiosInstance.post('v2/willy/update-stats', {
              uid: user?.uid,
              shopId: currentShopId,
              sequenceId: globalDashboardId,
              actionType: 'viewed',
            });
          }
        } catch (e) {
          console.error('Error updating views counter', e);
        }
      })();
    }, [currentShopId, sequenceId, user?.uid]);

    useEffect(() => {
      (async () => {
        const conversationId = conversationIdFromQS;
        const sequenceId = sequenceIdFromQS;
        const runId = runIdFromQS;

        if (!shouldSetConversationRef.current) {
          return;
        }

        if (!conversationId) {
          setConversationId('');
          setCurrentConversation(undefined);
        }
        if (!sequenceId) {
          setSequenceId('');
          setIsSequenceMode(false);
        }
        if (!runId) {
          setRunId('');
        }

        if (runId && sequenceId) {
          setRunId(runId);
          setSequenceId(sequenceId);
          setIsSequenceMode(true);
          setLoadingConversation(true);
          const runDoc = await _db()
            .collection('data_sequences')
            .doc(sequenceId)
            .collection('runs')
            .doc(runId)
            .get();

          if (!runDoc.exists) {
            setLoadingConversation(false);
            return;
          }

          const runData = runDoc.data();

          if (!runData) {
            setLoadingConversation(false);
            return;
          }

          const messages: Message[] = createMessageFromDb(runData, runId);
          setMessages(messages);
          setLoadingConversation(false);
        } else if (sequenceId) {
          setSequenceId(sequenceId);
          setIsSequenceMode(true);
        } else if (conversationId && conversationId != currentConversation?.id) {
          setLoadingConversation(true);
          setUserName('');
          const conversationDoc = await _db().collection('conversations').doc(conversationId).get();
          if (!conversationDoc.exists) {
            setMessages([]);
            setConversationId('');
            setLoadingConversation(false);
            setConversationNotFound(true);
            return;
          }
          const conversation = conversationDoc.data() as Conversation;
          if (conversation?.history) {
            const messages: Message[] = createMessageFromDb(conversation, conversationId);
            setMessages(messages);
            setConversationId(conversationId);
          }
          if (conversation?.user) {
            setConversationUser(conversation.user);
            try {
              const { data } = await axiosInstance.get<User>(
                `/v2/willy/get-user-name?shopId=${currentShopId}&userId=${conversation.user}`,
              );
              const { firstName, lastName, email } = data;
              setUserName(firstName || lastName || email || conversation.user);
            } catch (e) {
              console.error('Could not fetch user name', e);
            }
          }
          setLoadingConversation(false);
        }
      })();
    }, [
      currentShopId,
      conversationIdFromQS,
      sequenceIdFromQS,
      runIdFromQS,
      user,
      currentConversation?.id,
      setConversationId,
      setMessages,
    ]);

    useEffect(() => {
      setUserName(user.firstName || user.lastName || user.email || user.uid!);
    }, [user]);

    useEffect(() => {
      setFollowUpQuestions([]);
    }, [conversationId]);

    useEffect(() => {
      (async () => {
        setLoadingShopUsers(true);
        const res = (
          await axiosInstance.get(`/v2/account-manager/shops/users/${currentShopId}?noFilter=true`)
        ).data;

        setLoadingShopUsers(false);

        setShopUsers(
          res.map((x) => {
            return {
              id: x.id,
              display: x.email,
            };
          }),
        );
      })();
    }, [currentShopId]);

    useEffect(() => {
      // clear the conversationId query param when user navigates away
      setTimeout(() => {
        isMounted.current = true;
      }, 3000);

      return () => {
        if (!isMounted.current) {
          return;
        }
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.delete('conversationId');
        searchParams.delete('sequenceId');
        searchParams.delete('runId');
        searchParams.delete('prompt');

        navigate(
          {
            pathname: window.location.pathname,
            search: searchParams.toString(),
            hash: window.location.hash,
          },
          { replace: true },
        );
      };
    }, [navigate]);

    useImperativeHandle(ref, () => ({
      clearConversation,
      handleSubmit,
      setLastMessageId,
      setLoadingConversation,
      lastMessageId,
      focusTextInput,
      shouldSetConversationRef,
    }));

    const clearConversationButton = (
      <div
        className="cursor-pointer"
        onClick={() => {
          genericEventLogger(currentAnalyticsEvent, {
            action: chatActions.CLEAR_CONVERSATION,
            conversationId,
          });
          clearConversation();
        }}
      >
        <span className="text-black/50 dark:text-white/50 font-semibold [text-shadow:_0_0_0_white]">
          {isSequenceMode ? 'Clear sequence' : 'Clear conversation'}
        </span>
      </div>
    );

    useLayoutEffect(() => {
      if (messageIdFromHash && atEnd?.[0] && !isScrolledToMessage.current) {
        // scroll to message
        const messageElement = document.getElementById(messageIdFromHash);
        if (messageElement) {
          setTimeout(() => {
            messageElement.scrollIntoView({ behavior: 'smooth' });
            isScrolledToMessage.current = true;
          }, 2000);
        }
      }
    }, [messageIdFromHash, atEnd]);

    useEffect(() => {
      let timer = setTimeout(
        () =>
          setActiveChatTitleCarouselIndex(
            activeChatTitleCarouselIndex + 1 >= chatTitleCarouselOptions.length
              ? 0
              : activeChatTitleCarouselIndex + 1,
          ),
        4000,
      );

      return () => {
        clearTimeout(timer);
      };
    }, [activeChatTitleCarouselIndex]);

    return (
      <WillyMainChatWrapper
        asPage={asPage}
        buildMode={buildMode}
        workflowPanelOpen={workflowPanelOpen}
        setWorkflowPanelOpen={setWorkflowPanelOpen}
        messages={messages}
        conversationId={conversationId}
        setBuildMode={setBuildMode}
        clearConversation={clearConversation}
        currentAnalyticsEvent={currentAnalyticsEvent}
        activeTab={activeTab}
        conversations={conversations}
        setConversations={setConversations}
        loading={loading}
        error={error}
        userFilter={userFilter}
        setUserFilter={setUserFilter}
        setValue={setValue}
        workflowIdToRun={workflowIdToRun}
        runWorkflowOnInit={runWorkflowOnInit}
        workflowInMobyStatus={workflowInMobyStatus}
        runWorkflowIfPossible={runWorkflowIfPossible}
        setRunWorkflowIfPossible={setRunWorkflowIfPossible}
      >
        <ScrollToBottom
          debug={false}
          followButtonClassName="hidden"
          className={`h-full w-full overflow-auto flex flex-col scroll-p-40 ${messagesWrapperClassName}`}
          scrollViewClassName="h-full w-full overflow-auto flex flex-col"
        >
          <WillyScrollToBottom ref={scrollToBottomRef} showArrow>
            <div className="w-full flex flex-col flex-auto @container" style={computedBackground}>
              {!hidePills && isUserAdmin && (
                <div className="sticky top-0 bg-white z-10 p-4 grid items-center gap-4">
                  <div className="grid grid-cols-3 gap-4 flex-auto flex-wrap">
                    <div className="flex mr-auto gap-4 items-center">
                      <WillyMainChatModelSelectors />
                      {isUserAdmin && (
                        <WillyMainChatForecastModelSelector
                          activeForecastModel={forecastModel}
                          setActiveForecastModel={setForecastModel}
                        />
                      )}
                    </div>
                  </div>
                </div>
              )}
              {showNewLP ? (
                <>
                  {!!lpVersion?.new_moby_lp || isUserAdmin ? (
                    <WillyChatLP onSuggestionClick={onSuggestionClick} />
                  ) : (
                    <WillyScrollQuestionsLP onSuggestionClick={onSuggestionClick} />
                  )}
                </>
              ) : (
                <>
                  {showLogo && isEmptyState && !loadingConversation && !loadingSequence && (
                    <div className="w-full flex flex-col justify-center items-center m-auto mb-auto md:mb-auto p-">
                      <div
                        className={`text-gray-800 w-full md:h-full md:flex md:flex-col p-4 dark:text-gray-100 overflow-hidden ${isDashboardPage && 'mt-8'}`}
                      >
                        <h1 className="text-4xl font-semibold md:text-center ml-auto mr-auto mb-5 md:mb-8 flex gap-4 items-center justify-center flex-col">
                          {/* <Alan className=" @[600px]:w-[150px] w-20 max-w-[100%] h-auto" /> */}
                          <ChatIcon
                            className="@[600px]:w-[150px] w-20 max-w-[100%] h-auto"
                            onClick={(e) => {
                              dispatch({
                                type: 'FLOPPY_WHALE_MODAL_OPENED',
                              });
                            }}
                          />
                        </h1>
                      </div>
                      {!isSequenceMode && (
                        <>
                          <div
                            className="overflow-hidden shrink-0"
                            style={{ height: carouselHeight + (windowSize.isSmall ? 0 : 7) }}
                          >
                            <div
                              className="transition ease-in-out duration-300 flex flex-col items-center justify-start text-center shrink-0"
                              style={{
                                ...chatTitleCarouselStyles,
                                height: carouselHeight + (windowSize.isSmall ? 0 : 7),
                              }}
                            >
                              {chatTitleCarouselOptions.map((option, index) => (
                                <div
                                  key={index}
                                  className="font-medium text-3xl flex items-center justify-center shrink-0"
                                  style={{ lineHeight: '30px' }}
                                >
                                  {option}
                                </div>
                              ))}
                            </div>
                          </div>

                          {!hidePills && !windowSize.isSmall && (
                            <div className="flex gap-4 mt-8">
                              {mainChatPillPrompts.map(({ prompt, name }) => (
                                <div
                                  role="button"
                                  className="willy-suggest-question px-6 py-2 flex flex-col overflow-hidden cursor-pointer animateFadeToRight rounded-2xl border border-solid border-[#D1D4DB] hover:bg-[#e5e7eb] transition-colors"
                                  onClick={() => {
                                    handleSubmit?.(prompt);
                                    genericEventLogger(analyticsEvents.CHAT, {
                                      action: chatActions.SELECT_DEFAULT_PILL,
                                      prompt,
                                    });
                                  }}
                                  key={name}
                                >
                                  {name}
                                </div>
                              ))}
                            </div>
                          )}
                        </>
                      )}
                      {conversationNotFound && (
                        <div className="text-gray-800 dark:text-gray-100 font-semibold text-lg mt-4">
                          Conversation not found, you can start a new one or change the shop
                        </div>
                      )}
                    </div>
                  )}
                  {(loadingConversation || loadingSequence) && showLogo && (
                    <div className="flex flex-col gap-2 w-full h-full justify-center items-center">
                      <AlanLoaderGray />
                      {!!loadingSequenceText && (
                        <Text color="gray.5" size="lg">
                          {loadingSequenceText}
                        </Text>
                      )}
                    </div>
                  )}
                  {!isEmptyState && !loadingConversation && !loadingSequence && (
                    <div className="flex-auto flex flex-col">
                      {(windowSize.isSmall || isMobileApp) && <div className="mt-auto"></div>}
                      {messages.map((message, i, arr) => (
                        <WillyMessageTemplate
                          message={message}
                          setWorkflowIdToRun={setWorkflowIdToRun}
                          setWorkflowPanelOpen={setWorkflowPanelOpen}
                          workflowPanelOpen={workflowPanelOpen}
                          firstOfAssistant={message.role !== 'user' && arr[i - 1]?.role === 'user'}
                          lastOfAssistant={
                            (message.role !== 'user' && arr[i + 1]?.role === 'user') ||
                            i === arr.length - 1
                          }
                          lastMessageFromUser={lastMessageFromUser}
                          canEdit={conversationMembers.includes(user.uid!)}
                          userName={userName}
                          handleSubmit={handleSubmit}
                          key={message.id + '_element'}
                          loading={willyLoading}
                          codeActions={codeActions}
                          isLast={i === messages.length - 1}
                          conversationUser={conversationUser}
                          isSequenceMode={isSequenceMode}
                          chatSourceIds={chatSourceIds}
                          conversationMessages={messages}
                          buildMode={buildMode}
                        />
                      ))}
                    </div>
                  )}
                </>
              )}
              <div
                className={`sticky bottom-0 z-[200] w-full flex flex-col  ${asPage ? 'p-6 @3xl:!px-40 @3xl:!pb-10' : ' p-6 @3xl:!px-90 @3xl:!pb-16'}`}
                style={computedBackground}
              >
                {!showNewLP && showSuggestions && (
                  <div
                    className={`${
                      source === 'dashboard' &&
                      !willyLoading &&
                      (!!conversationId || messages?.length > 0)
                        ? ' mb-8'
                        : ''
                    }`}
                  >
                    <WillySuggestQuestions
                      onSuggestionClick={onSuggestionClick}
                      source={source}
                      inputValue={value}
                    />
                  </div>
                )}
                <div className="flex flex-col gap-1 items-end relative">
                  <div className="w-full relative sm:flex items-end gap-4 justify-between pb-2 sm:pb-4">
                    {followUpQuestions?.length > 0 && (
                      <div
                        className={`max-w-full self-start fadein overflow-hidden transition-[height] duration-300 ${
                          showFollowUpQuestions ? 'h-64' : 'h-10'
                        }`}
                      >
                        <div
                          className={`rounded-lg overflow-hidden ${
                            showFollowUpQuestions ? 'bg-white dark:bg-gray-800' : 'bg-transparent'
                          }`}
                        >
                          <div
                            className="sm:px-4 py-2 flex gap-1 items-center cursor-pointer"
                            onClick={() => {
                              setShowFollowUpQuestions((x) => !x);
                            }}
                          >
                            <ExpandOrCollapse className="w-6 h-6" />
                            <h1 className="text-gray-900 dark:text-white font-semibold text-lg">
                              Follow-up questions
                            </h1>
                          </div>
                          <div
                            className={`flex flex-col gap-2 p-4 pt-0 ${
                              showFollowUpQuestions ? 'opacity-100' : 'opacity-0'
                            }`}
                          >
                            {followUpQuestions.map((q) => {
                              return (
                                <div
                                  onClick={() => {
                                    setValue(q);
                                    handleSubmit(q);
                                    genericEventLogger(currentAnalyticsEvent, {
                                      action: currentAnalyticsActionSet.CLICK_FOLLOWUP,
                                      conversationId,
                                      followup_prompt: q,
                                    });
                                  }}
                                  className="flex flex-col gap-2 p-4 bg-gray-100 dark:bg-gray-700 rounded-lg cursor-pointer hover:bg-gray-200 dark:hover:bg-gray-600 transition-colors"
                                  key={q}
                                >
                                  <div className="text-gray-900 dark:text-white font-semibold text-lg line-clamp-1">
                                    {q}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    )}
                    {!willyLoading && (!!conversationId || messages?.length > 0) && (
                      <div
                        className={`whitespace-nowrap absolute right-1 bottom-2 sm:relative ${
                          showFollowUpQuestions ? 'hidden sm:block' : ''
                        }`}
                      >
                        {clearConversationButton}
                      </div>
                    )}
                  </div>
                  <div className="flex items-center w-full">
                    <div className={`relative flex-auto ${doDarkMode ? ' tw-nice-dark' : ''}`}>
                      {!streamingMode && (
                        <Tooltip label="Streaming mode is temporarily disabled, responses might take longer than usual">
                          <div className="absolute flex-shrink-0 top-1/2 -left-12 translate-x-1/2 -translate-y-1/2 w-4 h-4 bg-red-500 rounded-full" />
                        </Tooltip>
                      )}
                      <ChatInput
                        value={value}
                        setValue={setValue}
                        onSubmit={handleSubmit}
                        disabled={
                          (!isUserAdmin && !conversationMembers.includes(user.uid!)) ||
                          (!!activeVersion && activeVersion < 6)
                        }
                        onStopChat={() => {
                          resetLoading();
                          setLastMessageId('');
                          socket?.emit('stop-answer-nlq-question', {
                            shopId: currentShopId,
                            messageId: lastMessageId,
                            conversationId,
                            question: value,
                          });
                        }}
                        context={source}
                        inputRef={inputRef}
                        placeholder={inputPlaceholder}
                        willyLoading={willyLoading}
                        isReadyForChat={isReadyForChat}
                        currentAnalyticsEvent={currentAnalyticsEvent}
                      >
                        <Mention
                          trigger="@"
                          // style={{}}
                          isLoading={loadingShopUsers}
                          data={shopUsers}
                          // displayTransform={(id, display) => {
                          //   return display.split('@')[0].trim();
                          // }}
                          markup='@"__display__"'
                          onAdd={(id, display) => {
                            setMentionedUsers((old) => {
                              return old.concat({ id: id.toString(), display });
                            });
                          }}
                        />
                        <Mention
                          trigger="#"
                          appendSpaceOnAdd
                          data={metricsData}
                          markup='@"__display__"'
                          renderSuggestion={(suggestion, search) => {
                            const metric = metricsDict?.[suggestion.id];
                            if (!metric) {
                              return <Text fw="bold">{suggestion.display}</Text>;
                            }
                            return (
                              <div className="flex flex-col gap-1">
                                <Text fw="bold">{metric.Name}</Text>
                                <Text size="sm">{metric.About}</Text>
                                {!!metric.Formula && (
                                  <Text size="sm" color="gray.5" fs="italic">
                                    <Text display="inline-flex" fw="bold">
                                      Formula:
                                    </Text>{' '}
                                    <Text display="inline-flex">{metric.Formula}</Text>
                                  </Text>
                                )}
                                {!!metric['Learn More'] && (
                                  <Text size="sm" color="gray.5">
                                    <a target="_blank" href={metric['Learn More']}>
                                      Learn more
                                    </a>
                                  </Text>
                                )}
                              </div>
                            );
                          }}
                        />
                      </ChatInput>
                    </div>
                  </div>

                  {shouldShowMobyUpgradeCtas && (
                    <div className="flex items-center w-full">
                      <div className="w-full text-center pt-[40px]">
                        {
                          <Anchor underline="always" fz="sm" fw={500} onClick={action}>
                            {linkText}
                          </Anchor>
                        }
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </WillyScrollToBottom>
          <SequenceRunsModal
            opened={!!runsOpen}
            close={() => setRunsOpen(undefined)}
            sequenceId={runsOpen}
          />
        </ScrollToBottom>
      </WillyMainChatWrapper>
    );
  },
);
