import moment from '@tw/moment-cached';
import {
  AnswerNlqQuestionParams,
  BreakdownValue,
  ChartData,
  ChartDataItem,
  ChartInfo,
  CodeExecutionResponse,
  CodeInterpreterResponse,
  WidgetQuery,
  HistoryItem,
  MainQueryChangedParams,
  Message,
  MessageData,
  NlqCodeData,
  NlqResponse,
  NlqResponseDataType,
  FileWithData,
  RawNlqData,
  RCERequest,
  RelatedSchema,
  ScheduleItem,
  UniformChatResponse,
  WillyBaseMainElement,
  WillyDashboardElement,
  WillyDataColumn,
  WillyDataSequence,
  WillyMetric,
  WillyParameter,
  WillyWidgetElement,
  ExecuteCustomQueryParams,
  Dialect,
  WillyToolName,
  SupportedLanguages,
  ToolProgress,
  ChatSources,
  WillyPrompt,
  Conversation,
  WillyFieldElement,
  DeprecatedToolNames,
  VisualizationTypes,
  ChartType,
  ToolResult,
  WorkflowStepToolPreload,
} from '../types/willyTypes';
import { toast } from 'react-toastify';
import allServices from 'constants/services';
import { AlanLoaderGray } from 'components/AlanLoader';
import { removePinSummaryWilly } from '../../../ducks/willy';
import { ReactComponent as AlanIcon } from 'components/Icons/alan-icon.svg';
import { WillyWidget } from '../WillyWidget';
import axiosInstance from 'utils/axiosInstance';
import { getSocket } from '../WillySocket';
import { GRADIENT_CHART_COLORS } from 'constants/general';
import { mergeArraysBy } from 'utils/mergeArraysBy';
import { snakeCase, startCase, uniqBy } from 'lodash';
import { AnalyticsObjectType, Granularity } from '@tw/types';
import { confirm, IconName, icons } from '@tw/ui-components';
import _db, { FieldValue, firestoreRef, getTrueShopId, getUserId, toArray, userDb } from 'utils/DB';
import { arrayUnion, DocumentData } from 'firebase/firestore';
import { format, formatDialect } from 'sql-formatter';
import ReactGridLayout from 'react-grid-layout';
import { $relatedSchema, $tables } from '$stores/willy/$tables';
import { formatText } from './formatters';
import { emptyArray as EMPTY_ARRAY } from '@tw/stats/module/generalUtils';
import { v4 as uuidV4 } from 'uuid';
import firebase from 'firebase/compat/app';
import DocumentReference = firebase.firestore.DocumentReference;
import { $currency, $currentShopId, $forceSharded } from '$stores/$shop';
import {
  analyticsEvents,
  attributionActions,
  attributionMessages,
  chatActions,
  dashboardsActions,
  genericEventLogger,
  sqwhaleActions,
  willyDashActions,
} from '../../../utils/dataLayer';
import { updateDashboardForFFConfigs } from '../api/updateDashboardForFFConfigs';
import { FeatureFlag, FeatureFlagConfigKey } from '@tw/feature-flag-system/module/types';
import {
  columnsForAds,
  columnsForAdsets,
  columnsForCampaigns,
  DEFAULT_AXIS_DOMAIN,
  DEFAULT_DIALECT,
  FAVORITE_PROMPTS_COLLECTION,
  GREEN,
  RED,
  willyToolMap,
} from '../constants';
import { guessDefaultType } from '../hooks/useDefaultType';
import { $dialect, $user, $userId } from '../../../$stores/$user';
import { clickhouse } from '@tw/willy-clickhouse-formatter';
import { ServicesIds } from '@tw/types/module/services';
import Cookies from 'utils/Cookies';
import { BqTable } from 'pages/FreeQuery/dataStuff/tables';
import { BqColumn, OptionsObj } from 'pages/FreeQuery/dataStuff/columns/types';
import { BuilderTable, SQLType } from '@tw/willy-data-dictionary/module/columns/types';
import { astifyQuery } from './queryFormatter';
import { BaseFrom } from 'node-sql-parser';
import { User } from 'components/UserProfileManagment/User/constants';
import { encode } from 'gpt-tokenizer';

export function dataHasMultipleValues(data: RawNlqData) {
  return Array.isArray(data) && data.some((v) => v?.value?.length > 1);
}

export function dataHasExactlyOneValue(data?: RawNlqData) {
  return data?.length === 1;
}

export function keyIsMonth(key: string): key is 'month' {
  return /\bmonth\b/.test(key.replace(/_/g, ' '));
}

export function keyIsQuarter(key: string): key is 'quarter' {
  return /\bquarter\b/.test(key.replace(/_/g, ' '));
}

export function keyIsYear(key: string): key is 'year' {
  return /\byear\b/.test(key.replace(/_/g, ' '));
}

export function keyIsDay(key: string): key is 'day' {
  // exclude {number}_day:
  if (/\d+_day/.test(key)) {
    return false;
  }
  return /\bday\b/.test(key.replace(/_/g, ' '));
}

export function keyIsHour(key: string): key is 'hour' {
  return /\bhour\b/.test(key.replace(/_/g, ' '));
}

export function keyIsWeek(key: string): key is 'week' {
  return /^week\b/i.test(key.replace(/_/g, ' '));
}

export function keyIsDate(key: string): key is 'date' {
  return /\bdate\b/.test(key.replace(/_/g, ' '));
}

export function keyIsSomeDate(
  key?: string,
): key is 'date' | 'day' | 'month' | 'year' | 'week' | 'hour' | 'quarter' {
  if (!key || typeof key !== 'string') return false;
  return (
    keyIsDate(key) ||
    keyIsDay(key) ||
    keyIsMonth(key) ||
    keyIsYear(key) ||
    keyIsWeek(key) ||
    keyIsHour(key) ||
    keyIsQuarter(key)
  );
}

export function isValidHour(input: string) {
  const hourPattern = /^(0[0-9]|1[0-9]|2[0-3])$/;
  return hourPattern.test(input);
}

export const keyIsService = (key: string) => {
  if (!key || typeof key !== 'string') return false;

  return ['service', 'channel', 'provider'].some((k) =>
    key.toLowerCase().includes?.(k.toLowerCase()),
  );
};

export function keyIsImage(key: string) {
  return key.includes('image');
}

export function keyIsVideo(key: string) {
  return key.includes('video');
}

export function keyIsDimension(key: string, metrics?: WillyMetric[]) {
  if (!metrics) return false;
  const metric = metrics.find((m) => m.key === key);
  return !!metric?.isDimension;
}

export function keyIsPixel(key: string) {
  return key.includes('pixel');
}

export const keyIsEntityId = (
  key: string,
  pixelBreakdown?: 'campaign_id' | 'ad_set_id' | 'ad_id',
): key is 'campaign_id' | 'ad_set_id' | 'ad_id' => {
  if (!key) return false;

  if (!pixelBreakdown) {
    return ['campaign_id', 'ad_set_id', 'ad_id'].some((k) => key.includes?.(k));
  } else {
    return key.includes(pixelBreakdown);
  }
};

export const keyIsEntityName = (
  pixelBreakdown: 'campaigns' | 'ad_sets' | 'ads' | undefined,
  key: string,
) => {
  if (!pixelBreakdown) {
    return ['campaign_name', 'ad_set_name', 'ad_name'].some((k) => key.includes?.(k));
  } else if (pixelBreakdown === 'campaigns') {
    return key.includes('campaign_name');
  } else if (pixelBreakdown === 'ad_sets') {
    return key.includes('ad_set_name');
  } else if (pixelBreakdown === 'ads') {
    return key.includes('ad_name');
  } else {
    return false;
  }
};

export const keyIsCity = (key: string) => {
  if (!key) return false;

  return ['city'].some((k) => key.includes?.(k));
};

export const keyIsCountry = (key: string) => {
  if (!key) return false;

  return ['country', 'state', 'region', 'continent'].some((k) => key.includes?.(k));
};

export const keyIsGeo = (key: string) => {
  if (!key) return false;

  return keyIsCity(key) || keyIsCountry(key);
};

export const extractPossibleXKey = (dataColumns: WillyDataColumn): string | undefined => {
  if (dataColumns?.x?.length) {
    const dateX = dataColumns?.x?.find(keyIsSomeDate);
    return dateX || dataColumns.x[0];
  }

  const dateKey = dataColumns?.y?.find(keyIsSomeDate);

  if (dateKey) {
    return dateKey;
  }

  const serviceKey = dataColumns?.y?.find(keyIsService);

  if (serviceKey) {
    return serviceKey;
  }
};

export const convertDataToJson = (data: RawNlqData) => {
  const result: Record<string, string | number | null>[] = [];

  for (let item of data) {
    const name = item.name;
    for (let j = 0; j < item.value.length; j++) {
      if (!result[j]) {
        result[j] = {};
      }
      result[j][name] = item.value[j];
    }
  }

  return result;
};

export function convertDataToChart(
  data: RawNlqData,
  dataColumns: WillyDataColumn,
  metrics: WillyMetric[],
  previousPeriodData?: RawNlqData,
  dimension?: string,
): ChartInfo {
  if (!data?.length || !Array.isArray(data)) {
    return {
      data: [],
      stackedData: [],
    };
  }

  const hasTotalRow = data?.some((x) => x.value.some(keyIsTotal));
  if (hasTotalRow) {
    data = moveTotalToEnd(data);
    // for now let's take only the breakdown
    data = data.map((d) => {
      return {
        ...d,
        value: d.value.slice(0, -1),
      };
    });

    if (!!previousPeriodData) {
      previousPeriodData = moveTotalToEnd(previousPeriodData);
      previousPeriodData = previousPeriodData.map((d) => {
        return {
          ...d,
          value: d.value.slice(0, -1),
        };
      });
    }
  }

  const dimensionInColumns = dimension && dataColumns?.x?.includes(dimension);

  const xAxis = dimensionInColumns ? dimension : extractPossibleXKey(dataColumns);

  if (!xAxis) {
    const chartData: ChartData = data.reduce((acc, curr) => {
      const currElement: ChartDataItem = {
        [curr.name]: {
          metric: curr.name,
          value: curr.value?.[0] ? +curr.value?.[0] : null,
        },
      };
      return [...acc, currElement];
    }, [] as ChartData);

    return {
      data: chartData,
      stackedData: [],
    };
  }

  let xData = data.find((x) => xAxis === x.name)?.value;
  const prevXData = previousPeriodData?.find((x) => x.name === xAxis)?.value;

  const yKeys = dataColumns?.y
    ?.filter((x) => x !== xAxis)
    .concat(dataColumns?.x.filter((x) => x !== xAxis));

  const chartData: ChartData = !xData
    ? []
    : xData.map((x, i) => {
        const yData = yKeys.reduce<Record<string, any>>((acc, y) => {
          let metric = data?.find((x) => x.name === y)?.name;

          acc[y] = {
            metric,
            value: data.find((x) => x.name === y)?.value[i],
            previousValue: previousPeriodData?.find((x) => x.name === y)?.value[i] || undefined,
          };
          return acc;
        }, {});

        const currentMetric = metrics.find((m) => m.key === xAxis);

        const isDate = currentMetric ? !!currentMetric.isDate : keyIsSomeDate(xAxis);

        let sortingKey = i;
        let formattedX = x;
        let formattedPreviousX = prevXData?.[i];
        if (isDate) {
          if (xData) {
            formattedX = formatDateField(xAxis, xData!, x, currentMetric?.dateFormat);
          }
          if (prevXData?.[i]) {
            formattedPreviousX = formatDateField(xAxis, prevXData, prevXData?.[i]);
          }

          sortingKey = moment(x).valueOf();

          if (keyIsHour(xAxis)) {
            sortingKey = x ? +x : 0;
          }
          if (formattedX === 'Invalid date') {
            sortingKey = i;
          }
        } else if (keyIsService(xAxis) && typeof x === 'string') {
          const service = allServices[x];
          formattedX = service?.name || x;
        }
        return {
          sortingKey: sortingKey as any,
          x: formattedX,
          previousX: formattedPreviousX,
          [xAxis]: {
            metric: xAxis,
            value: x,
            previousValue: formattedPreviousX,
          },
          ...yData,
        };
      });
  const d = chartData
    .sort((a, b) => (a.sortingKey as number) - (b.sortingKey as number))
    .map(({ sortingKey, ...rest }) => rest);

  const stackedData: ChartData = d.map((x) => {
    const sortedByValue = Object.entries(x)
      .filter(([key]) => key !== 'x' && key !== 'previousX')
      .sort((a, b) => a[1].value - b[1].value);
    const incremented = sortedByValue.map(([key, value], i) => {
      const prev = sortedByValue[i - 1];
      const prevValue = prev ? prev[1].value : 0;
      return [key, { ...value, value: value.value - prevValue, originalValue: value.value }];
    });
    return incremented.reduce(
      (acc, [key, value]) => {
        acc[key] = value;
        return acc;
      },
      { x: x.x as any },
    );
  });

  return {
    data: d,
    stackedData: stackedData,
  };
}

export function formatDateField(
  key: string,
  rowData: (string | number | null)[],
  value: string | number | null,
  dateFormat?: string,
) {
  if (value === undefined || value === null) {
    return '-';
  }
  let formattedValue = value.toString();
  const firstXDate = moment(rowData[0]);
  const isOneDay = rowData.every((x) => moment(x).isSame(firstXDate, 'day'));
  const isOneYear = rowData.every((x) => moment(x).isSame(firstXDate, 'year'));
  const hasMultipleDays = rowData.length > 1;

  const isYear = keyIsYear(key);
  const isMonth = keyIsMonth(key);
  const isWeek = keyIsWeek(key);
  const isHour = keyIsHour(key);
  let format: string;
  const valueIsNumber = typeof value === 'number';

  let momentValue: string | number = value;

  if (dateFormat) {
    if (dateFormat === 'none') {
      return value.toString();
    }
    format = dateFormat;
    formattedValue = moment(value).format(format);
  } else if (isHour) {
    format = 'LT';
    if (typeof momentValue === 'string' && momentValue?.includes?.('T')) {
      momentValue = momentValue.split('T')[1];
      momentValue = momentValue.split(':')[0];
    }

    momentValue = +momentValue;
    formattedValue = moment()
      .hour(momentValue || 0)
      .startOf('hour')
      .format(format);
  } else if (isWeek) {
    format = 'LL';
    if (valueIsNumber) {
      momentValue = Number(momentValue);
      momentValue = momentValue + 1;
      formattedValue = momentValue.toString();
    } else {
      formattedValue = moment(momentValue).format(format);
    }
  } else if (isMonth) {
    format = 'MMM YY';
    if (valueIsNumber) {
      formattedValue = moment(momentValue, 'M').format(format);
    } else {
      formattedValue = moment(momentValue).format(format);
    }
  } else if (isYear) {
    format = 'YYYY';
    if (valueIsNumber) {
      formattedValue = moment(momentValue, 'YYYY').format(format);
    } else {
      formattedValue = moment(momentValue).format(format);
    }
  } else {
    format = 'LL';
    formattedValue = moment(momentValue).format(format);
  }
  if (formattedValue === 'Invalid date') {
    formattedValue = value.toString();
  }

  return formattedValue;
}

export const buildWillyDynamicSectionWidget = (selectWillyWidgets) => {
  return selectWillyWidgets.map((willyWidget) => {
    const { title, id, queryData, widgetType } = willyWidget;

    const SpinnerWidget = () => (
      <div className="flex w-full h-full justify-center items-center">
        <AlanLoaderGray />
      </div>
    );

    const widget =
      Object.keys(queryData).length === 0
        ? SpinnerWidget
        : () => {
            const { queryId, question } = queryData;

            return (
              <WillyWidget
                permission={{ providers: [] }}
                permissionChanged={() => {}}
                context="summary"
                question={question}
                queryId={queryId}
                type={widgetType}
                title={title}
                currency={$currency.get()}
                titleChanged={() => {}}
                queriesChanged={async () => {}}
                typeChanged={() => {}}
                setEditMetricModalOpen={() => {}}
                wrapText={false}
                setWrapText={() => {}}
                // setTitle={(newTitle, dispatch) => dispatch(editWillySummaryTitle(id, newTitle))}
                // currency={''}
                initialRawData={queryData}
                isDynamic={true}
                metrics={[]}
                metricsChanged={async () => {}}
                stackedChanged={() => {}}
                stacked={false}
                incrementedStacked={false}
                incrementedStackedChanged={() => {}}
                grid={'flex'}
                setGrid={() => {}}
                gridColumns={2}
                setGridColumns={() => {}}
                twoColumnMobile={false}
                setTwoColumnMobile={() => {}}
                tileMode={'tile'}
                setTileMode={() => {}}
                skinny={false}
                setSkinny={() => {}}
                rightYAxisLabel={''}
                setRightYAxisLabel={() => {}}
                leftYAxisLabel=""
                setLeftYAxisLabel={() => {}}
                xAxisLabel=""
                setXAxisLabel={() => {}}
                yAxisDomain={DEFAULT_AXIS_DOMAIN}
                setYAxisDomain={() => {}}
                allowDataOverflow={false}
                setAllowDataOverflow={() => {}}
                dimension=""
                setDimension={() => {}}
                hasGlobalConditionalFormatting={false}
                setHasGlobalConditionalFormatting={() => {}}
                globalConditionalFormattingColor=""
                setGlobalConditionalFormattingColor={() => {}}
                breakdownMode={false}
                breakdownModeChanged={async () => {}}
                dialect={$dialect.get()}
                builderSetupChanged={async () => {}}
                filtersOpen={false}
                setFiltersOpen={() => {}}
                paginationType="server"
                isSyncCharts={false}
              />
            );
          };

    return {
      id,
      title,
      icons: [
        () => (
          <AlanIcon className="w-10 h-10 text-[black] fill-[white] dark:text-[white] dark:fill-[black]" />
        ),
      ],
      tiles: [],
      allowToPickForCustomSection: false,
      widgets: [widget],
      types: ['summary'],
      services: ['willy'],
      disabledSwitchMode: true,
      disabledEditSection: true,
      onDeleteSection: async (dispatch) => {
        await dispatch(removePinSummaryWilly(id, 'widget'));
      },
      showPreviousPeriod: false,
    };
  });
};

export async function answerNlqQuestion(
  params: AnswerNlqQuestionParams & { stream: true },
): Promise<void>;

export async function answerNlqQuestion(
  params: AnswerNlqQuestionParams & { stream: false },
): Promise<UniformChatResponse>;

export async function answerNlqQuestion(
  params: AnswerNlqQuestionParams,
): Promise<void | UniformChatResponse> {
  const {
    shopId,
    additionalShopIds,
    conversationId,
    messageId,
    question,
    generateInsights,
    query,
    metrics,
    widgetId,
    dashboardId,
    mentionedUsers,
    source,
    returnQueryOnly,
    currency,
    stream,
    dialect,
    industry,
    dashboardData,
    modelToolsName,
    sqlGeneratingModel,
    forecastModel,
    runWorkflowIfPossible,
    timezone,
    preGeneratedQuery,
    conversationLink,
  } = params;

  let model = modelToolsName === 'default' ? undefined : modelToolsName;

  let relatedSchema: RelatedSchema[] | undefined = undefined;
  if (query) {
    relatedSchema = $relatedSchema.get();
  }
  if (stream) {
    const socket = getSocket();
    socket.emit('answer-nlq-question', {
      shopId,
      additionalShopIds,
      conversationId,
      messageId,
      question,
      generateInsights,
      returnQueryOnly,
      query,
      metrics,
      widgetId,
      dashboardId,
      mentionedUsers,
      source,
      relatedSchema,
      currency,
      timezone: moment().tz() as string,
      dialect,
      dashboardData,
      industry: industry || 'other',
      modelToolsName: model,
      sqlGeneratingModel,
      conversationLink: window.location.href,
      forecastModel,
      runWorkflowIfPossible,
      preGeneratedQuery,
    });

    return;
  } else {
    const { data } = await axiosInstance.post<
      AnswerNlqQuestionParams,
      { data: UniformChatResponse }
    >('/v2/willy/answer-nlq-question', {
      shopId,
      conversationId,
      messageId,
      question,
      generateInsights,
      returnQueryOnly,
      query,
      metrics,
      mentionedUsers,
      source,
      relatedSchema,
      currency,
      timezone: moment().tz() as string,
      stream,
      dashboardData,
      industry: industry || 'other',
      modelToolsName: model,
      sqlGeneratingModel,
      conversationLink: window.location.href,
      forecastModel,
      runWorkflowIfPossible,
      preGeneratedQuery,
    });
    return data;
  }
}

export function getFromClause(sqlQuery: string) {
  try {
    // Regular expression to match the FROM clause and capture until a possible SQL keyword or end of string
    const regex = /FROM\s+([\s\S]+?)(?=\s+(WHERE|GROUP BY|ORDER BY|HAVING|$))/i;

    // Execute the regular expression
    const match = regex.exec(sqlQuery);

    // If a match is found, return the captured group; otherwise, return null
    return match ? match[1]?.trim() : null;
  } catch (e) {
    return null;
  }
}

export function getTableFromQuery(query: string) {
  try {
    const fromClause = getFromClause(query);
    if (!fromClause) {
      return null;
    }
    const table = fromClause.split(/[\s(]+/)[0];
    return table;
  } catch (e) {
    return null;
  }
}

export function getTableFromEveryQuery(query: string) {
  const ast = astifyQuery(query);
  if (!ast) {
    return null;
  }

  let tables = ast.from?.map((x) => (x as BaseFrom).table);
  tables = tables?.filter((x) => !!x);
  if (tables?.length === 0) {
    return null;
  }

  tables = tables?.map((t) => {
    let res = t;
    if (t === 'custom_spendings') {
      return 'custom_spend_table';
    }

    if (t === 'pixel_joined_table') {
      return 'pixel_joined_tvf';
    }

    if (!t.endsWith('_table')) {
      res = `${t}_table`;
    }
    if (t.includes('.')) {
      res = t.split('.')[1];
    }

    return res;
  });

  return tables || null;
}

export function getColumnFromTable(id: string, tableId: string | null) {
  const table = $tables
    .get()
    .filter((t) => {
      if (tableId) {
        return t.id === tableId;
      }
      return true;
    })
    .find((table) => {
      return table.columns.some((column) => column.id === id);
    });
  if (!table) {
    return null;
  }
  const column = table.columns.find((column) => column.id === id);
  if (!column) {
    return null;
  }
  return column;
}

export function createWillyMetricFromColumn(
  column: BqColumn,
  index = 0,
  serviceIds?: ServicesIds[],
): WillyMetric {
  const metricKey = column.id;
  const currentDef = GRADIENT_CHART_COLORS[index % GRADIENT_CHART_COLORS.length];

  return {
    key: metricKey,
    name: column.title || formatText(metricKey, true),
    description: column.description || '',
    icon: guessMetricIconFromData(metricKey, serviceIds || [], column),
    active: false,
    color: currentDef.start,
    colorName: currentDef.name,
    isDate: keyIsSomeDate(metricKey),
    isDimension: column.type !== 'numeric' && column.type !== 'formula',
    format: column.format || 'decimal',
    toFixed: column.maximumFractionDigits || 2,
    valueIsNegative: column.valueIsNegative ?? false,
    minimumFractionDigits: column.minimumFractionDigits || 0,
  };
}

export function extractProvidersFromFilters(filters: WillyParameter[]) {
  return filters.filter((f) => f.column === 'channel').flatMap((f) => f.value as ServicesIds[]);
}

export function determineChartTypeFromVisualizationType(
  visualizationType?: VisualizationTypes,
): ChartType | null {
  if (!visualizationType) {
    return null;
  }
  if (visualizationType === 'Bar') {
    return 'bar';
  }

  if (visualizationType === 'Line') {
    return 'line';
  }

  if (visualizationType === 'Scatter') {
    return 'scatter';
  }

  if (visualizationType === 'Area') {
    return 'area';
  }

  if (visualizationType === 'StackedBar') {
    return 'stacked-bar';
  }

  return null;
}

type CreateWillyMetricsFromRawDataParams = {
  data: RawNlqData;
  sqlQuery: string;
  initialMetrics: WillyMetric[];
  servicesIds: ServicesIds[];
  dataType?: NlqResponseDataType;
  visualizationType?: VisualizationTypes;
};

export function createWillyMetricsFromRawData(
  params: CreateWillyMetricsFromRawDataParams,
): WillyMetric[] {
  const { data, sqlQuery, initialMetrics, servicesIds, dataType, visualizationType } = params;
  const mainTable = getTableFromQuery(sqlQuery);
  if (!data?.length || !Array.isArray(data)) {
    return [];
  }
  const allColumns = data.map((x) => getColumnFromTable(x.name, mainTable)).filter((x) => !!x);
  const keysAreMixedOfCurrencyAndPercentage =
    allColumns.some((y) => y.format === 'currency') &&
    allColumns.some((y) => y.format === 'percent');

  const chartType = determineChartTypeFromVisualizationType(visualizationType);

  const metricsFromData = data.map<WillyMetric>((d, i) => {
    const metricKey = d.name;
    const column = allColumns.find((x) => x.id === metricKey);

    // if one of the values is a string, then it's a dimension
    const isDimension = d.value.some((x) => typeof x === 'string');

    const currentDef = GRADIENT_CHART_COLORS[i % GRADIENT_CHART_COLORS.length];

    const isCurrency = column?.format === 'currency';
    const isDecimal = column?.format === 'decimal';
    const isPercentage = column?.format === 'percent';
    // const isNameOfPercent = d.name.includes('ratio') || d.name.includes('percentage');
    const percentKeywords = ['ratio', 'percent', 'percentage', 'ctr', 'ratio', 'rate', 'cvr'];
    const currencyKeywords = [
      'revenue',
      'cost',
      'spend',
      'spent',
      'profit',
      'margin',
      'cogs',
      'roi',
      'conversion_value',
      'aov',
      'ltv',
    ];
    const isNameOfPercent = percentKeywords.some((keyword) => {
      const regex = new RegExp(`(^|[\\s\\-\\_])${keyword}([\\s\\-\\_]|$)`, 'i');
      return regex.test(d.name);
    });
    const isNameOfCurrency = currencyKeywords.some((keyword) => {
      const regex = new RegExp(`(^|[\\s\\-\\_])${keyword}([\\s\\-\\_]|$)`, 'i');
      return regex.test(d.name);
    });
    const isAnyPercent = isPercentage || isNameOfPercent;
    const isAnyCurrency = isCurrency || isNameOfCurrency;
    let yAxisId: 'left' | 'right' = 'left';
    if (dataType === 'forecast') {
      yAxisId = 'left';
    } else if (keysAreMixedOfCurrencyAndPercentage) {
      yAxisId = isAnyCurrency || isDecimal ? 'left' : 'right';
    }
    const isDate = keyIsSomeDate(d.name);

    let specialColor: any = currentDef;
    let shouldBeBarChart = true;
    if (dataType === 'forecast') {
      shouldBeBarChart = !d.name.includes('ci_lower') && !d.name.includes('ci_upper');
    } else if (data.some((x) => x.value.length !== 1)) {
      shouldBeBarChart = false;
    }

    if (dataType === 'forecast' && (d.name.includes('ci_lower') || d.name.includes('ci_upper'))) {
      specialColor = { start: 'indigo', stop: 'indigo', name: 'indigo' };
      //specialColor = GRADIENT_CHART_COLORS.find((x) => x.name === 'indigo-gradient') || currentDef;
    }

    let active = true;

    if (dataType === 'mmm') {
      if (
        d.name === 'revenue - current daily avg' ||
        d.name === 'revenue - adjusted daily avg' ||
        d.name === 'ROAS - current daily avg' ||
        d.name === 'ROAS - adjusted daily avg'
      ) {
        active = false;
      }
    }

    return {
      key: d.name,
      isDimension: !!isDimension,
      isDate: !!isDate,
      format: isAnyPercent
        ? 'percent'
        : isAnyCurrency
          ? 'currency'
          : isDecimal
            ? 'decimal'
            : column?.format || 'decimal',
      toFixed: column?.maximumFractionDigits || 2,
      valueIsNegative: column?.valueIsNegative || false,
      minimumFractionDigits: column?.minimumFractionDigits || 0,
      name: column?.title || formatText(d.name, true),
      description: column?.description || '',
      icon: guessMetricIconFromData(d.name, servicesIds, column),
      colorName: specialColor.name,
      color: specialColor.start,
      chartType: chartType || (shouldBeBarChart ? 'bar' : 'line'),
      yAxisId: yAxisId,
      active,
      onClickAction: 'none',
    };
  });

  const breakdownMetricsToKeep = columnsForCampaigns.concat(columnsForAdsets).concat(columnsForAds);

  const existingMetrics = initialMetrics
    ?.map((x) => {
      const keyFromData = metricsFromData.find((y) => y.key === x.key);
      if (keyFromData) {
        return {
          ...x,
          key: keyFromData.key,
          hiddenBecauseHasNoData: false,
        };
        // just for the next few weeks, we want to clean the merge responses garbage
      } else if (x.key.includes('_(')) {
        return {
          ...x,
          key: clearKeyFromParameters(x.key),
        };
      } else {
        return {
          ...x,
          key: clearKeyFromParameters(x.key),
          hiddenBecauseHasNoData: !breakdownMetricsToKeep.includes(x.key),
        };
      }
    })
    .filter(isTruthy);

  const mergedByKey = mergeArraysBy(existingMetrics, metricsFromData, 'key');
  return mergedByKey;
}

export function isTruthy<T>(value: T | undefined): value is T {
  return Boolean(value);
}

export function isNotUndefined<T>(value: T | undefined): value is T {
  return value !== undefined;
}

export function getMetricOriginalKey(metric: WillyMetric) {
  return clearKeyFromParameters(metric.key);
}

export function clearKeyFromParameters(key: string) {
  return key.split(/\_\(/)[0];
}

export function guessMetricIconFromData(
  metricKey: string,
  servicesIds: string[],
  column?: BqColumn,
): IconName {
  let icon: IconName = 'blended-metrics';

  try {
    if (column?.title?.toLowerCase().includes('pixel')) {
      icon = 'pixel';
      return icon;
    }
    if (['pixel'].some((x) => metricKey?.toLowerCase()?.includes(x))) {
      icon = 'pixel';
      return icon;
    }
    const servicesIdsWithoutPixel = servicesIds.filter((x) => x !== 'pixel');
    if (servicesIdsWithoutPixel?.length === 1) {
      const service = servicesIdsWithoutPixel[0];
      icon = mapServiceToIcon(service, icon);
      return icon;
    }

    const parts = metricKey
      .toLowerCase()
      // split by _ or whitespace
      .split(/[\s_]+/)
      // remove the work "ads" and every non alphanumeric character
      .map((x) => x.replaceAll('ads', '').replaceAll(/[^a-zA-Z0-9]/g, ''))
      .filter((x) => x);
    const possibleService = Object.keys(allServices).find((x) =>
      parts.some((p) => x.toLowerCase().replace('-ads', '') === p.toLowerCase()),
    );
    if (possibleService) {
      icon = mapServiceToIcon(possibleService, icon);
      return icon;
    }

    return icon;
  } catch (e) {
    console.error(e);
    return icon;
  }
}

export function mapServiceToIcon(
  service: string | undefined,
  defaultIcon: 'blended-metrics',
): IconName {
  if (!service) {
    return defaultIcon;
  }
  if (service.includes('facebook')) {
    return 'facebook-circle';
  } else if (service.includes('google-ads')) {
    return 'google-ads';
  } else if (service.includes('google')) {
    return 'google';
  } else if (service.includes('snapchat')) {
    return 'snapchat-circle';
  } else if (service.includes('tiktok')) {
    return 'tiktok';
  } else if (service.includes('twitter')) {
    return 'twitter';
  } else if (service.includes('pinterest')) {
    return 'pinterest';
  } else if (service.includes('linkedin')) {
    return 'linkedin';
  } else if (service.includes('youtube')) {
    return 'google-ads'; // TODO: change to youtube
  } else if (service.includes('amazon')) {
    return 'amazon';
  } else if (service.includes('shopify')) {
    return 'shopify';
  } else if (service.includes('microsoft') || service.includes('bing')) {
    return 'microsoft';
  } else if (service.includes('mountain')) {
    return 'mountain';
  } else if (icons.includes(service as IconName)) {
    return service as IconName;
  }
  return defaultIcon;
}

export function adjustColor(color: `#${string}`, amount: number) {
  return (
    '#' +
      color
        ?.replace(/^#/, '')
        ?.replace(/../g, (color) =>
          ('0' + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2),
        ) || '000000'
  );
}

function generateQueryFetchUrl(
  q: string,
  isPreviousPeriod?: boolean,
  dataType?: NlqResponseDataType,
) {
  let url = `/v2/willy/load-saved-query?query=${q}${isPreviousPeriod ? '&previous' : ''}`;
  if (dataType === 'forecast') {
    url = `/v2/willy/load-saved-forecast?query=${q}${isPreviousPeriod ? '&previous' : ''}`;
  }
  return url;
}

export type FetchWidgetQueriesParams = {
  abortSignal: AbortSignal;
  queryIds?: string[];
  parameters?: WillyParameter[];
  queryParameters?: WillyParameter[];
  shopId: string;
  additionalShopIds: string[];
  start: moment.Moment;
  end: moment.Moment;
  currency: string;
  isDynamic?: boolean;
  isPreviousPeriod?: boolean;
  shouldReplaceTablesWithNlq?: boolean;
  queryVars?: Record<string, string | number>;
  deselectedMetrics?: string[];
  selectedCustomMetrics?: string[];
  dataType?: NlqResponseDataType;
  functionName?: string;
  granularity?: Granularity;
  page?: number;
  pageSize?: number;
  orderBy?: string;
  orderDirection?: 'asc' | 'desc' | null;
  currentIsToday?: boolean;
};

export async function fetchWidgetQueries(params: FetchWidgetQueriesParams) {
  const {
    abortSignal,
    queryIds = [],
    parameters = [],
    queryParameters = [],
    shopId,
    additionalShopIds,
    currency,
    isDynamic,
    isPreviousPeriod,
    shouldReplaceTablesWithNlq,
    queryVars,
    deselectedMetrics,
    selectedCustomMetrics,
    dataType,
    functionName,
    granularity,
    page = 1,
    pageSize,
    orderBy,
    orderDirection,
    currentIsToday,
  } = params;
  let { start, end } = params;
  const hasStartParameter = queryParameters.find((p) => p.column === 'start_date');
  const hasEndParameter = queryParameters.find((p) => p.column === 'end_date');
  const startAndEndAreString =
    typeof hasStartParameter?.value === 'string' && typeof hasEndParameter?.value === 'string';

  if (hasStartParameter && hasEndParameter && startAndEndAreString && !isDynamic) {
    start = moment(hasStartParameter.value);
    end = moment(hasEndParameter.value);
  }
  const isOnlyOneDay = start.isSame(end, 'day');

  // round to the nearest hour
  const todayHour = moment().minutes() > 30 ? moment().hour() + 1 : moment().hour();

  const promises: Promise<{ data: NlqResponse }>[] = [];
  // let cancel: Canceler | undefined;
  try {
    const allQueries = [...new Set(queryIds.filter(Boolean))];
    const eventHour = isPreviousPeriod && currentIsToday ? todayHour : undefined;
    // pad the event hour with a 0 if it's a single digit
    const eventHourString = eventHour?.toString().padStart(2, '0');

    const startDate = isDynamic ? start.format('YYYY-MM-DD') : undefined;
    const endDate = isDynamic ? end.format('YYYY-MM-DD') : undefined;
    const paramsFromQueryParameters = queryParameters.reduce(
      (acc, curr) => {
        if (curr.column === 'start_date' && startDate) {
          return acc;
        } else if (curr.column === 'end_date' && endDate) {
          return acc;
        }
        return {
          ...acc,
          [curr.column]: curr.value,
        };
      },
      {} as Record<string, any>,
    );

    allQueries?.forEach((q) => {
      const url = generateQueryFetchUrl(q, isPreviousPeriod, dataType);
      promises.push(
        axiosInstance.post(
          url,
          {
            queryId: q,
            functionName,
            shopId: shopId,
            includeInsights: false,
            startDate: isDynamic ? start.format() : undefined,
            endDate: isDynamic ? end.format() : undefined,
            changedParameters: parameters, //.filter((p) => p.visible || p.visibleInDashboard),
            hour: eventHour,
            timezone: moment().tz(),
            granularity: !isDynamic ? undefined : isOnlyOneDay ? 'hour' : granularity,
            additionalShopIds,
            currency,
            shouldReplaceTablesWithNlq,
            orderBy,
            orderDirection,
            queryParams: {
              start_date: startDate,
              end_date: endDate,
              event_hour: eventHourString,
              ...queryVars,
              ...paramsFromQueryParameters,
            },
            deselectedMetrics,
            selectedCustomMetrics,
            page,
            pageSize,
            forceSharded: $forceSharded.get(),
          },
          {
            signal: abortSignal,
          },
        ),
      );
    });

    const responses = (await Promise.all(promises)).map((a) => a.data);

    const responsesWithQueries: NlqResponse[] = responses.map((r) => {
      return {
        ...r,
        queries: [
          {
            id: r.queryId!,
            query: r.generatedQuery!,
            question: r.question!,
          },
        ],
      };
    });

    return responsesWithQueries;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export function arrayMove(arr, old_index, new_index) {
  if (new_index >= arr.length) {
    var k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr;
}

export function moveTotalToEnd(data: RawNlqData) {
  const allDataValues = data.map((x) => x.value);
  let totalIndex = -1;
  for (const values of allDataValues) {
    totalIndex = values.findIndex(keyIsTotal);
    if (totalIndex !== -1) {
      break;
    }
  }

  if (totalIndex === -1 || totalIndex === undefined) {
    return data;
  }

  const res = data.map((item) => {
    return {
      ...item,
      value: [
        ...item.value.slice(0, totalIndex),
        ...item.value.slice(totalIndex + 1),
        item.value[totalIndex],
      ],
    };
  });

  return res;
}

export function breakDownRowDataWithoutTotal(
  data: RawNlqData,
  metricKey: string,
): BreakdownValue | undefined {
  if (data.length === 1) {
    return undefined;
  }
  if (data.find((x) => x.name === metricKey)?.value?.length === 1) {
    return undefined;
  }
  const columnWithTotal = data?.find((x) => x.value.some(keyIsTotal));
  const totalRowIndex = columnWithTotal?.value.findIndex(keyIsTotal);

  let result: BreakdownValue = [];
  if (totalRowIndex === undefined) {
    result = data
      .filter((x) => x.name === metricKey)
      .flatMap((x) => x.value)
      .map((y, i) => {
        return {
          metric: metricKey,
          value: y,
          date: data.find((x) => x.name === columnWithTotal?.name)?.value?.[i]?.toString(),
        };
      });
  } else {
    result = data
      .filter((x) => x.name === metricKey)
      .flatMap((x) => x.value.filter((_, i) => i !== totalRowIndex))
      .map((y, i) => {
        return {
          metric: metricKey,
          value: y,
          date: data
            .find((x) => x.name === columnWithTotal?.name)
            ?.value?.filter((_, i) => i !== totalRowIndex)
            ?.[i]?.toString(),
        };
      });
  }

  return result.sort((a, b) => {
    if (!a.date || !b.date) {
      return 0;
    }
    return moment(a.date).valueOf() - moment(b.date).valueOf();
  });
}

export function dataBreakdownHasProvider(dataColumns?: WillyDataColumn) {
  if (!dataColumns?.x?.length) {
    return null;
  }

  const hasRelevantColumns = dataColumns.x.some(keyIsService);

  return hasRelevantColumns;
}

export function dataBreakdownIsAd(dataColumns?: WillyDataColumn) {
  if (
    dataBreakdownHasProvider(dataColumns) &&
    dataColumns?.x.some((x) => x === 'ad_id') &&
    dataColumns?.x.some((x) => x === 'ad_name')
  ) {
    return 'ad_id';
  }

  return null;
}

export function dataBreakdownContext(dataColumns?: WillyDataColumn) {
  if (!dataBreakdownHasProvider(dataColumns)) {
    return null;
  }

  if (
    dataColumns?.x.some((x) => x === 'ad_set_id') &&
    dataColumns?.x.some((x) => x === 'ad_set_name')
  ) {
    return 'ad_set_id';
  } else if (
    dataColumns?.x.some((x) => x === 'campaign_id') &&
    dataColumns?.x.some((x) => x === 'campaign_name')
  ) {
    return 'campaign_id';
  } else {
    return null;
  }
}

// Example usage
// const base64String: string = "UklGRmLyAgBXRUJQVlA4IFbyAgAwgQqdASoABAAEPjEWiUOiISOoppb6+RAGCWdrt3vZjt/Gt+Y7jbk5S/NdNdau61Hs0YyOnT33hQGr87lUdE8qPlP9t/2viD+5+EX6L/cf+X7gX8p/rP+z87TuxUAP2R/X30U+Yf7Q9OD/Sej7+lf8DqD/6n7W+nj66u5nfGrF1PqeZhyX6Fxbck7mrN79a70Hqg8yD9h/2X91nrN84v7rfun7wn5VfBL+4+of/V/+b113ogeG38Sv9k/6/7n+0R1+nPL+Vf+7zh/M/8Hvj/N/sv+b/hv9F70f73lP+B/0/289R/vjnq/x/218lf1z+s9BH3f/1PVR/L/cjwv+P/3/7Yew18Lfj//D/m/3x/2XyU/m//H/berv7x/u/YC/rP71evP/U/cPyyvxv+9/bD81vsD/p/+M/9f+e/2H7ufUT/of/X/bf7792fbv+0/7H/5f67/d/IP/RP73/4v8l/r/z/+w";
// const fileName: string = "example.bin"; // Replace with your desired file name, and use the correct extension
// const file: File = base64ToFile(base64String, fileName, 'image/png'); // Replace 'image/png' with the actual MIME type if known
export function base64ToFile(
  base64Data: string,
  filename: string,
  mimeType: string = 'application/octet-stream',
): File {
  // Handle data URI schema for base64 strings
  let byteString: string;
  if (base64Data.split(',')[0].indexOf('base64') >= 0) {
    byteString = Buffer.from(base64Data.split(',')[1], 'base64').toString('binary');
    mimeType = base64Data.split(',')[0].split(':')[1].split(';')[0];
  } else {
    byteString = atob(base64Data);
  }

  // Write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // Create a blob from the ArrayBuffer and convert it to a File
  const blob = new Blob([ab], { type: mimeType });
  const file = new File([blob], filename, { type: mimeType });

  return file;
}

export function base64toBlob(base64Data: string, contentType: string = ''): Blob {
  const sliceSize = 1024;
  const byteCharacters = atob(base64Data);
  const bytesLength = byteCharacters.length;
  const slicesCount = Math.ceil(bytesLength / sliceSize);
  const byteArrays: Uint8Array[] = new Array(slicesCount);

  for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    const begin = sliceIndex * sliceSize;
    const end = Math.min(begin + sliceSize, bytesLength);

    const bytes = new Array(end - begin);
    for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0);
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  return new Blob(byteArrays, { type: contentType });
}

export function guessMessageType(message) {
  if (!!Object.keys(message?.data?.data || {})?.length) {
    return 'data';
  } else if (message?.data?.action) {
    return 'action';
  } else if (message?.data?.links) {
    return 'help';
  } else {
    return 'chat';
  }
}

export function keyIsTotal(key: string | number | null): key is 'total' {
  if (!key || typeof key !== 'string') {
    return false;
  }
  return key.toLowerCase() === 'total';
}

export async function deleteMainElement(
  element: WillyBaseMainElement,
  /** Packages that a global dashboard needs to be removed from */
  ffConfs: FeatureFlagConfigKey[] = [],
  defaultPackages: FeatureFlagConfigKey[] = [],
) {
  const shopId = $currentShopId.get() || undefined;
  const userId = $userId.get() || getUserId;
  const { name, isGlobal, id } = element;
  const conf = await confirm({
    title: isGlobal ? `Delete template` : `Delete Report`,
    message: `Are you sure you want to delete "${name}"?`,
  });

  if (conf) {
    if (isDashboardElement(element)) {
      await axiosInstance.post('v2/willy/delete-dashboard', {
        shopId,
        dashboardId: id,
        userId,
        isGlobal,
        isCustomView: element.isCustomView,
      });
    } else {
      const ref = getMainElementDbRef(element);

      await ref
        .collection('widgets')
        .get()
        .then((widgets) => {
          widgets.forEach((widget) => {
            widget.ref.delete();
          });
        });

      await ref
        .collection('fields')
        .get()
        .then((fields) => {
          fields.forEach((field) => {
            field.ref.delete();
          });
        });

      await ref
        .collection('history')
        .get()
        .then((hs) => {
          hs.forEach((h) => {
            h.ref.delete();
          });
        });

      await ref.delete();
    }

    if (isGlobal) {
      await updateDashboardForFFConfigs(
        {
          configs: ffConfs,
          dashboardId: element.id,
          mergeStrategy: 'delete',
        },
        FeatureFlag.TEMPLATES_FF,
      );
      await updateDashboardForFFConfigs(
        {
          configs: defaultPackages,
          dashboardId: element.id,
          mergeStrategy: 'delete',
        },
        FeatureFlag.WILLY_DEFAULT_TEMPLATES_FF,
      );
    }

    if (element.globalDashboardId) {
      if (isDashboardElement(element) && !element.isCustomView) {
        await updateUninstalledCounter(element.globalDashboardId);
      }
      if (!isDashboardElement(element)) {
        await updateUninstalledCounter(element.globalDashboardId);
      }
    }

    return true;
  }

  return false;
}

async function updateUninstalledCounter(globalDashboardId: string): Promise<void> {
  try {
    const shopId = $currentShopId.get() || undefined;
    await axiosInstance.post('v2/willy/update-stats', {
      shopId: $currentShopId.get(),
      dashboardId: globalDashboardId,
      actionType: 'uninstalled',
    });
  } catch (error) {
    console.error('Error updating uninstalled counter', error);
  }
}

export async function addWidgetToDashboard(
  dashboard: WillyDashboardElement,
  widget: WillyWidgetElement,
  shopId: string,
  conversationId?: string,
) {
  const {
    queryId,
    type,
    title,
    metrics,
    queries,
    stacked,
    incrementedStacked,
    parameters,
    wrapText,
    withoutMainQuery,
    codeResult,
    breakdownMode,
    hasGlobalConditionalFormatting,
    globalConditionalFormattingColor,
    dataType,
    dialect,
    mode,
    builderSetup,
    leftYAxisLabel,
    rightYAxisLabel,
    xAxisLabel,
    chartLabel,
    chartLayout,
  } = widget;

  const batch = firestoreRef().batch();
  const codeResults: CodeInterpreterResponse = {
    pythonCode: codeResult?.pythonCode || '',
    processId: codeResult?.processId || '',
    error: codeResult?.error || '',
    files: codeResult?.files || [],
    executionId: codeResult?.executionId || '',
    queries: codeResult?.queries || [],
    codeResults: codeResult?.codeResults || '',
  };

  batch.set(
    getMainElementDbRef(dashboard).collection('widgets').doc(queryId),
    {
      shopId: shopId,
      type: type || 'table',
      queryId: queryId,
      conversationId: conversationId || null,
      title: title || '',
      metrics: metrics || [],
      queries: queries || [],
      parameters: parameters || [],
      stacked: !!stacked,
      incrementedStacked: !!incrementedStacked,
      wrapText: !!wrapText,
      withoutMainQuery: !!withoutMainQuery,
      breakdownMode: !!breakdownMode,
      codeResult: codeResult ? codeResults : null,
      hasGlobalConditionalFormatting: !!hasGlobalConditionalFormatting,
      globalConditionalFormattingColor: globalConditionalFormattingColor || '',
      dataType: dataType || 'nlq',
      dialect: dialect || 'bigquery',
      mode: mode || 'sql',
      builderSetup: builderSetup || null,
      leftYAxisLabel: leftYAxisLabel || null,
      rightYAxisLabel: rightYAxisLabel || null,
      xAxisLabel: xAxisLabel || null,
      chartLabel: chartLabel || {},
      chartLayout: chartLayout || 'horizontal',
    },
    {
      merge: true,
    },
  );

  batch.set(
    getMainElementDbRef(dashboard),
    {
      widgetIds: arrayUnion(queryId),
    },
    {
      merge: true,
    },
  );

  await batch.commit();
}

export function formatSqlSafely(sql: string, dialect: Dialect | null = $dialect.get()) {
  try {
    const commentedQuery = sql.replace(/{{/g, '/*{{').replace(/}}/g, '}}*/');
    let formatted = sql;

    if (dialect === 'clickhouse') {
      formatted = formatDialect(commentedQuery, {
        dialect: clickhouse,
      });
    } else {
      formatted = format(commentedQuery, {
        language: dialect || 'mysql',
      });
    }
    const uncommentedQuery = formatted.replaceAll('/*{{', '{{').replaceAll('}}*/', '}}');
    return uncommentedQuery;
  } catch (e) {
    console.log('Info only: error formatting SQL');

    return sql;
  }
}

export function parseCodeSnippet(code: string) {
  if (!code) {
    return '';
  }

  if (code.startsWith('```python')) {
    return code.replace('```python', '').replace('```', '');
  }

  return code;
}

// https://cestoliv.com/blog/how-to-count-emojis-with-javascript/
export function visibleLength(str) {
  if (!Intl.Segmenter) {
    return str.length;
  }

  return [...new Intl.Segmenter().segment(str)].length;
}

export async function mainQueryChanged(params: MainQueryChangedParams): Promise<string | boolean> {
  try {
    const {
      newQuery,
      oldQueryId,
      dashboardId,
      isGlobalDashboard,
      isCustomViewDashboard,
      queryParameters,
    } = params;
    if (!dashboardId) {
      return 'Report is not exists';
    }
    if (!newQuery.queryId) {
      return 'New query not defined';
    }

    const batch = firestoreRef().batch();
    const partialDash: Partial<WillyDashboardElement> = {
      id: dashboardId,
      isGlobal: !!isGlobalDashboard,
      isCustomView: !!isCustomViewDashboard,
    };
    const dashboardRef = getMainElementDbRef(partialDash as WillyBaseMainElement);

    const dashboardDoc = await dashboardRef.get();
    const dashboardData = dashboardDoc.data() as WillyDashboardElement | undefined;
    const dashboardLayout: ReactGridLayout.Layout[] = JSON.parse(dashboardData?.layout || '[]');

    const updatedLayout = dashboardLayout.map((x) => {
      if (x.i === oldQueryId) {
        return {
          ...x,
          i: newQuery.queryId,
        };
      }
      return x;
    });

    batch.set(
      dashboardRef,
      {
        widgetIds: FieldValue.arrayUnion(newQuery.queryId),
        layout: JSON.stringify(updatedLayout),
      },
      {
        merge: true,
      },
    );

    batch.set(
      dashboardRef,
      {
        widgetIds: FieldValue.arrayRemove(oldQueryId),
      },
      {
        merge: true,
      },
    );

    const oldWidgetRef = dashboardRef.collection('widgets').doc(oldQueryId);
    const newWidgetRef = dashboardRef.collection('widgets').doc(newQuery.queryId);
    const oldWidget = await oldWidgetRef.get();

    const oldWidgetData = oldWidget.data() as WillyWidgetElement | undefined;

    if (!oldWidgetData) {
      return 'Widget does not exist';
    }

    const newQueries: WidgetQuery[] =
      oldWidgetData.queries?.map((x) => {
        if (x.id === oldQueryId) {
          return {
            question: newQuery.question!,
            id: newQuery.queryId!,
            query: newQuery.generatedQuery!,
            explanation: '',
          };
        }
        return x;
      }) || [];

    const metrics: WillyMetric[] = createWillyMetricsFromRawData({
      data: newQuery.data,
      sqlQuery: newQuery.generatedQuery!,
      initialMetrics: oldWidgetData.metrics.filter((x) =>
        newQuery.data.some((d) => d.name === x.key),
      ),
      servicesIds: newQuery.serviceIds || [],
      dataType: oldWidgetData.dataType,
      visualizationType: newQuery.visualizationType,
    });

    if (!newQuery.queryId) {
      return 'New query not defined';
    }

    const parametersWithoutQueryParams = newQuery.parameters?.filter((p) => {
      const exists = queryParameters?.find((np) => np.column === p.column);
      return !exists;
    });
    const allParameters = [...(queryParameters || []), ...(parametersWithoutQueryParams || [])];

    const newWidget: WillyWidgetElement = {
      queryId: newQuery.queryId! || newQuery.queries?.[0]?.id!,
      type: oldWidgetData.type || 'table',
      parameters: allParameters,
      metrics: metrics || [],
      title: oldWidgetData.title || '',
      stacked: !!oldWidgetData.stacked,
      incrementedStacked: !!oldWidgetData.incrementedStacked,
      wrapText: !!oldWidgetData.wrapText,
      queries: newQueries,
      dialect: newQuery.dialect || 'bigquery',
    };

    batch.set(
      newWidgetRef,
      {
        ...newWidget,
        historicalQueryIds: FieldValue.arrayUnion(oldQueryId),
      },
      {
        merge: true,
      },
    );

    batch.delete(oldWidgetRef);

    await batch.commit();

    return true;
  } catch (e) {
    console.error(e);
    return e.message || e;
  }
}

export function createNewPopupQuery(sql: string, name: string) {
  const q: Omit<WillyWidgetElement, 'queryId'> = {
    queries: [
      {
        id: '',
        question: `New query for ${name}`,
        query: sql,
      },
    ],
    title: `New query for ${name}`,
    type: 'table',
    metrics: [],
    incrementedStacked: false,
    stacked: false,
    wrapText: false,
    dialect: $dialect.get(),
  };

  return q;
}

export function hasTotalRow(data: RawNlqData) {
  const hasTotalRow = data?.some((x) => x.value.some(keyIsTotal));
  return hasTotalRow;
}

export function totalRowIndex(data: RawNlqData, hasTotalRow: boolean) {
  if (!hasTotalRow) {
    return undefined;
  }
  const totalRowIndex = data?.find((x) => x.value.some(keyIsTotal))?.value.findIndex(keyIsTotal);
  return totalRowIndex;
}

export function totalRowData(data: RawNlqData, metricKey: string) {
  if (data.length === 1) {
    return data.find((d) => d.name === metricKey)?.value;
  }

  const htr = hasTotalRow(data);
  const tri = totalRowIndex(data, htr);
  if (tri === undefined || tri === -1) {
    const metricData = data.find((d) => {
      return d.name === metricKey;
    });
    if (metricData?.value.length === 1) {
      return metricData.value;
    }
    return undefined;
  }

  const d = data.filter((x) => x.name === metricKey).map((x) => x.value[tri]);

  if (d.every((x) => typeof x === 'string')) {
    return undefined;
  }

  return d;
}

export function detectDynamicParameters(query: string) {
  const dynamicParts = query.match(/\{\{.*?\}\}/g);
  return dynamicParts;
}

export function extractSnippets(query: string) {
  const dynamicParts = detectDynamicParameters(query);
  if (!dynamicParts) {
    return [];
  }
  const snippets = dynamicParts.filter((part) => {
    return part.replace('{{', '').trim().startsWith('snippet:');
  });

  return snippets.map((s) =>
    s.replace('{{', '').replace('}}', '').replace('snippet:', '').trim().replace('@', ''),
  );
}

export function extractOptionalVariables(query: string, queryVariables: string[]): string[] {
  // find all the dynamic parts of the query wrapped in {{ }}
  const dynamicParts = detectDynamicParameters(query);
  if (!dynamicParts) {
    return [];
  }

  // snippets are not optional variables even though they are wrapped in {{ }}
  const snippets = extractSnippets(query);

  const optionalVariables = queryVariables.filter((variable) => {
    const isOptional = dynamicParts.some((part) => part.includes(`@${variable}`));
    const isSnippet = snippets.includes(variable);
    return isOptional && !isSnippet;
  });
  return optionalVariables;
}

function removeStringLiterals(sqlQuery: string) {
  // This pattern matches string literals enclosed in single or double quotes.
  // It includes support for escaped quotes within the literals.
  const pattern = /'((?:''|[^'])*)'|"((?:""|[^"])*)"/g;

  // Replace matched string literals with an empty string
  const cleanedQuery = sqlQuery.replace(pattern, '');

  return cleanedQuery;
}

export function extractVariablesFromWhereClause(query: string) {
  // Remove comments from the query (supports both /* */ and -- comments)
  const queryWithoutComments = query.replace(/\/\*[\s\S]*?\*\/|--.*?(?:\r?\n|$)/g, '');

  const cleanedQuery = removeStringLiterals(queryWithoutComments);

  // Use a regular expression to find variables within the "where" clause
  const pattern = /@([a-zA-Z_][a-zA-Z0-9_]*)/g;

  const matches = cleanedQuery.match(pattern);

  if (matches) {
    // Remove the "@" symbol from each variable
    return matches.map((match) => {
      const atIndex = match.indexOf('@');
      return match.substring(atIndex + 1);
    });
  }

  return [];
}

export const mode = (arr: number[]) => {
  if (!arr || !Array.isArray(arr) || arr.length === 0 || arr.some((x) => typeof x !== 'number')) {
    return 0;
  }

  const mode = {};
  let max = 0,
    count = 0;

  for (let i = 0; i < arr.length; i++) {
    const item = arr[i];

    if (mode[item]) {
      mode[item]++;
    } else {
      mode[item] = 1;
    }

    if (count < mode[item]) {
      max = item;
      count = mode[item];
    }
  }

  return max;
};

export const median = (arr: number[]) => {
  if (!arr || !Array.isArray(arr) || arr.length === 0 || arr.some((x) => typeof x !== 'number')) {
    return 0;
  }
  const { length } = arr;

  arr.sort((a, b) => a - b);

  if (length % 2 === 0) {
    return (arr[length / 2 - 1] + arr[length / 2]) / 2;
  }

  return arr[(length - 1) / 2];
};

export function emptyArray<T>() {
  return EMPTY_ARRAY as Array<T>;
}

export async function createNewWidget(
  shopId: string,
  dashboard: WillyDashboardElement,
  fields?: Partial<WillyWidgetElement>,
): Promise<WillyWidgetElement> {
  const queryId = uuidV4();

  const widget: WillyWidgetElement = {
    queryId,
    queries: [],
    parameters: [],
    stacked: false,
    incrementedStacked: false,
    wrapText: false,
    withoutMainQuery: true,
    dialect: $dialect.get(),
    ...fields,
    title: fields?.title || 'New Widget',
    type: fields?.type || 'tile',
    metrics: fields?.metrics || [],
    mode: fields?.mode || 'sql',
  };

  await addWidgetToDashboard(dashboard, widget, shopId);

  return widget;
}

export const updateDashboardWidget = async (
  dashboard: Partial<WillyDashboardElement>,
  fieldsToUpdate: Partial<WillyWidgetElement>,
  widgetId: string,
  shopId?: string | null,
  userId?: string | null,
) => {
  if (!dashboard.id) throw new Error(`can't update report without id`);
  const body = {
    shopId: shopId || getTrueShopId() || $currentShopId.get(),
    userId: userId || getUserId(),
    dashboardId: dashboard.id,
    fieldsToUpdate,
    widgetId,
  };
  if (dashboard.isGlobal) {
    body['isGlobal'] = true;
  }
  if (dashboard.isCustomView) {
    body['isCustomView'] = true;
  }
  await axiosInstance.post('v2/willy/update-dashboard-widget', body);
};

export const updateMainElement = async (
  element: WillyBaseMainElement,
  fieldsToUpdate:
    | Partial<WillyBaseMainElement>
    | Partial<WillyDashboardElement>
    | Partial<WillyDataSequence>,
  shopId?: string | null,
  userId?: string | null,
) => {
  const body = {
    shopId: shopId || getTrueShopId() || $currentShopId.get(),
    userId: userId || getUserId(),
    dashboardId: element.id,
    fieldsToUpdate,
  };
  if (isDashboardElement(element) || !element.type) {
    if (element.isGlobal) {
      body['isGlobal'] = true;
    }
    if ((element as WillyDashboardElement).isCustomView) {
      body['isCustomView'] = true;
    }
    await axiosInstance.post('v2/willy/update-dashboard', body);
  } else {
    if (element.isGlobal)
      await firestoreRef()
        .collection('global_data_sequences')
        .doc(element.id)
        .set(fieldsToUpdate, { merge: true });
    else
      await _db().collection('data_sequences').doc(element.id).set(fieldsToUpdate, { merge: true });
  }
};

export const getMainElementDbRef = (
  element: WillyBaseMainElement,
): DocumentReference<DocumentData> => {
  if (isDashboardElement(element) || !element.type) {
    if (element.isGlobal) {
      return firestoreRef().collection('willy_global_dashboards').doc(element.id);
    }
    if ((element as WillyDashboardElement).isCustomView) {
      return getCustomViewDashboardCollectionRef().doc(element.id);
    }
    return _db().collection('willy_dashboards').doc(element.id);
  } else {
    return element.isGlobal
      ? firestoreRef().collection('global_data_sequences').doc(element.id)
      : _db().collection('data_sequences').doc(element.id);
  }
};

export const getCustomViewDashboardCollectionRef = () =>
  userDb().collection('willy').doc($currentShopId.get()!).collection('dashboards');

export const copyDashboard = async (
  hostDashboardId: string,
  hostStoreId: string | undefined,
  userEmail: string,
  user: Partial<User>,
  force = false,
  overrideDashboardData: Partial<WillyDashboardElement> = {},
  isCustomView = false,
  dashboardIdToUpdate: string | undefined = undefined,
) => {
  const { data } = await axiosInstance.post('v2/willy/duplicate-dashboard', {
    shopId: $currentShopId.get(),
    hostDashboardId: hostDashboardId,
    force,
    hostStoreId,
    userEmail,
    user,
    overrideDashboardData,
    isCustomView,
    dashboardIdToUpdate,
  });

  // log event
  genericEventLogger(analyticsEvents.WILLY_DASH, {
    action: willyDashActions.DUPLICATE_DASHBOARD,
    id: data.id,
    force,
    hostId: hostDashboardId,
    title: data.name,
    description: data.description,
    userEmail,
  });

  return data.id;
};

export const copyGlobalSequenceToShop = async (sequence: WillyDataSequence) => {
  const { id, isGlobal, user, ...rest } = sequence;
  try {
    const { data } = await axiosInstance.post('v2/willy/copy-sequence', {
      shopId: $currentShopId.get(),
      uid: $user.get().uid,
      templateId: id,
    });
    return data?.id;
  } catch (e) {
    console.error('Error copying global sequence to shop', e);
    toast.error('Error copying global sequence to shop');
  }
};

export async function updateBudgetOrBid(
  shopId: string,
  entity: AnalyticsObjectType,
  id: string,
  field: string,
  value: string,
) {
  let message = '',
    success = false;
  const fieldToLog = field === 'budget' ? 'newBudget' : 'newBidAmount';
  try {
    const { data } = await axiosInstance.post('/v2/facebook-ads/update-ad', {
      shopId: shopId,
      entity: entity,
      id: id,
      field: field,
      value: +value * 100,
    });

    if (data.error) {
      message = data.error;
      genericEventLogger(analyticsEvents.ATTRIBUTION, {
        action: true ? attributionActions.SAVE_BUDGET_ERROR : attributionActions.SAVE_BID_ERROR,
        message: true ? attributionMessages.SAVE_BUDGET_ERROR : attributionMessages.SAVE_BID_ERROR,
        error: message,
        shop: shopId,
        id,
        entity,
      });
    } else {
      message = true
        ? attributionMessages.SAVE_BUDGET_SUCCESS
        : attributionMessages.SAVE_BID_SUCCESS;
      success = true;
      genericEventLogger(analyticsEvents.ATTRIBUTION, {
        action: attributionActions.SAVE_BUDGET_SUCCESS,
        shop: shopId,
        id,
        entity,
        message,
        [fieldToLog]: value,
      });
    }
  } catch (e) {
    message = e.message;
    genericEventLogger(analyticsEvents.ATTRIBUTION, {
      action: true ? attributionActions.SAVE_BUDGET_ERROR : attributionActions.SAVE_BID_ERROR,
      message: true ? attributionMessages.SAVE_BUDGET_ERROR : attributionMessages.SAVE_BID_ERROR,
      shop: shopId,
      id,
      entity,
      error: message,
    });
  }
  genericEventLogger(analyticsEvents.ATTRIBUTION, {
    action: true ? attributionActions.SAVE_BUDGET : attributionActions.SAVE_BID,
    shop: shopId,
    id,
    entity,
    message,
    success,
    [fieldToLog]: value,
  });
  return { message, success };
}

export const isBooleanString = (v: string | number): v is 'true' | 'false' =>
  v === 'true' || v === 'false';
export const isNullString = (v: string | number): v is 'null' =>
  v === 'null' || v === '-' || v === null;

export function formatSingleValueCondition(
  metric: WillyMetric,
  value: WillyMetric['conditionalStyleValue'],
  conditionValue: WillyMetric['conditionalStyleValue'],
): { backgroundColor?: string } {
  const { valueIsNegative } = metric;

  // handle undefined values
  if (typeof value === 'undefined' || typeof conditionValue === 'undefined') {
    return {};
  }

  // handle boolean values
  if (isBooleanString(conditionValue) || isBooleanString(value)) {
    if (isBooleanString(value) !== isBooleanString(conditionValue)) {
      return {};
    }
    return {
      backgroundColor: value === conditionValue ? GREEN : RED,
    };
  }

  // handle null values
  if (isNullString(conditionValue) || isNullString(value)) {
    if (isNullString(value) !== isNullString(conditionValue)) {
      return {};
    }
    return {
      backgroundColor: value === conditionValue ? GREEN : RED,
    };
  }

  // handle numeric string values
  if (typeof value === 'string' || typeof conditionValue === 'string') {
    if (isNaN(Number(value)) || isNaN(Number(conditionValue))) {
      return {};
    }
    const condition = valueIsNegative ? value < conditionValue : value >= conditionValue;
    return {
      backgroundColor: condition ? GREEN : RED,
    };
  }

  return {};
}

function hexToRgb(hex: string) {
  let r = 0,
    g = 0,
    b = 0;
  if (hex.length === 7) {
    // Assuming input is in the form of #RRGGBB
    r = parseInt(hex.substr(1, 2), 16);
    g = parseInt(hex.substr(3, 2), 16);
    b = parseInt(hex.substr(5, 2), 16);
  }
  return [r, g, b];
}

function rgbToHex(r: number, g: number, b: number) {
  return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase();
}

function lightenColor(baseColor: string, value: number, max: number, min: number) {
  let [r, g, b] = hexToRgb(baseColor);

  // Adjust the formula to avoid reaching pure white.
  // Ensure there's a minimal amount of original hue in the lightest color.
  let factor = (max - value) / (max - min);
  const maxLightening = 0.9; // Prevents reaching full white; adjust for deeper colors

  // Apply a maximum lightening limit
  factor = Math.min(factor * maxLightening, 1);

  // Calculate the new RGB values, preventing full saturation to white
  r = Math.min(255, r + Math.round(factor * (255 - r)));
  g = Math.min(255, g + Math.round(factor * (255 - g)));
  b = Math.min(255, b + Math.round(factor * (255 - b)));

  return rgbToHex(r, g, b);
}

export function generateColorScale(baseColor: string, values: number[]) {
  const max = Math.max(...values);
  const min = Math.min(...values);

  let colorMap: Record<number, string> = {};
  values.forEach((value) => {
    colorMap[value] = lightenColor(baseColor, value, max, min);
  });

  return colorMap;
}

export function cleanQuestion(question: string) {
  const safeQuestion = question?.replace(/[^a-zA-Z0-9 -]/g, '') || 'unknown';

  const snakeCaseQuestion = snakeCase(safeQuestion);
  return snakeCaseQuestion.substring(0, 200);
}

export async function executeRce(
  abortSignal: AbortSignal,
  code: string,
  data?: string,
): Promise<CodeExecutionResponse> {
  const { data: dataResult } = await axiosInstance.post<
    any,
    { data: CodeExecutionResponse },
    { code: string; data?: string }
  >(
    '/v2/willy/execute-rce',
    {
      code,
      data,
    },
    {
      signal: abortSignal,
    },
  );
  return dataResult;
}

type SaveNewQueryParams = {
  widgetWithoutQuery: Omit<WillyWidgetElement, 'queryId'>;
  metric: WillyMetric;
  shopId: string;
  timezone: string;
  currency: string;
  activeAccounts: string[];
  dialect: Dialect;
  variables?: Record<string, any>;
};

export async function saveNewQuery(
  params: SaveNewQueryParams,
): Promise<{ error?: string; metric?: WillyMetric }> {
  const {
    widgetWithoutQuery,
    metric,
    shopId,
    timezone,
    currency,
    activeAccounts,
    variables,
    dialect,
  } = params;
  if (!metric) {
    return { error: 'Metric is not defined' };
  }
  if (!widgetWithoutQuery.queries?.[0].query) {
    return { error: 'Query cannot be empty' };
  }
  try {
    const queryResult = await executeCustomQuery({
      query: widgetWithoutQuery.queries?.[0].query || '',
      shopId: shopId,
      activeAccounts,
      currency,
      queryParams: variables,
      timezone: timezone,
      dialect,
    });
    if (queryResult.error) {
      return { error: queryResult.error };
    }
    const { data } = await axiosInstance.post('/v2/willy/save-query', {
      query: widgetWithoutQuery.queries?.[0].query,
      shopId: shopId,
      dialect,
    });

    const { queryId: newWidgetQueyId } = data;

    const q: WillyWidgetElement = {
      ...widgetWithoutQuery,
      queryId: newWidgetQueyId,
      metrics: createWillyMetricsFromRawData({
        data: queryResult.data,
        sqlQuery: queryResult.generatedQuery || '',
        initialMetrics: widgetWithoutQuery.metrics,
        servicesIds: queryResult.serviceIds || [],
        dataType: queryResult.dataType,
        visualizationType: queryResult.visualizationType,
      }),
      queries: [
        {
          id: newWidgetQueyId,
          query: widgetWithoutQuery.queries?.[0].query!,
          question: widgetWithoutQuery.queries?.[0].question!,
        },
      ],
    };
    const newMetric: WillyMetric = {
      ...metric,
      popupWidget: q,
    };
    return {
      metric: newMetric,
    };
  } catch (e) {
    console.error(e);
    return { error: e.message };
  }
}

export function createColumnFromParameter(parameter: WillyParameter) {
  const column: BqColumn = {
    id: parameter.column,
    name: parameter.column,
    title: parameter.column,
    type: 'string',
    options: parameter.options?.length
      ? parameter.options.map((p) => {
          return {
            label: p,
            value: p,
          };
        })
      : undefined,
  };
  return column;
}

export function getScheduleCollection(userId: string, shopId: string) {
  return userDb(userId)
    .collection('willy')
    .doc(shopId)
    .collection('scheduled_sequences') as firebase.firestore.CollectionReference<ScheduleItem>;
}

export function reorderPreviousDataByKeys(
  current: RawNlqData,
  previous: RawNlqData,
  keys: string[],
): RawNlqData {
  try {
    const len = current[0].value.length;
    // fill array with nulls
    const arr = Array(len).fill(null);
    const previousIndexes: any[] = arr.map((x, exampleIndex) => {
      const indexes = keys.map((key) => {
        const previousKey = previous.find((x) => x.name === key);
        const currentKey = current.find((x) => x.name === key);
        const previousIndexes = previousKey?.value
          .map((x, i) => {
            if (x === currentKey?.value[exampleIndex]) {
              return i;
            }
          })
          .filter(isNotUndefined);
        return {
          key,
          previousIndexes,
        };
      });

      // find value that exists in all indexes
      const commonIndexes = indexes.reduce((acc, curr) => {
        return acc.filter((x) => curr?.previousIndexes?.includes(x));
      }, indexes[0]?.previousIndexes || []);
      if (commonIndexes.length === 1) {
        return commonIndexes[0];
      } else if (commonIndexes.length > 1) {
        // console.log('Multiple indexes found', indexes);
      }
    });
    const reorderedPrevious = previous.map((x) => {
      const value = x.value?.length > 1 ? previousIndexes?.map((i) => x.value[i]) : x.value;
      return {
        ...x,
        value,
      };
    });
    return reorderedPrevious;
  } catch (e) {
    // console.error(e);
    return [];
  }
}

export function createMessageFromDb(conversation: DocumentData, conversationId: string) {
  if (!conversation.history) {
    return [];
  }

  let history = (conversation.history as HistoryItem[]) || [];

  history = history.filter((x) => {
    if (x.role === 'assistant' && x.tool_calls?.length && !x.text) {
      return false;
    }

    return true;
  });

  const messages: Message[] = history.map<Message>((y) => {
    const msg: Message = {
      role: y.role,
      id: y.messageId || uuidV4(),
      text: y.text,
      error: y.error,
      conversationId,
      question: y.text,
      originalQuestion: y.originalQuestion,
      title: y.title,
      toolsNames: y.role === 'tool' ? y.toolsNames || [] : [],
      userId: y.role === 'user' ? y.userId : undefined,
      fromWorkflowId: y.role === 'assistant' ? y.fromWorkflowId : undefined,
    };

    if (y.role === 'tool' && y.toolResults) {
      let body: string | undefined = undefined;
      let language: SupportedLanguages = 'text';

      msg.toolsNames = [y.toolResults.name];
      msg.toolResults = y.toolResults;
      y.toolResults.name = convertDeprecatedToolName(y.toolResults.name);
      const { name } = y.toolResults;

      let questionText = y.originalQuestion;
      if (
        y.toolResults?.name === 'TextToSQL' &&
        y.toolResults.nlqResponse &&
        y.toolResults.nlqResponse.question
      ) {
        questionText = y.toolResults.nlqResponse.question;
      }

      const toolProgress: ToolProgress = {
        progress: 100,
        name: name,
        processId: uuidV4(),
        step: `Finished analyzing for "${questionText}"`,
        body: body,
        language,
      };

      if (name === 'TextToPython') {
        const { pythonCode, error, data } = y.toolResults;
        body = pythonCode;
        language = 'python';
        if (error) {
          msg.error = error;
          toolProgress.step = 'An error occurred';
          toolProgress.body = error;
        }
      } else if (name === 'TextToSQL') {
        const { nlqResponse, errorForInterface } = y.toolResults;
        body = nlqResponse?.generatedQuery;
        language = 'sql';
        msg.dialect = nlqResponse?.dialect;

        let err = errorForInterface || nlqResponse?.error || nlqResponse?.errorForInterface?.[0];

        if (err) {
          msg.error = err;
          toolProgress.step = 'An error occurred';
          toolProgress.body = err;
        }
      } else if (name === 'Forecasting' || name === 'MarketingMixModel') {
        const { message } = y.toolResults;
        if (message?.error) {
          msg.error = message?.error;
          toolProgress.step = message?.error || 'An error occurred';
          toolProgress.body = message?.error;
        }

        msg.dialect = message?.dialect;
      }

      toolProgress.body = body;
      toolProgress.language = language;

      msg.toolProgress = toolProgress;
    }

    return msg;
  });

  return messages;
}

export function convertNlqResponseToMessageData(response: NlqResponse): MessageData {
  if (!response) {
    return {};
  }
  return {
    ...response,
    visualizationType: response.visualizationType,
    query: response.generatedQuery || '',
    progressInfo: {
      progress: 100,
      text: 'Finished',
    },
    metrics: createWillyMetricsFromRawData({
      data: response.data,
      sqlQuery: response.generatedQuery || '',
      initialMetrics: [],
      servicesIds: response.serviceIds || [],
      dataType: response.dataType,
      visualizationType: response.visualizationType,
    }),
  };
}

export function convertCodeResponseToMessageCodeInterpreterResponse(
  msg: CodeInterpreterResponse,
): CodeInterpreterResponse {
  const converted: CodeInterpreterResponse = {
    files: msg.files,
    codeResults: msg.codeResults,
    error: msg.error,
    pythonCode: msg.pythonCode,
    queries: msg.error
      ? undefined
      : msg.queries?.map((q) => {
          return {
            id: q.id,
            file_name: q.file_name,
            query: q.query,
            data: q.data,
          };
        }),
    data: msg.error ? undefined : msg.data,
    executionId: msg.executionId,
  };

  return converted;
}

export const isDashboardElement = (
  element: WillyDashboardElement | WillyDataSequence | WillyBaseMainElement,
): element is WillyDashboardElement => {
  return element.type === 'dashboard';
};

export const isSequenceElement = (
  element: WillyDashboardElement | WillyDataSequence | WillyBaseMainElement,
): element is WillyDataSequence => {
  return element.type === 'sequence';
};

export async function executeCustomQuery(params: ExecuteCustomQueryParams): Promise<NlqResponse> {
  const {
    shopId,
    query,
    activeAccounts,
    currency,
    timezone,
    queryParams,
    usePreAgg,
    dialect,
    granularity,
    page = 1,
    pageSize,
    applyGlobalFilters,
    orderBy,
    orderDirection,
  } = params;
  const forceSharded = $forceSharded.get();
  const { data } = await axiosInstance.post('/v2/willy/custom-query', {
    query,
    shopId,
    additionalShopIds: activeAccounts,
    currency,
    timezone,
    queryParams,
    usePreAgg,
    dialect,
    granularity,
    page,
    pageSize,
    applyGlobalFilters,
    forceSharded,
    orderBy,
    orderDirection,
  });

  return data;
}

export function getToolName(tool: WillyToolName) {
  const name = willyToolMap[tool]?.title || startCase(tool);
  return name;
}

export async function getMetricsDictionary() {
  const { data } = await axiosInstance.get('/v2/willy/get-metrics-dictionary');
  return data.metrics;
}

export async function getPromptsList() {
  const { data } = await axiosInstance.get<{ prompts: WillyPrompt[] }>(
    '/v2/willy/get-prompts-list-v2',
  );
  return data.prompts;
}

export async function getPaginatedPrompts(shopId, userId, category, searchTerm, page) {
  const { data } = await axiosInstance.post<{
    prompts: WillyPrompt[];
    topics: { topic: string; subCategories: string[] }[];
    pagination: { hasNextPage: boolean; totalItems: number };
  }>(`/v2/willy/prompts-list-v2`, {
    shopId: shopId,
    userId: userId,
    category,
    searchTerm,
    page,
  });
  return data;
}

export async function getShopReportPrompts(shopId: string, page = 0) {
  const { data } = await axiosInstance.post<{
    pagination: { hasNextPage: boolean; totalItems: number };
    prompts: WillyPrompt[];
  }>(`/v2/willy/shop-report-prompts`, {
    shopId: shopId,
    page,
  });
  return data;
}

export function fieldIsWidget(
  field: WillyFieldElement | WillyWidgetElement,
): field is WillyWidgetElement {
  return (field as WillyWidgetElement).queryId !== undefined;
}

export async function removeFavoritePrompt(promptId: string) {
  return await _db().collection(FAVORITE_PROMPTS_COLLECTION).doc(promptId).delete();
}

type TableBuilderMetadata = {
  shopId: string;
  additionalShopIds: string[];
};

export async function fetchQueryBuilderData(
  jsonStuff: BuilderTable,
  metadata: TableBuilderMetadata,
) {
  const { data } = await axiosInstance.post<NlqResponse>('/v2/willy/json-query', {
    data: jsonStuff,
    metadata,
    // needed for the backend for auth :/
    shopId: metadata.shopId,
    additionalShopIds: metadata.additionalShopIds,
  });

  return data;
}

export function filterHasValue(filter: WillyParameter) {
  let { value, column } = filter;
  if (column === 'start_date' || column === 'end_date' || column === 'event_date') {
    return false;
  }
  if (!Array.isArray(value)) {
    value = [value];
  }
  return value.every((v) => v !== null && v !== undefined && v !== '');
}

export function getDataLayerEventByContext(context?: ChatSources) {
  if (context === 'chat' || context === 'sequence') {
    return analyticsEvents.CHAT;
  }
  if (context === 'editor') {
    return analyticsEvents.SQWHALE;
  }
  return analyticsEvents.DASHBOARDS;
}

export function getCurrentAnalyticsActionSet(context?: ChatSources) {
  if (context === 'chat' || context === 'sequence') {
    return chatActions;
  }
  if (context === 'editor') {
    return sqwhaleActions;
  }
  return dashboardsActions;
}

export function convertDeprecatedToolName(
  toolName: DeprecatedToolNames | WillyToolName,
): WillyToolName {
  switch (toolName) {
    case 'tryToGetDataFromBigQuery':
    case 'TextToSQL':
      return 'TextToSQL';
    case 'Searching':
    case 'helpCenter':
    case 'webSearch':
      return 'Searching';
    case 'codeInterpreter':
    case 'TextToPython':
      return 'TextToPython';
    case 'forecaster':
    case 'Forecasting':
      return 'Forecasting';
    case 'ChatTool_MMM_ToolPrompt':
    case 'MarketingMixModel':
      return 'MarketingMixModel';
    case 'GenerateInsights':
      return 'GenerateInsights';
  }
}

export function getQueryIdFromMessage(message?: Message): string | null {
  if (!message) {
    return null;
  }
  const { toolResults } = message;
  if (!toolResults) {
    return null;
  }

  switch (toolResults?.name) {
    case 'TextToSQL':
      return toolResults.nlqResponse?.queryId || null;
    case 'Forecasting':
    case 'MarketingMixModel':
      return toolResults.message?.queryId || null;
  }

  return null;
}

export function getSqlFromMessage(message?: Message): string | null {
  if (!message) {
    return null;
  }
  const { toolResults } = message;
  if (!toolResults) {
    return null;
  }

  toolResults.name = convertDeprecatedToolName(toolResults?.name);
  let query = '';

  switch (toolResults?.name) {
    case 'TextToSQL':
      query = toolResults.sqlGenerated || '';
      break;
    case 'Forecasting':
    case 'MarketingMixModel':
      query = toolResults.message?.generatedQuery || '';
      break;
  }

  query = formatSqlSafely(query, message.dialect);

  return query || null;
}

export function getSourcesFromMessage(message: Message): ServicesIds[] {
  const { toolResults } = message;

  if (!toolResults) {
    return [];
  }

  toolResults.name = convertDeprecatedToolName(toolResults.name);

  switch (toolResults?.name) {
    case 'TextToSQL':
      return toolResults.nlqResponse?.serviceIds || [];

    case 'Forecasting':
    case 'MarketingMixModel':
      return toolResults.message?.serviceIds || [];
  }

  return [];
}

export function getColumnsFromMessage(message?: Message): string[] {
  if (!message) {
    return [];
  }
  const { toolResults } = message;

  if (!toolResults) {
    return [];
  }

  toolResults.name = convertDeprecatedToolName(toolResults.name);

  switch (toolResults?.name) {
    case 'TextToSQL':
      return toolResults.nlqResponse?.data?.map((x) => x.name) || [];

    case 'Forecasting':
    case 'MarketingMixModel':
      return toolResults.message?.data?.map((x) => x.name) || [];
  }

  return [];
}

export function getParametersFromMessage(message: Message): WillyParameter[] | null {
  const { toolResults } = message;

  if (!toolResults) {
    return null;
  }

  toolResults.name = convertDeprecatedToolName(toolResults.name);

  switch (toolResults?.name) {
    case 'TextToSQL':
      return toolResults.nlqResponse?.parameters || null;

    case 'Forecasting':
    case 'MarketingMixModel':
      return toolResults.message?.parameters || null;
  }

  return null;
}

export function getTablesFromMessage(message: Message): string[] | null {
  const { toolResults } = message;

  let tables: string[] | null = null;

  if (!toolResults) {
    return null;
  }

  toolResults.name = convertDeprecatedToolName(toolResults.name);

  switch (toolResults?.name) {
    case 'TextToSQL':
      const fromMsg = toolResults.nlqResponse?.tables;
      if (typeof fromMsg === 'string') {
        tables = [fromMsg];
      } else {
        tables = fromMsg || null;
      }
      break;

    case 'Forecasting':
    case 'MarketingMixModel':
      const fromForecaster = toolResults.message?.tables;
      if (typeof fromForecaster === 'string') {
        tables = [fromForecaster];
      } else {
        tables = fromForecaster || null;
      }
  }

  tables = tables
    ? tables.map((x) => {
        if (x === 'pixel_joined_table') {
          return 'pixel_joined_tvf';
        }
        return x;
      })
    : null;

  return tables;
}

export function getQueryDatesFromMessage(message: Message): string | null {
  if (!message.toolResults) {
    return null;
  }

  message.toolResults.name = convertDeprecatedToolName(message.toolResults.name);

  switch (message.toolResults?.name) {
    case 'TextToSQL':
      return message.toolResults?.nlqResponse?.datesRange || null;

    case 'Forecasting':
    case 'MarketingMixModel':
      return message.toolResults?.message?.datesRange || null;
  }

  return null;
}

export function getQuestionFromMessage(message: Message): string | null {
  if (!message.toolResults) {
    return null;
  }

  message.toolResults.name = convertDeprecatedToolName(message.toolResults.name);

  switch (message.toolResults?.name) {
    case 'TextToSQL':
      return message.toolResults?.nlqResponse?.question || null;

    case 'Forecasting':
    case 'MarketingMixModel':
      return message.toolResults?.message?.question || null;
  }

  return null;
}

export function getConversationDialect(conversation?: Conversation): Dialect | null {
  if (!conversation) {
    return null;
  }
  const toolResults = conversation.history?.find((x) => x.role === 'tool');
  if (!toolResults?.toolResults) {
    return null;
  }

  toolResults.toolResults.name = convertDeprecatedToolName(toolResults.toolResults.name);

  const { name } = toolResults.toolResults;

  if (name === 'TextToSQL') {
    return toolResults.toolResults.nlqResponse?.dialect || null;
  } else if (name === 'Forecasting' || name === 'MarketingMixModel') {
    return toolResults.toolResults.message?.dialect || null;
  }

  return null;
}

type ConvertQueryToBuilderResponse = {
  sqlQuery: string;
  isValidated?: boolean;
  jsonQuery?: BuilderTable;
  error?: string;
};

export async function convertQueryToBuilder(query: string, shopId: string) {
  const CONVERSION_READY = false;
  if (!CONVERSION_READY) {
    return {
      isValidated: false,
      jsonQuery: undefined,
      sqlQuery: query,
      error: 'Conversion is disabled',
    };
  }
  if (!query) {
    return { isValidated: false, jsonQuery: undefined, sqlQuery: query, error: 'Query is empty' };
  }
  try {
    const { data } = await axiosInstance.post<ConvertQueryToBuilderResponse>(
      '/v2/willy/parse-query',
      {
        query,
        shopId,
      },
    );

    return data;
  } catch (e) {
    console.error(e);
    return { isValidated: false, jsonQuery: undefined, sqlQuery: query, error: e.message };
  }
}

export function createBqColumn(props: {
  id: string;
  name: string;
  type?: SQLType;
  options?: OptionsObj[];
}): BqColumn {
  const { id, name, type, options = [] } = props;
  return {
    id,
    name,
    title: name,
    type: type!, // Can be empty
    options,
  };
}

export function getToolPreloadFromToolResult(
  toolResult?: ToolResult,
): WorkflowStepToolPreload | undefined {
  if (!toolResult) {
    return undefined;
  }
  if (toolResult.name === 'TextToPython') {
    return toolResult;
  }
  if (toolResult.name === 'TextToSQL' && toolResult.nlqResponse) {
    return {
      name: toolResult.name,
      ...toolResult.nlqResponse,
    };
  }
  return undefined;
}

export function calculateDataSize(response: NlqResponse) {
  const tokens = encode(JSON.stringify(response));
  const bytes = JSON.stringify(response).length;
  return {
    tokens: tokens.length,
    bytes: bytes,
  };
}
