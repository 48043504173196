import { Flex, Text, Button } from '@tw/ui-components';
import _db from 'utils/DB';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';

import { WillyPageWrapper } from './WillyPageWrapper';
import { SequencesList } from './SequencesList';
import { WillyTemplatesInner } from './dashboardManagment/WillyTemplates';

import { Allotment } from 'allotment';
import { MENU_SIZE } from './constants';

import { computeFeatureFlags } from '../../feature-flag-system';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { UpgradePageFallBack } from '../../feature-flag-system/components';
import { SequenceRunsModal } from './utils/SequenceRuns';
import { $sequenceSelectorDrawer, closeSequenceSelectorDrawer } from '$stores/willy/$mobileDrawers';
import { useComputedValue } from '@tw/snipestate';
import { SequencesAllList } from './SequencesAllList';
import { MobileDrawer } from '@tw/ui-components';

const SEQUENCE_NAV_ITEMS = [
  {
    id: 'shop',
    label: 'Workflows',
    link: '/workflows/shop',
    // icon: 'clock',
    content: ({ setRunsOpen }) => <SequencesList isPage={true} setRunsOpen={setRunsOpen} />,
  },
  {
    id: 'inbox',
    label: 'Inbox',
    link: '/workflows/inbox',
    // icon: 'tiles-view',
    content: <SequencesAllList />,
  },
  {
    id: 'templates',
    label: 'Templates',
    link: '/workflows/templates',
    // icon: 'button-play',
    content: (
      <div className="relative h-full px-6 sm:p-0 sm:pl-6">
        <WillyTemplatesInner
          templateType="sequence"
          setTemplateType={() => {}}
          isSequencesPage
          hideHeader
          onlyInner
        />
      </div>
    ),
  },
];

type NavItem = (typeof SEQUENCE_NAV_ITEMS)[0];
type SequencesNavItemProps = { item: NavItem };

const SequencesNavItem = ({ item }: SequencesNavItemProps) => {
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const currentPage =
    SEQUENCE_NAV_ITEMS.find((item) => pathname.includes(item.link)) || SEQUENCE_NAV_ITEMS[0];

  return (
    <div
      onClick={() => {
        closeSequenceSelectorDrawer();
        navigate({ pathname: item.link, search });
      }}
      className={
        'no-underline text-[var(--mantine-color-gray-7)] font-medium group/dashboard-label gap-2 ' +
        'render-item flex rounded-md items-center ' +
        'cursor-pointer hover:bg-[var(--gray-light-mode-300)] py-1 ' +
        (item.id === currentPage?.id ? 'bg-[var(--gray-light-mode-300)]' : '')
      }
    >
      <div className="flex items-center gap-2 pl-4 py-1">
        {/* <Icon name={item.icon as any} /> */}
        <Text span fz="sm" c="gray.8" fw={500}>
          {item.label}
        </Text>
        <div className="opacity-0 group-hover/report-side-panel:opacity-100 pl-4 min-w-[24px]" />
      </div>
    </div>
  );
};

const SequencesSidePanel = () => {
  const isSmall = useIsSmall();

  return (
    <>
      <div className="bg-white sticky top-0 z-10">
        {!isSmall && (
          <>
            <Flex justify="space-between" align="center" p="md" h={65}>
              <Text fz="xl" fw="500">
                Workflows
              </Text>
              <div className="text-[12px] rounded-full bg-gray-100 text-blue-500 p-4 py-1">
                Beta
              </div>
            </Flex>

            <div className="border-t border-solid border-l-0 border-r-0 border-b-0 willy-border" />
          </>
        )}
      </div>

      <div className="willy-side-panel dash-panel p-4 gap-4 flex flex-col">
        {SEQUENCE_NAV_ITEMS.map((item) => (
          <SequencesNavItem key={item.id} item={item} />
        ))}
      </div>
    </>
  );
};

export const MobileDrawerSequenceSelector = () => {
  const drawerOpen = useComputedValue($sequenceSelectorDrawer, (r) => r.opened);

  return (
    <MobileDrawer
      opened={drawerOpen}
      onClose={() => closeSequenceSelectorDrawer()}
      title="Workflows"
    >
      <div className="m-[-16px]">
        <SequencesSidePanel />
      </div>
    </MobileDrawer>
  );
};

export const SequencesComponent = () => {
  const { pathname, search } = useLocation();
  const currentPage =
    SEQUENCE_NAV_ITEMS.find((item) => pathname.includes(item.link)) || SEQUENCE_NAV_ITEMS[0];
  const navigate = useNavigate();
  const [runsOpen, setRunsOpen] = useState<string>();

  return (
    <div className="w-full h-full @container bg-white">
      <Allotment>
        <Allotment.Pane>
          <Allotment vertical>
            <Allotment.Pane maxSize={MENU_SIZE} minSize={MENU_SIZE}>
              <div className="willy-dash-header bg-white flex flex-row items-center lg:w-full h-full grow overflow-hidden overflow-x-auto  px-6 py-4 lg:px-8">
                <div className="flex items-center justify-between flex-grow">
                  <Text size="lg" weight={500}>
                    {currentPage?.label}
                  </Text>
                </div>
                <Button
                  onClick={() => {
                    navigate({ pathname: '/workflows/create', search });
                  }}
                  leftSection="plus-1"
                  iconSize={20}
                >
                  New Workflow
                </Button>
              </div>
            </Allotment.Pane>
            <Allotment.Pane className="dash-pane-wrapper !overflow-hidden willy-main-pane relative @container">
              {typeof currentPage?.content === 'function'
                ? currentPage.content({ setRunsOpen })
                : currentPage?.content}
            </Allotment.Pane>
          </Allotment>
        </Allotment.Pane>
      </Allotment>
      <SequenceRunsModal
        opened={!!runsOpen}
        close={() => setRunsOpen(undefined)}
        sequenceId={runsOpen}
      />
    </div>
  );
};

export const SequencesPage = () => {
  return (
    <WillyPageWrapper
      className="willy-page"
      showResizer={true}
      hideSidePanel={false}
      sidePanelContent={<SequencesSidePanel />}
      mainContent={<SequencesComponent />}
    />
  );
};

export const Sequences = computeFeatureFlags(
  [FeatureFlag.TW_3_0_FF, FeatureFlag.TW_ONLY_3_0_FF],
  SequencesPage,
  () => (
    <UpgradePageFallBack
      InAppContextBannerEnabled={false}
      title="TW 3.0"
      description="Chat, analyze and manage with TW 3.0, the AI assistant that helps you effortlessly interact with your eCommerce business."
      featureFlag={FeatureFlag.TW_3_0_FF}
    />
  ),
);
