import { LighthouseNotification } from '@tw/types/module/types/lighthouse';
import {
  LighthouseClientNotification,
  LighthouseNotificationSettings,
  LighthouseTypesRecord,
} from 'components/Lighthouse/types';
import { metrics } from 'constants/metrics/metrics';
import moment from '@tw/moment-cached/module/timezone';
import { ReactComponent as GreenAnomaly } from '../icons/anomaly-icon-green.svg';
import { ReactComponent as RedAnomaly } from '../icons/anomaly-icon-red.svg';
import { ReactComponent as RulesRecommendationIcon } from '../icons/lighthouse-rules-recommendation.svg';
import { ReactComponent as AudienceIcon } from '../icons/Ai-audience-icon.svg';
import { ReactComponent as TimingIcon } from '../icons/Ai-timing-icon.svg';
import { ReactComponent as GenerativeIcon } from '../icons/Ai-generative-icon.svg';
import { ReactComponent as RulesReportIcon } from '../icons/lighthouse-rules-report-icon.svg';
import { ReactComponent as MetricsReportIcon } from '../icons/lighthouse-metric-report-icon.svg';
import { ReactComponent as DataStoriesIcon } from '../icons/data-stories-text.svg';
import { LighthouseChart } from 'components/Lighthouse/LighthouseChart';
import { LighthouseLaunchRule } from 'components/Lighthouse/LighthouseLaunchRule';
import { LighthouseGeneratedAds } from 'components/Lighthouse/LighthouseGeneratedAds';
import { getCurrencySymbol } from './getCurrencySymbol';
import { MetricData } from '@tw/types/module/metrics/metrics';
import { formatNumber } from './formatNumber';
import { LighthouseTiming } from 'components/Lighthouse/LighthouseTiming';
import { LighthouseCDPAudience } from 'components/Lighthouse/LighthouseCDPAudience';
import { LighthouseRulesReports } from 'components/Lighthouse/LighthouseRulesReport';
import { LighthouseInventoryAlert } from 'components/Lighthouse/LighthouseInventoryAlert';
import { LighthouseBudgetSplit } from 'components/Lighthouse/LighthouseBudgetSplit';
import { capitalize, truncate } from 'lodash';
import { LighthouseDataStory } from 'components/Lighthouse/LighthouseDataStory';
import SERVICES from '../constants/services';
import TWImage from 'components/library/TWImage/TWImage';
import { LighthouseOpportunityReport } from 'components/Lighthouse/LighthouseOpportunityReport';
import KlaviyoIconBase64 from 'components/KlaviyoIconBase64';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';

export const convertBucketSpanToUnit = (bucketSpan: number) => {
  if (bucketSpan % 604800 === 0) {
    return 'week';
  }
  if (bucketSpan % 86400 === 0) {
    return 'day';
  }
  if (bucketSpan % 3600 === 0) {
    return 'hour';
  }
  return undefined;
};

export const transformSingleAnomalyNotification = (
  notification: LighthouseNotification,
  metadata,
) => {
  const { data, startDate, endDate, read } = notification || {};
  const { currency } = metadata || {};
  const { metric, typical, actual, bucket_span, service_id, level, campaign_name } = data || {};
  const metricObj: MetricData<any> = metrics[metric];
  const metricName = metricObj?.shortLabel || notification.data.metric || 'Unknown metric';
  const isUp = actual > typical;
  let diffBetweenActualAndTypical = Math.abs((actual - typical) / typical) * 100;
  let sign = '%';
  if (!isFinite(diffBetweenActualAndTypical)) {
    diffBetweenActualAndTypical = Math.abs(actual - typical);
    sign = getCurrencySymbol(currency);
  }
  let severity: LighthouseClientNotification['severity'] = 'info';
  if ((isUp && metricObj?.valueIsNegative) || (!isUp && !metricObj?.valueIsNegative)) {
    severity = 'error';
  } else if ((isUp && !metricObj?.valueIsNegative) || (!isUp && metricObj?.valueIsNegative)) {
    severity = 'success';
  } else {
    severity = 'info';
  }

  const unitOfTime = convertBucketSpanToUnit(bucket_span);
  const duration = moment(endDate).diff(startDate, unitOfTime);

  const unit = duration > 1 ? unitOfTime + 's' : unitOfTime;

  const n: LighthouseClientNotification = {
    docId: notification.docId,
    id: notification.id,
    title: (
      <div className="flex gap-2 items-center">
        {`${metricName} Anomaly`}
        {SERVICES[service_id]?.icon(true)}
      </div>
    ),
    description: `${metricName} is ${
      isUp ? 'up' : 'down'
    } in the last ${duration} ${unit} by ${diffBetweenActualAndTypical.toFixed(0)}${sign}
      ${level === 'campaign' ? `Campaign: "${truncate(campaign_name, { length: 23 })}" ` : ''}`,
    startDate: moment(notification.startDate),
    endDate: moment(notification.endDate),
    updatedAt: moment(notification.updatedAt),
    type: notification.type,
    severity,
    extraData: { ...data, unitOfTime, duration },
    read: read,
    Icon: (size) =>
      severity === 'error' ? (
        <RedAnomaly width={size} height={size} />
      ) : (
        <GreenAnomaly width={size} height={size} />
      ),
    archived: notification.archived,
  };
  return n;
};

export const transformSingleRuleNotification = (notification: LighthouseNotification, metadata) => {
  const { data, read } = notification || {};
  const { sumOfSaving } = data || {};
  const { currency } = metadata || {};

  const saving = formatNumber(sumOfSaving, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    style: 'currency',
    currency,
  });

  const n: LighthouseClientNotification = {
    docId: notification.docId,
    id: notification.id,
    title: 'Rules Recommendation',
    description: `Save ${saving} in the last 14 days`,
    startDate: moment(notification.startDate),
    endDate: moment(notification.endDate),
    updatedAt: moment(notification.updatedAt),
    type: notification.type,
    severity: 'info',
    extraData: data,
    read: read,
    Icon: (size) => <RulesRecommendationIcon width={size} height={size} />,
    archived: notification.archived,
  };
  return n;
};

export const transformSingleKlaviyoNotification = (notification: LighthouseNotification) => {
  const { data, read } = notification || {};

  const n: LighthouseClientNotification = {
    docId: notification.docId,
    id: notification.id,
    title: notification?.data?.title,
    description: notification?.data?.subtitle,
    startDate: moment(notification.startDate),
    endDate: moment(notification.endDate),
    updatedAt: moment(notification.updatedAt),
    type: notification.type,
    severity: 'info',
    extraData: data,
    read: read,
    Icon: (size) => <KlaviyoIconBase64 small={false} light />,
    archived: notification.archived,
  };
  return n;
};

export const transformSingleGeneratedAdNotification = (notification: LighthouseNotification) => {
  const { data, read } = notification || {};

  const n: LighthouseClientNotification = {
    docId: notification.docId,
    id: notification.id,
    title: `New AI Generated Ads`,
    description: `${data?.ads?.length} new ads based on yesterdays top performing ads and products.`,
    startDate: moment(notification.startDate),
    endDate: moment(notification.endDate),
    updatedAt: moment(notification.updatedAt),
    type: notification.type,
    severity: 'info',
    extraData: data,
    read: read,
    Icon: (size) => <GenerativeIcon width={size} height={size} />,
    archived: notification.archived,
  };
  return n;
};

export const transformSingleTimingNotification = (notification: LighthouseNotification) => {
  const { data, read } = notification || {};

  const n: LighthouseClientNotification = {
    docId: notification.docId,
    id: notification.id,
    title: 'Timing',
    description: 'An informative sentence about AI timing and Roas',
    startDate: moment(notification.startDate),
    endDate: moment(notification.endDate),
    updatedAt: moment(notification.updatedAt),
    type: notification.type,
    severity: 'info',
    extraData: data,
    read: read,
    Icon: (size) => <TimingIcon width={size} height={size} />,
    archived: notification.archived,
  };
  return n;
};

export const transformSingleAudienceNotification = (notification: LighthouseNotification) => {
  const { data, read } = notification || {};

  const n: LighthouseClientNotification = {
    docId: notification.docId,
    id: notification.id,
    title: 'AI Audience',
    description: 'New CDP audience cohort generated by AI',
    startDate: moment(notification.startDate),
    endDate: moment(notification.endDate),
    updatedAt: moment(notification.updatedAt),
    type: notification.type,
    severity: 'info',
    extraData: data,
    read: read,
    Icon: (size) => <AudienceIcon width={size} height={size} />,
    archived: notification.archived,
  };
  return n;
};

export const transformSingleInventoryNotification = (
  notification: LighthouseNotification<{
    id: string;
    title: string;
    image: string;
    inventoryItem: 'product' | 'variant';
    type: 'lead_time' | 'out';
    productTitle: string;
  }>,
) => {
  const { data, read } = notification || {};

  const { title, productTitle, image, type } = data || {};

  const color = type === 'lead_time' ? '#EA643D' : '#D80027';

  const n: LighthouseClientNotification = {
    docId: notification.docId,
    id: notification.id,
    title: (
      <div className="flex items-center gap-[6px]">
        <div className="w-[6px] h-[6px] rounded-md" style={{ backgroundColor: color }}></div>
        {type === 'lead_time' ? 'Low Inventory' : 'Out of Stock'}
      </div>
    ),
    description:
      type === 'lead_time'
        ? `${productTitle} - ${title} is running low`
        : `${productTitle} - ${title} is out of stock`,
    startDate: moment(notification.startDate),
    endDate: moment(notification.endDate),
    updatedAt: moment(notification.updatedAt),
    type: notification.type,
    severity: type === 'lead_time' ? 'error' : 'critical',
    extraData: data,
    read: read,
    Icon: (size) => (
      <div className="border border-solid rounded-lg w-full h-full" style={{ borderColor: color }}>
        <TWImage
          src={image || ''}
          alt={title}
          className="object-cover w-full h-full rounded-lg"
          wrapperClass="w-full h-full flex"
        />
      </div>
    ),
    archived: notification.archived,
  };
  return n;
};

export const transformSingleRulesReportNotification = (notification: LighthouseNotification) => {
  const { data, read } = notification || {};

  const { frequency } = data || {};

  const n: LighthouseClientNotification = {
    docId: notification.docId,
    id: notification.id,
    title: 'Rules Report',
    description: `Look what your rules did for you in the last ${
      frequency === 'day' ? '24 hours' : 'days'
    }`,
    startDate: moment(notification.startDate),
    endDate: moment(notification.endDate),
    updatedAt: moment(notification.updatedAt),
    type: notification.type,
    severity: 'info',
    extraData: data,
    read: read,
    Icon: (size) => <RulesReportIcon width={size} height={size} />,
    archived: notification.archived,
  };
  return n;
};
export const transformSingleMetricsReportNotification = (notification: LighthouseNotification) => {
  const { data, read } = notification || {};

  const { story } = data;

  const n: LighthouseClientNotification = {
    docId: notification.docId,
    id: notification.id,
    title: 'Total Impact',
    description: truncate(story || 'You got total impact report', { length: 60 }).replaceAll(
      '\n',
      ' ',
    ),
    startDate: moment(notification.startDate),
    endDate: moment(notification.endDate),
    updatedAt: moment(notification.updatedAt),
    type: notification.type,
    severity: 'info',
    extraData: data,
    read: read,
    Icon: (size) => <MetricsReportIcon width={size} height={size} />,
    archived: notification.archived,
  };
  return n;
};

export const transformSingleOpportunityReportNotification = (
  notification: LighthouseNotification,
) => {
  const { data, read } = notification || {};

  const { story } = data;

  const n: LighthouseClientNotification = {
    docId: notification.docId,
    id: notification.id,
    title: 'Opportunities',
    description: truncate(story || 'You have some opportunities to make your ads better', {
      length: 60,
    }).replaceAll('\n', ' '),
    startDate: moment(notification.startDate),
    endDate: moment(notification.endDate),
    updatedAt: moment(notification.updatedAt),
    type: notification.type,
    severity: 'info',
    extraData: data,
    read: read,
    Icon: (size) => <MetricsReportIcon width={size} height={size} />,
    archived: notification.archived,
  };
  return n;
};

export const transformSingleDataStoryNotification = (
  notification: LighthouseNotification,
): LighthouseClientNotification => {
  const { data, read } = notification || {};

  const { dataStory, titleStory, frequency } = data || {};

  const unitOfTime = frequency === 'day' ? 'Daily' : capitalize(frequency + 'ly');

  const n: LighthouseClientNotification = {
    docId: notification.docId,
    id: notification.id,
    title: `${unitOfTime} Data Story`,
    description: (titleStory || dataStory).substring(0, 60),
    startDate: moment(notification.startDate),
    endDate: moment(notification.endDate),
    updatedAt: moment(notification.updatedAt),
    type: notification.type,
    severity: 'info',
    extraData: data,
    read: read,
    Icon: (size) => <DataStoriesIcon width={size} height={size} />,
    archived: notification.archived,
  };
  return n;
};

export const LighthouseTypesDictionary: Readonly<LighthouseTypesRecord> = {
  // rule: {
  //   key: 'rule',
  //   transformer: transformSingleRuleNotification,
  //   Details: (props: LighthouseClientNotification) => <LighthouseLaunchRule {...props} />,
  //   title: 'Rules Recommendations',
  //   topics: ['rule'],
  //   dependOnFeatures: [FeatureFlag.PIXEL_ALL_FF],
  // },
  // klaviyo: {
  //   key: 'klaviyo',
  //   transformer: transformSingleKlaviyoNotification,
  //   link: '/activity-feed',
  //   noPopup: true,
  //   title: 'Klaviyo Notification',
  //   topics: ['campaign_sent'],
  // },
  anomaly: {
    key: 'anomaly',
    transformer: transformSingleAnomalyNotification,
    Details: (props: LighthouseClientNotification) => <LighthouseChart {...props} />,
    title: 'Anomaly',
    topics: ['spend', 'revenue', 'roas', 'orders'],
  },
  // generative: {
  //   key: 'generative',
  //   transformer: transformSingleGeneratedAdNotification,
  //   Details: (props: LighthouseClientNotification) => <LighthouseGeneratedAds {...props} />,
  //   title: 'Generative Ai Ads',
  //   topics: ['ads'],
  //   dependOnFeatures: [FeatureFlag.CC_CREATIVE_ANALYSIS_FF, FeatureFlag.PIXEL_ALL_FF],
  // },
  // timing: {
  //   key: 'timing',
  //   transformer: transformSingleTimingNotification,
  //   Details: (props: LighthouseClientNotification) => <LighthouseTiming {...props} />,
  //   title: 'Timing',
  // },
  'cdp-audience': {
    key: 'cdp-audience',
    transformer: transformSingleAudienceNotification,
    Details: (props: LighthouseClientNotification) => <LighthouseCDPAudience {...props} />,
    title: 'AI Audience',
    topics: ['create', 'update'],
    dependOnFeatures: [FeatureFlag.INSIGHT_GENERAL_FF],
  },
  // 'rules-report': {
  //   key: 'rules-report',
  //   transformer: transformSingleRulesReportNotification,
  //   Details: (props: LighthouseClientNotification) => <LighthouseRulesReports {...props} />,
  //   title: 'Rules',
  //   topics: ['facebook-ads'],
  //   dependOnFeatures: [FeatureFlag.PIXEL_ALL_FF],
  // },
  inventory: {
    key: 'inventory',
    transformer: transformSingleInventoryNotification,
    Details: (props: LighthouseClientNotification) => <LighthouseInventoryAlert {...props} />,
    title: 'Inventory',
    topics: ['out', 'lead_time'],
    dependOnFeatures: [FeatureFlag.PIXEL_ALL_FF],
  },
  'metrics-report': {
    key: 'metrics-report',
    transformer: transformSingleMetricsReportNotification,
    Details: (props: LighthouseClientNotification) => <LighthouseBudgetSplit {...props} />,
    title: 'Total Impact',
    topics: ['total_impact'],
    dependOnFeatures: [FeatureFlag.PIXEL_ALL_FF, FeatureFlag.POST_PURCHASE_SURVEY_FF],
  },
  'data-stories': {
    key: 'data-stories',
    transformer: transformSingleDataStoryNotification,
    Details: (props: LighthouseClientNotification) => <LighthouseDataStory {...props} />,
    title: 'Data Stories',
    topics: ['data-stories'],
  },
  'opportunity-report': {
    key: 'opportunity-report',
    transformer: transformSingleOpportunityReportNotification,
    Details: (props: LighthouseClientNotification) => <LighthouseOpportunityReport {...props} />,
    title: 'Opportunity Report',
    topics: ['opportunity-report'],
  },
};

export const DEFAULT_LIGHTHOUSE_NOTIFICATION_SETTINGS = Object.values(
  LighthouseTypesDictionary,
).reduce((acc, { key, topics }) => {
  acc[key] = topics.reduce((acc2, topic) => ((acc2[topic] = true), acc2), {});
  return acc;
}, {} as LighthouseNotificationSettings);
