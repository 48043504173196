import { Alert, Icon, Text } from '@tw/ui-components';
import { FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

type NoCreditsLeftAlertProps = {
  creditsLeft?: number;
};

export const NoCreditsLeftAlert: FC<NoCreditsLeftAlertProps> = ({ creditsLeft }) => {
  const navigate = useNavigate();
  const creditsText = useMemo(
    () =>
      creditsLeft
        ? `You have only ${creditsLeft} credit${creditsLeft === 1 ? '' : 's'} left.`
        : 'You have no credits left.',
    [creditsLeft],
  );

  return (
    <Alert color="yellow.3" icon={<Icon name="exclamation" color="yellow.4" size={20} />}>
      <Text size="sm" weight={500} color="yellow.8">
        {`${creditsText} Unlock unlimited reports for your team. `}
        <span
          onClick={() => navigate('/store-settings/plans')}
          className="text-[14px] text-[var(--mantine-color-yellow-8)] font-medium underline cursor-pointer"
        >
          Upgrade
        </span>
      </Text>
    </Alert>
  );
};
