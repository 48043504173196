import React, { useCallback, useMemo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/RootType';
import _db from 'utils/DB';
import { WillyWidget } from '../WillyWidget';
import { toast } from 'react-toastify';
import { DEFAULT_AXIS_DOMAIN } from '../constants';
import {
  CodeInterpreterResponse,
  WillyDashboardElement,
  WillyFieldElement,
  WillyWidgetElement,
  NlqResponse,
  CodeExecutionResponse,
} from '../types/willyTypes';
import { useDoubleTap } from 'use-double-tap';

import { confirm } from '@tw/ui-components';
import { useDashContext } from '../dashContext';
import { useDashHistory } from '../hooks/useDashHistory';
import { useDashLayout } from '../hooks/useDashLayout';
import { WillySection } from '../WillySection';
import { emptyArray, getMainElementDbRef } from '../utils/willyUtils';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { cohortColor } from 'constants/general';
import { useStoreValue, useWritableStore } from '@tw/snipestate';
import { closeDashItemDrawer } from '$stores/willy/$mobileDashItemDrawer';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';
import { $userId } from '$stores/$user';
import { deleteSchedule } from '../utils/sequenceSchedules';
import { $currency, $currentShopId } from '$stores/$shop';

type WillyDashItemProps = {
  field?: WillyFieldElement | null;
  widget?: WillyWidgetElement | null;
  id: string;
  secondaryToolbarOpen: boolean;
  setEditMetricModalOpen: any;
  editingFields: string[];
  setEditingFields: (fields: string[]) => void;
  localFiles: File[];
  setLocalFiles: any;
  updateFiles: (fieldId: string, files: File[]) => void;
  style?: any;
  breakdownMode?: boolean;
  isDnd: boolean;
  dashboard?: WillyDashboardElement;
  dashboardId?: string;
  isGlobalDashboard: boolean;
  updateDashWidgetData?: (id: string, data: NlqResponse) => void;
  openChatWithQuery?: (widgetSourceId?: string) => void;
  openSqlWithWidget?: (widgetSourceId?: string) => void;
  inMobileDrawer?: boolean;
  openSettingsDrawer?: (item: any) => void;
};

export const WillyDashItem: React.FC<WillyDashItemProps> = ({
  field = null,
  widget = null,
  id,
  secondaryToolbarOpen,
  setEditMetricModalOpen,
  editingFields,
  setEditingFields,
  localFiles,
  setLocalFiles,
  updateFiles,
  style,
  breakdownMode,
  isDnd,
  dashboard,
  dashboardId,
  isGlobalDashboard,
  updateDashWidgetData,
  openChatWithQuery,
  openSqlWithWidget,
  inMobileDrawer,
  openSettingsDrawer,
}) => {
  const currency = useStoreValue($currency);
  const {
    $dashboardDoc,
    $fields,
    updateWidgetFieldsInDb,
    updateMetricsInDb,
    updateFieldsInDb,
    fetchFields,
  } = useDashContext();
  const [fields, setFields] = useWritableStore($fields);
  const userId = useStoreValue($userId);
  const shopId = useStoreValue($currentShopId);
  const { editLayout, triggerLayout } = useDashLayout();
  const { updateHistory } = useDashHistory();

  const { setNodeRef, transform, transition, isDragging } = useSortable({
    id,
  });

  const isSmall = useIsSmall();

  const [tables, setTables] = useState<string[]>([]);

  const dndStyle = useMemo(
    () => ({
      transform: CSS.Translate.toString(transform),
      transition,
      zIndex: isDragging ? '100' : 'auto',
      opacity: isDragging ? 0.3 : 1,
      touchAction: 'manipulation',
    }),
    [isDragging, transform, transition],
  );

  const doubleTapBind = useDoubleTap((e) => {
    if (!isSmall) return;
    if (!openSettingsDrawer) return;
    openSettingsDrawer(id);
  });

  const codeResultChanged = useCallback(
    async (codeResult: CodeExecutionResponse) => {
      if (!widget?.queryId) {
        return;
      }
      const { executionError = '', output, dataFiles = [], code = '', executionId } = codeResult;
      const newCodeResult: CodeInterpreterResponse = {
        error: executionError,
        codeResults: output,
        files: dataFiles,
        pythonCode: code,
      };
      if (executionId) {
        newCodeResult.executionId = executionId;
      }
      updateWidgetFieldsInDb(widget.queryId, {
        codeResult: newCodeResult,
      });
    },
    [updateWidgetFieldsInDb, widget?.queryId],
  );

  const widgetMarkup = () => {
    if (!widget) return;

    return (
      <div
        {...doubleTapBind}
        ref={setNodeRef}
        style={{ ...dndStyle, ...style }}
        className={`willy-widget h-full relative w-full max-w-full sm:rounded dark:border-lighter flex flex-col ${
          isDnd && editLayout ? 'border-gray-300 border-solid border-[1px]' : ''
        } ${isDnd ? 'isDndWidget' : 'isRGLWidget'}`}
      >
        <WillyWidget
          permission={widget.permission ?? { providers: [] }}
          withoutMainQuery={widget.withoutMainQuery}
          context="dashboard"
          currency={currency}
          hasGlobalConditionalFormatting={!!widget.hasGlobalConditionalFormatting}
          globalConditionalFormattingColor={widget.globalConditionalFormattingColor || cohortColor}
          setHasGlobalConditionalFormatting={(hasGlobalConditionalFormatting) => {
            updateWidgetFieldsInDb(widget.queryId, { hasGlobalConditionalFormatting });
          }}
          setGlobalConditionalFormattingColor={(color) => {
            updateWidgetFieldsInDb(widget.queryId, { globalConditionalFormattingColor: color });
          }}
          type={widget.type}
          queryId={widget.queryId}
          title={widget.title}
          titleChanged={(title) => {
            updateWidgetFieldsInDb(widget.queryId, { title });
          }}
          queriesChanged={async (queries) => {
            updateWidgetFieldsInDb(widget.queryId, { queries });
          }}
          typeChanged={(type) => {
            updateWidgetFieldsInDb(widget.queryId, { type });
          }}
          stackedChanged={(stacked) => {
            updateWidgetFieldsInDb(widget.queryId, { stacked });
          }}
          permissionChanged={(providers) => {
            updateWidgetFieldsInDb(widget.queryId, { permission: { providers } });
          }}
          incrementedStackedChanged={(incrementedStacked) => {
            updateWidgetFieldsInDb(widget.queryId, { incrementedStacked });
          }}
          parameters={widget.parameters}
          metrics={widget.metrics || emptyArray()}
          verified={widget.verified}
          queries={widget.queries}
          metricsChanged={updateMetricsInDb}
          parametersChanged={async (parameters) => {
            const fieldsToUpdate: Partial<WillyWidgetElement> = { parameters };
            if (widget.mode === 'builder' && widget.builderSetup) {
              fieldsToUpdate.builderSetup = { ...widget.builderSetup, filters: parameters };
            }

            await updateWidgetFieldsInDb(widget.queryId, fieldsToUpdate);
          }}
          dashboard={dashboard}
          isGlobalDashboard={isGlobalDashboard}
          isCustomView={dashboard?.isCustomView}
          isDynamic={dashboard?.isDynamic}
          stacked={widget.stacked}
          incrementedStacked={widget.incrementedStacked}
          setEditMetricModalOpen={setEditMetricModalOpen}
          wrapText={widget.wrapText}
          setWrapText={(wrapText) => {
            updateWidgetFieldsInDb(widget.queryId, { wrapText });
          }}
          grid={widget.grid ?? 'flex'}
          setGrid={(grid) => {
            updateWidgetFieldsInDb(widget.queryId, { grid });
          }}
          gridColumns={widget.gridColumns ?? 2}
          setGridColumns={(gridColumns) => {
            updateWidgetFieldsInDb(widget.queryId, { gridColumns });
          }}
          twoColumnMobile={widget.twoColumnMobile ?? false}
          setTwoColumnMobile={(twoColumnMobile) => {
            updateWidgetFieldsInDb(widget.queryId, { twoColumnMobile });
          }}
          tileMode={widget.tileMode ?? 'tile'}
          setTileMode={(tileMode) => {
            updateWidgetFieldsInDb(widget.queryId, { tileMode });
          }}
          skinny={widget.skinny ?? false}
          setSkinny={(skinny) => {
            updateWidgetFieldsInDb(widget.queryId, { skinny });
          }}
          xAxisLabel={widget.xAxisLabel ?? ''}
          setXAxisLabel={(xAxisLabel) => {
            updateWidgetFieldsInDb(widget.queryId, { xAxisLabel });
          }}
          leftYAxisLabel={widget.leftYAxisLabel ?? ''}
          setLeftYAxisLabel={(leftYAxisLabel) => {
            updateWidgetFieldsInDb(widget.queryId, { leftYAxisLabel });
          }}
          rightYAxisLabel={widget.rightYAxisLabel ?? ''}
          setRightYAxisLabel={(rightYAxisLabel) => {
            updateWidgetFieldsInDb(widget.queryId, { rightYAxisLabel });
          }}
          chartLabel={widget.chartLabel}
          setChartLabel={(chartLabel) => {
            updateWidgetFieldsInDb(widget.queryId, { chartLabel });
          }}
          yAxisDomain={widget.yAxisDomain || DEFAULT_AXIS_DOMAIN}
          setYAxisDomain={(yAxisDomain) => {
            updateWidgetFieldsInDb(widget.queryId, { yAxisDomain });
          }}
          allowDataOverflow={widget.allowDataOverflow ?? false}
          setAllowDataOverflow={(allowDataOverflow) => {
            updateWidgetFieldsInDb(widget.queryId, { allowDataOverflow });
          }}
          dimension={widget.dimension || ''}
          setDimension={(dimension) => {
            updateWidgetFieldsInDb(widget.queryId, { dimension });
          }}
          secondaryToolbarOpen={secondaryToolbarOpen}
          pinnedSection={widget.pinnedSection}
          pinnedMetricKeys={dashboard?.pinnedMetricKeys}
          setHidePinnedSection={(hidePinnedSection) => {
            if (!widget.pinnedSection) {
              return;
            }
            updateWidgetFieldsInDb(widget.queryId, { hidden: hidePinnedSection });
          }}
          breakdownMode={breakdownMode}
          breakdownModeChanged={async (breakdownMode) => {
            await updateWidgetFieldsInDb(widget.queryId, { breakdownMode });
          }}
          isDnd={isDnd}
          editLayout={editLayout}
          codeResult={widget.codeResult}
          codeResultChanged={codeResultChanged}
          updateDashWidgetData={updateDashWidgetData}
          dataType={widget.dataType}
          openChatWithQuery={openChatWithQuery}
          openSqlWithWidget={openSqlWithWidget}
          historicalQueryIds={widget.historicalQueryIds}
          dialect={widget.dialect || 'bigquery'}
          mode={widget.mode}
          builderSetup={widget.builderSetup}
          builderSetupChanged={async (builderSetup) => {
            const { filters } = builderSetup;
            await updateWidgetFieldsInDb(widget.queryId || builderSetup.queryId, {
              builderSetup,
              parameters: filters,
            });
          }}
          filtersOpen={!!widget.filtersOpen}
          setFiltersOpen={async (filtersOpen) => {
            await updateWidgetFieldsInDb(widget.queryId, { filtersOpen });
          }}
          inMobileDrawer={inMobileDrawer}
          paginationType="server"
          isSyncCharts={dashboard?.isSyncCharts ?? false}
          chartLayout={widget.chartLayout}
          setChartLayout={(chartLayout) => {
            updateWidgetFieldsInDb(widget.queryId, { chartLayout });
          }}
          showQuickEditing={widget.showQuickEditing}
          setShowQuickEditing={(showQuickEditing) => {
            updateWidgetFieldsInDb(widget.queryId, { showQuickEditing });
          }}
        />
      </div>
    );
  };

  const fieldMarkup = () => {
    if (!field) return;
    return (
      <div
        {...doubleTapBind}
        ref={setNodeRef}
        className={`h-full relative sm:rounded group/field overflow-hidden shadow ${
          field.type === 'image' ? ' select-none' : ' flex flex-col'
        }`}
      >
        <div className="w-full bg-white dark:bg-slate-800 flex flex-col h-full">
          <WillySection
            id={field.id}
            type={field.type}
            title={field.title}
            titleChanged={async (id, title) => {
              await updateFieldsInDb(id, { title });
              const f = await fetchFields($dashboardDoc.get());
              setFields(f);
            }}
            editingFields={editingFields}
            editingFieldsChanged={async (fields) => {
              setEditingFields(fields);
              updateHistory('update_field', field);
            }}
            secondaryToolbarOpen={secondaryToolbarOpen}
            content={field.content}
            contentChanged={async (id, content, updatedAt) => {
              await updateFieldsInDb(id, {
                content,
                updatedAt,
              });
              const f = await fetchFields($dashboardDoc.get());
              setFields(f);
            }}
            fieldRemoved={async () => {
              const conf = await confirm({
                title: `Delete ${field.type}`,
                message: `Are you sure you want remove this ${field.type}?`,
              });
              if (conf) {
                try {
                  const newFields = fields.filter((f) => f.id !== field.id);
                  setFields(newFields);

                  await getMainElementDbRef(dashboard!).collection('fields').doc(field.id).delete();

                  if (field.fromSequenceScheduleId && field.author === userId && shopId) {
                    await deleteSchedule(field.fromSequenceScheduleId, shopId, userId);
                  }

                  updateHistory('remove_field', field);
                  if (inMobileDrawer) {
                    closeDashItemDrawer();
                  }
                  toast.success(`Field removed`);
                  triggerLayout();
                } catch {
                  toast.error('Error removing field');
                }
              }
            }}
            localFiles={localFiles}
            localFilesChanged={async (files) => {
              setLocalFiles(files);
            }}
            filesLoaded={async (fieldId, files) => {
              updateFiles(fieldId, files);
            }}
            isDnd={isDnd}
            editLayout={editLayout}
            inMobileDrawer={inMobileDrawer}
          />
        </div>
      </div>
    );
  };

  return <>{field ? fieldMarkup() : widgetMarkup()} </>;
};
