import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import ActivityPortalHeader from 'components/headers/ActivityPortalHeader';
import SummaryHeader from 'components/headers/SummaryHeader';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/RootType';
import { NavSection } from '../types';
import { NavSectionIcon } from '@tw/ui-components';

export const SUMMARY_SECTION: NavSection = {
  title: 'Summary',
  icon: (props) => <NavSectionIcon src="summary" {...props} />,
  routes: [
    {
      url: '/summary',
      label: 'Home',
      header: () => <SummaryHeader />,
      dependsOnFFSystem: FeatureFlag.SUMMARY_PAGE_FF,
      lastUpdate: () => {
        const InnerComponent = () => {
          const lastUpdate = useSelector((state: RootState) => state.summaryLastRefresh);
          return <> {moment(lastUpdate).local().format('LT')} </>;
        };
        return <InnerComponent />;
      },
    },
    {
      url: '/activity-feed',
      label: 'Activity Feed',
      isHybridPage: true,
      dependsOnFFSystem: FeatureFlag.LIGHTHOUSE_FF,
      header: () => <ActivityPortalHeader />,
    },
  ],
};
