import { MessageTypes, WillyDashboardElement } from 'components/Willy/types/willyTypes';
import { Reducer, combineReducers } from 'redux';
import _db, { toArray } from 'utils/DB';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import firestore = firebase.firestore;
import axiosInstance from '../utils/axiosInstance';
import { sectionTilesOrderChanged } from './summary';
import { toast } from 'react-toastify';
import { windowSize } from 'utils/classes/WindowSizeObserver';
import { INIT_UI } from './constants';
import { MetricsKeys } from '@tw/types';

export type summarySupportedWillyTypes = 'tile' | 'widget';
import { metrics } from '@tw/constants/module/Metrics/allMetrics';
import { MetricData } from '@tw/types/module/metrics/metrics';
import { $shopDashboards } from '$stores/willy/$shopDashboards';
import { $globalDashboards } from '$stores/willy/$globalDashboards';
export const WILLY_DASHBOARDS_FETCH_SUCCESS = 'WILLY_DASHBOARDS_FETCH_SUCCESS';
export const WILLY_GLOBAL_DASHBOARDS_FETCH_SUCCESS = 'WILLY_GLOBAL_DASHBOARDS_FETCH_SUCCESS';
export const WILLY_SUMMARY_BOX_FETCH_SUCCESS = 'WILLY_SUMMARY_BOX_FETCH_SUCCESS';
export const WILLY_WIDGET_FETCH_SUCCESS = 'WILLY_WIDGET_FETCH_SUCCESS';
export const WILLY_TEMP_SUMMARY_BOX_FETCH_SUCCESS = 'WILLY_TEMP_SUMMARY_BOX_FETCH_SUCCESS';
export const WILLY_SUMMARY_BOX_OPEN = 'WILLY_SUMMARY_BOX_OPEN';
export const WILLY_SUMMARY_BOX_CLOSE = 'WILLY_SUMMARY_BOX_CLOSE';
export const WILLY_SUMMARY_TITLE_CHANGED = 'WILLY_SUMMARY_TITLE_CHANGED';
export const WILLY_SUMMARY_WIDGET_TITLE_CHANGED = 'WILLY_SUMMARY_WIDGET_TITLE_CHANGED';
export const WILLY_DASHBOARD_RANGE_CHANGE = 'WILLY_DASHBOARD_RANGE_CHANGE';
export const WILLY_SIDE_PANEL_MOBILE_OPEN = 'WILLY_SIDE_PANEL_MOBILE_OPEN';
export const WILLY_SIDE_PANEL_WIDTH_CHANGE = 'WILLY_SIDE_PANEL_WIDTH_CHANGE';
export const WILLY_HISTORY_PANE_OPEN = 'WILLY_HISTORY_PANE_OPEN';

export const pinWillyToSummary =
  (queryId, title, type: summarySupportedWillyTypes, widgetType: MessageTypes) =>
  async (dispatch, getState) => {
    const { currentShopId, summarySectionsCustomization } = getState();
    await axiosInstance.post('/v2/willy/add-summary-willy', {
      queryId,
      shopId: currentShopId,
      title,
      widgetType,
      type,
    });

    if (type == 'tile')
      if (!(summarySectionsCustomization['customMetrics']?.tiles || []).includes(queryId)) {
        const tiles = (summarySectionsCustomization['customMetrics']?.tiles || []).concat([
          queryId,
        ]);
        dispatch(sectionTilesOrderChanged('customMetrics', tiles));
      }
    toast.success(`${title} Added to the summary page`);
    dispatch(fetchWillySummary(currentShopId, type));
  };

export const removePinSummaryWilly =
  (queryId, type: summarySupportedWillyTypes) => async (dispatch, getState) => {
    const { currentShopId } = getState();
    await axiosInstance.post('/v2/willy/remove-summary-willy', {
      queryId,
      shopId: currentShopId,
    });
    dispatch(fetchWillySummary(currentShopId, type));
  };

export const newTempWillySummaryBoxWidget = (queryId: string, title: string) => {
  return (dispatch) => {
    dispatch({
      type: WILLY_TEMP_SUMMARY_BOX_FETCH_SUCCESS,
      payload: { id: queryId, title: title.replace(/"/g, '') },
    });
  };
};

export const willyEditSummaryBox = (id) => {
  return {
    type: WILLY_SUMMARY_BOX_OPEN,
    payload: id,
  };
};

export function getWillyMetric(data: any): MetricData<MetricsKeys> | undefined {
  const metricKey = data?.queryData?.data?.find((item: any) => item.metricKey !== null)?.metricKey;
  if (!metricKey) {
    return undefined;
  }
  return metrics[metricKey] as MetricData<MetricsKeys>;
}

export const editWillySummaryTitle = (id, title) => async (dispatch, getState) => {
  const { currentShopId } = getState();
  await firestore()
    .collection('shops')
    .doc(currentShopId)
    .collection('willySummary')
    .doc(id)
    .update({ title });

  dispatch({ type: WILLY_SUMMARY_TITLE_CHANGED, payload: { id, title } });
};

export const willyCloseEditSummaryBox = () => {
  return {
    type: WILLY_SUMMARY_BOX_CLOSE,
  };
};

export const fetchWillySummary = (shopId: string, type: summarySupportedWillyTypes) => {
  return async (dispatch, getState) => {
    const { mainDatePickerSelectionRange } = getState();
    let { start, end } = mainDatePickerSelectionRange;
    const emptyArr = toArray(
      await firestore()
        .collection('shops')
        .doc(shopId)
        .collection('willySummary')
        .where('type', '==', type)
        .get(),
    ).map((x) => ({ ...x, title: x.title.replace(/"/g, ''), queryData: {} }));
    dispatch({
      type: type === 'tile' ? WILLY_SUMMARY_BOX_FETCH_SUCCESS : WILLY_WIDGET_FETCH_SUCCESS,
      payload: emptyArr,
    });
    const startDate = start.format();
    const endDate = end.format();
    const arr =
      (
        await axiosInstance.post('/v2/willy/get-summary-willy', {
          shopId,
          startDate,
          endDate,
          type,
        })
      )?.data ?? [];
    dispatch({
      type: type === 'tile' ? WILLY_SUMMARY_BOX_FETCH_SUCCESS : WILLY_WIDGET_FETCH_SUCCESS,
      payload: arr,
    });
  };
};

export const toggleMobileSidePanel = (open) => ({
  type: WILLY_SIDE_PANEL_MOBILE_OPEN,
  payload: open,
});

const isEditSummaryTitleOpen: Reducer<boolean> = (state = false, action: any) => {
  switch (action.type) {
    case WILLY_SUMMARY_BOX_OPEN:
      return true;
    case WILLY_SUMMARY_BOX_CLOSE:
      return false;
    default:
      return state;
  }
};

const editSummaryId: Reducer<string> = (state = '', action: any) => {
  switch (action.type) {
    case WILLY_SUMMARY_BOX_OPEN:
      return action.payload;
    case WILLY_SUMMARY_BOX_CLOSE:
      return '';
    default:
      return state;
  }
};

export const willySummaryBox: Reducer<any[]> = (state = [], action: any) => {
  switch (action.type) {
    case WILLY_SUMMARY_BOX_FETCH_SUCCESS:
      return action.payload || [];
    case WILLY_SUMMARY_TITLE_CHANGED:
      return state.map((item) =>
        item.id === action.payload.id ? { ...item, title: action.payload.title } : item,
      );
    default:
      return state;
  }
};

export const willySummaryWidget: Reducer<any[]> = (state = [], action: any) => {
  switch (action.type) {
    case WILLY_WIDGET_FETCH_SUCCESS:
      return action.payload || [];
    case WILLY_SUMMARY_WIDGET_TITLE_CHANGED:
      return state.map((item) =>
        item.id === action.payload.id ? { ...item, widgetType: action.payload.widgetType } : item,
      );
    case WILLY_SUMMARY_TITLE_CHANGED:
      return state.map((item) =>
        item.id === action.payload.id ? { ...item, title: action.payload.title } : item,
      );
    default:
      return state;
  }
};

export const willyTempSummaryBox: Reducer<any[]> = (state = [], action: any) => {
  switch (action.type) {
    case WILLY_TEMP_SUMMARY_BOX_FETCH_SUCCESS:
      const exists = state.some((item) => item.id === action.payload.id);

      if (exists) {
        // Map through the list and replace the item if it matches the id of newItem
        return state.map((item) => (item.id === action.payload.id ? action.payload : item));
      } else {
        // Otherwise, return a new array with the new item appended
        return [...state, ...[action.payload]];
      }

    default:
      return state;
  }
};

export const changeWillySideMenuWidth = (width: number) => ({
  type: WILLY_SIDE_PANEL_WIDTH_CHANGE,
  width,
});

const loadingWillySummaryBox: Reducer<boolean> = (state = true, action: any) => {
  switch (action.type) {
    case WILLY_SUMMARY_BOX_FETCH_SUCCESS:
      return !action.payload.some((x) => Object.keys(x.queryData ?? {}).length > 0);
    default:
      return state;
  }
};

const mobileSidePanelOpen: Reducer<boolean> = (state = !windowSize.isSmall, action: any) => {
  switch (action.type) {
    case WILLY_SIDE_PANEL_MOBILE_OPEN:
      return action.payload;
    default:
      return state;
  }
};

const willySideMenuWidth: Reducer<number> = (state = 380, action) => {
  switch (action.type) {
    case INIT_UI:
      return action.willySideMenuWidth ?? state;
    case WILLY_SIDE_PANEL_WIDTH_CHANGE:
      return action.width ?? state;
    default:
      return state;
  }
};

export const reducers = combineReducers({
  loadingWillySummaryBox,
  isEditSummaryTitleOpen,
  editSummaryId,
  mobileSidePanelOpen,
  willySideMenuWidth,
});
