import _db from 'utils/DB';
import { WillyRule, WillyRuleExpression } from '@tw/willy-data-dictionary/module/columns/types';
import { SAVED_RULES_COLLECTION } from 'pages/FreeQuery/constants';
import { $tables } from '$stores/willy/$tables';
import { BqTable } from 'pages/FreeQuery/dataStuff/tables';
import { formatSqlSafely } from '../utils/willyUtils';
import { AggregationFunction } from '@tw/willy-data-dictionary/module/columns/types';
import { convertUndefinedToNull } from '../utils/convertUndefinedToNull';

export async function createNewRule(newRule: WillyRule) {
  const rule = convertUndefinedToNull(newRule);
  const allTables = $tables.get();
  rule.query = extractQueryFromRule(rule);
  const tableId = rule.tableId || allTables.find((t) => rule.query.includes(t.id))?.id;
  const table = allTables.find((x) => x.id === tableId);
  const { expressions, ...rest } = rule;

  const { whereClause, havingClause } = translateExpressionsToSql(expressions, table);
  const aliasesToWrap = extractAliasesFromExpressions(expressions);
  const { query } = wrapQueryWithOuterExpression(
    newRule.query,
    aliasesToWrap,
    whereClause,
    havingClause,
  );

  await _db()
    .collection(SAVED_RULES_COLLECTION)
    .doc(newRule.id)
    .set({ ...rest, fullQuery: query, expressions: JSON.stringify(expressions) }, { merge: true });
}

export function extractAliasesFromExpression(expression: WillyRuleExpression[]) {
  const aliases = expression.map((exp) => {
    return exp.column;
  });
  return aliases;
}

export function aggregateAlias(
  expression: WillyRuleExpression,
  aggFunc: AggregationFunction = 'SUM',
  tableId?: string,
  withAlias = true,
) {
  const table = $tables.get().find((t) => t.id === tableId);
  const column = table?.columns.find((c) => c.id === expression.column);
  if (column?.type === 'numeric' || column?.type === 'formula') {
    return `${aggFunc}(${expression.column}) ${withAlias ? `as ${expression.column}` : ''}`;
  }

  return expression.column;
}

export function extractAliasesFromExpressions(expressions: WillyRuleExpression[][]) {
  const flattenAliases = expressions
    .map((expression) => extractAliasesFromExpression(expression))
    .flat();

  return [...new Set(flattenAliases)];
}

export function translateExpressionsToSql(expressions: WillyRuleExpression[][], table?: BqTable) {
  const whereClause = buildWhereClause(expressions, table);

  const havingClause = buildHavingClause(expressions, table);

  return { whereClause, havingClause };
}

export function buildWhereClause(expressions: WillyRuleExpression[][], table?: BqTable) {
  if (!expressions.length) {
    return '';
  }
  const whereClause = expressions
    .map((group) => {
      return group
        .map((expression) => {
          const column = table?.columns.find((c) => c.id === expression.column);
          let value: string | string[] = `'${expression.value}'`;
          if (column?.type === 'numeric' || column?.type === 'formula') {
            return '';
          }

          if (expression.operator === 'BETWEEN') {
            const [value1, value2] = Array.isArray(expression.value)
              ? expression.value
              : expression.value.split(',');
            return `${expression.column} BETWEEN '${value1 || ''}' AND '${value2 || ''}'`;
          }

          return `${expression.column} ${expression.operator} ${value}`;
        })
        .filter((x) => x)
        .join(' AND ');
    })
    .join(' OR ');

  return whereClause;
}

export function buildHavingClause(expressions: WillyRuleExpression[][], table?: BqTable) {
  if (!expressions.length) {
    return '';
  }
  const havingClause = expressions
    .map((group) => {
      return group
        .map((expression) => {
          const column = table?.columns.find((c) => c.id === expression.column);
          if (column?.type !== 'numeric' && column?.type !== 'formula') {
            return '';
          }

          const alias = aggregateAlias(expression, 'SUM', table?.id, false);

          if (expression.operator === 'BETWEEN') {
            const [value1, value2] = Array.isArray(expression.value)
              ? expression.value
              : expression.value.split(',');
            return `${alias} BETWEEN ${value1} AND ${value2}`;
          }

          return `${alias} ${expression.operator} ${Number(expression.value)}`;
        })
        .filter((x) => x)
        .join(' AND ');
    })
    .join(' OR ');

  return havingClause;
}

export function wrapQueryWithOuterExpression(
  query: string,
  aliasesToWrap: string[],
  whereClause?: string,
  havingClause?: string,
) {
  const subQuery = `${query.replace(/;$/, '')}`;

  const prefix = `SELECT ${aliasesToWrap.join(', ')} FROM (`;
  const suffix = `) as outer_query ${whereClause ? `WHERE ${whereClause}` : ''} ${
    havingClause ? `HAVING ${havingClause}` : ''
  } LIMIT 1`;
  const wrappedQuery = formatSqlSafely(`${prefix}${subQuery}${suffix}`);

  return { query: wrappedQuery, subQuery, prefix, suffix };
}

export function extractQueryFromRule(rule: WillyRule) {
  if (!rule.mode || rule.mode === 'sql') {
    return rule.query;
  }

  const allTables = $tables.get();

  const table = allTables.find((x) => x.id === rule.tableId);
  if (!table) {
    return '';
  }

  let { whereClause, havingClause } = translateExpressionsToSql(rule.expressions, table);

  whereClause = whereClause ? `WHERE ${whereClause}` : '';
  havingClause = havingClause ? `HAVING ${havingClause}` : '';

  return `SELECT * FROM ${table.id} ${whereClause} ${havingClause}`;
}
