import { Icon, IconName, Modal, Tabs, Text } from '@tw/ui-components';
import { BreakdownValue, MessageTypes, WillyMetric, WillyWidgetElement } from './types/willyTypes';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppSelector } from 'reducers/RootType';
import moment from 'moment-timezone';
import BaseChart from 'components/library/BaseChart/BaseChart';
import { Bar, BarChart, Tooltip } from 'recharts';
import { useWindowSize } from 'utils/useWindowSize';
import { formatNumber } from 'utils/formatNumber';
import { RoundingOptionsMapper } from 'ducks/summary';
import { GradientChartDefs } from 'constants/general';
import { WillyComparisonChart } from './WillyComparisonChart';
import { isDefined } from 'utils/isDefined';
import { WillyCustomQueryWidget } from './WillyCustomQueryWidget';
import { visibleLength } from './utils/willyUtils';
import { $currentDateRange, $prevDateRange } from '../../$stores/willy/$dateRange';
import { useStoreValue } from '@tw/snipestate';

const tabsType = ['bars', 'comparison', 'custom'] as const;
type TabsType = (typeof tabsType)[number];
type Tab = { value: TabsType; label: string };

type WillySingleValueChartProps = {
  onClose: () => void;
  queryId: string;
  currency: string;
  loadingPreviousPeriod?: boolean;
  metric: WillyMetric;
  metrics: WillyMetric[];
  metricsChanged: (id: string, v: WillyMetric[]) => Promise<void>;
  totalValue?: (string | number | null)[];
  breakDownValue?: BreakdownValue;
  previousPeriodTotalValue?: (string | number | null)[];
  previousPeriodBreakDownValue?: BreakdownValue;
  metricData?: Record<string, any>;
  type: MessageTypes;
  isSyncCharts: boolean;
};

const convertChartDataToNumbers = (item: BreakdownValue[number]) => {
  return {
    ...item,
    value: item.value ? +item.value : null,
  };
};

export const WillySingleValueChart: React.FC<WillySingleValueChartProps> = ({
  metric,
  queryId,
  loadingPreviousPeriod,
  totalValue,
  breakDownValue,
  previousPeriodTotalValue,
  previousPeriodBreakDownValue,
  metrics,
  currency,
  metricData,
  type,
  isSyncCharts,
  metricsChanged,
  onClose,
}) => {
  const windowSize = useWindowSize();

  const isOneYear = useAppSelector((state) => state.isOneYear);
  const isOneDay = useAppSelector((state) => state.isOneDay);
  const defaultRoundingOption = useAppSelector((state) => state.defaultRoundingOption);
  const prevDateRange = useStoreValue($prevDateRange);
  const currentDateRange = useStoreValue($currentDateRange);
  const tabs: Tab[] = useMemo(() => {
    let tabs: Tab[] = [];
    if ((type === 'tile' || type === 'summaryBox') && !!totalValue && !!breakDownValue) {
      tabs = tabs.concat([
        { label: 'Bars', value: 'bars' },
        { label: 'Comparison', value: 'comparison' },
      ]);
    }

    if (metric.popupWidget) {
      tabs = [{ label: 'Custom', value: 'custom' }, ...tabs];
    }

    return tabs;
  }, [breakDownValue, metric.popupWidget, totalValue, type]);

  const [activeTab, setActiveTab] = useState<TabsType | undefined>();
  const [previousPeriodBreakdownData, setPreviousPeriodBreakdownData] = useState<
    BreakdownValue | undefined
  >(previousPeriodBreakDownValue);

  const loadingData = useMemo(() => {
    if (!currentDateRange) {
      return [];
    }
    const { start, end } = currentDateRange;
    const len = end.diff(start, 'days');

    return Array.from({ length: len + 1 }).map((_, i) => {
      return {
        date: start.clone().add(i, 'days').format('YYYY-MM-DD'),
        value: Math.random() * 1000,
      };
    });
  }, [currentDateRange]);

  const queryVarsValues = useMemo(() => {
    if (metric?.queryVars?.length && metricData && Object.keys(metricData).length) {
      const queryVarsValues: Record<string, any> = metric.queryVars.reduce(
        (acc, curr) => {
          acc[curr] = metricData[curr] ?? null;
          return acc;
        },
        {} as Record<string, any>,
      );
      return queryVarsValues;
    }
  }, [metric.queryVars, metricData]);

  const onTabChange = useCallback((selectedTabIndex: string | null) => {
    if (!isDefined(selectedTabIndex)) return;
    setActiveTab(selectedTabIndex as TabsType);
  }, []);

  const currencyRounding = useCallback(
    (metric: WillyMetric) => {
      if (metric.format === 'currency') {
        return RoundingOptionsMapper[defaultRoundingOption];
      }
      return null;
    },
    [defaultRoundingOption],
  );

  const { currentPeriodDate, previousPeriodDate } = useMemo(() => {
    if (!currentDateRange) {
      return { currentPeriodDate: '', previousPeriodDate: '' };
    }

    const { start: prevPeriodStart, end: prevPeriodEnd } = prevDateRange ?? {};

    const { start, end } = currentDateRange;
    if (!start || !end || !prevPeriodStart || !prevPeriodEnd) {
      return { currentPeriodDate: '', previousPeriodDate: '' };
    }
    const isCurrentAndPreviousTheSameYear =
      isOneYear && moment(prevPeriodEnd).isSame?.(start, 'year');
    let prevPeriodDate = '';
    let currPeriodDate = '';
    if (isOneDay) {
      currPeriodDate = moment(start).format('MMM DD');
      prevPeriodDate = moment(prevPeriodStart).format('MMM DD');
    } else {
      currPeriodDate = `${moment(start).format(
        isCurrentAndPreviousTheSameYear ? 'MMM DD' : 'MMM DD, YYYY',
      )} - ${moment(end).format(isCurrentAndPreviousTheSameYear ? 'MMM DD' : 'MMM DD, YYYY')}`;
      prevPeriodDate = `${moment(prevPeriodStart).format(
        isCurrentAndPreviousTheSameYear ? 'MMM DD' : 'MMM DD, YYYY',
      )} - ${moment(prevPeriodEnd).format(
        isCurrentAndPreviousTheSameYear ? 'MMM DD' : 'MMM DD, YYYY',
      )}`;
    }

    return { currentPeriodDate: currPeriodDate, previousPeriodDate: prevPeriodDate };
  }, [isOneDay, isOneYear, currentDateRange, prevDateRange]);

  const updateWidgetSettings = useCallback(
    async (innerWidget: Partial<WillyWidgetElement> | null) => {
      const newMetrics = metrics.map((m) => {
        if (m.key === metric.key) {
          const { popupWidget, ...rest } = m;
          return {
            ...rest,
            popupWidget: popupWidget ? { ...popupWidget, ...innerWidget } : null,
          };
        }
        return m;
      });

      await metricsChanged(queryId, newMetrics);
    },
    [metric.key, metrics, metricsChanged, queryId],
  );

  useEffect(() => {
    if (!activeTab && tabs.length) {
      setActiveTab(tabs[0].value);
    }
  }, [activeTab, tabs]);

  return (
    <>
      <Modal.Root opened onClose={onClose} size="90vw" centered>
        <Modal.Overlay />
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>
              <div className="flex items-center gap-2">
                {metric?.icon &&
                  (visibleLength(metric.icon) > 1 ? (
                    <Icon name={metric.icon as IconName} size={16} />
                  ) : (
                    metric.icon
                  ))}
                <Text size="lg" weight="bold" lh={1}>
                  {metric?.name}
                </Text>
              </div>
            </Modal.Title>
            <Modal.CloseButton onClick={onClose} />
          </Modal.Header>
          <Modal.Body className="!p-0">
            {tabs?.length > 1 && (
              <div className="flex items-center justify-between">
                <Tabs fullWidth value={activeTab} onChange={onTabChange}>
                  <Tabs.List className="flex-nowrap justify-between flex">
                    {tabs.map((tab) => (
                      <Tabs.Tab
                        key={tab.value}
                        value={tab.value}
                        className="dark:text-white hover:dark:text-black"
                      >
                        {tab.label}
                      </Tabs.Tab>
                    ))}
                  </Tabs.List>
                </Tabs>
              </div>
            )}
            {activeTab !== 'custom' && (
              <div className="flex justify-between p-6.5 bg-gray-100">
                <div>
                  <p>{currentPeriodDate}</p>
                  <p className="font-bold text-3xl">
                    {!!totalValue?.[0]
                      ? formatNumber(+totalValue[0], {
                          style: metric?.format || 'decimal',
                          currency,
                          minimumFractionDigits: currencyRounding(metric) ?? metric?.toFixed ?? 0,
                          maximumFractionDigits: currencyRounding(metric) ?? metric?.toFixed ?? 0,
                        })
                      : '-'}
                  </p>
                </div>
                <div className="text-right">
                  <p className="text-secondary-text text-opacity-90">{previousPeriodDate}</p>
                  <p className="font-bold text-3xl text-secondary-text text-opacity-90">
                    {!!previousPeriodTotalValue?.[0]
                      ? formatNumber(+previousPeriodTotalValue[0], {
                          style: metric?.format || 'decimal',
                          currency,
                          minimumFractionDigits: currencyRounding(metric) ?? metric?.toFixed ?? 0,
                          maximumFractionDigits: currencyRounding(metric) ?? metric?.toFixed ?? 0,
                        })
                      : '-'}
                  </p>
                </div>
              </div>
            )}
            {activeTab === 'bars' && (
              <BaseChart
                ChartType={BarChart}
                data={(breakDownValue || []).map(convertChartDataToNumbers)}
                height={200}
                wrapperStyle={{ padding: '0 2rem 0 0' }}
                margin={{ top: 30, left: 0, bottom: 30, right: 0 }}
                xAxis={[
                  {
                    tickFormatter: (value: string) => {
                      if (isOneDay) {
                        return moment().hour(+value).minutes(0).format('HH:mm');
                      }
                      return moment(value).format('MMM D');
                    },
                    dy: 20,
                    dataKey: (item: any) => {
                      return item.date;
                    },
                  },
                ]}
                yAxis={[
                  {
                    tickFormatter: (value, index) => {
                      if (index % 2 !== 0) {
                        return '';
                      }
                      return +value < 1000 ? value : +value / 1000 + 'K';
                    },
                    dx: 0,
                    domain: [0, 'auto'],
                  },
                ]}
              >
                <defs>
                  <GradientChartDefs metrics={[]} />
                </defs>

                <Bar
                  name={metric?.name}
                  barSize={windowSize.isSmall ? 3 : 4}
                  fill={`url(#summary-like-chart-color)`}
                  dataKey="value"
                />

                <Tooltip
                  cursor={{ fill: 'none' }}
                  formatter={(value: number) => {
                    const { format, toFixed } = metric || {};
                    return formatNumber(value, {
                      style: format || 'decimal',
                      currency,
                      minimumFractionDigits: currencyRounding(metric) ?? toFixed ?? 0,
                      maximumFractionDigits: currencyRounding(metric) ?? toFixed ?? 0,
                    });
                  }}
                  labelFormatter={(value) => {
                    if (isOneDay) {
                      return moment().hour(+value).minutes(0).format('HH:mm');
                    }
                    return moment(value).format('MMM D');
                  }}
                />
              </BaseChart>
            )}
            {activeTab === 'comparison' && (
              <>
                <WillyComparisonChart
                  loading={false}
                  data={(breakDownValue || []).map(convertChartDataToNumbers)}
                  previousPeriodData={(previousPeriodBreakdownData || []).map(
                    convertChartDataToNumbers,
                  )}
                  metrics={metrics}
                />
              </>
            )}
            {activeTab === 'custom' && (
              <div className="min-w-full flex flex-col pb-0 relative">
                {metric.popupWidget && (
                  <div className="flex flex-col flex-auto w-full">
                    <WillyCustomQueryWidget
                      widget={metric.popupWidget}
                      currency={currency}
                      widgetSaved={updateWidgetSettings}
                      queryVarsValues={queryVarsValues}
                      functionName={metric.functionName}
                      isSyncCharts={isSyncCharts}
                    />
                  </div>
                )}
              </div>
            )}
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
    </>
  );
};
