import { useNavigate, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { computeFeatureFlags } from '../../../feature-flag-system';
import { FeatureFlag, FeatureFlagConfigKey } from '@tw/feature-flag-system/module/types';
import { UpgradePageFallBack } from '../../../feature-flag-system/components';

import '../styles/willy.scss';
import { ReactComponent as AddedToBoard } from 'components/Icons/checkmark.svg';

import { NewLoader } from 'components/loaders/NewLoader';
import { Icon as PolarisIcon } from '@shopify/polaris';
import { SearchMinor } from '@shopify/polaris-icons';
import { Allotment } from 'allotment';

import { WillyPageWrapper } from '../WillyPageWrapper';
import { isGlobalDashboardExistsInShop, leftTabs, useDashContext } from '../dashContext';
import {
  ActionIcon,
  Button,
  Flex,
  Icon,
  IconName,
  confirm,
  Text,
  TextInput,
  Tooltip,
  Title,
  Box,
  Anchor,
  Size,
} from '@tw/ui-components';
import { $isTwGlobalDashboardCreatorClaim } from '$stores/$user';
import { openDashSettingModal } from '$stores/willy/$dashSettingsModal';
import { analyticsEvents, genericEventLogger, templateLibraryActions } from 'utils/dataLayer';
import { $globalDashboardRoles } from '../../../$stores/willy/$globalDashboardRoles';
import { ProviderComboBox } from './ProviderComboBox';
import { WillySelect } from './WillySelect';
import { TemplateFilterItem, WillyTemplatesFilterComboBox } from './WillyTemplatesFilterComboBox';
import { useWindowSize } from '../../../utils/useWindowSize';
import { WillyDashImage } from './WillyDashImage';
import {
  $combineDashboardSequence,
  $loadingGlobals,
} from '../../../$stores/willy/$combineDashboardSequence';
import { $displayFeaturedTemplates } from '$stores/willy/$globalDashboards';
import { deleteMainElement, isSequenceElement } from '../utils/willyUtils';
import { toast } from 'react-toastify';
import { AskAnSQWhaleExpert } from '../AskAnSQWhaleExpert';
import { closeCreateModal } from '$stores/willy/$createModal';
import { WillyElementType } from 'components/Willy/types/willyTypes';
import { useStoreValue } from '@tw/snipestate';
import { TemplateCard } from './template-management/TemplateCard';
import { RequestCustomReportBanner } from './template-management/RequestCustomBanner';
import { DashboardCreditsIndicator } from './template-management/DashboardCreditsIndicator';
import { $createDashboardAccess } from '$stores/willy/$shopDashboards';
import { NoCreditsLeftAlert } from './template-management/NoCreditsLeftAlert';
import { openFreeTrialPopup } from '$stores/willy/$upgradePopupManager';
import { LimitedAccessTooltip } from 'components/Nav/components/navs/AlanNav/LimitedAccessTooltip';
import { userDb } from '../../../utils/DB';
import { $templateViewFilterSettings } from './stores';
import { $dashboardCredits } from '$stores/willy/$credits';
import { FeaturedTemplatesSection } from './template-management/FeaturedTemplatesSection';

const sortOptions = [
  { label: 'A to Z', value: 'title' },
  { label: 'Newest', value: 'new' },
  { label: 'Oldest', value: 'old' },
  //{ label: 'Most popular', value: 'popular' },
];

const secondaryFilterOptions = (type: WillyElementType) => [
  { label: 'All', value: '' },
  {
    label: `Added to my ${type === 'dashboard' ? 'reports' : 'workflows'}`,
    value: 'added',
  },
  {
    label: `Not added to my ${type === 'dashboard' ? 'reports' : 'workflows'}`,
    value: 'not',
  },
];

const typeFilterOptions = [
  { label: 'Reports', value: 'dashboard' },
  { label: 'Workflows', value: 'sequence' },
];

const planList: Array<{
  planId: string;
  name: string;
  icon: IconName;
  packages: FeatureFlagConfigKey[];
}> = [
  {
    planId: 'free',
    name: 'Founders Dash',
    icon: 'flash',
    packages: ['CONF_FREE', 'CONF_FREE_3', 'CONF_D', 'CONF_D_3'],
  },
  { planId: 'growth', name: 'Growth', icon: 'growth', packages: ['CONF_A', 'CONF_A_3'] },
  { planId: 'pro', name: 'Pro', icon: 'pro', packages: ['CONF_B', 'CONF_B_3'] },
  {
    planId: 'enterprise',
    name: 'Enterprise',
    icon: 'office-building-tall',
    packages: ['CONF_C', 'CONF_C_3'],
  },
];

export const WillyTemplatesInner = ({
  templateType,
  setTemplateType,
  onlyInner,
  hideHeader,
  isSequencesPage,
}: {
  templateType: WillyElementType;
  setTemplateType: (v: WillyElementType) => void;
  onlyInner?: boolean;
  hideHeader?: boolean;
  isSequencesPage?: boolean;
}) => {
  const loadingGlobals = useStoreValue($loadingGlobals);
  const globalDashboardRoles = useStoreValue($globalDashboardRoles);
  const isTwGlobalDashboardCreatorClaim = useStoreValue($isTwGlobalDashboardCreatorClaim);
  const displayFeaturedTemplates = useStoreValue($displayFeaturedTemplates);
  const globals = useStoreValue($combineDashboardSequence);
  const templateViewFilterSettings = useStoreValue($templateViewFilterSettings);
  const { $activeTab } = useDashContext();
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedRole, setSelectedRole] = useState<string>('');
  const [selectPlanFilter, setSelectPlanFilter] = useState<string>('');
  const [selectedProviders, setSelectedProviders] = useState<string[]>([]);
  const [selectComboboxItem, setSelectComboboxItem] = useState<TemplateFilterItem>();
  const [sortOption, setSortOption] = useState<string>('new');
  const [filterOnlyHide, setFilterOnlyHide] = useState<boolean>(false);
  const [dashSearch, setDashSearch] = useState('' as string);
  const [onlyPopular, setOnlyPopular] = useState(false);
  const windowSize = useWindowSize();
  const { search } = useLocation();
  const { credits, creditsLeft, creditsUsed } = useStoreValue($dashboardCredits);

  const [secondaryFilterOption, setSecondaryFilterOption] = useState<string>('not');
  const [copyLoading, setCopyLoading] = useState(false);

  const toggleDisplayFeaturedTemplates = useCallback(() => {
    $displayFeaturedTemplates.set(!displayFeaturedTemplates);
  }, [displayFeaturedTemplates]);

  const templateFilterComboBox: TemplateFilterItem[] = useMemo(() => {
    let list: TemplateFilterItem[] = [];
    list.push({ name: 'All Templates', id: 'all', type: 'NONE', icon: 'button-controller' });
    list.push({ name: 'Popular', id: 'popular', type: 'NONE' });
    list = list.concat(
      globalDashboardRoles
        .filter((x) => x.category === 'NONE')
        .map((role) => ({ name: role.name, id: role.id, type: 'NONE', icon: role.icon })),
    );
    list = list.concat(
      globalDashboardRoles
        .filter((x) => x.category === 'ROLES')
        .map((role) => ({ name: role.name, id: role.id, type: 'ROLE', icon: role.icon })),
    );
    // list = list.concat(
    //   planList.map((plan) => ({ name: plan.name, id: plan.planId, type: 'PLAN', icon: plan.icon }))
    // );

    return list;
  }, [globalDashboardRoles]);

  useEffect(() => {
    setSelectComboboxItem(templateFilterComboBox[0]);
  }, [templateFilterComboBox]);

  useEffect(() => {
    $activeTab.set('global');
  }, [$activeTab]);

  useEffect(() => {
    const { integrations, sort, added } = templateViewFilterSettings;
    setSortOption(sort);
    setSecondaryFilterOption(added);
    setSelectedProviders(integrations);
  }, [templateViewFilterSettings]);

  const setSecondaryFilterOptionVal = useCallback((val: string) => {
    setSecondaryFilterOption(val);
    userDb().update('templateLibraryFilterSettings.added', val);
  }, []);

  const setSelectedProvidersVal = useCallback((val: string[]) => {
    setSelectedProviders(val);
    userDb().update('templateLibraryFilterSettings.integrations', val);
  }, []);

  const setSortOptionVal = useCallback((val: string) => {
    setSortOption(val);
    userDb().update('templateLibraryFilterSettings.sort', val);
  }, []);

  const popularTemplates = useMemo(() => {
    return globals
      .sort((a, b) => (b.installCount ?? 0) - (a.installCount ?? 0))
      .filter((x) => !x.isHide)
      .slice(0, 10)
      .map((p) => p.id);
  }, [globals]);

  const filteredGlobalDashboards = useMemo(() => {
    const globalsFiltered = globals.map((board) => {
      const existsInStore = isGlobalDashboardExistsInShop(board);
      return { ...board, existsInStore };
    });

    if (filterOnlyHide) {
      return globalsFiltered.filter((x) => x.isHide);
    }

    return globalsFiltered
      .filter((x) => !x.isHide)
      .filter((board) =>
        dashSearch.trim().length > 0
          ? board.name?.toLowerCase().trim().includes(dashSearch.toLowerCase().trim())
          : true,
      )
      .filter(
        (board) =>
          selectedProviders.length === 0 ||
          board.providers?.some((provider) => selectedProviders.includes(provider)),
      )

      .filter((board) => (board.isBeta ? isTwGlobalDashboardCreatorClaim : true))

      .filter((board) => {
        return secondaryFilterOption === 'added'
          ? board.existsInStore
          : secondaryFilterOption === 'not'
            ? !board.existsInStore
            : true;
      })
      .filter((board) => {
        if (selectComboboxItem?.type === 'NONE') {
          if (selectComboboxItem?.id === 'all') {
            return true;
          }
          if (selectComboboxItem?.id === 'popular') {
            return popularTemplates.includes(board.id);
          } else {
            return board.roles?.includes(selectComboboxItem.id);
          }
        } else if (selectComboboxItem?.type === 'ROLE') {
          return board.roles?.includes(selectComboboxItem.id);
        } else if (selectComboboxItem?.type === 'PLAN') {
          return board.packages?.some((x) =>
            planList.find((x) => x.planId === selectComboboxItem.id)?.packages.includes(x),
          );
        }
      })
      .filter((board) => {
        return !selectedRole || board.roles?.includes(selectedRole);
      })
      .filter((board) => {
        return (
          !selectPlanFilter ||
          board.packages?.some((x) =>
            planList.find((x) => x.planId === selectPlanFilter)?.packages.includes(x),
          )
        );
      })
      .filter((board) => !onlyPopular || popularTemplates.includes(board.id))
      .filter((board) => {
        return templateType === 'dashboard'
          ? board.type === 'dashboard'
          : board.type === 'sequence';
      })
      .sort((a, b) => {
        if (sortOption === 'new') {
          let aDate = a?.createdAt ?? 0;
          let bDate = b?.createdAt ?? 0;
          return (
            (typeof bDate === 'number' ? bDate : bDate.toMillis()) -
            (typeof aDate === 'number' ? aDate : aDate.toMillis())
          );
        } else if (sortOption === 'old') {
          let aDate = a?.createdAt ?? 0;
          let bDate = b?.createdAt ?? 0;
          return (
            (typeof aDate === 'number' ? aDate : aDate.toMillis()) -
            (typeof bDate === 'number' ? bDate : bDate.toMillis())
          );
        } else if (sortOption === 'title') {
          return (a.name || '').localeCompare(b.name || '');
        } else if (sortOption === 'popular') {
          return (b.installCount ?? 0) - (a.installCount ?? 0);
        } else if (sortOption === 'not in') {
          return a.existsInStore === b.existsInStore ? 1 : a.existsInStore ? 0 : -1;
        } else {
          return 1;
        }
      });
  }, [
    globals,
    filterOnlyHide,
    dashSearch,
    selectedProviders,
    isTwGlobalDashboardCreatorClaim,
    secondaryFilterOption,
    selectComboboxItem,
    selectedRole,
    selectPlanFilter,
    onlyPopular,
    templateType,
    sortOption,
  ]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (dashSearch.trim().length > 0) {
        genericEventLogger(analyticsEvents.DASHBOARDS, {
          action: templateLibraryActions.SEARCH_TEMPLATES,
          search_query: dashSearch,
          results: filteredGlobalDashboards.length,
          positive_search: filteredGlobalDashboards.length > 0,
        });
      }
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [dashSearch, filteredGlobalDashboards.length]);

  const setTemplateTypeInternal = useCallback(
    (val: WillyElementType, replace?: boolean) => {
      const params = new URLSearchParams(window.location.search);
      params.set('template-type', val);
      if (replace) {
        navigate(
          {
            pathname: window.location.pathname,
            search: params.toString(),
          },
          { replace: true },
        );
      } else {
        navigate({
          pathname: window.location.pathname,
          search: params.toString(),
        });
      }
    },
    [navigate],
  );

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const templateType = params.get('template-type');
    if (templateType) {
      setTemplateType(templateType as WillyElementType);
    } else {
      setTemplateTypeInternal('dashboard', true);
    }
  }, [setTemplateTypeInternal, setTemplateType]);

  const featuredTemplates = useMemo(
    () => globals.filter((g) => g.isHighlight && !g.isHide),
    [globals],
  );

  const SearchInput = useMemo(
    () => (
      <div className={`dark:tw-nice-dark`}>
        <TextInput
          placeholder="Search for a template"
          value={dashSearch}
          onChange={(v) => setDashSearch(v)}
          leadingIcon={<PolarisIcon source={SearchMinor} color="base" />}
          description={
            loadingGlobals ? (
              <span>Loading...</span>
            ) : filteredGlobalDashboards.length > 0 ? (
              <div className={'text-gray-600'}>
                {`Showing ${filteredGlobalDashboards.length} of ${
                  globals.filter((x) => x.type === templateType && !x.isHide).length
                } ${templateType === 'dashboard' ? 'reports' : 'workflows'}`}
              </div>
            ) : (
              <>
                No reports to show.{' '}
                <span
                  className="underline cursor-pointer"
                  onClick={() => {
                    setSortOption('');
                    setSecondaryFilterOption('');
                    setSelectedProviders([]);
                    setSelectedRole('');
                    setSelectPlanFilter('');
                    setDashSearch('');
                    setOnlyPopular(false);
                  }}
                >
                  Try starting over?
                </span>
              </>
            )
          }
        />
      </div>
    ),
    [dashSearch, filteredGlobalDashboards.length, globals, loadingGlobals, templateType],
  );

  const secondaryFilterOptionsPerType = useMemo(
    () => secondaryFilterOptions(templateType),
    [templateType],
  );

  useEffect(() => {
    genericEventLogger(analyticsEvents.TEMPLATE_LIBRARY, {
      action: onlyInner
        ? templateLibraryActions.VIEW_TEMPLATE_LIBRARY_MODAL
        : templateLibraryActions.VIEW_TEMPLATE_LIBRARY,
    });
  }, [onlyInner]);

  useEffect(() => {
    const role = new URLSearchParams(location.search).get('template-role');
    if (role && globalDashboardRoles.map((x) => x.id).includes(role)) {
      setSelectedRole(role);
    }
  }, [globalDashboardRoles, search, location.search]);

  return (
    <div className={`share-willy-wrapper ${onlyInner ? '' : 'bg-white'} h-full overflow-hidden`}>
      <div className={`mx-auto ${!hideHeader ? 'p-12 pt-0' : ''} h-full overflow-auto`}>
        {!hideHeader &&
          (onlyInner ? (
            <Flex gap="md" mt={'30px' as Size} align="start" w="100%" justify="space-between">
              <Flex direction="column" align="start" gap="xs">
                <Text fw="600" fz={22} color="gray.8">
                  Report Templates
                </Text>
                <Text mb="16" fw="400" fz={14} maw={600} color="gray.7" align={'start'}>
                  No need to start from scratch – choose from dozens of ready-made templates and get
                  your project started quickly.
                </Text>
              </Flex>
              <Flex align="center" gap="md" pos="relative">
                {!!credits && creditsLeft === 0 && (
                  <Button
                    onClick={() => {
                      openFreeTrialPopup('template_library', false);
                      closeCreateModal();
                    }}
                  >
                    Upgrade
                  </Button>
                )}
                <LimitedAccessTooltip
                  $store={$createDashboardAccess}
                  target={(accessLimit) => {
                    return (
                      <Button
                        loading={copyLoading}
                        disabled={accessLimit !== 'can-access'}
                        onClick={() => {
                          closeCreateModal();
                          openDashSettingModal(true);
                        }}
                      >
                        Create New Report
                      </Button>
                    );
                  }}
                />
                {!!credits && credits !== Infinity && (
                  <Text color="gray.5" size="sm" pos="absolute" top={50} right={0}>
                    {creditsUsed}/{credits} credits used
                  </Text>
                )}
              </Flex>
            </Flex>
          ) : (
            <div
              className={`p-[30px] mt-12 w-full
        rounded-xl request-custom flex flex-col items-center gap-2 md:gap-6.5`}
            >
              <Title fw={600} weight={600} color="white" order={windowSize.isSmall ? 3 : 1}>
                Triple Whale Templates
              </Title>
              <Text mb="16" fw="400" fz={16} color="white" align="center">
                No need to start from scratch – choose from dozens of ready-made templates and get
                your project started quickly.
              </Text>
            </div>
          ))}
        {onlyInner && !!credits && creditsLeft === 0 && <NoCreditsLeftAlert />}
        <div className={'flex gap-4 items-start relative'}>
          <div
            className={`hidden md:block top-0 pt-10 ${
              !onlyInner ? 'md:pt-[40px]' : 'pt-4'
            } items-start gap-2 w-[230px] mr-[20px] xl:mr-[40px] sticky`}
          >
            <>
              <SideMenuItem
                id={'all'}
                name="All Templates"
                onSelect={(r) => {
                  setSelectedRole('');
                  setSelectPlanFilter('');
                  setOnlyPopular(false);
                  setFilterOnlyHide(false);
                }}
                active={
                  selectedRole === '' && selectPlanFilter === '' && !onlyPopular && !filterOnlyHide
                }
              />
              <SideMenuItem
                id={'popular'}
                name="Popular"
                onSelect={(r) => {
                  setSelectedRole('');
                  setSelectPlanFilter('');
                  setOnlyPopular(true);
                  setFilterOnlyHide(false);
                }}
                active={onlyPopular}
              />
              {isTwGlobalDashboardCreatorClaim && templateType === 'dashboard' && (
                <SideMenuItem
                  id={'hide'}
                  name="Only Hide (showed only to TW admin)"
                  onSelect={(r) => {
                    setSelectedRole('');
                    setSelectPlanFilter('');
                    setOnlyPopular(false);
                    setFilterOnlyHide(!filterOnlyHide);
                  }}
                  active={filterOnlyHide}
                />
              )}
              {templateType === 'dashboard' &&
                globalDashboardRoles
                  .filter((x) => x.category === 'NONE')
                  .map((role) => (
                    <SideMenuItem
                      key={role.id}
                      id={role.id}
                      name={role.name}
                      onSelect={(r) => {
                        setSelectedRole(r);
                        setSelectPlanFilter('');
                        setOnlyPopular(false);
                        setFilterOnlyHide(false);
                      }}
                      active={selectedRole === role.id}
                    />
                  ))}
              <Text weight={600} size="md" pl={'7px'} py="xs" color="gray.8">
                Use Cases
              </Text>
              {globalDashboardRoles
                .filter((x) => x.category === 'ROLES')
                .map((role) => (
                  <SideMenuItem
                    key={role.id}
                    id={role.id}
                    name={role.name}
                    onSelect={(r) => {
                      setSelectedRole(r);
                      setSelectPlanFilter('');
                      setOnlyPopular(false);
                      setFilterOnlyHide(false);
                    }}
                    active={selectedRole === role.id}
                  />
                ))}
              {/* <Text weight={600} size="md" pl="sm" py="xs" color="gray.8">
                Plan
              </Text>
              {planList.map((plan) => (
                <SideMenuItem
                  key={plan.planId}
                  id={plan.planId}
                  name={plan.name}
                  onSelect={(plan) => {
                    setSelectPlanFilter(plan);
                    setSelectedRole('');
                    setOnlyPopular(false);
                  }}
                  active={selectPlanFilter === plan.planId}
                />
              ))} */}
              <Box bg="gray.3" h={1} mb="lg" mt="md" />
              <AskAnSQWhaleExpert
                activator={
                  <Anchor fz="sm" fw={500} underline="never" as="a" ml="sm">
                    Request Custom {templateType === 'dashboard' ? 'Report' : 'Workflow'}
                  </Anchor>
                }
              />
              {!onlyInner && isTwGlobalDashboardCreatorClaim && templateType === 'dashboard' && (
                <div className="mt-6.5">
                  <Anchor
                    fz="sm"
                    fw={500}
                    underline="never"
                    ml="sm"
                    onClick={() => openDashSettingModal(true, true)}
                  >
                    Create New Template
                  </Anchor>
                </div>
              )}
              {<DashboardCreditsIndicator />}
            </>
          </div>

          <div className={`w-full flex-1`}>
            {featuredTemplates.length > 0 &&
              displayFeaturedTemplates &&
              templateType !== 'sequence' && (
                <FeaturedTemplatesSection
                  featuredTemplates={featuredTemplates}
                  toggleDisplayFeaturedTemplates={toggleDisplayFeaturedTemplates}
                  closeCreateModal={onlyInner ? closeCreateModal : undefined}
                />
              )}
            <div
              className={`flex flex-col md:hidden gap-4 justify-between pb-8 pt-8 sticky top-0 ${
                onlyInner ? '' : 'bg-white'
              } z-20`}
            >
              <div className="w-full flex-grow">{SearchInput}</div>
              <div className="w-full flex-grow">
                <WillyTemplatesFilterComboBox
                  data={templateFilterComboBox}
                  selected={selectComboboxItem!}
                  onSelect={(item) => {
                    setSelectComboboxItem(item);
                  }}
                />
              </div>
              <DashboardCreditsIndicator />
            </div>
            <div
              className={`${
                !onlyInner ? 'md:pt-[40px]' : 'pt-[15px]'
              } flex flex-col xl:flex-row xl:gap-8 flex-wrap gap-4 pb-8 pl-1 sticky md:top-0 ${
                isSequencesPage ? 'bg-white md:top-[1px]' : onlyInner ? '' : 'bg-white'
              } ${isSequencesPage ? 'pr-6 mt-[1px]' : ''} md:z-20`}
            >
              <div className="flex-grow hidden md:block">{SearchInput}</div>
              <div className="flex gap-8 items-center text-[14px] xl:pb-8">
                {!isSequencesPage && (
                  <WillySelect
                    data={typeFilterOptions}
                    targetClassName={'justify-start min-w-0'}
                    value={templateType}
                    onChange={(val) => {
                      setTemplateTypeInternal(val as WillyElementType);
                    }}
                    dropdownMinWidth="120px"
                  />
                )}
                <WillySelect
                  data={secondaryFilterOptionsPerType}
                  targetClassName={'justify-start min-w-0'}
                  value={secondaryFilterOption}
                  onChange={setSecondaryFilterOptionVal}
                />

                <ProviderComboBox
                  targetClassName={onlyInner ? 'justify-end ml-auto' : undefined}
                  values={selectedProviders}
                  setValues={setSelectedProvidersVal}
                />

                <div className="flex gap-4 md:flex-col relative">
                  <WillySelect
                    targetClassName={'md:justify-end'}
                    data={sortOptions}
                    value={sortOption}
                    onChange={setSortOptionVal}
                    dropdownMinWidth="120px"
                  />
                </div>
              </div>
            </div>
            {loadingGlobals ? (
              <div className="flex justify-center w-full m-auto">
                <NewLoader />
              </div>
            ) : (
              <>
                {(templateType === 'dashboard' || windowSize.isSmall) && (
                  <>
                    <div className="pl-1 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4  md:gap-8 xl:gap-16 flex-wrap">
                      {filteredGlobalDashboards.map((dashboard) => {
                        return (
                          <TemplateCard
                            dashboard={dashboard}
                            closeCreateModal={onlyInner ? closeCreateModal : undefined}
                            key={dashboard.id}
                          />
                        );
                      })}
                    </div>
                    <RequestCustomReportBanner />
                  </>
                )}
                {templateType === 'sequence' && !windowSize.isSmall && (
                  <div className={`${isSequencesPage && 'pr-6 overflow-y-hidden'}`}>
                    {filteredGlobalDashboards.map((dashboard) => {
                      return (
                        <div
                          className="flex justify-between items-start mb-10 gap-4"
                          key={dashboard.id}
                        >
                          <div className="flex gap-4 sm:gap-6 2xl:gap-8">
                            <div className={'w-[100px] xl:w-[165px] flex-shrink-0'}>
                              <WillyDashImage dashboard={dashboard} inSequence />
                            </div>
                            <div className={'flex flex-col'}>
                              <Text weight="500">{dashboard.name}</Text>
                              {dashboard.description.length > 0 && (
                                <div
                                  className={
                                    'text-[12px] text-gray-600 font-[400] max-w-[500px] my-2'
                                  }
                                >
                                  {dashboard.description}
                                </div>
                              )}
                              <Flex gap="sm" align="center" justify="space-between">
                                <Text size="xs">by {dashboard.users?.[0] || 'Triple Whale'}</Text>
                              </Flex>
                            </div>
                          </div>
                          <div className="flex items-center gap-8">
                            <div className={'flex justify-center items-center gap-3'}>
                              <Flex gap="sm" align={'center'}>
                                <Tooltip label={'Users'}>
                                  <Flex justify={'center'} align={'center'}>
                                    <Icon size={14} color={'gray.4'} name={'user-single'} />
                                    <Text size="sm" ml={2}>
                                      {dashboard.installCount}
                                    </Text>
                                  </Flex>
                                </Tooltip>
                              </Flex>
                              {dashboard.existsInStore && (
                                <Tooltip
                                  label={`Added To Your ${
                                    dashboard.type === 'dashboard' ? 'Reports' : 'Workspaces'
                                  } `}
                                >
                                  <div className={'ml-3'}>
                                    <AddedToBoard width="16" />
                                  </div>
                                </Tooltip>
                              )}
                            </div>
                            <Link
                              className="no-underline text-inherit"
                              to={`/templates/${dashboard.id}`}
                            >
                              <Button variant={'white'}>Preview</Button>
                            </Link>
                            {isTwGlobalDashboardCreatorClaim && (
                              <ActionIcon
                                icon={'edit'}
                                onClick={
                                  isSequenceElement(dashboard)
                                    ? () => {
                                        navigate({
                                          pathname: `/workflows/create/${dashboard.id}`,
                                        });
                                      }
                                    : () => {}
                                }
                              />
                            )}
                            {isTwGlobalDashboardCreatorClaim && (
                              <ActionIcon
                                icon={<Icon name={'delete'} color={'red.6'} size={22} />}
                                onClick={async () => {
                                  if (
                                    !(await confirm({
                                      title: 'Remove Public Sequence',
                                      message:
                                        'Are you sure you want to remove this sequence from the public namespace?',
                                    }))
                                  )
                                    return;

                                  const isRemoved = await deleteMainElement(
                                    dashboard,
                                    dashboard.packages,
                                    dashboard.defaultPackages,
                                  );
                                  if (isRemoved) {
                                    toast.success('Template removed from public namespace!');
                                  }
                                }}
                              />
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const WillyTemplatesComponent = ({ onlyInner }: { onlyInner?: boolean }) => {
  const { $activeTab } = useDashContext();
  const { search } = useLocation();
  const [templateType, setTemplateType] = useState<WillyElementType>('dashboard');

  useEffect(() => {
    const type = new URLSearchParams(location.search).get('template-type');
    if (type && typeFilterOptions.map((x) => x.value).includes(type)) {
      setTemplateType(type as WillyElementType);
    }
  }, [search]);

  useEffect(() => {
    $activeTab.set('global');
  }, [$activeTab]);

  return onlyInner ? (
    <WillyTemplatesInner templateType={templateType} setTemplateType={setTemplateType} onlyInner />
  ) : (
    <>
      <WillyPageWrapper
        tabs={leftTabs as any}
        activeTab={'global'}
        setActiveTab={() => {}}
        sidePanelContent={<></>}
        hideSidePanel={true}
        mainContent={
          <Allotment vertical>
            <Allotment.Pane className="!overflow-auto willy-main-pane">
              <WillyTemplatesInner templateType={templateType} setTemplateType={setTemplateType} />
            </Allotment.Pane>
          </Allotment>
        }
      />
    </>
  );
};

type SideMenuItemProps = {
  id: string;
  name: string;
  active: boolean;
  onSelect: (id) => void;
};

const SideMenuItem: FC<SideMenuItemProps> = ({ id, name, active, onSelect }) => {
  return (
    <div
      onClick={() => onSelect(id)}
      className={`flex items-center gap-3 p-3 my-2 cursor-pointer rounded-md text-gray-800 text-[14px] font-medium  ${
        active ? 'bg-gray-200' : ''
      } hover:bg-gray-200  transition duration-300 ease-in-out`}
    >
      <div>{name}</div>
    </div>
  );
};

export const WillyTemplates = computeFeatureFlags(
  [FeatureFlag.TW_3_0_FF, FeatureFlag.TW_ONLY_3_0_FF],
  WillyTemplatesComponent,
  () => (
    <UpgradePageFallBack
      InAppContextBannerEnabled={false}
      title="TW 3.0"
      description="Chat, analyze and manage with TW 3.0, the AI assistant that helps you effortlessly interact with your eCommerce business."
      featureFlag={FeatureFlag.TW_3_0_FF}
      showButtons={false}
    />
  ),
);
