import { Button, Icon, Tooltip } from '@tw/ui-components';
import { stepActionType, WorkflowStep } from '../types/willyTypes';
import { v4 as uuidV4 } from 'uuid';
import { useFeatureFlag } from 'feature-flag-system';
import LockedFeatureIndicator from 'components/library/LockedFeatures/LockedFeatureIndicator';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';

type StepSelectorProps = {
  action: stepActionType;
  shouldBeDisabled: boolean;
  fromRule?: boolean;
  addNewStep: (stepType: Partial<WorkflowStep>, ruleDescendant?: boolean) => void;
  closeCategoryDrawer: () => void;
  onClose: () => void;
};

export const StepSelector: React.FC<StepSelectorProps> = ({
  action,
  shouldBeDisabled,
  fromRule,
  addNewStep,
  closeCategoryDrawer,
  onClose,
}) => {
  const { shouldNotBeSeen: isLocked } = useFeatureFlag(action.ff);
  const disabled = isLocked || shouldBeDisabled;
  const isSmall = useIsSmall();

  return (
    <Tooltip
      withArrow
      w={250}
      multiline
      label={action.tooltip}
      key={action.type === 'tool' ? action.toolName : action.type}
    >
      <Button
        disabled={disabled}
        leftSection={
          isLocked ? (
            <LockedFeatureIndicator featureFlag={action.ff} iconOnly />
          ) : (
            <Icon name={action.icon} color={`${action?.color || 'green'}.4`} />
          )
        }
        variant="white"
        justify="start"
        size="md"
        onClick={() => {
          if (shouldBeDisabled) {
            return;
          }
          let step: Partial<WorkflowStep> = {
            id: uuidV4(),
            createdAt: new Date().toISOString(),
            stepType: action.type,
          };

          if (step.stepType === 'tool' && action.type === 'tool' && action.toolName !== 'auto') {
            step.toolToUse = action.toolName;
          }

          addNewStep(step, fromRule);
          closeCategoryDrawer();

          if (isSmall) {
            onClose();
          }
        }}
      >
        {action.name}
      </Button>
    </Tooltip>
  );
};
