import { Title, Text, Button, Flex, Icon } from '@tw/ui-components';
import { AskAnSQWhaleExpert } from 'components/Willy/AskAnSQWhaleExpert';
import { FC } from 'react';

export const RequestCustomReportBanner: FC = () => {
  return (
    <div
      className={`p-[30px] w-full md:mt-8 xl:mt-16
        rounded-xl shadow request-custom flex flex-col gap-2 md:gap-6.5`}
    >
      <Flex align="center" gap="sm" justify="start" mb="lg">
        <Icon name="triple-whale-rounded-logo" color="white" size={27} />
        <Text fz={27} weight={600} color="white">
          Triple Whale
        </Text>
      </Flex>
      <Title weight={600} color="white">
        Have a report in mind that you want us to build?
      </Title>
      <Text color="gray.0">
        Let us know what you need in your report and we will build it for you.
      </Text>
      <AskAnSQWhaleExpert
        activator={
          <Button variant="activator" mt="lg">
            Request Custom Report
          </Button>
        }
      />
    </div>
  );
};
