import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Avatar, Button, Card, Icon, Text, Modal } from '@shopify/polaris';
import { DeleteMinor, PlusMinor } from '@shopify/polaris-icons';
import { RootState } from 'reducers/RootType';
import { toast } from 'react-toastify';
import { useAppDispatch } from 'index';
import {
  googleSheetsConnect,
  removeGoogleSheetsAccount,
  GOOGLE_SHEETS_SETTINGS_CLOSE,
  googleSheetsDisconnect,
} from 'ducks/googleSheets';

export default function GoogleSheetsSettings({ done }) {
  const googleSheetsAccounts = useSelector((state: RootState) => state.googleSheetsAccounts);
  const isGoogleSheetsSettingsOpen = useSelector(
    (state: RootState) => state.isGoogleSheetsSettingsOpen,
  );
  const dispatch = useAppDispatch();

  const onClose = useCallback(() => {
    dispatch({ type: GOOGLE_SHEETS_SETTINGS_CLOSE });
  }, [dispatch]);

  return (
    <Modal
      open={isGoogleSheetsSettingsOpen}
      title="Connection Details: Google Sheets"
      onClose={onClose}
    >
      <Card.Section title="Connected Accounts">
        {googleSheetsAccounts.map((account, i) => (
          <GoogleSheetsAccount key={i} account={account} />
        ))}
      </Card.Section>
      <Card.Section>
        <div className="flex justify-between items-center">
          <AddGoogleSheetsAccount />
          <DisconnectAllGoogleSheetsAccounts onClose={onClose} />
        </div>
      </Card.Section>
    </Modal>
  );
}

const GoogleSheetsAccount = ({ account }) => {
  const dispatch = useAppDispatch();
  return (
    <div
      id={`sheets-account-${account.name}`}
      aria-label={`Google Sheets Integration for ${account.name}`}
      className="flex justify-between items-center my-4 p-6 rounded bg-blue-50"
    >
      <div>
        <div className="flex items-center">
          {account.picture ? (
            <img
              src={account.picture}
              referrerPolicy="no-referrer"
              className="h-12 w-12 rounded-full mr-4"
            />
          ) : (
            <div className="h-12 w-12 mr-4 rounded-full bg-gray-200"></div>
          )}
          <div className="mr-4">
            <Text variant="bodyMd" as="p">
              {account.name}
            </Text>
          </div>
          {account.email && (
            <div className="mr-4">
              <Text variant="bodySm" as="p">
                {account.email}
              </Text>
            </div>
          )}
        </div>
      </div>
      <Button
        onClick={() => {
          dispatch(removeGoogleSheetsAccount(account.id));
        }}
        size="slim"
      >
        Disconnect
      </Button>
    </div>
  );
};

const AddGoogleSheetsAccount = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  return (
    <Button
      onClick={() => {
        setLoading(true);
        googleSheetsConnect();
      }}
      icon={PlusMinor}
      loading={loading}
    >
      Add a Google Sheets Account
    </Button>
  );
};

const DisconnectAllGoogleSheetsAccounts = ({ onClose }) => {
  const dispatch = useAppDispatch();
  return (
    <Button
      onClick={() => {
        dispatch(googleSheetsDisconnect());
        onClose();
      }}
      icon={DeleteMinor}
    >
      Disconnect All
    </Button>
  );
};
