import { Button, Size } from '@tw/ui-components';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';

export type DropdownActivatorProps = {
  title?: string;
  loading?: boolean;
  toggle: () => void;
  disabled?: boolean;
  customActivator?: React.ReactNode;
  label: React.ReactNode;
  size?: Exclude<Size, 0>;
  active?: boolean;
};

export const DropdownActivator: React.FC<DropdownActivatorProps> = ({
  title,
  loading = false,
  disabled = false,
  toggle,
  customActivator,
  label,
  size,
  active = false,
}) => {
  const isSmall = useIsSmall();

  return (
    <>
      {customActivator ? (
        <div onClick={() => !disabled && !loading && toggle()}>{customActivator}</div>
      ) : (
        <Button
          variant={isSmall && active ? 'primary' : 'activator'}
          rightSection="caret-down"
          onClick={toggle}
          disabled={disabled || loading}
          loading={loading}
          size={size}
          color={isSmall && active ? 'gray.4' : undefined}
        >
          {title ? title + ' ' : ''}
          {label}
        </Button>
      )}
    </>
  );
};
