import { Popover } from '@shopify/polaris';
import { ChevronLeftMinor, ChevronRightMinor, EditMajor } from '@shopify/polaris-icons';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { CopyToClipboard } from './CopyToClipboard';
import TWSlides from 'components/library/TWSlides/TWSlides';
import { SlideshowRef } from 'react-slideshow-image';
import { Accordion, Button, Flex, Icon, Modal, Tabs, Text, Tooltip } from '@tw/ui-components';
import { getSocket } from './WillySocket';
import { useAppSelector } from 'reducers/RootType';
import { WillySimpleText } from './WillySimpleText';
import {
  BaseEventPayload,
  Dialect,
  WidgetQuery,
  WillyParameter,
  WillyWidgetElement,
} from './types/willyTypes';
import { Prism as ReactSyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { FreeQuery } from 'pages/FreeQuery/FreeQuery';
import axiosInstance from 'utils/axiosInstance';
import {
  executeCustomQuery,
  fetchWidgetQueries,
  formatSqlSafely,
  mainQueryChanged,
} from './utils/willyUtils';
import { genericEventLogger, analyticsEvents, chatActions } from 'utils/dataLayer';
import { useFeatureFlag } from 'feature-flag-system';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { $activeAccounts, $currency } from '../../$stores/$shop';
import { $dialect, $isTWDevClaim, changeDialect } from '$stores/$user';
import { $currentDateRange } from '$stores/willy/$dateRange';
import { refreshDashboardWidgets } from './dashContext';
import { useStoreValue } from '@tw/snipestate';

type WillyQuestionAndSqlProps = {
  queries: WidgetQuery[];
  queriesChanged: (queries: WidgetQuery[]) => Promise<void>;
  queryId: string;
  show: boolean;
  showCTA?: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  dashboardId?: string;
  isGlobalDashboard?: boolean;
  isCustomViewDashboard?: boolean;
  conversationId?: string;
  queryDialect?: Dialect;
  historicalQueryIds?: string[];
  parameters?: WillyParameter[];
  isDynamic?: boolean;
  mode?: WillyWidgetElement['mode'];
  setTableBuilderModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
  canEdit?: boolean;
};

export const WillyQuestionAndSql: React.FC<WillyQuestionAndSqlProps> = ({
  queries,
  queryId,
  show,
  showCTA = true,
  conversationId,
  dashboardId,
  isGlobalDashboard,
  isCustomViewDashboard,
  queryDialect,
  historicalQueryIds,
  parameters,
  isDynamic,
  mode,
  setTableBuilderModalOpened,
  setShow,
  queriesChanged,
  canEdit = true,
}) => {
  const { search } = useLocation();
  const willySocket = useMemo(() => getSocket(), []);
  const currentShopId = useAppSelector((state) => state.currentShopId);
  const currency = useStoreValue($currency);
  const shopTimezone = useAppSelector((state) => state.shopTimezone);
  const activeAccounts = useStoreValue($activeAccounts);
  const dialect = useStoreValue($dialect);
  const isTWDevClaim = useStoreValue($isTWDevClaim);
  const currentDateRange = useStoreValue($currentDateRange);

  const { shouldNotBeSeen: isSQLBlocked } = useFeatureFlag(FeatureFlag.SQL_FF);

  const sqlSliderRef = useRef<SlideshowRef>(null);
  const questionSliderRef = useRef<SlideshowRef>(null);
  const [explanations, setExplanations] = useState<string[]>([]);
  const [loadingExplanations, setLoadingExplanations] = useState<Record<string, boolean>>({});
  const [index, setIndex] = useState(0);
  const [activeTab, setActiveTab] = useState<'query' | 'explanation'>('query');
  const [queryInModal, setQueryInModal] = useState<string>();
  const [editingQuery, setEditingQuery] = useState<boolean>(false);
  const [editQueryError, setEditQueryError] = useState<string>('');
  const [queryParameters, setQueryParameters] = useState<WillyParameter[]>([]);
  const [historicalQueries, setHistoricalQueries] = useState<
    { id: string; generatedQuery: string; createdAt: string }[]
  >([]);
  const [showRestoredQueries, setShowRestoredQueries] = useState<boolean>(false);
  const [restoringPreviousQuery, setRestoringPreviousQuery] = useState<boolean>(false);

  const activeSql = useMemo(() => {
    return queries[index].query;
  }, [queries, index]);

  const searchParams = useMemo(() => {
    const params = new URLSearchParams(search);
    if (activeSql) {
      params.set('query', activeSql);
    }
    if (params.has('conversationId')) {
      params.delete('conversationId');
    }
    return params.toString();
  }, [activeSql, search]);

  const formattedSql = useMemo(() => {
    if (!activeSql) {
      return '';
    }
    try {
      return formatSqlSafely(activeSql);
    } catch (e) {
      return activeSql;
    }
  }, [activeSql]);

  const queryVars = useMemo(() => {
    return [...(parameters || []).filter((p) => p.isQueryParameter).map((p) => p.column)];
  }, [parameters]);

  const queryVarsValues = useMemo(() => {
    let allQvv: { [a: string]: { query: string; value: string; options: string[] } } = {};
    if (isDynamic) {
      allQvv = {
        start_date: {
          query: '',
          value: currentDateRange?.start.format('YYYY-MM-DD') || '',
          options: [],
        },
        end_date: {
          query: '',
          value: currentDateRange?.end.format('YYYY-MM-DD') || '',
          options: [],
        },
      };
    }

    if (!parameters) {
      return allQvv;
    }

    allQvv = parameters.reduce((acc, p) => {
      let v = p.value || [];
      if (Array.isArray(v)) {
        v = v.join(',');
      }

      if (p.column === 'start_date') {
        v = currentDateRange?.start.format('YYYY-MM-DD') || '';
      } else if (p.column === 'end_date') {
        v = currentDateRange?.end.format('YYYY-MM-DD') || '';
      }
      return {
        ...acc,
        [p.column]: {
          query: p.query || '',
          value: v,
          options: p.options || [],
        },
      };
    }, allQvv);

    return allQvv;
  }, [currentDateRange?.end, currentDateRange?.start, parameters, isDynamic]);

  const updateQuery = useCallback((queryStr: string) => {
    if (!queryStr) {
      return;
    }

    setQueryInModal(queryStr);
  }, []);

  useEffect(() => {
    (async () => {
      if (!historicalQueryIds?.length) {
        return;
      }
      const { data: historicalQueries } = await axiosInstance.post('/v2/willy/get-queries-by-id', {
        shopId: currentShopId,
        queryIds: historicalQueryIds,
      });

      setHistoricalQueries(historicalQueries);
    })();
  }, [historicalQueryIds, currentShopId]);

  useEffect(() => {
    if (queries) {
      setExplanations(queries.map((x) => x.explanation || ''));
    }
  }, [queries]);

  useEffect(() => {
    (async () => {
      if (!activeSql) {
        return;
      }
      if (activeTab !== 'explanation') {
        return;
      }
      if (loadingExplanations[index]) {
        return;
      }

      if (explanations[index]) {
        return;
      }

      if (queries[index]?.explanation) {
        setExplanations((x) => {
          return x.map((z, i) => {
            if (i === index) {
              return queries[index].explanation!;
            }
            return z;
          });
        });
        return;
      }

      setLoadingExplanations((x) => ({ ...x, [index]: true }));

      willySocket.emit('insight-sql', {
        id: index.toString(),
        conversationId: queryId,
        query: activeSql,
        shopId: currentShopId,
      });
    })();
  }, [
    activeSql,
    activeTab,
    index,
    queryId,
    willySocket,
    currentShopId,
    loadingExplanations,
    queries,
    explanations,
  ]);

  useEffect(() => {
    const receiveMessage = (event: { text: string } & BaseEventPayload) => {
      const { text, conversationId: cId, messageId } = event;

      if (queryId !== cId) {
        return;
      }

      const index = +messageId;

      setExplanations((y) => {
        return y.map((z, i) => {
          if (i === index) {
            return z + text;
          }
          return z;
        });
      });

      sqlSliderRef.current?.goTo(index);

      setTimeout(() => {
        setLoadingExplanations((x) => {
          return { ...x, [index]: false };
        });
      }, 500);
    };

    willySocket.on('insight', receiveMessage);

    return () => {
      willySocket.off('insight', receiveMessage);
    };
  }, [willySocket, queryId]);

  useEffect(() => {
    const done = async (event) => {
      const { conversationId: cId, messageId } = event;

      if (queryId !== cId) {
        return;
      }

      setLoadingExplanations((x) => {
        return { ...x, [messageId]: false };
      });

      await queriesChanged(
        queries.map((x) => {
          if (x.id !== queryId) {
            return x;
          }
          return {
            ...x,
            explanation: explanations[messageId],
          };
        }),
      );
    };

    willySocket.on('done', done);

    return () => {
      willySocket.off('done', done);
    };
  }, [willySocket, queryId, queries, queriesChanged, explanations]);

  let trackOpenInEditor = () => {
    let eventContext = conversationId
      ? analyticsEvents.CHAT
      : dashboardId
        ? analyticsEvents.DASHBOARDS
        : analyticsEvents.SQWHALE;
    let action = chatActions.OPEN_IN_SQWHALE_EDITOR;
    genericEventLogger(eventContext, { formattedSql, action, conversationId });
  };

  return (
    <>
      <Popover
        activator={
          <>
            {showCTA && (
              <div
                className="flex items-center justify-center cursor-pointer p-1"
                onClick={() => {
                  setShow((x) => !x);
                }}
              >
                <Icon name={'code-bracket'} />
              </div>
            )}
            {!showCTA && <div></div>}
          </>
        }
        preferredAlignment="right"
        active={show}
        onClose={() => setShow(false)}
      >
        <Popover.Pane fixed>
          <Popover.Section>
            <div className="flex flex-col gap-4">
              <div className="flex flex-row space-between">
                <div className="text-xl font-medium">Question:</div>
                <div className="flex gap-3 align-items-center rounded-full border border-slate-400 border-1 border-solid px-3">
                  <Icon name="triple-whale-rounded-logo" color="cyan.8" />
                  <Text size="sm">By Triple Whale</Text>
                </div>
              </div>
              <TWSlides
                properties={{
                  infinite: false,
                  arrows: false,
                  canSwipe: true,
                  ref: questionSliderRef,
                  // onStartChange
                  onStartChange: (from, to) => {
                    setIndex(to);
                    sqlSliderRef.current?.goTo(to);
                  },
                }}
                items={queries
                  .map((d) => d.question)
                  ?.map((x, i) => ({
                    src: x,
                    alt: x,
                    id: i,
                    type: 'ReactNode',
                    Children: (
                      <i key={i} className="block whitespace-pre-wrap max-h-[110px] overflow-auto">
                        {x}
                      </i>
                    ),
                  }))}
              />
            </div>
          </Popover.Section>
        </Popover.Pane>

        <Popover.Pane>
          <div className="flex flex-col">
            <div className="flex items-center sticky top-0 bg-white z-10 p-6.5">
              <Tabs
                defaultValue={activeTab}
                onChange={(v) => {
                  if (!v) {
                    return;
                  }
                  setActiveTab(v as 'query' | 'explanation');
                }}
              >
                <Tabs.List className="border-0">
                  <Tabs.Tab value="query">
                    <span className="flex gap-1">
                      <Text size="sm">Query</Text>
                      {isTWDevClaim && !!queryDialect && (
                        <Text size="sm" color="gray.5">
                          ({queryDialect})
                        </Text>
                      )}
                    </span>
                  </Tabs.Tab>
                  <Tabs.Tab value="explanation">
                    <Text size="sm">Explanation</Text>
                  </Tabs.Tab>
                </Tabs.List>
              </Tabs>
              <span className="ml-auto flex items-center gap-2">
                <CopyToClipboard
                  text={activeTab === 'query' ? formattedSql : explanations[index]}
                  context={conversationId ? 'chat' : dashboardId ? 'dashboard' : 'sqwhale'}
                />
                {!dashboardId && (
                  <Link
                    to={{
                      pathname: '/sql-editor',
                      search: searchParams,
                    }}
                    // @ts-ignore
                    reloadDocument={true}
                    target="_blank"
                    onClick={trackOpenInEditor}
                  >
                    <EditMajor className="w-8 flex items-center justify-center fill-primary dark:fill-gray-400" />
                  </Link>
                )}
                {!!dashboardId && canEdit && !queries[index]?.fromMetricBuilder && (
                  <div
                    className="cursor-pointer"
                    onClick={async () => {
                      if (mode === 'builder') {
                        setTableBuilderModalOpened(true);
                      } else {
                        if (queryDialect && dialect !== queryDialect) {
                          await changeDialect(queryDialect);
                        }
                        // const queryIdToGet = queries[index].id;
                        // const { data } = await axiosInstance.post('/v2/willy/get-query-by-id', {
                        //   shopId: currentShopId,
                        //   queryId: queryIdToGet,
                        // });
                        // const formatted = formatSqlSafely(data.generatedQuery || formattedSql);

                        const formatted = formatSqlSafely(formattedSql);
                        setQueryInModal(formatted);
                        setShow(false);
                      }
                    }}
                  >
                    <EditMajor className="w-8 flex items-center justify-center fill-primary dark:fill-gray-400" />
                  </div>
                )}
              </span>
            </div>
            <div className="p-6.5 pt-0">
              <TWSlides
                properties={{
                  infinite: false,
                  canSwipe: true,
                  ref: sqlSliderRef,
                  onStartChange: (from, to) => {
                    setIndex(to);
                    questionSliderRef.current?.goTo(to);
                  },
                  prevArrow: (
                    <span className="w-12 h-12 border-none ml-0 rounded-full">
                      <ChevronLeftMinor className="w-12 h-12 fill-white" />
                    </span>
                  ),
                  nextArrow: (
                    <span className="w-12 h-12 border-none mr-0 rounded-full">
                      <ChevronRightMinor className="w-12 h-12 fill-white" />
                    </span>
                  ),
                }}
                items={(activeTab === 'explanation'
                  ? explanations
                  : queries.map((z) => z.query)
                ).map((x, i) => ({
                  src: x,
                  alt: x,
                  id: i,
                  type: 'ReactNode',
                  Children: (
                    <div key={i} className="prose dark:prose-invert">
                      {activeTab === 'query' && (
                        <ReactSyntaxHighlighter
                          language={'sql'}
                          style={vscDarkPlus}
                          showLineNumbers
                        >
                          {formattedSql}
                        </ReactSyntaxHighlighter>
                      )}
                      {activeTab === 'explanation' && (
                        <WillySimpleText text={x} loading={loadingExplanations[index]} />
                      )}
                    </div>
                  ),
                }))}
              />
            </div>
          </div>
        </Popover.Pane>

        {!!conversationId && (
          <Popover.Pane fixed>
            <Popover.Section>
              <div className="flex gap-2">
                <Link
                  to={{
                    pathname: '/chat',
                    search: `?conversationId=${conversationId}&${search}`,
                  }}
                  onClick={trackOpenInEditor}
                >
                  Go to containing conversation
                </Link>
              </div>
            </Popover.Section>
          </Popover.Pane>
        )}
        {!!historicalQueries?.length && (
          <Popover.Pane fixed>
            <Popover.Section>
              <div className="flex gap-2">
                <Button
                  variant="white"
                  onClick={() => {
                    setShowRestoredQueries(true);
                    setShow(false);
                  }}
                >
                  Restore to previous query
                </Button>
              </div>
            </Popover.Section>
          </Popover.Pane>
        )}
      </Popover>

      <Modal.Root
        size="xl"
        centered
        fullScreen
        opened={!!queryInModal}
        onClose={() => setQueryInModal(undefined)}
        closeOnEscape={false}
      >
        <Modal.Overlay />
        <Modal.Content className="!flex flex-col w-full h-full">
          <Modal.Header>
            <Modal.Title>
              <span className=" text-3xl font-semibold">Edit Query</span>
            </Modal.Title>
            <Button variant="white" onClick={() => setQueryInModal(undefined)}>
              Close
            </Button>
          </Modal.Header>
          <div className="w-full h-[1px] bg-[#E5E7EB] mt-5"></div>
          <Modal.Body className="flex flex-auto">
            <div className="flex w-full">
              <div className="w-full flex flex-col flex-auto">
                <FreeQuery
                  showResizer={false}
                  initialQuery={queryInModal}
                  queryChanged={updateQuery}
                  onParametersChanged={setQueryParameters}
                  queryVars={queryVars}
                  queryVarsValues={queryVarsValues}
                />
                <div className="w-full h-[1px] bg-[#E5E7EB] mb-5"></div>
                <div className="my-auto pt-4 w-full flex">
                  <div className="ml-auto">
                    <Tooltip label={isSQLBlocked ? 'Upgrade to access SQL' : ''}>
                      <Button
                        // disabled={!query?.query}
                        disabled={isSQLBlocked}
                        loading={editingQuery}
                        variant="primary"
                        onClick={async () => {
                          if (!queryInModal || !activeAccounts || !dialect) {
                            return;
                          }
                          try {
                            setEditingQuery(true);
                            const updatedQuery = await executeCustomQuery({
                              query: queryInModal,
                              shopId: currentShopId,
                              activeAccounts,
                              currency,
                              timezone: shopTimezone,
                              dialect,
                              queryParams: queryParameters?.reduce(
                                (acc, p) => ({ ...acc, [p.column]: p.value }),
                                {},
                              ),
                            });

                            if (updatedQuery?.error) {
                              setEditQueryError(updatedQuery.error);
                              return;
                            }

                            if (!dashboardId) {
                              setEditQueryError('Report id not found');
                              return;
                            }
                            const res = await mainQueryChanged({
                              newQuery: updatedQuery,
                              oldQueryId: queries[index].id,
                              dashboardId,
                              isGlobalDashboard,
                              isCustomViewDashboard,
                              queryParameters,
                            });

                            if (!res || typeof res === 'string') {
                              setEditQueryError(res || 'Something went wrong');
                            } else {
                              setQueryInModal(undefined);
                            }
                            await refreshDashboardWidgets();
                          } catch (e) {
                            setEditQueryError(e.message || 'Something went wrong');
                          } finally {
                            setEditingQuery(false);
                          }
                        }}
                      >
                        Update Query
                      </Button>
                    </Tooltip>
                    {editQueryError && (
                      <Text size="sm" color="red.5" lh={2}>
                        {editQueryError}
                      </Text>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>

      <Modal
        title="Restore to previous query"
        opened={showRestoredQueries}
        onClose={() => setShowRestoredQueries(false)}
      >
        <Accordion>
          {historicalQueries?.map((q, i) => {
            return (
              <Accordion.Item key={q.id} value={q.id}>
                <Accordion.Control>
                  <Flex gap="xs">
                    <Text fw="bold">{i + 1}:</Text> <Text color="gray.6">{q.createdAt}</Text>
                  </Flex>
                </Accordion.Control>
                <Accordion.Panel>
                  <Flex direction="column" gap="sm">
                    <ReactSyntaxHighlighter
                      language={'sql'}
                      style={vscDarkPlus}
                      showLineNumbers
                      customStyle={{
                        maxHeight: '300px',
                        overflow: 'auto',
                      }}
                    >
                      {formatSqlSafely(q.generatedQuery)}
                    </ReactSyntaxHighlighter>
                    <div>
                      <Button
                        loading={restoringPreviousQuery}
                        variant="white"
                        onClick={async () => {
                          if (
                            !currentDateRange ||
                            !currentShopId ||
                            !currency ||
                            !shopTimezone ||
                            !activeAccounts
                          ) {
                            return;
                          }

                          if (!dashboardId) {
                            setEditQueryError('Report id not found');
                            return;
                          }
                          setRestoringPreviousQuery(true);
                          let { start, end } = currentDateRange;
                          const abortController = new AbortController();
                          const previousQuery = await fetchWidgetQueries({
                            shopId: currentShopId,
                            queryIds: [q.id],
                            abortSignal: abortController.signal,
                            additionalShopIds: activeAccounts,
                            currency,
                            start: start,
                            end: end,
                            dataType: 'nlq',
                          });

                          if (!previousQuery || !previousQuery.length) {
                            setRestoringPreviousQuery(false);
                            return;
                          }

                          const query = previousQuery[0];
                          const res = await mainQueryChanged({
                            newQuery: query,
                            oldQueryId: queries[index].id,
                            dashboardId,
                            isGlobalDashboard,
                            isCustomViewDashboard,
                            queryParameters,
                          });

                          if (!res || typeof res === 'string') {
                            setEditQueryError(res || 'Something went wrong');
                          } else {
                            setShowRestoredQueries(false);
                          }
                        }}
                      >
                        Restore to this query
                      </Button>
                    </div>
                  </Flex>
                </Accordion.Panel>
              </Accordion.Item>
            );
          })}
        </Accordion>
      </Modal>
    </>
  );
};
