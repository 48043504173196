import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  rulesActionDropdown,
  conditionsLevels,
  EditRule,
  EditRuleExpression,
  EditStrategy,
  PRELIMINARY_PRIORITY,
  ruleOperator,
  RuleTime,
  scheduleOptions,
  Rule,
} from './types';

import {
  Button,
  FormLayout,
  Modal,
  Stack,
  Tooltip,
  Card,
  Link,
  Icon,
  ButtonGroup,
} from '@shopify/polaris';
import { DeleteMajor, DuplicateMinor, PlusMinor } from '@shopify/polaris-icons';
import { ServicesIds } from '@tw/types/module/services';

import { RulesContext } from './rulesContext';
import { RuleHeader } from './RuleHeader';
import { RuleAction } from './RuleAction';
import { RuleCondition } from './RuleCondition';
import _, { cloneDeep } from 'lodash';
import { defaultConditions } from './types';
import { FilterCondition } from './FilterCondition';
import { getNextRun, simulateRule } from 'ducks/rulesEngine/action';
import {
  getConditionsLength,
  getMaxPeriod,
  mapConditionsForEdit,
  mapConditionsForSave,
  mapRulesConditionsWith,
  reduceRulesConditionsWith,
} from './helpers';
import { useSelector } from 'react-redux';
import { type RootState } from 'reducers/RootType';
import moment from '@tw/moment-cached';
import { ConditionalWrapper } from 'components/ConditionalWrapper';

export interface RuleType {
  rule: {
    fact: string;
    path: string;
    params: RuleTime;
    operator: string;
    value: number;
  };
}

type Props = {
  onCloseCreatePopup: () => void;
  isOpen: boolean;
  isEdit: boolean;
  handleNew: (serviceId: ServicesIds) => void;
  defaultExpression?: any;
  operatorBetweenExpressions?: 'and' | 'or';
  editRule?: EditStrategy;
  setShowError: Dispatch<SetStateAction<boolean>>;
  errorSave: string;
  showError: boolean;
  pendingSave: boolean;
  startSimulation: boolean;
  unableLaunch?: boolean;
  unableLaunchMessage?: string;
  setPendingSave: Dispatch<SetStateAction<boolean>>;
  setStartSimulation: Dispatch<SetStateAction<boolean>>;
  existingStrategies: any[];
};

export const CreateEditRulesEngineModal: React.FC<Props> = ({
  onCloseCreatePopup,
  editRule,
  errorSave,
  setShowError,
  showError,
  pendingSave,
  startSimulation,
  setPendingSave,
  unableLaunch,
  unableLaunchMessage,
  setStartSimulation,
  existingStrategies,
}) => {
  const [rule, setRule] = useState<EditRule>();
  const [pendingDelete, setPendingDelete] = useState(false);
  const { currentShopId, userEmail, saveRule } = useContext(RulesContext);

  const [conditionsLevelsList, setConditionsLevelsList] = useState([{ label: '', value: '' }]);
  const [isTemplate, setIsTemplate] = useState(false);
  const [draft, setDraft] = useState(false);
  const [simulationMsg, setSimulationMsg] = useState<{
    success: string[];
    failed: number;
  }>({ success: [], failed: 0 });
  const [openSimulation, setOpenSimulation] = useState(false);
  const [simulationLoading, setSimulationLoading] = useState(false);
  const [showWarning, setShowWarning] = useState<string>();
  const [simulationError, setSimulationError] = useState<string>();
  const [simulationActions, setSimulationActions] = useState<boolean>(true);
  const shopTimezone = useSelector((state: RootState) => state.shopTimezone);
  const shopTimezoneAbbr = moment()
    .tz(shopTimezone || 'America/Los_Angeles')
    .zoneAbbr();

  // eslint-disable-next-line react-hooks/exhaustive-deps

  const defaultShopRules: EditStrategy = {
    shop: currentShopId,
    createdBy: userEmail,
    updatedBy: userEmail,
    name: '',
    description: '',
    domain: 'shop',
    status: 'inactive',
    version: {
      rules: [
        {
          name: '',
          description: '',
          status: 'inactive',
          level: '',
          frequency: '1 days',
          runAt: '00:00',
          daysOfWeek: [-1],
          conditions: {
            all: [{ any: [defaultConditions] }],
          },
          event: {
            type: '',
          },
        },
      ],
    },
  };
  useEffect(() => {
    initRule();
  }, []);

  useEffect(() => {
    let conditionsLevelsList = Object.values(conditionsLevels)
      .filter((level) => {
        return level.show?.includes(rule?.level || '');
      })
      .map((action) => ({ label: action.label, value: action.value }));

    setConditionsLevelsList(conditionsLevelsList);
  }, [rule]);

  useEffect(() => {
    if (startSimulation && rule) {
      simulate();
      setStartSimulation(false);
    }
  }, [startSimulation, rule]);

  const handleClose = () => {
    reset();
    onCloseCreatePopup();
  };

  const thereIsNoError = (rule) => {
    let copyRule = cloneDeep(rule);
    let enabled = true;

    enabled = reduceRulesConditionsWith((enabled, condition: EditRuleExpression) => {
      if (
        condition.showError ||
        condition.showMetricError ||
        condition.showFormulaError ||
        condition.showFormulaMetricError ||
        condition.showFormulaTimeError ||
        condition.showTimeError ||
        condition.showOperatorError ||
        condition.showValueError
      ) {
        enabled = false;
      }
      return enabled;
    })(copyRule!.conditions, true);

    return enabled;
  };

  const showRunTime = (rule) => {
    return (
      rule?.frequency !== '1 hours' &&
      rule?.frequency !== '12 hours' &&
      rule?.frequency?.indexOf('minutes') === -1
    );
  };

  const showWeekDay = (rule) => {
    return rule?.frequency === '1 days';
  };

  const setNextRun = async (rule: Rule) => {
    const copyOfRule = cloneDeep(rule);
    if (validateFrequency(copyOfRule)) {
      try {
        if (copyOfRule?.daysOfWeek?.length === 1 && copyOfRule?.daysOfWeek[0] === -1) {
          copyOfRule.daysOfWeek = [0, 1, 2, 3, 4, 5, 6];
        }

        const { data } = await getNextRun(currentShopId, copyOfRule, shopTimezone);
        const nextRunAsShopTZ = moment(data.nextRun).tz(shopTimezone).format('LLL');

        const nextRunForClient = `${nextRunAsShopTZ} ${shopTimezoneAbbr}` || '';
        setRule((prevState) => {
          if (prevState) {
            return { ...prevState, nextRunForClient: nextRunForClient };
          }
        });
      } catch (err) {
        return '';
      }
    } else {
      return '';
    }
  };

  const validateFrequency = (copyRule: EditRule): boolean => {
    return copyRule?.frequency &&
      ((showRunTime(copyRule) && copyRule?.runAt) || !showRunTime(copyRule))
      ? true
      : false;
  };

  const checkRequiredFields = () => {
    const copyRule = cloneDeep(rule);
    if (copyRule) {
      const verifiedConditions = mapRulesConditionsWith((condition: EditRuleExpression) => {
        condition.showError = !condition.fact ? 'Required' : false;
        condition.showMetricError = !condition.path ? 'Required' : false;
        condition.showTimeError =
          !condition.isFilter && !condition.params?.unit ? 'Required' : false;
        condition.showOperatorError = !condition.operator ? 'Required' : false;
        if (
          typeof condition.value === 'object' &&
          (condition['value']['params']?.factor !== undefined || condition.formulaMode)
        ) {
          condition.showFormulaError = !condition.value?.fact ? 'Required' : false;
          condition.showFormulaMetricError = !condition.value?.path ? 'Required' : false;
          condition.showFormulaTimeError =
            !condition.value?.params?.unit && !condition.value?.path?.includes('Budget')
              ? 'Required'
              : false;
          condition.showValueError = !condition.value?.params?.factor ? 'Required' : false;
        } else {
          condition.showValueError =
            typeof condition.value !== 'number' && !condition.value ? 'Required' : false;
        }
        return condition;
      })(copyRule?.conditions);

      copyRule.conditions = verifiedConditions;
      setRule(copyRule);

      return thereIsNoError(copyRule);
    }
  };

  const validateAction = () => {
    let copyRule = cloneDeep(rule);
    let disabled = true;
    const action = copyRule?.event;

    if (action && action.type) {
      if (action.type === 'pause' || action.type === 'start') disabled = false;
      else if (action.params !== undefined && action.params.value) {
        disabled = false;
      }
    }
    return disabled;
  };

  const showActionWarning = (rule) => {
    const ruleEvent = Object.values(rulesActionDropdown).find(
      (option) => option.value === rule?.event?.type,
    );
    const ruleFrequency = Object.values(scheduleOptions).find(
      (option) => option.value === rule?.frequency,
    );
    const warningMsg = `Warning: The selected schedule can update facebook data multiple times a day (as often as every ${ruleFrequency?.value}).`;
    setShowWarning(ruleEvent?.showWarning && ruleFrequency?.showWarning ? warningMsg : '');
  };

  const handleSubmit = async (isDraft = true) => {
    if (!checkRequiredFields()) {
      return;
    }
    setDraft(isDraft);

    const ruleToSave: EditStrategy = editRule ? { ...editRule } : { ...defaultShopRules };
    const status = isDraft ? 'inactive' : 'active';
    ruleToSave.status = status;
    rule!.status = status;

    ruleToSave.version = { rules: rule ? [rule] : [] };
    ruleToSave.name = ruleToSave.name ? ruleToSave.name : rule?.name || '';
    ruleToSave.description = ruleToSave.description
      ? ruleToSave.description
      : rule?.description || '';

    saveRule(ruleToSave);
  };

  const handleCancel = async () => {
    setPendingSave(false);
    if (showError) {
      setShowError(false);
    } else {
      handleClose();
    }
  };

  const handleCancelSimulation = () => {
    setOpenSimulation(false);
    setSimulationMsg({ success: [], failed: 0 });
  };

  const simulate = async (simulateWithActions = true) => {
    if (!checkRequiredFields()) {
      return;
    }

    setSimulationLoading(true);
    setSimulationError('');
    setSimulationActions(simulateWithActions);
    const ruleSimulation = cloneDeep(rule);
    if (ruleSimulation) {
      const maxPeriod = getMaxPeriod(ruleSimulation.conditions);
      ruleSimulation.conditions = mapRulesConditionsWith((condition) =>
        mapConditionsForSave(condition, maxPeriod),
      )(ruleSimulation.conditions);
      try {
        const ruleResult = await simulateRule(currentShopId, ruleSimulation);
        const results: {
          success: string[];
          failed: number;
        } = {
          success: ruleResult?.success?.map((result) => result),
          failed: ruleResult?.failed,
        };
        setSimulationMsg(results);
        setOpenSimulation(true);
      } catch (e) {
        setSimulationError('There was an error running the rule, please verify it is correct');
      }
    } else {
      setSimulationError('There was an error running the rule, please verify it is correct');
    }

    setSimulationLoading(false);
  };

  const reset = useCallback(() => {
    setPendingSave(false);
    setPendingDelete(false);
    setRule(undefined);
  }, []);

  const addNewCondition = (groupIndex) => {
    let ruleCopy = cloneDeep(rule);
    ruleCopy?.conditions.all[groupIndex]?.any?.push(defaultConditions);
    setRule(ruleCopy);
  };

  const addNewGrup = useCallback(() => {
    const ruleCopy = cloneDeep(rule);
    ruleCopy?.conditions.all.push({ any: [defaultConditions] });
    setRule(ruleCopy);
  }, [rule]);

  const deleteCondition = (groupIndex, conditionIndex) => () => {
    let ruleCopy = cloneDeep(rule);
    const arr = ruleCopy?.conditions?.all.slice();
    if (arr?.[groupIndex]['any']?.length === 1) {
      ruleCopy?.conditions?.all.splice(groupIndex, 1);
    } else {
      ruleCopy?.conditions?.all[groupIndex].any?.splice(conditionIndex, 1);
    }
    setRule(ruleCopy);
  };

  const formatRule = (ruleToEdit: EditRule) => {
    const mappedConditions = mapRulesConditionsWith((condition) => mapConditionsForEdit(condition))(
      ruleToEdit.conditions,
    );
    ruleToEdit.conditions = mappedConditions as any;

    return ruleToEdit;
  };

  const initRule = async () => {
    let ruleToEdit =
      editRule && editRule.versions && editRule.versions[0]
        ? editRule.versions[0].rules[0]
        : defaultShopRules.version!.rules[0];
    ruleToEdit = formatRule(ruleToEdit);

    if (ruleToEdit.nextRun && ruleToEdit.nextRun.indexOf('T') > -1) {
      ruleToEdit.nextRunForClient = `${moment(ruleToEdit.nextRun)
        .tz(shopTimezone)
        .format('LLL')} ${shopTimezoneAbbr}`;
      setRule({ ...ruleToEdit });
    } else {
      setRule({ ...ruleToEdit });
      await setNextRun({ ...ruleToEdit });
    }

    showActionWarning({ ...ruleToEdit });
  };

  const deleteRule = () => {
    let copyRule = cloneDeep(rule);
    if (copyRule) {
      copyRule.isDeleted = true;
      copyRule.status = 'inactive';
      let ruleToSave = { ...editRule };
      ruleToSave.version = { rules: [copyRule] };
      saveRule(ruleToSave);
    }
  };

  const duplicateRule = () => {
    let copyRule = cloneDeep(rule);
    if (copyRule) {
      copyRule.name = `${copyRule.name} - copy`;
      delete copyRule._id;
      let ruleToSave = { ...defaultShopRules };
      ruleToSave.name = copyRule.name;
      ruleToSave.version = { rules: [copyRule] };
      saveRule(ruleToSave);
    }
  };

  const hasConditionFilters = () => {
    const hasFilters = rule?.conditions.all.find((condition) => {
      const filterConditions = condition.any?.find((condStatement) => condStatement.isFilter);
      return filterConditions !== undefined;
    });

    return !!hasFilters;
  };

  const getFirstFilterIndex = () => {
    const filterIndex = rule?.conditions.all.findIndex((condition) => {
      const filterConditions = condition.any?.find((condStatement) => condStatement.isFilter);
      return filterConditions !== undefined;
    });

    return filterIndex || 0;
  };

  const addFilter = () => {
    let ruleCopy = cloneDeep(rule);
    let defaultFilter = { ...defaultConditions };
    ruleCopy?.conditions.all.push({
      any: [{ ...defaultFilter, isFilter: true }],
      priority: PRELIMINARY_PRIORITY,
      hidden: true,
    });

    setRule(ruleCopy);
  };

  const formatSimulationMsg = (msg) => {
    ruleOperator.forEach((op) => {
      msg = msg.replaceAll(`${op.value} `, `${op.text} `);
    });

    msg = msg.replaceAll('undefined', '');

    return msg;
  };

  const simulationMsgLength = simulationMsg.success.length + simulationMsg.failed;
  const simulationSuccessMsgLength = simulationMsg.success.length;
  const simulationExamples = Math.min(simulationSuccessMsgLength, 3);
  const simulationExamplesMessage =
    simulationExamples === 1 ? 'is 1 example' : `are ${simulationExamples} examples`;
  const filterGroupIndex = getFirstFilterIndex();

  return (
    <Modal
      large
      open={true}
      onClose={handleClose}
      title={
        <>
          {/* <div className='h-12'></div> */}
          <div className="flex-container items-baseline font-bold">
            {editRule && editRule.versions && editRule.versions[0]
              ? editRule.versions[0].rules[0]?.name
              : 'Create New Rule'}
            {editRule && editRule.versions && editRule.versions[0] && (
              <div className="ml-auto mr-2 flex-container">
                <div className="pr-6">
                  <Tooltip content="Delete rule">
                    <Button
                      plain
                      onClick={() => deleteRule()}
                      icon={DeleteMajor}
                      textAlign="left"
                    />
                  </Tooltip>
                </div>
                <Tooltip content="Duplicate rule">
                  <Button plain onClick={() => duplicateRule()} icon={DuplicateMinor} />
                </Tooltip>
              </div>
            )}
          </div>
        </>
      }
      footer={
        <div>
          {showError && <div className="text-red-600"> {errorSave} </div>}
          {simulationError && <div className="text-red-600"> {simulationError} </div>}
          <div>
            <Stack distribution="equalSpacing" spacing="tight" alignment="center">
              <Stack.Item>
                <Button id="simulate" loading={simulationLoading} onClick={() => simulate(false)}>
                  Simulate
                </Button>
              </Stack.Item>
              <Stack.Item>
                <ButtonGroup spacing="tight">
                  <Button id="cancel" loading={pendingDelete} onClick={handleCancel}>
                    Cancel
                  </Button>
                  <Button
                    id="save-rule-draft"
                    loading={draft && pendingSave}
                    disabled={
                      rule
                        ? !!rule.showNameError ||
                          !rule.name ||
                          !rule.level ||
                          !validateFrequency(rule) ||
                          !thereIsNoError(rule) ||
                          validateAction()
                        : true
                    }
                    destructive={false}
                    onClick={handleSubmit}
                  >
                    Save as draft
                  </Button>
                  <ConditionalWrapper
                    condition={!!unableLaunch}
                    wrapper={(children) => (
                      <Tooltip content={unableLaunchMessage}>{children}</Tooltip>
                    )}
                  >
                    <Button
                      id="save-rule"
                      destructive={false}
                      disabled={
                        (rule
                          ? !!rule.showNameError ||
                            !rule.name ||
                            !rule.level ||
                            !validateFrequency(rule) ||
                            !thereIsNoError(rule) ||
                            validateAction()
                          : true) || unableLaunch
                      }
                      loading={simulationLoading}
                      onClick={() => simulate()}
                    >
                      {simulationLoading ? 'Running simulation' : 'Simulate and Launch'}
                    </Button>
                  </ConditionalWrapper>
                </ButtonGroup>
              </Stack.Item>
            </Stack>
          </div>
        </div>
      }
    >
      <Modal.Section>
        {openSimulation && (
          <Modal
            open={true}
            onClose={() => setOpenSimulation(false)}
            title={
              <>
                <div className="flex-container items-baseline font-semibold">Rule Simulation</div>
                <div className="flex-container items-baseline smaller" id="simulation-subheader">
                  Please review the simulation below, prior to launching this rule
                </div>
              </>
            }
            primaryAction={
              simulationActions
                ? {
                    onAction: () => handleSubmit(false),
                    content: 'Launch',
                    disabled: rule
                      ? !rule.name ||
                        !rule.level ||
                        !validateFrequency(rule) ||
                        !thereIsNoError(rule) ||
                        validateAction()
                      : true,
                    loading: !draft && pendingSave,
                    destructive: false,
                    id: 'save-rule-sim',
                  }
                : undefined
            }
            secondaryActions={
              simulationActions
                ? [
                    {
                      onAction: handleCancelSimulation,
                      content: 'Cancel',
                      loading: pendingDelete,
                      id: 'cancel-sim',
                    },
                  ]
                : []
            }
          >
            <Modal.Section>
              <h2 className=" font-semibold mb-2">
                We run a rule simulation on {simulationMsgLength} {rule?.level}
                {simulationMsgLength > 1 ? 's' : ''}.
              </h2>
              <p style={{ paddingBottom: '20px' }}>
                {simulationSuccessMsgLength === 0
                  ? 'Currently, none of them meet the rule conditions. Once launched, we will automatically check the filters and conditions of this rule and make your desired changes if they are met.'
                  : `Currently, ${simulationSuccessMsgLength} of them meet the rule conditions. Here ${simulationExamplesMessage} of ${rule?.level}${
                      simulationSuccessMsgLength > 1 ? 's' : ''
                    } that will be changed by this rule.`}
              </p>
              <Stack>
                {simulationSuccessMsgLength &&
                  simulationMsg.success.slice(0, 3).map((msg: string) => (
                    <ul className="simulation-list-item">
                      <li>{formatSimulationMsg(msg)}</li>
                    </ul>
                  ))}
              </Stack>
            </Modal.Section>
          </Modal>
        )}
        <FormLayout>
          <RuleHeader
            rule={rule}
            setRule={setRule}
            isTemplate={isTemplate}
            setIsTemplate={setIsTemplate}
            formatRule={(rule) => formatRule(rule)}
            showRunTime={(copyRule) => showRunTime(copyRule)}
            showWeekDay={(ruletoShowWeek) => showWeekDay(ruletoShowWeek)}
            showActionWarning={showActionWarning}
            setNextRun={(ruleToTime) => setNextRun(ruleToTime)}
            existingStrategies={existingStrategies?.filter((s) => s?._id !== editRule?._id)}
          />

          <Stack>
            <Stack.Item fill>
              <div
                style={{
                  backgroundColor: 'rgba(24, 119, 242, 0.1)',
                  padding: '15px',
                  borderRadius: '0.8rem',
                  boxShadow: '0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15)',
                  outline: '0.1rem solid transparent',
                }}
                className="mb-5"
              >
                {hasConditionFilters() && (
                  <h3 className="font-semibold mb-3" style={{ color: '#707075' }}>
                    FILTERS
                  </h3>
                )}
                <Stack>
                  {rule?.conditions.all.map((group, groupIndex) => (
                    <>
                      {group.any?.map(
                        (condition, conditionIndex) =>
                          condition.isFilter && (
                            <>
                              {filterGroupIndex < groupIndex ? (
                                <div className="pb-6 ml-4">
                                  <span>AND</span>
                                </div>
                              ) : (
                                <></>
                              )}
                              <Stack.Item>
                                <FilterCondition
                                  disabled={false}
                                  rule={rule}
                                  setRule={setRule}
                                  conditionsLevelsList={conditionsLevelsList}
                                  condition={condition}
                                  conditionIndex={conditionIndex}
                                  groupIndex={groupIndex}
                                  deleteCondition={deleteCondition(groupIndex, conditionIndex)}
                                />
                              </Stack.Item>
                            </>
                          ),
                      )}
                    </>
                  ))}
                </Stack>
                <div className="mt-8">
                  <Stack alignment="center">
                    <Link onClick={addFilter} removeUnderline={true}>
                      <div className="flex">
                        <Icon source={PlusMinor} color="interactive" />
                        <span id="filter-button">Add filter</span>
                      </div>
                    </Link>
                  </Stack>
                </div>
              </div>
            </Stack.Item>
          </Stack>

          <Stack alignment="center" distribution="equalSpacing">
            <p className="font-semibold">IF THE FOLLOWING IS TRUE:</p>
          </Stack>

          <Stack>
            <Stack.Item fill>
              {rule?.conditions?.all.length &&
                rule?.conditions.all.map(
                  (group, groupIndex) =>
                    !group.any?.find((condStatement) => condStatement.isFilter) && (
                      <>
                        {getConditionsLength(rule?.conditions) > 1 && groupIndex > 0 && (
                          <div className="pt-6 pb-6 ml-4">
                            <span>AND</span>
                          </div>
                        )}
                        <Card>
                          <Card.Section>
                            <Stack>
                              {group.any?.map(
                                (condition, conditionIndex) =>
                                  !condition.isFilter && (
                                    <RuleCondition
                                      disabled={false}
                                      rule={rule}
                                      setRule={setRule}
                                      conditionsLevelsList={conditionsLevelsList}
                                      condition={condition}
                                      conditionIndex={conditionIndex}
                                      groupIndex={groupIndex}
                                      deleteCondition={deleteCondition(groupIndex, conditionIndex)}
                                    ></RuleCondition>
                                  ),
                              )}
                            </Stack>
                          </Card.Section>
                          <Card.Section>
                            <Stack alignment="center">
                              <Button
                                icon={PlusMinor}
                                onClick={() => {
                                  addNewCondition(groupIndex);
                                }}
                              >
                                OR
                              </Button>
                            </Stack>
                          </Card.Section>
                        </Card>
                      </>
                    ),
                )}
            </Stack.Item>
          </Stack>
          <Tooltip content="Add New rule">
            <Button icon={PlusMinor} onClick={addNewGrup}>
              AND
            </Button>
          </Tooltip>
          {!!rule && (
            <RuleAction
              disabled={false}
              rule={rule}
              setRule={setRule}
              showActionWarning={showActionWarning}
              showWarning={showWarning}
            />
          )}
        </FormLayout>
      </Modal.Section>
    </Modal>
  );
};
