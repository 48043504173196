import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import _db from 'utils/DB';
import { RootState } from 'reducers/RootType';
import { WillyFieldElement, WillyWidgetElement } from '../types/willyTypes';
import { messageTypes } from '../constants';
import { toast } from 'react-toastify';
import { useWindowSize } from 'utils/useWindowSize';
import { isMobileApp } from 'utils/Device';
import { useDashHistory } from './useDashHistory';
import ReactGridLayout from 'react-grid-layout';
import { useDashContext } from '../dashContext';
import { updateMainElement } from '../utils/willyUtils';
import { useStoreValue, useWritableStore } from '@tw/snipestate';

// trigger resize event -> trigger RGL
const triggerLayout = () => window.dispatchEvent(new Event('resize'));

export const useDashLayout = () => {
  const {
    $dashboard,
    $dashboardId,
    $loading,
    $fields,
    $widgets,
    $layout,
    $editLayout,
    $loadingFields,
    $loadingWidgets,
  } = useDashContext();
  const dashboard = useStoreValue($dashboard);
  const dashboardId = useStoreValue($dashboardId);
  const loading = useStoreValue($loading);
  const loadingFields = useStoreValue($loadingFields);
  const loadingWidgets = useStoreValue($loadingWidgets);
  const fields = useStoreValue($fields);
  const widgets = useStoreValue($widgets);
  const [layout, setLayout] = useWritableStore($layout);
  const [editLayout, setEditLayout] = useWritableStore($editLayout);

  const { updateHistory } = useDashHistory();
  const windowSize = useWindowSize();

  const userEmail = useSelector((state: RootState) => state.userEmail);
  const summaryFullWidth = useSelector((state: RootState) => state.summaryFullWidth);

  const tryToSetEditLayout = useCallback(
    (edit: boolean) => {
      if (!dashboardId) return;
      if (edit) {
        if (!dashboard?.editing) {
          setEditLayout(true);
        } else if (dashboard?.editor === userEmail) {
          setEditLayout(true);
        } else {
          toast.error(`This report is currently being edited by ${dashboard?.editor}`);
        }
      } else {
        setEditLayout(false);
      }

      if (dashboard?.editor === userEmail) {
        if (edit) {
          updateMainElement(dashboard, { editing: true, editor: userEmail });
          updateHistory('layout');
        } else {
          updateMainElement(dashboard, { editing: false, editor: undefined });
        }
      }
    },
    [dashboardId, dashboard, userEmail, setEditLayout, updateHistory],
  );

  const fieldLayout = useCallback(
    (field: WillyFieldElement): { 'data-grid': ReactGridLayout.Layout & { key?: string } } => {
      const currField = fields?.find((f) => f.id === field.id);
      const currLayout = layout.length > 0 && layout?.find((l) => l.i === field.id);
      const currIndex = fields?.findIndex((f) => f.id === field.id);

      if (currField && currLayout)
        return {
          'data-grid': { ...currLayout },
        };

      return {
        'data-grid': {
          i: field.id,
          key: field.id,
          x: currIndex % 2 ? 6 : 0,
          y: -1,
          w: 6,
          maxW: 12,
          minW: 3,
          h: 17,
          minH: 4,
        },
      };
    },
    [fields, layout],
  );

  const updateLayout = useCallback(
    async (sentLayout: ReactGridLayout.Layout[]) => {
      if (
        !loading &&
        !loadingFields &&
        !loadingWidgets &&
        dashboardId &&
        editLayout &&
        sentLayout &&
        !windowSize.isSmall &&
        !isMobileApp &&
        sentLayout.length > 0
      ) {
        await updateMainElement(dashboard!, { layout: JSON.stringify(sentLayout) });
      }
    },
    [
      loading,
      loadingFields,
      loadingWidgets,
      dashboardId,
      editLayout,
      windowSize.isSmall,
      dashboard,
    ],
  );

  const widgetLayout = useCallback(
    (
      widget: WillyWidgetElement,
      i: number,
    ): { 'data-grid': ReactGridLayout.Layout & { key?: string } } => {
      const currWidget = widgets.find((w) => w.queryId === widget.queryId);
      const currLayout = layout.length > 0 && layout?.find((l) => l.i === widget.queryId);
      if (currWidget && currLayout) {
        return {
          'data-grid': { ...currLayout },
        };
      }

      const minH = currWidget?.title?.includes('Untitled Section') // brittle, but it works
        ? 32
        : messageTypes[widget.type]?.widgetMinHeight || 6;
      const minW = messageTypes[widget.type]?.widgetMinWidth || 4;
      const h = minH || 17;

      return {
        'data-grid': {
          i: widget.queryId,
          key: widget.queryId,
          x: (i % 2 ? 2 : 0) * (summaryFullWidth ? 6 : 1),
          y: i * 4 * (summaryFullWidth ? 0.5 : 1),
          w: 12,
          maxW: 12,
          minW,
          h: h,
          minH,
        },
      };
    },
    [layout, summaryFullWidth, widgets],
  );

  useEffect(() => {
    triggerLayout();
  }, [summaryFullWidth, loading, loadingFields, loadingWidgets]);

  return {
    layout,
    setLayout,
    editLayout,
    tryToSetEditLayout,
    fieldLayout,
    updateLayout,
    widgetLayout,
    triggerLayout,
  };
};

export default useDashLayout;
