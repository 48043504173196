import { useMemo, useCallback } from 'react';
import { shuffle } from 'lodash';
import { Text } from '@tw/ui-components';
import { useSuggestions, Suggestion } from './hooks/useSuggestions';
import { WillySuggestQuestion } from './WillySuggestQuestions';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';

interface WillyScrollQuestionsLPProps {
  onSuggestionClick: (suggestion?: string) => void;
}

const CHUNK_SIZE = 3;

function* chunker(arr, n) {
  for (let i = 0; i < arr.length; i += n) {
    yield arr.slice(i, i + n);
  }
}

export const WillyScrollQuestionsLP: React.FC<WillyScrollQuestionsLPProps> = ({
  onSuggestionClick,
}) => {
  const questionSuggestions = useSuggestions({ question: '' });
  const { suggestions } = questionSuggestions;
  const isSmall = useIsSmall();

  const chunkedSuggestions = useMemo(() => {
    return [...chunker(suggestions, suggestions.length / CHUNK_SIZE)].map((sug) =>
      shuffle(sug),
    ) as Suggestion[][];
  }, [suggestions]);

  const calculatedDuration = useCallback((s) => {
    return s.length * CHUNK_SIZE * 2;
  }, []);

  return (
    <>
      <div className="w-full text-center m-auto px-4">
        <Text size={isSmall ? '30' : '40'} fw="normal" p={isSmall ? 'xs' : 'sm'} lh="1">
          Meet Moby – Your Data-Driven Assistant
        </Text>
        <div className="max-w-[85%] m-auto text-center">
          <Text size={isSmall ? 'xs' : 'sm'} p={isSmall ? 'xs' : 'sm'}>
            Get real-time insights from your store's data to make better decisions effortlessly.
          </Text>
        </div>
      </div>
      {!isSmall && (
        <div className="grid gap-4 overflow-hidden">
          {chunkedSuggestions.map((sug, i) => (
            <div className="looper w-full overflow-hidden flex gap-4" key={`${i}-sug-group`}>
              {Array.from(new Array(CHUNK_SIZE)).map((_, j) => (
                <div
                  className={`looper-carousel flex gap-4 w-[max-content] ${i % 2 ? 'reverse' : ''}`}
                  style={{
                    animationDuration: `${calculatedDuration(suggestions)}s`,
                  }}
                  key={`${i}-sug-group-${j}`}
                >
                  {sug.map((s, x) => (
                    <WillySuggestQuestion
                      suggestion={s}
                      onSuggestionClick={onSuggestionClick}
                      isCarousel
                      isReverse={i % 2 ? true : false}
                      key={`${i}-sug-group-${j}-${x}`}
                    />
                  ))}
                </div>
              ))}
            </div>
          ))}
        </div>
      )}
    </>
  );
};
