import { $currentShopId } from '$stores/$shop';
import { $userId } from '$stores/$user';
import { useStoreValue } from '@tw/snipestate';
import { userFilterOptions } from 'components/Willy/constants';
import { Conversation } from 'components/Willy/types/willyTypes';
import { useEffect, useState } from 'react';
import _db, { toArray } from 'utils/DB';

export function useConversationsStore({
  userFilter,
}: {
  userFilter: (typeof userFilterOptions)[number]['value'];
}) {
  const [state, setState] = useState({
    conversations: [] as Conversation[],
    loading: true,
    error: false,
  });
  const userId = useStoreValue($userId);
  const shopId = useStoreValue($currentShopId);

  useEffect(() => {
    if (!userId || !shopId) {
      return;
    }

    setState((old) => {
      return {
        ...old,
        loading: true,
      };
    });

    let query = _db(shopId)
      .collection('conversations')
      .where('v', 'in', [6])
      .where('source', 'in', ['chat'])
      .orderBy('createdAt', 'desc')
      .limit(20);

    if (userFilter === 'user') {
      query = query.where('user', '==', userId);
    }

    const unSub = query.onSnapshot(
      (snapshot) => {
        const changes = snapshot.docChanges();

        const conversationsArray = toArray<Conversation>(snapshot).filter(
          (x) => x.user !== 'fake_user' && !x.user.includes('SLACK_'),
        );

        setState((old) => {
          const conversations = conversationsArray
            .map((x) => {
              const change = changes.find((y) => y.doc.id === x.id);
              if (!change) {
                return x;
              } else if (change.type === 'modified') {
                return {
                  ...x,
                  ...change.doc.data(),
                  id: change.doc.id,
                };
              } else if (change.type === 'removed') {
                return null;
              } else {
                return x;
              }
            })
            .filter((x) => x !== null);

          return {
            loading: false,
            conversations: conversations,
            error: false,
          };
        });
      },
      (error) => {
        console.log('error', error);
        setState((old) => {
          return {
            ...old,
            error: true,
            loading: false,
          };
        });
      },
    );

    return () => unSub();
  }, [userId, userFilter, shopId]);

  return [state, setState] as const;
}
