import { Button, Combobox, Icon, Size, extractCSSColor, useCombobox } from '@tw/ui-components';
import { useDarkMode } from 'dark-mode-control';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { SummarySection } from 'types/SummarySection';
import { cx } from 'utils/cx';
import { $activeSummarySection } from './stores';
import { SummarySectionSelectItem } from './SummarySectionSelectItem';
import classes from './SummarySectionPicker.module.scss';
import { useWritableStore } from '@tw/snipestate';

export function SummarySectionPicker({
  sections,
  buttonSize,
}: {
  sections: SummarySection[];
  buttonSize?: Exclude<Size, 0>;
}) {
  const [search, setSearch] = useState('');
  const darkMode = useDarkMode();
  const [activeSection, setActiveSection] = useWritableStore($activeSummarySection);

  const combobox = useCombobox({
    onDropdownOpen() {
      combobox.focusSearchInput();
    },
    onDropdownClose: () => {
      combobox.resetSelectedOption();
      combobox.focusTarget();
      setSearch('');
    },
  });

  const selectData = useMemo(
    () =>
      sections
        .filter((s) => s.title.toLowerCase().includes(search))
        .map((section) => {
          const isWilly = section.services?.includes('willy');
          return {
            icon: () => (
              <>
                {(section.icons || []).map((I, i) => (
                  <Fragment key={`section_icon_${i}`}>
                    {isWilly && <I style={{ height: '20px', width: '20px' }} key={i} />}
                    {!isWilly && <I key={i} />}
                  </Fragment>
                ))}
              </>
            ),
            label: section.title,
            value: section.id,
          };
        }),
    [search, sections],
  );

  const selectedOption = useMemo(
    () => selectData.find((item) => item.value === activeSection),
    [selectData, activeSection],
  );

  const comboboxOptions = useMemo(
    () =>
      selectData.map((item) => (
        <Combobox.Option
          value={item.value}
          key={item.value}
          className={cx(
            'data-[combobox-selected=true]:!bg-[var(--mantine-color-named2-3)]',
            ':hover:!bg-[var(--mantine-color-named2-3)]',
            classes.selectItem,
          )}
        >
          <SummarySectionSelectItem {...item} />
        </Combobox.Option>
      )),
    [selectData],
  );

  // Purely for first render, since it's too complicated to initialize the active section store outside a component.
  useEffect(() => {
    setActiveSection(sections[0]?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setActiveSection]);

  return (
    <Combobox
      store={combobox}
      width={300}
      position="bottom-start"
      styles={{ options: { maxHeight: 400, overflowY: 'scroll' } }}
      onOptionSubmit={(id) => {
        const section = sections.find((section) => section.id === id);
        if (!section) return;

        setActiveSection(section.id);

        const sectionElement = document.querySelector(`.anchor_${section.id}`);
        if (sectionElement) sectionElement.scrollIntoView({ behavior: 'smooth' });

        combobox.closeDropdown();
      }}
    >
      <Combobox.Target>
        <div>
          <Button
            id="summary-section-picker"
            rightSection={<Icon name="caret-down" width={12} />}
            onClick={() => combobox.toggleDropdown()}
            variant="activator"
            size={buttonSize}
          >
            {selectedOption && <SummarySectionSelectItem {...selectedOption} />}
          </Button>
        </div>
      </Combobox.Target>

      <Combobox.Dropdown
        bg={darkMode ? 'gray.6' : 'white'}
        style={{ border: 'none', boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)' }}
      >
        <Combobox.Search
          classNames={{
            input: classes.search,
          }}
          styles={{
            input: {
              backgroundColor: extractCSSColor(darkMode ? 'gray.6' : 'white'),
              borderBottom: `0.5px solid ${extractCSSColor(darkMode ? 'gray.3' : 'gray.4')}`,
              fontWeight: 500,
            },
          }}
          value={search}
          onChange={(event) => setSearch(event.currentTarget.value)}
          placeholder="Search sections"
        />
        <Combobox.Options>{comboboxOptions}</Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
}
