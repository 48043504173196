import { Allotment } from 'allotment';

import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { userFilterOptions } from './constants';
import { WorkflowInMobyStatus } from './hooks/useChatSocket';
import { Conversation, WillyPrompt } from './types/willyTypes';

import { Message } from './types/willyTypes';
import { WillyPageWrapper } from './WillyPageWrapper';
import { ChatHistory } from './ChatHistory';
import { PromptLibrayPage } from './PromptLibraryPage';
import { chatActions, analyticsEvents } from 'utils/dataLayer/constants';
import { genericEventLogger } from 'utils/dataLayer';
import { WillyWorkflowPanel } from './WillyWorkflowPanel';
import { WillyBuilder } from './WillyBuilder';
import { WillySidePanel } from './WillySidePanel';
import { PromptTopic } from './WillyMainChat';

type WillyChatWrapperProps = {
  activeTab: string;
  children: React.ReactNode;
  asPage: boolean;
  buildMode?: boolean;
  workflowPanelOpen?: boolean;
  setWorkflowPanelOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  messages: Message[];
  conversationId: string;
  setBuildMode?: React.Dispatch<React.SetStateAction<boolean>>;
  clearConversation: () => void;
  currentAnalyticsEvent: string;
  conversations: Conversation[];
  setConversations: React.Dispatch<
    React.SetStateAction<{ conversations: Conversation[]; loading: boolean; error: boolean }>
  >;
  loading: boolean;
  error: boolean;
  setUserFilter: React.Dispatch<React.SetStateAction<(typeof userFilterOptions)[number]['value']>>;
  userFilter: (typeof userFilterOptions)[number]['value'];
  setValue: React.Dispatch<React.SetStateAction<string>>;
  workflowInMobyStatus: WorkflowInMobyStatus;
  workflowIdToRun?: string;
  runWorkflowOnInit?: boolean;
  runWorkflowIfPossible: boolean;
  setRunWorkflowIfPossible: React.Dispatch<React.SetStateAction<boolean>>;
};
export const WillyMainChatWrapper: React.FC<WillyChatWrapperProps> = ({
  activeTab,
  children,
  asPage,
  buildMode,
  setBuildMode,
  runWorkflowIfPossible,
  setRunWorkflowIfPossible,
  workflowPanelOpen,
  workflowIdToRun,
  messages,
  clearConversation,
  conversationId,
  currentAnalyticsEvent,
  conversations,
  setConversations,
  loading,
  error,
  userFilter,
  workflowInMobyStatus,
  runWorkflowOnInit,
  setUserFilter,
  setValue,
}) => {
  const navigate = useNavigate();
  const { search } = useLocation();

  return (
    <>
      {asPage ? (
        <WillyPageWrapper
          className="willy-page"
          showResizer={true}
          mainContent={
            <Allotment className="sm:h-dvh sm:max-h-dvh z-0 bg-white">
              <Allotment.Pane>
                <div className="m-auto py-12 h-full">
                  {activeTab === 'history' && (
                    <ChatHistory
                      conversations={conversations}
                      setConversations={setConversations}
                      loading={loading}
                      error={error}
                      userFilter={userFilter}
                    />
                  )}
                  {activeTab === 'chat' && children}
                  {activeTab === 'prompt_library' && (
                    <PromptLibrayPage
                      // promptTopics={promptTopics}
                      // promptsList={formattedSamplePrompts}
                      // customizedPrompts={customizedPrompts}
                      // reportPrompts={reportPrompts}
                      onSelectPrompt={(prompt) => {
                        genericEventLogger(analyticsEvents.CHAT, {
                          action: chatActions.CHOOSE_PROMPT_LIBRARY_PROMPT,
                          prompt,
                        });
                        const params = new URLSearchParams(search);
                        params.delete('tab');
                        params.set('prompt', prompt.prompt);
                        navigate({
                          pathname: window.location.pathname,
                          search: params.toString(),
                          hash: '',
                        });
                      }}
                      // loadMoreReportPrompts={loadMoreReportPrompts}
                      // totalReportPrompts={totalReportPrompts}
                    />
                  )}
                </div>
              </Allotment.Pane>
              {buildMode && <WillyBuilder messages={messages} conversationId={conversationId} />}
              {workflowPanelOpen && (
                <WillyWorkflowPanel
                  workflowIdToRun={workflowIdToRun}
                  workflowInMobyStatus={workflowInMobyStatus}
                  conversationId={conversationId}
                  runOnInit={runWorkflowOnInit}
                />
              )}
            </Allotment>
          }
          hideSidePanel={false}
          sidePanelContent={
            <WillySidePanel
              activeTab={activeTab}
              buildMode={buildMode}
              setBuildMode={setBuildMode}
              clearConversation={clearConversation}
              conversationId={conversationId}
              currentAnalyticsEvent={currentAnalyticsEvent}
              runWorkflowIfPossible={runWorkflowIfPossible}
              setRunWorkflowIfPossible={setRunWorkflowIfPossible}
            />
          }
        />
      ) : (
        <>{children}</>
      )}
    </>
  );
};
