import { $dashboardCredits } from '$stores/willy/$credits';
import { $isFreeShop } from '$stores/willy/$subscription';
import { useStoreValue } from '@tw/snipestate';
import { Button, Flex, FormattedColor, Icon, Progress, Text } from '@tw/ui-components';
import { useUpgradeButton } from 'components/UpgradePlan/components/useUpgradeButton';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

export const DashboardCreditsIndicator: FC = () => {
  const { credits, creditsUsed } = useStoreValue($dashboardCredits);
  const { buttonText, action } = useUpgradeButton('template_library');

  return (
    <Flex
      bg={'#F5F9FF' as FormattedColor}
      borderRadius={'10px'}
      p="sm"
      mt="lg"
      direction="column"
      gap="sm"
    >
      {credits === 0 ? (
        <>
          <Text color="one.7" size="sm" weight={500} align="center">
            Want to speed up your reporting? Upgrade to use templates.
          </Text>
          <Button onClick={action}>{buttonText}</Button>
        </>
      ) : (
        <>
          <Text color="gray.9" weight={500} size="sm">
            Report Credits
          </Text>
          {credits === Infinity ? (
            <Flex gap="xs" align="center">
              <Icon name="infinity" size={25} color="gray.4" />
              <Text color="gray.5" size="sm">
                Unlimited
              </Text>
            </Flex>
          ) : (
            <>
              <Progress value={(creditsUsed / credits) * 100} size="sm" color="one.6" />
              <Text color="gray.5" size="sm">{`${creditsUsed}/${credits} credits used`}</Text>
            </>
          )}
        </>
      )}
    </Flex>
  );
};
