import { $store } from '@tw/snipestate';

export const $customizePromptsPopup = $store<{ opened: boolean }>({
  opened: false,
});

export const openCustomizePromptsPopup = () => {
  $customizePromptsPopup.set({ opened: true });
};

export const closeCustomizePromptsPopup = () => {
  $customizePromptsPopup.set({ opened: false });
};
