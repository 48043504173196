import { $activeRoute, $navigationSections } from '$stores/nav-config-stores';
import { useComputedValue, useStoreValue } from '@tw/snipestate';
import { Flex, NavSectionLink, Size, Text } from '@tw/ui-components';
import { useFilterMenu } from '../../../hooks/useFilterMenu';
import { $navOpen } from 'components/Nav/stores';
import { NavSectionRoute } from 'constants/routes/types';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

export const LinksSection = () => {
  const navigationSections = useStoreValue($navigationSections);
  const settingsSections = useFilterMenu(navigationSections, 'settings');

  const mainLinks = useMemo(() => {
    const map = settingsSections
      .flatMap((x) => x.routes)
      .reduce((map, route) => {
        const group = route.uiGroupName || 'Misc';
        const existing = map.get(group);
        if (!existing) map.set(group, [route]);
        else existing.push(route);

        return map;
      }, new Map<string, NavSectionRoute[]>());

    const linkSections: { section: string; routes: NavSectionRoute[] }[] = [];

    for (const [section, routes] of map) {
      linkSections.push({ section, routes });
    }

    return linkSections.map(({ section, routes }, i) => (
      <div key={`settings-nav-section-${section}-${i}`}>
        <Text fz="xs" c="gray.5" tt="uppercase" mb={5 as unknown as Size}>
          {section}
        </Text>
        {routes.map((route, i) => (
          <WrappedLink key={`nav-section-link-${route.label}-${i}`} route={route} />
        ))}
      </div>
    ));
  }, [settingsSections]);

  return (
    <Flex direction="column" gap="md">
      {mainLinks}
    </Flex>
  );
};

const WrappedLink = ({ route }: { route: NavSectionRoute }) => {
  const navigate = useNavigate();
  const isActive = useComputedValue($activeRoute, (r) => r.activeRoute === route);

  return (
    <NavSectionLink
      label={route.label}
      isActive={isActive}
      onClick={() => {
        navigate(route.url);
        $navOpen.set(false);
      }}
      style={{ pl: 'xs', pb: 5 as unknown as Size }}
    />
  );
};
