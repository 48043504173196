import { $observer } from '@tw/snipestate';
import axiosInstance from 'utils/axiosInstance';
import { $currentShopId } from '$stores/$shop';
import { $userId } from '$stores/$user';

interface ShopUser {
  id: string;
  display: string;
}

interface ShopUsers {
  loading: boolean;
  users: ShopUser[];
}

export const $shopUsers = $observer({ loading: true, users: [] } as ShopUsers, async (get, set) => {
  const shopId = get($currentShopId);
  const userId = get($userId);
  const data = get().users;
  if (!shopId || !userId || data.length > 0) return;

  try {
    const { data } = await axiosInstance.get(
      `/v2/account-manager/shops/users/${shopId}?noFilter=true`,
    );

    set({
      loading: false,
      users: data?.map((x) => ({
        ...x,
        id: x.id,
        display: x.name,
      })),
    });
  } catch (error) {
    console.error('Error fetching shop users:', error);
    set({ loading: false, users: [] });
  }
});
