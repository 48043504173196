export const WILLY_NAV_ITEMS: {
  id: string;
  label: string;
  link: string;
  content: React.ReactElement;
  customAction?: () => void;
}[] = [
  {
    id: 'chat',
    label: 'Chat',
    link: '/chat',
    content: <div>Main Chat Page</div>,
  },
  {
    id: 'history',
    label: 'Chat History',
    link: '/chat?tab=history',
    content: <div>Chat History</div>,
  },
  {
    id: 'prompt_library',
    label: 'Prompt Library',
    link: '/chat?tab=prompt_library',
    content: <div>Prompt Library</div>,
  },
];
