import './styles.scss';
import { facebookConnectOnPress } from '../../../ducks/facebook';
import { Anchor, Box, Size, Text } from '@tw/ui-components';

interface AttributionBannerProps {}

const ReconnectBanner: React.FC<AttributionBannerProps> = () => {
  return (
    <Box
      ml="xs"
      py={5 as unknown as Size}
      px={10 as unknown as Size}
      borderRadius="5px"
      bg="named2.4"
    >
      <Text fz="xs">
        <span className="whitespace-nowrap">Facebook connection lost! </span>
      </Text>
      <Anchor fz="xs" as="button" onClick={facebookConnectOnPress} underline="never">
        Click here to reconnect
      </Anchor>
    </Box>
  );
};

export default ReconnectBanner;
