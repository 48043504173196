import { Granularity } from '@tw/types';

export enum ShopifySegmentType {
  SOURCES = 'sources',
  PAYMENT_GATEWAY_COSTS = 'payment_gateway_costs',
  CUSTOMER_TAGS = 'customer_tags',
  ORDER_TAGS = 'order_tags',
  TW = 'tw',
  SHIPPING_PROFILES = 'shipping_profiles',
}

export interface ShopifySegment {
  [ShopifySegmentType.PAYMENT_GATEWAY_COSTS]: ShopifySegmentItem[];
  [ShopifySegmentType.CUSTOMER_TAGS]: ShopifySegmentItem[];
  [ShopifySegmentType.ORDER_TAGS]: ShopifySegmentItem[];
  [ShopifySegmentType.SOURCES]: ShopifySegmentItemOrderTags[];
  [ShopifySegmentType.TW]: ShopifySegmentItem[];
  [ShopifySegmentType.SHIPPING_PROFILES]: ShopifySegmentItem[];
}

export interface ShopifySegmentItem {
  id: string;
  name: string;
  type: string;
}

export interface ShopifySegmentItemOrderTags {
  id: string;
  original: string;
  type: string;
}

export interface ShopifySegmentItemMatch {
  id: string;
  type: ShopifySegmentType;
}

export interface GetShopifyOrdersSegmentsStatsRequest {
  shopId: string;
  start: string;
  end: string;
  metricsBreakdown: boolean;
  useClickhouse: boolean;
  granularity: Granularity;
  match: ShopifySegmentItemMatch[];
}

export interface GetShopifyOrdersSegmentsStatsResponse {}
