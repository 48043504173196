import { $activeAppVersion, $activeRoute } from '$stores/nav-config-stores';
import {
  FREE_TRIAL_BANNER_HEIGHT,
  FREE_TRIAL_BANNER_HEIGHT_VAR,
  closeTrialBanner,
  $trialBannerPackage,
  $trialBannerMoby,
} from '$stores/willy/$upgradePopupManager';
import { useStoreValue } from '@tw/snipestate';
import { Button, Icon, Text } from '@tw/ui-components';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { SELECTED_PRODUCT_PARAM } from '../../UserProfileManagment/Shop/ChangePlan/constants';
import { MOBY_PRODUCT_ID } from '../../../$stores/willy/$subscription';

export function useShowFreeTrialBanner() {
  const isSmall = useIsSmall();
  const { show: showTrialBannerPackage } = useStoreValue($trialBannerPackage);
  const { show: showTrialBannerMoby } = useStoreValue($trialBannerMoby);
  const { activeRoute: r } = useStoreValue($activeRoute);
  const appVersion = useStoreValue($activeAppVersion);

  return !!(
    !isSmall &&
    (showTrialBannerPackage || showTrialBannerMoby) &&
    (r?.isWillyPage || r?.isHybridPage) &&
    !r?.isShoplessPage &&
    !r?.isSettingsPage &&
    appVersion === '3.0'
  );
}

export const FreeTrialBanner = () => {
  const navigate = useNavigate();
  const { show: showMoby, daysLeft: daysLeftMoby } = useStoreValue($trialBannerMoby);
  const {
    show: showPackage,
    daysLeft: daysLeftPackage,
    currentPlanName,
  } = useStoreValue($trialBannerPackage);
  const { activeRoute: r } = useStoreValue($activeRoute);
  const isMobyPage = !!r?.isMobyPage;
  const isMobyContext = isMobyPage && showMoby;
  if (!showMoby && !showPackage) return null;
  if (showMoby && !showPackage && !isMobyPage) return null;
  return (
    <div
      style={{ height: FREE_TRIAL_BANNER_HEIGHT + 'px' }}
      className="w-full bg-blue-600 z-[9999999] flex items-center px-[20px] justify-between"
    >
      <div className="flex items-center gap-[20px]">
        {!isMobyContext && (
          <Text color="white" fz={16} fw={500}>
            {daysLeftPackage == 0 ? (
              <span>
                {`Your ${currentPlanName ?? ''} free trial has ended. To get full access to all features upgrade`}
              </span>
            ) : (
              <span>
                Your {currentPlanName} free trial ends in <b>{daysLeftPackage} days.</b>
              </span>
            )}
          </Text>
        )}
        {isMobyContext && (
          <Text color="white" fz={16} fw={500}>
            {daysLeftMoby == 0 ? (
              <span>
                {`Your Moby free trial has ended. To get full access to all features upgrade`}
              </span>
            ) : (
              <span>
                Your Moby free trial ends in <b>{daysLeftMoby} days.</b>
              </span>
            )}
          </Text>
        )}
        <Button
          variant="activator"
          size="xs"
          onClick={() => {
            const params = new URLSearchParams(window.location.search);
            params.set(SELECTED_PRODUCT_PARAM, MOBY_PRODUCT_ID);
            navigate({ pathname: `/store-settings/plans`, search: `?${params.toString()}` });
          }}
        >
          Upgrade
        </Button>
      </div>
      <div
        className="cursor-pointer"
        onClick={closeTrialBanner(isMobyContext ? daysLeftMoby === 0 : daysLeftPackage === 0)}
      >
        <Icon name="close" color="white" size={14} />
      </div>
    </div>
  );
};

export function useComputeFreeTrialBannerHeight() {
  const showFreeTrialBanner = useShowFreeTrialBanner();

  useEffect(() => {
    document.documentElement.style.setProperty(
      FREE_TRIAL_BANNER_HEIGHT_VAR,
      (showFreeTrialBanner ? FREE_TRIAL_BANNER_HEIGHT : 0) + 'px',
    );
  }, [showFreeTrialBanner]);

  useEffect(() => {
    return () => {
      document.documentElement.style.setProperty(FREE_TRIAL_BANNER_HEIGHT_VAR, '0px');
    };
  }, []);
}
