import { Popover } from '@shopify/polaris';
import { Prism as ReactSyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { executeRce, parseCodeSnippet } from './utils/willyUtils';
import { CopyToClipboard } from './CopyToClipboard';
import { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  NlqFileCodeResponse,
  ChatSources,
  WillyDashboardElement,
  CodeExecutionResponse,
} from './types/willyTypes';
import { EditMajor, FileMinor } from '@shopify/polaris-icons';
import { Button, Modal, Tabs, Text } from '@tw/ui-components';
import { useLocation } from 'react-router';
import {
  analyticsEvents,
  sqwhaleActions,
  dashboardsActions,
  chatActions,
  genericEventLogger,
} from 'utils/dataLayer';
import { FreeQuery } from 'pages/FreeQuery/FreeQuery';

type WillyCodeSnippetProps = {
  code: string;
  output?: string;
  error?: string;
  files?: NlqFileCodeResponse[];
  show: boolean;
  codeInterpreterInputData?: string;
  dashboard?: WillyDashboardElement;
  conversationId?: string;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  codeResultChanged?: (v: CodeExecutionResponse) => Promise<void>;
  context: ChatSources;
};

export const WillyCodeSnippet: React.FC<WillyCodeSnippetProps> = ({
  code,
  output,
  error,
  files,
  codeInterpreterInputData,
  show,
  dashboard,
  conversationId,
  setShow,
  codeResultChanged,
  context,
}) => {
  const { search } = useLocation();

  const [activeTab, setActiveTab] = useState<'code' | 'data'>('code');
  const [queryInModal, setQueryInModal] = useState<string>();
  const [dataInModal, setDataInModal] = useState<string>();
  const [editingQuery, setEditingQuery] = useState(false);
  const [editQueryError, setEditQueryError] = useState<string>();

  const parsedCode = useMemo(() => {
    return parseCodeSnippet(code);
  }, [code]);

  const searchParams = useMemo(() => {
    const params = new URLSearchParams(search);
    if (parsedCode) {
      params.set('query', parsedCode);
      params.set('data', codeInterpreterInputData || '');
      params.set('language', 'python');
    }
    if (params.has('conversationId')) {
      params.delete('conversationId');
    }
    return params.toString();
  }, [parsedCode, search, codeInterpreterInputData]);

  const currentAnalyticsEvent = useMemo(() => {
    return context === 'chat' || context === 'sequence'
      ? analyticsEvents.CHAT
      : context === 'editor'
        ? analyticsEvents.SQWHALE
        : analyticsEvents.DASHBOARDS;
  }, [context]);

  const currentAnalyticsAction = useMemo(() => {
    return context === 'chat' || context === 'sequence'
      ? chatActions.OPEN_IN_SQWHALE_EDITOR
      : context === 'editor'
        ? sqwhaleActions.OPEN_IN_SQWHALE_EDITOR
        : dashboardsActions.OPEN_IN_SQWHALE_EDITOR;
  }, [context]);

  let trackOpenInEditor = useCallback(() => {
    genericEventLogger(currentAnalyticsEvent, { code, action: currentAnalyticsAction });
  }, [code, currentAnalyticsAction, currentAnalyticsEvent]);

  const updateQuery = useCallback((queryStr: string) => {
    if (!queryStr) {
      return;
    }

    setQueryInModal(queryStr);
  }, []);

  const updateData = useCallback((dataStr: string) => {
    if (!dataStr) {
      return;
    }

    setDataInModal(dataStr);
  }, []);

  return (
    <>
      <Popover active={show} activator={<div></div>} onClose={() => setShow(false)}>
        <Popover.Pane sectioned>
          <div className="flex flex-col -mt-6.5">
            <div className="prose dark:prose-invert">
              {!!error && (
                <div className="flex flex-col gap-2">
                  <div className="flex items-center gap-2 justify-between">
                    <Text fw="600" size="sm">
                      Last call got the following error:
                    </Text>
                    <CopyToClipboard
                      text={error}
                      context={conversationId ? 'chat' : dashboard ? 'dashboard' : 'sqwhale'}
                      dashboard={dashboard}
                    />
                  </div>
                  <ReactSyntaxHighlighter
                    language="python"
                    style={vscDarkPlus}
                    showLineNumbers
                    customStyle={{
                      maxHeight: '130px',
                    }}
                  >
                    {error}
                  </ReactSyntaxHighlighter>
                </div>
              )}
              <div className="flex items-center gap-2 justify-between sticky top-0 z-10 pt-6.5 bg-white">
                <Tabs
                  defaultValue={activeTab}
                  onChange={(v) => {
                    if (!v) {
                      return;
                    }
                    setActiveTab(v as 'code' | 'data');
                  }}
                >
                  <Tabs.List className="border-0">
                    <Tabs.Tab value="code">Code Snippet</Tabs.Tab>
                    <Tabs.Tab value="data">Data</Tabs.Tab>
                  </Tabs.List>
                </Tabs>
                <span className="ml-auto flex items-center gap-2">
                  {!dashboard && (
                    <Link
                      to={{
                        pathname: '/sql-editor',
                        search: searchParams,
                      }}
                      // @ts-ignore
                      reloadDocument={true}
                      target="_blank"
                      onClick={trackOpenInEditor}
                    >
                      <EditMajor className="w-8 flex items-center justify-center fill-primary dark:fill-gray-400" />
                    </Link>
                  )}
                  {!!dashboard && (
                    <div
                      className="cursor-pointer"
                      onClick={async () => {
                        setQueryInModal(parsedCode);
                        setDataInModal(codeInterpreterInputData);
                        setShow(false);
                      }}
                    >
                      <EditMajor className="w-8 flex items-center justify-center fill-primary dark:fill-gray-400" />
                    </div>
                  )}
                </span>
                <CopyToClipboard
                  text={activeTab === 'code' ? code : codeInterpreterInputData || 'no data'}
                  context={conversationId ? 'chat' : dashboard ? 'dashboard' : 'sqwhale'}
                />
              </div>
              {activeTab === 'code' && (
                <ReactSyntaxHighlighter language="python" style={vscDarkPlus} showLineNumbers>
                  {parsedCode}
                </ReactSyntaxHighlighter>
              )}
              {activeTab === 'data' && !!codeInterpreterInputData && (
                <div className="flex flex-col gap-1 mt-2">
                  <Text size="sm" color="gray.5">
                    The data used in the code snippet (max 30 rows):
                  </Text>
                  <ReactSyntaxHighlighter language="json" style={vscDarkPlus} showLineNumbers>
                    {codeInterpreterInputData}
                  </ReactSyntaxHighlighter>
                </div>
              )}
            </div>
          </div>
        </Popover.Pane>
        <Popover.Pane fixed sectioned>
          {!!output && (
            <div className="p-6.5 prose dark:prose-invert">
              <ReactSyntaxHighlighter language="python" style={vscDarkPlus} showLineNumbers>
                {output}
              </ReactSyntaxHighlighter>
            </div>
          )}
          {!!files && files.length > 0 && (
            <div className="flex flex-col gap-2">
              <Text fw="600" size="sm">
                Files generated:
              </Text>
              {files?.map((file) => (
                <div
                  key={file.name}
                  className="cursor-pointer flex items-center gap-1"
                  onClick={() => {
                    window.open(file.url, '_blank')?.focus();
                  }}
                >
                  <FileMinor className="w-10 h-10 fill-blue-600" />
                  <span className="text-blue-600 font-medium">{file.name}</span>
                  <span className="text-blue-600">({file.size} B)</span>
                </div>
              ))}
            </div>
          )}
        </Popover.Pane>
        {!!conversationId && (
          <Popover.Pane fixed>
            <Popover.Section>
              <div className="flex gap-2">
                <Link
                  to={{
                    pathname: '/chat',
                    search: `?conversationId=${conversationId}&${search}`,
                  }}
                  onClick={trackOpenInEditor}
                >
                  Go to containing conversation
                </Link>
              </div>
            </Popover.Section>
          </Popover.Pane>
        )}
      </Popover>
      <Modal.Root
        size="xl"
        centered
        fullScreen
        opened={!!queryInModal}
        onClose={() => setQueryInModal(undefined)}
      >
        <Modal.Overlay />
        <Modal.Content className="!flex flex-col w-full h-full">
          <Modal.Header>
            <Modal.Title>Edit Query</Modal.Title>
            <Modal.CloseButton onClick={() => setQueryInModal(undefined)} />
          </Modal.Header>
          <Modal.Body className="flex flex-auto">
            <div className="flex w-full">
              <div className="w-full flex flex-col flex-auto">
                <FreeQuery
                  initialQuery={queryInModal}
                  initialData={dataInModal}
                  queryChanged={updateQuery}
                  dataChanged={updateData}
                  language="python"
                />
                <div className="my-auto pt-4 w-full flex">
                  <div className="ml-auto">
                    <Button
                      // disabled={!query?.query}
                      loading={editingQuery}
                      variant="primary"
                      onClick={async () => {
                        if (!queryInModal) {
                          return;
                        }
                        try {
                          const abortSignal = new AbortController().signal;
                          setEditingQuery(true);
                          const codeRes = await executeRce(
                            abortSignal,
                            parseCodeSnippet(queryInModal),
                            dataInModal,
                          );
                          const { executionError } = codeRes;

                          if (!dashboard) {
                            setEditQueryError('Report id not found');
                            return;
                          }

                          if (executionError) {
                            setEditQueryError('Something went wrong');
                          } else {
                            await codeResultChanged?.(codeRes);
                            setQueryInModal(undefined);
                          }
                        } catch (e) {
                          setEditQueryError(e.message || 'Something went wrong');
                        } finally {
                          setEditingQuery(false);
                        }
                      }}
                    >
                      Update Code
                    </Button>
                    {editQueryError && (
                      <Text size="sm" color="red.5" lh={2}>
                        {editQueryError}
                      </Text>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
    </>
  );
};
