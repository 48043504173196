import { $currentShopId } from '$stores/$shop';
import { $userShop } from '$stores/$user';
import {
  $customizePromptsPopup,
  closeCustomizePromptsPopup,
} from '$stores/willy/$customizePromptPopup';
import { useComputedValue, useStoreValue } from '@tw/snipestate';
import { Anchor, Button, Icon, Modal, Text } from '@tw/ui-components';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';
import { useEffect, useState } from 'react';
import { userDb } from 'utils/DB';
import customizeStartImg from '../../images/customize_prompts_start.png';
import customizeEndImg from '../../images/customize_prompts_done.png';

export const CustomizeMobyPopup = () => {
  const { opened } = useStoreValue($customizePromptsPopup);
  const isSmall = useIsSmall();
  const currentShopId = useStoreValue($currentShopId);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [step, setStep] = useState<1 | 2 | 3 | 4>(1);
  const userShopCustomMobyTags = useComputedValue(
    $userShop,
    (shop) => shop?.customPromptTags ?? [],
  );
  const topicsMap = [
    { label: 'Metrics Forecast', cat: 'Forcast Metrics' },
    { label: 'Customer Insights', cat: 'Uncover Customer Insights' },
    { label: 'Patterns Identification', cat: 'Identify Patterns' },
    { label: 'Product Performance Analysis', cat: 'Product Performance' },
    { label: 'Industry Benchmarks', cat: 'Get Industry Benchmarks' },
    { label: 'Website Performance Metrics', cat: 'Analyze Website Metrics' },
    { label: 'Marketing Performance Analysis', cat: 'Marketing Performance' },
    { label: 'Channel Performance', cat: 'Enhance Channel Performance' },
    { label: 'Creative', cat: 'Creative Recommendation' },
    { label: 'Campaign Budget Allocation', cat: 'Campaign Budget Allocation' },
    { label: 'Knowledge Base Resources', cat: 'Learn Triple Whale' },
  ];

  useEffect(() => {
    setSelectedTags(userShopCustomMobyTags);
  }, [userShopCustomMobyTags]);

  const toggleTopic = (topic: string) => {
    if (selectedTags.includes(topic)) {
      setSelectedTags(selectedTags.filter((tag) => tag !== topic));
    } else {
      setSelectedTags([...selectedTags, topic]);
    }
  };

  const saveCustomTags = async () => {
    if (currentShopId) {
      await userDb().set(
        { shops: { [currentShopId]: { customPromptTags: selectedTags } } },
        { merge: true },
      );
    }
    closeCustomizePromptsPopup();
    setStep(1);
  };
  return (
    <Modal.Root
      opened={opened}
      onClose={() => {
        closeCustomizePromptsPopup();
        setStep(1);
      }}
      size={isSmall ? '100%' : '592px'}
    >
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Body>
          {step === 1 && (
            <div className="flex flex-col p-0 md:p-[32px] md:pb-[16px]  gap-12">
              <Text fz={26} fw={700}>
                Meet Moby✨, your AI-powered team member
              </Text>
              <div className="flex flex-col gap-2">
                <Icon name="ai-chat-spark" size={20} color="two.4" />
                <Text fz={18} fw={600}>
                  Ask Away
                </Text>
                <Text fz={16}>
                  From industry trends to company-specific queries, there’s no wrong question for
                  Moby.
                </Text>
              </div>
              <div className="flex flex-col gap-2">
                <Icon name="prompts-library" size={20} color="purple.4" />
                <Text fz={18} fw={600}>
                  Get started with the prompt library
                </Text>
                <Text fz={16}>
                  You don’t need to be a prompt engineer. Moby comes with a prompt library loaded
                  with powerful use cases.
                </Text>
              </div>
              <div className="flex flex-col gap-2">
                <Icon name="star-plus" size={20} color="teal.4" />
                <Text fz={18} fw={600}>
                  Continuous learning
                </Text>
                <Text fz={16}>
                  Moby not only helps you learn more about your data, it also continually learns
                  from interactions, thereby enhancing its capability to deliver relevant and timely
                  business intelligence.
                </Text>
              </div>
              <div className="flex w-full justify-end">
                <Button onClick={() => setStep(2)}>Get Started</Button>
              </div>
            </div>
          )}
          {step === 2 && (
            <div className="flex flex-col p-0 md:p-[32px] md:pb-[16px] gap-12">
              <div className="flex flex-col gap-2">
                <Text fz={26} fw={700}>
                  Customize Your Chat
                </Text>
                <Text>
                  Let's customize your chat to help us show you the most relevant content.
                </Text>
              </div>
              <Button onClick={() => setStep(3)}>Customize</Button>
              <img src={customizeStartImg} alt="Customize Moby" />
              <Anchor onClick={() => setStep(1)}>
                <span className="flex gap-4 items-center">
                  <Icon name="chevron-left-minor" color="one.5" size={12} />
                  <Text fw={500} color="one.5">
                    Back
                  </Text>
                </span>
              </Anchor>
            </div>
          )}
          {step === 3 && (
            <div className="flex flex-col p-0 md:p-[32px] md:pb-[16px]  gap-12">
              <Text fz={26} fw={700}>
                What type of insights would be most helpful for you?
              </Text>
              <div className="flex flex-wrap gap-4">
                {topicsMap.map((topic) => (
                  <Button
                    key={topic.label}
                    size="xs"
                    onClick={() => toggleTopic(topic.cat)}
                    variant={selectedTags.includes(topic.cat) ? 'primary' : 'white'}
                  >
                    {topic.label}
                  </Button>
                ))}
              </div>
              <div className="flex justify-between pt-10">
                <Anchor onClick={() => setStep(2)}>
                  <span className="flex gap-4 items-center">
                    <Icon name="chevron-left-minor" color="one.5" size={12} />
                    <Text fw={500} color="one.5">
                      Back
                    </Text>
                  </span>
                </Anchor>
                <Anchor onClick={() => setStep(4)}>
                  <span className="flex gap-4 items-center">
                    <Text fw={500} color="one.5">
                      Next
                    </Text>
                    <Icon name="chevron-right-minor" color="one.5" size={12} />
                  </span>
                </Anchor>
              </div>
            </div>
          )}
          {step === 4 && (
            <div className="flex flex-col p-0 md:p-[32px] md:pb-[16px]  gap-12">
              <div className="flex flex-col gap-2">
                <Text fz={26} fw={700}>
                  Your “For you” category has been personalized based on your choices.
                </Text>
                <Text>You can edit your preferenced anytime by clicking “edit”.</Text>
              </div>
              <Button onClick={() => saveCustomTags()}>Save and Start Chatting</Button>
              <img src={customizeEndImg} alt="Customize Moby" />
              <Anchor onClick={() => setStep(3)} pt="lg">
                <span className="flex gap-4 items-center">
                  <Icon name="chevron-left-minor" color="one.5" size={12} />
                  <Text fw={500} color="one.5">
                    Back
                  </Text>
                </span>
              </Anchor>
            </div>
          )}
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
