import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { orderBy } from 'lodash';
import { toast } from 'react-toastify';
import moment from '@tw/moment-cached';
import { metrics } from '@tw/constants/module/Metrics/allMetrics';
import { type RootState } from 'reducers/RootType';
import { getAttributionCSVData, getParams, getSourcesList } from 'utils/attributions';
import axiosInstance from 'utils/axiosInstance';
import { genericEventLogger, analyticsEvents, attributionActions } from 'utils/dataLayer';
import { useAttributionParams } from 'utils/useAttributionParam';
import { useAttributionActiveSource } from 'utils/useAttributionActiveSource';
import { useAttributionActivePage } from 'utils/useAttributionActivePage';
import { useEffectiveSelectedColumns } from 'pages/Attribution/useEffectiveSelectedColumns';
import { formatNumber } from 'utils/formatNumber';
import allServices from 'constants/services';
import { AFFLUENCER, ALL_SOURCES_ID } from 'constants/types';
import CSVExport from 'utils/CSVExport';
import { CustomMetricsType, selectCustomMetricsForType } from 'ducks/customMetrics';
import { formatCustomMetricValue, getCustomMetricsFromRowData } from 'utils/customMetrics';
import { Flex, Icon, Text } from '@tw/ui-components';
import { useStoreValue } from '@tw/snipestate';
import { $currency } from '../../$stores/$shop';

const errorToastMsg = 'The CSV report was not generated due to an error. You can try again.';
const successToastMsg = 'The CSV report has been generated and saved to your device.';
const emptyArr = [];

const AttributionCsvExport = () => {
  const currentShopId = useSelector((state: RootState) => state.currentShopId);
  const [csvLoading, setCsvLoading] = useState(false);

  const userEmail = useSelector((state: RootState) => state.userEmail);
  const currency = useStoreValue($currency);
  const attributionParams = useAttributionParams();
  const activeSource = useAttributionActiveSource();
  const sourceCategory = useAttributionActivePage();
  const { attributionData } = useSelector((state: RootState) => state.attribution);
  const customMetrics = useSelector(selectCustomMetricsForType(CustomMetricsType.Attribution));

  const effectiveSelectedColumns = useEffectiveSelectedColumns();
  const addCustomMetricsToData = (data, customMetrics) => {
    return data.map((row) => {
      return {
        ...row,
        ...getCustomMetricsFromRowData(customMetrics, row),
      };
    });
  };

  const getCSVData = async (params, columns: string[], isOneDay: boolean) => {
    const requestParams = { ...params, columns, isOneDay };
    const { data } = await getAttributionCSVData(requestParams);
    return data || [];
  };

  const downloadCSV = async (
    requestParams,
    currentShopId,
    selectedColumns,
    metrics,
    attributionCampaigns,
    activeSource,
  ) => {
    let params = { ...requestParams.params } as any;
    let attributionData;
    let allData;
    let columns = getCsvColumns(selectedColumns, metrics, params, activeSource);
    const {
      period: { startDate, endDate, isOneDay },
    } = requestParams;
    const fileName = getFileName(isOneDay, currentShopId, startDate, endDate, params);

    try {
      params.breakdown =
        params.breakdown !== 'source' && params.sources[0].includes('ads')
          ? 'adId'
          : params.breakdown;
      attributionData =
        activeSource === AFFLUENCER
          ? attributionCampaigns
          : await getCSVData(params, columns, isOneDay);

      allData = addCustomMetricsToData(attributionData, customMetrics);
    } catch (e) {
      throw new Error('Fetch data error');
    }

    let csvData;
    if (!isOneDay) {
      csvData = allData.map((d) => {
        let row = {};
        columns.forEach((col) => {
          if (
            [
              'day',
              'campaignId',
              'campaignName',
              'adsetId',
              'adsetName',
              'adId',
              'name',
              'serviceId',
              'id',
            ].includes(col.key)
          ) {
            row[col.label] = getEntityValues(d, col, params);
          } else {
            row[col.label] = getMetricValues(d, col, currency, metrics);
          }
        });
        return row;
      });
      csvData = csvData.flat();
      csvData = orderBy(csvData, (row) => row['Day'], 'asc');
    } else {
      csvData = allData.map((d) => {
        let metricRows = columns.reduce((acc, col) => {
          if (
            [
              'day',
              'campaignId',
              'campaignName',
              'adsetId',
              'adsetName',
              'adId',
              'name',
              'serviceId',
            ].includes(col.key)
          ) {
            acc[col.label] = getEntityValues(d, col, params);
          } else {
            acc[col.label] = getMetricValues(d, col, currency, metrics);
          }
          return acc;
        }, {});
        return metricRows;
      });
    }

    try {
      CSVExport(csvData, fileName);
      return csvData;
    } catch (e) {
      throw new Error('Saving error');
    }
  };

  const getFileName = (isOneDay, currentShopId, startDate, endDate, params) => {
    let source = '';
    if (!params.sources) {
      source = ALL_SOURCES_ID;
    } else {
      source = params.sources?.length > 1 || params.breakdown === 'source' ? 'all-' : '';
      source +=
        params.sources?.length > 1
          ? `${allServices[params.sources?.[0] || '']?.type || ''}`
          : allServices[params.sources[0]]?.title?.replaceAll?.(/\s/g, '-') || params.sources[0];
    }
    const namePrefix = `${currentShopId}-${source}`;
    const fileName = !isOneDay
      ? `${namePrefix}-${startDate?.split('T')[0]}-${endDate?.split('T')[0]}`
      : `${namePrefix}-${startDate?.split('T')[0]}`;
    return fileName;
  };

  const getCsvColumns = (selectedColumns, metrics, params, activeSource) => {
    let columns: any[] = selectedColumns
      .filter((c) => !['status', 'urlParams', 'showInGraph'].includes(c.key))
      .map((c) => {
        return { label: metrics[c.key]?.label || c.name || c.key, key: c.key };
      });

    if (activeSource === AFFLUENCER) {
      columns.splice(0, 0, { label: 'Affluencer Id', key: 'id' });
      columns.splice(1, 0, { label: 'Affluencer Name', key: 'name' });
    } else {
      columns.splice(0, 0, { label: 'Day', key: 'day' });
      if (params.breakdown !== 'source') {
        columns.splice(1, 0, { label: 'Channel', key: 'serviceId' });
        columns.splice(2, 0, { label: 'Campaign Id', key: 'campaignId' });
        columns.splice(3, 0, { label: 'Campaign Name', key: 'campaignName' });
        if (['ads', 'bing'].some((source) => params.sources[0].includes(source))) {
          columns.splice(4, 0, { label: 'Adset Id', key: 'adsetId' });
          columns.splice(5, 0, { label: 'Adset Name', key: 'adsetName' });
          columns.splice(6, 0, { label: 'Ad Id', key: 'adId' });
          columns.splice(7, 1, { label: 'Ad Name', key: 'name' });
        } else if (params.sources[0].includes('klaviyo')) {
          columns.splice(3, 2, { label: 'Campaign Name', key: 'campaignName' });
        } else {
          columns.splice(3, 2, { label: 'Campaign Name', key: 'name' });
        }
      } else {
        columns.splice(1, 0, { label: 'Source', key: 'serviceId' });
        columns.splice(2, 1);
      }
    }

    return columns;
  };

  const getEntityValues = (data, col, params) => {
    let value = '';
    if (col.key.toLowerCase().includes('name')) {
      if (data.campaignType === 'PERFORMANCE_MAX') {
        value = col.label === 'Ad Name' ? '' : data[col.key] || '';
      } else {
        value = col.label !== 'Ad Name' ? data[col.key] || '' : data[col.key] || data.id;
      }
    } else {
      switch (col.key) {
        case 'serviceId':
          value =
            params.breakdown === 'source'
              ? allServices[data.id]?.title || data.id || data.serviceId
              : allServices[params.sources[0]]?.title || params.sources[0] || '';
          break;
        case 'campaignId':
          value = data[col.key]
            ? data[col.key]
            : data.entity === 'campaign' && !data.campaignId && data.id
              ? data.id
              : '';
          break;
        default:
          value = data[col.key] || '';
          break;
      }
    }
    try {
      value = (value || '').toString().replaceAll('#', '%23');
    } catch (e) {
      console.log(e);
    }
    return value;
  };

  const getMetricValues = (data, col, currency, metrics) => {
    const value = data[col.key];
    const metric = metrics[col.key];
    const customMetric = customMetrics.find((m) => m.id === col.key);

    if (metric) {
      return formatNumber(value || 0, {
        style: metric?.format,
        currency,
        minimumFractionDigits: metric.toFixed,
        maximumFractionDigits: metric.toFixed,
      });
    }

    if (customMetric) {
      return formatCustomMetricValue(value, customMetric, currency);
    }

    return value || '';
  };

  const sourcesList = useMemo(() => {
    if (sourceCategory === ALL_SOURCES_ID) {
      return emptyArr;
    }
    return getSourcesList(sourceCategory || ALL_SOURCES_ID);
  }, [sourceCategory]);

  const exportCSV = async () => {
    setCsvLoading(true);
    const requestParams = getParams({
      attributionParams,
      sourceCategory,
      activeSource,
      sourcesList,
    });
    if (!requestParams) {
      return;
    }
    const {
      params,
      period: { startDate, endDate },
    } = requestParams;

    genericEventLogger(analyticsEvents.ATTRIBUTION, {
      action: attributionActions.EXPORT_TO_CSV,
      shop: currentShopId,
      dateTo: startDate?.split('T')[0],
      dateFrom: endDate?.split('T')[0],
    });

    const startTime = Date.now();
    try {
      await downloadCSV(
        requestParams,
        currentShopId,
        effectiveSelectedColumns,
        metrics,
        attributionData,
        activeSource,
      );
      toast.success(successToastMsg);
      setCsvLoading(false);

      const endTime = Date.now();
      const doc = {
        requestTime: moment().utc().format(),
        shop: currentShopId,
        user: userEmail,
        dateFrom: startDate,
        dateTo: endDate,
        attributionModel: params.model,
        attributionDateType: params.dateModel,
        channels: params.sources,
        breakdown: 'adId',
        generationTime: endTime - startTime,
      };
    } catch (e) {
      console.log(e.message);
      if (e.message === 'Fetch data error') {
        toast.error('The CSV report was not generated due to an error. Try a smaller date range.');
      } else {
        toast.error(errorToastMsg);
      }
      setCsvLoading(false);
    }
  };

  return (
    <Flex onClick={exportCSV} align="center" justify="space-between" id="exportCsv">
      <Text size="sm" weight={500} color="named2.0">
        Export CSV
      </Text>
      {csvLoading && <Icon name="loader-1" />}
    </Flex>
  );
};

export default AttributionCsvExport;
