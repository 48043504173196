import { $currentRevenue, $isFreeShop } from '$stores/willy/$subscription';
import { $bookDemoModal, closeBookDemoModal } from '$stores/willy/$upgradePopupManager';
import { useStoreValue } from '@tw/snipestate';
import { Modal } from '@tw/ui-components';
import { MeetingPlanner } from 'components/WizardWalkthrough/steps/MeetingPlanner';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { RootState } from 'reducers/RootType';

export const BookDemoModal: React.FC = () => {
  const navigate = useNavigate();
  const bookDemoModal = useStoreValue($bookDemoModal);
  const isFreeShop = useStoreValue($isFreeShop);
  const { hubSpotCompany }: any = useSelector((state: RootState) => state.hubspot);
  const { primary_support_team, primary_sales_team } = hubSpotCompany.properties || {};
  const [routerId, setRouterId] = useState<string>('');
  const revenue = useStoreValue($currentRevenue);
  useEffect(() => {
    if (bookDemoModal.opened) {
      let newRouterId = '';

      switch (bookDemoModal.source) {
        case 'pricing':
          if (revenue > 1000000) {
            if (isFreeShop) {
              newRouterId = 'app-router';
            } else if (primary_sales_team === 'Expansion Sales') {
              newRouterId = 'expansion-ae-round-robin';
            } else if (primary_sales_team === 'Brand Sales' || !primary_sales_team) {
              newRouterId = 'app-router';
            }
          } else {
            toast.info('Please reach out to us via the chat icon below!');
            closeBookDemoModal();
          }
          break;

        case 'sonar':
          if (isFreeShop) {
            newRouterId = 'app-router';
          } else if (revenue > 1000000) {
            const lowSupportOptions = ['Customer Success Office Hours', 'L1 Support'];
            if (!lowSupportOptions.includes(primary_support_team)) {
              newRouterId = 'sonar-router';
            } else {
              navigate('https://try.triplewhale.com/customer-success-office-hours');
              closeBookDemoModal(); // Close modal after navigation
            }
          } else {
            toast.info('Please reach out to us via the chat icon below!');
            closeBookDemoModal();
          }
          break;

        default:
          break;
      }

      // Update the router ID if a new one was set and is different from the current
      if (newRouterId && newRouterId !== routerId) {
        setRouterId(newRouterId);
      }
    }
  }, [bookDemoModal.opened, primary_sales_team, primary_support_team, isFreeShop, routerId]);

  return (
    <Modal
      opened={bookDemoModal.opened}
      onClose={() => {
        closeBookDemoModal();
      }}
    >
      <MeetingPlanner routerId={routerId} />
    </Modal>
  );
};
