import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { AffluencerHubHeader } from 'components/headers/AffluencerHubHeader';
import AttributionHeader from 'components/headers/AttributionHeader';
import { CustomerJourneysHeader } from 'components/headers/CustomerJourneysHeader';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/RootType';
import { NavSection } from '../types';
import { NavSectionIcon } from '@tw/ui-components';
import CreativeCockpitHeader from 'components/headers/CreativeCockpitHeader';

export const PIXEL_SECTION: NavSection = {
  title: 'Pixel',
  icon: (props) => <NavSectionIcon src="pixel" {...props} />,
  routes: [
    {
      url: '/attribution/all',
      dependsOnFFSystem: FeatureFlag.PIXEL_ALL_FF,
      label: 'All',
      header: () => <AttributionHeader />,
      lastUpdate: () => {
        const InnerComponent = () => {
          const lastUpdate = useSelector((state: RootState) => state.attribution.lastRefresh);
          return <> {moment(lastUpdate).local().format('LT')} </>;
        };
        return <InnerComponent />;
      },
    },
    {
      url: '/attribution/ads',
      dependsOnFFSystem: FeatureFlag.PIXEL_ALL_FF,
      label: 'Ads',
      header: () => <AttributionHeader />,
      lastUpdate: () => {
        const InnerComponent = () => {
          const lastUpdate = useSelector((state: RootState) => state.attribution.lastRefresh);
          return <> {moment(lastUpdate).local().format('LT')} </>;
        };
        return <InnerComponent />;
      },
    },
    {
      url: '/attribution/email',
      dependsOnFFSystem: FeatureFlag.PIXEL_EMAIL_FF,
      label: 'Email / SMS',
      header: () => <AttributionHeader />,
      lastUpdate: () => {
        const InnerComponent = () => {
          const lastUpdate = useSelector((state: RootState) => state.attribution.lastRefresh);
          return <> {moment(lastUpdate).local().format('LT')} </>;
        };
        return <InnerComponent />;
      },
    },
    {
      url: '/attribution/social',
      dependsOnFFSystem: FeatureFlag.PIXEL_ORGANIC_FF,
      label: 'Organic',
      header: () => <AttributionHeader />,
      lastUpdate: () => {
        const InnerComponent = () => {
          const lastUpdate = useSelector((state: RootState) => state.attribution.lastRefresh);
          return <> {moment(lastUpdate).local().format('LT')} </>;
        };
        return <InnerComponent />;
      },
    },
    {
      url: '/attribution/affluencer-hub',
      dependsOnFFSystem: FeatureFlag.PIXEL_AFFLUENCER_HUB_FF,
      label: 'Affluencer Hub',
      header: () => <AffluencerHubHeader />,
    },
    // {
    //   url: '/attribution/customer-journeys-new',
    //   dependsOnFeature: FeatureFlag.PIXEL_ALL_FF,
    //   label: 'Customer Journeys',
    //   header: () => <CustomerJourneysHeaderNew />,
    // },
    // {
    //   url: '/attribution/customer-journeys',
    //   dependsOnFFSystem: FeatureFlag.PIXEL_ALL_FF,
    //   label: 'Customer Journeys',
    //   header: () => <CustomerJourneysHeader />,
    // },
    {
      url: '/creative-cockpit',
      dependsOnFFSystem: FeatureFlag.CC_CREATIVE_ANALYSIS_FF,
      label: 'Creative Cockpit',
      header: () => <CreativeCockpitHeader />,
    },
  ],
};
