import { useEffect } from 'react';
import { Page } from '@shopify/polaris';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { computeFeatureFlags } from 'feature-flag-system';
import { UpgradePageFallBack } from 'feature-flag-system/components';
import Cohorts from 'components/Insights/Cohorts/Cohorts';
import { useAppDispatch } from 'index';
import { setInsightsEventsContext } from 'ducks/insights/actions';
import { analyticsEvents } from 'utils/dataLayer';
import { InAppContextBanner } from 'components/InAppContextBanner';

const CohortsPage = computeFeatureFlags(
  FeatureFlag.INSIGHT_GENERAL_FF,
  () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
      dispatch(setInsightsEventsContext(analyticsEvents.COHORTS));
    }, [dispatch]);

    return (
      <Page fullWidth>
        <InAppContextBanner page="cohorts" />
        <Cohorts />
      </Page>
    );
  },
  () => (
    <UpgradePageFallBack
      InAppContextBannerPage={'cohorts'}
      InAppContextBannerEnabled={true}
      featureFlag={FeatureFlag.INSIGHT_GENERAL_FF}
    />
  ),
);

export default CohortsPage;
