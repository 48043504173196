import { CreateEditRulesEngineModal } from 'components/RulesEngine/CreateEditRulesEngineModal';
import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { Banner, Button, Link, Popover, Spinner, TextStyle, Tooltip } from '@shopify/polaris';
import {
  ChannelsMajor,
  EditMinor,
  MobilePlusMajor,
  QuestionMarkInverseMinor,
  QuestionMarkMajor,
} from '@shopify/polaris-icons';
import { ServicesIds } from '@tw/types/module/services';
import {
  checkHealth,
  getStrategies,
  getTWStrategies,
  saveStrategies,
} from 'ducks/rulesEngine/action';
import { RulesContext } from './rulesContext';
import { useSelector } from 'react-redux';
import { type RootState } from 'reducers/RootType';
import { rulesReducer } from './rulesReducer';
import { TWRuleStrategy } from './RulesStrategy';
import { EditStrategy, StrategyType } from './types';
import ReactSwitch from 'react-switch';
import { getTWRules, cleanUpForSave } from './helpers';
import {
  UNMATCHED_RATE_TOO_HIGH_TOOLTIP,
  MISSING_PERMISSION_TOOLTIP,
  UNMATCHED_RATE_TOO_HIGH_POPUP_TOOLTIP,
} from './constants';
import { ConditionalWrapper } from 'components/ConditionalWrapper';
import { genericEventLogger, analyticsEvents, trafficRulesActions } from 'utils/dataLayer';
import { useDarkMode } from 'dark-mode-control';
import _ from 'lodash';
import { ActionIcon, Box } from '@tw/ui-components';

type Props = {
  serviceId: string;
};
export const RulesEnginePopup: React.FC<Props> = ({ serviceId }) => {
  const currentShopId = useSelector((state: RootState) => state.currentShopId);
  const userEmail = useSelector((state: RootState) => state.userEmail);
  const [strategies, rulesDispatch] = useReducer(rulesReducer, []);
  const [isFbScopeEnough, setIsFbScopeEnough] = useState<boolean>(false);
  const [isUnmatchedRateLow, setIsUnmatchedRateLow] = useState<boolean>(false);
  const [showCheckHealthTooltip, setShowCheckHealthTooltip] = useState<boolean>(false);
  // let { strategies, currentShopId, rulesDispatch } = useContext(RulesContext);

  const [showError, setShowError] = useState(false);
  const [errorSave, setErrorSave] = useState('');
  const [toggleError, setToggleError] = useState('');
  const [isRuleEngineOpen, setIsRuleEngineOpen] = useState(false);
  const [openCreate, setOpenCreate] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editRule, setEditRule] = useState<StrategyType>();
  const [twStrategies, setTWStrategies] = useState<any>([]);
  const [openViewStrategy, setOpenViewStrategy] = useState(false);
  const [loading, setloading] = useState(false);
  const [twLoading, setTwLoading] = useState(false);
  const [pendingSave, setPendingSave] = useState(false);
  const [simulate, setSimulate] = useState(false);

  const fetchRules = useCallback(async () => {
    setloading(true);
    const params = { shop: currentShopId };
    try {
      const { rulesEngine } = await getStrategies(params);
      rulesDispatch({ type: 'replace_all', rules: rulesEngine });
    } catch {
      console.log('can not get rules');
      setloading(false);
    }
    setloading(false);
  }, [currentShopId]);

  const fetchHealthCheck = useCallback(async () => {
    try {
      setToggleError('');
      const { data } = await checkHealth(currentShopId);
      const { unmatched, permission } = data;
      setIsUnmatchedRateLow(unmatched === 'ok');
      setIsFbScopeEnough(permission === 'ok');
      setShowCheckHealthTooltip(true);
      setToggleError('');
    } catch (e) {
      setToggleError('An unexpected error occurred. Please close and reopen the Rules Popup.');
    }
  }, [currentShopId]);

  const fetchTWRules = async () => {
    setTwLoading(true);
    const twStrategies = await getTWStrategies({ shop: currentShopId });
    setTWStrategies(twStrategies);
    setTwLoading(false);
  };

  const getTwStrategiesStatus = (ruleId): boolean => {
    const twStrategies = getTWRules(strategies);
    const found = twStrategies.find(
      (strategy) => (strategy.tw_rules._id || strategy.tw_rules) === ruleId,
    );
    return found ? found.status === 'active' : false;
  };

  const saveRule = useCallback(
    async function (shopRule: EditStrategy, toggleActive = false) {
      try {
        setPendingSave(true);
        setShowError(false);
        setErrorSave('');
        let cleanShopRule = cleanUpForSave({ ...shopRule });
        cleanShopRule.updatedBy = userEmail;
        let res;
        try {
          res = await saveStrategies(cleanShopRule);
          if (cleanShopRule._id) {
            let type = cleanShopRule.version?.rules[0]?.isDeleted ? 'delete_rule' : 'replace';
            rulesDispatch({ type: type as any, rule: res.data });
          } else {
            rulesDispatch({ type: 'add', rules: [res.data] });
          }
          closeCreateRulePopup();

          setPendingSave(false);
          setToggleError('');
        } catch (e) {
          console.log('error:', e);
          setPendingSave(false);
          setShowError(true);
          setErrorSave(e.data || ' We had a problem saving this rule. You can try again.');
          if (toggleActive) {
            setToggleError('There was a problem saving the rule. Please try again');
            setTimeout(() => {
              setToggleError('');
            }, 8000);
          }
        }
      } catch (e) {
        console.log('error:', e);
      }
    },
    [userEmail],
  );

  const openPopup = useCallback(() => {
    //add loading
    fetchHealthCheck();
    setIsRuleEngineOpen(true);
  }, [fetchRules, fetchHealthCheck]);

  const toggleRule = useCallback(
    (currentRule) => () => {
      strategies?.every((strategy) => {
        for (const version of strategy.versions) {
          const rule = version.rules?.find((rule) => rule._id === currentRule._id);
          if (rule) {
            const status = rule.status === 'active' ? 'inactive' : 'active';
            if (status === 'active') {
              setSimulate(true);
              handleEditRule(strategy);
            } else {
              rule.status = status;
              strategy.status = status;
              rulesDispatch({ type: 'replace', rule: strategy });
              saveRule(strategy, true);

              genericEventLogger(analyticsEvents.TRAFFIC_RULES, {
                action: trafficRulesActions.CLICK_RULE,
                shop: currentShopId,
                ruleName: rule.name,
                ruleId: rule._id,
                status: rule.status,
              });
            }
            // return false to break out of loops
            return false;
          }
          return true;
        }
      });
    },
    [saveRule, strategies],
  );

  const toggleTWRule = (twRule: any, shouldTurnON) => {
    let twRules = getTWRules(strategies);
    let twStrategy = twRules.find((rule) => rule.tw_rules._id === twRule._id);
    if (twStrategy) {
      if (shouldTurnON && twStrategy.status === 'active') {
        setPendingSave(true);
        setTimeout(() => {
          closeCreateRulePopup();
          setPendingSave(false);
        }, 1000);
        return;
      }
      twStrategy.status = twStrategy.status === 'active' ? 'inactive' : 'active';
      rulesDispatch({ type: 'replace', rule: twStrategy });
    } else {
      twStrategy = {
        shop: currentShopId,
        createdBy: userEmail,
        name: twRule.name,
        status: 'active',
        tw_rules: twRule._id,
      };
      rulesDispatch({ type: 'add', rules: [twStrategy] });
    }
    saveRule(twStrategy, true);
    genericEventLogger(analyticsEvents.TRAFFIC_RULES, {
      action: trafficRulesActions.CLICK_STRATEGY,
      shop: currentShopId,
      ruleName: twRule.name,
      ruleId: twRule._id,
      status: twStrategy?.status || 'active',
    });
  };

  const handleToggleTWRule = (status, twStrategy) => {
    if (status) {
      toggleTWRule(twStrategy, false);
    } else {
      setOpenViewStrategy(twStrategy);
    }
  };

  const viewTWRule = (twStrategy) => {
    setOpenViewStrategy(twStrategy);
  };

  const closeRuleEnginePopup = () => {
    openCreate || openViewStrategy ? setIsRuleEngineOpen(true) : setIsRuleEngineOpen(false);
  };

  const closeCreateRulePopup = function () {
    setOpenCreate(false);
    setEditRule(undefined);
    setIsEdit(false);
    setErrorSave('');
    setShowError(false);
    setOpenViewStrategy(false);
  };

  const handleEditRule = (rule: EditStrategy) => {
    setIsEdit(true);
    setEditRule({ ...rule });
    setOpenCreate(true);
  };

  const handleClickOnCreate = function () {
    setOpenCreate(true);
    //setIsRuleEngineOpen(false);
  };

  useEffect(() => {
    fetchRules();
    fetchHealthCheck();
    fetchTWRules();
  }, [fetchRules]);

  const context = {
    strategies,
    currentShopId,
    userEmail,
    rulesDispatch,
    saveRule,
    toggleTWRule,
  };

  return (
    <RulesContext.Provider value={context}>
      <Popover
        active={isRuleEngineOpen}
        onClose={closeRuleEnginePopup}
        fullHeight
        zIndexOverride={518}
        activator={
          <Box pos="relative">
            <Tooltip
              content={
                serviceId !== 'facebook-ads'
                  ? 'Rules are currently available only for Facebook Ads'
                  : 'Rules Engine'
              }
            >
              <ActionIcon
                id="rules-popup-button"
                size="lg"
                radius="sm"
                onClick={openPopup}
                disabled={serviceId !== 'facebook-ads'}
                variant="activator"
                icon="channels-major"
              />
            </Tooltip>
          </Box>
        }
      >
        <div className="p-6.5">
          <div className="flex justify-between items-center gap-4 font-bold">
            <span className="text-2xl mb-6.5 flex items-center gap-4">
              <ChannelsMajor className="flex items-baseline" width={20} height={20} />
              <span>Rules</span>
            </span>
            <span className="mb-6.5 flex-end text-xl">
              <Link url="/changelog/auditlogs" removeUnderline>
                Audit logs
              </Link>
            </span>
          </div>
          <div>{toggleError && <div className="text-red-600">{toggleError}</div>}</div>
          {isUnmatchedRateLow && !isFbScopeEnough && (
            <div style={{ paddingBottom: '5px' }}>
              <Banner status="warning">{MISSING_PERMISSION_TOOLTIP(currentShopId, true)}</Banner>
            </div>
          )}
          <div className="bg-blue-100 p-3">
            <div className="overflow-x-hidden ">
              <div>
                <div className="overflow-x-hidden">
                  {twLoading && (
                    <div>
                      <Spinner size="small" />
                    </div>
                  )}
                  {!twLoading && (
                    <>
                      {twStrategies.map((twStrategy) => {
                        return (
                          <div key={twStrategy._id} className="flex items-center gap-4 pb-2 pt-2">
                            <ConditionalWrapper
                              condition={
                                showCheckHealthTooltip && (!isUnmatchedRateLow || !isFbScopeEnough)
                              }
                              wrapper={(children) => (
                                <Tooltip
                                  content={
                                    !isUnmatchedRateLow
                                      ? (UNMATCHED_RATE_TOO_HIGH_TOOLTIP as any)
                                      : MISSING_PERMISSION_TOOLTIP(currentShopId, false)
                                  }
                                >
                                  {children}
                                </Tooltip>
                              )}
                            >
                              <ReactSwitch
                                onColor="#1877f2"
                                handleDiameter={16}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                height={10}
                                width={30}
                                className="react-switch align-middle"
                                id="twStrategy-checkbox"
                                checked={getTwStrategiesStatus(twStrategy._id)}
                                onChange={() =>
                                  handleToggleTWRule(
                                    getTwStrategiesStatus(twStrategy._id),
                                    twStrategy,
                                  )
                                }
                                disabled={
                                  !getTwStrategiesStatus(twStrategy._id) &&
                                  (!isFbScopeEnough || !isUnmatchedRateLow)
                                }
                              />
                            </ConditionalWrapper>
                            <div className="flex items-center gap-4">
                              <span>Triple Whale Strategy</span>
                            </div>
                            <div className="flex items-center">
                              <Tooltip content="Recommended strategy for optimizing the performance of your ad account.">
                                <span>
                                  <QuestionMarkMajor
                                    className="flex items-center opacity-50"
                                    width={14}
                                    height={14}
                                  />
                                </span>
                              </Tooltip>
                            </div>
                            <div
                              className="flex items-center font-bold"
                              style={{ marginLeft: 'auto' }}
                            >
                              <div
                                id="view-twStrategies"
                                className="font-semibold text-sky-600 text-xl cursor-pointer"
                                onClick={() => viewTWRule(twStrategy)}
                              >
                                VIEW
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center my-6.5 w-full">
            <div className="flex-auto w-full max-h-80 overflow-auto relative">
              <div className="pb-6.5 sticky top-0 bg-white z-50">
                <TextStyle variation="strong">Saved Rules</TextStyle>
              </div>
              {loading && (
                <div>
                  <Spinner size="small" />
                </div>
              )}
              {!loading && (
                <>
                  {Array.isArray(strategies) &&
                    strategies?.length > 0 &&
                    strategies
                      ?.filter((x) => !x.tw_rules)
                      ?.map((strategy, ind) =>
                        strategy.versions?.map((version) =>
                          version.rules?.map((rule) => {
                            return (
                              <div
                                key={rule._id}
                                className="flex items-center gap-4 flex-auto w-full mb-6.5 mt-4 ml-3"
                              >
                                <ConditionalWrapper
                                  condition={
                                    showCheckHealthTooltip &&
                                    (!isUnmatchedRateLow || !isFbScopeEnough)
                                  }
                                  wrapper={(children) => (
                                    <Tooltip
                                      content={
                                        !isUnmatchedRateLow
                                          ? (UNMATCHED_RATE_TOO_HIGH_TOOLTIP as any)
                                          : MISSING_PERMISSION_TOOLTIP(currentShopId, false)
                                      }
                                    >
                                      {children}
                                    </Tooltip>
                                  )}
                                >
                                  <ReactSwitch
                                    key={rule._id}
                                    onColor="#1877f2"
                                    handleDiameter={16}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                    height={10}
                                    width={30}
                                    className="react-switch align-middle"
                                    id="rule-checkbox"
                                    checked={rule.status === 'active'}
                                    onChange={toggleRule(rule)}
                                    disabled={
                                      !(rule.status === 'active') &&
                                      (!isFbScopeEnough || !isUnmatchedRateLow)
                                    }
                                  />
                                </ConditionalWrapper>
                                <div className="flex items-center gap-4 flex-auto">
                                  <span>{rule.name}</span>
                                </div>

                                <div className="flex items-center gap-4 flex-auto">
                                  {rule.ruleDescription && (
                                    <Tooltip content={rule.ruleDescription}>
                                      <span>
                                        <QuestionMarkInverseMinor
                                          className="flex items-center fill-logo opacity-40"
                                          width={16}
                                          height={16}
                                        />
                                      </span>
                                    </Tooltip>
                                  )}
                                </div>
                                <span className="edit-rule ml-auto mr-6">
                                  <Tooltip content={'Edit rule'}>
                                    <span>
                                      <EditMinor
                                        width={16}
                                        height={16}
                                        className="flex items-center cursor-pointer"
                                        onClick={() => handleEditRule(strategy)}
                                      />
                                    </span>
                                  </Tooltip>
                                </span>
                              </div>
                            );
                          }),
                        ),
                      )}
                </>
              )}
            </div>
          </div>
          <Tooltip content={'Add new rule'} dismissOnMouseOut>
            <div className="">
              <Button
                onClick={() => {
                  handleClickOnCreate();
                }}
                icon={MobilePlusMajor}
                textAlign="left"
                id="create-rule-button"
              >
                Create new rule
              </Button>
            </div>
          </Tooltip>
        </div>
      </Popover>

      {openCreate && (
        <>
          <CreateEditRulesEngineModal
            pendingSave={pendingSave}
            setPendingSave={setPendingSave}
            onCloseCreatePopup={closeCreateRulePopup}
            isOpen={openCreate}
            isEdit={isEdit}
            handleNew={function (serviceId: ServicesIds): void {}}
            editRule={editRule}
            setShowError={setShowError}
            errorSave={errorSave}
            showError={showError}
            startSimulation={simulate}
            setStartSimulation={setSimulate}
            unableLaunch={!isUnmatchedRateLow || !isFbScopeEnough}
            unableLaunchMessage={!isUnmatchedRateLow ? UNMATCHED_RATE_TOO_HIGH_POPUP_TOOLTIP : ''}
            existingStrategies={strategies}
          ></CreateEditRulesEngineModal>
        </>
      )}

      {openViewStrategy && (
        <>
          <TWRuleStrategy
            onClose={() => setOpenViewStrategy(false)}
            isOpen={true}
            strategies={openViewStrategy}
            toggleTWRule={toggleTWRule}
            currentShopId={currentShopId}
            errorSave={errorSave}
            pendingSave={pendingSave}
            checkHealthResult={{ unmatched: isUnmatchedRateLow, permission: isFbScopeEnough }}
            showApplyRules={false}
          ></TWRuleStrategy>
        </>
      )}
    </RulesContext.Provider>
  );
};
