import 'firebase/compat/firestore';
import { toast } from 'react-toastify';
import firebase from 'firebase/compat/app';
import db, { toArray } from 'utils/DB';
import { toggleSaveIndicator } from './actions';
import axiosInstance from 'utils/axiosInstance';
import { INIT_SHOP } from 'ducks/constants';
import { $currentShopId } from '$stores/$shop';
import { SHARE_REPORT_DONE } from './reports';
import { AppDispatch } from 'index';
import { RootState } from 'reducers/RootType';
import type { BannerStatus } from '@shopify/polaris';
import { analyticsEvents, googleSheetsActions, genericEventLogger } from 'utils/dataLayer';
import { callRemoveLocalShopProp } from 'utils/callRemoveLocalShopProp';

const firestore = firebase.firestore;

export const LOADING_GOOGLE_SHEETS_REPORTS = 'LOADING_GOOGLE_SHEETS_REPORTS';
export const LOADED_GOOGLE_SHEETS_REPORTS = 'LOADED_GOOGLE_SHEETS_REPORTS';
export const GOOGLE_SHEETS_REPORT_LOADING_ERROR = 'GOOGLE_SHEETS_REPORT_LOADING_ERROR';
export const GOOGLE_SHEETS_REPORT_SAVING = 'GOOGLE_SHEETS_REPORT_SAVING';
export const GOOGLE_SHEETS_REPORT_SAVED = 'GOOGLE_SHEETS_REPORT_SAVED';
export const GOOGLE_SHEETS_REPORT_UPDATED = 'GOOGLE_SHEETS_REPORT_UPDATED';
export const GOOGLE_SHEETS_REPORT_UPDATING = 'GOOGLE_SHEETS_REPORT_UPDATING';
export const GOOGLE_SHEETS_REPORT_ERROR = 'GOOGLE_SHEETS_REPORT_ERROR';
export const GOOGLE_SHEETS_REPORT_DELETED = 'GOOGLE_SHEETS_REPORT_DELETED';
export const GOOGLE_SHEETS_REMOVE_ACCOUNT_DONE = 'GOOGLE_SHEETS_REMOVE_ACCOUNT_DONE';
export const GOOGLE_SHEETS_DISCONNECT_DONE = 'GOOGLE_SHEETS_DISCONNECT_DONE';
export const GOOGLE_SHEETS_CSV_SAVING = 'GOOGLE_SHEETS_CSV_SAVING';
export const GOOGLE_SHEETS_CSV_SAVED = 'GOOGLE_SHEETS_CSV_SAVED';
export const GOOGLE_SHEETS_BANNER_METADATA = 'GOOGLE_SHEETS_BANNER_METADATA';
export const UPDATE_GOOGLE_SHEETS_ACCOUNTS = 'UPDATE_GOOGLE_SHEETS_ACCOUNTS';

export const googleSheetsConnect = async (AddedRedirect: boolean = false) => {
  try {
    const url = `v2/google-sheets/authorize/login?shopId=${$currentShopId.get()}${
      AddedRedirect ? `&redirectUrl=${window.location.href}` : ''
    }`;
    const res = await axiosInstance.get(url);
    window.location.replace(`${res.data?.url}`);
  } catch (e) {
    toast.error('There was an error connecting to Google Sheets, please try again!');
  }
};

export function googleSheetsDisconnect() {
  return async (dispatch, getState) => {
    toggleSaveIndicator();
    // TODO billy: further implementation needed?
    await db().update({
      googleSheetsAccessToken: { disconnected: true },
      googleSheetsAccounts: [],
    });
    dispatch(callRemoveLocalShopProp('googleSheetsDisconnect'));
    dispatch({
      type: GOOGLE_SHEETS_DISCONNECT_DONE,
    });
  };
}

export const startLoadingGoogleSheetsReports = () => {
  return {
    type: LOADING_GOOGLE_SHEETS_REPORTS,
  };
};

export const doneLoadingGoogleSheetsReports = (reports) => {
  return {
    type: LOADED_GOOGLE_SHEETS_REPORTS,
    reports,
  };
};

export const loadGoogleSheetsReports = () => {
  return async (dispatch, getState) => {
    dispatch(startLoadingGoogleSheetsReports());
    try {
      const data = await db().collection('reports').orderBy('createdAt', 'desc').get();
      const gsReports = toArray(data).filter((r) => r.type === 'googleSheets');
      dispatch(doneLoadingGoogleSheetsReports(gsReports));
    } catch (e) {
      dispatch(doneLoadingGoogleSheetsReports([getState().googleSheetsReports]));
      dispatch({
        type: GOOGLE_SHEETS_REPORT_LOADING_ERROR,
        error: e.data?.message || e.message || 'Unknown error occured',
      });
    }
  };
};

export const getSpreadsheetsForAccount = async (
  sheetsAccount: string,
): Promise<{ id: string; name: string }[]> => {
  try {
    return (
      await axiosInstance.get(
        `/v2/google-sheets/spreadsheets?sheetsAccount=${sheetsAccount}&shopId=${$currentShopId.get()}`,
      )
    ).data;
  } catch (e) {
    console.error(e);
    return [];
  }
};

export const getWorksheetsForSpreadsheet = async (args: {
  spreadsheetId: string;
  sheetsAccount: string;
}): Promise<{ id: number; title: string }[]> => {
  const { spreadsheetId, sheetsAccount } = args;
  try {
    return (
      await axiosInstance.get(
        `/v2/google-sheets/spreadsheets/${spreadsheetId}/worksheets?sheetsAccount=${sheetsAccount}&shopId=${$currentShopId.get()}`,
      )
    ).data;
  } catch (e) {
    console.error(e);
    return [];
  }
};

export type BaseGoogleSheetsReportInfo = {
  id?: string;
  title: string;
  frequency: string;
  sheetsAccount: string;
  startDate?: string;
  endDate?: string;
  oneTime?: boolean;
  reportType?: 'metrics' | 'forecast' | 'customSpends';
};

export type GoogleSheetsMetricsReportInfo = BaseGoogleSheetsReportInfo & {
  metrics: string[];
  startDate?: string;
  endDate?: string;
  oneTime?: boolean;
};

export type GoogleSheetsForecastReportInfo = BaseGoogleSheetsReportInfo & {
  reportType: 'forecast';
  numPeriodsAhead?: string;
  numPeriodsBehind?: string;
  forecastingScenarioIds?: any;
  forecastingGroupBy?: string;
  forecastingModel?: string;
  forecastingScaled?: boolean;
};

export type GoogleSheetsCustomSpendsReportInfo = BaseGoogleSheetsReportInfo & {
  reportType: 'customSpends';
};

export type AnyGoogleSheetsReportInfo =
  | GoogleSheetsMetricsReportInfo
  | GoogleSheetsForecastReportInfo
  | GoogleSheetsCustomSpendsReportInfo;

const editableFields = ['title'];

function sanitizeUpdate(report: any) {
  return editableFields.reduce(
    (acc, field) => {
      acc[field] = report[field];
      return acc;
    },
    { id: report.id, shopId: report.shopId },
  );
}

export const createGoogleSheetsReport = (
  obj: AnyGoogleSheetsReportInfo,
  opt?: { retry: boolean },
) =>
  async function (dispatch, getState) {
    const shopId = $currentShopId.get();
    try {
      dispatch({
        type: GOOGLE_SHEETS_REPORT_SAVING,
        report: { ...obj, shopId, building: true },
      });
      const url = opt?.retry ? 'v2/google-sheets/retry-new-report' : 'v2/google-sheets/new-report';
      const request = axiosInstance.post(
        url,
        {
          ...obj,
          shopId,
        },
        { timeout: 0 }, // don't timeout
      );
      const response = (await request).data;
      const newReport = await db().collection('reports').doc(response.reportId).get();
      dispatch({
        type: GOOGLE_SHEETS_REPORT_SAVED,
        report: { ...newReport.data() },
      });
    } catch (e) {
      // TODO: improve this, it will be infuriating UI
      console.error('Error creating report', e);
      toast.error('There was an error creating the report, please try again!');
    } finally {
      dispatch(loadGoogleSheetsReports());
    }
  };

export const editReportInfo = (report: AnyGoogleSheetsReportInfo) =>
  async function (dispatch, getState) {
    try {
      dispatch({
        type: GOOGLE_SHEETS_REPORT_UPDATING,
        report: { ...report },
      });
      await axiosInstance.post('/v2/google-sheets/edit-report-info', sanitizeUpdate(report));
      const updatedReport = await db().collection('reports').doc(report.id).get();
      dispatch({
        type: GOOGLE_SHEETS_REPORT_UPDATED,
        report: { ...updatedReport.data() },
      });
      toast.success('Successfully updated the report!');
    } catch (e) {
      console.error('Error updating report info', e);
      toast.error('There was an error updating the report, please try again!');
    }
  };

export const deleteGoogleSheetsReport = (report) =>
  async function (dispatch, getState) {
    try {
      await axiosInstance.post('/v2/google-sheets/delete-report', {
        reportId: report.id,
        sheetsAccount: report.sheetsAccount,
        shopId: $currentShopId.get(),
      });
      dispatch({
        type: GOOGLE_SHEETS_REPORT_DELETED,
        reports: getState().googleSheetsReports.filter((r) => r.id !== report.id),
      });
    } catch (e) {
      toast.error('There was an error deleting the report, please try again!');
    }
  };

export const exportCsvToGoogleSheets = (data) =>
  async function (dispatch: AppDispatch, getState: () => RootState) {
    const { currentShopId } = getState();
    try {
      dispatch({ type: GOOGLE_SHEETS_CSV_SAVING, payload: true });
      if (!data.sheetsAccount) {
        toast.error('No Google Sheets account selected!');
        dispatch({ type: GOOGLE_SHEETS_CSV_SAVING, payload: false });
        return;
      }
      const res = await axiosInstance.post('/v2/google-sheets/export-csv-to-google-sheets', {
        ...data,
        shopId: currentShopId,
      });
      toast.success('The data was successfully exported to Google Sheets!');
      dispatch({ type: GOOGLE_SHEETS_CSV_SAVED, payload: res.data.url });
      dispatch({
        type: GOOGLE_SHEETS_BANNER_METADATA,
        payload: {
          text: 'Click here to view your Google Sheet',
          link: res.data.url,
          bannerTitle: 'Success!',
          showBanner: true,
          status: 'success',
        },
      });
      return res;
    } catch (e) {
      console.log('error exporting >>', e);
      dispatch({ type: GOOGLE_SHEETS_CSV_SAVED, payload: '' });
      dispatch({
        type: GOOGLE_SHEETS_BANNER_METADATA,
        payload: {
          text: 'There was an error exporting the data, please try again!',
          link: '',
          bannerTitle: 'Error',
          showBanner: false,
          status: 'critical',
        },
      });
      toast.error('There was an error exporting the data, please try again!');
    } finally {
      dispatch({ type: GOOGLE_SHEETS_CSV_SAVING, payload: false });
    }
  };

interface GoogleSheetsBannerState {
  text: string;
  link: string;
  bannerTitle: string;
  showBanner: boolean;
  status: BannerStatus;
}

type GoogleSheetsBannerAction = {
  type: string;
  payload?: GoogleSheetsBannerState;
};

const googleSheetsBannerMetadata = (
  state: GoogleSheetsBannerState = {
    text: '',
    link: '',
    bannerTitle: '',
    showBanner: false,
    status: 'info',
  },
  action: GoogleSheetsBannerAction,
): GoogleSheetsBannerState => {
  switch (action.type) {
    case INIT_SHOP:
      return { ...state, showBanner: false, link: '' };
    case GOOGLE_SHEETS_BANNER_METADATA:
      return action.payload || state;
    default:
      return state;
  }
};

const isGoogleSheetsConnected = (state = false, action) => {
  switch (action.type) {
    case INIT_SHOP:
      let connected = false;
      if (action.googleSheetsAccessToken && !action.googleSheetsAccessToken.disconnected) {
        connected = true;
      }
      if (action.googleSheetsAccounts?.length) {
        connected = true;
      }
      return connected;
    case GOOGLE_SHEETS_DISCONNECT_DONE:
      return false;
    case GOOGLE_SHEETS_REMOVE_ACCOUNT_DONE:
      return !!action.googleSheetsAccounts?.length;
    default:
      return state;
  }
};

export type GSAccountInfo = {
  id: string;
  name: string;
  picture?: string;
  accessToken: any;
  disconnected?: boolean;
};
const googleSheetsAccounts = (state = [] as GSAccountInfo[], action) => {
  switch (action.type) {
    case INIT_SHOP:
      return action.googleSheetsAccounts ?? state;
    case GOOGLE_SHEETS_REMOVE_ACCOUNT_DONE:
      return action.googleSheetsAccounts ?? state;
    default:
      return state;
  }
};

const activeGoogleSheetsAccounts = (state = [] as GSAccountInfo[], action) => {
  if (![INIT_SHOP, GOOGLE_SHEETS_REMOVE_ACCOUNT_DONE].includes(action.type)) return state;
  return (action.googleSheetsAccounts ?? []).filter((a) => a?.disconnected !== true);
};

export const removeGoogleSheetsAccount = (id) => async (disatch, getState) => {
  toggleSaveIndicator();
  const { googleSheetsAccounts } = getState();
  if (id === 'legacy') {
    await db().update({
      googleSheetsAccessToken: { disconnected: true },
    });
  } else {
    genericEventLogger(analyticsEvents.GOOGLE_SHEETS, {
      action: googleSheetsActions.DISCONNECT,
      google_account: googleSheetsAccounts.find((a) => a.id === id)?.email,
    });
    const accounts = googleSheetsAccounts
      .filter((a) => a.id !== id)
      .filter((a) => a.id !== 'legacy');
    await db().update({
      googleSheetsAccounts: accounts,
    });
  }
  disatch({
    type: GOOGLE_SHEETS_REMOVE_ACCOUNT_DONE,
    googleSheetsAccounts: googleSheetsAccounts.filter((a) => a.id !== id),
  });
};

const TOGGLE_GOOGLE_SHEETS_SETTINGS_OPEN = 'TOGGLE_GOOGLE_SHEETS_SETTINGS_OPEN';
export const GOOGLE_SHEETS_SETTINGS_CLOSE = 'GOOGLE_SHEETS_SETTINGS_CLOSE';
export const toggleGoogleSheetsSettingsOpen = () => (dispatch, getState) => {
  dispatch({ type: TOGGLE_GOOGLE_SHEETS_SETTINGS_OPEN });
};

export const updateLegacyReportAccount = (reportId, sheetsAccount) => {
  return async (dispatch, getState) => {
    const { currentShopId } = getState();
    try {
      const url = `v2/google-sheets/update-legacy-sync-account`;
      const res = await axiosInstance.post(url, {
        shopId: currentShopId,
        reportId,
        sheetsAccount,
      });
      dispatch(loadGoogleSheetsReports());
      toast.success('Successfully updated the report!');
    } catch (e) {
      toast.error('There was an error connecting to Google Sheets, please try again!');
    }
  };
};

const googleSheetsSharedUrl = (state = '', action) => {
  switch (action.type) {
    case GOOGLE_SHEETS_CSV_SAVED:
      return action.payload || '';
    case SHARE_REPORT_DONE:
      if (action.method === 'googleSheets') {
        return action.spreadsheetUrl || '';
      } else return state;
    default:
      return state;
  }
};

const loadingGoogleSheetsReports = (state = false, action) => {
  switch (action.type) {
    case LOADING_GOOGLE_SHEETS_REPORTS:
      return true;
    case LOADED_GOOGLE_SHEETS_REPORTS:
      return false;
    default:
      return state;
  }
};

const isSavingGoogleCSV = (state = false, action) => {
  switch (action.type) {
    case GOOGLE_SHEETS_CSV_SAVING:
      return action.payload;
    default:
      return state;
  }
};

const googleSheetsReports = (state = [], action) => {
  switch (action.type) {
    case LOADED_GOOGLE_SHEETS_REPORTS:
      return action.reports;
    case GOOGLE_SHEETS_REPORT_SAVING:
      return [action.report, ...state.filter((r: { id: string }) => r.id !== action.report.id)];
    case GOOGLE_SHEETS_REPORT_UPDATING:
      return state.map((r: { id: string }) => (r.id === action.report.id ? action.report : r));
    case GOOGLE_SHEETS_REPORT_SAVED:
      let rest = state.filter((r: { title: string }) => r.title !== action.report.title); // not guaranteed, but there's no id yet!
      return [action.report, ...rest];
    case GOOGLE_SHEETS_REPORT_DELETED:
      return action.reports || state;
    case GOOGLE_SHEETS_REPORT_UPDATED:
      return state.map((r: { id: string }) => (r.id === action.report.id ? action.report : r));
    default:
      return state;
  }
};

const googleSheetsReportError = (state = {}, action) => {
  switch (action.type) {
    case GOOGLE_SHEETS_REPORT_ERROR:
      return {
        ...state,
        report: action.report,
        error: action.error,
      };
    case GOOGLE_SHEETS_REPORT_SAVED:
      return {};
    default:
      return state;
  }
};

const googleSheetsReportLoadingError = (state = '', action) => {
  switch (action.type) {
    case GOOGLE_SHEETS_REPORT_LOADING_ERROR:
      return action.error;
    default:
      return state;
  }
};

const isGoogleSheetsSettingsOpen = (state = false, action) => {
  switch (action.type) {
    case TOGGLE_GOOGLE_SHEETS_SETTINGS_OPEN:
      return !state;
    case GOOGLE_SHEETS_SETTINGS_CLOSE:
      return false;
    default:
      return state;
  }
};

export const reducers = {
  activeGoogleSheetsAccounts,
  isGoogleSheetsConnected,
  isSavingGoogleCSV,
  googleSheetsBannerMetadata,
  googleSheetsSharedUrl,
  googleSheetsReports,
  loadingGoogleSheetsReports,
  googleSheetsReportError,
  googleSheetsReportLoadingError,
  googleSheetsAccounts,
  isGoogleSheetsSettingsOpen,
};
