import {
  $availablePlans,
  $isUserHasMobyPlanPreview,
  MOBY_PRODUCT_ID,
} from '$stores/willy/$subscription';
import { $unlockMobyModal, closeUnlockMobyModal } from '$stores/willy/$upgradePopupManager';
import { useComputedValue, useStoreValue } from '@tw/snipestate';
import { Button, Card, Flex, Icon, Modal, Text } from '@tw/ui-components';
import { useUpgradeButton } from 'components/UpgradePlan/components/useUpgradeButton';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';

type UnlockMobyPopupProps = {};

export const UnlockMobyPopup: React.FC<UnlockMobyPopupProps> = ({}) => {
  const hasPlanPlanPreview = useStoreValue($isUserHasMobyPlanPreview);

  const isSmall = useIsSmall();
  const { buttonText, action, linkText } = useUpgradeButton('unlock_moby_popup', true);
  const unlockMobyModal = useStoreValue($unlockMobyModal);
  const addons = useComputedValue($availablePlans, (x) => x.data?.addons || []);
  const mobyAddon = addons.find((addon) => addon.product_id === MOBY_PRODUCT_ID);

  return (
    <Modal.Root
      size="90vw"
      maw="1140px"
      centered
      opened={unlockMobyModal.opened}
      onClose={() => closeUnlockMobyModal()}
      p={0}
    >
      <Modal.Overlay />
      <Modal.Content mah="95vh" p={0}>
        <Modal.Body p={0}>
          <div
            style={{ paddingInline: hasPlanPlanPreview ? 150 : 30 }}
            className="relative flex justify-center p-[30px] lg:pb-0 flex-col items-center gap-5 unlock-moby overflow-y-scroll"
          >
            <div className="absolute top-[5px] right-[5px]">
              <Modal.CloseButton />
            </div>
            <div className="text-blue-600 uppercase font-semibold text-xl">
              {hasPlanPlanPreview ? 'Full Access' : 'Unlock Moby'}
            </div>

            <Text fz={isSmall ? 28 : 36} fw={700} color="gray.8" align="center">
              Meet Moby, your $99 AI-powered data analyst{' '}
              {/* {Math.floor(Number(mobyAddon?.price))} */}
            </Text>
            <Text fw="400" fz={16} maw={600} color="gray.6" align="center">
              Unlock the flexibility to simply ask for the data you need and build workflows to
              accelerate your growth.
            </Text>
            {hasPlanPlanPreview ? (
              <FullAccessCard />
            ) : (
              <div className="flex justify-center w-full mt-10 mb-10">
                {/* Commented out Limited Access box */}
                {/* <div className="w-full lg:w-1/2">
                  <Card withBorder w="100%">
                    <div className="flex flex-col gap-8">
                      <div className="flex flex-col gap-3">
                        <Text fw="700" fz={24} maw={600} color="gray.9">
                          Limited Access
                        </Text>
                        <Text fw="400" fz={16} maw={600} color="gray.6">
                          Basic insights and guidance to get you started.
                        </Text>
                      </div>
                      <div className="flex flex-col gap-2 mt-8">
                        <div className="text-blue-600 uppercase font-semibold text-xl">
                          What's included
                        </div>
                        <div className="w-full h-[1px] bg-gray-200"></div>
                        <div className="flex flex-col gap-5">
                          <div className="flex gap-5 items-center">
                            <Icon name="circle-check" color="named.6" size={17} />
                            <Text fw="400" fz={16} maw={600} color="gray.7" align={'start'}>
                              Make smarter decisions about marketing investments with more accurate
                              data.
                            </Text>
                          </div>
                          <div className="flex gap-5 items-center">
                            <Icon name="circle-check" color="named.6" size={17} />
                            <Text fw="400" fz={16} maw={600} color="gray.7" align={'start'}>
                              Get basic tips and strategies for your e-commerce store.
                            </Text>
                          </div>
                          <div className="flex gap-5 items-center">
                            <Icon name="circle-check" color="named.6" size={17} />
                            <Text fw="400" fz={16} maw={600} color="gray.7" align={'start'}>
                              Stay competitive with updates on industry trends and market changes.
                            </Text>
                          </div>
                          <div className="flex gap-5 items-center">
                            <Icon name="circle-check" color="named.6" size={17} />
                            <Text fw="400" fz={16} maw={600} color="gray.7" align={'start'}>
                              Uncover helpful insights to guide your business decisions and improve
                              campaigns.
                            </Text>
                          </div>
                          <div className="flex gap-5 items-center">
                            <Icon name="circle-check" color="named.6" size={17} />
                            <Text fw="400" fz={16} maw={600} color="gray.7" align={'start'}>
                              Get support through Moby for help center content.
                            </Text>
                          </div>
                        </div>
                      </div>
                      <Button variant="white" onClick={() => closeUnlockMobyModal()}>
                        Continue without Moby
                      </Button>
                    </div>
                  </Card>
                </div> */}
                <div className="w-full lg:w-1/2">
                  <Card w="100%" p={0} m={0}>
                    <div className="border-[3px] rounded-[6px] border-solid border-gray-200">
                      <Card>
                        <div className="flex flex-col gap-8 items-center">
                          <div className="flex flex-col gap-3 text-center">
                            <Text fw="700" fz={24} maw={600} color="gray.9">
                              Full Access
                            </Text>
                            <Text fw="400" fz={16} maw={600} color="gray.6">
                              Chat with your store’s data to uncover insights and turn them into
                              actionable reports, dashboards, and strategies.
                            </Text>
                          </div>
                          <div className="flex flex-col gap-2 mt-8 items-start">
                            <div className="text-blue-600 uppercase font-semibold text-xl">
                              What's included
                            </div>
                            <div className="w-full h-[1px] bg-gray-200"></div>
                            <div className="flex flex-col gap-5">
                              <div className="flex gap-5 items-start">
                                <Icon name="circle-check" color="named.6" size={17} />
                                <Text fw="400" fz={16} maw={600} color="gray.7" align="left">
                                  Uncover trends & opportunities in your data for faster
                                  decision-making.
                                </Text>
                              </div>
                              <div className="flex gap-5 items-start">
                                <Icon name="circle-check" color="named.6" size={17} />
                                <Text fw="400" fz={16} maw={600} color="gray.7" align="left">
                                  Save hours by generating reports instantly.
                                </Text>
                              </div>
                              <div className="flex gap-5 items-start">
                                <Icon name="circle-check" color="named.6" size={17} />
                                <Text fw="400" fz={16} maw={600} color="gray.7" align="left">
                                  Ask ad hoc business questions & get instant answers.
                                </Text>
                              </div>
                              <div className="flex gap-5 items-start">
                                <Icon name="circle-check" color="named.6" size={17} />
                                <Text fw="400" fz={16} maw={600} color="gray.7" align="left">
                                  Create engaging ad copy & marketing ideas — all in one platform.
                                </Text>
                              </div>
                              <div className="flex gap-5 items-start">
                                <Icon name="circle-check" color="named.6" size={17} />
                                <Text fw="400" fz={16} maw={600} color="gray.7" align="left">
                                  Generate inventory & product reports effortlessly.
                                </Text>
                              </div>
                            </div>
                          </div>
                          <Button
                            variant="primary"
                            onClick={() => {
                              action();
                              closeUnlockMobyModal();
                            }}
                          >
                            {buttonText}
                          </Button>
                          <div className={'text-center'}>
                            <Text color={'gray.6'}>{linkText}</Text>
                          </div>
                        </div>
                      </Card>
                    </div>
                  </Card>
                </div>
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};

const FullAccessCard = () => {
  return (
    <Card withBorder w="100%" mt="lg" mb="xl" p="lg">
      <Flex direction="column" gap="lg">
        <Flex direction="column">
          <Text color="gray.8" weight={600} size="lg">
            Get the answers you need
          </Text>
          <Text color="gray.6" weight={400} size="md">
            Moby understands your data. Want to see your LTV for customers coming from Meta? Just
            ask Moby.
          </Text>
        </Flex>
        <Flex direction="column">
          <Text color="gray.8" weight={600} size="lg">
            Build visualizations with Moby
          </Text>
          <Text color="gray.6" weight={400} size="md">
            Moby can build data visuals that you can customize and add to any dashboard.
          </Text>
        </Flex>
        <Flex direction="column">
          <Text color="gray.8" weight={600} size="lg">
            Chat with your dashboards
          </Text>
          <Text color="gray.6" weight={400} size="md">
            Access Moby within any report to get analysis, insights, and actionable recommendations.
          </Text>
        </Flex>
        <Flex direction="column">
          <Text color="gray.8" weight={600} size="lg">
            Get Data Forecasts
          </Text>
          <Text color="gray.6" weight={400} size="md">
            Build accurate forecasts with ease.
          </Text>
        </Flex>
        <Button mt="sm" variant="primary" fullWidth onClick={() => closeUnlockMobyModal()}>
          Start Chatting
        </Button>
      </Flex>
    </Card>
  );
};
