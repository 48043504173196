import Cookies from 'utils/Cookies';
import { userDb } from 'utils/DB';

const UI = 'ui';
const IN_APP_CONTEXT_BANNERS = 'inAppContextBanners';

const setInAppContextBannersInFirestore = (currentShopId: string, bannersObj: any): void => {
  if (!currentShopId) return;

  userDb().set(
    { shops: { [currentShopId]: { ui: { [IN_APP_CONTEXT_BANNERS]: bannersObj } } } },
    { merge: true },
  );
};

export const setInAppContextBanner = (
  feature: string,
  value: boolean,
  currentShopId: string,
): void => {
  const uiString = Cookies.get(UI);
  const uiObj = !uiString ? {} : JSON.parse(uiString);
  const bannersObj = uiObj?.[IN_APP_CONTEXT_BANNERS] || {};

  bannersObj[feature] = value;

  Cookies.set(
    UI,
    JSON.stringify({
      ...uiObj,
      [IN_APP_CONTEXT_BANNERS]: bannersObj,
    }),
  );

  setInAppContextBannersInFirestore(currentShopId, bannersObj);
};

export const getInAppContextBanner = async (
  feature: string,
  currentShopId: string,
): Promise<boolean> => {
  const uiString = Cookies.get(UI);
  const uiObj = !uiString ? {} : JSON.parse(uiString);
  let bannersObj = uiObj?.[IN_APP_CONTEXT_BANNERS] || null;

  if (!bannersObj) {
    const userData = (await userDb().get()).data();
    bannersObj = userData?.shops?.[currentShopId]?.ui?.[IN_APP_CONTEXT_BANNERS] || {};
  }

  const result = bannersObj[feature] === undefined ? true : bannersObj[feature];
  return result;
};

export const clearInAppContextBanner = (currentShopId: string) => {
  setInAppContextBannersInFirestore(currentShopId, {});
};

type InAppContextBannersMapValue = {
  title: string;
  description?: string;
  lightBackground?: boolean;
  demoLink?: boolean;
  mobileBackground: string;
  desktopBackground: string;
  howItWorksLink: string;
  padding: number;
  knowledgeBaseLink?: string;
};

export const IN_APP_CONTEXT_BANNERS_DATA: Record<string, InAppContextBannersMapValue> = {
  summary: {
    title: 'Summary',
    description:
      'View a real-time snapshot of your business performance by centralizing important metrics from all of your connected systems',
    lightBackground: false,
    demoLink: true,
    mobileBackground: '/inAppContextBanners/summary_m.jpg',
    desktopBackground: '/inAppContextBanners/summary.jpg',
    howItWorksLink: 'https://www.youtube.com/embed/fpR7hvvgV0s',
    padding: 0,
  },
  attribution: {
    title: 'Attribution',
    description: 'Understand the effectiveness of all your marketing channels.',
    lightBackground: false,
    demoLink: false,
    mobileBackground: '/inAppContextBanners/attribution_m.jpg',
    desktopBackground: '/inAppContextBanners/attribution.jpg',
    howItWorksLink: 'https://www.youtube.com/embed/56vPaIAeYlw',
    padding: 2,
  },
  attributionSettings: {
    title: 'Pixel Settings',
    description: 'Configure, verify, and troubleshoot your Triple Pixel installation.',
    lightBackground: false,
    demoLink: false,
    mobileBackground: '/inAppContextBanners/attribution_settings_m.jpg',
    desktopBackground: '/inAppContextBanners/attribution_settings.jpg',
    howItWorksLink: 'https://www.loom.com/embed/567387a1b8c14ef78de832cc1c0e2a43',
    padding: 5,
  },
  creativeCockpit: {
    title: 'Creative Cockpit',
    description:
      'Generate real-time creative reports of individual ads, ad groups or creative types.',
    lightBackground: false,
    demoLink: false,
    mobileBackground: '/inAppContextBanners/creative_analysis_m.jpg',
    desktopBackground: '/inAppContextBanners/creative_analysis.jpg',
    howItWorksLink: 'https://www.youtube.com/embed/8-emYwNNdL8',
    padding: 0,
  },
  creativeHub: {
    title: 'Creative Hub',
    description: 'A centralized place to store all of your creative assets',
    lightBackground: false,
    demoLink: false,
    mobileBackground: '/inAppContextBanners/creative_hub_m.jpg',
    desktopBackground: '/inAppContextBanners/creative_hub.jpg',
    howItWorksLink: 'https://www.loom.com/embed/3423fb97cacd4ff7a2e6aab2c30d3a41',
    padding: 0,
  },
  productAnalytics: {
    title: 'Product Analytics',
    description:
      'Make better merchandising decisions by understanding true profitability and stock level at the SKU, product, and category level',
    lightBackground: false,
    demoLink: false,
    mobileBackground: '/inAppContextBanners/product_analytics_m.jpg',
    desktopBackground: '/inAppContextBanners/product_analytics.jpg',
    howItWorksLink: 'https://www.youtube.com/embed/AnVZaQZu7H8',
    padding: 5,
  },
  segmentBuilder: {
    title: 'Segment Builder',
    description:
      'Define customer segments to create personalized experiences, targeted communications, and high yield audiences',
    lightBackground: false,
    demoLink: false,
    mobileBackground: '/inAppContextBanners/segment_builder_m.jpg',
    desktopBackground: '/inAppContextBanners/segment_builder.jpg',
    howItWorksLink: 'https://www.loom.com/embed/ef394d3c537f4f7484fc49c1654a528f',
    padding: 0,
  },
  cost: {
    title: 'Cost Settings',
    description:
      'Enhance the accuracy of Net Profit by entering costs that are not directly connected to ad channels.',
    lightBackground: false,
    demoLink: false,
    mobileBackground: '/inAppContextBanners/cost_m.jpg',
    desktopBackground: '/inAppContextBanners/cost.jpg',
    howItWorksLink: 'https://www.youtube.com/embed/zYDZr5fXQB8',
    padding: 0,
  },
  cohorts: {
    title: 'Retention',
    description: 'Track customer retention and spend over time.',
    lightBackground: false,
    demoLink: false,
    mobileBackground: '/inAppContextBanners/cohorts_m.jpg',
    desktopBackground: '/inAppContextBanners/cohorts.jpg',
    howItWorksLink: 'https://www.youtube.com/embed/zDR6Og4sdRw',
    padding: 5,
  },
  utmBuilder: {
    title: 'Tracking Settings',
    description: 'Set up proper tracking for your ads on each marketing platform.',
    lightBackground: false,
    demoLink: false,
    mobileBackground: '/inAppContextBanners/utm_builder_m.jpg',
    desktopBackground: '/inAppContextBanners/utm_builder.jpg',
    howItWorksLink: 'https://www.youtube.com/embed/FlLeTO4Ogho',
    padding: 5,
  },
  adBuilder: {
    title: 'Ad Builder',
    description: 'Use generative AI to rapidly create and deploy new ads.',
    lightBackground: false,
    demoLink: false,
    mobileBackground: '/inAppContextBanners/ad_builder_m.jpg',
    desktopBackground: '/inAppContextBanners/ad_builder.jpg',
    howItWorksLink: 'https://www.loom.com/embed/736083000f9942dca7ec277d62e4c8f1',
    padding: 5,
  },
  productJourney: {
    title: 'Product Journey',
    description: 'Discover the products that your customers purchase in repeat orders.',
    lightBackground: false,
    demoLink: false,
    mobileBackground: '/inAppContextBanners/product_journey_m.jpg',
    desktopBackground: '/inAppContextBanners/product_journey.jpg',
    howItWorksLink: 'https://www.loom.com/embed/b3afae26f2b645b9a5f14b362e5f46c5',
    padding: 5,
  },
  reports: {
    title: 'Reports',
    description:
      'Build and manage custom summary reports and schedule delivery to your preferred channel.',
    lightBackground: false,
    demoLink: false,
    mobileBackground: '/inAppContextBanners/reports_m.jpg',
    desktopBackground: '/inAppContextBanners/reports.jpg',
    howItWorksLink: 'https://www.loom.com/embed/025fd3762d7d4e51a91820164f037d3d',
    padding: 5,
  },
  cartAnalysis: {
    title: 'Cart Analysis',
    mobileBackground: '/inAppContextBanners/cart_analysis_m.jpg',
    desktopBackground: '/inAppContextBanners/cart_analysis.jpg',
    description:
      'See what products customers are buying at the same time in your store. Understand what upsell and cross-sell opportunities you have on your product pages, your cart pages, and even in your post-purchase upsells.',
    howItWorksLink: 'https://www.youtube.com/embed/TZXK5NdUJrM',
    padding: 5,
  },
  benchmarks: {
    title: 'Benchmarks',
    lightBackground: false,
    demoLink: false,
    description:
      'Access the largest aggregated set of DTC real-time attribution data and see how your brand and marketing strategy are pacing against stores just like yours.',
    mobileBackground: '/inAppContextBanners/summary_m.jpg',
    desktopBackground: '/inAppContextBanners/summary.jpg',
    howItWorksLink: 'https://www.youtube.com/embed/Zgyi0BgUGNY',
    padding: 5,
  },
  trafficRules: {
    title: 'Traffic Rules',
    lightBackground: false,
    demoLink: false,
    description:
      'Use Traffic Rules to permanently override the Triple Whale attribution logic. Rules will be run in a sequence from top to bottom. If the conditions of a rule are met, its action will be performed and the rest of the rules in the sequence will not be evaluated. ',
    mobileBackground: '/inAppContextBanners/attribution_m.jpg',
    desktopBackground: '/inAppContextBanners/attribution.jpg',
    howItWorksLink: 'https://www.youtube.com/embed/uOAeM8hDBdw',
    padding: 5,
  },
  forecasting: {
    title: 'Planner',
    lightBackground: false,
    demoLink: false,
    description:
      'Where the front end and back end of your business come together to form holistic plans.',
    mobileBackground: '/inAppContextBanners/forecasting_m.png',
    desktopBackground: '/inAppContextBanners/forecasting.png',
    howItWorksLink: 'https://www.youtube.com/embed/rTiJgiXT25k?si=AHJwOQdHOZRtkuck',
    knowledgeBaseLink:
      'https://kb.triplewhale.com/en/articles/8201950-introducing-triple-whale-s-financial-planning-analysis-tool',
    padding: 5,
  },
  // customerJourneys: {
  //   title: 'Customer Journeys',
  //   description: '',
  //   lightBackground: false,
  //   demoLink: false,
  //   mobileBackground: '/inAppContextBanners/customer_journeys_m.jpg',
  //   desktopBackground: '/inAppContextBanners/customer_journeys.jpg',
  //   howItWorksLink: '',
  //   padding: 5
  // }
};
