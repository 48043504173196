import { Button, Flex, Icon, Menu, Text } from '@tw/ui-components';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';
import { FC, useState } from 'react';

type DateRangeOptionsListProps = {
  options: {
    label: string | JSX.Element;
    value: string;
    selected: boolean;
    onClick: () => void;
  }[];
};
export const DateRangeOptionsList: FC<DateRangeOptionsListProps> = ({ options }) => {
  const isSmall = useIsSmall();
  const [openPopover, setOpenPopover] = useState(false);

  return isSmall ? (
    <div className="p-6.5">
      <Menu
        opened={openPopover}
        width="target"
        withinPortal={false}
        onClose={() => {
          setOpenPopover(false);
        }}
      >
        <Menu.Target>
          <span className="w-full">
            <Button
              variant="activator"
              fullWidth
              justify="space-between"
              rightSection="caret-down"
              onClick={() => {
                setOpenPopover((prev) => !prev);
              }}
            >
              {options.find((option) => option.selected)?.label ?? 'Select Range'}
            </Button>
          </span>
        </Menu.Target>
        <Menu.Dropdown>
          <div className="flex flex-col max-h-[300px] overflow-y-auto">
            {options.map((option) => (
              <Menu.Item
                rightSection={option.selected && <Icon name="checkmark" color="one.6" size={16} />}
                onClick={option.onClick}
                bg={option.selected ? 'gray.2' : undefined}
              >
                {option.label}
              </Menu.Item>
            ))}
          </div>
        </Menu.Dropdown>
      </Menu>
    </div>
  ) : (
    <div className="flex-[1] flex flex-col overflow-auto p-6.5 border border-solid border-[var(--mantine-color-gray-2)] border-l-0 border-t-0 border-b-0">
      {options.map((option) => (
        <Flex
          key={option.value}
          align="center"
          justify="space-between"
          gap="lg"
          onClick={option.onClick}
          cursor="pointer"
          p="xs"
          bg={option.selected ? 'gray.1' : undefined}
          hoverBg="gray.2"
          borderRadius="6px"
        >
          <Text size="sm" color="gray.8">
            {option.label}
          </Text>
          {option.selected && <Icon name="checkmark" color="one.6" size={16} />}
        </Flex>
      ))}
    </div>
  );
};
