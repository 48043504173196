import { TW_SUMMARY_PIXEL_COLUMNS } from 'constants/attribution';
import { useAppDispatch } from 'index';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { type RootState } from 'reducers/RootType';
import { SummarySection as Section } from 'types/SummarySection';
import { getDefaultColumns } from 'utils/getDefaultColumns';
import { selectAllSections } from 'utils/selectors';
import { Banner, Layout, Page } from '@shopify/polaris';
import SummarySection from '../components/SummarySection';
import {
  getConversionRate,
  getStatsLtv,
  hideCustomSpendsBanner,
  loadingCustomSpends,
  summaryRefresh,
} from '../ducks/actions';
import { SummaryContext, SummaryContextProps } from '../ducks/summary';
import allPixelColumns from 'components/attribution-new/columns';
import { AffluencerColumn, PixelColumn } from 'types/attribution';
import { dispatchPixelStats, getNewStatsForAllServices } from '../ducks/newStats';
import UpgradeBar from 'components/library/LockedFeatures/UpgradeBar';
import { useActiveOrderSegment } from '../utils/useActiveOrderSegment';
import { useAttributionParams } from 'utils/useAttributionParam';
import { loadShopifySourcesDictionary } from '../ducks/shopify/shopifySegmentSources';
import { InAppContextBanner } from 'components/InAppContextBanner';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { useFeatureFlagSummarySectionFilter } from 'hooks/useFeatureFlagSummarySectionFilter';
// import { getBenchmarksStats } from 'ducks/businessIntelligence';
import { toggleLauncherVisibility } from 'utils/intercom';
import { useEnterpriseUpgradeCampaign } from 'hooks/useEnterpriseUpgradeCampaign';
import { fetchWillySummary } from '../ducks/willy';
import { isInIframe } from '../config';
import { getAllSensoryNewStats } from '../ducks/sensory';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';
import { shopIntegrations } from '../ducks/shopIntegrations';
import { ServicesIds } from '@tw/types/module/services';
import { ShopIntegrationProperties } from '@tw/types/module/types/ShopProviders';
import { BENCHMARKS_SECTION } from '../constants/defaultTileSections';
import {
  $currency,
  $shopCurrency,
  $useClickHouseInSummary,
  $useSummaryWillyWay,
  $forceSharded,
} from '../$stores/$shop';
import { useStoreValue } from '@tw/snipestate';
import { $trialBannerPackage } from '$stores/willy/$upgradePopupManager';
import MetricPopup from '../components/MetricPopup';
import { TilesSectionSettingsModal } from '../components/TilesSectionSettingsModal';

export const SummaryPage = ({ onlyBenchmarks = false }) => {
  const [pixelColumns, setPixelColumns] = useState<(PixelColumn | AffluencerColumn)[]>(
    getDefaultColumns(TW_SUMMARY_PIXEL_COLUMNS, allPixelColumns()),
  );
  useActiveOrderSegment();

  // TODO: Remove this hook when we no longer have this upgrade campaign
  useEnterpriseUpgradeCampaign();
  const useClickHouseInSummary = useStoreValue($useClickHouseInSummary);
  const useWillyWay = useStoreValue($useSummaryWillyWay);
  const forceSharded = useStoreValue($forceSharded);
  const sensoryIntegrationsLoaded = useSelector(
    (state: RootState) => state.sensory.sensoryIntegrationsLoaded,
  );
  const integrations = useSelector(shopIntegrations);
  const customSpendsFlag = useSelector((state: RootState) => state.customSpendsFlag);
  const hideCustomSpendBanner = useSelector((state: RootState) => state.hideCustomSpendBanner);
  const summaryFullWidth = useSelector((state: RootState) => state.summaryFullWidth);
  const attributionParams = useAttributionParams();
  const { summarySectionsFilter } = useFeatureFlagSummarySectionFilter();

  const dispatch = useAppDispatch();

  const mainDatePickerSelectionRange = useSelector(
    (state: RootState) => state.mainDatePickerSelectionRange,
  );
  const customSpends = useSelector((state: RootState) => state.customSpends);
  const msp = useSelector((state: RootState) => state.msp);
  const currency = useStoreValue($currency);
  const shopCurrency = useStoreValue($shopCurrency);
  const currentShopId = useSelector((state: RootState) => state.currentShopId);
  const initialSections: Section[] = useSelector(selectAllSections);
  const groupStatsBy = useSelector((state: RootState) => state.groupStatsBy);

  const sections = useMemo(
    () => initialSections.filter(summarySectionsFilter),
    [initialSections, summarySectionsFilter],
  );

  const { industry, aovSegment, spendSegment } =
    useSelector((state: RootState) => state.benchmarks) || {};

  useEffect(() => {
    const { start, end } = mainDatePickerSelectionRange || {};
    if (!start || !end) {
      return;
    }

    if (!sensoryIntegrationsLoaded) {
      return;
    }

    if (msp == 'shopify') {
      dispatch(loadShopifySourcesDictionary());
    }
    // dispatch(getShopifySegmentsStats(true));
    // else {
    //   dispatch(calculateCogsOnServer());
    // }
    dispatch(getNewStatsForAllServices());
    dispatch(fetchWillySummary(currentShopId, 'tile'));
    dispatch(fetchWillySummary(currentShopId, 'widget'));
    // dispatch(getBenchmarksStats());

    //dispatch(getStatsSamePeriodComparison());
    dispatch(summaryRefresh());

    if (currency) dispatch(getConversionRate(shopCurrency, currency));
  }, [
    mainDatePickerSelectionRange,
    dispatch,
    sensoryIntegrationsLoaded,
    integrations,
    currency,
    useClickHouseInSummary,
    useWillyWay,
    forceSharded,
    shopCurrency,
    currentShopId,
    groupStatsBy,
    msp,
  ]);

  useEffect(() => {
    if (!attributionParams) return;
    dispatch(dispatchPixelStats(attributionParams));
  }, [dispatch, attributionParams, groupStatsBy]);

  const summaryContextProps: SummaryContextProps = {
    industry,
    aovSegment,
    spendSegment,
    pixelColumns,
    currency,
    setPixelColumns,
  };

  const isFromMobileApp = useSelector((state: RootState) => state.mobileApp.isFromMobileApp);
  const isSmall = useIsSmall();
  const { show: showTrialBanner } = useStoreValue($trialBannerPackage);

  return (
    <>
      {onlyBenchmarks && (
        <div className="tw-page-wrapper p-8 sm:p-0">
          <Page fullWidth={summaryFullWidth}>
            <InAppContextBanner page="benchmarks" />
            <SummarySection sections={BENCHMARKS_SECTION} type="summary" />
          </Page>
        </div>
      )}
      {!onlyBenchmarks && (
        <SummaryContext.Provider value={summaryContextProps}>
          <div>
            {!isInIframe && (
              <>
                {!showTrialBanner && <UpgradeBar feature={FeatureFlag.LIMIT_LOOKBACK_FF} />}
                <div
                  style={
                    isSmall || isFromMobileApp
                      ? {}
                      : { paddingTop: '24px', paddingLeft: '24px', paddingRight: '24px' }
                  }
                >
                  <InAppContextBanner page="summary" />
                </div>
              </>
            )}
            <div className="summary-page-wrapper">
              <Page fullWidth={summaryFullWidth}>
                <div className="summary-wrapper">
                  <Layout sectioned>
                    <Layout.Section>
                      {customSpendsFlag && !hideCustomSpendBanner && (
                        <Banner
                          onDismiss={() => dispatch(hideCustomSpendsBanner())}
                          status="critical"
                        >
                          <p>
                            Due to changes in Custom Expenses, existing Custom Expenses that are
                            missing data will affect your Net Sales and other metrics. Please update
                            your custom expenses to include a start date, an end date, and if
                            recurring, choose a recurring cadence.
                          </p>
                          <p>
                            Sorry for the inconvenience! Please contact our CS team if you need any
                            help.
                          </p>
                        </Banner>
                      )}
                    </Layout.Section>
                    <SummarySection sections={sections} type="summary" />
                  </Layout>
                </div>
              </Page>
            </div>
          </div>
        </SummaryContext.Provider>
      )}
      <MetricPopup />
      <TilesSectionSettingsModal />
    </>
  );
};

export default SummaryPage;

export const IFrameSummary = () => <SummaryPage />;
