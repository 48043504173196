import { Size, UserSettingsPopover } from '@tw/ui-components';
import { useAppSelector } from 'reducers/RootType';
import { useUserInitials } from 'hooks/useUserInitials';
import { useSettingsConfig } from './useSettingsConfig';
import { $userId } from '../../../../../$stores/$user';
import { useStoreValue } from '@tw/snipestate';
import { baseURL } from '../../../../../config';

export interface ISettingsAvatar {
  size?: Size | number;
}

export const SettingsAvatar: React.FC<ISettingsAvatar> = ({ size }) => {
  const initials = useUserInitials();
  const config = useSettingsConfig();
  const userId = useStoreValue($userId);
  return (
    <div id="user-settings-popover">
      <UserSettingsPopover
        targetSize={size}
        avatarSource={`${baseURL}/v2/media/profile-image/${userId}`}
        initials={initials}
        popoverLinks={config}
      />
    </div>
  );
};
