import { uniqBy } from 'lodash';
import {
  $derived,
  $effect,
  $mutableDerived,
  $mutableObserver,
  $observer,
  $store,
} from '@tw/snipestate';
import {
  Dialect,
  DialectWithBoth,
  WillyBaseMainElement,
  WillyDashboardElement,
  WillyDashboardVersionElement,
  WillyFieldElement,
  WillyMetric,
  WillySection,
  WillyWidgetElement,
} from './types/willyTypes';
import {
  $globalDashboardIds,
  $globalDashboards,
  $loadingGlobalDashboards,
} from '$stores/willy/$globalDashboards';
import { $dashboardIds, $shopDashboards, $shopDashesLoading } from '$stores/willy/$shopDashboards';
import { $currentShopId } from '$stores/$shop';
import { $isAdminClaim, $userId } from '$stores/$user';
import _db, { firestoreRef, toArray } from 'utils/DB';
import firebase from 'firebase/compat';
import { getFieldImage } from './utils/dashImageCrud';
import { $pathname, $search } from '$stores/$location';
import { $redux } from '$stores/$redux';
import { ReactComponent as TemplatesIcon } from 'components/Icons/templates.svg';
import { ReactComponent as MyWorkspace } from 'components/Icons/yours.svg';
import { $ffStore } from 'feature-flag-system';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { $customViews, $customViewsIds, $customViewsLoading } from '$stores/willy/$customViews';
import { createCharifStore } from '$stores/$charif';
import { getLastViewedDashboard, saveLastViewedDashboard } from './utils/lastViewedDashboard';
import { $combinedDashboard, $combinedDashboardsMap } from '$stores/willy/$combinedDashboards';
import { $shopWithSensory } from '$stores/$shopWithSensory';
import {
  getCustomViewDashboardCollectionRef,
  updateDashboardWidget,
  updateMainElement,
} from './utils/willyUtils';
import { services } from '@tw/types/module/services';
import { $favoriteDashboards } from '$stores/willy/$favoriteDashboards';
import moment from '@tw/moment-cached';
import { pushFullDateToQS } from '$stores/willy/$dateRange';
import { $navigate } from '$stores/$navigate';
import QueryString from 'qs';
import { $shopSequencesRaw } from '$stores/willy/$sequences';
import { emptyArray } from 'utils/emptyArray';
import { getDashPermsManager } from './dashboardManagment/permissions-management/DashboardPermissionsManager';
import { DEFAULT_DIALECT } from './constants';
import { $globalConfig } from '$stores/$globalConfig';
import { emptyObject } from 'utils/emptyObject';
import { NavSection, NavSectionRoute } from 'constants/routes/types';
import { isLocal, isProd } from '@tw/constants';
import { getDatePickerOptionValueOptions } from 'components/useDatePickerSelectedOptions';
import { getDatePickerCompareOptionsValue } from 'components/useDatePickerCompareOptions';

export const leftTabs = [
  {
    value: 'shop',
    label: 'My Workspace',
    get link() {
      const savedId = getLastViewedDashboard();
      const firstShopDashId = $dashboardIds.get()[0];
      // if id is global, get first shop dashboard id instead
      const id =
        savedId && !$globalDashboardIds.get().has(savedId)
          ? savedId
          : firstShopDashId || ':dashboard';
      return `/dashboards/${id}${window.location.search}`;
    },
    icon: <MyWorkspace fill="inherit" width={16} />,
  },
  {
    value: 'global',
    label: 'Templates',
    get link() {
      return `/templates${window.location.search}`;
    },
    icon: <TemplatesIcon fill="inherit" width={18} />,
  },
] as const;

export const rightTabs = [
  { value: 'analytics', label: 'Analytics' },
  { value: 'history', label: 'Updates' },
] as const;

export const defaultLeftTab: (typeof leftTabs)[0]['value'] = 'shop';
export const defaultRightTab: (typeof rightTabs)[0]['value'] = 'analytics';

// trigger resize event -> trigger RGL
export const triggerLayout = () => window.dispatchEvent(new Event('resize'));

class DashboardStores {
  public constructor() {
    $effect((_, get) => {
      const doc = get(this.$dashboardDoc);
      if (!doc) return;

      const unsubSnapshot = doc.onSnapshot((snapshotData) => {
        this.$dashboardSnapshotData.set(snapshotData);
      });
      this.cleanupQueue.add(unsubSnapshot);

      return () => {
        unsubSnapshot();
        this.cleanupQueue.delete(unsubSnapshot);
      };
    });

    // update all elements of the dash whenever $dashboardDoc updates
    $effect((_unsub, get) => {
      const doc = get(this.$dashboardSnapshotData);
      if (!doc) return;

      const isGlobal = get(this.$isGlobal);
      const dashboardId = get(this.$dashboardId);
      const isLocked = get(this.$isLocked);
      const canEdit = get(this.$canEdit);
      const canView = get(this.$canView);
      const canDelete = get(this.$canDelete);
      const canEditPerms = get(this.$canEditPerms);
      const isProviderLocked = get(this.$isProviderLocked);
      const isCustomView = get(this.$isCustomView);
      const favoriteDashboards = get($favoriteDashboards);

      try {
        const d = doc.data() as WillyDashboardElement;
        const dashboard: WillyDashboardElement = {
          ...d,
          id: doc.id,
          isGlobal,
          isLocked,
          isProviderLocked,
          type: 'dashboard',
          canEdit,
          canView,
          canDelete,
          canEditPerms,
          currentDateRange: d.currentDateRange
            ? {
                ...d.currentDateRange,
                start: moment(d.currentDateRange.start),
                end: moment(d.currentDateRange.end),
              }
            : undefined,
          prevDateRange: d.prevDateRange
            ? {
                ...d.prevDateRange,
                start: moment(d.prevDateRange.start),
                end: moment(d.prevDateRange.end),
              }
            : undefined,
          isFavorite: favoriteDashboards.includes(doc.id),
          isCustomView,
        };
        if (!dashboard || (!dashboard?.id && !dashboardId)) return;

        this.$dashboard.set(dashboard);

        if (dashboard?.layout) {
          try {
            const layoutDoc = JSON.parse(dashboard.layout);
            if (layoutDoc.length > 0) {
              let ld: ReactGridLayout.Layout[] = JSON.parse(JSON.stringify(layoutDoc));
              this.$layout.set(ld);
            }
            // don't care about error here
          } catch {}
        }

        triggerLayout();
        this.$loading.set(false);
      } catch {}
    });

    $effect((_, get) => {
      const dashboardId = get(this.$dashboardId);
      if (!dashboardId || get($loadingGlobalDashboards)) return;

      if (!get($globalDashboardIds).has(dashboardId)) {
        saveLastViewedDashboard(dashboardId || '');
      }
    });

    $effect((_, get) => {
      if (!get(this.$dashboardId)) return;
      this.$editLayout.set(false);
    });

    // always cleanup when dashboardId changes
    $effect((_, get, timesRun) => {
      get(this.$dashboardId);
      if (!timesRun) return;

      this.$loading.set(true);
      this.$loadingWidgets.set(true);
      this.$loadingFields.set(true);
      this.$dashboard.set(undefined);
      this.$widgets.set([]);
      this.$fields.set([]);
      this.$images.set({});
      this.$layout.set([]);
    });

    // at some point we should be able to remove this
    // and use /dashboard-images only
    // but for now we need to keep this for backwards compatibility
    $effect(async (_, get) => {
      const fields = get(this.$fields);
      const newImages: any = {};
      for (const field of fields) {
        if (!field.id || field.content?.length <= 0 || field.type !== 'image') continue;

        try {
          newImages[field.id] = await getFieldImage(field.content);
        } catch (e) {}
      }

      this.$images.set(newImages);
    });
  }

  /**
   * Necessary to add unsub functions, so when the snapshots some stores are
   * dependent on aren't in use, we make sure to call "clear" and remove them all.
   */
  private cleanupQueue = new Set<() => void>();

  //
  // STORES
  //
  public readonly $dashboardSnapshotData =
    $store<firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData> | null>(null);

  public readonly $activeTab = $store<(typeof leftTabs)[number]['value']>(defaultLeftTab);

  private readonly $reportsSection = $derived(async () => {
    const { REPORTS_SUB_SECTION } = await import('constants/routes/configs/reports-subnav');
    return REPORTS_SUB_SECTION();
  });

  private readonly $dashboardsLoading = $derived((get) => {
    const shopDashesLoading = get($shopDashesLoading);
    const globalDashesLoading = get($loadingGlobalDashboards);
    const customViewsLoading = get($customViewsLoading);
    return shopDashesLoading || globalDashesLoading || customViewsLoading;
  });

  public readonly $standardDashboard = $derived<NavSectionRoute | null>((get) => {
    const url = get($pathname).match(/dashboards\/([^?]*)/)?.[1];
    if (!url) return null;

    const { pending, data: sections } = get(this.$reportsSection);
    if (pending) return null;

    const routes = sections?.flatMap((s) => s.routes);
    const standardDash = routes?.find((r) => `/${url}`.includes(r.url));
    return standardDash || null;
  });

  public readonly $dashboardId = $derived((get) => {
    const reportsSection = get(this.$reportsSection);
    if (reportsSection.pending) return;

    const pathname = get($pathname);
    const match = pathname.match(/\/dashboards\/(.*?)(\?|\#|$)/);
    if (!match || get(this.$dashboardsLoading)) return '';

    const dashboardId = match[1];
    if (dashboardId === 'new') return '';

    const navigate = $navigate.get();
    const search = window.location.search;
    if (dashboardId === ':dashboardId' || !dashboardId) {
      const newId = getLastViewedDashboard() || 'summary';
      navigate?.({ pathname: `/dashboards/${newId}`, search }, { replace: true });
      return newId;
    }

    if (
      !get(this.$standardDashboard) &&
      !get($globalDashboardIds).has(dashboardId) &&
      !get($dashboardIds).has(dashboardId) &&
      !get($customViewsIds).has(dashboardId)
    ) {
      navigate?.({ pathname: `/dashboards/summary`, search }, { replace: true });
      return 'summary';
    }

    return dashboardId;
  });

  public readonly $dashboardName = $derived((get) => get(this.$dashboard)?.name ?? null);

  public readonly $loading = $store(true);

  public readonly $loadingWidgets = $store(true);
  public readonly $loadingFields = $store(true);

  public readonly $editLayout = $store(false);

  public readonly $layout = $store<ReactGridLayout.Layout[]>([]);

  public readonly $dashboard = $store<WillyDashboardElement | undefined>(undefined);

  public readonly $isGlobal = $derived(
    (get) => !!get($globalDashboards).find((x) => x.id === get(this.$dashboardId)),
  );

  public readonly $isCustomView = $derived(
    (get) => !!get($customViews).find((x) => x.id === get(this.$dashboardId)),
  );

  private readonly $derivedDialect = $derived((get) => {
    const isGlobal = get(this.$isGlobal);
    const isShopDashboardFromGlobal = get(this.$isShopDashboardFromGlobal);

    if (isShopDashboardFromGlobal || isGlobal) {
      const dashDialect = get(this.$dashboard)?.dialect;
      return dashDialect ?? 'bigquery';
    } else {
      return 'both';
    }
  });

  private readonly $isEmailReport = $derived((get) => {
    const pathname = get($search);
    return pathname.includes('report=true');
  });

  public readonly $dashboardDialect = $mutableDerived<DialectWithBoth>((get) => {
    if (get(this.$isEmailReport)) return 'both';

    const globalConfig = get($globalConfig);
    const dashDialect = get(this.$derivedDialect);

    if (globalConfig.find((x) => x.id === 'forceBQDialect')?.state) {
      return 'bigquery';
    }
    return dashDialect;
  });

  public readonly $dashboardDoc = $derived((get) => {
    const userId = get($userId);
    const shopId = get($currentShopId);
    const id = get(this.$dashboardId);
    const standardDash = get(this.$standardDashboard);
    if (!userId || !shopId || !id || standardDash || id.includes('/')) return null;

    const isGlobal = get(this.$isGlobal);
    const globalDash = get($globalDashboards);
    const isCustomView = get(this.$isCustomView);
    if (isGlobal) {
      if (!globalDash.length) return null;
      return firestoreRef().collection('willy_global_dashboards').doc(id);
    }
    if (isCustomView) {
      return getCustomViewDashboardCollectionRef().doc(id);
    }
    return _db().collection('willy_dashboards').doc(id);
  });

  public readonly $images = $store<any>({});

  public readonly $isGlobalDashboardExistsInShop = $derived(
    (get) => !!get($shopDashboards).find((d) => d.globalDashboardId === get(this.$dashboardId)),
  );

  //relevant only when this.$dashboard is global
  public readonly $latestDashboardVersion = $derived((get) => {
    const latest = get(this.$dashboard)?.versions?.reduce((acc, version) => {
      if (moment(version.createdAt).isAfter(acc.createdAt)) return version;
      return acc;
    });
    return latest;
  });

  // public readonly $allDashboardsFromAGlobalDashboard = $derived((get) => {
  //   const globalDashboardId = get(this.$dashboardId);
  //   const shopDashboards = get($shopDashboards);
  //   return shopDashboards
  //     .filter((d) => d.globalDashboardId === globalDashboardId)
  //     .sort((a, b) => a.createdAt.toMillis() - b.createdAt.toMillis());
  // });
  //until here

  public readonly $shopDashboardFromGlobal = $derived((get) => {
    const id = get(this.$dashboardId);
    const shopDash = get($shopDashboards).find((d) => d.id === id);
    if (!shopDash) return;

    return get($globalDashboards).find((d) => d.id === shopDash.globalDashboardId) || null;
  });

  public readonly $isShopDashboardFromGlobal = $derived(
    (get) => !!get(this.$shopDashboardFromGlobal),
  );

  public readonly $permissions = createCharifStore<'view' | 'edit'>({
    resourceId: $derived((get) => get(this.$dashboardId) || ''),
    resourceType: 'dashboard',
    permissions: ['edit', 'view'],
  });

  public readonly $isLocked = $derived((get) => {
    const dashboardId = get(this.$dashboardId);
    const dashboards = get($combinedDashboard);
    if (!dashboardId || dashboards.length === 0) return false;
    return dashboards.find((d) => d.id === dashboardId)?.isLocked ?? false;
  });

  public readonly $canEdit = $derived((get) => {
    const dashboardId = get(this.$dashboardId);
    const dashboards = get($combinedDashboardsMap);
    const dashboard = dashboardId ? dashboards.get(dashboardId) : null;
    return dashboard?.canEdit ?? false;
  });

  public readonly $canView = $derived((get) => {
    const dashboardId = get(this.$dashboardId);
    const dashboards = get($combinedDashboardsMap);
    const dashboard = dashboardId ? dashboards.get(dashboardId) : null;
    return dashboard?.canView ?? false;
  });

  public readonly $canDelete = $derived((get) => {
    const dashboardId = get(this.$dashboardId);
    const dashboards = get($combinedDashboardsMap);
    const dashboard = dashboardId ? dashboards.get(dashboardId) : null;
    return dashboard?.canDelete ?? false;
  });

  public readonly $canEditPerms = $derived((get) => {
    const dashboardId = get(this.$dashboardId);
    const dashboards = get($combinedDashboardsMap);
    const dashboard = dashboardId ? dashboards.get(dashboardId) : null;
    return dashboard?.canEditPerms ?? false;
  });

  public readonly $isProviderLocked = $derived((get) => {
    const dashboardId = get(this.$dashboardId);
    const dashboards = get($combinedDashboardsMap);
    const dashboard = dashboardId ? dashboards.get(dashboardId) : null;
    return dashboard?.isProviderLocked ?? false;
  });

  public readonly $packages = $mutableDerived((get) => {
    const dashboardId = get(this.$dashboardId);
    const dashboards = get($combinedDashboard);
    if (!dashboardId || dashboards.length === 0) return [];
    return dashboards.find((d) => d.id === dashboardId)?.packages ?? [];
  });

  public readonly $defaultPackages = $mutableDerived((get) => {
    const dashboardId = get(this.$dashboardId);
    const dashboards = get($combinedDashboard);
    if (!dashboardId || dashboards.length === 0) return [];
    return dashboards.find((d) => d.id === dashboardId)?.defaultPackages ?? [];
  });

  public readonly $noNotificationVersion = $derived(
    (get) => get(this.$dashboard)?.noNotificationVersion || null,
  );

  public readonly $isDnd = $derived((get) => get(this.$dashboard)?.isDnd ?? false);

  public readonly $isFullWidth = $derived((get) => get(this.$dashboard)?.isFullWidth ?? true);

  public readonly $isSummary = $derived((get) => {
    const dashboardId = get(this.$dashboardId);
    return dashboardId === 'summary' || dashboardId === 'migration';
  });

  public readonly fetchWidgets = async (
    doc = this.$dashboardDoc.get(),
    shopWithSensory = $shopWithSensory.get(),
    ffComputer = $ffStore.get(),
  ): Promise<WillyWidgetElement[]> => {
    if (!doc || !ffComputer.isReady) {
      return [];
    }
    try {
      const wd = await doc.collection('widgets').get();
      const widgetsDocs = toArray(wd);
      const uniqueWidgets = uniqBy(widgetsDocs, 'queryId').filter(
        (w) => !!w.queryId,
      ) as WillyWidgetElement[];

      const widgets = uniqueWidgets.map<WillyWidgetElement>((w) => {
        let isConnected = true;
        let isLocked = false;
        if (w.permission && w.permission.providers.length > 0) {
          const someProviderIsNotConnected = w.permission.providers
            .map((x) => services[x]?.getIsConnected?.(shopWithSensory) ?? true)
            .includes(false);
          if (someProviderIsNotConnected) {
            isConnected = false;
          }

          const { blockList } = ffComputer.getConfigById(FeatureFlag.LIMIT_INTEGRATIONS_FF);

          const { allowList: allowedMetrics } = ffComputer.getConfigById(
            FeatureFlag.LIMIT_METRICS_FF,
          );

          if (allowedMetrics.length) {
            w.metrics = w.metrics?.map((m) => ({
              ...m,
              isBlocked: m.v2metricId ? !allowedMetrics.includes(m.v2metricId) : false,
            }));
          }

          if (blockList.length > 0) {
            isLocked = w.permission.providers.some((x) => blockList.includes(x));
          }
        }

        return {
          ...w,
          dialect: w.dialect || 'bigquery',
          isProviderNotConnected: !isConnected,
          isProviderLocked: isLocked,
        };
      });

      return widgets;
    } catch (e) {
      return this.$widgets.get() as WillyWidgetElement[];
    }
  };

  public readonly $widgets = $mutableObserver<Awaited<ReturnType<typeof this.fetchWidgets>>>(
    [],
    async (get, set) => {
      const doc = get(this.$dashboardDoc);
      if (doc?.id === 'summary') return set([]); // hack @yitchak need to fix
      const widgets = await this.fetchWidgets(doc, get($shopWithSensory), get($ffStore));
      this.$loadingWidgets.set(false);
      set(widgets || get());
    },
  );

  public readonly $maxWidgetCount = $derived((get) => (get($isAdminClaim) ? Infinity : 10));

  public readonly $widgetsLeft = $derived((get) =>
    Math.max(0, get(this.$maxWidgetCount) - get(this.$widgets).length),
  );

  public async fetchFields(doc = this.$dashboardDoc.get()) {
    if (!doc) return [];
    try {
      const wd = await doc.collection('fields').get();
      return toArray<WillyFieldElement>(wd);
    } catch {
      return [];
    }
  }

  public readonly $fields = $mutableObserver<Awaited<ReturnType<typeof this.fetchFields>>>(
    [],
    async (get, set) => {
      const fields = await this.fetchFields(get(this.$dashboardDoc));
      this.$loadingFields.set(false);
      set(fields);
    },
  );

  public readonly $orderedSectionsByLayout = $derived((get) => {
    const widgets = get(this.$widgets);
    const fields = get(this.$fields);
    const layout = get(this.$layout);
    const dashboardDialect = get(this.$dashboardDialect);

    const fieldsToOrder = fields.map((f) => ({ ...f, queryId: f.id, sectionType: 'field' }));
    const widgetsToOrder = widgets.map((w) => ({ ...w, sectionType: 'widget' }));
    const isWidget = (w: WillyFieldElement | WillyWidgetElement): w is WillyWidgetElement =>
      'queryId' in w;

    const orderedWidgets: WillySection[] = [...fieldsToOrder, ...widgetsToOrder]
      .sort((a, b) => {
        const aLayout = layout.find((l) => l.i === a.queryId);
        const bLayout = layout.find((l) => l.i === b.queryId);
        if (!aLayout || !bLayout) return 0;
        return aLayout.y - bLayout.y || aLayout.x - bLayout.x;
      })
      .map((w) => {
        return {
          id: w.queryId,
          hidden: w.hidden,
          isProviderNotConnected: isWidget(w) ? w.isProviderNotConnected : false,
          dialect: isWidget(w) ? (w.dialect ? w.dialect : 'bigquery') : undefined,
          isProviderLocked: isWidget(w) ? w.isProviderLocked : false,
          title: w.title,
          type: w.type,
          sectionType: w.sectionType as 'widget' | 'field',
        };
      })
      .filter((w) => {
        if (w.sectionType === 'widget') {
          return w.dialect === dashboardDialect || dashboardDialect === 'both';
        }
        return true;
      });

    return orderedWidgets;
  });

  public readonly $pinnedSection = $derived((get) =>
    get(this.$widgets).find((w) => w.pinnedSection === true),
  );

  public readonly $visitors = $observer<any[]>([], (get, set) => {
    const doc = get(this.$dashboardDoc);
    const userId = get($userId);
    if (!userId || !doc) return;

    const unsubSnapshot = doc.collection('visitors').onSnapshot((wd) => {
      try {
        const viewersDocs = toArray(wd)
          .sort((a, b) => b.time - a.time)
          .sort((v) => (v.uid === userId ? -1 : 1));

        set(viewersDocs);
      } catch {}
    });
    this.cleanupQueue.add(unsubSnapshot);

    return () => {
      unsubSnapshot();
      doc?.collection('visitors')?.doc(userId)?.delete();
      this.cleanupQueue.delete(unsubSnapshot);
    };
  });

  public readonly $history = $observer<any[]>([], (get, set) => {
    const doc = get(this.$dashboardDoc);
    if (!doc) return;

    const unsubSnapshot = doc.collection('history').onSnapshot((wd) => {
      try {
        const historyDocs = toArray(wd).sort((a, b) => b.time - a.time);
        set(historyDocs);
      } catch {}
    });
    this.cleanupQueue.add(unsubSnapshot);

    return () => {
      unsubSnapshot();
      this.cleanupQueue.delete(unsubSnapshot);
    };
  });

  public readonly $uniqueVisitors = $derived((get) => {
    const history = get(this.$history);
    return history.length > 0 ? uniqBy(history, (h) => h.user?.uid) : emptyArray<any>();
  });

  public readonly $uniqueUsersEvents = $derived((get) => {
    const uniqueVisitors = get(this.$uniqueVisitors);
    if (!uniqueVisitors.length) return emptyArray<any>();

    const dashboard = get(this.$dashboard);
    return uniqueVisitors
      .filter((h) => dashboard?.users?.includes(h.user?.email))
      .map((h) => ({ ...h, admin: dashboard?.admins?.includes(h.user?.email) ?? false }));
  });

  private readonly $workspaces = $derived((get) => get($redux)?.workspaces);
  private readonly $selectedWorkspaceId = $derived((get) => get($redux)?.selectedWorkspaceId || '');

  private readonly $selectedWorkspace = $derived((get) => {
    const workspaces = get(this.$workspaces);
    if (!Array.isArray(workspaces)) return {};

    const selectedWorkspaceId = get(this.$selectedWorkspaceId);
    return workspaces?.find((x) => x.id === selectedWorkspaceId) ?? {};
  });

  public readonly $dashCreator = $derived((get) => {
    const history = get(this.$history);
    return history.length ? history.find((v) => v.action === 'created') : emptyObject<any>();
  });

  public readonly $lastUpdated = $derived((get) => {
    const history = get(this.$history);
    return history.length > 0
      ? history.find((v) => v.action !== 'created' && v.action !== 'view_dashboard')
      : emptyObject<any>();
  });

  public readonly $isShopOwner = $derived((get) => {
    const selectedWorkspace = get(this.$selectedWorkspace);
    const currentShopId = $currentShopId.get();
    const currentShop = selectedWorkspace?.shops?.find((s) => s.shopId === currentShopId);
    return !!currentShop?.roles?.includes('owner');
  });

  //
  // METHODS
  //

  public readonly updateWidgetFieldsLocally = (
    widgetId: string,
    fields: Partial<WillyWidgetElement>,
  ) => {
    this.$widgets.set((prev) => {
      return prev.map((w) => {
        if (w.queryId === widgetId) {
          return { ...w, ...fields };
        }
        return w;
      });
    });
  };

  public readonly updateWidgetFieldsInDb = async (
    widgetId: string,
    widget: Partial<WillyWidgetElement>,
  ) => {
    const dashboard = this.$dashboard.get();
    if (!dashboard) return;

    this.updateWidgetFieldsLocally(widgetId, widget);

    const { canEdit } = getDashPermsManager().computeDashPerms();
    if (!canEdit) return;

    await updateDashboardWidget(dashboard, widget, widgetId, $currentShopId.get(), $userId.get());
  };

  public readonly updateMetricsInDb = async (id: string, metrics: WillyMetric[]) => {
    try {
      await this.updateWidgetFieldsInDb(id, { metrics });
    } catch {
      console.error('Error updating metrics in db', id);
    }
  };

  public readonly updateFieldsInDb = async (
    fieldId: string,
    fields: Partial<Record<keyof WillyFieldElement, any>>,
  ) => {
    const doc = this.$dashboardDoc.get();
    if (!doc) return;

    await doc.collection('fields').doc(fieldId).set(fields, { merge: true });
  };

  public readonly setDashboardToSeeWorkflow = async (value: boolean) => {
    const doc = this.$dashboardDoc.get();
    if (!doc) return;
    await doc.set({ showWorkflowsInDashboard: value }, { merge: true });
  };

  public readonly cleanup = () => {
    this.cleanupQueue.forEach((c) => c());
  };

  public readonly updateDashboard = async <T extends keyof WillyDashboardElement>(
    fields: Partial<Pick<WillyDashboardElement, T>>,
  ) => {
    const dashboard = this.$dashboard.get();
    if (!dashboard) return;
    await updateMainElement(dashboard, fields, $currentShopId.get(), $userId.get());
  };

  public readonly addVersionToGlobalDashboard = async (version: WillyDashboardVersionElement) => {
    const doc = this.$dashboardDoc.get();
    const dashboard = this.$dashboardSnapshotData.get()?.data() as WillyDashboardElement;
    if (!doc || !dashboard) return;
    //add document with version as id and dashboard element as data
    await doc
      .collection('versions')
      ?.doc(version.version)
      .set({
        ...dashboard,
      });
  };

  public readonly setDashboardDefaultDateToQS = async () => {
    const doc = this.$dashboardDoc.get();
    //get dashboard from db separately to prevent unnecessary rerenders
    const dashboard = (await doc?.get())?.data() as WillyDashboardElement;
    if (!dashboard) return;
    const { currentDateRange, prevDateRange, isUseDashboardDefaultDate } = dashboard;
    const qs = QueryString.parse(location.search, { ignoreQueryPrefix: true });
    if (isUseDashboardDefaultDate && currentDateRange && prevDateRange && !qs['report']) {
      const currentDateRangeAllOptionsValues = getDatePickerOptionValueOptions();
      const dashboardCurrentDateRange = currentDateRangeAllOptionsValues.find(
        (v) => v.id === currentDateRange.id,
      );
      const { start, end } = dashboardCurrentDateRange || currentDateRange;
      const prevDateRangeAllOptionsValues = getDatePickerCompareOptionsValue(
        dashboardCurrentDateRange || currentDateRange,
      );
      const dashboardPrevDateRange = prevDateRangeAllOptionsValues[prevDateRange.id];
      const { start: prevStart, end: prevEnd } = dashboardPrevDateRange || prevDateRange;
      const prevIsNone = prevDateRange.id === 'none';
      pushFullDateToQS(
        $navigate.get(),
        moment(start),
        moment(end),
        prevIsNone ? undefined : moment(prevStart),
        prevIsNone ? undefined : moment(prevEnd),
        prevIsNone,
      );
    }
  };
}

const dashboardStores = new DashboardStores();
if (!isProd || isLocal) {
  //@ts-ignore
  window.dashboardStores = dashboardStores;
}

export function clearDashContext() {
  dashboardStores.cleanup();
}

export function getDashContext() {
  return dashboardStores;
}

export async function refreshDashboardWidgets() {
  const dashContext = getDashContext();
  const widgets = await dashContext.fetchWidgets();
  dashContext.$widgets.set(widgets);
}

export function useDashContext() {
  return dashboardStores;
}

export const isGlobalDashboardExistsInShop = (template: WillyBaseMainElement): boolean => {
  const shopDashboards =
    template.type === 'dashboard' ? $shopDashboards.get() : $shopSequencesRaw.get();
  return !!shopDashboards.find((d) => d.globalDashboardId === template.id);
};

export const shopLevelDashComingFromTemplate = (
  template: WillyBaseMainElement,
): WillyBaseMainElement | undefined => {
  const shopDashboards =
    template.type === 'dashboard' ? $shopDashboards.get() : $shopSequencesRaw.get();
  return shopDashboards.find((d) => d.globalDashboardId === template.id);
};
