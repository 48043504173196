import { isGroupBtnDisabled } from 'ducks/stats';
import React, { FC, useCallback } from 'react';
import { CurrentDateRange } from '$stores/willy/$dateRange';
import { Granularity } from '@tw/types';
import { Flex, Text } from '@tw/ui-components';

type GranularitySwitcherProps = {
  onClose?: () => void;
  currentDateRange: CurrentDateRange;
  granularity: Granularity;
  setGranularity: (selected: Granularity) => void;
};

export const GroupStatsButtons: FC<GranularitySwitcherProps> = ({
  onClose,
  currentDateRange,
  granularity,
  setGranularity,
}) => {
  const isChoiceDisabled = useCallback(
    (group) => {
      return isGroupBtnDisabled({ mainDatePickerSelectionRange: currentDateRange, group });
    },
    [currentDateRange],
  );

  const handleChoice = useCallback(
    (selected: Granularity) => {
      setGranularity(selected);
      onClose && onClose();
    },
    [onClose, setGranularity],
  );

  return (
    <Flex align="center" gap="sm">
      <Text weight={500} size="sm" color="gray.8">
        Aggregate by:
      </Text>
      {[
        { label: 'Daily', value: 'day', disabled: isChoiceDisabled('day') },
        { label: 'Weekly', value: 'week', disabled: isChoiceDisabled('week') },
        { label: 'Monthly', value: 'month', disabled: isChoiceDisabled('month') },
      ].map(({ label, value, disabled }, index) => (
        <Flex align="center" gap="sm">
          <GranularityElement
            key={value}
            label={label}
            disabled={disabled}
            selected={granularity === value}
            onClick={() => handleChoice(value as Granularity)}
          />
          {index < 2 && <div className="h-[14px] w-[1px] bg-[var(--mantine-color-gray-3)]"></div>}
        </Flex>
      ))}
    </Flex>
  );
};

type GranularityElementProps = {
  label: string;
  disabled: boolean;
  selected: boolean;
  onClick: () => void;
};
const GranularityElement: FC<GranularityElementProps> = ({
  label,
  disabled,
  selected,
  onClick,
}) => {
  return (
    <span
      style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
      onClick={disabled ? undefined : onClick}
    >
      <Text weight={500} color={selected ? 'one.5' : disabled ? 'gray.2' : 'gray.5'} size="sm">
        {label}
      </Text>
    </span>
  );
};
