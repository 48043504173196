import { $store } from '@tw/snipestate';
import { Dialect } from '@tw/types';
import { WorkflowStep, builderShelf } from 'components/Willy/types/willyTypes';

type MultiFlowSequenceBuilderStore = {
  parentSequenceId?: string;
  shelfOpen: builderShelf | null;
  prefilledUserPrompts?: WorkflowStep[];
  parentDialect: Dialect;
  conversationId?: string;
};

const defaultMultiFlowSequenceBuilder: MultiFlowSequenceBuilderStore = {
  shelfOpen: null,
  parentSequenceId: '',
  parentDialect: 'clickhouse',
} as MultiFlowSequenceBuilderStore;

export const $multiFlowSequenceBuilder = $store<MultiFlowSequenceBuilderStore>(
  defaultMultiFlowSequenceBuilder,
);

type SequenceBuilderSequencesStoreType = {
  [key: string]: {
    steps: WorkflowStep[];
  };
};

export const $sequenceBuilderSequences = $store<SequenceBuilderSequencesStoreType>({});

export const setParentSequenceId = (parentSequenceId: string) => {
  $multiFlowSequenceBuilder.set((x) => ({ ...x, parentSequenceId: parentSequenceId }));
};

export const resetMultiFlowSequenceBuilder = () => {
  $multiFlowSequenceBuilder.set(defaultMultiFlowSequenceBuilder);
};

export const updateSequenceSteps = (sequenceId: string, steps: WorkflowStep[]) => {
  $sequenceBuilderSequences.set((x: SequenceBuilderSequencesStoreType) => ({
    ...x,
    [sequenceId]: { ...x[sequenceId], steps },
  }));
};

export const setSequenceSteps = (sequenceId: string, steps: WorkflowStep[]) => {
  $sequenceBuilderSequences.set((x) => ({
    ...x,
    [sequenceId]: { steps },
  }));
};
