import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/RootType';

import { Tooltip } from '@shopify/polaris';
import { ActionIcon, Menu } from '@tw/ui-components';

import { TagsOptions } from './AttributionMoreSettings';
import { AttributionSubscriptionFilter } from './AttributionSubscriptionFilter';
import AttributionWindowPicker from './AttributionWindowPicker';

export const AttributionFiltersDropdown = () => {
  const { attributionSubscription } = useSelector((state: RootState) => state.attribution);
  const tagsToCheck: TagsOptions[] = useMemo<TagsOptions[]>(
    () => ['oneTime', 'subscriptionFirstOrder', 'subscriptionRecurringOrder'],
    [],
  );
  const subscriptionFilterOn = useMemo<boolean>(() => {
    const allTagsPresent = tagsToCheck.every((tag) => attributionSubscription?.includes(tag));
    return !allTagsPresent && !!attributionSubscription.length;
  }, [attributionSubscription, tagsToCheck]);
  const [opened, setOpened] = useState(false);
  return (
    <Menu opened={opened} onClose={() => setOpened(false)} closeOnItemClick={false} shadow="md">
      <Menu.Target>
        <div>
          <Tooltip
            content={
              subscriptionFilterOn ? 'Table is currently displaying subscription filtered data' : ''
            }
          >
            <ActionIcon
              icon="filter"
              variant="activator"
              bg={opened ? 'gray.1' : 'transparent'}
              onClick={() => setOpened(!opened)}
            />
            {subscriptionFilterOn && (
              <div className={'turn-on-indication right-indicator z-10 ml-5'} />
            )}
          </Tooltip>
        </div>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item>
          <AttributionWindowPicker withinPortal={false} />
        </Menu.Item>
        <Menu.Item>
          <AttributionSubscriptionFilter />
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};
