import { Allotment } from 'allotment';
import { useSelectByWindowResize } from 'hooks/useSelectByWindowResize';
import { WorkflowBuilderWrapper } from './sequenceBuilder/WorkflowBuilderWrapper';
import { useEffect, useRef, useState } from 'react';
import { WorkflowInMobyStatus } from './hooks/useChatSocket';
import { Loader } from '@tw/ui-components';

type WillyWorkflowPanelProps = {
  workflowIdToRun?: string;
  conversationId: string;
  workflowInMobyStatus: WorkflowInMobyStatus;
  runOnInit?: boolean;
};

export const WillyWorkflowPanel: React.FC<WillyWorkflowPanelProps> = ({
  workflowIdToRun,
  workflowInMobyStatus,
  conversationId,
  runOnInit = false,
}) => {
  const windowWidth = useSelectByWindowResize(({ width }) => width);
  const [initialPositionX, setInitialPositionX] = useState(0);
  const ref = useRef<HTMLDivElement>(null);
  const [shouldRunOnInit, setShouldRunOnInit] = useState(runOnInit);

  useEffect(() => {
    if (!ref.current) return;
    setInitialPositionX(((1 - 0.6) / 2) * ref.current.clientWidth);
  }, [ref]);

  useEffect(() => {
    if (workflowInMobyStatus === 'done') {
      setShouldRunOnInit(false);
    }
  }, [workflowInMobyStatus]);

  return (
    <Allotment.Pane
      snap
      className="transition-[width] duration-300 ease-in-out sm:transition-none h-full"
      preferredSize={windowWidth / 3}
      maxSize={windowWidth / 3}
      minSize={windowWidth / 3}
      ref={ref}
    >
      {!workflowIdToRun &&
        (workflowInMobyStatus === 'idle' ||
          workflowInMobyStatus === 'started' ||
          workflowInMobyStatus === 'editing') && (
          <div className="h-full flex flex-col p-6.5">
            <div className="flex flex-col items-center justify-center m-auto gap-4">
              {(workflowInMobyStatus === 'started' || workflowInMobyStatus === 'editing') && (
                <Loader size="lg" />
              )}
              {workflowInMobyStatus === 'idle' && (
                <div className="text-gray-5 text-lg">Select a workflow to run</div>
              )}

              {workflowInMobyStatus === 'started' && (
                <div className="text-gray-5 text-lg">Workflow will start soon...</div>
              )}

              {workflowInMobyStatus === 'editing' && (
                <div className="text-gray-5 text-lg">Workflow is being edited...</div>
              )}
            </div>
          </div>
        )}
      {!!workflowIdToRun && (
        <WorkflowBuilderWrapper
          workflowId={workflowIdToRun}
          runOnInit={shouldRunOnInit}
          withMiniMap={false}
          canAddNewStep={false}
          navBarWidth={0}
          canvasSize={ref.current?.clientWidth!.toString()}
          zoom={0.6}
          initialPositionY={100}
          initialPositionX={initialPositionX}
          readOnly
          rephraseStepsBasedOnConversation={conversationId}
        />
      )}
    </Allotment.Pane>
  );
};
