import { useSelector } from 'react-redux';
import type { RootState } from 'reducers/RootType';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch } from 'index';

// onboarding icons
import facebookIcon from 'components/onboarding/icons/integ/Facebook.svg';
import googleAdsIcon from 'components/onboarding/icons/integ/Google Ads.svg';
import analyticsIcon from 'components/onboarding/icons/integ/Google Analytics.svg';
import gorgiasIcon from 'components/onboarding/icons/integ/Gorgias.svg';
import sheetsIcon from 'components/onboarding/icons/integ/GoogleSheets.svg';
import klvIcon from 'components/onboarding/icons/integ/Klaviyo.svg';
import pinIcon from 'components/onboarding/icons/integ/Pinterest.svg';
import slackIcon from 'components/onboarding/icons/integ/Slack.svg';
import twitterIcon from 'components/onboarding/icons/twitter.svg';
import snapchatIcon from 'components/onboarding/icons/integ/Snapchat.svg';
import tiktokIcon from 'components/onboarding/icons/integ/Tik Tok.svg';
import fairingIcon from 'components/onboarding/icons/integ/Fairing.svg';
import rechargeIcon from 'components/onboarding/icons/integ/Recharge.svg';
import criteo from 'components/onboarding/icons/integ/criteo.png';
import mountain from 'components/onboarding/icons/integ/mountain.png';
import tatari from 'components/onboarding/icons/integ/tatari.png';
import taboolaIcon from 'components/onboarding/icons/integ/taboola.png';
import adrollIcon from 'components/onboarding/icons/integ/adroll.png';
import outbrainIcon from 'components/onboarding/icons/integ/outbrain.png';
import knoIcon from 'components/onboarding/icons/integ/Kno.svg';
import amazonIcon from 'components/onboarding/icons/integ/Amazon.svg';
import shopifyIcon from '../../../icons/shopify.svg';
import microsoftIcon from 'components/onboarding/icons/integ/Microsoft.svg';
import shipstationIcon from 'components/onboarding/icons/integ/Shipstation.svg';
import shipbobIcon from 'components/onboarding/icons/integ/shipbob.svg';
import smsbump from 'components/onboarding/icons/integ/smsbump.svg';
import reddit from 'components/onboarding/icons/integ/reddit.svg';
import intercom from 'components/onboarding/icons/integ/intercom.png';
import hubspot from 'components/onboarding/icons/integ/hubspot.png';
import postscript from 'components/onboarding/icons/integ/postscript.png';
import attentive from 'components/onboarding/icons/integ/attentive.png';
import posthog from 'components/onboarding/icons/integ/posthog.png';
import stripe from 'components/onboarding/icons/integ/stripe.png';
import gcpIcon from 'components/onboarding/icons/integ/gcp-billing.png';
import linkedinIcon from 'components/onboarding/icons/integ/linkedin.png';
import okendoIcon from 'components/onboarding/icons/integ/okendo.png';
import SkioIcon from 'components/onboarding/icons/integ/Skio.jpg';
import bigqueryIcon from 'components/onboarding/icons/integ/bigquery.svg';
import rakutenIcon from 'components/onboarding/icons/integ/rakuten.png';
import snowflakeIcon from 'components/onboarding/icons/integ/snowflake.svg';
import applovinIcon from 'components/onboarding/icons/integ/Applovin.svg';
// components
import DisconnectFromGeneralExplanation from 'components/DisconnectFromGeneralExplanation';
import DisconnectFromEnquirelabsExplanation from 'components/DisconnectFromEnquirelabsExplanation';
import DisconnectFromKnoExplanation from 'components/DisconnectFromKnoExplanation';
import GoogleSheetsConnectionDetails from 'components/GoogleSheetsConnectionDetails';

// types
import { IIntegrationWidget } from '../components/IntegrationWidget';
import { onboardingResponse } from '@tw/types/module/services/subscription-manager';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';

// actions
import { klaviyoConnectOnPress, klaviyoDisconnect } from 'ducks/klaviyo';
import { facebookDisconnect } from 'ducks/facebook';
import { googleAdsDisconnect } from 'ducks/googleAds';
import {
  googleSheetsConnect,
  googleSheetsDisconnect,
  toggleGoogleSheetsSettingsOpen,
} from 'ducks/googleSheets';
import { gorgiasConnectOnPress, gorgiasDisconnect } from 'ducks/gorgias';
import { snapchatDisconnect } from 'ducks/snapchat';
import { onToggleTiktokPurchaseEventSettingsOpen, tiktokDisconnect } from 'ducks/tiktok';
import { slackDisconnect } from 'ducks/slack';
import { pinterestDisconnect } from 'ducks/pinterest';
import { enquirelabsConnectOnPress, enquirelabsDisconnect } from 'ducks/enquirelabs';
import { knoConnectOnPress, knoDisconnect } from 'ducks/kno';
import { amazonConfigOpen, amazonConnectOnPress, amazonDisconnect } from 'ducks/amazon';
import { bingPopupConfig, connectBing, disconnectBing } from 'ducks/bing';

// utils
import { twitterConnectOnPress, twitterDisconnect } from 'ducks/twitter';
import { useFeatureFlag } from 'feature-flag-system';
import {
  sensoryAccountConnect,
  sensoryAccountDisconnect,
  sensoryAuthConfigOpen,
  sensoryConfigOpen,
  tryToOpenDialogIfOpenCred,
} from '../../../ducks/sensory';
import { ShopProviderStatusEnum } from '@tw/types/module/types/ShopProviders';
import { oldProviderConnectOnPress, shopProvidersStatus } from 'ducks/shopIntegrations';
import { ServicesIds, services } from '@tw/types/module/services';
import { confirm } from '@tw/ui-components';
import { providers } from 'ducks/shopIntegrations';
import { $v3_0_Enabled } from '$stores/$v3_0_Enabled';
import { useStoreValue } from '@tw/snipestate';

const integrationTitles: { [key in ServicesIds]?: { title: string; subTitle: string } } = {
  stripe: {
    title: 'Payment Services',
    subTitle: 'Analyze payments, MRR and financial metrics such as NDR, GRR and more.',
  },
  hubspot: {
    title: 'CRM',
    subTitle:
      'Investigate, your leads, conversion funnel, sales team’s performance and the impacts on the button line.',
  },
  posthog: {
    title: 'User Behavior Analytics',
    subTitle: "Unlock User Behavior Analytics by tracking your user's behavior",
  },
  gcp: {
    title: 'Cloud Providers',
    subTitle: 'import cloud costs, utilization and correlated it to MRR',
  },
  intercom: {
    title: 'Customer Support',
    subTitle: 'Gain insights from your user’s interaction with your support',
  },
};

const providerToIconsMapper: {} = {
  'facebook-ads': facebookIcon,
  'google-ads': googleAdsIcon,
  'google-analytics': analyticsIcon,
  gorgias: gorgiasIcon,
  'google-sheets': sheetsIcon,
  klaviyo: klvIcon,
  'pinterest-ads': pinIcon,
  slack: slackIcon,
  'twitter-ads': twitterIcon,
  'snapchat-ads': snapchatIcon,
  'tiktok-ads': tiktokIcon,
  fairing: fairingIcon,
  kno: knoIcon,
  recharge: rechargeIcon,
  skio: SkioIcon,
  amazon: amazonIcon,
  shopify: shopifyIcon,
  'amazon-ads': amazonIcon,
  'amazon-sales': amazonIcon,
  bing: microsoftIcon,
  criteo,
  mountain,
  tatari,
  taboola: taboolaIcon,
  adroll: adrollIcon,
  outbrain: outbrainIcon,
  shipstation: shipstationIcon,
  shipbob: shipbobIcon,
  smsbump,
  reddit,
  intercom,
  hubspot,
  postscript,
  attentive,
  okendo: okendoIcon,
  posthog: posthog,
  stripe: stripe,
  gcp: gcpIcon,
  linkedin: linkedinIcon,
  'big-query': bigqueryIcon,
  snowflake: snowflakeIcon,
  rakuten: rakutenIcon,
  applovin: applovinIcon,
  'custom-msp': gcpIcon,
};

// import { sensoryProviderInfoSelector } from '../selectors/selectors';

export const useIntegrationsConfig = () => {
  // selectors

  const dispatch = useAppDispatch();
  const currentShopId = useSelector((state: RootState) => state.currentShopId);
  const onboarding = useSelector((state: RootState) => state.onboarding as onboardingResponse);
  const isAmazonConnectedNA = useSelector((state: RootState) => state.isAmazonConnectedNA);
  const isAmazonConnectedEU = useSelector((state: RootState) => state.isAmazonConnectedEU);
  const isAmazonConnectedFE = useSelector((state: RootState) => state.isAmazonConnectedFE);
  const sensorySupportedProviders = useSelector(
    (state: RootState) => state.sensory.sensorySupportedProviders,
  );
  const sensoryProviders = useSelector((state: RootState) => state.sensory.providers);
  const providersStatus = useSelector(shopProvidersStatus);

  const isFacebookIntegrationBtnLoading = useSelector(
    (state: RootState) => state.isFacebookIntegrationBtnLoading,
  );
  const isGoogleAdsIntegrationBtnLoading = useSelector(
    (state: RootState) => state.isGoogleAdsIntegrationBtnLoading,
  );
  const isPinterestIntegrationBtnLoading = useSelector(
    (state: RootState) => state.isPinterestIntegrationBtnLoading,
  );
  const isTiktokIntegrationBtnLoading = useSelector(
    (state: RootState) => state.isTiktokIntegrationBtnLoading,
  );
  const isSnapchatIntegrationBtnLoading = useSelector(
    (state: RootState) => state.isSnapchatIntegrationBtnLoading,
  );

  const sensoryProviderInfo = useSelector((state: RootState) =>
    state.sensory.providers.filter((p) => p.version === 2),
  );
  const providerList = useSelector(providers);
  const v3_0_Enabled = useStoreValue($v3_0_Enabled);
  const msp = useSelector((state: RootState) => state.msp);

  const twVersion = useMemo(() => {
    return v3_0_Enabled ? 3.0 : 2.0;
  }, [v3_0_Enabled]);

  useEffect(() => {
    if (sensoryProviders.length > 0) {
      dispatch(tryToOpenDialogIfOpenCred());
    }
  }, [dispatch, sensoryProviders]);

  // hooks
  const { blockList: blockedIntegrations } = useFeatureFlag(FeatureFlag.LIMIT_INTEGRATIONS_FF);

  // state
  const [integrationsStatus, setIntegrationsStatus] = useState<any>({});

  useEffect(() => {
    const integration = onboarding.criticalTasks.find((x) => x.id === 'integrations');
    if (!currentShopId || !integration) return;

    const newIntegrationsStatus: any =
      integration.info?.reduce((obj, int) => ((obj[int.id] = int), obj), {}) || {};

    setIntegrationsStatus(newIntegrationsStatus);
  }, [onboarding, currentShopId]);

  let integrationsWidgets = (
    [
      {
        id: 'facebook-ads',
        name: 'Facebook',
        icon: facebookIcon,
        serviceId: 'facebook-ads',
        disconnectHook: async () => {
          if (
            await confirm({
              title: 'Disconnect from Facebook?',
              message: DisconnectFromGeneralExplanation,
            })
          ) {
            dispatch(facebookDisconnect());
          }
        },
        connectHook: async () => {
          dispatch(oldProviderConnectOnPress('facebook-ads'));
        },
        isLoading: isFacebookIntegrationBtnLoading,
      },
      {
        id: 'google-ads',
        name: 'Google Ads',
        icon: googleAdsIcon,
        serviceId: 'google-ads',
        infoWhenUnconnected: "Can't connect? Try disabling your ad blocker.",
        disconnectHook: async () => {
          if (
            await confirm({
              title: `Disconnect from Google Ads?`,
              message: DisconnectFromGeneralExplanation,
            })
          ) {
            dispatch(googleAdsDisconnect());
          }
        },
        connectHook: async () => {
          dispatch(oldProviderConnectOnPress('google-ads'));
        },
        isLoading: isGoogleAdsIntegrationBtnLoading,
      },
      {
        id: 'gorgias',
        name: 'Gorgias',
        icon: gorgiasIcon,
        isDarkIcon: true,
        serviceId: 'gorgias',
        disconnectHook: async () => {
          if (
            await confirm({
              title: `Disconnect from Gorgias?`,
              message: DisconnectFromGeneralExplanation,
            })
          ) {
            dispatch(gorgiasDisconnect());
          }
        },
        connectHook: () => dispatch(gorgiasConnectOnPress()),
      },
      {
        id: 'klaviyo',
        name: 'Klaviyo',
        icon: klvIcon,
        isDarkIcon: true,
        serviceId: 'klaviyo',
        disconnectHook: async () => {
          if (
            await confirm({
              title: `Disconnect from Klaviyo?`,
              message: DisconnectFromGeneralExplanation,
            })
          ) {
            dispatch(klaviyoDisconnect());
          }
        },
        connectHook: () => dispatch(klaviyoConnectOnPress()),
      },
      {
        id: 'snapchat',
        name: 'Snapchat',
        icon: snapchatIcon,
        serviceId: 'snapchat-ads',
        disconnectHook: async () => {
          if (
            await confirm({
              title: `Disconnect from Snapchat?`,
              message: DisconnectFromGeneralExplanation,
            })
          ) {
            dispatch(snapchatDisconnect());
          }
        },
        connectHook: async () => {
          dispatch(oldProviderConnectOnPress('snapchat-ads'));
        },
        isLoading: isSnapchatIntegrationBtnLoading,
      },
      {
        id: 'tiktok',
        name: 'TikTok',
        icon: tiktokIcon,
        serviceId: 'tiktok-ads',
        disconnectHook: async () => {
          if (
            await confirm({
              title: `Disconnect from Tiktok?`,
              message: DisconnectFromGeneralExplanation,
            })
          ) {
            dispatch(tiktokDisconnect());
          }
        },
        connectHook: async () => {
          dispatch(oldProviderConnectOnPress('tiktok-ads'));
        },
        configHook: () => dispatch(onToggleTiktokPurchaseEventSettingsOpen()),
        isLoading: isTiktokIntegrationBtnLoading,
      },
      {
        id: 'slack',
        name: 'Slack',
        icon: slackIcon,
        serviceId: 'slack',
        disconnectHook: async () => {
          if (
            await confirm({
              title: `Disconnect from Slack?`,
              message: DisconnectFromGeneralExplanation,
            })
          ) {
            dispatch(slackDisconnect());
          }
        },
        connectHook: async () => {
          dispatch(oldProviderConnectOnPress('slack'));
        },
      },
      {
        id: 'pinterest',
        name: 'Pinterest',
        icon: pinIcon,
        serviceId: 'pinterest-ads',
        disconnectHook: async () => {
          if (
            await confirm({
              title: `Disconnect from Pinterest?`,
              message: DisconnectFromGeneralExplanation,
            })
          ) {
            dispatch(pinterestDisconnect());
          }
        },

        connectHook: async () => {
          dispatch(oldProviderConnectOnPress('pinterest-ads'));
        },
        isLoading: isPinterestIntegrationBtnLoading,
      },
      {
        id: 'enquire',
        name: 'Fairing (Enquirelabs)',
        icon: fairingIcon,
        isDarkIcon: true,
        serviceId: 'enquirelabs',
        disconnectHook: async () => {
          if (
            await confirm({
              title: `Disconnect from Fairing?`,
              message: <DisconnectFromEnquirelabsExplanation />,
            })
          ) {
            dispatch(enquirelabsDisconnect());
          }
        },
        connectHook: () => dispatch(enquirelabsConnectOnPress()),
      },
      {
        id: 'kno',
        name: 'Kno',
        icon: knoIcon,
        isDarkIcon: true,
        serviceId: 'kno',
        disconnectHook: async () => {
          if (
            await confirm({
              title: `Disconnect from Kno?`,
              message: <DisconnectFromKnoExplanation />,
            })
          ) {
            dispatch(knoDisconnect(currentShopId));
          }
        },
        connectHook: () => dispatch(knoConnectOnPress()),
      },
      {
        id: 'amazon',
        name: 'Amazon NA',
        serviceId: 'amazon',
        infoWhenUnconnected: '🇺🇸🇨🇦🇲🇽🇧🇷',
        tooltipWhenUnconnected: 'United States, Canada, Mexico, Brazil',
        icon: amazonIcon,
        isDarkIcon: true,
        disconnectHook: async () => {
          if (
            await confirm({
              title: `Disconnect from Amazon?`,
              message: DisconnectFromGeneralExplanation,
            })
          ) {
            dispatch(amazonDisconnect(currentShopId, 'na'));
          }
        },
        connectHook: async () => {
          dispatch(amazonConnectOnPress('na'));
        },
        configHook: () => dispatch(amazonConfigOpen('na')),
        providerState: {
          status: isAmazonConnectedNA
            ? ShopProviderStatusEnum.connected
            : ShopProviderStatusEnum.disconnected,
        },
        //broken: integrationsStatus?.AMAZON?.broken,//TODO not implemented -  not in onboarding service
      },
      {
        id: 'amazon',
        name: 'Amazon EU',
        serviceId: 'amazon',
        infoWhenUnconnected: '🇬🇧🇪🇸🇫🇷🇧🇪🇳🇱🇮🇹🇩🇪🇸🇪🇵🇱🇿🇦🇪🇬🇹🇷🇸🇦🇦🇪🇮🇳',
        tooltipWhenUnconnected:
          'United Kingdom, Spain, France, Belgium, Netherlands, Italy, Germany, Sweden, Poland, South Africa, Egypt, Turkey, Saudi Arabia, United Arab Emirates, India',
        icon: amazonIcon,
        disconnectHook: async () => {
          if (
            await confirm({
              title: `Disconnect from Amazon EU?`,
              message: DisconnectFromGeneralExplanation,
            })
          ) {
            dispatch(amazonDisconnect(currentShopId, 'eu'));
          }
        },
        connectHook: async () => {
          dispatch(amazonConnectOnPress('eu'));
        },
        configHook: () => dispatch(amazonConfigOpen('eu')),
        providerState: {
          status: isAmazonConnectedEU
            ? ShopProviderStatusEnum.connected
            : ShopProviderStatusEnum.disconnected,
        },
        //broken: integrationsStatus?.AMAZON?.broken,//TODO not implemented -  not in onboarding service
      },
      {
        id: 'amazon',
        name: 'Amazon FE',
        serviceId: 'amazon',
        infoWhenUnconnected: '🇸🇬🇸🇯🇦🇺',
        tooltipWhenUnconnected: 'Singapore, Japan, Australia',
        icon: amazonIcon,
        disconnectHook: async () => {
          if (
            await confirm({
              title: `Disconnect from Amazon FE?`,
              message: DisconnectFromGeneralExplanation,
            })
          ) {
            dispatch(amazonDisconnect(currentShopId, 'fe'));
          }
        },
        connectHook: async () => {
          dispatch(amazonConnectOnPress('fe'));
        },
        configHook: () => dispatch(amazonConfigOpen('fe')),
        providerState: {
          status: isAmazonConnectedFE
            ? ShopProviderStatusEnum.connected
            : ShopProviderStatusEnum.disconnected,
        },
        //broken: integrationsStatus?.AMAZON?.broken,//TODO not implemented -  not in onboarding service
      },
      {
        id: 'google-sheets',
        name: 'Google Sheets',
        icon: sheetsIcon,
        serviceId: 'google-sheets',
        //broken: integrationsStatus?.GOOGLE_SHEETS?.broken,//TODO not implemented -  not in onboarding service
        disconnectHook: async () => {
          if (
            await confirm({
              title: `Disconnect from Google Sheets?`,
              message: DisconnectFromGeneralExplanation,
            })
          ) {
            dispatch(googleSheetsDisconnect());
          }
        },
        connectHook: (data) => googleSheetsConnect(!!data?.isFromChat),
        configHook: () => dispatch(toggleGoogleSheetsSettingsOpen()),
        customConnectionDetails: GoogleSheetsConnectionDetails,
      },
      {
        id: 'bing',
        name: 'Microsoft Ads',
        icon: microsoftIcon,
        serviceId: 'bing',
        disconnectHook: async () => {
          if (
            await confirm({
              title: `Disconnect from Microsoft?`,
              message: DisconnectFromGeneralExplanation,
            })
          ) {
            dispatch(disconnectBing());
          }
        },
        connectHook: () => connectBing(),
        configHook: () => dispatch(bingPopupConfig()),
      },
      {
        id: 'twitter',
        name: 'Twitter',
        icon: twitterIcon,
        serviceId: integrationsStatus?.TWITTER?.serviceId,
        disconnectHook: async () => {
          if (
            await confirm({
              title: `Disconnect from Twitter?`,
              message: DisconnectFromGeneralExplanation,
            })
          ) {
            dispatch(twitterDisconnect());
          }
        },
        connectHook: () => twitterConnectOnPress(),
        //broken: integrationsStatus?.TWITTER?.broken,//TODO not implemented -  not in onboarding service
      },
    ] as IIntegrationWidget[]
  )
    .filter((int) => int.enabled ?? true)
    .map((int) => {
      int.providerState = int.providerState || (int.serviceId && providersStatus[int.serviceId]);
      return int;
    });

  const originalIntegrationsWidgetsWithoutSensory = [...integrationsWidgets];
  const sensoryIntegrations: IIntegrationWidget[] = sensoryProviderInfo
    .filter((i) => sensorySupportedProviders.includes(i.id))
    .filter((i) => !providerList[i.id]?.twVersion || +providerList[i.id]?.twVersion <= +twVersion)
    .map((int) => {
      return {
        id: int.id,
        name: int.name,
        providerState: providersStatus[int.id],
        title: integrationTitles[int.id]?.title,
        subTitle: integrationTitles[int.id]?.subTitle,
        serviceId: int.id,
        showAccountConnectedDetails: false,
        isFromSensory: true,
        icon: providerToIconsMapper[int.id],
        connectHook: async () => {
          if (
            int.auth_config?.[0]?.auth_method === 'basic_auth' ||
            (int.auth_config?.[0]?.auth_method === 'oauth' &&
              int.auth_config?.[0]?.fields?.length > 0) // Case where oauth need pre client params.
          ) {
            dispatch(sensoryAuthConfigOpen(int));
          } else {
            await dispatch(sensoryAccountConnect(int));
          }
        },
        configHook: () => dispatch(sensoryConfigOpen(int)),
        disconnectHook: async () => {
          if (int?.credentials?.[0]?.id) {
            if (
              await confirm({
                title: `Disconnect from ${int.name}?`,
                message: DisconnectFromGeneralExplanation,
              })
            ) {
              await dispatch(sensoryAccountDisconnect(int?.credentials?.[0]?.id));
            }
          } else {
            originalIntegrationsWidgetsWithoutSensory
              .find((i) => i.id === int.id)
              ?.disconnectHook?.();
          }
        },
      } as IIntegrationWidget;
    });

  const sensoryIntegrationIds = new Set(sensoryIntegrations.map((si) => si.id));
  integrationsWidgets = integrationsWidgets.filter((i) => !sensoryIntegrationIds.has(i.id));
  integrationsWidgets = [...integrationsWidgets, ...sensoryIntegrations];
  integrationsWidgets = integrationsWidgets.filter((i) => {
    const msps = providerList[i.id]?.msps || services[i.serviceId]?.msps || undefined;
    return !msps || msps.includes(msp) || msps.length == 0;
  });

  if (blockedIntegrations.length) {
    const { allowed, notAllowed } = integrationsWidgets.reduce(
      (acc: any, int) => {
        let bucket: 'allowed' | 'notAllowed' = 'allowed';

        if (blockedIntegrations.includes(int.id)) {
          int.isLocked = true;
          bucket = 'notAllowed';
        }

        acc[bucket].push(int);

        return acc;
      },
      { allowed: [], notAllowed: [] },
    );

    // all locked should be displayed at the end
    integrationsWidgets = [...allowed, ...notAllowed];
  }
  return integrationsWidgets;
};
