import 'components/CreativesCockpit/CreativeChart/CreativeChart.scss';

import BaseChart from 'components/library/BaseChart/BaseChart';
import BubbleToggleButton from 'components/library/BubbleToggleButton/BubbleToggleButton';
import TWImage from 'components/library/TWImage/TWImage';
import DropDown from 'components/ltv/DropDown';
import {
  TW_CREATIVE_TRENDS_PRIMARY_METRIC,
  TW_CREATIVE_TRENDS_SECONDARY_METRIC,
} from 'constants/creativeCockpit';
import { cloneDeep } from 'lodash';
import moment from '@tw/moment-cached/module/timezone';
import React, { FC, Fragment, useCallback, useContext, useEffect, useState } from 'react';
import {
  Bar,
  BarChart,
  Cell,
  Line,
  LineChart,
  Tooltip as ChartTooltip,
  TooltipProps,
} from 'recharts';
import { ChartData, ChartMode, INFLUENCERS_COLORS, SelectableInfluencer } from 'types/Influencers';
import { formatNumber } from 'utils/formatNumber';

import { Spinner } from '@shopify/polaris';

import { metrics } from '../../../constants/metrics/metrics';
import { InfluencersContext } from '../context';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';
import { BaseLegend } from 'components/library/BaseChart/BaseLegend';
import { Text } from '@tw/ui-components';

interface InfluencersChartProps {}

const InfluencersChart: FC<InfluencersChartProps> = () => {
  const isSmall = useIsSmall();
  const { selectedInfluencers, loading, currency, toggleActive } = useContext(InfluencersContext);
  const [lineData, setLineData] = useState<any>([]);
  const [barData, setBarData] = useState<any>([]);
  const [hoveredItems, setHoveredItems] = useState<string[]>([]);
  const [chartData, setChartData] = useState<ChartData>('influencer');
  const [chartMode, setChartMode] = useState<ChartMode>('line');

  const defaultPrimaryMetric = 'pixelPurchases'; //localStorage.getItem(TW_CREATIVE_TRENDS_PRIMARY_METRIC) || 'spend';
  const defaultSecondaryMetric = 'pixelConversionValue';
  //localStorage.getItem(TW_CREATIVE_TRENDS_SECONDARY_METRIC) || 'roas';

  const [primaryMetric, setPrimaryMetric] = useState(defaultPrimaryMetric);
  const [secondaryMetric, setSecondaryMetric] = useState(defaultSecondaryMetric);

  const lineHovered = useCallback(
    (influencer: SelectableInfluencer) => {
      if (influencer.active) {
        return;
      }
      setHoveredItems((items: any) => [...items, influencer!.id]);
      toggleActive?.(influencer);
    },
    [toggleActive],
  );

  const lineLeft = useCallback(
    (influencer: SelectableInfluencer) => {
      const copy = cloneDeep(hoveredItems);
      const index = copy.findIndex((item) => influencer.id === item);
      if (index === -1) {
        return;
      }
      toggleActive?.(influencer);
      copy.splice(index, 1);
      setHoveredItems(copy);
    },
    [hoveredItems, toggleActive],
  );

  const handlePrimaryMetricChange = useCallback((metric: string) => {
    /*  amplitude
      .getInstance()
      .logEvent('Creative Cockpit: Chart Metric Changed', { yAxis: 'left', metric: metric }); */
    setPrimaryMetric(metric);
    localStorage.setItem(TW_CREATIVE_TRENDS_PRIMARY_METRIC, metric);
  }, []);

  const handleSecondaryMetricChange = useCallback((metric: string) => {
    setSecondaryMetric(metric);
    /*  amplitude
      .getInstance()
      .logEvent('Creative Cockpit: Chart Metric Changed', { yAxis: 'right', metric: metric }); */
    localStorage.setItem(TW_CREATIVE_TRENDS_SECONDARY_METRIC, metric);
  }, []);

  const getData = useCallback(() => {
    let linesData: any = [];
    let barsData: any = [];

    linesData = selectedInfluencers?.[0]?.metricsBreakdown?.map?.((d) => ({ date: d.date }))!;
    selectedInfluencers?.forEach((influencer) => {
      influencer.metricsBreakdown?.forEach((bd) => {
        let date = linesData.find((date) => date.date === bd.date);
        if (!date) {
          date = { date: bd.date };
          linesData.push(date);
        }
        date[influencer.id!] = {
          ...bd.metrics,
          id: influencer.id,
          color: influencer.color,
        };
      });
    });

    selectedInfluencers!.forEach((influencer) => {
      barsData.push({
        [primaryMetric]: influencer.metrics
          ? Number(influencer.metrics![primaryMetric]).toFixed(2) || 0
          : 0,
        [secondaryMetric]:
          (influencer.metrics ? Number(influencer.metrics[secondaryMetric]).toFixed(2) : 0) || 0,
        fill: influencer.color,
        opacity: influencer.active ? 1 : 0.1,
      });
    });

    setLineData(linesData);
    setBarData(barsData);
  }, [primaryMetric, secondaryMetric, selectedInfluencers]);

  useEffect(() => {
    getData();
  }, [getData]);

  const getDropdownOptions = () => {
    const metricOptions = Object.values(metrics).filter((m) => m.showInInfluencersHub);
    return metricOptions.map((value) => {
      return {
        label: value.label,
        value: value.key,
      };
    });
  };

  return (
    <div className="blue-chart-wrapper">
      <div className="blue-chart">
        {loading && (
          <div className="loading-creatives-wrapper">
            <div className="loading-creatives">
              <Spinner size="large" />
            </div>
          </div>
        )}
        <div className="blue-chart-top-section gap-4">
          {
            /* <DropDown
            value={chartData}
            handleSelect={setChartData}
            options={[
              { label: 'Influencers', value: 'influencer' },
              /* { label: 'Platform', value: 'platform' }, */
            //]}
            ///>}
          }
          <DropDown
            showFilter
            value={metrics[primaryMetric].key}
            handleSelect={handlePrimaryMetricChange}
            options={getDropdownOptions()}
          />
          <div className="blue-chart-top-title">
            <span>Trends</span>
            <div style={{ width: '44px', height: '28px' }}>
              <BubbleToggleButton
                onClick={() => {
                  setChartMode('line');
                  /*  amplitude
                    .getInstance()
                    .logEvent('Creative Cockpit: Chart Type Changed', { chartType: 'timeseries' }); */
                }}
                active={chartMode === 'line'}
                icon="line-chart"
              />
            </div>
            <div style={{ width: '44px', height: '28px' }}>
              <BubbleToggleButton
                onClick={() => {
                  setChartMode('bar');
                  /* amplitude
                    .getInstance()
                    .logEvent('Creative Cockpit: Chart Type Changed', { charType: 'bars' }); */
                }}
                active={chartMode === 'bar'}
                icon="bar-chart"
              />
            </div>
          </div>
          <DropDown
            showFilter
            value={metrics[secondaryMetric].key}
            handleSelect={handleSecondaryMetricChange}
            options={getDropdownOptions()}
          />
        </div>
        {chartMode === 'line' && (
          <BaseChart
            data={lineData}
            showTooltip={true}
            ChartType={LineChart}
            xAxis={[
              {
                tickFormatter: (value: string) => {
                  if (!moment(value).isValid() || !value) {
                    return '';
                  }
                  if (value.includes('T')) {
                    return moment(value).format('HH:mm');
                  }
                  return moment(value).format('MMM D');
                },
                dataKey: 'date',
              },
            ]}
            yAxis={[
              {
                yAxisId: 'left',
                tickFormatter: (value, index) => {
                  if (index % 2 !== 0) {
                    return '';
                  }
                  return +value < 1000 ? value : +value / 1000 + 'K';
                },
              },
              {
                yAxisId: 'right',
                orientation: 'right',
                tickFormatter: (value, index) => {
                  if (index % 2 !== 0) {
                    return '';
                  }
                  return +value < 1000 ? value : +value / 1000 + 'K';
                },
              },
            ]}
          >
            {selectedInfluencers &&
              selectedInfluencers.map((sel) => {
                return (
                  <Fragment key={sel.id}>
                    <Line
                      yAxisId={'left'}
                      type="monotone"
                      name={metrics[primaryMetric].label}
                      dataKey={(dataPoint) => {
                        return dataPoint[sel.id!] && dataPoint[sel.id!][primaryMetric]
                          ? dataPoint[sel.id!][primaryMetric]
                          : 0;
                      }}
                      stroke={sel.color}
                      strokeWidth={3}
                      strokeOpacity={sel.active ? 1 : 0}
                      dot={false}
                      activeDot={{
                        onClick: () => toggleActive?.(sel),
                        style: {
                          cursor: 'pointer',
                          fill: sel.active ? 'white' : 'transparent',
                          opacity: sel.active ? 1 : 0,
                        },
                      }}
                      onMouseOver={() => lineHovered(sel)}
                      onMouseLeave={() => lineLeft(sel)}
                      onClick={() => toggleActive?.(sel)}
                      style={{ cursor: 'pointer' }}
                    />
                    <Line
                      yAxisId={'left'}
                      type="monotone"
                      name={metrics[secondaryMetric].label}
                      dataKey={(dataPoint) => {
                        return dataPoint[sel.id!] && dataPoint[sel.id!][secondaryMetric]
                          ? dataPoint[sel.id!][secondaryMetric]
                          : 0;
                      }}
                      stroke={sel.color}
                      strokeWidth={3}
                      strokeOpacity={sel.active ? 1 : 0}
                      dot={false}
                      activeDot={{
                        style: { display: 'none' },
                        strokeOpacity: 0,
                      }}
                      strokeDasharray="3,3"
                      style={{ cursor: 'pointer' }}
                    />
                    <ChartTooltip
                      content={
                        <CustomLineTooltip
                          data={selectedInfluencers}
                          primaryMetric={primaryMetric}
                          secondaryMetric={secondaryMetric}
                        />
                      }
                      cursor={{ fill: 'none' }}
                      labelStyle={{ fontWeight: 'bold' }}
                      contentStyle={{ fontSize: '10px' }}
                      formatter={(value: number, metricLabel: string) => {
                        const metric = Object.values(metrics).find(
                          (m) => m.label === metricLabel,
                        )?.key;
                        if (!metric) {
                          return value;
                        }
                        if (!isNaN(value)) {
                          return value;
                        }
                        return value;
                      }}
                      labelFormatter={(value) => {
                        if (moment(value).isValid()) {
                          value = '' + value;
                          return moment(value).format(value.includes('T') ? 'LT' : 'MMM D');
                        }
                        return value;
                      }}
                    />
                  </Fragment>
                );
              })}
          </BaseChart>
        )}
        {chartMode === 'bar' && (
          <BaseChart
            data={barData}
            showTooltip={true}
            ChartType={BarChart}
            xAxis={[
              {
                dataKey: 'date',
                tickFormatter: (value) => {
                  return !!value && moment(value).isValid() ? moment(value).format('MMM D') : '';
                },
              },
            ]}
            yAxis={[
              {
                yAxisId: 'left',
                tickFormatter: (value, index) => {
                  if (index % 2 !== 0) {
                    return '';
                  }
                  return +value < 1000 ? value : +value / 1000 + 'K';
                },
              },
              {
                yAxisId: 'right',
                orientation: 'right',
                tickFormatter: (value, index) => {
                  if (index % 2 !== 0) {
                    return '';
                  }
                  return +value < 1000 ? value : +value / 1000 + 'K';
                },
              },
            ]}
          >
            <defs>
              {INFLUENCERS_COLORS.map((color, index) => (
                <linearGradient
                  key={'color-' + color + '-' + index}
                  id={`color-creative-${index}`}
                  x1="0"
                  y1="0"
                  x2="0"
                  y2="1"
                >
                  <stop offset="0" stopColor={color} stopOpacity={1} />
                  <stop offset="1" stopColor={color} stopOpacity={0} />
                </linearGradient>
              ))}
            </defs>
            <Bar
              barSize={22}
              yAxisId={'left'}
              dataKey={primaryMetric}
              name={(metrics[primaryMetric]?.label as string) || ''}
            ></Bar>
            <Bar
              barSize={22}
              yAxisId={'right'}
              dataKey={secondaryMetric}
              name={(metrics[secondaryMetric]?.label as string) || ''}
            >
              {(chartData === 'influencer' ? selectedInfluencers : [])?.map((entry, index) => (
                <Cell
                  key={`cell-gradient-${secondaryMetric}-${index}`}
                  fill={`url(#color-creative-${index % INFLUENCERS_COLORS.length})`}
                  opacity={entry.active ? 1 : 0.1}
                />
              ))}
            </Bar>
            <ChartTooltip
              cursor={{ fill: 'none' }}
              // content={<></>}
              labelStyle={{ fontWeight: 'bold' }}
              contentStyle={{ fontSize: '10px' }}
              formatter={(value: number, metricLabel: string) => {
                const metric = Object.values(metrics).find((m) => m.label === metricLabel)?.key;
                if (!metric) {
                  return value;
                }
                if (!isNaN(value)) {
                  return formatNumber(value, {
                    style: metrics[metric]?.format || 'decimal',
                    currency,
                    minimumFractionDigits: metrics[metric]?.toFixed,
                    maximumFractionDigits: metrics[metric]?.toFixed,
                  });
                }
                return value;
              }}
              labelFormatter={(value) => {
                if (moment(value).isValid()) {
                  value = '' + value;
                  return moment(value).format(value.includes('T') ? 'LT' : 'MMM D');
                }
                return value;
              }}
            />
          </BaseChart>
        )}
        <div
          className="flex overflow-auto gap-4 p-[var(--padding)]"
          style={{
            paddingInline: chartMode === 'bar' && !isSmall ? '3.75rem 2.5rem' : '',
            justifyContent: chartMode === 'bar' ? 'space-around' : 'flex-end',
            transition: 'all 0.3s ease',
          }}
        >
          {(chartData === 'influencer' ? selectedInfluencers : [])?.map((dataObj) => (
            <BaseLegend
              key={dataObj.id}
              color={dataObj.color ?? '#000'}
              tooltipText={dataObj.name}
              active={dataObj.active}
              content={
                <TWImage
                  src={dataObj.profile_photo ? dataObj.profile_photo.url : ''}
                  wrapperClass="w-full h-full"
                  className="w-full h-full object-cover block rounded-lg"
                />
              }
              onClick={() => toggleActive?.(dataObj)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default InfluencersChart;

const CustomLineTooltip = (
  props: TooltipProps<any, any> & { data: any[]; primaryMetric: string; secondaryMetric: string },
) => {
  const { active, payload, label, data, primaryMetric, secondaryMetric } = props;
  const { currency } = useContext(InfluencersContext);

  const labelFormatter = useCallback((value) => {
    if (moment(value).isValid()) {
      value = '' + value;
      return moment(value).format(value.includes('T') ? 'LT' : 'MMM D');
    }
    return value;
  }, []);

  if (!active) {
    return null;
  }

  const allInfluencersInChart: any[] = Object.values(payload?.[0]?.payload || {});
  if (!allInfluencersInChart?.length) {
    return null;
  }

  return (
    <div className="bg-white dark:bg-[var(--gray-dark-mode-600)] rounded p-4 custom-line-chart-tooltip shadow-md">
      <p className="font-bold mb-4">{labelFormatter(label)}</p>
      <div className=" list-none p-0 m-0 flex flex-col gap-4">
        {allInfluencersInChart.map((c, i) => {
          const influencer = data?.find((el) => el.id === c.id);
          if (!influencer || !c.id) return null;
          return (
            <div
              className="flex gap-4 justify-start text-base"
              key={`${c.id}-${metrics[primaryMetric].label}`}
            >
              <TWImage
                src={influencer.profile_photo ? influencer.profile_photo.url : ''}
                className="w-full h-full object-cover rounded-md cursor-pointer"
                wrapperClass=" w-24 h-24"
                onClick={() => {}}
              />

              <div
                className={`flex flex-col justify-between ${
                  !influencer.active ? 'opacity-50' : ''
                }`}
              >
                <div className="flex gap-2 items-center">
                  <div
                    className="grid grid-cols-1 grid-rows-1 border border-solid rounded-md h-4 w-4 box-content"
                    style={{ borderColor: influencer.color, backgroundColor: influencer.color }}
                  ></div>
                  <Text span>{metrics[primaryMetric].label}:</Text>
                  <Text span>
                    {formatNumber(c[primaryMetric], {
                      currency,
                      style: metrics[primaryMetric].format,
                      minimumFractionDigits: metrics[primaryMetric].toFixed,
                      maximumFractionDigits: metrics[primaryMetric].toFixed,
                    })}
                  </Text>
                </div>
                <div className="flex gap-2 items-center">
                  <div
                    className="grid grid-cols-2 grid-rows-2 border border-solid rounded-md overflow-hidden"
                    style={{ borderColor: influencer.color }}
                  >
                    <div className="w-2 h-2" style={{ backgroundColor: influencer.color }}></div>
                    <div className="w-2 h-2" style={{ backgroundColor: 'white' }}></div>
                    <div className="w-2 h-2" style={{ backgroundColor: 'white' }}></div>
                    <div className="w-2 h-2" style={{ backgroundColor: influencer.color }}></div>
                  </div>
                  <Text span>{metrics[secondaryMetric].label}:</Text>
                  <Text span>
                    {formatNumber(c[secondaryMetric], {
                      currency,
                      style: metrics[secondaryMetric].format,
                      minimumFractionDigits: metrics[secondaryMetric].toFixed,
                      maximumFractionDigits: metrics[secondaryMetric].toFixed,
                    })}
                  </Text>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
