import { useStoreValue } from '@tw/snipestate';
import { ActionIcon } from '@tw/ui-components';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import {
  MiniMap,
  ReactZoomPanPinchContentRef,
  TransformComponent,
  TransformWrapper,
} from 'react-zoom-pan-pinch';
import { useWindowSize } from 'utils/useWindowSize';
import { SequenceFlow } from './SequenceFlow';
import { $navWidth } from '$stores/nav-config-stores';
import { Link } from 'react-router-dom';

type WorkflowBuilderWrapperProps = {
  workflowId?: string;
  variables?: Record<string, string>;
  runOnInit?: boolean;
  withMiniMap?: boolean;
  canAddNewStep?: boolean;
  zoom?: number;
  readOnly?: boolean;
  initialPositionY?: number;
  initialPositionX?: number;
  canvasSize?: string;
  navBarWidth?: number;
  rephraseStepsBasedOnConversation?: string;
};

const DEFAULT_CANVAS_SIZE = '5000';

const excludedElements = [
  'input',
  'textarea',
  'button',
  'open-step-drawer-trigger ',
  'preview-webhook-step',
  'switch-root',
  'minimap',
];

export const WorkflowBuilderWrapper: React.FC<WorkflowBuilderWrapperProps> = ({
  workflowId,
  variables,
  runOnInit,
  withMiniMap = true,
  canAddNewStep = true,
  canvasSize = DEFAULT_CANVAS_SIZE,
  navBarWidth,
  zoom = 1,
  initialPositionY,
  initialPositionX,
  readOnly = false,
  rephraseStepsBasedOnConversation,
}) => {
  const isSmall = useIsSmall();
  const windowSize = useWindowSize();
  const navWidth = useStoreValue($navWidth);
  const zoomPanPinchContentRef = useRef<ReactZoomPanPinchContentRef>(null);
  const INITIAL_SCALE = isSmall ? 0.5 : zoom;

  const initialCenter = useMemo(() => {
    const barWidth = navBarWidth ?? navWidth;
    const builderAreaWidth = isSmall
      ? windowSize.windowSize.width
      : windowSize.windowSize.width - barWidth;

    const canvasSizeNumber = parseInt(canvasSize);
    const middleOfCanvas = canvasSizeNumber / 2;
    const adjustedBuilderAreaWidth = builderAreaWidth / INITIAL_SCALE;
    const middleOfBuilderArea = middleOfCanvas - adjustedBuilderAreaWidth / 2;

    return middleOfBuilderArea;
  }, [INITIAL_SCALE, isSmall, navWidth, windowSize, navBarWidth, canvasSize]);

  useEffect(() => {
    if (!zoomPanPinchContentRef.current) return;
    zoomPanPinchContentRef.current.resetTransform();
  }, [initialPositionX]);

  return (
    <TransformWrapper
      ref={zoomPanPinchContentRef}
      minScale={0.1}
      maxScale={2}
      initialScale={INITIAL_SCALE}
      initialPositionY={initialPositionY}
      initialPositionX={initialPositionX ?? initialCenter * -1}
      centerOnInit={true}
      panning={{
        excluded: excludedElements,
        disabled: false,
        wheelPanning: true,
      }}
      centerZoomedOut={true}
      smooth
      wheel={{
        wheelDisabled: true,
        smoothStep: 0.005,
        excluded: excludedElements,
      }}
      doubleClick={{
        excluded: excludedElements,
        disabled: true,
      }}
      pinch={{
        step: 100,
        excluded: excludedElements,
      }}
      limitToBounds={false}
      alignmentAnimation={{ disabled: true }}
    >
      <TransformComponent wrapperClass="cursor-grab !h-full">
        <FlowElement
          runOnInit={runOnInit}
          workflowId={workflowId}
          variables={variables}
          canAddNewStep={canAddNewStep}
          canvasSize={canvasSize}
          readOnly={readOnly}
          rephraseStepsBasedOnConversation={rephraseStepsBasedOnConversation}
        />
      </TransformComponent>
      <div
        className="minimap absolute top-10 left-10 items-center gap-2 hidden sm:flex sm:flex-col z-[100]"
        onScroll={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        {!!readOnly && (
          <div className="text-gray-5 text-lg !no-underline">
            <Link
              to={{
                pathname: `/workflows/create/${workflowId}`,
                search: window.location.search,
              }}
              className="!no-underline text-inherit"
              target="_blank"
            >
              Edit in builder
            </Link>
          </div>
        )}
        {withMiniMap && (
          <MiniMap width={100} height={100}>
            <FlowElement
              runOnInit={false}
              workflowId={workflowId}
              variables={variables}
              canAddNewStep={canAddNewStep}
              canvasSize={canvasSize}
              readOnly={readOnly}
              rephraseStepsBasedOnConversation={rephraseStepsBasedOnConversation}
            />
          </MiniMap>
        )}
        <div className="controls">
          <ActionIcon
            icon="plus"
            onClick={() => {
              zoomPanPinchContentRef.current?.zoomIn();
            }}
          />
          <ActionIcon
            icon="minus"
            onClick={() => {
              zoomPanPinchContentRef.current?.zoomOut();
            }}
          />
          <ActionIcon
            icon="reset"
            onClick={() => {
              zoomPanPinchContentRef.current?.resetTransform();
            }}
          />
        </div>
      </div>
    </TransformWrapper>
  );
};

const FlowElement: React.FC<WorkflowBuilderWrapperProps> = ({
  runOnInit,
  workflowId,
  variables,
  canAddNewStep = true,
  canvasSize = DEFAULT_CANVAS_SIZE,
  readOnly = false,
  rephraseStepsBasedOnConversation,
}) => {
  return (
    <div
      className={`relative flex flex-col items-center pt-[40px]`}
      style={{
        width: `${canvasSize}px`,
        maxWidth: `${canvasSize}px`,
        height: `${canvasSize}px`,
        maxHeight: `${canvasSize}px`,
      }}
    >
      <SequenceFlow
        sequenceId={workflowId ?? null}
        depth={0}
        isFirstSibling={false}
        isLastSibling={false}
        noEdit={readOnly}
        variables={variables}
        runOnInit={runOnInit}
        canAddNewStep={canAddNewStep}
        rephraseStepsBasedOnConversation={rephraseStepsBasedOnConversation}
      />
    </div>
  );
};
