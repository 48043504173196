import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Modal } from '@shopify/polaris';
import { toggleShowingModal } from '../../ducks/actions/tilesSettingsModalActions';
import ReorderList from '../ReorderList';
import { sectionTilesOrderChanged } from '../../ducks/summary';
import ConnectedLists from '../ConnectedLists';
import SECTIONS from '../../constants/defaultTileSections';
import { selectAllMetrics, selectAllSections } from 'utils/selectors';
import { type RootState } from 'reducers/RootType';
import { useAppDispatch } from 'index';
import { BaseSummaryMetric } from '@tw/types/module/SummaryMetrics';
import { getDefaultHiddenTiles, getDefaultShownTiles } from 'utils/getDefaultTiles';
import { useStoreValue } from '@tw/snipestate';
import { $isFreeShop } from '../../$stores/willy/$subscription';

export const TilesSectionSettingsModal: React.FC = () => {
  const dispatch = useAppDispatch();

  const [tileMetrics, setTileMetrics] = useState<any[]>([]);
  const [hiddenTileMetrics, setHiddenTileMetrics] = useState<BaseSummaryMetric<any>[]>([]);
  const isFreeShop = useStoreValue($isFreeShop);
  const { isShowingModal, currentSection } = useSelector(
    (state: RootState) => state.tileModalSettings,
  );

  const summarySectionsCustomization = useSelector(
    (state: RootState) => state.summarySectionsCustomization,
  );

  const state = useSelector((state: RootState) => state);

  const dragChange = (currentSectionId, tiles) => {
    dispatch(sectionTilesOrderChanged(currentSectionId, tiles));
  };

  const allMetrics = useSelector(selectAllMetrics);
  const allSections = useSelector(selectAllSections);
  useEffect(() => {
    if (!currentSection?.tiles) return;

    if (summarySectionsCustomization[currentSection.id]?.tiles) {
      const tilesInTheSection = summarySectionsCustomization[currentSection.id].tiles || [];
      const reorderedRes = tilesInTheSection
        .map((tile) => allMetrics.find((j) => j.id === tile))
        .filter((tile): tile is BaseSummaryMetric<any> => !!tile);
      setTileMetrics(reorderedRes);

      const defaultTileSection = allSections.find((s) => s.id === currentSection.id);
      if (!defaultTileSection) return;

      const hiddenDefaultTileIds = (getDefaultHiddenTiles(isFreeShop, defaultTileSection) || [])
        .concat(currentSection.tiles || [])
        .filter((x) => !reorderedRes.some((m) => m?.id === x));
      setHiddenTileMetrics(allMetrics.filter((m) => hiddenDefaultTileIds.includes(m.id)));
      return;
    }

    const defaultTiles =
      getDefaultShownTiles(isFreeShop, currentSection)
        ?.map((i) => allMetrics.find((j) => j.id === i))
        .filter((x) => x) || [];
    const defaultHiddenTiles =
      getDefaultHiddenTiles(isFreeShop, currentSection)
        ?.map((i) => allMetrics.find((j) => j.id === i))
        .filter((x) => x) || [];

    const shownTiles = currentSection.isHideTilesByDefault ? [] : defaultTiles;
    const hiddenTiles = (currentSection.isHideTilesByDefault ? defaultTiles : [])
      .concat(defaultHiddenTiles)
      .filter((tile): tile is BaseSummaryMetric<any> => !!tile);

    setTileMetrics(shownTiles.filter((x) => (x?.isShow ? x.isShow(state) : true)));
    setHiddenTileMetrics(hiddenTiles.filter((x) => (x.isShow ? x.isShow(state) : true)));
  }, [currentSection, summarySectionsCustomization, allMetrics, allSections, isFreeShop]);

  const hideTile = useCallback(
    (metric) => {
      const newTiles = tileMetrics.filter((tile) => tile.id !== metric.id);
      dispatch(sectionTilesOrderChanged(currentSection?.id, newTiles));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tileMetrics, currentSection],
  );
  const showTile = useCallback(
    (metric) => {
      const newTiles = tileMetrics.concat([metric]);
      dispatch(sectionTilesOrderChanged(currentSection?.id, newTiles));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tileMetrics, currentSection],
  );
  const isPinned = currentSection && ['pinned'].includes(currentSection.id);
  const isCustom = currentSection && !SECTIONS.find((s) => s.id === currentSection.id);
  const isPacing = currentSection && currentSection.id === 'pacing';

  return (
    <>
      {currentSection &&
        tileMetrics && ( // tileMetrics.length && (
          // Should be able to show this popup even when no tiles (for default sections)
          <Modal
            open={isShowingModal}
            onClose={() => dispatch(toggleShowingModal(false))}
            title={currentSection.title + ' Settings'}
          >
            <Modal.Section>
              <div className="summaryOrderingSection">
                {(isCustom || isPinned) && (
                  <ReorderList
                    currentSectionId={currentSection && currentSection.id}
                    items={tileMetrics}
                    orderOnChange={dragChange}
                    itemHasLeftButton={undefined}
                    itemLeftButtonIcon={undefined}
                    itemLeftButtonClick={undefined}
                  />
                )}
                {!(isCustom || isPinned) && (
                  <ConnectedLists
                    items1={tileMetrics}
                    items2={hiddenTileMetrics}
                    title1={!isPinned ? 'INCLUDE' : undefined}
                    title2={!isPinned && !isPacing ? 'MORE TILES' : undefined}
                    currentSectionId={currentSection.id}
                    items1orderOnChange={dragChange}
                    itemMoveFrom1to2onPress={hideTile}
                    itemMoveFrom2to1onPress={showTile}
                  />
                )}
              </div>
            </Modal.Section>
          </Modal>
        )}
    </>
  );
};
