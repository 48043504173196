import { toggleShowingModalSection } from 'ducks/actions/modalCustomSectionActions';
import { toggleCustomMetricModal } from 'ducks/customMetrics';
import {
  applyGridDesktop,
  applyGridDesktopColumns,
  applySummaryFullWidth,
  applyTwoColumnMobile,
  RoundingOptions,
  summarySettingsButtonOnPress,
  updateDefaultRoundingOption,
} from 'ducks/summary';
import { useAppDispatch } from 'index';
import moment from '@tw/moment-cached/module/timezone';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { type RootState } from 'reducers/RootType';
import { CurrencyButton } from 'components/CurrencyButton';
import SummaryDatePicker from 'components/SummaryDatePicker';
import SummaryRefreshButton from 'components/SummaryRefreshButton';
import { selectAllSections } from 'utils/selectors';
import { SummarySection } from 'types/SummarySection';
import {
  analyticsEvents,
  dashboardsActions,
  genericEventLogger,
  summaryActions,
  summaryMessages,
} from 'utils/dataLayer';
import { useFeatureFlag } from 'feature-flag-system';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { $activeAppVersion, useActiveRoute } from '$stores/nav-config-stores';
import LockedFeatureIndicator from 'components/library/LockedFeatures/LockedFeatureIndicator';
import { StatsFilterBar } from 'components/StatsFilterBar';
import { useFeatureFlagSummarySectionFilter } from 'hooks/useFeatureFlagSummarySectionFilter';
import {
  ActionIcon,
  Button as TWButton,
  Checkbox,
  Flex,
  Icon,
  Menu,
  SegmentedControl,
  Switch,
  Text,
  Tooltip,
} from '@tw/ui-components';
import { WillyMainChatRef } from 'components/Willy/types/willyTypes';
import { SummarySectionPicker } from 'components/SummarySectionPicker/SummarySectionPicker';
import { useSummaryScroll } from 'components/SummarySectionPicker';
import { useStoreValue, useWritableStore } from '@tw/snipestate';
import { $forceSharded, $useClickHouseInSummary, $useSummaryWillyWay } from '$stores/$shop';
import { MetricsCurrentlyFiltered } from 'components/MetricsCurrentlyFiltered';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';
import { useNavigate, useLocation } from 'react-router-dom';
import { refreshDateRanges } from '$stores/willy/$dateRange';

type SummaryHeaderProps = {
  chatOpen?: boolean;
  setChatOpen?: (val: boolean) => void;
  mainChatRef?: React.RefObject<WillyMainChatRef>;
};

const SummaryHeader: React.FC<SummaryHeaderProps> = ({ chatOpen, setChatOpen, mainChatRef }) => {
  const dispatch = useAppDispatch();
  const { shouldNotBeSeen: createCustomMetricsLocked } = useFeatureFlag(
    FeatureFlag.CUSTOM_METRICS_FF,
  );
  const { shouldNotBeSeen: isChatBlocked } = useFeatureFlag(FeatureFlag.CHAT_FF);
  const { summarySectionsFilter } = useFeatureFlagSummarySectionFilter();

  const mainDatePickerSelectionRange = useSelector(
    (state: RootState) => state.mainDatePickerSelectionRange,
  );
  const { search } = useLocation();
  const navigate = useNavigate();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const { isAddModalSection } = useSelector((state: RootState) => state.modalCustomSection);

  useEffect(() => {
    const openModalSection = searchParams.get('openModalSection');
    if (openModalSection && openModalSection === '1') {
      dispatch(toggleShowingModalSection(true));
    }
  }, [searchParams, dispatch]);

  useEffect(() => {
    if (isAddModalSection) {
      searchParams.set('openModalSection', '1');
    } else {
      searchParams.delete('openModalSection');
    }

    navigate(
      { pathname: window.location.pathname, search: searchParams.toString() },
      { replace: true },
    );
  }, [navigate, isAddModalSection, searchParams]);

  const isSmall = useIsSmall();

  const currentShopId = useSelector((state: RootState) => state.currentShopId);
  const twoColumnMobile = useSelector((state: RootState) => state.twoColumnMobile);
  const gridDesktop = useSelector((state: RootState) => state.gridDesktop);
  const gridDesktopColumns = useSelector((state: RootState) => state.gridDesktopColumns);
  const summaryFullWidth = useSelector((state: RootState) => state.summaryFullWidth);
  const isAdmin = useSelector((state: RootState) => state.user.isAdminClaim);
  const [useClickHouseInSummary, setUseClickHouseInSummary] =
    useWritableStore($useClickHouseInSummary);
  const [useSummaryWillyWay, setUseSummaryWillyWay] = useWritableStore($useSummaryWillyWay);
  const [forceSharded, setForceSharded] = useWritableStore($forceSharded);
  const { activeRoute } = useActiveRoute();
  const isBenchmarks = activeRoute?.label === 'Benchmarks';
  const activeAppVersion = useStoreValue($activeAppVersion);
  const isThreePointOh = activeAppVersion === '3.0';

  let sections: SummarySection[] = useSelector(selectAllSections);
  sections = sections.filter(summarySectionsFilter);

  const [isPopoverActive, setIsPopoverActive] = useState(false);

  const shouldShowRefreshButton = !mainDatePickerSelectionRange || isBenchmarks ? false : true;

  const defaultRoundingOption: RoundingOptions | null = useSelector(
    (state: RootState) => state.defaultRoundingOption,
  );
  const isAmazonConnected: boolean = useSelector(
    (state: RootState) =>
      state.isAmazonConnectedNA || state.isAmazonConnectedEU || state.isAmazonConnectedFE,
  );

  const clickOnRefresh = () => {
    refreshDateRanges();
  };

  const createNewSection = useMemo(
    () => (
      <>
        <Menu.Item
          onClick={() => {
            dispatch(toggleShowingModalSection(true));
            setIsPopoverActive(false);
          }}
        >
          <Text color="named2.0" size="sm" weight={500}>
            Create Section
          </Text>
        </Menu.Item>
        <Menu.Item
          disabled={createCustomMetricsLocked}
          onClick={() => {
            dispatch(toggleCustomMetricModal({ isModalOpen: true }));
            setIsPopoverActive(false);
          }}
          leftSection={
            createCustomMetricsLocked && (
              <LockedFeatureIndicator
                iconOnly
                iconSize={20}
                featureFlag={FeatureFlag.CUSTOM_METRICS_FF}
              />
            )
          }
        >
          <Text color="named2.0" size="sm" weight={500}>
            Create Custom Metric
          </Text>
        </Menu.Item>
      </>
    ),
    [createCustomMetricsLocked, dispatch],
  );

  const layoutSection = (
    <>
      <Menu.Label tt="uppercase" fw={500} color="gray.5">
        Layout
      </Menu.Label>
      {!isBenchmarks && (
        <Menu.Item
          onClick={() => {
            dispatch(summarySettingsButtonOnPress());
            setIsPopoverActive(false);
          }}
        >
          <Text color="named2.0" size="sm" weight={500}>
            Manage Sections
          </Text>
        </Menu.Item>
      )}
      {!isSmall && (
        <Menu.Item>
          <Flex align="center" gap="sm">
            <Text fw={500} color="named2.0" size="sm">
              Grid Layout
            </Text>
            <SegmentedControl
              data={[
                { label: 'Auto', value: 'auto' },
                { label: '2', value: '2' },
                { label: '3', value: '3' },
                { label: '4', value: '4' },
              ]}
              size="sm"
              value={gridDesktop ? `${gridDesktopColumns}` : 'auto'}
              onChange={(value) => {
                if (value === 'auto') {
                  dispatch(applyGridDesktop(true));
                  genericEventLogger(analyticsEvents.SUMMARY, {
                    action: summaryActions.DESKTOP_GRID_CHANGE,
                    shop: currentShopId,
                    grid: gridDesktop ? 'grid' : 'flex',
                    description: `Desktop Layout: Changed to ${gridDesktop ? 'grid' : 'flex'}`,
                  });
                } else {
                  dispatch(applyGridDesktopColumns(parseInt(value)));
                  dispatch(applyGridDesktop(false));
                  genericEventLogger(analyticsEvents.SUMMARY, {
                    action: summaryActions.DESKTOP_GRID_CHANGE,
                    shop: currentShopId,
                    grid: gridDesktop ? 'grid' : 'flex',
                    columns: gridDesktopColumns,
                    description: `Desktop Layout: Changed to grid with ${value} columns`,
                  });
                }
              }}
            />
          </Flex>
        </Menu.Item>
      )}
      {!isSmall && (
        <Menu.Item>
          <Switch
            checked={summaryFullWidth}
            onChange={() => {
              dispatch(applySummaryFullWidth(summaryFullWidth));
              genericEventLogger(analyticsEvents.SUMMARY, {
                action: summaryActions.DESKTOP_FULL_WIDTH_CHANGE,
                shop: currentShopId,
                isFullWidth: summaryFullWidth,
                columns: gridDesktopColumns,
                description: `Desktop Full Width: Changed to ${
                  summaryFullWidth ? 'full width' : 'max width (constrained)'
                }`,
              });
            }}
            label={
              <Text size="sm" weight={500} color="named2.0">
                Full Width
              </Text>
            }
            labelPosition="left"
            size="sm"
            variant="short"
          />
        </Menu.Item>
      )}
      {isSmall && (
        <Menu.Item>
          <Switch
            checked={twoColumnMobile}
            onChange={() => {
              dispatch(applyTwoColumnMobile(twoColumnMobile));
              genericEventLogger(analyticsEvents.SUMMARY, {
                action: summaryActions.MOBILE_COLUMN_CHANGE,
                shop: currentShopId,
                columns: twoColumnMobile ? 2 : 1,
                description: summaryMessages.MOBILE_COLUMN_CHANGE(twoColumnMobile),
              });
            }}
            label={
              <Text size="sm" weight={500} color="named2.0">
                Two Columns
              </Text>
            }
            labelPosition="left"
            size="sm"
            variant="short"
          />
        </Menu.Item>
      )}
    </>
  );

  const adminSection = useMemo(
    () => (
      <>
        <Menu.Label tt="uppercase" fw={500} color="gray.5">
          Admin
        </Menu.Label>
        <Menu.Item>
          <Checkbox
            checked={useClickHouseInSummary}
            label={
              <Text size="sm" weight={500} color="named2.0">
                Ads on CH
              </Text>
            }
            onChange={(checked) => {
              setUseClickHouseInSummary(checked);
            }}
          />
        </Menu.Item>
        <Menu.Item>
          <Checkbox
            checked={useSummaryWillyWay}
            label={
              <Text size="sm" weight={500} color="named2.0">
                Fully CH
              </Text>
            }
            onChange={(checked) => {
              setUseSummaryWillyWay(checked);
            }}
          />
        </Menu.Item>
        <Menu.Item>
          <Checkbox
            checked={forceSharded}
            label={
              <Text size="sm" weight={500} color="named2.0">
                Force Sharded
              </Text>
            }
            onChange={(checked) => {
              setForceSharded(checked);
            }}
          />
        </Menu.Item>
      </>
    ),
    [
      setUseClickHouseInSummary,
      setUseSummaryWillyWay,
      setForceSharded,
      useClickHouseInSummary,
      useSummaryWillyWay,
      forceSharded,
    ],
  );

  const includeAmazonSection = useMemo(
    () => (
      <Menu.Item>
        <StatsFilterBar />
      </Menu.Item>
    ),
    [],
  );

  const currencySection = useMemo(
    () => (
      <>
        <Menu.Item>
          <CurrencyButton
            isShopContext={true}
            activator={(currency) => (
              <Flex align="center" justify="space-between">
                <Text size="sm" weight={500} color="named2.0">
                  Currency: {currency}
                </Text>
                <Icon name="chevron-right-minor" size={12} color="named.8" />
              </Flex>
            )}
            dropdownPosition={'right-start'}
            dropDownOffset={16}
            withinPortal={false}
          />
        </Menu.Item>
        <Menu.Item>
          <Flex direction="column" gap="sm">
            <Text size="sm" weight={500} color="named2.0">
              Currency Display
            </Text>
            <SegmentedControl
              data={[
                { label: '0', value: RoundingOptions.ROUND },
                { label: '.0', value: RoundingOptions.ONE_DECIMAL },
                { label: '.00', value: RoundingOptions.TWO_DECIMALS },
                { label: 'Clear', value: '' },
              ]}
              value={defaultRoundingOption || ''}
              onChange={(value) => {
                const newValue = value === '' ? null : value;
                dispatch(updateDefaultRoundingOption(newValue));
              }}
              size="sm"
            />
          </Flex>
        </Menu.Item>
      </>
    ),
    [defaultRoundingOption, dispatch],
  );

  const chatWithQuerySection = useMemo(
    () => (
      <Tooltip label={isChatBlocked ? 'Upgrade to Access Chat' : 'Chat with Report'}>
        <TWButton
          leftSection="star-plus"
          iconSize={16}
          variant="secondary"
          size="xs"
          onClick={() => {
            setChatOpen?.(!chatOpen);
            if (!chatOpen) {
              genericEventLogger(analyticsEvents.DASHBOARDS, {
                action: dashboardsActions.OPEN_CHAT,
              });
            }
            mainChatRef?.current?.focusTextInput();
          }}
          disabled={isChatBlocked}
        >
          Moby
        </TWButton>
      </Tooltip>
    ),
    [chatOpen, isChatBlocked, mainChatRef, setChatOpen],
  );

  useSummaryScroll(sections);

  return (
    <Flex w="100%" align="center" gap="xs" wrap={`${isThreePointOh ? 'nowrap' : 'wrap'}`}>
      {isThreePointOh && !isSmall && (
        <Flex align="center" pl="sm" gap="sm">
          <Icon name="house" color={'gray.5'} size={18} />
          <Text color="gray.7" weight={500} size="lg">
            Summary
          </Text>
        </Flex>
      )}
      <MetricsCurrentlyFiltered />
      {shouldShowRefreshButton && !isThreePointOh && (
        <SummaryRefreshButton hideLabel={true} customOnPress={clickOnRefresh} />
      )}
      {!isBenchmarks && !isThreePointOh && <SummarySectionPicker sections={sections} />}
      <SummaryDatePicker
        showGroupByButton={true}
        compareSeparately={!!isThreePointOh}
        buttonSize={isThreePointOh ? 'xs' : undefined}
        showCompareButton={true}
      />
      <div className={`dash-menu-right-area flex gap-4 ${isThreePointOh ? 'ml-auto' : ''}`}>
        <Menu
          opened={isPopoverActive}
          onClose={() => setIsPopoverActive(!isPopoverActive)}
          closeOnItemClick={false}
        >
          <Menu.Target>
            {!isThreePointOh ? (
              <div>
                <ActionIcon
                  id="summary-settings"
                  size="lg"
                  radius="sm"
                  onClick={() => setIsPopoverActive(!isPopoverActive)}
                  variant="activator"
                  icon="horizontal-slider"
                />
              </div>
            ) : (
              <TWButton
                id="summary-settings"
                size="xs"
                onClick={() => setIsPopoverActive(!isPopoverActive)}
              >
                Edit Report
              </TWButton>
            )}
          </Menu.Target>
          <Menu.Dropdown>
            {!isBenchmarks && (
              <>
                {createNewSection}
                <Menu.Divider />
              </>
            )}
            {isAmazonConnected && !isThreePointOh ? (
              <>
                {includeAmazonSection} <Menu.Divider />
              </>
            ) : null}
            {layoutSection}
            {!!isAdmin && (
              <>
                <Menu.Divider />
                {adminSection}
              </>
            )}
            {!isThreePointOh && (
              <>
                <Menu.Divider />
                {currencySection}
              </>
            )}
          </Menu.Dropdown>
        </Menu>
        {isThreePointOh && !isChatBlocked && chatWithQuerySection}
      </div>
      {!isBenchmarks && isThreePointOh && (
        <SummarySectionPicker sections={sections} buttonSize="xs" />
      )}
      {shouldShowRefreshButton && isThreePointOh && (
        <SummaryRefreshButton hideLabel={true} customOnPress={clickOnRefresh} buttonSize="md" />
      )}
      {isThreePointOh && (
        <Menu closeOnItemClick={false}>
          <Menu.Target>
            <div>
              <ActionIcon icon="three-dots" variant="activator" />
            </div>
          </Menu.Target>
          <Menu.Dropdown>
            {isAmazonConnected && (
              <>
                {includeAmazonSection} <Menu.Divider />
              </>
            )}
            {!!isAdmin && (
              <>
                <Menu.Divider />
                {adminSection}
              </>
            )}
            {currencySection}
          </Menu.Dropdown>
        </Menu>
      )}
    </Flex>
  );
};

export default SummaryHeader;
