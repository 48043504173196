import TWImage from 'components/library/TWImage/TWImage';
import allServices from 'constants/services';
import { mediaPicker } from 'ducks/contentHub/mediaPicker';
import { useAppDispatch } from 'index';
import { take } from 'lodash';
import QueryString from 'qs';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { type RootState } from 'reducers/RootType';
import {
  AttributionData,
  AttributionMainResponse,
  AttributionStatsRequest,
} from 'types/attribution';
import { AdTypeToGenerate } from 'types/general';
import axiosInstance from 'utils/axiosInstance';

import {
  Button,
  FormLayout,
  Modal,
  Select,
  Spinner,
  Stack,
  TextField,
  TextStyle,
} from '@shopify/polaris';
import { DeleteMinor, ImagesMajor } from '@shopify/polaris-icons';
import { MediaItemType } from '@tw/types';
import { shopIntegrations } from 'ducks/shopIntegrations';

type CreateAdProps = {
  open: boolean;
  onClose: () => any;
  campaigns: AttributionData[];
  getRequestParams: (campaignId?: string, adsetId?: string) => any;
};
const CreateAd: React.FC<CreateAdProps> = ({ open, onClose, campaigns, getRequestParams }) => {
  const dispatch = useAppDispatch();
  const currentShopId = useSelector((state: RootState) => state.currentShopId);
  const integrations = useSelector(shopIntegrations);
  const [headlines, setHeadlines] = useState<string[]>(['']);
  const [bodies, setBodies] = useState<string[]>(['']);
  const [action, setAction] = useState<string>('LEARN_MORE');
  const [page, setPage] = useState<string>();
  const [instagramAccount, setInstagramAccount] = useState<string>();
  const [account, setAccount] = useState<string>('');

  const [campaign, setCampaign] = useState('');
  const [adset, setAdset] = useState('');
  const [ad, setAd] = useState('New ad from Triple Whale');
  const [link, setLink] = useState('');
  const [images, setImages] = useState<MediaItemType[]>();
  const [videos, setVideos] = useState<MediaItemType[]>();

  const [loadingAdsets, setLoadingAdsets] = useState(false);
  const [adsets, setAdsets] = useState<any[]>([]);
  const [callToActions, setCallToActions] = useState<any[]>([]);
  const [pages, setPages] = useState<any[]>([]);
  const [loadingPages, setLoadingPages] = useState(false);
  const [instagramAccounts, setInstagramAccounts] = useState<any[]>([]);
  const [adType, setAdType] = useState<AdTypeToGenerate>('image');
  const user = useSelector((state: RootState) => state.userEmail);

  const isDynamic = adType === 'dynamic';

  const onSubmit = useCallback(async () => {
    const imagesToTake = take(
      images?.filter((x) => x.media_type === 'image').map((x) => x.url),
      5,
    );
    const videosToTake = take(
      videos?.filter((x) => x.media_type === 'video').map((x) => x.url),
      5,
    );
    const params = {
      shopId: currentShopId,
      accountId: account,
      adsetId: adset,
      adName: ad,
      params: {
        titles: headlines,
        bodies,
        callToActionTypes: [action],
        type: adType,
        link,
        imageUrls: imagesToTake,
        videoUrls: videosToTake,
        pageId: page,
        instagramAccountId: instagramAccount,
      },
      user,
    };
    const { data } = await axiosInstance.post('/v2/facebook-ads/create-ad-with-creative', params);
    if (data.error) {
      toast.error(data.error);
    } else {
      toast.success(
        <div className="flex flex-col gap-4">
          <TextStyle>Ad created</TextStyle>
          <TextStyle variation="subdued">
            <a
              target="_blank"
              href={
                allServices['facebook-ads']?.externalAdsUrl?.(
                  'facebook-ads',
                  'ad',
                  account,
                  campaign,
                  adset,
                  data.ad,
                ) || ''
              }
              rel="noreferrer"
            >
              Link {data.ad}
            </a>
          </TextStyle>
        </div>,
      );
      onClose();
    }
  }, [
    account,
    action,
    ad,
    adType,
    adset,
    bodies,
    campaign,
    currentShopId,
    headlines,
    instagramAccount,
    link,
    images,
    videos,
    page,
    onClose,
  ]);

  const fetchData = useCallback(async () => {
    const requestParams = await getRequestParams(campaign);
    if (!requestParams) {
      return;
    }
    const { params } = requestParams;

    setLoadingAdsets(true);
    const { data } = await axiosInstance.post<
      any,
      { data: AttributionMainResponse },
      AttributionStatsRequest
    >(`/v2/attribution/get-all-stats`, params);

    const { stats } = data.data;
    setLoadingAdsets(false);
    setAdsets(stats);
  }, [campaign, getRequestParams]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    const func = async () => {
      const { data } = await axiosInstance.get('/v2/facebook-ads/get-call-to-actions');
      setCallToActions(data);
    };
    func();
  }, []);

  useEffect(() => {
    if (!account) {
      return;
    }
    const func = async () => {
      const params = {
        shop_id: currentShopId,
        account_id: account,
      };

      setLoadingPages(true);
      const [{ data: pagesData }, { data: InstData }] = await Promise.all([
        axiosInstance.get(
          '/v2/facebook-ads/get-pages' + QueryString.stringify(params, { addQueryPrefix: true }),
        ),
        axiosInstance.get(
          '/v2/facebook-ads/get-instagram-accounts' +
            QueryString.stringify(params, { addQueryPrefix: true }),
        ),
      ]);
      setPages(pagesData);
      setInstagramAccounts(InstData);
      setLoadingPages(false);
    };
    func();
  }, [account, currentShopId]);

  useEffect(() => {
    setImages([]);
    setVideos([]);
  }, [adType]);

  const mediaPickerButton = useCallback(
    (type) => {
      let label = '';
      if (adType === 'dynamic') {
        label = type === 'image' ? 'Images (maximum 5)' : 'Videos (maximum 5)';
      } else {
        label = type === 'image' ? 'Image' : 'Video';
      }
      const media = (type === 'image' ? images : videos) || [];
      const setMedia = type === 'image' ? setImages : setVideos;
      return (
        <Stack.Item>
          <Stack vertical spacing="tight">
            <Stack.Item>
              <TextStyle>{label}</TextStyle>
            </Stack.Item>
            <Stack.Item>
              <Stack alignment="center">
                <Button
                  icon={ImagesMajor}
                  onClick={() =>
                    dispatch(
                      mediaPicker({
                        onSelect: (media) => {
                          setMedia(media);
                        },
                        maxFiles: adType === 'dynamic' ? 5 : 1,
                        preSelected: media,
                        allowTypes: [type],
                        selectedMedia: [],
                      }),
                    )
                  }
                />
                <TextStyle variation="subdued">
                  Select file{adType === 'dynamic' ? 's' : ''}
                </TextStyle>
              </Stack>
            </Stack.Item>
            <Stack.Item>
              <Stack>
                {media?.map((x) => (
                  <Stack.Item key={x?.url}>
                    <div className="relative group">
                      <TWImage
                        src={x?.url}
                        className="w-full h-full object-cover rounded-md"
                        wrapperClass="w-32 h-32 opacity-100 group-hover:opacity-40 transition-opacity"
                      />
                      <div className="absolute top-2 left-2">
                        <DeleteMinor
                          width={16}
                          height={16}
                          className="stroke-red-500 cursor-pointer opacity-0 transition-opacity group-hover:opacity-100"
                          onClick={() => {
                            setMedia((prev) => {
                              const copy = structuredClone(prev) || [];
                              const index = copy.findIndex((c) => c.url === x.url);
                              copy.splice(index, 1);
                              return copy;
                            });
                          }}
                        />
                      </div>
                    </div>
                  </Stack.Item>
                ))}
              </Stack>
            </Stack.Item>
          </Stack>
        </Stack.Item>
      );
    },
    [adType, dispatch, images, videos],
  );

  return (
    <Modal sectioned title="Create new Ad" open={open} onClose={onClose}>
      <Stack spacing="extraLoose" vertical>
        <Stack.Item>
          <FormLayout>
            <Select
              value={adType}
              options={[
                { label: 'Single Text', value: 'text' },
                { label: 'Single Image', value: 'image' },
                { label: 'Single Video', value: 'video' },
                { label: 'Dynamic', value: 'dynamic' },
              ]}
              onChange={(type: AdTypeToGenerate) => setAdType(type)}
              label="Ad type"
              requiredIndicator
            />
          </FormLayout>
        </Stack.Item>
        {adType !== 'text' && mediaPickerButton('image')}
        {adType === 'video' && mediaPickerButton('video')}
        {adType === 'dynamic' && (
          <TextStyle variation="subdued">Currently we're not supporting dynamic videos</TextStyle>
        )}
        <Stack.Item fill>
          <Stack vertical spacing="extraLoose">
            <Stack.Item>
              <FormLayout>
                <TextField
                  value={ad}
                  onChange={setAd}
                  label="Ad title"
                  requiredIndicator
                  autoComplete="off"
                />
                <TextField
                  value={link}
                  onChange={setLink}
                  label="Link"
                  requiredIndicator
                  placeholder={`${currentShopId}/products/my-product-url`}
                  autoComplete="off"
                />
              </FormLayout>
            </Stack.Item>
            <Stack.Item>
              <FormLayout>
                {headlines.map((x, i) => (
                  <TextField
                    key={`headline_${i}`}
                    value={x}
                    label={isDynamic ? 'Headlines' : 'Headline'}
                    onChange={(str) => {
                      setHeadlines((old) => {
                        const copy = structuredClone(old);
                        copy.splice(i, 1, str);
                        return copy;
                      });
                    }}
                    clearButton={adType === 'dynamic'}
                    onClearButtonClick={() => {
                      setHeadlines((old) => {
                        const copy = structuredClone(old);
                        copy.splice(i, 1);
                        return copy;
                      });
                    }}
                    autoComplete="off"
                  ></TextField>
                ))}
                {adType === 'dynamic' && (
                  <Button
                    disabled={headlines.length >= 3}
                    onClick={() => setHeadlines((old) => old.concat(''))}
                    size="slim"
                  >
                    Add headline variants
                  </Button>
                )}
              </FormLayout>
            </Stack.Item>
            <Stack.Item>
              <FormLayout>
                {bodies.map((x, i) => (
                  <TextField
                    key={`body_${i}`}
                    multiline={4}
                    value={x}
                    label={isDynamic ? 'Bodies' : 'Body'}
                    onChange={(str) => {
                      setBodies((old) => {
                        const copy = structuredClone(old);
                        copy.splice(i, 1, str);
                        return copy;
                      });
                    }}
                    clearButton={adType === 'dynamic'}
                    onClearButtonClick={() => {
                      setBodies((old) => {
                        const copy = structuredClone(old);
                        copy.splice(i, 1);
                        return copy;
                      });
                    }}
                    autoComplete="off"
                  ></TextField>
                ))}
                {adType === 'dynamic' && (
                  <Button onClick={() => setBodies((old) => old.concat(''))} size="slim">
                    Add body variants
                  </Button>
                )}
              </FormLayout>
            </Stack.Item>
            <Stack.Item>
              <Select
                labelInline
                options={callToActions?.map((x) => ({ label: x.label, value: x.key }))}
                onChange={setAction}
                value={action}
                label="Call to Action:"
                disabled
              />
            </Stack.Item>
            <Stack.Item>
              <Select
                labelInline
                options={[{ label: '', value: '' }].concat(
                  campaigns.map((x) => ({ label: x.name!, value: x.id! })),
                )}
                onChange={setCampaign}
                value={campaign}
                label="Campaign:"
                requiredIndicator
              />
            </Stack.Item>
            <Stack.Item>
              {loadingAdsets && (
                <div>
                  <Spinner size="small" />
                </div>
              )}
              {!loadingAdsets && (
                <Select
                  options={[{ label: '', value: '' }].concat(
                    adsets.map((x) => ({ label: x.name!, value: x.id! })),
                  )}
                  onChange={setAdset}
                  value={adset}
                  label="Adset:"
                  disabled={!campaign || !adsets?.length}
                  requiredIndicator
                  labelInline
                />
              )}
            </Stack.Item>
            <Stack.Item>
              <Select
                label="Facebook account:"
                value={account}
                labelInline
                options={[{ label: '', value: '' }].concat(
                  integrations['facebook-ads']?.map((x) => ({
                    label: x.name || x.id,
                    value: x.id,
                  })) || [],
                )}
                onChange={setAccount}
              />
            </Stack.Item>
            <Stack.Item>
              {loadingPages && (
                <div>
                  <Spinner size="small" />
                </div>
              )}
              {!loadingPages && (
                <Select
                  label="Facebook page:"
                  labelInline
                  disabled={!account}
                  value={page}
                  options={[{ label: '', value: '' }].concat(
                    pages.map((x) => ({ label: x.name, value: x.id })),
                  )}
                  onChange={setPage}
                />
              )}
            </Stack.Item>
            <Stack.Item>
              {loadingPages && (
                <div>
                  <Spinner size="small" />
                </div>
              )}
              {!loadingPages && (
                <Select
                  label="Instagram Account:"
                  helpText="You can add here an instagram account"
                  labelInline
                  disabled={!account}
                  value={instagramAccount}
                  options={[{ label: '', value: '' }].concat(
                    instagramAccounts.map((x) => ({ label: x.name, value: x.id })),
                  )}
                  onChange={setInstagramAccount}
                />
              )}
            </Stack.Item>
            <Stack.Item>
              <Button
                primary
                disabled={
                  !campaign ||
                  !page ||
                  !adset ||
                  !headlines?.length ||
                  !headlines[0] ||
                  !bodies?.length ||
                  !bodies[0] ||
                  !ad ||
                  !link ||
                  (adType === 'image' && !images?.length) ||
                  (adType === 'video' && !videos?.length)
                }
                onClick={() => onSubmit()}
              >
                Send to Facebook
              </Button>
            </Stack.Item>
          </Stack>
        </Stack.Item>
      </Stack>
    </Modal>
  );
};

export default CreateAd;
