import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { SequenceFlowStep } from './SequenceFlowStep';
import {
  RunSequenceRequest,
  StepWsStatus,
  WorkflowStep,
  WorkflowWsStatus,
} from '../types/willyTypes';
import { TreeVerticalLine, TreeVerticalLineFull } from './TreeVerticalLine';
import { TreeHorizontalLine } from './TreeHorizontalLine';
import { ActionIcon, Button, confirm, Icon, Loader, Text, Tooltip } from '@tw/ui-components';
import {
  $multiFlowSequenceBuilder,
  $sequenceBuilderSequences,
  setSequenceSteps,
  updateSequenceSteps,
} from '$stores/willy/$seqBuilder';
import { useComputedValue, useStoreValue } from '@tw/snipestate';
import { $shopSequences } from '$stores/willy/$sequences';
import { $userEmail } from '$stores/$user';
import { $isTwGlobalDashboardCreatorClaim } from '$stores/$user';

import { v4 as uuidV4 } from 'uuid';
import { StepDrawer } from './StepDrawer';
import { $activeAccounts, $currency, $industry } from '$stores/$shop';
import { useAppSelector } from 'reducers/RootType';
import { AlanLoaderGray } from 'components/AlanLoader';
import _db, { Timestamp } from 'utils/DB';
import { noop } from 'lodash';
import { useSequenceFlow } from './useSequenceFlow';
import { updateDashboardForFFConfigs } from '../api/updateDashboardForFFConfigs';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { toast } from 'react-toastify';
import {
  EditNewSequenceProps,
  createNewSequence,
  createNewStep,
  editSequence,
  editStep,
  getParentRuleId,
  isStepInvalid,
} from '../utils/sequences';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';
import { Link } from 'react-router-dom';
import { useSequenceFlowSocket } from './useSequenceFlowSocket';
import { useWillySocket } from '../WillySocket';
import { sleep } from 'utils/sleep';
import { useDeepCompareMemoize } from 'hooks/useDeepCompareMemoize';
import { SequenceFlowRuleStep } from './SequenceFlowRuleStep';
import axiosInstance from 'utils/axiosInstance';
import { $socket } from '$stores/$socket';

const space = 50;
type SequenceFlowProps = {
  sequenceId: string | null;
  depth: number;
  isFirstSibling: boolean;
  isLastSibling: boolean;
  disabledFlow?: boolean;
  noEdit: boolean;
  fromRule?: boolean;
  parentRunId?: string;
  variables?: Record<string, string>;
  runOnInit?: boolean;
  canAddNewStep?: boolean;
  rephraseStepsBasedOnConversation?: string;
};

export const SequenceFlow: React.FC<SequenceFlowProps> = ({
  sequenceId,
  depth,
  isFirstSibling,
  isLastSibling,
  disabledFlow,
  noEdit,
  fromRule,
  parentRunId,
  variables,
  runOnInit,
  canAddNewStep = true,
  rephraseStepsBasedOnConversation,
}) => {
  const {
    sequence,
    name,
    description,
    dialect,
    emoji,
    isGlobal,
    providers,
    providersBlockingCombination,
    category,
    roles,
    isBeta,
    isHide,
    msps,
    featureFlagConfigs,
    featureFlagDefaultCopyConfigs,
    additionalShopIds,
  } = useSequenceFlow(sequenceId);
  const { socket } = useWillySocket();
  const realtimeSocket = useStoreValue($socket);
  const isSmall = useIsSmall();
  const isOnlySibling = isFirstSibling && isLastSibling;
  const isTwGlobalDashboardCreatorClaim = useStoreValue($isTwGlobalDashboardCreatorClaim);
  const user = useAppSelector((state) => state.user);
  const allSequences = useStoreValue($shopSequences);
  const sequenceBuilderSequences = useStoreValue($sequenceBuilderSequences);

  const ranOnInitRef = useRef(false);

  const conversationId = useStoreValue($multiFlowSequenceBuilder).conversationId;
  const userEmail = useStoreValue($userEmail);

  const shelfIsOpen = useComputedValue($multiFlowSequenceBuilder, (mb) => !!mb.shelfOpen);
  const prefilledUserPrompts = useComputedValue(
    $multiFlowSequenceBuilder,
    (mb) => mb.prefilledUserPrompts,
  );

  const parentSequenceId = useComputedValue($multiFlowSequenceBuilder, (mb) => mb.parentSequenceId);

  const [errorRunSequence, setErrorRunSequence] = useState<string>();
  const [collapsedSteps, setCollapsedSteps] = useState<string[]>([]);
  const [loadingAnswers, setLoadingAnswers] = useState(false);
  const [promptsWereChanged, setPromptsWereChanged] = useState(false);
  const [currentRunId, setCurrentRunId] = useState<string>();
  const [activeWorkflowId, setActiveWorkflowId] = useState<string>();
  const [openStepSettingDrawer, setOpenStepSettingDrawer] = useState(false);
  const [openRuleStepSettingsDrawer, setOpenRuleStepSettingsDrawer] = useState(false);
  const [wsWorkflows, setWsWorkflows] = useState<Record<string, WorkflowWsStatus>>({});
  const [wsSteps, setWsSteps] = useState<Record<string, StepWsStatus>>({});
  const [mainWorkflowStopped, setMainWorkflowStopped] = useState(false);
  const [indexToAddNewStep, setIndexToAddNewStep] = useState(0);
  const [fromReport, setFromReport] = useState(false);

  const memoizedVariables = useDeepCompareMemoize(variables);

  const [ruleStepSettingDrawerRef, setRuleStepSettingDrawerRef] = useState<{
    stepId: string;
    type: 'passed' | 'failed';
  }>({ stepId: '', type: 'passed' });

  const randomId = useMemo(() => {
    return uuidV4();
  }, []);

  const sequenceFlowId = sequenceId ?? randomId;

  useSequenceFlowSocket({
    sequenceId: sequenceFlowId,
    runId: parentRunId || currentRunId,
    setWsWorkflows,
    setWsSteps,
    setMainWorkflowStopped,
  });

  const workflowRunningOrLoading =
    wsWorkflows[sequenceFlowId]?.status === 'running' || loadingAnswers;

  const allowRegenerate =
    !workflowRunningOrLoading && !Object.values(wsSteps).some((step) => step.status === 'running');

  const [steps, setSteps] = useState<WorkflowStep[]>([]);

  useEffect(() => {
    return $sequenceBuilderSequences.subscribe((x) => {
      setSteps(x[sequenceFlowId]?.steps ?? []);
    });
  }, [sequenceFlowId]);

  const stepsWithoutParentRuleId = useMemo(() => {
    return steps.filter((s) => !s.parentRuleId);
  }, [steps]);

  const allStepsValid = useMemo(() => {
    return !steps.some((step) => isStepInvalid(step));
  }, [steps]);

  const emailStepLink = useMemo(() => {
    // first check for outputFile
    const outputResponse =
      wsWorkflows && wsWorkflows[sequenceFlowId]?.status === 'done' && wsWorkflows[sequenceFlowId];

    if (outputResponse && outputResponse.outputFile) {
      const path = outputResponse.outputFile;
      return path && btoa(path);
    }

    // if no output, fall back to check if sendToEmail exists
    const foundStep = Object.keys(wsSteps).find((step) =>
      Object.keys(wsSteps[step])
        .map((key) => wsSteps[step]?.[key]?.stepType)
        .includes('sendToEmail'),
    );

    if (!foundStep || !wsSteps[foundStep]) {
      return [];
    }

    const stepResponse = wsSteps[foundStep].response;

    if (stepResponse && 'attachments' in stepResponse) {
      const path = stepResponse.attachments?.find((a) => a.format === 'JSON')?.path;
      return path && btoa(path);
    }

    return null;
  }, [sequenceFlowId, wsSteps, wsWorkflows]);

  const isNew = !sequence;

  const readOnly = useMemo(() => {
    return (isGlobal && !isTwGlobalDashboardCreatorClaim) || noEdit;
  }, [noEdit, isGlobal, isTwGlobalDashboardCreatorClaim]);

  const currency = useStoreValue($currency);
  const activeAccounts = useStoreValue($activeAccounts);
  const industry = useStoreValue($industry);
  const parentDialect = useStoreValue($multiFlowSequenceBuilder).parentDialect;
  const timezone = useAppSelector((state) => state.shopTimezone);
  const currentShopId = useAppSelector((state) => state.currentShopId);

  const toggleCollapse = useCallback(
    (stepId: string) => {
      if (collapsedSteps.includes(stepId)) {
        setCollapsedSteps(collapsedSteps.filter((id) => id !== stepId));
      } else {
        setCollapsedSteps([...collapsedSteps, stepId]);
      }
    },
    [collapsedSteps],
  );

  useEffect(() => {
    if (!!sequence) {
      setSequenceSteps(sequenceFlowId, sequence.steps);
    } else if (prefilledUserPrompts && depth === 0) {
      setSequenceSteps(sequenceFlowId, prefilledUserPrompts);
    }
  }, [sequence, sequenceFlowId, prefilledUserPrompts, depth]);

  const addNewStep = useCallback(
    (step: Partial<WorkflowStep>, ruleDescendant?: boolean) => {
      const newStep = createNewStep(step, userEmail);
      if (!newStep) {
        return;
      }

      if (ruleDescendant) {
        const stepId = ruleStepSettingDrawerRef.stepId;
        const type = ruleStepSettingDrawerRef.type;
        const parentRuleId = getParentRuleId(stepId, type);
        newStep.parentRuleId = parentRuleId;
      }

      setOpenStepSettingDrawer(false);
      setOpenRuleStepSettingsDrawer(false);

      updateSequenceSteps(sequenceFlowId, [
        ...steps.slice(0, indexToAddNewStep),
        newStep,
        ...steps.slice(indexToAddNewStep),
      ]);
    },
    [
      sequenceFlowId,
      steps,
      indexToAddNewStep,
      userEmail,
      ruleStepSettingDrawerRef.stepId,
      ruleStepSettingDrawerRef.type,
    ],
  );

  const handlePromptChange = useCallback(
    (newPrompt: WorkflowStep) => {
      const allSteps = steps.map((step) => {
        if (step.id === newPrompt.id) {
          return editStep(step, newPrompt);
        }
        return step;
      });
      updateSequenceSteps(sequenceFlowId, allSteps);

      setPromptsWereChanged(true);
    },
    [sequenceFlowId, steps],
  );

  const deleteStep = useCallback(
    async (stepId: string) => {
      const confirmed = await confirm({ title: 'Delete step', message: 'Are you sure?' });
      if (!confirmed) {
        return;
      }
      updateSequenceSteps(
        sequenceFlowId,
        steps.filter((step) => step.id !== stepId),
      );

      setPromptsWereChanged(true);
    },
    [sequenceFlowId, steps],
  );

  const saveSequenceIdsToStep = useCallback(
    (sequenceIds: string[], stepId: string) => {
      updateSequenceSteps(sequenceFlowId, [
        ...steps.map((step) => {
          if (step.id === stepId) {
            return { ...step, sequenceIds };
          }
          return step;
        }),
      ]);
    },
    [sequenceFlowId, steps],
  );

  const runSequence = useCallback(
    async (draftId: string, upToStepId?: string) => {
      if (!activeAccounts) {
        return;
      }
      setLoadingAnswers(true);
      let currentMessageId = uuidV4();
      const newRunId = uuidV4();
      setCurrentRunId(newRunId);
      const requestParams: RunSequenceRequest = {
        source: 'sequence' as const,
        sequenceId: draftId,
        shopId: currentShopId,
        conversationId: newRunId,
        additionalShopIds: activeAccounts ?? [],
        messageId: currentMessageId,
        question: '<question will generate from sequence>',
        generateInsights: true,
        stream: false,
        currency,
        timezone: timezone,
        dialect: parentDialect,
        industry: industry || 'other',
        upToStepId,
        conversationLink: window.location.href,
        variables: memoizedVariables,
        rephraseStepsBasedOnConversation,
      };
      try {
        setErrorRunSequence(undefined);
        // const response = await axiosInstance.post<RunSequenceResponse>(
        //   '/v2/willy/run-sequence',
        //   requestParams,
        // );

        await sleep(1000);

        // reconnect before emitting
        // socket.disconnect();
        // socket.connect();
        // socket.emit('run-sequence', requestParams);
        const authBody = { shopId: currentShopId, userId: user.uid };
        realtimeSocket.emit('subscribe', { channel: `workflow:${newRunId}`, ...authBody });
        const response = await axiosInstance.post('/v2/sequences/workflows/run', requestParams);

        setActiveWorkflowId(response.data.workflowId);

        // const steps = response.data?.allSteps || [];
        // const allAnswersToReturn = steps.reduce<Record<string, WorkflowResponse>>((acc, step) => {
        //   acc[step.stepId] = step;
        //   return acc;
        // }, {});

        // setGeneratedAnswers(allAnswersToReturn);
      } catch (error: any) {
        const errorMessage = error.message || 'Error running sequence';
        console.error(errorMessage);
        setErrorRunSequence(errorMessage);
      }
    },
    [
      activeAccounts,
      currentShopId,
      currency,
      parentDialect,
      industry,
      timezone,
      realtimeSocket,
      memoizedVariables,
      rephraseStepsBasedOnConversation,
      user.uid,
    ],
  );

  const onEditSequence = useCallback(
    async (seqId: string) => {
      if (isGlobal && isTwGlobalDashboardCreatorClaim) {
        toast.error('You are not allowed to edit global sequences');
      }
      try {
        const newSeqObject: EditNewSequenceProps = {
          dialect,
          messages: steps || [],
          additionalShopIds: additionalShopIds || [],
          baseMainElement: {
            id: seqId,
            type: 'sequence',
            canEdit: false,
            createdAt: Timestamp.now(),
            updatedAt: Timestamp.now(),
            name,
            description: !!description ? description : '',
            emoji,
            isGlobal,
            roles,
            isBeta,
            isHide,
            category,
            dialect,
            providers,
            providersBlockingCombination: !!providersBlockingCombination
              ? providersBlockingCombination
              : ('AND' as 'AND' | 'OR'),
            packages: featureFlagConfigs,
            defaultPackages: featureFlagDefaultCopyConfigs,
            msps,
          },
        };
        await editSequence(newSeqObject, seqId);
      } catch (e) {
        console.error(e);
        toast.error('Could not save sequence');
      }
    },
    [
      isGlobal,
      description,
      name,
      emoji,
      roles,
      isBeta,
      isHide,
      category,
      dialect,
      providers,
      providersBlockingCombination,
      msps,
      isTwGlobalDashboardCreatorClaim,
      featureFlagConfigs,
      featureFlagDefaultCopyConfigs,
      steps,
      additionalShopIds,
    ],
  );

  const createSequence = useCallback(async () => {
    if (!currentShopId || !user.uid) {
      toast.error('Shop ID is required');
      return;
    }
    // const newDraftId = uuidV4();
    // setDraftId(newDraftId);
    const { error, success, conversationData } = await createNewSequence({
      shopId: currentShopId,
      userId: user.uid,
      dialect,
      conversationId,
      messages: steps,
      additionalShopIds: additionalShopIds ?? [],
      baseMainElement: {
        id: sequenceFlowId,
        type: 'sequence',
        canEdit: false,
        createdAt: Timestamp.now(),
        updatedAt: Timestamp.now(),
        name,
        description: description ?? '',
        emoji,
        isGlobal,
        roles,
        isBeta,
        isHide,
        category,
        dialect,
        providers,
        providersBlockingCombination,
        packages: featureFlagConfigs,
        defaultPackages: featureFlagDefaultCopyConfigs,
        msps,
      },
    });
    if (error) {
      toast.error(error);
    } else if (success) {
      if (isGlobal && isTwGlobalDashboardCreatorClaim && conversationData) {
        await updateDashboardForFFConfigs(
          {
            configs: featureFlagConfigs,
            dashboardId: conversationData.id,
            mergeStrategy: 'merge',
          },
          FeatureFlag.TEMPLATES_FF,
        );
        await updateDashboardForFFConfigs(
          {
            configs: featureFlagDefaultCopyConfigs,
            dashboardId: conversationData.id,
            mergeStrategy: 'merge',
          },
          FeatureFlag.WILLY_DEFAULT_TEMPLATES_FF,
        );
      }
      return sequenceFlowId;
    }
  }, [
    category,
    description,
    dialect,
    emoji,
    featureFlagConfigs,
    featureFlagDefaultCopyConfigs,
    isBeta,
    isGlobal,
    isHide,
    msps,
    name,
    providers,
    providersBlockingCombination,
    roles,
    steps,
    conversationId,
    currentShopId,
    user.uid,
    isTwGlobalDashboardCreatorClaim,
    sequenceFlowId,
    additionalShopIds,
  ]);

  const onGenerateAnswer = useCallback(
    async (upToStepId?: string) => {
      if (promptsWereChanged || fromReport) {
        if (isNew || fromReport) {
          await createSequence();
          if (fromReport) setFromReport(false);
        } else {
          await onEditSequence(sequenceFlowId);
        }
      }
      await runSequence(sequenceFlowId, upToStepId);
    },
    [
      promptsWereChanged,
      runSequence,
      sequenceFlowId,
      isNew,
      fromReport,
      createSequence,
      onEditSequence,
    ],
  );

  const onTestWorkflow = useCallback(async () => {
    await onGenerateAnswer();
  }, [onGenerateAnswer]);

  const stopWorkflow = useCallback(async () => {
    setWsWorkflows({});
    setWsSteps({});
    setMainWorkflowStopped(true);
    setLoadingAnswers(false);
    socket.emit('stop-sequence', {
      sequenceId: sequenceFlowId,
      shopId: currentShopId,
      messageId: currentRunId || '',
    });
    if (activeWorkflowId) {
      await axiosInstance.post('/v2/sequences/workflows/cancel', {
        shopId: currentShopId,
        workflowId: activeWorkflowId,
      });
    }
  }, [currentShopId, socket, sequenceFlowId, currentRunId, activeWorkflowId]);

  useEffect(() => {
    if (mainWorkflowStopped) {
      setLoadingAnswers(false);
    }
  }, [mainWorkflowStopped]);

  useEffect(() => {
    setErrorRunSequence(
      wsWorkflows[sequenceFlowId]?.status === 'error'
        ? wsWorkflows[sequenceFlowId]?.error || 'Error running sequence'
        : undefined,
    );
  }, [wsWorkflows, sequenceFlowId]);

  useEffect(() => {
    setIndexToAddNewStep(steps.length);
  }, [steps]);

  useEffect(() => {
    if (depth === 0 && runOnInit && !ranOnInitRef.current) {
      ranOnInitRef.current = true;
      onTestWorkflow();
    }
  }, [onTestWorkflow, depth, runOnInit, sequenceFlowId]);

  useEffect(() => {
    const sequenceInCache = sequenceBuilderSequences[sequenceFlowId];
    if (sequenceInCache?.steps) {
      setSteps(sequenceInCache.steps);
      setFromReport(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div key={sequenceFlowId}>
        <div className={`flex flex-col items-center ${depth === 0 ? '' : 'h-full'}`}>
          {!fromRule && (
            <TreeHorizontalLine
              isOnlySibling={isOnlySibling}
              isFirstSibling={isFirstSibling}
              isLastSibling={isLastSibling}
              depth={depth}
            />
          )}
          {depth !== 0 && (
            <>
              <TreeVerticalLine />
              <div className="flex w-full justify-center">
                <div
                  className={`group flex p-5 rounded items-center gap-3 justify-between cursor-pointer`}
                  style={{
                    backgroundColor: `${disabledFlow ? 'var(--mantine-color-gray-3)' : 'var(--mantine-color-orange-0)'}`,
                    width: `calc(700px - ${depth * space}px)`,
                  }}
                >
                  <div className="flex items-start gap-2">
                    {Object.values(wsWorkflows).some((w) => w.status === 'running') &&
                      wsWorkflows[sequenceFlowId]?.status !== 'running' &&
                      wsWorkflows[sequenceFlowId]?.status !== 'error' &&
                      wsWorkflows[sequenceFlowId]?.status !== 'done' && (
                        <Icon name="clock" color="gray.4" size={16} />
                      )}
                    {wsWorkflows[sequenceFlowId]?.status === 'running' && <Loader size="xs" />}
                    {wsWorkflows[sequenceFlowId]?.status === 'error' && (
                      <Tooltip label={wsWorkflows[sequenceFlowId]?.error}>
                        <span>
                          <Icon name="info" color="red.4" size={16} />
                        </span>
                      </Tooltip>
                    )}
                    {wsWorkflows[sequenceFlowId]?.status === 'done' && (
                      <Icon name="check-thin" color="green.4" size={16} />
                    )}
                    <Text color="gray.8" size="sm">
                      <Link
                        to={{
                          pathname: `/workflows/create/${sequenceFlowId}`,
                          search: window.location.search,
                        }}
                        target="_blank"
                        className="flex items-center gap-2 no-underline text-inherit"
                      >
                        <span>{name}</span>
                        <Icon name="external-minor" color="gray.4" size={16} />
                      </Link>
                    </Text>
                  </div>
                </div>
              </div>
            </>
          )}
          {!!stepsWithoutParentRuleId.length && !disabledFlow && (
            <div className="flex flex-col items-center">
              {stepsWithoutParentRuleId.map((step, stepNumber, arr) => {
                const generatedAnswer = wsSteps[step.id];
                const subSequenceIds =
                  step?.stepType === 'subSequence'
                    ? step?.sequenceIds?.filter((subseq) => {
                        return !!allSequences.find((seq) => seq.id === subseq);
                      })
                    : [];
                const lastStepInSequence =
                  stepsWithoutParentRuleId[stepsWithoutParentRuleId.length - 1].id === step.id;
                return (
                  <Fragment key={step.id}>
                    <SequenceFlowStep
                      dialect={dialect}
                      sequenceId={sequenceFlowId}
                      userPrompt={step}
                      stepNumber={stepNumber}
                      onGenerateAnswer={depth === 0 ? onGenerateAnswer : noop}
                      handlePromptChange={handlePromptChange}
                      deleteStep={deleteStep}
                      toggleCollapse={toggleCollapse}
                      isCollapsed={collapsedSteps.includes(step.id)}
                      loadingAnswers={workflowRunningOrLoading}
                      allowRegenerateAnswers={allowRegenerate}
                      stepRunInfo={generatedAnswer}
                      depth={depth}
                      saveSequences={saveSequenceIdsToStep}
                      readOnly={readOnly}
                      noMoreStepsAllowed={fromRule && lastStepInSequence}
                      isLastStep={lastStepInSequence}
                    />
                    {step?.stepType === 'subSequence' &&
                      subSequenceIds &&
                      !!subSequenceIds.length && (
                        <div>
                          <div className="flex">
                            {subSequenceIds
                              ?.filter((item): item is string => item !== null)
                              ?.map((subseq, i) => {
                                const flowIsParent = subseq === parentSequenceId;

                                return (
                                  <SequenceFlow
                                    disabledFlow={flowIsParent}
                                    key={subseq}
                                    sequenceId={subseq}
                                    depth={depth + 1}
                                    isFirstSibling={i === 0}
                                    isLastSibling={
                                      subSequenceIds ? i === subSequenceIds?.length - 1 : true
                                    }
                                    noEdit={true}
                                    parentRunId={currentRunId}
                                    rephraseStepsBasedOnConversation={
                                      rephraseStepsBasedOnConversation
                                    }
                                  />
                                );
                              })}
                          </div>
                        </div>
                      )}
                    {depth === 0 && stepNumber !== arr.length - 1 && canAddNewStep && (
                      <div className="flex items-center">
                        <ActionIcon
                          icon="plus-1"
                          iconSize={20}
                          size="sm"
                          onClick={() => {
                            setIndexToAddNewStep(stepNumber + 1);
                            setOpenStepSettingDrawer(true);
                          }}
                        />
                      </div>
                    )}
                    {(step?.stepType === 'condition' || step?.stepType === 'rule') && (
                      <SequenceFlowRuleStep
                        step={step}
                        sequenceId={sequenceFlowId}
                        depth={depth + 1}
                        steps={steps}
                        wsSteps={wsSteps}
                        space={space}
                        onGenerateAnswer={depth === 0 ? onGenerateAnswer : noop}
                        handlePromptChange={handlePromptChange}
                        deleteStep={deleteStep}
                        toggleCollapse={toggleCollapse}
                        loadingAnswers={workflowRunningOrLoading}
                        allowRegenerate={allowRegenerate}
                        saveSequenceIdsToStep={saveSequenceIdsToStep}
                        readOnly={readOnly}
                        collapsedSteps={collapsedSteps}
                        currentRunId={currentRunId}
                        shelfIsOpen={shelfIsOpen}
                        setOpenRuleStepSettingsDrawer={setOpenRuleStepSettingsDrawer}
                        setRuleStepSettingDrawerRef={setRuleStepSettingDrawerRef}
                        rephraseStepsBasedOnConversation={rephraseStepsBasedOnConversation}
                      />
                    )}
                  </Fragment>
                );
              })}
            </div>
          )}
          {!steps.length && depth === 0 && canAddNewStep && (
            <div
              className={`w-[500px] border border-solid border-gray-300 rounded-lg p-5 cursor-pointer`}
              onClick={() => {
                setOpenStepSettingDrawer(true);
              }}
            >
              <Text fz={14} fw={500} color={'gray.4'}>
                Add step
              </Text>
            </div>
          )}
          <TreeVerticalLineFull />

          <TreeHorizontalLine
            isOnlySibling={isOnlySibling}
            isFirstSibling={isFirstSibling}
            isLastSibling={isLastSibling}
            depth={depth}
          />
        </div>
        {depth === 0 && (
          <div className="flex flex-col items-center gap-4">
            {canAddNewStep && (
              <>
                <TreeVerticalLine />
                <div
                  className={`open-step-drawer-trigger flex justify-center ${shelfIsOpen ? '' : 'cursor-pointer'}`}
                  onClick={() => {
                    setIndexToAddNewStep(steps.length);
                    setOpenStepSettingDrawer(true);
                  }}
                >
                  <Icon name="plus-1" color={shelfIsOpen ? 'gray.3' : 'named.6'} size={45} />
                </div>
              </>
            )}
            <div>
              {workflowRunningOrLoading && (
                <div className="flex items-center w-full justify-center">
                  <span className="scale-50">
                    <AlanLoaderGray />
                  </span>
                </div>
              )}
              {!workflowRunningOrLoading && (
                <div>
                  {(Object.values(wsSteps).at(-1)?.response?.status === 'error' ||
                    !!errorRunSequence) && (
                    <div className="flex flex-col items-center">
                      <Text size="sm" fw={500} color={'red.4'}>
                        Error
                      </Text>
                      <Text size="sm" fw={400} color={'gray.4'}>
                        <Tooltip
                          label={
                            <div className="max-w-[300px] whitespace-pre-wrap">
                              {errorRunSequence || 'Error running sequence'}
                            </div>
                          }
                        >
                          <div className="max-w-[300px] overflow-hidden">
                            <span className="line-clamp-3">
                              {errorRunSequence ||
                                Object.values(wsSteps)
                                  .map((answer) => answer.error)
                                  .filter((error) => !!error)
                                  .join('\n')}
                            </span>
                          </div>
                        </Tooltip>
                      </Text>
                    </div>
                  )}
                  {Object.values(wsSteps).at(-1)?.response?.status === 'ok' && (
                    <div className="flex flex-col items-center">
                      <Text size="sm" fw={500} color={'green.4'}>
                        Success
                      </Text>
                      <Text size="sm" fw={400} color={'gray.4'}>
                        Workflow ran successfully
                      </Text>
                      {emailStepLink && (
                        <Link
                          target={isSmall ? '' : '_blank'}
                          to={`/workflows/view/${sequenceFlowId}?fileName=${emailStepLink}`}
                        >
                          View Output
                        </Link>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
            {depth === 0 && (
              <div className="flex justify-end">
                <Button
                  disabled={steps.length === 0 || !allStepsValid}
                  onClick={() => {
                    if (workflowRunningOrLoading) {
                      stopWorkflow();
                    } else {
                      onTestWorkflow();
                    }
                  }}
                >
                  {workflowRunningOrLoading ? 'Stop' : errorRunSequence ? 'Retry' : 'Test Workflow'}
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
      <StepDrawer
        addNewStep={addNewStep}
        open={openStepSettingDrawer}
        onClose={() => setOpenStepSettingDrawer(false)}
        isFirstStep={depth === 0 && steps.length === 0}
        sequenceId={sequenceId ?? undefined}
      />
      <StepDrawer
        addNewStep={addNewStep}
        open={openRuleStepSettingsDrawer}
        onClose={() => setOpenRuleStepSettingsDrawer(false)}
        isFirstStep={depth === 0 && steps.length === 0}
        fromRule={true}
      />
    </>
  );
};
