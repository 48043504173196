import { useAppSelector } from 'reducers/RootType';
import {
  Message,
  WebSources,
  WillyMetric,
  WillyPrompt,
  WillyToolName,
  WorkflowStep,
} from './types/willyTypes';
import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import axiosInstance from 'utils/axiosInstance';
import { Tooltip } from '@shopify/polaris';
import { CodeAction, WillySimpleText } from './WillySimpleText';
import { Button, Icon, Badge, ActionIcon, Skeleton, Text } from '@tw/ui-components';
import { WillyEditMetric } from './WillyEditMetric';
import { WillyUserAvatar } from './WillyUserAvatar';
import { ReactComponent as AlanIcon } from 'components/Icons/alan-icon.svg';
import _db from 'utils/DB';
import { User } from 'components/UserProfileManagment/User/constants';
import { ReactComponent as ButtonPlay } from 'components/Icons/Button-Play.svg';
import { WillyMessageWidget } from './WillyMessageWidget';
import { FAVORITE_PROMPTS_COLLECTION, willyToolMap } from './constants';
import { startCase } from 'lodash';
import {
  convertCodeResponseToMessageCodeInterpreterResponse,
  convertDeprecatedToolName,
  convertNlqResponseToMessageData,
  emptyArray,
  getToolPreloadFromToolResult,
  removeFavoritePrompt,
} from './utils/willyUtils';
import { WillyToolProgress } from './WillyToolProgress';
import { MessageToolDetailsTabs, MessageToolTabs, PossibleTab } from './MessageToolDetailsTabs';
import { useStoreValue } from '@tw/snipestate';
import { $favoritePrompts } from '$stores/willy/$favoritePrompts';
import { $userId } from '$stores/$user';
import { $multiFlowSequenceBuilder } from '$stores/willy/$seqBuilder';
import { useNavigate } from 'react-router';
import copyToClipboard from 'utils/copyToClipboard';
import { WillyMessageResources } from './WillyMessageResources';

type MessageTemplateProps = {
  message: Message;
  prevMessage?: Message;
  conversationMessages: Message[];
  userName: string;
  conversationUser: string;
  loading?: boolean;
  codeActions?: CodeAction[];
  isLast?: boolean;
  lastMessageFromUser?: Message;
  canEdit?: boolean;
  isSequenceMode?: boolean;
  firstOfAssistant?: boolean;
  lastOfAssistant?: boolean;
  handleSubmit?: (text: string, skipUserMessage?: boolean, userMessageText?: string) => void;
  chatSourceIds?: { dashboardId: string; widgetId: string; onAdd?: () => void };
  buildMode?: boolean;
  hideDetails?: boolean;
  showToolResults?: boolean;
  workflowPanelOpen?: boolean;
  setWorkflowIdToRun?: React.Dispatch<React.SetStateAction<string | undefined>>;
  setWorkflowPanelOpen?: React.Dispatch<React.SetStateAction<boolean>>;
};

export const WillyMessageTemplate: React.FC<MessageTemplateProps> = ({
  message,
  prevMessage,
  conversationMessages,
  userName,
  conversationUser,
  loading,
  codeActions = [],
  isLast,
  lastMessageFromUser,
  canEdit = false,
  isSequenceMode,
  handleSubmit,
  chatSourceIds,
  firstOfAssistant,
  lastOfAssistant,
  buildMode,
  hideDetails,
  showToolResults,
  workflowPanelOpen,
  setWorkflowIdToRun,
  setWorkflowPanelOpen,
}) => {
  const inputRef = useRef<HTMLDivElement>(null);
  const currentShopId = useAppSelector((state) => state.currentShopId);
  const favoritePrompts = useStoreValue($favoritePrompts);

  const userId = useStoreValue($userId);
  const navigate = useNavigate();
  const [willyMetrics, setWillyMetrics] = useState<WillyMetric[]>([]);
  const [editMessage, setEditMessage] = useState(false);
  const [loadingSavePrompt, setLoadingSavePrompt] = useState(false);
  const [cachedEditedMessage, setCachedEditedMessage] = useState<string | undefined>(undefined);
  const [messageUserName, setMessageUserName] = useState('');
  const [messageAvatarSource, setMessageAvatarSource] = useState('');
  const [loadingUserName, setLoadingUserName] = useState(false);
  const [loadingAvatarSource, setLoadingAvatarSource] = useState(false);
  const [selectedTab, setSelectedTab] = useState<PossibleTab | null>('details');
  const [isDataCollapsible, setIsDataCollapsible] = useState(false);
  const [isDataCollapsed, setIsDataCollapsed] = useState(false);
  const [editMetricModalOpen, setEditMetricModalOpen] = useState<{
    open: boolean;
    queryId?: string;
    metricId?: string;
  }>({ open: false });

  const readyForToolbar = useMemo(() => {
    if (!isLast) {
      return true;
    }

    if (message?.loading) {
      return false;
    }

    return !loading;
  }, [isLast, loading, message?.loading]);

  const isFavoritePrompt = useMemo(() => {
    if (!message.text || message.role !== 'user') {
      return false;
    }
    return favoritePrompts.some((x) => x.prompt === message.text);
  }, [favoritePrompts, message.text, message.role]);

  const shouldShowTabs = useMemo(() => {
    if (!message.toolResults?.name) {
      return false;
    }
    const arr: WillyToolName[] = ['TextToSQL'];
    return arr.includes(convertDeprecatedToolName(message.toolResults.name));
  }, [message.toolResults?.name]);

  const messageData = useMemo(() => {
    if (!message?.toolResults) {
      return null;
    }

    message.toolResults.name = convertDeprecatedToolName(message.toolResults.name);

    if (
      message.toolResults.name === 'Forecasting' ||
      message.toolResults.name === 'MarketingMixModel'
    ) {
      if (!message.toolResults.message) {
        return null;
      }
      return convertNlqResponseToMessageData(message.toolResults.message);
    } else if (message.toolResults.name === 'TextToSQL') {
      if (!message.toolResults.nlqResponse) {
        return null;
      }
      return convertNlqResponseToMessageData(message.toolResults.nlqResponse);
    } else if (message.toolResults.name === 'GenerateInsights') {
      if (!message.toolResults.nlqResponse) {
        return null;
      }
      return convertNlqResponseToMessageData(message.toolResults.nlqResponse);
    }

    return null;
  }, [message.toolResults]);

  const messageResources: WebSources | null = useMemo(() => {
    if (!message.toolResults) {
      return null;
    }

    if (message.toolResults.name !== 'Searching') {
      return null;
    }

    const { sources, imgs, videos } = message.toolResults;

    return { sources, imgs, videos };
  }, [message.toolResults]);

  const prevMessageData = useMemo(() => {
    if (!prevMessage?.toolResults) {
      return null;
    }

    prevMessage.toolResults.name = convertDeprecatedToolName(prevMessage.toolResults.name);

    if (
      prevMessage.toolResults.name === 'Forecasting' ||
      prevMessage.toolResults.name === 'MarketingMixModel'
    ) {
      if (!prevMessage.toolResults.message) {
        return null;
      }
      return convertNlqResponseToMessageData(prevMessage.toolResults.message);
    } else if (prevMessage.toolResults.name === 'TextToSQL') {
      if (!prevMessage.toolResults.nlqResponse) {
        return null;
      }
      return convertNlqResponseToMessageData(prevMessage.toolResults.nlqResponse);
    }

    return null;
  }, [prevMessage?.toolResults]);

  const messageCode = useMemo(() => {
    if (!message?.toolResults || !Object.values(message.toolResults).length) {
      return null;
    }
    if (message.toolResults.name !== 'TextToPython') {
      return null;
    }
    return convertCodeResponseToMessageCodeInterpreterResponse(message.toolResults);
  }, [message.toolResults]);

  const copyUrlWithMessageId = useCallback(() => {
    copyToClipboard(`${window.location.href.replace(window.location.hash, '')}#${message.id}`);
  }, [message.id]);

  const hasMessageData = !!messageData;

  useEffect(() => {
    if (message.toolResults?.name === 'GenerateInsights' && hasMessageData) {
      setIsDataCollapsed(true);
      setIsDataCollapsible(true);
    }
  }, [message.toolResults?.name, hasMessageData]);

  useEffect(() => {
    (async () => {
      if (!message.userId) {
        setMessageUserName(userName);
        return;
      }
      if (conversationUser === message.userId) {
        setMessageUserName(userName);
        return;
      }

      try {
        setLoadingUserName(true);
        const { data } = await axiosInstance.get<User>(
          `/v2/willy/get-user-name?shopId=${currentShopId}&userId=${message.userId}`,
        );
        const { firstName, lastName, email } = data;
        setMessageUserName(firstName || lastName || email || message.userId);
        setLoadingUserName(false);
      } catch (e) {
        console.error('Could not fetch user name', e);
      }
    })();
  }, [conversationUser, currentShopId, message.userId, userName]);

  return (
    <Fragment>
      <div
        id={message.id}
        className="group @container w-full text-gray-800 dark:text-gray-100 border-b border-black/10 dark:border-gray-900/50"
      >
        <div
          className={`gap-6 flex flex-col @3xl:flex-row m-auto ${hideDetails ? '' : 'p-4 @3xl:!px-40'}`}
        >
          {!hideDetails && (
            <div className="relative flex @3xl:items-start">
              {message.role === 'user' && (
                <div className="flex gap-2 items-center">
                  <div className="w-10 h-10 rounded-sm overflow-hidden flex items-center">
                    <WillyUserAvatar
                      avatarSource={messageAvatarSource}
                      userId={message.userId}
                      userName={messageUserName}
                      loading={loadingAvatarSource}
                    />
                  </div>
                  <div
                    className="flex @3xl:hidden font-semibold leading-none"
                    onClick={copyUrlWithMessageId}
                  >
                    {loadingUserName && <Skeleton width="100px" />}
                    {!loadingUserName && messageUserName}
                  </div>
                </div>
              )}
              {message.role !== 'user' && (
                <div className="flex gap-2 items-center">
                  <div className="w-10 h-10 rounded-sm flex items-center">
                    {firstOfAssistant && (
                      <AlanIcon className="w-full h-auto fill-[white] text-[#0C70F2]" />
                    )}
                  </div>
                  {firstOfAssistant && (
                    <p
                      className="flex @3xl:hidden font-semibold leading-none"
                      onClick={copyUrlWithMessageId}
                    >
                      Moby
                    </p>
                  )}
                </div>
              )}
            </div>
          )}

          <div className="relative flex @3xl:flex-col flex-auto max-w-full">
            {/* <p>{message.id}</p> */}
            {(firstOfAssistant || message.role === 'user') && (
              <div className="py-2 mb-4">
                {message.role === 'user' && (
                  <p
                    className="@3xl:flex hidden font-semibold leading-none"
                    onClick={copyUrlWithMessageId}
                  >
                    {messageUserName}
                  </p>
                )}
                {message.role !== 'user' && (
                  <p
                    className="@3xl:flex hidden font-semibold leading-none"
                    onClick={copyUrlWithMessageId}
                  >
                    Moby
                  </p>
                )}
              </div>
            )}
            <div
              className={`${hideDetails ? '' : 'min-h-[20px]'} flex flex-col items-start max-w-full flex-auto`}
            >
              <div className="group/message flex items-start gap-2 w-full">
                <div
                  className={`flex-auto flex flex-col w-full h-full ${hideDetails ? '' : 'gap-4'}`}
                >
                  {/* If message has progress value, show it here */}
                  {!!message.loading && <div className="relative blinking-cursor"></div>}
                  {!!message.toolProgress && (
                    <WillyToolProgress
                      progress={message.toolProgress}
                      question={message.question}
                    />
                  )}

                  {!buildMode && (
                    <>
                      {/* message toolResults */}
                      {isDataCollapsible && !showToolResults && (
                        <div className="flex flex-col gap-2 items-center">
                          <Text fw={500} size="sm">
                            This message contains a visualization of the data.
                          </Text>
                          <div>
                            <Button
                              variant="activator"
                              onClick={() => setIsDataCollapsed((x) => !x)}
                            >
                              {isDataCollapsed ? 'Show Visualization' : 'Hide Visualization'}
                            </Button>
                          </div>
                        </div>
                      )}
                      {!!messageData && (!isDataCollapsed || showToolResults) && (
                        <div className="chatWidget">
                          <WillyMessageWidget
                            message={message}
                            data={messageData}
                            prevData={prevMessageData}
                            chatSourceIds={chatSourceIds}
                            context={hideDetails ? 'dashboard' : undefined}
                          />
                        </div>
                      )}

                      {!!messageResources && (
                        <WillyMessageResources resources={messageResources} message={message} />
                      )}

                      {!!messageCode && (
                        <WillyMessageWidget
                          codeResult={messageCode}
                          message={message}
                          chatSourceIds={chatSourceIds}
                          context={hideDetails ? 'dashboard' : undefined}
                        />
                      )}
                    </>
                  )}

                  {/* every message can have text, show it here */}
                  {!!message.text && (
                    <div className="flex-auto h-full w-full relative flex gap-4">
                      <div
                        className="w-full h-full flex-auto flex flex-col outline-none overflow-auto"
                        ref={inputRef}
                        tabIndex={1}
                        suppressContentEditableWarning={true}
                        contentEditable={message.role === 'user' && editMessage}
                        onBlur={(e) => {
                          const text = e.currentTarget?.innerText?.trim() ?? '';
                          if (text && text.length > 0) {
                            setCachedEditedMessage(text.trim());
                          }
                        }}
                      >
                        <WillySimpleText
                          text={message.text || ''}
                          error={!!message.error}
                          codeActions={codeActions}
                        />
                      </div>
                    </div>
                  )}

                  {/* message toolbar: regenerate response from this message show/hide query (if exists) */}
                  {readyForToolbar && (
                    <>
                      <div
                        className={`flex items-center flex-wrap gap-2 ${
                          isLast || editMessage
                            ? ''
                            : // let's try to make it always visible with opacity 100
                              'opacity-100 @3xl:opacity-100 group-hover:opacity-100 transition-opacity'
                        }`}
                      >
                        {message.role === 'user' && !editMessage && canEdit && (
                          <div className="flex items-center gap-4">
                            <Tooltip content="Edit">
                              <div
                                onClick={() => {
                                  inputRef.current?.focus();
                                  setEditMessage(true);
                                }}
                              >
                                <ActionIcon icon="new-edit" size="sm" />
                              </div>
                            </Tooltip>
                            <Tooltip content={isFavoritePrompt ? 'Remove Prompt' : 'Save Prompt'}>
                              <div
                                onClick={async () => {
                                  if (!message.text) {
                                    return;
                                  }
                                  setLoadingSavePrompt(true);
                                  if (isFavoritePrompt) {
                                    const prompt = favoritePrompts.find(
                                      (x) => x.prompt === message.text,
                                    );
                                    if (
                                      !!prompt?.id &&
                                      !!prompt.userId &&
                                      prompt?.userId === userId
                                    ) {
                                      await removeFavoritePrompt(prompt.id);
                                      setLoadingSavePrompt(false);
                                      return;
                                    }
                                    setLoadingSavePrompt(false);
                                  }

                                  const { data } = await axiosInstance.post(
                                    '/v2/willy/suggest-prompt-title',
                                    {
                                      shopId: currentShopId,
                                      prompt: message.text,
                                    },
                                  );

                                  const title = data.completion;

                                  const prompt: WillyPrompt = {
                                    prompt: message.text,
                                    userId: userId,
                                    category: 'Saved Prompts',
                                    subCategory: 'Saved Prompts',
                                    title: title,
                                  };
                                  await _db().collection(FAVORITE_PROMPTS_COLLECTION).add(prompt);
                                  setLoadingSavePrompt(false);
                                }}
                              >
                                <ActionIcon
                                  icon="star-plus"
                                  size="sm"
                                  color={isFavoritePrompt ? 'one.5' : undefined}
                                  loading={loadingSavePrompt}
                                />
                              </div>
                            </Tooltip>
                          </div>
                        )}
                        {message.role !== 'user' &&
                          !isSequenceMode &&
                          lastOfAssistant &&
                          !hideDetails && (
                            <Tooltip content="Save as Workflow until here">
                              <div
                                className="flex items-center justify-center cursor-pointer"
                                onClick={async () => {
                                  if (!message.conversationId) {
                                    return;
                                  }

                                  const userMessages = conversationMessages
                                    .filter((x) => x.role === 'user')
                                    .map<WorkflowStep>((x) => {
                                      const followUpMessage = conversationMessages.find(
                                        (y) => y.role === 'tool' && y.originalQuestion === x.text,
                                      );
                                      return {
                                        text: x.text ?? '',
                                        id: x.id,
                                        stepType: 'tool',
                                        toolToUse: followUpMessage?.toolResults?.name,
                                        toolPreload: getToolPreloadFromToolResult(
                                          followUpMessage?.toolResults,
                                        ),
                                      };
                                    });

                                  $multiFlowSequenceBuilder.set((x) => ({
                                    ...x,
                                    conversationId: message.conversationId,
                                    prefilledUserPrompts: userMessages,
                                  }));

                                  navigate({
                                    pathname: `/workflows/create`,
                                  });
                                }}
                              >
                                <ButtonPlay className="w-7 flex items-center justify-center dark:fill-gray-300 fill-gray-400" />
                              </div>
                            </Tooltip>
                          )}
                        {message.role !== 'user' &&
                          lastOfAssistant &&
                          !!message.fromWorkflowId &&
                          setWorkflowIdToRun &&
                          setWorkflowPanelOpen && (
                            <Tooltip content="Run Workflow">
                              <Button
                                size="xs"
                                variant="activator"
                                onClick={() => {
                                  setWorkflowIdToRun(message.fromWorkflowId);
                                  setWorkflowPanelOpen(!workflowPanelOpen);
                                }}
                              >
                                {workflowPanelOpen
                                  ? 'Close Workflow Panel'
                                  : 'See Workflow for this Message'}
                              </Button>
                            </Tooltip>
                          )}
                        {message.role === 'user' && editMessage && (
                          <div className="flex items-center gap-4">
                            <Button
                              size="xs"
                              onClick={() => {
                                const oldText = message.text?.trim();
                                const newText = cachedEditedMessage?.trim() ?? '';

                                if (oldText && oldText != newText) {
                                  handleSubmit?.(newText);
                                }

                                const input = inputRef.current;
                                if (input) {
                                  input.innerText = message.text?.trim() ?? '';
                                }

                                setEditMessage(false);
                              }}
                            >
                              Save and Submit
                            </Button>
                            <Button
                              size="xs"
                              variant="white"
                              onClick={() => {
                                const input = inputRef.current;
                                if (input) {
                                  input.innerText = message.text?.trim() ?? '';
                                }

                                setCachedEditedMessage(undefined);
                                setEditMessage(false);
                              }}
                            >
                              Cancel
                            </Button>
                          </div>
                        )}
                        {isLast && canEdit && !isSequenceMode && (
                          <Tooltip content="Regenerate">
                            <div
                              className="cursor-pointer justify-center pt-2"
                              onClick={() => {
                                if (!lastMessageFromUser?.text) {
                                  return;
                                }
                                handleSubmit?.(lastMessageFromUser.text);
                              }}
                            >
                              <Icon name="regenerate" color="gray.4" width={18} />
                            </div>
                          </Tooltip>
                        )}
                        {isLast && (
                          <Button
                            size="xs"
                            variant="activator"
                            onClick={() => {
                              window.open(
                                'https://docs.google.com/forms/d/e/1FAIpQLSchadlp-9rQ25smRi-TjQFPfYcCwjgbZouDyCZjxpFHMiiYgA/viewform?usp=sf_link',
                                '_blank',
                              );
                            }}
                          >
                            Request Support
                          </Button>
                        )}

                        {!!message.toolsNames && message.toolsNames.length > 0 && !hideDetails && (
                          <div className="flex items-center gap-4 justify-between flex-auto">
                            {shouldShowTabs && (
                              <div>
                                <MessageToolTabs
                                  message={message}
                                  selectedTab={selectedTab}
                                  tabChanged={(tab) => setSelectedTab(tab)}
                                />
                              </div>
                            )}
                            <div className="ml-auto flex items-center gap-4">
                              {message.toolsNames?.map((toolName) => {
                                toolName = convertDeprecatedToolName(toolName);
                                return (
                                  <Badge
                                    variant="dot"
                                    key={toolName}
                                    color={willyToolMap[toolName]?.color}
                                  >
                                    <span className="normal-case">
                                      {willyToolMap[toolName]?.title || startCase(toolName)}
                                    </span>
                                  </Badge>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </div>
                      {!hideDetails && (
                        <div
                          className={`transition-transform duration-100 origin-top ${
                            selectedTab ? ' scale-y-100' : 'scale-y-0'
                          }`}
                        >
                          {!!message.toolResults && message.role === 'tool' && (
                            <MessageToolDetailsTabs activeTab={selectedTab} message={message} />
                          )}
                        </div>
                      )}
                    </>
                  )}
                </div>

                <WillyEditMetric
                  open={editMetricModalOpen.open}
                  metric={
                    willyMetrics?.find((m) => m?.key === editMetricModalOpen.metricId) ?? null
                  }
                  availableMetrics={willyMetrics}
                  parameters={messageData?.parameters || emptyArray()}
                  onClose={() => setEditMetricModalOpen({ open: false })}
                  onSaved={async (metric) => {
                    setWillyMetrics((old) => {
                      return old.map((m) => {
                        if (m.key === editMetricModalOpen.metricId) {
                          return {
                            ...m,
                            ...metric,
                          };
                        }
                        return m;
                      });
                    });
                    setEditMetricModalOpen({ open: false });
                  }}
                  onRemoved={async (metricToRemove) => {
                    setWillyMetrics((old) => {
                      return old.filter((m) => m.key !== metricToRemove.key);
                    });
                    setEditMetricModalOpen({ open: false });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
