import LighthouseImage from 'components/Lighthouse/LighthouseImage';
import { DatePickerHeader } from 'components/headers/DatePickerHeader';
import PodFilterBar from 'routes/allShopsPinnedSections/PodFilterBar/PodFilterBar';
import { ReactComponent as RocketIcon } from 'icons/rocket.svg';
import { NavSection } from '../types';
import { Icon } from '@tw/ui-components';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';

export const HIDDEN_SECTION: NavSection = {
  routes: [
    {
      url: '/pods-view',
      label: 'Pods View',
      isHybridPage: true,
      isShoplessPage: true,
      icon: () => (
        <div className="flex">
          <img src={'/pod-icon-wrapper.svg'} width={30} alt={''} />
        </div>
      ),
      header: () => <PodFilterBar />,
      hidden: true,
    },
    {
      url: '/pod-settings',
      label: 'Pod Settings',
      isHybridPage: true,
      isShoplessPage: true,
      icon: () => <Icon name="settings" />,
      header: () => <></>,
      hidden: true,
    },
    {
      url: '/all-shops-admin',
      label: 'Pods View',
      isHybridPage: true,
      isShoplessPage: true,
      icon: () => <img src={'/pod-icon-wrapper.svg'} width={30} alt={''} />,
      header: () => <></>,
      hidden: true,
    },
    {
      url: '/upgrade',
      label: 'Upgrade',
      icon: () => <RocketIcon width={30} />,
      header: () => <></>,
      hidden: true,
    },
    {
      url: '/lighthouse/archive',
      label: 'Lighthouse Archive',
      icon: () => <LighthouseImage className="flex w-14 items-center" />,
      header: () => <DatePickerHeader showGroupByButton={false} />,
      hidden: true,
    },
    {
      url: '/activities-feed',
      label: 'Activities Feed',
      icon: () => <RocketIcon width={30} />,
      header: () => <DatePickerHeader showGroupByButton={true} showCompareButton={true} />,
      hidden: true,
    },
    {
      url: '/optimizations',
      label: 'Optimizations',
      icon: () => <RocketIcon width={30} />,
      header: () => <></>,
      hidden: true,
    },
    {
      url: '/user-profile',
      label: 'User Profile',
      icon: () => <RocketIcon width={30} />,
      header: () => <></>,
      hidden: true,
    },
    {
      url: '/signin',
      label: 'Sign in',
      isHybridPage: true,
      isShoplessPage: true,
      icon: () => <RocketIcon width={30} />,
      header: () => <></>,
      hidden: true,
    },
    {
      url: '/signup',
      label: 'Sign up',
      isHybridPage: true,
      isShoplessPage: true,
      icon: () => <RocketIcon width={30} />,
      header: () => <></>,
      hidden: true,
    },
    {
      url: '/signup-free',
      label: 'Sign Up Free',
      isHybridPage: true,
      isShoplessPage: true,
      icon: () => <RocketIcon width={30} />,
      header: () => <></>,
      hidden: true,
    },
    {
      url: '/signup-free-new',
      label: 'Sign Up Free New',
      isHybridPage: true,
      isShoplessPage: true,
      icon: () => <RocketIcon width={30} />,
      header: () => <></>,
      hidden: true,
    },
    {
      url: '/signup-invitation',
      label: 'Signup Invitation',
      isHybridPage: true,
      isShoplessPage: true,
      icon: () => <RocketIcon width={30} />,
      header: () => <></>,
      hidden: true,
    },
    {
      url: '/payment',
      label: 'Payment',
      isHybridPage: true,
      isShoplessPage: true,
      icon: () => <RocketIcon width={30} />,
      header: () => <></>,
      hidden: true,
    },
    {
      url: '/welcome',
      label: 'Welcome',
      isHybridPage: true,
      icon: () => <RocketIcon width={30} />,
      header: () => <></>,
      hidden: true,
    },
    {
      url: '/user-actions',
      label: 'user-actions',
      isHybridPage: true,
      isShoplessPage: true,
      icon: () => <RocketIcon width={30} />,
      header: () => <></>,
      hidden: true,
    },
    {
      url: '/share-section',
      label: 'Shared Section',
      isHybridPage: true,
      isShoplessPage: true,
      icon: () => <RocketIcon width={30} />,
      header: () => <></>,
      hidden: true,
    },
    {
      url: '/orders',
      isHybridPage: true,
      dependsOnFFSystem: FeatureFlag.ORDERS_PAGE_FF,
      label: 'Orders',
      header: () => <DatePickerHeader />,
      hidden: true,
    },
  ],
};
