import React, { Fragment, useEffect, useState } from 'react';
import { conditionsLevels, RuleTime } from './types';

import { FormLayout, Modal, Card, Collapsible, Link, Button, Tooltip } from '@shopify/polaris';
import { RuleAction } from './RuleAction';
import { RuleCondition } from './RuleCondition';
import { ChevronDownMinor } from '@shopify/polaris-icons';
import { cloneDeep } from 'lodash';
import { ConditionalWrapper } from 'components/ConditionalWrapper';
import { MISSING_PERMISSION_TOOLTIP, UNMATCHED_RATE_TOO_HIGH_TOOLTIP } from './constants';
import { RULES_ENGINE_STRATEGY } from 'constants/knowledgeBaseLinks';

export interface RuleType {
  rule: {
    fact: string;
    path: string;
    params: RuleTime;
    operator: string;
    value: number;
  };
}

type Props = {
  onClose: () => void;
  isOpen: boolean;
  strategies: any;
  toggleTWRule: (twRules: any, trunOn: boolean) => void;
  currentShopId: string;
  errorSave: any;
  pendingSave: any;
  checkHealthResult?: any;
  showApplyRules: boolean;
  children?: React.ReactNode[];
};

export const TWRuleStrategy: React.FC<Props> = ({
  onClose,
  isOpen,
  strategies,
  toggleTWRule,
  errorSave,
  pendingSave,
  checkHealthResult,
  currentShopId,
  showApplyRules,
}) => {
  const [open, setOpen] = useState({});
  const [newStrategy, setNewStrategy] = useState<any>({});
  const [isUnmatchedRateLow, setIsUnmatchedRateLow] = useState(false);
  const [isFbScopeEnough, setIsFbScopeEnough] = useState(false);
  const [showCheckHealthTooltip, setShowCheckHealthTooltip] = useState(false);

  useEffect(() => {
    let formattedStrategy = cloneDeep(strategies);
    formattedStrategy?.rules?.forEach((rule) => {
      rule.event.type = rule.event?.params?.valueType
        ? `${rule.event.type}-${rule.event.params.valueType}`
        : rule.event.type;
      rule?.conditions.all.forEach((group) => {
        group.any.forEach((condition) => {
          if (condition.fact?.includes('Budget')) {
            condition.path = condition.fact;
            let newFact = condition.fact.split('Budget')[0];
            condition.fact = newFact;
          }
          if (condition.value?.fact?.includes('Budget')) {
            let newFact = condition.value?.fact?.split('Budget')[0];
            condition.value.path = condition.value.fact;
            condition.value.fact = newFact;
          }
        });
      });
    });
    setNewStrategy(formattedStrategy);
  }, [strategies]);

  useEffect(() => {
    setIsUnmatchedRateLow(
      checkHealthResult?.unmatched === 'ok' || checkHealthResult?.unmatched === true,
    );
    setIsFbScopeEnough(
      checkHealthResult?.permission === 'ok' || checkHealthResult?.permission === true,
    );

    if (!isUnmatchedRateLow && !isFbScopeEnough) {
      setShowCheckHealthTooltip(true);
    } else {
      setShowCheckHealthTooltip(false);
    }
  }, [checkHealthResult, isUnmatchedRateLow, isFbScopeEnough]);

  return (
    <Modal
      large={showApplyRules ? false : true}
      open={isOpen}
      onClose={onClose}
      title={
        <div className="flex-container flex-children middle-children font-bold">
          Triple Whale Rule Strategy
        </div>
      }
    >
      <div className="traffic-rule-strategy-modal">
        <Modal.Section>
          <FormLayout>
            {!showApplyRules && (
              <>
                <div className="mb-5">
                  <span>
                    Launching the Triple Whale Strategy will set each of following Facebook
                    advertising rules active. Please review each one using the down arrow, then
                    click the launch button to activate the strategy.&nbsp;
                    <Link url={RULES_ENGINE_STRATEGY} removeUnderline>
                      Learn more.
                    </Link>
                  </span>
                </div>
                <div className="mt-3">
                  {(newStrategy?.rules || []).map((rule, ruleIndex) => (
                    <Fragment key={`${rule}-${ruleIndex}`}>
                      <Card>
                        <div
                          className="cursor-pointer items-center flex gap-4"
                          onClick={() => setOpen({ ...open, [rule._id]: !open[rule._id] })}
                        >
                          <span className="mr-auto ml-6  mt-5 text-2xl mb-6.5 font-bold">
                            Rule #{ruleIndex + 1} {rule.name}
                          </span>
                          <span className="ml-auto mr-6">
                            <ChevronDownMinor width={16} height={16} className=" " />
                          </span>
                        </div>

                        <Collapsible open={open[rule._id]} id="basic-collapsible" expandOnPrint>
                          <div className="ml-10 pl-6 pb-6">
                            <p>IF THE FOLLOWING IS TRUE:</p>
                          </div>
                          <div>
                            <div>
                              {rule?.conditions?.all.length &&
                                rule?.conditions.all.map((group, groupIndex) => (
                                  <Fragment key={`${group}-${groupIndex}`}>
                                    <div className="gap-4">
                                      <Card.Section>
                                        {group.any.map((condition, conditionIndex) => (
                                          <Fragment key={`${condition}-${conditionIndex}`}>
                                            <Card>
                                              <div className="ml-6">
                                                <RuleCondition
                                                  rule={rule}
                                                  setRule={() => {}}
                                                  conditionsLevelsList={
                                                    Object.values(conditionsLevels).map(
                                                      (action) => ({
                                                        label: action.label,
                                                        value: action.value,
                                                      }),
                                                    ) || []
                                                  }
                                                  condition={condition}
                                                  conditionIndex={conditionIndex}
                                                  groupIndex={groupIndex}
                                                  deleteCondition={() => {}}
                                                  disabled={true}
                                                ></RuleCondition>
                                              </div>
                                            </Card>
                                          </Fragment>
                                        ))}
                                      </Card.Section>
                                    </div>
                                    <div className="ml-10 pl-6 pb-6">
                                      {rule?.conditions?.all?.length > 1 &&
                                        groupIndex < rule?.conditions?.all?.length - 1 && (
                                          <span>AND</span>
                                        )}
                                    </div>
                                  </Fragment>
                                ))}
                            </div>
                          </div>
                          <Card.Section>
                            <RuleAction disabled={true} rule={rule} setRule={() => {}}></RuleAction>
                          </Card.Section>
                        </Collapsible>
                      </Card>
                    </Fragment>
                  ))}
                </div>
              </>
            )}

            {showApplyRules && (
              <div className="flex content-center mt-2 float-center">
                <span>
                  Launching the Triple Whale Rule Strategy will automatically activate rules that
                  will optimize your Facebook campaigns, adsets, and ads.
                  <br></br>
                  Optimizations actions include turning off, turning on, and adjusting budgets.
                  <br></br>
                  Click the Launch Strategy button to set these rules live in your account today
                </span>
              </div>
            )}

            <div className=" mt-5 flex float-right gap-3 mb-5">
              <ConditionalWrapper
                condition={showCheckHealthTooltip && (!isUnmatchedRateLow || !isFbScopeEnough)}
                wrapper={(children) => (
                  <Tooltip
                    content={
                      !isUnmatchedRateLow
                        ? (UNMATCHED_RATE_TOO_HIGH_TOOLTIP as any)
                        : MISSING_PERMISSION_TOOLTIP(currentShopId, false)
                    }
                  >
                    {children}
                  </Tooltip>
                )}
              >
                <Button
                  id="active-strategy-button"
                  loading={pendingSave}
                  disabled={errorSave || pendingSave || !isUnmatchedRateLow || !isFbScopeEnough}
                  onClick={() => toggleTWRule(strategies, true)}
                >
                  Launch Strategy
                </Button>
              </ConditionalWrapper>
            </div>

            {showApplyRules && (
              <div className="flex gap-2 mt-5 mb-5 mr-2 float-right">
                <Button
                  id="cancel-apply-rule-button"
                  onClick={() => {
                    onClose();
                  }}
                >
                  Cancel
                </Button>
              </div>
            )}

            {errorSave && <div className="text-red-500">{errorSave}</div>}
          </FormLayout>
        </Modal.Section>
      </div>
    </Modal>
  );
};
