import { useIsNavWideStyle } from '$stores/nav-config-stores';
import { Box, Flex, Icon, Text, TextProps, Tooltip } from '@tw/ui-components';
import { $lastSettingsRoute, $navOpen } from 'components/Nav/stores';
import { ConditionalWrapper } from 'components/library/TwConditionalWrapper';
import { useNavigate } from 'react-router-dom';

export type SettingsTogglerSectionProps = {
  showTooltip?: boolean;
  mode?: 'wide' | 'narrow';
  openNavOnClick?: boolean;
  hideIcon?: boolean;
  textProps?: TextProps;
};

export const SettingsTogglerSection: React.FC<SettingsTogglerSectionProps> = ({
  showTooltip = true,
  mode = 'wide',
  openNavOnClick = true,
  hideIcon = false,
  textProps,
}) => {
  const isWide = useIsNavWideStyle() && mode === 'wide';
  const navigate = useNavigate();

  return (
    <Box
      data-marketing-target="market-target-settings-toggler"
      fw={500}
      cursor="pointer"
      onClick={() => {
        navigate($lastSettingsRoute.get());
        if (openNavOnClick) $navOpen.set(true);
      }}
    >
      {isWide ? (
        <Flex gap="xs" align="center">
          {!hideIcon && (
            <Flex>
              <Icon name="settings" />
            </Flex>
          )}
          <Text fw={500} fz="sm" {...textProps}>
            Settings
          </Text>
        </Flex>
      ) : (
        <ConditionalWrapper
          condition={showTooltip}
          wrapper={(x) => <Tooltip label="Settings">{x}</Tooltip>}
        >
          <Flex align="center" justify="center" w="100%" h="100%">
            <Icon name="settings" size={20} />
          </Flex>
        </ConditionalWrapper>
      )}
    </Box>
  );
};
