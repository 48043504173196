import {
  Button,
  Icon,
  Text,
  Menu,
  ActionIcon,
  Checkbox,
  Modal,
  Flex,
  Loader,
  Tooltip,
} from '@tw/ui-components';
import { WILLY_SEQUENCE_STEP_CATEGORIES, WILLY_SEQUENCE_STEP_OPTIONS } from '../constants';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useStoreValue } from '@tw/snipestate';
import { $user } from '$stores/$user';
import { DialectWithBoth, Message, StepWsStatus, WorkflowStep } from '../types/willyTypes';
import { AlanLoaderGray } from 'components/AlanLoader';
import { WillyMessageTemplate } from '../WillyMessageTemplate';
import { TreeVerticalLine } from './TreeVerticalLine';
import { FlowSubWorkflowStep } from './FlowSubWorkflowStep.tsx';
import { FlowLoadDataStep } from './FlowLoadDataStep';
import { FlowSendToDashboardStep } from './FlowSendToDashboardStep';
import { FlowSendToEmailStep } from './FlowSendToEmailStep';
import { FlowRuleStep } from './FlowRuleStep';
import { FlowStepWithText } from './FlowStepWithText';
import { getMessagesFromWorkflowResponse, isStepInvalid } from '../utils/sequences';
import { FlowSendToWarehouse } from './FlowSendToWarehouse';
import { FlowRunQueryStep } from './FlowRunQueryStep';
import { Prism as ReactSyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { formatSqlSafely } from '../utils/willyUtils';
import { FlowSendToWebhookStep } from './FlowSendToWebhookStep';
import { FlowSendToGoogleSheetStep } from './FlowSendToGoogleSheetStep';
import { useResizeObserver } from 'hooks/useResizeObserver';
import { FlowLoadRunsStep } from './FlowLoadRunsStep';
import { WillySimpleText } from '../WillySimpleText';

type SequenceFlowStepProps = {
  sequenceId: string;
  userPrompt: WorkflowStep;
  stepNumber: number;
  deleteStep: (messageId: string) => void;
  isCollapsed: boolean;
  toggleCollapse: (messageId: string) => void;
  handlePromptChange: (newPrompt: WorkflowStep) => void;
  allowRegenerateAnswers: boolean;
  onGenerateAnswer: (upToStepId?: string) => void;
  loadingAnswers: boolean;
  depth: number;
  stepRunInfo: StepWsStatus | null;
  saveSequences: (sequences: string[], messageId: string) => void;
  readOnly: boolean;
  isLastStep?: boolean;
  noMoreStepsAllowed?: boolean;
  parentStepId?: string;
  fromRule?: boolean;
  dialect: DialectWithBoth;
};

export const SequenceFlowStep: React.FC<SequenceFlowStepProps> = ({
  sequenceId,
  userPrompt,
  stepNumber,
  deleteStep,
  isCollapsed,
  toggleCollapse,
  handlePromptChange,
  allowRegenerateAnswers,
  stepRunInfo,
  onGenerateAnswer,
  loadingAnswers,
  depth,
  saveSequences,
  readOnly,
  isLastStep,
  fromRule,
  dialect,
  noMoreStepsAllowed,
}) => {
  const user = useStoreValue($user);
  const [answerHidden, setAnswerHidden] = useState<boolean>(true);
  const [assetModalOpened, setAssetModalOpened] = useState<boolean>(false);
  const [fullAnswerPopupOpen, setFullAnswerPopupOpen] = useState<boolean>(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [isPristine, setIsPristine] = useState(true);
  const [showRephrasedText, setShowRephrasedText] = useState(true);
  useEffect(() => {
    if (!!isLastStep) {
      setAnswerHidden(false);
    }
  }, [isLastStep]);

  const generatedAnswer = stepRunInfo?.response;
  const isRunning = stepRunInfo?.status === 'running';
  const isDone = stepRunInfo?.status === 'done';
  const isError = stepRunInfo?.status === 'error';
  const isSkipped = stepRunInfo?.status === 'skipped';
  const isAwaiting =
    stepRunInfo?.status !== 'done' &&
    stepRunInfo?.status !== 'error' &&
    stepRunInfo?.status !== 'running' &&
    stepRunInfo?.status !== 'skipped' &&
    loadingAnswers;
  const error = stepRunInfo?.error;
  const progress = stepRunInfo?.progress;

  const MAX_ANSWER_BOX_HEIGHT = 300;

  const setAnswerBoxRef = useResizeObserver((entries) => {
    if (!entries[0]) return;
    const { blockSize: height } = entries[0].contentBoxSize[0];
    setIsOverflowing(height > MAX_ANSWER_BOX_HEIGHT);
  });

  const hasAnswer = useMemo(() => {
    return !!generatedAnswer;
  }, [generatedAnswer]);

  const canRegenerate = useMemo(() => {
    if (!allowRegenerateAnswers) {
      return false;
    }

    return !isStepInvalid(userPrompt);
  }, [allowRegenerateAnswers, userPrompt]);

  const stepError = useMemo(() => {
    return isStepInvalid(userPrompt);
  }, [userPrompt]);

  const toolConfig = useMemo(() => {
    if (userPrompt.stepType === 'tool') {
      const toolUsed = userPrompt.toolToUse ?? 'auto';
      const tool = WILLY_SEQUENCE_STEP_OPTIONS.find(
        (option) => option.type === 'tool' && option.toolName === toolUsed,
      );
      return tool;
    }
    const tool = WILLY_SEQUENCE_STEP_OPTIONS.find((option) => option.type === userPrompt.stepType);
    return tool;
  }, [userPrompt]);

  const categoryConfig = useMemo(() => {
    const tool = WILLY_SEQUENCE_STEP_OPTIONS.find((option) => option.type === userPrompt.stepType);
    const toolCategory = WILLY_SEQUENCE_STEP_CATEGORIES.find(
      (option) => option.id === tool?.category,
    );
    return toolCategory;
  }, [userPrompt]);

  const messages: Message[] = useMemo(() => {
    if (!generatedAnswer) {
      return [];
    }
    return getMessagesFromWorkflowResponse(generatedAnswer, dialect);
  }, [generatedAnswer, dialect]);

  const lastMessageFromUser = useMemo(() => {
    return messages?.findLast((message) => message.role === 'user');
  }, [messages]);

  const _onShowAnswer = async (upToStepId?: string) => {
    if (canRegenerate) {
      await onGenerateAnswer(upToStepId);
    }
  };

  return (
    <div
      key={userPrompt.id}
      className="mx-5"
      style={{
        width: `calc(700px - ${depth * 50}px)`,
      }}
    >
      {!(depth === 0 && stepNumber === 0) && <TreeVerticalLine />}

      <div className={`flex flex-col`}>
        <div
          className={`group flex p-5 rounded  items-center gap-3 justify-between cursor-pointer border border-solid border-gray-300 ${isCollapsed ? '' : 'border-b-0 rounded-b-none'} `}
          style={{
            backgroundColor: `var(--mantine-color-${toolConfig?.color}-${['one', 'two'].includes(categoryConfig?.color || '') ? '0' : '1'})`,
          }}
        >
          <span className="flex gap-3 items-center">
            {isRunning && <Loader size="xs" />}
            {!isRunning && isDone && !error && <Icon name="check-thin" color="green.4" size={16} />}
            {!!error && isError && (
              <Tooltip label={error}>
                <div className="flex">
                  <Icon name="info" color="red.4" size={16} />
                </div>
              </Tooltip>
            )}
            {isSkipped && <Icon name="circle-x" color="gray.4" size={16} />}
            {isAwaiting && <Icon name="clock" color="gray.4" size={16} />}
            <Icon
              name={toolConfig?.icon || 'triple-whale-rounded-logo'}
              color={`${toolConfig ? toolConfig?.color : 'one'}.4`}
            />
            <Text fw={500}>
              <span className="line-clamp-1">
                {stepNumber + 1}. {toolConfig?.name}
              </span>
            </Text>
            {!!progress && (
              <Text fz={12} color="gray.4" fw={500}>
                <span className="line-clamp-1">({progress})</span>
              </Text>
            )}
          </span>
          <div
            className={`flex gap-3 items-center opacity-0 transition-opacity group-hover:opacity-100 `}
          >
            {userPrompt.stepType === 'tool' &&
              (userPrompt.toolToUse === 'Forecasting' ||
                userPrompt.toolToUse === 'MarketingMixModel' ||
                userPrompt.toolToUse === 'TextToSQL' ||
                userPrompt.toolToUse === 'TextToPython') && (
                <Menu closeOnItemClick={false}>
                  <Menu.Target>
                    <ActionIcon icon="settings" iconSize={16} />
                  </Menu.Target>
                  <Menu.Dropdown>
                    <Menu.Item>
                      <Checkbox
                        label={
                          userPrompt.toolToUse === 'TextToPython'
                            ? 'Preserve Python code'
                            : 'Preserve SQL query'
                        }
                        disabled={readOnly}
                        checked={userPrompt.preserveHistory}
                        onChange={(checked) =>
                          handlePromptChange({
                            ...userPrompt,
                            preserveHistory: checked,
                          })
                        }
                      />
                    </Menu.Item>
                    <Menu.Item
                      disabled={!userPrompt.toolPreload || !userPrompt.preserveHistory}
                      onClick={() => {
                        setAssetModalOpened(true);
                      }}
                    >
                      View {userPrompt.toolToUse === 'TextToPython' ? 'Code' : 'Query'}
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              )}
            {!readOnly && (
              <ActionIcon icon="delete" iconSize={20} onClick={() => deleteStep(userPrompt.id)} />
            )}
            <div className={`transition-transform ${isCollapsed ? '' : 'rotate-180'}`}>
              <ActionIcon
                icon="chevron-down"
                iconSize={10}
                onClick={() => {
                  toggleCollapse(userPrompt.id);
                }}
              />
            </div>
          </div>
        </div>

        {!isCollapsed && (
          <div
            className={`flex flex-col p-5 rounded rounded-t-none gap-4 border border-solid border-gray-300 border-t-0 min-h-[232px]`}
          >
            <div className="flex justify-between relative">
              <div className="flex-1 min-h-[75px]">
                <div className="w-full relative">
                  {(userPrompt.stepType === 'tool' ||
                    userPrompt.stepType === 'insights' ||
                    userPrompt.stepType === 'rule') && (
                    <FlowStepWithText
                      readOnly={readOnly}
                      text={userPrompt.text || ''}
                      textChange={(text) => handlePromptChange({ ...userPrompt, text })}
                      toolConfig={toolConfig}
                      setIsPristine={setIsPristine}
                    />
                  )}
                  {userPrompt.stepType === 'preloadData' && (
                    <div>
                      <FlowLoadDataStep
                        readOnly={readOnly}
                        step={userPrompt}
                        handlePromptChange={handlePromptChange}
                        setIsPristine={setIsPristine}
                      />
                    </div>
                  )}
                  {userPrompt.stepType === 'preloadRuns' && (
                    <div>
                      <FlowLoadRunsStep
                        readOnly={readOnly}
                        step={userPrompt}
                        handlePromptChange={handlePromptChange}
                        setIsPristine={setIsPristine}
                        sequenceId={sequenceId}
                      />
                    </div>
                  )}

                  {userPrompt.stepType === 'subSequence' && (
                    <FlowSubWorkflowStep
                      readOnly={readOnly}
                      onSave={(sequences) => saveSequences(sequences, userPrompt.id)}
                      sequenceIds={userPrompt.sequenceIds}
                      limitToOne={fromRule}
                      setIsPristine={setIsPristine}
                    />
                  )}
                  {userPrompt.stepType === 'sendToDashboard' && (
                    <FlowSendToDashboardStep
                      readOnly={readOnly}
                      onSave={(dashboardId) => handlePromptChange({ ...userPrompt, dashboardId })}
                      dashboardId={userPrompt.dashboardId}
                      setIsPristine={setIsPristine}
                    />
                  )}
                  {userPrompt.stepType === 'sendToEmail' && (
                    <FlowSendToEmailStep
                      readOnly={readOnly}
                      email={userPrompt.email}
                      setEmail={(email) => handlePromptChange({ ...userPrompt, email })}
                      formats={userPrompt.formats}
                      setFormats={(formats) => handlePromptChange({ ...userPrompt, formats })}
                      setIsPristine={setIsPristine}
                    />
                  )}
                  {userPrompt.stepType === 'sendToGoogleSheet' && (
                    <FlowSendToGoogleSheetStep
                      readOnly={readOnly}
                      onSave={(args) => {
                        setIsPristine(false);
                        return handlePromptChange({
                          ...userPrompt,
                          ...args,
                        });
                      }}
                      {...userPrompt}
                    />
                  )}
                  {userPrompt.stepType === 'sendToWarehouse' && (
                    <FlowSendToWarehouse
                      readOnly={readOnly}
                      onProviderChange={(providerId, integrationId, tableId) =>
                        handlePromptChange({ ...userPrompt, providerId, integrationId, tableId })
                      }
                      providerId={userPrompt.providerId}
                      integrationId={userPrompt.integrationId}
                      tableId={userPrompt.tableId}
                      setIsPristine={setIsPristine}
                      query={userPrompt.query}
                      useDedicatedQuery={userPrompt.useDedicatedQuery}
                      onUseDedicatedQueryChange={(useDedicatedQuery) =>
                        handlePromptChange({ ...userPrompt, useDedicatedQuery })
                      }
                      toolConfig={toolConfig}
                      step={userPrompt}
                      onQueryChange={(query) => handlePromptChange({ ...userPrompt, query })}
                      onParamsChange={(params) =>
                        handlePromptChange({ ...userPrompt, queryParams: params })
                      }
                    />
                  )}
                  {userPrompt.stepType === 'runQuery' && (
                    <FlowRunQueryStep
                      step={userPrompt}
                      onSave={(query, params) =>
                        handlePromptChange({ ...userPrompt, query, queryParams: params })
                      }
                      readOnly={readOnly}
                      toolConfig={toolConfig}
                      setIsPristine={setIsPristine}
                    />
                  )}
                  {userPrompt.stepType === 'condition' && (
                    <FlowRuleStep
                      step={userPrompt}
                      handlePromptChange={handlePromptChange}
                      readOnly={readOnly}
                      setIsPristine={setIsPristine}
                    />
                  )}
                  {userPrompt.stepType === 'sendToWebhook' && (
                    <FlowSendToWebhookStep
                      step={userPrompt}
                      sequenceId={sequenceId}
                      handlePromptChange={(url, headers) =>
                        handlePromptChange({ ...userPrompt, url, headers })
                      }
                      readOnly={readOnly}
                      setIsPristine={setIsPristine}
                    />
                  )}
                </div>
              </div>
            </div>
            {!!stepRunInfo?.rephrasedText && showRephrasedText && (
              <div className="bg-white p-3 rounded-md flex flex-col gap-2 relative">
                <div className="absolute top-2 right-2">
                  <ActionIcon
                    icon="close"
                    iconSize={16}
                    onClick={() => setShowRephrasedText(false)}
                  />
                </div>
                <Text fz={12} fw={500}>
                  The step was rephrased to:
                </Text>
                <div className="max-h-[200px] overflow-hidden">
                  <WillySimpleText text={stepRunInfo.rephrasedText} />
                </div>
              </div>
            )}
            <div className="mt-auto">
              {!!stepError && !isPristine && (
                <Text size="sm" fw="bold" color="red.4">
                  {stepError}
                </Text>
              )}
            </div>
            <div className="flex justify-between">
              {!['preloadData', 'condition', 'rule', 'preloadRuns'].includes(
                userPrompt.stepType,
              ) && (
                <Button
                  variant="white"
                  size="xs"
                  onClick={() => _onShowAnswer(userPrompt.id)}
                  disabled={!canRegenerate}
                >
                  Run until here
                </Button>
              )}

              {hasAnswer && (
                <div
                  className="flex gap-3 items-center cursor-pointer"
                  onClick={() => {
                    setAnswerHidden((x) => !x);
                  }}
                >
                  <Text fz={12} fw={500}>
                    Response
                  </Text>
                  <span className={`transition-transform ${answerHidden ? '' : 'rotate-180'}`}>
                    <Icon name={'caret-down'} size={10} />
                  </span>
                </div>
              )}
            </div>
            {hasAnswer && !answerHidden && (
              <div
                className={`flex flex-col gap-3 transition-[height] duration-300 ease-in-out ${!answerHidden ? 'h-full' : 'h-0'}`}
              >
                {isRunning && (
                  <div className="flex items-center w-full justify-center">
                    <span className="scale-50">
                      <AlanLoaderGray />
                    </span>
                  </div>
                )}
                {!isRunning && !!messages?.length && (
                  <div className="relative">
                    <div
                      className={`max-h-[${MAX_ANSWER_BOX_HEIGHT}px] overflow-hidden`}
                      style={{ maxHeight: `${MAX_ANSWER_BOX_HEIGHT}px` }}
                    >
                      <div ref={setAnswerBoxRef} id="answer-wrapper">
                        {messages?.map((message, i, arr) => {
                          return (
                            <WillyMessageTemplate
                              message={message}
                              firstOfAssistant={
                                message.role !== 'user' && arr[i - 1]?.role === 'user'
                              }
                              lastOfAssistant={
                                (message.role !== 'user' && arr[i + 1]?.role === 'user') ||
                                i === arr.length - 1
                              }
                              lastMessageFromUser={lastMessageFromUser}
                              canEdit={false}
                              userName={''}
                              handleSubmit={() => {}}
                              key={message.id + '_element'}
                              loading={loadingAnswers}
                              codeActions={[]}
                              isLast={i === messages.length - 1}
                              conversationUser={user.uid || ''}
                              isSequenceMode
                              //   TODO:notsure
                              conversationMessages={messages}
                            />
                          );
                        })}
                      </div>
                    </div>
                    {isOverflowing && (
                      <div
                        onClick={() => setFullAnswerPopupOpen(true)}
                        className="bg-gradient-to-t from-white via-white to-transparent absolute bottom-0 h-[150px] items-end justify-center w-full flex cursor-pointer"
                      >
                        <Text fz={14} fw={600} color="one.6">
                          Open Full Answer
                        </Text>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>

      {!noMoreStepsAllowed && <TreeVerticalLine />}

      <Modal opened={assetModalOpened} onClose={() => setAssetModalOpened(false)}>
        <ToolPreloadAssets toolPreload={userPrompt} />
        <Modal.Footer>
          <Flex gap="xs" justify="flex-end">
            <Button variant="white" onClick={() => setAssetModalOpened(false)}>
              Close
            </Button>
            <Button
              variant="danger"
              onClick={() => {
                if (userPrompt.stepType !== 'tool') {
                  return;
                }
                handlePromptChange({
                  ...userPrompt,
                  toolPreload: null,
                });
                setAssetModalOpened(false);
              }}
            >
              Remove{' '}
              {userPrompt.stepType === 'tool'
                ? userPrompt.toolPreload?.name === 'TextToSQL'
                  ? 'query'
                  : 'code'
                : 'assets'}
            </Button>
          </Flex>
        </Modal.Footer>
      </Modal>
      <Modal opened={fullAnswerPopupOpen} onClose={() => setFullAnswerPopupOpen(false)} size="xl">
        <div id="answer-wrapper">
          {messages?.map((message, i, arr) => {
            return (
              <WillyMessageTemplate
                message={message}
                firstOfAssistant={message.role !== 'user' && arr[i - 1]?.role === 'user'}
                lastOfAssistant={
                  (message.role !== 'user' && arr[i + 1]?.role === 'user') || i === arr.length - 1
                }
                lastMessageFromUser={lastMessageFromUser}
                canEdit={false}
                userName={''}
                handleSubmit={() => {}}
                key={message.id + '_element'}
                loading={loadingAnswers}
                codeActions={[]}
                isLast={i === messages.length - 1}
                conversationUser={user.uid || ''}
                isSequenceMode
                //   TODO:notsure
                conversationMessages={messages}
              />
            );
          })}
        </div>
      </Modal>
    </div>
  );
};

type ToolPreloadAssetsProps = {
  toolPreload: WorkflowStep;
};

export const ToolPreloadAssets: React.FC<ToolPreloadAssetsProps> = ({ toolPreload }) => {
  const code = useMemo(() => {
    if (toolPreload.stepType !== 'tool') {
      return 'No assets found';
    }
    if (toolPreload.toolPreload?.name === 'TextToSQL') {
      return formatSqlSafely(toolPreload.toolPreload?.generatedQuery || 'Query not generated');
    }
    if (toolPreload.toolPreload?.name === 'TextToPython') {
      return toolPreload.toolPreload?.pythonCode || 'Code not generated';
    }
    return 'No assets found';
  }, [toolPreload]);

  if (toolPreload.stepType !== 'tool') {
    return null;
  }

  return (
    <div>
      <ReactSyntaxHighlighter
        language={toolPreload.toolPreload?.name === 'TextToSQL' ? 'sql' : 'python'}
        style={vscDarkPlus}
        showLineNumbers
        customStyle={{
          maxHeight: '300px',
          overflow: 'auto',
        }}
      >
        {code}
      </ReactSyntaxHighlighter>
    </div>
  );
};
