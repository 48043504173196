import { Link } from 'react-router-dom';
import { WILLY_NAV_ITEMS } from './willyNavItems';
import { closeChatSelectorDrawer } from '$stores/willy/$mobileDrawers';
import { Text } from '@tw/ui-components';

type NavItem = (typeof WILLY_NAV_ITEMS)[0];
type WillyNavItemProps = { activeTab: string; item: NavItem };

export const WillyNavItem: React.FC<WillyNavItemProps> = ({ activeTab, item }) => {
  const linkAction = () => {
    closeChatSelectorDrawer();
  };
  return (
    <Link
      to={item.link}
      onClick={() => linkAction()}
      className={
        'no-underline text-[var(--mantine-color-gray-7)] font-medium group/dashboard-label gap-2 ' +
        'render-item flex rounded-md items-center ' +
        'cursor-pointer hover:bg-[var(--gray-light-mode-300)] py-1 ' +
        (item.id === activeTab ? 'bg-[var(--gray-light-mode-300)]' : '')
      }
    >
      <div className="flex items-center gap-2 pl-4 py-1">
        {/* <Icon name={item.icon as any} /> */}
        <Text span fz="sm" c="gray.8" fw={500}>
          {item.label}
        </Text>
        <div className="opacity-0 group-hover/report-side-panel:opacity-100 pl-4 min-w-[24px]" />
      </div>
    </Link>
  );
};
