import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  Button,
  OptionList,
  Stack,
  TextField,
  Subheading,
  RadioButton,
} from '@shopify/polaris';
import {
  toggleFacebookConfigScreenOpen,
  facebookAccountsSelectOnChange,
  facebookConfigSavePress,
  facebookAccountsSearchOnChange,
  facebookAttributionWindowOnChange,
  facebookConnectOnPress,
} from '../ducks/facebook';

const FacebookAdsAccountsConfig = ({
  isOpen,
  toggleIsOpen,
  accountsSelectOnChange,
  accounts,
  isLoading,
  save,
  allSelected,
  searchOnChange,
  facebookAccountSearch,
  attributionWindowOnChange,
}) => (
  <Modal
    title={accounts.length ? 'Facebook Settings' : 'Connect to Facebook'}
    open={isOpen}
    onClose={toggleIsOpen}
    primaryAction={{
      content: 'Save',
      onAction: save,
      disabled:
        !accounts.filter((acc) => acc.selected).length ||
        isLoading ||
        accounts.filter((acc) => acc.selected).length > 20,
    }}
    loading={isLoading}
    //sectioned
  >
    {accounts.length > 0 && (
      <AttributionWindowsPicker attributionWindowOnChange={attributionWindowOnChange} />
    )}
    {accounts.length > 1 && (
      <Modal.Section>
        <Subheading>Select Facebook Business accounts</Subheading>
        <br />
        <Stack>
          <Stack.Item fill>
            <Button
              disabled={!!facebookAccountSearch.length}
              onClick={() => {
                var accs = accounts.slice();
                accs.forEach((acc) => (acc.selected = !allSelected));
                accountsSelectOnChange(accs);
              }}
            >
              {allSelected ? 'Unselect All' : 'Select All'}
            </Button>
          </Stack.Item>
          <Stack.Item>
            <TextField
              value={facebookAccountSearch}
              placeholder="Search"
              clearButton
              onChange={searchOnChange}
              onClearButtonClick={() => searchOnChange('')}
              autoComplete="off"
              label={''}
            />
          </Stack.Item>
        </Stack>
        {accounts.filter((acc) => acc.selected).length > 20 && (
          <div className={'mt-10'} style={{ color: 'red' }}>
            You can select up to 20 accounts. Please unselect some accounts.
          </div>
        )}
      </Modal.Section>
    )}
    <OptionList
      //title="Select relevant accounts"
      onChange={(selected) => {
        var accs = accounts.slice();
        accs.forEach((acc) => (acc.selected = selected.includes(acc.account_id)));
        accountsSelectOnChange(accs);
      }}
      options={(facebookAccountSearch.length
        ? accounts.filter(
            (a) =>
              a.name.toLowerCase().indexOf(facebookAccountSearch.toLowerCase()) >= 0 ||
              String(a.account_id).indexOf(facebookAccountSearch) >= 0,
          )
        : accounts
      ).map((account) => {
        return {
          value: account.account_id,
          label: account.name + ' (' + account.account_id + ')',
        };
      })}
      selected={accounts.filter((acc) => acc.selected).map((acc) => acc.account_id)}
      allowMultiple
    />
    {accounts.length === 0 && (
      <Modal.Section>
        <Button primary onClick={facebookConnectOnPress}>
          Connect to Facebook
        </Button>
      </Modal.Section>
    )}
  </Modal>
);

const AttributionWindowsPicker = ({ attributionWindowOnChange }) => {
  var [currentType, setCurrentType] = useState('7d_click,1d_view');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  var handleChange = useCallback((val, id) => {
    setCurrentType(id);
    attributionWindowOnChange(id);
  }, []);
  return (
    <Modal.Section>
      <Subheading>Select Attribution Windows</Subheading>
      <br />
      <b>After clicking ad</b>
      <br />
      <RadioButton
        label="1-day click"
        checked={currentType === '1d_click'}
        id="1d_click"
        name="currentType"
        onChange={handleChange}
      />
      <br />
      <RadioButton
        label="7-day click"
        checked={currentType === '7d_click'}
        id="7d_click"
        name="currentType"
        onChange={handleChange}
      />
      <br />
      <br />
      <b>After clicking or viewing ad</b>
      <br />
      <RadioButton
        label="1-day click or view"
        checked={currentType === '1d_click,1d_view'}
        id="1d_click,1d_view"
        name="currentType"
        onChange={handleChange}
      />
      <br />
      <RadioButton
        label="7-day click or 1-day view"
        checked={currentType === '7d_click,1d_view'}
        id="7d_click,1d_view"
        name="currentType"
        onChange={handleChange}
      />
    </Modal.Section>
  );
};

const mapStateToProps = ({
  facebookConfigScreenIsOpen,
  facebookConfigInProgress,
  facebookAdsAccounts,
  facebookAccountSearch,
}) => ({
  isOpen: facebookConfigScreenIsOpen,
  isLoading: facebookConfigInProgress || !facebookAdsAccounts || !facebookAdsAccounts.length,
  accounts: facebookAdsAccounts || [],
  allSelected: facebookAdsAccounts
    ? facebookAdsAccounts.filter((acc) => acc.selected).length === facebookAdsAccounts.length
    : false,
  facebookAccountSearch,
});

const mapDispatchToProps = (dispatch) => ({
  toggleIsOpen: () => dispatch(toggleFacebookConfigScreenOpen()),
  accountsSelectOnChange: (value) => dispatch(facebookAccountsSelectOnChange(value)),
  attributionWindowOnChange: (value) => dispatch(facebookAttributionWindowOnChange(value)),
  save: () => dispatch(facebookConfigSavePress()),
  searchOnChange: (val) => dispatch(facebookAccountsSearchOnChange(val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FacebookAdsAccountsConfig);
