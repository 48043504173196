import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { type RootState } from '../reducers/RootType';
import { selectAllActiveOrderSegmentMetrics } from './selectors';
import {
  getShopifySegmentsNewStatsForShop,
  loadingNewStats,
  newStatsReceived,
} from '../ducks/newStats';
import { useAppDispatch } from '../index';
import { getStatsSamePeriodComparison } from '../ducks/actions';
import { usePrevious } from './usePrevious';
import { $useClickHouseInSummary, $useSummaryWillyWay } from '../$stores/$shop';
import { useStoreValue } from '@tw/snipestate';

const containsAll = (arr1, arr2) => arr2.every((arr2Item) => arr1.includes(arr2Item));

const sameMembers = (arr1, arr2) =>
  containsAll(
    arr1.map((x) => x.id),
    arr2.map((x) => x.id),
  ) &&
  containsAll(
    arr2.map((x) => x.id),
    arr1.map((x) => x.id),
  );

export const useActiveOrderSegment = () => {
  const {
    activeOrderSegment: calculatedOrderSegment,
    isReady,
    msp,
  } = useSelector(selectAllActiveOrderSegmentMetrics);

  const mainDatePickerSelectionRange = useSelector(
    (state: RootState) => state.mainDatePickerSelectionRange,
  );
  const groupStatsBy = useSelector((state: RootState) => state.groupStatsBy);
  const useClickHouseInSummary = useStoreValue($useClickHouseInSummary);
  const useSummaryWillyWay = useStoreValue($useSummaryWillyWay);
  const prevGroupStatsBy = usePrevious(groupStatsBy);
  const prevUseClickHouseInSummary = usePrevious(useClickHouseInSummary);
  const prevUseSummaryWillyWay = usePrevious(useSummaryWillyWay);
  const prevCalculatedOrderSegment = usePrevious(calculatedOrderSegment);
  const currentShopId = useSelector((state: RootState) => state.currentShopId);
  const shopTimezone = useSelector((state: RootState) => state.shopTimezone);
  const dispatch = useAppDispatch();
  const prevMainDatePickerSelectionRange = usePrevious(mainDatePickerSelectionRange);
  const prevCurrentShopId = usePrevious(currentShopId);
  const prevShopTimezone = usePrevious(shopTimezone);
  // useTraceUpdate({
  //   calculatedOrderSegment,
  //   mainDatePickerSelectionRange,
  //   groupStatsBy,
  //   currentShopId,
  //   shopTimezone,
  // });

  useEffect(() => {
    if (
      !isReady ||
      !mainDatePickerSelectionRange ||
      !currentShopId ||
      !shopTimezone ||
      !groupStatsBy
    ) {
      return;
    }

    if (
      prevMainDatePickerSelectionRange?.start === mainDatePickerSelectionRange.start &&
      prevMainDatePickerSelectionRange?.end === mainDatePickerSelectionRange.end &&
      prevCurrentShopId === currentShopId &&
      prevShopTimezone === shopTimezone &&
      prevGroupStatsBy === groupStatsBy &&
      prevUseClickHouseInSummary === useClickHouseInSummary &&
      prevUseSummaryWillyWay === useSummaryWillyWay &&
      prevCalculatedOrderSegment &&
      prevCalculatedOrderSegment?.length > 0 &&
      sameMembers(prevCalculatedOrderSegment, calculatedOrderSegment)
    ) {
      return;
    }

    (async () => {
      dispatch(getStatsSamePeriodComparison(calculatedOrderSegment));

      if (msp === 'shopify' && calculatedOrderSegment?.length > 0) {
        const start_time = new Date().getTime();
        const groupedStats = await getShopifySegmentsNewStatsForShop(
          groupStatsBy !== prevGroupStatsBy,
          mainDatePickerSelectionRange,
          groupStatsBy,
          () => {},
          // dispatch(
          //   loadingNewStats({
          //      'shopify-segment': true,
          //   })
          // ),
          shopTimezone,
          currentShopId,
          calculatedOrderSegment,
        );
        const end_time = new Date().getTime();
        console.debug(
          'performance checking - getServiceNewStats',
          'Shopify-segment',
          end_time - start_time,
          new Date().toISOString(),
        );
        dispatch(
          newStatsReceived({
            stats: groupedStats,
            serviceId: 'shopify-segment',
          }),
        );
      }
    })();
  }, [
    isReady,
    calculatedOrderSegment,
    currentShopId,
    mainDatePickerSelectionRange,
    shopTimezone,
    groupStatsBy,
    useClickHouseInSummary,
    useSummaryWillyWay,
    prevCalculatedOrderSegment,
    dispatch,
    msp,
  ]);
};
