import { useMemo, useState } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { Badge, Text, Skeleton, Button } from '@tw/ui-components';
import { useStoreValue } from '@tw/snipestate';
import { $userId } from '$stores/$user';
import { useFilteredItems } from './hooks/useFilteredItems';
import { WillySearchInput } from './WillySearchInput';
import { WillySelect } from './dashboardManagment/WillySelect';
import moment from 'moment';
import { Timestamp } from 'utils/DB';
import { $combinedSequences } from '$stores/willy/$combinedSequences';
import { useCallback } from 'react';
import { $shopUsers } from '$stores/$users';
import { WillyUserAvatar } from './WillyUserAvatar';

const filterOptions = [
  { value: 'all', label: 'All Workflows' },
  { value: 'user', label: 'My Workflows' },
];

const secondaryFilterOptions = [
  { value: 'all', label: 'All Types' },
  { value: 'report', label: 'Reports' },
  { value: 'workflow', label: 'Workflows' },
];

const sortOptions = [
  { value: 'recent', label: 'Most Recent' },
  { value: 'oldest', label: 'Oldest First' },
];

const momentFormat = 'MMM D, YYYY h:mm A';

const formatDate = (date: string | typeof Timestamp | Date) => {
  if (!date) return '';

  if (date instanceof Timestamp) {
    return moment(date.toDate()).format(momentFormat);
  }

  if (date instanceof Date) {
    return moment(date).format(momentFormat);
  }

  if (typeof date === 'string') {
    return moment(new Date(date)).format(momentFormat);
  }

  return '';
};

export const SequencesAllList = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const userId = useStoreValue($userId);
  const { users: shopUsers, loading: loadingUsers } = useStoreValue($shopUsers);
  const combinedSequences = useStoreValue($combinedSequences);

  // Get default values from URL params
  const params = new URLSearchParams(search);
  const defaultFilter = params.get('inboxFilter') || filterOptions[0].value;
  const defaultSecondaryFilter =
    params.get('inboxSecondaryFilter') || secondaryFilterOptions[0].value;
  const defaultSort = params.get('inboxSort') || sortOptions[0].value;

  const [filter, setFilter] = useState(defaultFilter);
  const [secondaryFilter, setSecondaryFilter] = useState(defaultSecondaryFilter);
  const [sort, setSort] = useState(defaultSort);
  const [freeSearch, setFreeSearch] = useState('');

  const hasSearchFilterOrSort = useMemo(() => {
    return (
      freeSearch !== '' ||
      filter !== filterOptions[0].value ||
      secondaryFilter !== secondaryFilterOptions[0].value ||
      sort !== sortOptions[0].value
    );
  }, [freeSearch, filter, secondaryFilter, sort]);

  const updateUrlParams = useCallback(
    (key: string, value: string) => {
      const params = new URLSearchParams(search);
      if (value) {
        params.set(key, value);
      } else {
        params.delete(key);
      }

      navigate({
        pathname: location.pathname,
        search: params.toString(),
      });
    },
    [navigate, search],
  );

  const handleFilterChange = (value: string) => {
    setFilter(value);
    updateUrlParams('inboxFilter', value);
  };

  const handleSecondaryFilterChange = (value: string) => {
    setSecondaryFilter(value);
    updateUrlParams('inboxSecondaryFilter', value);
  };

  const handleSortChange = (value: string) => {
    setSort(value);
    updateUrlParams('inboxSort', value);
  };

  const handleClearAll = () => {
    setFreeSearch('');
    setFilter(filterOptions[0].value);
    setSecondaryFilter(secondaryFilterOptions[0].value);
    setSort(sortOptions[0].value);
    navigate({ pathname: location.pathname });
  };

  const searchFiltered = useFilteredItems(combinedSequences, freeSearch, ['title', 'content']);

  const filteredItems = useMemo(() => {
    let filtered = searchFiltered;

    if (filter === 'user') {
      filtered = filtered.filter((item) => item.user === userId);
    }

    if (secondaryFilter !== 'all') {
      filtered = filtered.filter((item) => {
        if (secondaryFilter === 'report') {
          return item.isReport;
        }

        return !item.isReport;
      });
    }

    return filtered;
  }, [searchFiltered, filter, secondaryFilter, userId]);

  const sortedItems = useMemo(() => {
    return filteredItems
      .sort((a, b) => {
        const aTime = moment(formatDate(a?.finishedAt as string)).unix();
        const bTime = moment(formatDate(b?.finishedAt as string)).unix();

        return sort === 'recent' ? bTime - aTime : aTime - bTime;
      })
      .map((item) => {
        const user = shopUsers.find((x) => x.id === item.user);
        return { ...item, userName: user?.display };
      });
  }, [filteredItems, shopUsers, sort]);

  const loadingTemplate = useMemo(() => {
    return (
      <div className="flex flex-col gap-6.5 p-4">
        {Array.from({ length: 5 }).map((_, index) => (
          <Skeleton key={index} width="100%" height="100px" />
        ))}
      </div>
    );
  }, []);

  return (
    <div className="flex flex-col sm:max-w-[85%] h-full no-scrollbar overflow-y-scroll m-auto">
      <div className="p-8 pb-0">
        <div className="w-full flex flex-col lg:flex-row gap-6.5">
          <div className="w-full flex items-center gap-4">
            <WillySearchInput
              value={freeSearch}
              onChange={(v) => setFreeSearch(v)}
              placeholder="Search All Workflows"
              className="!p-0 overflow-visible w-full"
            />
            {hasSearchFilterOrSort && (
              <div className="flex-shrink-0">
                <Button variant="white" onClick={handleClearAll}>
                  Clear
                </Button>
              </div>
            )}
          </div>
          <div className="flex items-center lg:justify-between flex-shrink-0 gap-4">
            <WillySelect data={filterOptions} value={filter} onChange={handleFilterChange} />
            {/* <WillySelect
              data={secondaryFilterOptions}
              value={secondaryFilter}
              onChange={handleSecondaryFilterChange}
            /> */}
            <WillySelect data={sortOptions} value={sort} onChange={handleSortChange} />
          </div>
        </div>
      </div>

      <div className="overflow-auto flex-grow p-4">
        {sortedItems.length === 0 ? (
          <div className="p-4">No items found</div>
        ) : (
          <div className="flex flex-col gap-4 sm:gap-6.5">
            {sortedItems.map((item, i) => (
              <div
                className="cursor-pointer hover:bg-gray-100 p-4 rounded-md"
                key={item.id + i}
                onClick={() =>
                  navigate({
                    pathname: `/workflows/view/${item.id}`,
                    search: item.outputFile
                      ? `?fileName=${item.outputFile.includes('http') ? btoa(item.outputFile) : item.outputFile}&from=inbox`
                      : '?from=inbox',
                  })
                }
              >
                <div className="flex justify-between items-start gap-4 w-full border-b border-gray-100">
                  <div className="flex justify-between items-start gap-4 w-full flex-grow-1">
                    <div className="w-full">
                      <div className="flex items-center justify-between gap-2 mb-2 w-full">
                        <div className="flex items-center gap-2 w-full justify-between mb-2">
                          <div className="flex items-center gap-2 flex-wrap">
                            {loadingUsers ? (
                              <Skeleton width="100px" height="24px" />
                            ) : item.userName ? (
                              <div className="flex items-center gap-2">
                                <WillyUserAvatar
                                  userId={item.user}
                                  size="extraSmall"
                                  round={true}
                                />
                                <Text size="sm" fw="600">
                                  {item.userName}
                                </Text>
                              </div>
                            ) : null}
                            <Text size="sm" color="gray.5" fw="400">
                              {formatDate(item.finishedAt)}
                            </Text>
                          </div>

                          {!item.read && (
                            <div className="flex-shrink-0">
                              <Badge color="one.4" fw="400" size="md">
                                <span className="capitalize">New</span>
                              </Badge>
                            </div>
                          )}
                        </div>
                      </div>
                      <Text size="lg" fw="600" mb="xs" color="black">
                        {item.title}
                      </Text>
                      <Text size="md">
                        <span
                          className="line-clamp-2 overflow-hidden"
                          dangerouslySetInnerHTML={{ __html: item.content }}
                        />
                      </Text>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
