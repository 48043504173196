import { get } from 'lodash';
import { useAppSelector } from 'reducers/RootType';

export const useFilteredItems = <T extends Record<string, any>>(
  items: T[],
  search: string,
  fields: (keyof T)[] | string[],
  userFilter?: string,
) => {
  const user = useAppSelector((state) => state.user);

  if (!fields.length) {
    return items;
  }

  if (!items.length) {
    return items;
  }

  if (userFilter) {
    items = items.filter((item) => {
      if (!userFilter) {
        return true;
      }

      if (userFilter === 'all') {
        return true;
      } else if (userFilter === 'store') {
        return item.user !== user.uid;
      } else if (userFilter === 'user') {
        return item.user === user.uid;
      } else if (userFilter === 'active') {
        return item.schedule?.status;
      } else if (userFilter === 'inactive') {
        return !item.schedule?.status;
      }
    });
  }

  const searchLower = search.toLowerCase();

  if (!searchLower?.trim()) {
    return items;
  }

  return items.filter((item) => {
    return fields.some((field) => {
      const value = get(item, field);
      return (typeof value === 'object' ? JSON.stringify(value) : value?.toString())
        ?.toLowerCase()
        .includes(searchLower);
    });
  });
};
