import { Button, Tooltip } from '@tw/ui-components';
import { toggleCustomMetricModal } from 'ducks/customMetrics';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { useFeatureFlag } from 'feature-flag-system';
import { ConditionalWrapper } from 'components/library/TwConditionalWrapper';
import { useAppDispatch } from 'index';

export const CreateCustomMetricModalToggle = () => {
  const dispatch = useAppDispatch();
  const { shouldNotBeSeen } = useFeatureFlag(FeatureFlag.CUSTOM_METRICS_FF);

  return (
    <div className="ml-auto self-end">
      <ConditionalWrapper
        condition={shouldNotBeSeen}
        wrapper={(x) => <Tooltip label="Upgrade to access this feature">{x}</Tooltip>}
      >
        <Button
          mr="xs"
          disabled={shouldNotBeSeen}
          onClick={() =>
            !shouldNotBeSeen && dispatch(toggleCustomMetricModal({ isModalOpen: true }))
          }
        >
          Create Custom Metric
        </Button>
      </ConditionalWrapper>
    </div>
  );
};
