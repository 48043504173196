/**
 * @fileoverview Just keep in mind here that "group" and "category" are the same.
 * Category is internal, and group is for the user to see.
 */

import { ActionIcon, Icon, Text, TextInput, confirm, Popover } from '@tw/ui-components';
import { toggleMobileSidePanel } from 'ducks/willy';
import { useAppDispatch } from 'index';
import { $createModal } from '$stores/willy/$createModal';
import { Fragment, useMemo } from 'react';
import { LimitedAccessTooltip } from 'components/Nav/components/navs/AlanNav/LimitedAccessTooltip';
import { $canCreateAndMoveFolders, $createDashboardAccess } from '$stores/willy/$shopDashboards';
import { initCategoryCreatorModal } from './api';

export default function WillyDashNewActions() {
  const dispatch = useAppDispatch();

  const popoverLinks = useMemo(() => {
    const classes = `flex cursor-pointer justify-start gap-4 items-center w-full px-6 py-4 hover:bg-[--mantine-color-gray-2]`;

    return [
      {
        children: (
          <div
            className={classes}
            onClick={() => {
              dispatch(toggleMobileSidePanel(false));
              $createModal.set({ opened: true });
            }}
          >
            <Icon name="board" color={'gray.5'} size={14} />
            <Text fz="sm">Report</Text>
          </div>
        ),
      },
      {
        children: (
          <div className={classes} onClick={initCategoryCreatorModal}>
            <Icon name="group" color="gray.5" size={14} />
            <Text fz="sm">Folder</Text>
          </div>
        ),
      },
    ];
  }, [dispatch]);

  return (
    <Popover position="bottom" withinPortal={true} shadow="xl">
      <Popover.Target>
        <div>
          <LimitedAccessTooltip
            $store={[$createDashboardAccess, $canCreateAndMoveFolders]}
            message={"You don't have access to this feature on this plan."}
            target={(access) => {
              return (
                <ActionIcon
                  icon="union"
                  variant="activatorWithHover"
                  disabled={access !== 'can-access'}
                />
              );
            }}
          />
        </div>
      </Popover.Target>
      <Popover.Dropdown bg="white" p={0} w={150} border="none" overflow="hidden">
        <div>
          {popoverLinks.map(({ children }, i) => {
            return <Fragment key={`test-${i}`}>{children}</Fragment>;
          })}
        </div>
      </Popover.Dropdown>
    </Popover>
  );
}
