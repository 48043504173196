import { openCustomizePromptsPopup } from '$stores/willy/$customizePromptPopup';
import { useStoreValue } from '@tw/snipestate';
import { Container, Group, Icon, IconName, Menu, Text } from '@tw/ui-components';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';
import { Fragment, useState } from 'react';
import { analyticsEvents, chatActions, genericEventLogger } from 'utils/dataLayer';
import { PromptTopic } from './WillyMainChat';

type PromptCategorySelectProps = {
  activePromptCategory: string;
  setActivePromptCategory: (value: string) => void;
  mainPage?: boolean;
  promptTopics: PromptTopic[];
};

export const PromptCategorySelect: React.FC<PromptCategorySelectProps> = ({
  activePromptCategory,
  setActivePromptCategory,
  mainPage,
  promptTopics,
}) => {
  const isSmall = useIsSmall();
  const [popoverOpen, setPopoverOpen] = useState(false);

  const activeTopic = promptTopics.find((topic) => topic.cats.includes(activePromptCategory));

  const openPopover = () => {
    setPopoverOpen((o) => !o);
    if (mainPage) {
      genericEventLogger(analyticsEvents.CHAT, {
        action: chatActions.OPEN_PROMPT_CATEGORY_SELECT,
      });
    } else {
      genericEventLogger(analyticsEvents.PROMPT_LIBRARY, {
        action: chatActions.OPEN_PROMPT_CATEGORY_SELECT,
      });
    }
  };

  const _setActivePromptCategory = (value: string) => {
    setActivePromptCategory(value);
    if (mainPage) {
      genericEventLogger(analyticsEvents.CHAT, {
        action: chatActions.SELECT_PROMPT_CATEGORY,
        category: value,
      });
    } else {
      genericEventLogger(analyticsEvents.PROMPT_LIBRARY, {
        action: chatActions.SELECT_PROMPT_CATEGORY,
        category: value,
      });
    }
  };

  return (
    <Menu
      offset={mainPage && !isSmall ? 0 : 0}
      width="target"
      position="bottom"
      opened={popoverOpen}
      onChange={setPopoverOpen}
    >
      <Menu.Target>
        {mainPage ? (
          <div
            className={`rounded-xl bg-gray-50 border border-solid border-gray-200 p-2 md:p-4 w-[330px] flex justify-center gap-6 items-center text-[16px] cursor-pointer ${popoverOpen ? 'rounded-b-none' : ''}`}
            onClick={() => openPopover()}
          >
            <div className="w-[2px] h-[16px] flex overflow-hidden">
              <Container bg={activeTopic?.color || 'one.5'} fluid></Container>
            </div>
            <Text fz="inherit" fw={500} lineClamp={1}>
              {activePromptCategory}
            </Text>
            <span className={`flex items-center ${popoverOpen ? 'rotate-180' : ''}`}>
              <Icon name="caret-down" size={13} color="gray.4" />
            </span>
          </div>
        ) : (
          <div
            className={`cursor-pointer rounded-lg border border-solid border-gray-200 flex gap-4 py-3 md:py-0 px-6 items-center text-[14px] justify-between w-full ${popoverOpen ? 'rounded-b-none' : ''}`}
            onClick={() => setPopoverOpen((o) => !o)}
          >
            <span className="flex gap-3 items-center">
              <div className="w-[2px] h-[16px] min-w-[2px] flex overflow-hidden">
                <Container bg={activeTopic?.color || 'one.5'} fluid></Container>
              </div>
              <Text fz="inherit" fw={500} lineClamp={1}>
                {activePromptCategory}
              </Text>
            </span>
            <span className={`flex items-center ${popoverOpen ? 'rotate-180' : ''}`}>
              <Icon name="caret-down" size={13} color="gray.4" />
            </span>
          </div>
        )}
      </Menu.Target>
      <Menu.Dropdown
        mah={mainPage ? 300 : 500}
        style={{
          overflowY: 'auto',
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          borderTop: 'none',
        }}
      >
        <Menu.Item
          leftSection={
            <div className="w-[2px] min-w-[2px] h-[16px] flex overflow-hidden">
              <Container bg="one.5" fluid></Container>
            </div>
          }
          px={mainPage ? 'lg' : 'xs'}
          py="xs"
          pb={0}
          onClick={() => {
            _setActivePromptCategory('All Prompts');
          }}
        >
          <Text fw={500} fz={mainPage ? 16 : 14}>
            All Prompts
          </Text>
        </Menu.Item>

        {promptTopics.map((topic) => {
          return (
            <Fragment key={topic.topic}>
              <Menu.Label px={mainPage ? 'md' : 'xs'} pt={mainPage ? 'sm' : 'xs'}>
                <Text fz={mainPage ? 16 : 14} fw={500}>
                  {topic.topic}
                </Text>
              </Menu.Label>
              {topic.cats?.map((cat) => {
                return (
                  <Menu.Item
                    key={cat}
                    onClick={() => {
                      _setActivePromptCategory(cat);
                    }}
                    leftSection={
                      <div className="w-[2px] min-w-[2px] h-[16px] flex overflow-hidden">
                        <Container bg={topic.color} fluid></Container>
                      </div>
                    }
                    px={mainPage ? 'lg' : 'lg'}
                    py="xs"
                  >
                    <span className="flex justify-between group items-center">
                      <Text fw={500} fz={mainPage ? 16 : 14}>
                        {cat}
                      </Text>
                      {cat === 'For You' && (
                        <div
                          className="md:opacity-0 group-hover:opacity-100 transition-opacity flex items-center cursor-pointer"
                          onClick={() => openCustomizePromptsPopup()}
                        >
                          <Icon name="filled-pencil" size={18} color="gray.4" />
                        </div>
                      )}
                    </span>
                  </Menu.Item>
                );
              })}
            </Fragment>
          );
        })}
      </Menu.Dropdown>
    </Menu>
  );
};
