import { Icon, Kbd, Text, Textarea } from '@tw/ui-components';
import { stepActionType } from '../types/willyTypes';
import { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';

type FlowStepWithTextProps = {
  text: string;
  readOnly: boolean;
  toolConfig?: stepActionType;
  textChange: (text: string) => void;
  setIsPristine: (isPristine: boolean) => void;
};

export const FlowStepWithText = ({
  text,
  readOnly,
  toolConfig,
  textChange,
  setIsPristine,
}: FlowStepWithTextProps) => {
  const placeholderRef = useRef<HTMLDivElement>(null);
  const [placeholderHeight, setPlaceholderHeight] = useState<number>(0);
  const [placeholderIndex, setPlaceholderIndex] = useState<number>(0);

  const placeholder = useMemo(() => {
    if (!toolConfig?.examplePrompts) {
      return '';
    }
    const index =
      ((placeholderIndex % toolConfig?.examplePrompts.length) + toolConfig?.examplePrompts.length) %
      toolConfig?.examplePrompts.length;
    return toolConfig?.examplePrompts[index];
  }, [placeholderIndex, toolConfig]);

  const saveDraft = useCallback(
    (promptText: string) => {
      textChange(promptText);
    },
    [textChange],
  );

  const keyHit = useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key === 'Tab') {
        e.preventDefault(); // Prevent default tab behavior
        textChange(placeholder);
        setIsPristine(false);
      }
    },
    [placeholder, textChange, setIsPristine],
  );

  useLayoutEffect(() => {
    if (placeholderRef.current) {
      setPlaceholderHeight(placeholderRef.current.offsetHeight);
    }
  }, [placeholderRef.current?.offsetHeight]);

  return (
    <>
      {readOnly ? (
        <Text fw={500} fz={14} py="5.5px" px="12px">
          {text}
        </Text>
      ) : (
        <>
          <div className="w-full z-30 relative" style={{ minHeight: `${placeholderHeight}px` }}>
            <Textarea
              fw={500}
              value={text}
              onChange={(e) => saveDraft(e.target.value)}
              onKeyDown={(e) => {
                setIsPristine(false);
                keyHit(e);
              }}
              autosize
              minRows={1}
              maxRows={7}
              noBorder
              noBackground
            />
          </div>
          {!text && (
            <div className="flex flex-col gap-5 absolute top-[5px]" ref={placeholderRef}>
              <span className="px-[13px] ">
                <span className="text-[14px] font-semibold text-gray-400 mr-2">{placeholder}</span>
                <Kbd>Tab</Kbd>
              </span>
              <div
                className="ml-5 cursor-pointer z-30"
                onClick={() => {
                  setPlaceholderIndex(placeholderIndex + 1);
                }}
              >
                <Icon name="refresh" />
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};
