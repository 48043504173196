import { Button, Popover, Text } from '@tw/ui-components';
import { DashboardsDropDown } from '../DashboardsDropDown';
import { WorkflowStepBase, WorkflowStepPreloadData } from '../types/willyTypes';
import { useState } from 'react';
import {
  DatePickerTimePeriods,
  getDatePickerOptionsDictionary,
} from 'components/useDatePickerSelectedOptions';
import { DateOptionsList } from 'pages/FreeQuery/DateOptionsList';
import { compareOptions } from 'components/useDatePickerCompareOptions';
import { PreviousPeriodIds } from '@tw/types/module/datePicker/datePicker';
import { useStoreValue } from '@tw/snipestate';
import { $isAdminClaim } from '$stores/$user';

type FlowLoadDataStepProps = {
  step: WorkflowStepPreloadData & WorkflowStepBase;
  handlePromptChange: (newPrompt: WorkflowStepPreloadData & WorkflowStepBase) => void;
  readOnly: boolean;
  setIsPristine: (isPristine: boolean) => void;
};

export const FlowLoadDataStep: React.FC<FlowLoadDataStepProps> = ({
  step,
  handlePromptChange,
  readOnly,
  setIsPristine,
}) => {
  const isAdmin = useStoreValue($isAdminClaim);
  const [dateOptionsListOpened, setDateOptionsListOpened] = useState(false);
  const [previousDateOptionsListOpened, setPreviousDateOptionsListOpened] = useState(false);

  return (
    <div className="flex flex-col gap-4">
      <DashboardsDropDown
        disabled={readOnly}
        selected={step.dashboardId}
        onSelect={(dashboard) => {
          handlePromptChange({
            ...step,
            dashboardId: dashboard,
          });
          setIsPristine(false);
        }}
        hideGlobals={!isAdmin}
        showStandardDashboards
        withNone
      />

      <Text fw={500} size="sm">
        The data will be preloaded for the following date range:
      </Text>
      <Popover
        opened={dateOptionsListOpened}
        onClose={() => setDateOptionsListOpened(false)}
        shadow="sm"
      >
        <Popover.Target>
          <span>
            <Button
              disabled={!step.dashboardId || readOnly}
              variant="activator"
              onClick={() => setDateOptionsListOpened((x) => !x)}
              leftSection="calendar-1"
              rightSection="caret-down"
            >
              {step.date ? getDatePickerOptionsDictionary()[step.date]?.label : `Select Date`}
            </Button>
          </span>
        </Popover.Target>
        <Popover.Dropdown p={0}>
          <DateOptionsList
            selectedOption={step.date || null}
            onOptionSelect={(option) => {
              handlePromptChange({
                ...step,
                date: option as DatePickerTimePeriods,
              });
              setDateOptionsListOpened(false);
              setIsPristine(false);
            }}
          />
        </Popover.Dropdown>
      </Popover>
      <Text fw={500} size="sm">
        And compare it to the following date range: (optional)
      </Text>
      <Popover
        opened={previousDateOptionsListOpened}
        onClose={() => setPreviousDateOptionsListOpened(false)}
        shadow="sm"
      >
        <Popover.Target>
          <span>
            <Button
              disabled={!step.dashboardId || readOnly}
              variant="activator"
              onClick={() => setPreviousDateOptionsListOpened((x) => !x)}
              leftSection="calendar-1"
              rightSection="caret-down"
            >
              {step.previousDate ? compareOptions[step.previousDate]?.label : `None`}
            </Button>
          </span>
        </Popover.Target>
        <Popover.Dropdown p={0}>
          <DateOptionsList
            previous
            selectedOption={step.previousDate || null}
            onOptionSelect={(option) => {
              handlePromptChange({
                ...step,
                previousDate: option as PreviousPeriodIds,
              });
              setPreviousDateOptionsListOpened(false);
              setIsPristine(false);
            }}
          />
        </Popover.Dropdown>
      </Popover>
    </div>
  );
};
