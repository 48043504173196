import { $user } from '$stores/$user';
import { $derived, $effect, $mutableDerived } from '@tw/snipestate';
import { User } from 'components/UserProfileManagment/User/constants';
import { $moreSection } from 'constants/routes/configs/more';
import { Debouncer } from 'hooks/useDebouncer';
import { userDb } from 'utils/DB';

export type NavSectionCustomization = NonNullable<User['navSectionCustomization']>;

class NavSectionCustomizer {
  // Used to only save the new config to the user doc once user is finished customizing
  private debouncer = new Debouncer(2000);

  private readonly defaultShownRoutes = new Set([
    '/chat',
    '/dashboards',
    '/templates',
    '/get-started',
  ]);

  public constructor() {
    // one time effect used to save the DEFAULT_NAV_SECTIONS
    // to the user if the user doesn't have it
    $effect(async (unsub, get) => {
      const user = get($user);
      if (!user.uid) return;
      unsub();

      const navSectionCustomization = user.navSectionCustomization;
      if (navSectionCustomization) return;

      const defaultNavSections = get(this.$defaultNavSections);
      await userDb(user.uid).set({ navSectionCustomization: defaultNavSections }, { merge: true });
    });
  }

  /**
   * Sometimes we want to add or new sections to the nav - this function
   * makes sure they get added to user's info, so they can customize it
   */
  public getSyncedNavSections(navSectionCustomization: Partial<NavSectionCustomization>) {
    const newCustomization: NavSectionCustomization = {};
    const defaultNavSections = this.$defaultNavSections.get();

    for (const section in defaultNavSections) {
      const open = navSectionCustomization[section]?.open ?? defaultNavSections[section].open;
      newCustomization[section] = { open };
    }

    return newCustomization;
  }

  public readonly $defaultNavSections = $derived((get) => {
    return get($moreSection).reduce((acc, s) => {
      for (const { url, urlToCheck, hidden } of s.routes) {
        if (hidden) continue;
        const open =
          this.defaultShownRoutes.has(url) ||
          (typeof urlToCheck === 'string' && this.defaultShownRoutes.has(urlToCheck)) ||
          (Array.isArray(urlToCheck) && urlToCheck.some((url) => this.defaultShownRoutes.has(url)));
        acc[url] = { open };
      }
      return acc;
    }, {} as NavSectionCustomization);
  });

  public customizeNavSections(navSectionCustomization: NavSectionCustomization) {
    $navSectionCustomization.set(navSectionCustomization); // change locally immediately
    this.debouncer.handle(() => userDb().set({ navSectionCustomization }, { merge: true })); // save only when done
  }
}

const navSectionCustomizer = new NavSectionCustomizer();

export function getNavSectionCustomizer() {
  return navSectionCustomizer;
}

export const $navSectionCustomization = $mutableDerived((get) => {
  const userCustomization = get($user).navSectionCustomization || {};
  return navSectionCustomizer.getSyncedNavSections(userCustomization);
});
