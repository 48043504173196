import { $observer, $store } from '@tw/snipestate';
import { toArray } from 'utils/DB';
import 'firebase/firestore';
import firebase from 'firebase/compat/app';
import { IconName } from '@tw/ui-components';
import { $userId } from '$stores/$user';

export const $loadingGlobalRoles = $store(true);

export type Role = {
  id: string;
  name: string;
  icon: IconName;
  category: RoleCategory;
  order: number;
};
export const RoleCategoryArr = ['ROLES', 'COMPANY', 'NONE'] as const;
export type RoleCategory = (typeof RoleCategoryArr)[number];

export const $globalDashboardRoles = $observer<Role[]>([], (get, set) => {
  if (!get($userId)) return;

  return firebase
    .firestore()
    .collection('willy_global_dashboard_roles')
    .onSnapshot((querySnapshot) => {
      let arr = toArray(querySnapshot).sort((x, y) => x.order - y.order);
      set(arr);
      $loadingGlobalRoles.set(false);
    });
});
