import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  CirclePlusOutlineMinor,
  CircleMinusOutlineMinor,
  CircleAlertMajor,
  AlertMinor,
} from '@shopify/polaris-icons';
import { Icon, Link, Tooltip } from '@shopify/polaris';
import { utmServices } from '../constants';
import SendEmailModal from './SendEmailModal';
import { useSelector } from 'react-redux';
import { type RootState } from 'reducers/RootType';
import 'firebase/firestore';
import firebase from 'firebase/compat/app';
import { WizardContext } from '../WizardContext';
import { useNavigate } from 'react-router-dom';
import copyToClipboard from 'utils/copyToClipboard';
import { toast } from 'react-toastify';
import { useAppDispatch } from 'index';
import { setOnboardingStatus } from 'ducks/shop';
import { StepCompletionInfo } from 'types/WizardData';
import { UseSetupProgressArgs } from 'routes/allShopsPinnedSections/hooks/useSetupProgress';
import { cx } from 'utils/cx';
import { useStoreValue } from '@tw/snipestate';
import { $isFreeShop } from '../../../$stores/willy/$subscription';

export function WelcomeUtms() {
  type utmCollapsState = {
    [K in (typeof utmServices)[number]]: boolean;
  };

  type utmCollapsBody = {
    [K in (typeof utmServices)[number]]: JSX.Element;
  };

  type utmCollapsTitle = {
    [K in (typeof utmServices)[number]]: string;
  };

  const isGoogleAdsConnected = useSelector((state: RootState) => state.isGoogleAdsConnected);
  const navigate = useNavigate();
  const [isInviteUserModalOpen, setIsInviteUserModalOpen] = useState(false);
  const isFreeShop = useStoreValue($isFreeShop);
  const msp = useSelector((state: RootState) => state.msp);

  const { nextStep } = useContext(WizardContext);

  const [collapsState, setCollapsState] = useState<utmCollapsState>({
    facebookAds: true,
    googleAds: false,
    other: false,
  });

  const [collapsBody, setCollapsBody] = useState<utmCollapsBody>({
    facebookAds: (
      <div className="body">
        <p className="subtitle text-2xl py-4">Adding UTMs to your Facebook ads:</p>
        <div className="copy-section flex items-center gap-4 pt-2">
          <code
            onClick={() => {
              copyToClipboard(`tw_source={{site_source_name}}&tw_adid={{ad.id}}`);
              toast.success('Copied to clipboard');
            }}
            className="bg-white h-full py-3 px-5 rounded hover:bg-slate-200 cursor-copy"
          >
            {`tw_source={{site_source_name}}&tw_adid={{ad.id}}`}
          </code>
          <a
            href="https://business.facebook.com/latest/ad_center/ads_summary"
            target="_blank"
            className="bg-primary text-white py-3 px-5 rounded font-bold cursor-pointer no-underline"
          >
            Go to Facebook Admin
          </a>
        </div>
        <p className="py-[10px] text-[12px]">
          *On Facebook, changes to URL parameters on existing ads has the potential to reset social
          proof. Be sure to review our guide on updating ads without losing social proof.
        </p>
        <p className="text-slate-600">
          For more information, view our full guide on{' '}
          <Link
            url="https://kb.triplewhale.com/en/articles/6137471-updating-facebook-utms-without-losing-social-proof?location=conversation"
            external
          >
            {' '}
            How to add tracking parameters without losing social proof
          </Link>
        </p>
      </div>
    ),
    googleAds: (
      <div className="body">
        <p className="subtitle text-2xl py-4">
          You can automatically update your Google ads with the required UTMs using the button
          below:
        </p>
        <button
          className="bg-primary text-white py-3 px-5 rounded font-bold cursor-pointer w-max border-0 disabled:bg-gray-400"
          disabled={!isGoogleAdsConnected}
          onClick={() => {
            navigate(
              {
                pathname: '/utm-builder',
              },
              {
                state: { utmService: 'googleAds', openModal: true },
              },
            );
          }}
        >
          Automatically Update Google UTMs
        </button>
        {!isGoogleAdsConnected && (
          <p className="text-lg">You must first connect your Ads account to use this feature.</p>
        )}
        <p className="py-4">
          Alternatively, if you want to manually update your UTMs, please refer to our recommended
          UTMs knowledgebase article.
        </p>

        <div className="copy-section flex items-center gap-4 pt-5">
          <code
            onClick={() => {
              copyToClipboard(
                `{lpurl}?tw_source=google&tw_adid={creative}&tw_campaign={campaignid}`,
              );
              toast.success('Copied to clipboard');
            }}
            className="bg-white h-full py-3 px-5 rounded max-w-[76%] truncate hover:bg-slate-200 cursor-copy"
          >
            {`{lpurl}?tw_source=google&tw_adid={creative}&tw_campaign={campaignid}`}
          </code>
          <a
            href="https://ads.google.com/aw/overview"
            target="_blank"
            className="bg-primary text-white py-3 px-5 rounded font-bold cursor-pointer no-underline h-full"
          >
            Go to Google Ads
          </a>
        </div>
        <p className="py-5">
          For more information, view our full guide on{' '}
          <Link
            url="https://kb.triplewhale.com/en/articles/5827657-recommended-utms-for-triple-pixel#h_a3f86cd3a6"
            external
          >
            {' '}
            Google UTM Installation
          </Link>
        </p>
      </div>
    ),
    other: (
      <div className="body">
        <p className="subtitle pt-4">
          See the{' '}
          <Link
            url="https://kb.triplewhale.com/en/articles/5827657-recommended-utms-for-triple-pixel"
            external
          >
            {' '}
            Triple Whale UTM Installation Guide
          </Link>{' '}
          to find instructions for you specific ad platforms and marketing tools.
        </p>
        <ul className="list-none flex flex-col gap-4 pl-0">
          <li>
            <Link
              url="https://kb.triplewhale.com/en/articles/5827657-recommended-utms-for-triple-pixel#h_b976442ab0:~:text=Google%20Ads%20Manager.-,TikTok%20Ads%3A,-USE%20THESE%20TRACKING"
              external
            >
              TikTok Ads
            </Link>
          </li>
          <li>
            <Link
              url="https://kb.triplewhale.com/en/articles/5827657-recommended-utms-for-triple-pixel#h_b976442ab0:~:text=__CID_NAME__%26ttadid%3D__CID__-,Snapchat%20Ads%3A,-USE%20THESE%20TRACKING"
              external
            >
              Snapchat Ads
            </Link>
          </li>
          <li>
            <Link
              url="https://kb.triplewhale.com/en/articles/5827657-recommended-utms-for-triple-pixel#h_b976442ab0:~:text=scadid%3D%7B%7Bad.id%7D%7D-,Pinterest%20Ads%3A,-USE%20THESE%20TRACKING"
              external
            >
              Pinterest Ads
            </Link>
          </li>
          <li>
            <Link
              url="https://kb.triplewhale.com/en/articles/5827657-recommended-utms-for-triple-pixel#h_5ba674f8d1"
              external
            >
              Klaviyo Campaigns
            </Link>
          </li>
          <li>
            <Link
              url="https://kb.triplewhale.com/en/articles/5827657-recommended-utms-for-triple-pixel#h_b976442ab0:~:text=Flow%20settings%3A-,Other%20Email/SMS,-%3A"
              external
            >
              Non-Klaviyo Email and SMS Campaigns
            </Link>
          </li>
          <li>
            <Link
              url="https://kb.triplewhale.com/en/articles/5827657-recommended-utms-for-triple-pixel#h_b976442ab0:~:text=Tracking%20Non%2DIntegrated%20Sources%20in%20Triple%20Whale"
              external
            >
              Tracking a source that is not listed above
            </Link>
          </li>
        </ul>
      </div>
    ),
  });

  const [collapsDisplayTitle, setCollapsDisplayTitle] = useState<utmCollapsTitle>({
    facebookAds: 'Facebook Ads',
    googleAds: 'Google Ads',
    other: 'Other Ad Platforms and Marketing Tools',
  });

  const toggleCollaps = (key: string) => {
    setCollapsState({
      ...collapsState,
      [key]: !collapsState[key],
    });
  };

  return (
    <div className="main-utm page pt-36">
      <div className="copy flex flex-col justify-center items-center">
        <h1 className="step-title">Add Triple Whale UTMs</h1>
        <p className="step-description">
          {!isFreeShop
            ? `Adding the right tracking parameters to your campaign URLs is critical. It helps the
          Triple Pixel connect click-based actions across platforms to give you better insight into
          your customer journey.`
            : `You are currently on a Free plan that does not have access to Pixel attribution, 
          so adding UTMs is optional. You should consider adding UTMs now if you plan to upgrade in the future.
           Doing so will give you access to historical attribution data starting from the date of initial UTM setup.`}
        </p>
      </div>
      <div className="utm-warning flex items-center justify-center gap-2 m-10">
        <Icon source={AlertMinor} color="warning" />
        <p className="text-center text-2xl font-medium">
          {' '}
          Without proper UTM parameters, we cannot guarantee comprehensive attribution results.
        </p>
      </div>
      <div className="email m-10 flex flex-col gap-10 items-center justify-center">
        <p
          className="text-center cursor-pointer font-bold text-white bg-blue-500 hover:bg-[#156BD9] rounded py-4 px-10 w-max"
          onClick={() => setIsInviteUserModalOpen(true)}
        >
          Email UTM Instructions to Ad Manager
        </p>
        {isInviteUserModalOpen && (
          <SendEmailModal
            wizardStep="utms"
            msp={msp}
            onClose={() => {
              setIsInviteUserModalOpen(false);
            }}
          />
        )}
      </div>

      <div className="collaps-wraper pt-10 max-w-[700px] m-0">
        {Object.keys(collapsState).map((key, index) => (
          <div
            key={key + index}
            className={`collaps flex flex-col p-8 ${
              collapsState[key] && 'bg-slate-100'
            } hover:bg-slate-100 mt-4`}
          >
            <div className="collaps-header flex items-center justify-between w-full">
              <h2
                className="collaps-title font-bold cursor-pointer text-3xl"
                onClick={() => toggleCollaps(key)}
              >
                {collapsDisplayTitle[key]}
              </h2>
              <div className="close-collaps-btn cursor-pointer" onClick={() => toggleCollaps(key)}>
                <Icon
                  source={collapsState[key] ? CircleMinusOutlineMinor : CirclePlusOutlineMinor}
                  color="base"
                />
              </div>
            </div>
            {collapsState[key] && collapsBody[key]}
          </div>
        ))}
      </div>
      <button className="step-button" onClick={nextStep}>
        Next
      </button>
    </div>
  );
}

export function useUtmConnectionInfo(
  props: UseSetupProgressArgs,
): StepCompletionInfo<{ innerCoverage?: number }> {
  const [innerCoverage, setInnerCoverage] = useState<number>();

  const status =
    !innerCoverage || innerCoverage < 50
      ? 'not-started'
      : innerCoverage < 90
        ? 'in-progress'
        : 'complete';

  const text = innerCoverage ? `Coverage of ${innerCoverage.toFixed(1)}%` : 'No UTMs Detected';

  useEffect(() => {
    const shopRef = firebase.firestore().collection('shops').doc(props.shopDomain);

    shopRef.onSnapshot((doc) => {
      const data = doc.data();
      if (!data?.utm_counter) return;

      const totalUtm = data.utm_counter?.fb?.total + data?.utm_counter?.ga?.total;
      const properUtm = data.utm_counter?.fb?.properlySet + data?.utm_counter?.ga?.properlySet;

      if (totalUtm) setInnerCoverage((100 * properUtm) / totalUtm);
    });
  }, [props.shopDomain]);

  return { status, text, innerCoverage };
}

export const UtmIndication: React.FC<any> = (props) => {
  const currentShopId = useSelector((state: RootState) => state.currentShopId);
  const dispatch = useAppDispatch();
  const shopDomain = props.isFromPod ? props.shopDomain : currentShopId;

  const { status, text, innerCoverage } = useUtmConnectionInfo({ ...props, shopDomain });

  useEffect(() => {
    if (!innerCoverage) return;
    dispatch(setOnboardingStatus(shopDomain, 'utm', innerCoverage > 80));
  }, [dispatch, shopDomain, innerCoverage]);

  return (
    <div
      className={cx({
        'text-green': status === 'complete',
        'text-yellow-400': status === 'in-progress',
        'text-red-400': status === 'not-started',
      })}
    >
      <Tooltip content="Across connected Facebook and Google Ads accounts">
        <span>{text}</span>
      </Tooltip>
    </div>
  );
};
