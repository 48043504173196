import { $currentShopId } from '$stores/$shop';
import moment from 'moment';
import { AttributionData } from 'types/attribution';
import axiosInstance from 'utils/axiosInstance';

export const formatConversionValue = (cv: number, currency: string, conversionRate = 1) =>
  isNaN(cv)
    ? '-'
    : currency +
      (Number(cv) * conversionRate).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });

export const formatPercentage = (value: number) =>
  !Number(value) || !isFinite(Number(value)) ? '0%' : (value * 100).toFixed(2) + '%';

export const getJourney = async (order, useIdGraph: boolean) => {
  try {
    try {
      let shopDomain = $currentShopId.get();
      if (shopDomain === 'triplewhalestripe') {
        shopDomain = 'triplewhale.myshopify.com';
      }
      const response = await axiosInstance.post('/v2/pixel/pixelEvents/get', {
        shopDomain,
        coos: [order.tripleId || order.tripleCoo],
        thids: [order.tripleId],
        customerId: order.customerId || order.customer?.id,
        useIdGraph,
        pixelEventsTypes: [],
        includeCustomEvents: true,
      });
      return response?.data;
    } catch ({ message = '' }) {
      console.log('API ERROR', message);
    }
    return;
  } catch (e) {}
};

// copied from backend
export function getSource(event) {
  const source =
    event?.query?.triplesource ||
    event?.query?.tw_source ||
    event?.query?.utm_source ||
    getSourceFromUrl(event.referral);
  return source;
}

const POPULAR_REFERRERS = [
  'google',
  'facebook',
  'tiktok',
  'pinterest',
  'snapchat',
  'bing',
  'yahoo',
  'reddit',
  'twitter',
  'instagram',
  'youtube',
  'shop.app',
];
// copied from backend
function getSourceFromUrl(url) {
  if (!url) return;
  let referrer = '';
  try {
    if (url && typeof url === 'string' && !url.startsWith('http')) {
      url = 'http://' + url;
    }
    const hostname = new URL(url).hostname;
    // const host = urlObj.hostname.replace('www\.', '').replace(/^l\./, '').replace(/^m\./, '');
    // referrer = /(.*?)\./.exec(host)[1]
    const standardizedHostname = POPULAR_REFERRERS.find((x) => hostname.includes(x));
    referrer = standardizedHostname || hostname;
  } catch {
    console.log('failed to get source', url);
  }
  return referrer;
}

// copied partially from backend
export const checkIfReferral = (url, ref) => {
  const query = url?.query;
  const referrer = getSourceFromUrl(ref?.path);
  const path = getSourceFromUrl(url?.path);
  const hasUtms = !!query?.utm_source || !!query?.tw_source || !!query?.triplesource;
  const isWithinSite = referrer === path;
  const isReferral = !isWithinSite && !hasUtms && !!referrer;
  return isReferral;
};

export const checkIfSiteSearch = (query) => {
  return query?.q;
};

export const checkIfPageNumber = (query) => {
  const page = query?.page || query?.p;
  // check if page is a number
  return page && !isNaN(page) ? page : null;
};

export const isUsingPixel2 = (pixelVersion: string | null) => {
  const splitted = (pixelVersion || '').split('.');
  return Number(splitted[0]) > 1;
};

export function sliceByCampaign(
  arr: AttributionData[],
  size: number,
  skip?: number,
): AttributionData[] {
  let numberOfCampaigns = 0;
  const result: any[] = [];
  let firstCampaignCame = false;

  for (const element of arr) {
    firstCampaignCame = element.entity === 'campaign' && firstCampaignCame === false; // can flip only once
    if (!firstCampaignCame && element.entity !== 'campaign') {
      continue;
    }

    if (skip && element.entity === 'campaign') {
      skip--;
      continue;
    }
    if (element.entity === 'campaign') {
      numberOfCampaigns++;
      if (numberOfCampaigns > size) {
        break;
      }
      result.push(element);
    } else {
      result.push(element);
    }
  }
  return result;
}
