import { $globalSequences, $shopSequences } from '$stores/willy/$sequences';
import { useStoreValue } from '@tw/snipestate';
import { useEffect, useMemo, useState } from 'react';
import { DialectWithBoth, WillyDataSequence } from '../types/willyTypes';
import { $isTwGlobalDashboardCreatorClaim } from '$stores/$user';
import { WillyEmoji } from '../types/emojiTypes';
import { Dialect } from '@tw/types';
import { SalesPlatform, ServicesIds } from '@tw/types/module/services';
import { set } from 'lodash';
import { emptyEmoji } from '../dashboardManagment/WillyDashDescription';
import { $activeAccounts } from '$stores/$shop';

export const useSequenceFlow = (sequenceId: string | null) => {
  const sequences = useStoreValue($shopSequences);
  const globalSequences = useStoreValue($globalSequences);
  const isTwGlobalDashboardCreatorClaim = useStoreValue($isTwGlobalDashboardCreatorClaim);

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [dialect, setDialect] = useState<DialectWithBoth>('clickhouse');
  const [sequence, setSequence] = useState<WillyDataSequence | undefined>(undefined);
  const [emoji, setEmoji] = useState<WillyEmoji>(emptyEmoji);
  const [isGlobal, setIsGlobal] = useState(false);
  const [providers, setProviders] = useState<ServicesIds[] | undefined>(undefined);
  const [providersBlockingCombination, setProvidersBlockingCombination] = useState<
    'AND' | 'OR' | undefined
  >(undefined);
  const [category, setCategory] = useState<string | undefined>(undefined);
  const [roles, setRoles] = useState<string[] | undefined>(undefined);
  const [isBeta, setIsBeta] = useState<boolean | undefined>(undefined);
  const [isHide, setIsHide] = useState<boolean | undefined>(undefined);
  const [msps, setMsps] = useState<SalesPlatform[] | undefined>(undefined);
  const [featureFlagConfigs, setFeatureFlagConfigs] = useState<any | undefined>(undefined);
  const [featureFlagDefaultCopyConfigs, setFeatureFlagDefaultCopyConfigs] = useState<
    any | undefined
  >(undefined);
  const [credits, setCredits] = useState<number | undefined>(undefined);

  const allSequences = useMemo(() => {
    return [...sequences, ...globalSequences];
  }, [sequences, globalSequences]);

  const currentActiveAccounts = useStoreValue($activeAccounts);

  const additionalShopIds = useMemo(() => {
    if (sequence?.additionalShopIds) {
      return sequence.additionalShopIds;
    }
    if (!!currentActiveAccounts) {
      return currentActiveAccounts || [];
    }
    return null;
  }, [currentActiveAccounts, sequence?.additionalShopIds]);

  useEffect(() => {
    if (sequenceId) {
      const sequence = allSequences.find((x) => x.id === sequenceId);
      if (sequence) {
        setSequence(sequence);
        setName(sequence.name);
        setDescription(sequence.description);
        if (!!sequence.dialect) {
          setDialect(sequence.dialect);
        }
        if (!!sequence.emoji) {
          setEmoji(sequence?.emoji);
        }
        setIsGlobal(sequence.isGlobal);
        setProviders(sequence.providers);
        setProvidersBlockingCombination(sequence.providersBlockingCombination);
        setCategory(sequence.category);
        setRoles(sequence.roles);
        setIsBeta(sequence.isBeta);
        setIsHide(sequence.isHide);
        setMsps(sequence.msps);
        setFeatureFlagConfigs(sequence.packages);
        setFeatureFlagDefaultCopyConfigs(sequence.defaultPackages);
        setCredits(sequence.credits);
      }
    }
  }, [sequenceId, allSequences, isTwGlobalDashboardCreatorClaim]);

  const ffConfigsToRemoveDashId = useMemo(() => {
    const packages = sequence?.packages ?? [];
    return packages.filter((p) => !featureFlagConfigs?.includes(p));
  }, [featureFlagConfigs, sequence?.packages]);

  const ffConfigsDefaultToRemoveDashId = useMemo(() => {
    const packages = sequence?.defaultPackages ?? [];
    return packages.filter((p) => !featureFlagDefaultCopyConfigs?.includes(p));
  }, [featureFlagDefaultCopyConfigs, sequence?.defaultPackages]);

  return {
    name,
    description,
    dialect,
    sequence,
    emoji,
    isGlobal,
    providers,
    providersBlockingCombination,
    category,
    roles,
    isBeta,
    isHide,
    msps,
    featureFlagConfigs,
    featureFlagDefaultCopyConfigs,
    credits,
    ffConfigsToRemoveDashId,
    ffConfigsDefaultToRemoveDashId,
    additionalShopIds,
  };
};
