import React, { Dispatch, SetStateAction, useState } from 'react';
import {
  rulesActionDropdown,
  conditionsLevels,
  ruleLevels,
  templatesRule,
  runTimeOptions,
  scheduleOptions,
  weekDays,
  EditRuleExpression,
} from './types';
import Select, { components } from 'react-select';
import * as _ from 'lodash';

import { Spinner, Stack, TextField, Tooltip } from '@shopify/polaris';
import { QuestionMarkMajor } from '@shopify/polaris-icons';
import { cloneDeep } from 'lodash';
import ApplyPopup from './ApplyChangeTemplatePopup';
import moment from '@tw/moment-cached/module/timezone';
import { useSelector } from 'react-redux';
import { mapRulesConditionsWith } from './helpers';

type Props = {
  rule: any;
  setRule: Dispatch<SetStateAction<any>>;
  setIsTemplate?: Dispatch<SetStateAction<boolean>>;
  isTemplate?: boolean;
  formatRule?: (rule: any) => any;
  setTemporaryRule?: Dispatch<SetStateAction<any>>;
  showRunTime: (ruleRunTime) => boolean;
  showWeekDay: (ruleWeekDay) => boolean;
  showActionWarning?: (rule) => void;
  setNextRun?: (ruleNextRun) => Promise<any>;
  existingStrategies: any[];
};

export const RuleHeader: React.FC<Props> = ({
  rule,
  setRule,
  setIsTemplate = () => {},
  formatRule = (rule: any) => rule,
  showRunTime = () => {
    return true;
  },
  showWeekDay = () => {
    return true;
  },
  showActionWarning = (rule) => {},
  setNextRun = () => {
    return '';
  },
  existingStrategies,
}) => {
  const WeekDayOption = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input type="checkbox" checked={props.isSelected} onChange={() => null} />{' '}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  const setStyles = (width, margin = 0) => {
    return {
      container: (provided) => ({
        ...provided,
        width: width,
        margin: margin,
      }),
    };
  };
  const templatesList = Object.values(templatesRule).map((temp) => ({
    label: temp.label,
    value: temp.value,
  }));
  const [showApplyPopup, setShowApplyPopup] = useState(false);
  const [temporaryRule, setTemporaryRule] = useState<any>();
  const [nextRunLoading, setNextRunLoading] = useState<boolean>(false);

  const validateRule = (copyRule, level) => {
    const validatedConditions = mapRulesConditionsWith((condition: EditRuleExpression) => {
      if (condition.fact) {
        const conditionLevel = Object.values(conditionsLevels).find(
          (option) => option.value === condition['fact'],
        );
        condition.showError = !conditionLevel?.show?.includes(level) ? 'Error: level' : false;
        if (condition['value']['fact']) {
          const conditionFormulaLevel = Object.values(conditionsLevels).find(
            (option) => option.value === condition['value']['fact'],
          );
          condition.showFormulaError = !conditionFormulaLevel?.show?.includes(level)
            ? 'Error: level'
            : false;
        }
      }
      return condition;
    })(copyRule.conditions);

    copyRule.conditions = validatedConditions;
    return copyRule;
  };

  const validateRuleName = (name: string): void => {
    if (!name) {
      setRule((rule) => ({
        ...rule,
        showNameError: 'Name cannot be empty',
      }));
      return;
    }
    const existingRules = existingStrategies
      .reduce((rules, strategy) => [...rules, ...(strategy.versions?.[0]?.rules || [])], [])
      .filter(Boolean);
    const alreadyExists = existingRules.some(
      (existingRule) => (existingRule?.name || '').toLowerCase() === name.toLowerCase(),
    );
    if (alreadyExists) {
      setRule((rule) => ({
        ...rule,
        showNameError: 'This name already exists',
      }));
      return;
    }
    setRule((rule) => ({
      ...rule,
      showNameError: false,
    }));
  };

  const handleChangeLevel = (level) => {
    let copyRule = cloneDeep(rule);
    copyRule = validateRule(copyRule, level.value);
    const action = Object.values(rulesActionDropdown).find(
      (option) => option.value === copyRule['event']['type'],
    );
    if (!action?.show?.includes(level.value)) {
      copyRule['event'] = { type: '', value: '' };
    }

    copyRule.level = level.value;
    setRule(copyRule);
  };

  const handleUseTemplate = (temp) => {
    const template = Object.values(templatesRule).find((option) => option.value === temp);
    let copyTemp = cloneDeep(template?.value);
    let formatedRule = formatRule(copyTemp);
    if (!rule.level) {
      setIsTemplate(true);
      setRule(formatedRule);
      validateRuleName(formatedRule?.name);
    } else {
      setTemporaryRule(formatedRule);
      setShowApplyPopup(true);
    }
  };

  const handlePopupSelect = (toChange) => {
    if (toChange) {
      setRule(temporaryRule);
      validateRuleName(temporaryRule?.name);
    }
    setShowApplyPopup(false);
  };

  const handleFrequencyChange = async (frequency) => {
    let ruleCopy = cloneDeep(rule);
    ruleCopy.frequency = frequency;
    if (!showRunTime(ruleCopy)) {
      ruleCopy.runAt = '';
    }
    if (!showWeekDay(ruleCopy)) {
      ruleCopy.daysOfWeek = [-1];
    }
    setRule(ruleCopy);
    setNextRunLoading(true);
    await setNextRun(ruleCopy);
    setNextRunLoading(false);
    showActionWarning(ruleCopy);
  };

  const handleRunTimeChange = async (time) => {
    let ruleCopy = cloneDeep(rule);
    ruleCopy.runAt = time;
    setRule(ruleCopy);
    setNextRunLoading(true);
    await setNextRun(ruleCopy);
    setNextRunLoading(false);
  };

  const handleWeekdayChange = async (days) => {
    let ruleCopy = cloneDeep(rule);

    if (!days || days.length === 0) {
      ruleCopy.daysOfWeek = [-1];
      setRule(ruleCopy);
      setNextRunLoading(true);
      await setNextRun(ruleCopy);
      setNextRunLoading(false);
      return;
    }

    let values = days.map((d) => +d.value);
    if (values.includes(-1) && values.length > 1) {
      ruleCopy.daysOfWeek = ruleCopy.daysOfWeek.includes(-1)
        ? values.filter((v) => v !== -1)
        : [-1];
      setRule(ruleCopy);
      setNextRunLoading(true);
      await setNextRun(ruleCopy);
      setNextRunLoading(false);
      return;
    }

    values = _.sortedUniq(_.orderBy(values));
    ruleCopy.daysOfWeek = values;
    setRule(ruleCopy);
    setNextRunLoading(true);
    await setNextRun(ruleCopy);
    setNextRunLoading(false);
  };

  const shopTimezone = useSelector((state: any) => state.shopTimezone);
  const shopTimezoneAbbr = moment()
    .tz(shopTimezone || 'America/Los_Angeles')
    .zoneAbbr();

  return (
    <>
      {showApplyPopup && (
        <ApplyPopup
          handlePopupSelect={(value) => handlePopupSelect(value)}
          showPopup={showApplyPopup}
        ></ApplyPopup>
      )}
      <Stack distribution="equalSpacing">
        <Stack.Item>
          <Stack alignment="center">
            <span className="font-semibold" style={{ marginBottom: '2px', marginRight: '4px' }}>
              Template
            </span>
            <Tooltip content="Choosing a template will auto-populate a set of rules for you to edit">
              <span>
                <QuestionMarkMajor
                  className="flex items-center opacity-50"
                  width={14}
                  height={14}
                  style={{ marginLeft: '-1rem' }}
                />
              </span>
            </Tooltip>
            <span className="ml-56 mb-1">{'(optional)'}</span>
          </Stack>

          <Stack>
            <Select
              className="z-50 mt-auto"
              options={templatesList}
              styles={setStyles(300, 2)}
              placeholder="Choose a template..."
              onChange={(val) => handleUseTemplate(val?.value)}
            />
          </Stack>
        </Stack.Item>
        <Stack.Item>
          <Stack alignment="center">
            <p style={{ marginBottom: '2px', marginRight: '4px' }} className="font-semibold">
              Level
            </p>
            <Tooltip content="Please choose a Facebook advertising level for which we should execute the rule action (i.e. turn off a campaign)">
              <span>
                <QuestionMarkMajor
                  className="flex items-center opacity-50"
                  width={14}
                  height={14}
                  style={{ marginLeft: '-1rem' }}
                />
              </span>
            </Tooltip>
          </Stack>

          <Stack>
            <Select
              className="z-50"
              options={ruleLevels}
              styles={setStyles(180)}
              placeholder="Choose a level..."
              onChange={(val) => handleChangeLevel(val)}
              value={ruleLevels.find((option) => option.value === rule?.level || '')}
            />
          </Stack>
        </Stack.Item>
        <Stack.Item>
          <Stack>
            <p style={{ marginBottom: '2px' }} className="font-semibold">
              Title
            </p>
          </Stack>
          <Stack>
            <TextField
              label="Title"
              labelHidden
              placeholder="Name your rule"
              value={rule?.name || ''}
              onChange={(val) => {
                setRule({ ...rule, name: val });
                validateRuleName(val);
                // setRuleName(val);
              }}
              error={rule?.showNameError}
              autoComplete="off"
            />
          </Stack>
        </Stack.Item>
        <Stack.Item>
          <Stack>
            <p style={{ marginBottom: '2px' }} className="font-semibold">
              Description
            </p>
          </Stack>
          <Stack>
            <TextField
              label="Description"
              labelHidden
              placeholder="Description"
              value={rule?.description || ''}
              onChange={(val) => {
                setRule({ ...rule, description: val });
              }}
              autoComplete="off"
            />
          </Stack>
        </Stack.Item>
        <Stack.Item>
          <Stack alignment="center">
            <p style={{ marginBottom: '2px', marginRight: '4px' }} className="font-semibold">
              Schedule
            </p>
            <Tooltip content="Select how often Triple Whale should evaluate this rule and execute an action.">
              <span>
                <QuestionMarkMajor
                  className="flex items-center opacity-50"
                  width={14}
                  height={14}
                  style={{ marginLeft: '-1rem' }}
                />
              </span>
            </Tooltip>
          </Stack>
          <Stack>
            <Select
              className=""
              options={scheduleOptions}
              styles={setStyles(200)}
              placeholder="Choose a time to run"
              onChange={(val) => handleFrequencyChange(val?.value)}
              value={scheduleOptions.find((option) => option.value === rule?.frequency)}
            />
          </Stack>
        </Stack.Item>
        {rule && rule.frequency && showRunTime(rule) && (
          <Stack.Item>
            <Stack alignment="center">
              <p style={{ marginBottom: '2px' }} className="font-semibold">
                Time of Day
              </p>
              <Tooltip content="Choose the time of day when the rule should be evaluated. Time is based on your Shopify store's timezone.">
                <span>
                  <QuestionMarkMajor
                    className="flex items-center opacity-50"
                    width={14}
                    height={14}
                    style={{ marginLeft: '-1rem' }}
                  />
                </span>
              </Tooltip>
            </Stack>
            <Stack>
              <Select
                className=""
                options={runTimeOptions.map((o) => ({
                  ...o,
                  label: `${o.label} ${shopTimezoneAbbr}`,
                }))}
                styles={setStyles(250)}
                placeholder="Choose a time to run"
                onChange={(val) => handleRunTimeChange(val?.value)}
                value={runTimeOptions
                  .map((o) => ({ ...o, label: `${o.label} ${shopTimezoneAbbr}` }))
                  .find((option) => option.value === rule?.runAt)}
              />
            </Stack>
          </Stack.Item>
        )}

        {rule && rule.frequency && showWeekDay(rule) && (
          <Stack.Item fill>
            <Stack alignment="center">
              <p style={{ marginBottom: '2px' }} className="font-semibold">
                Day of the Week
              </p>
              <Tooltip content="Choose the days of the week when the rule should be evaluated.">
                <span>
                  <QuestionMarkMajor
                    className="flex items-center opacity-50"
                    width={14}
                    height={14}
                    style={{ marginLeft: '-1rem' }}
                  />
                </span>
              </Tooltip>
            </Stack>
            <Stack>
              <Select
                className=""
                options={weekDays}
                isMulti={true}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={{
                  Option: WeekDayOption,
                }}
                styles={setStyles(400)}
                placeholder={'Choose a week day to run'}
                onChange={(val) => handleWeekdayChange(val)}
                value={
                  rule?.daysOfWeek?.length === 7 || rule?.daysOfWeek?.length === 0
                    ? weekDays[0]
                    : weekDays.filter((option) => rule?.daysOfWeek?.includes(+option.value))
                }
              />
            </Stack>
          </Stack.Item>
        )}
      </Stack>
      {nextRunLoading ? (
        <Spinner size="small" />
      ) : (
        <div className="mt-5">Next scheduled execution: {rule ? rule.nextRunForClient : ''}</div>
      )}
    </>
  );
};
