import { Switch } from '@tw/ui-components';
import { chatActions } from 'utils/dataLayer';
import { useComputedValue } from '@tw/snipestate';
import { $chatSelectorDrawer, closeChatSelectorDrawer } from '$stores/willy/$mobileDrawers';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { setSqlGeneratingModel } from '$stores/willy/$mainChat';
import { $mainChatStore } from '$stores/willy/$mainChat';
import { analyticsEvents } from 'utils/dataLayer/constants';
import { genericEventLogger } from 'utils/dataLayer';
import { Drawer, Text } from '@tw/ui-components';
import { WILLY_NAV_ITEMS } from './willyNavItems';
import { WillyNavItem } from './WillyNavItem';

export const MobileDrawerWillyChat: React.FC = () => {
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const activeTab = searchParams.get('tab') || 'chat';
  const drawerOpen = useComputedValue($chatSelectorDrawer, (r) => r.opened);
  const sqlGeneratingModel = useComputedValue($mainChatStore, (r) => r.sqlGeneratingModel);

  const updateModel = (value) => {
    const newModel = value ? 'o1-preview' : 'default';
    setSqlGeneratingModel(newModel);
    genericEventLogger(analyticsEvents.CHAT, {
      action: chatActions.TOGGLE_CHAT_MODEL,
      model: newModel,
    });
  };
  return (
    <Drawer
      size="xs"
      shadow="0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.1)"
      closeOnClickOutside
      opened={drawerOpen}
      withCloseButton={false}
      onClose={() => closeChatSelectorDrawer()}
    >
      <div className="h-[calc(100vh-32px)]  flex flex-col justify-between">
        <div>
          <div className="p-3 pb-5 flex gap-5 w-full flex-col">
            <Text fz="xl" fw="600">
              Moby
            </Text>
            <div className="border-t border-solid border-l-0 border-r-0 border-b-0 willy-border" />
          </div>
          <div className="p-3 gap-4 flex flex-col">
            {WILLY_NAV_ITEMS.map((item) => (
              <WillyNavItem key={item.id} item={item} activeTab={activeTab} />
            ))}
          </div>
        </div>
        <div>
          <div className="border-t border-solid border-l-0 border-r-0 border-b-0 willy-border pb-5" />
          <div className="p-3 gap-4 flex flex-col">
            <Switch
              size="sm"
              label={
                <Text span fz="sm" c="gray.8" fw={500}>
                  Pro Model
                </Text>
              }
              onChange={(e) => updateModel(e.target.checked)}
              checked={sqlGeneratingModel === 'o1-preview'}
            />
          </div>
        </div>
      </div>
    </Drawer>
  );
};
