import { ActionIcon, Flex, Icon, Size, Text } from '@tw/ui-components';
import { WillyBaseMainElement } from 'components/Willy/types/willyTypes';
import { FC } from 'react';
import { TemplateCard } from './TemplateCard';
import { closeCreateModal } from '$stores/willy/$createModal';

type FeaturedTemplatesSectionProps = {
  toggleDisplayFeaturedTemplates: () => void;
  featuredTemplates: WillyBaseMainElement[];
  closeCreateModal?: () => void;
};

export const FeaturedTemplatesSection: FC<FeaturedTemplatesSectionProps> = ({
  featuredTemplates,
  toggleDisplayFeaturedTemplates,
  closeCreateModal,
}) => {
  return (
    <Flex bg="teal.0" borderRadius={'12px'} direction="column" mt={'40px' as Size} p="md" gap="sm">
      <Flex align="center" justify="space-between">
        <Flex align="center" gap="xs">
          <Icon name="stars" size={16} color="teal.4" />
          <Text color="gray.8" weight={500}>
            Featured Templates
          </Text>
        </Flex>
        <ActionIcon
          onClick={toggleDisplayFeaturedTemplates}
          icon="close"
          variant="transparent"
          iconSize={14}
        />
      </Flex>
      <div className="pl-1 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 flex-wrap">
        {featuredTemplates.map((template, index) => (
          <TemplateCard
            key={index}
            dashboard={template}
            variant="short"
            closeCreateModal={closeCreateModal}
          />
        ))}
      </div>
    </Flex>
  );
};
