import { SavedQueryType } from 'components/Willy/types/willyTypes';
import {
  SAVED_QUERIES_COLLECTION,
  SAVED_RULES_COLLECTION,
  SAVED_SNIPPETS_COLLECTION,
} from './constants';

export function getCollectionByQueryType(queryType?: SavedQueryType) {
  if (!queryType) {
    return null;
  }
  if (queryType === 'query') {
    return SAVED_QUERIES_COLLECTION;
  }
  if (queryType === 'snippet') {
    return SAVED_SNIPPETS_COLLECTION;
  }
  if (queryType === 'rule') {
    return SAVED_RULES_COLLECTION;
  }
  if (queryType === 'customMetric') {
    return 'custom_metrics';
  }

  return null;
}

import { Monaco } from '@monaco-editor/react';
import { editor } from 'monaco-editor';

export function updateEditorText(
  val: string,
  editorRef?: editor.IStandaloneCodeEditor,
  monacoRef?: Monaco,
) {
  const position = editorRef?.getPosition();
  if (position && monacoRef) {
    const Monaco = monacoRef;

    editorRef?.executeEdits('', [
      {
        range: new Monaco.Range(position.lineNumber, 1, position.lineNumber, 1),
        text: val,
      },
    ]);

    editorRef?.focus();

    const allValueInEditor = editorRef?.getValue();

    return allValueInEditor;
  }

  return '';
}
