import { $isAdminClaim, $user } from '$stores/$user';
import { useStoreValue } from '@tw/snipestate';
import { ActionIcon, Tooltip } from '@tw/ui-components';
import { Component, useCallback } from 'react';
import { Mention, MentionsInput, MentionsInputProps } from 'react-mentions';
import { analyticsEvents, genericEventLogger, willyActions } from 'utils/dataLayer';
import { isMobileApp } from 'utils/Device';
import { useWindowSize } from 'utils/useWindowSize';
import { getCurrentAnalyticsActionSet } from './utils/willyUtils';
import { ChatSources } from './types/willyTypes';

type ChatInputProps = {
  value: string;
  context: ChatSources;
  inputRef: React.RefObject<Component<MentionsInputProps, any, any>>;
  children: MentionsInputProps['children'];
  withoutInputShadow?: boolean;
  willyLoading: boolean;
  isReadyForChat: boolean;
  currentAnalyticsEvent: string;
  placeholder?: string;
  disabled?: boolean;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  onSubmit: (value: string) => void;
  onStopChat: () => void;
};

// DO NOT add dashContext or any specific context to this component.
export const ChatInput: React.FC<ChatInputProps> = ({
  value,
  setValue,
  context,
  inputRef,
  placeholder,
  disabled,
  children,
  onSubmit,
  withoutInputShadow,
  willyLoading,
  isReadyForChat,
  onStopChat,
  currentAnalyticsEvent,
}) => {
  const isUserAdmin = useStoreValue($isAdminClaim);
  const user = useStoreValue($user);
  const windowSize = useWindowSize();

  const onInputKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      const { shiftKey, metaKey, key } = e;
      if (key === 'Enter' && !(metaKey || shiftKey)) {
        e.preventDefault();
        isReadyForChat && onSubmit(value);
        return;
      } else if (key === 'Enter' && (metaKey || shiftKey)) {
        e.preventDefault();
        setValue((prev) => `${prev}\n`);
      }
    },
    [onSubmit, isReadyForChat, value, setValue],
  );

  return (
    <div className="relative" onKeyDown={onInputKeyDown}>
      <MentionsInput
        ref={inputRef}
        id="willy-input"
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        className="w-full"
        style={{
          control: {
            backgroundColor: '#fff',
            fontSize: 14,
            fontWeight: 'normal',
            border: 'none',
            boxShadow: withoutInputShadow ? 'none' : '0px 0px 15px var(--p-dark-border)',
            borderRadius: '10px',
          },
          highlighter: {
            padding: 16,
            paddingRight: 50,
            paddingLeft: 40,
            border: 'none',
            fontSize: '16px',
            fontWeight: 600,
          },
          input: {
            padding: 16,
            paddingRight: 50,
            paddingLeft: 16,
            border: 'none',
            backgroundColor: 'transparent',
            boxShadow: 'none',
            fontSize: '16px',
            fontWeight: 500,
            outline: 'none',
          },
          suggestions: {
            list: {
              backgroundColor: 'white',
              // border: '1px solid rgba(0,0,0,0.15)',
              fontSize: 14,
              maxHeight: '200px',
              overflow: 'auto',
            },
            item: {
              padding: '5px 15px',
              '&focused': {
                backgroundColor: '#cee4e5',
              },
            },
          },
        }}
        placeholder={placeholder}
        disabled={disabled}
        autoFocus={!windowSize.isSmall && !isMobileApp}
        // inputRef={inputRef}
        forceSuggestionsAboveCursor={true}
      >
        {children}
      </MentionsInput>
      <div className="absolute flex-shrink-0 top-1/2 right-0 -translate-x-1/2 -translate-y-1/2">
        {willyLoading && (
          <ActionIcon
            icon="pause"
            variant="transparent"
            onClick={() => {
              onStopChat();
            }}
          />
        )}
        {!willyLoading && (
          <div className="flex">
            <Tooltip
              label={
                !isReadyForChat ? 'Warming up chat...' : !value?.trim() ? 'Please enter value' : ''
              }
            >
              <ActionIcon
                size="md"
                disabled={!value?.trim() || !isReadyForChat}
                variant={!value?.trim() || !isReadyForChat ? 'light' : 'filled'}
                radius="sm"
                color="white"
                onClick={(e) => {
                  onSubmit(value);
                  genericEventLogger(analyticsEvents.WILLY, {
                    action: willyActions.START_CHAT,
                    prompt: value,
                  });
                  genericEventLogger(currentAnalyticsEvent, {
                    action: getCurrentAnalyticsActionSet(context).SEND_MESSAGE,
                    prompt: value,
                  });
                }}
                icon="send-chat"
                iconSize={16}
              />
            </Tooltip>
          </div>
        )}
      </div>
    </div>
  );
};
