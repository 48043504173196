import { useCallback, useEffect } from 'react';
import { Message, SequenceProgressEvent } from '../types/willyTypes';
import { useWillySocket } from '../WillySocket';
import { v4 as uuidV4 } from 'uuid';

type UseSequenceSocketProps = {
  sequenceId: string;
  runId: string;
  setMessages: React.Dispatch<React.SetStateAction<Message[]>>;
  setLastMessageId: React.Dispatch<React.SetStateAction<string>>;
  setLoadingSequence: React.Dispatch<React.SetStateAction<boolean>>;
  setLoadingSequenceText: React.Dispatch<React.SetStateAction<string>>;
};

export const useSequenceSocket = (props: UseSequenceSocketProps) => {
  const {
    sequenceId,
    runId,
    setMessages,
    setLastMessageId,
    setLoadingSequence,
    setLoadingSequenceText,
  } = props;

  const { socket } = useWillySocket();

  const sequenceProgress = useCallback(
    (data: SequenceProgressEvent) => {
      const { messageId, sequenceId: seqId, type, text, runId: id } = data;

      if (seqId !== sequenceId || runId !== id) {
        return;
      }

      if (type === 'sequence-started') {
        setLoadingSequence(true);
        return;
      } else if (type === 'sequence-done' || type === 'sequence-error' || type === 'step-started') {
        setLoadingSequence(false);
        setLoadingSequenceText('');
      }

      if (type === 'sequence-done') {
        const { outputFile } = data;
        const newFinalMessage: Message = {
          text: 'Sequence finished, you can schedule it to run again at any time.',
          role: 'assistant',
          id: messageId,
          outputFile,
        };
        setMessages((old) => {
          return [...old, newFinalMessage];
        });
        return;
      } else if (type === 'sequence-error') {
        const newFinalMessage: Message = {
          text: text || 'Sequence failed, please try again.',
          role: 'assistant',
          id: messageId,
          error: text,
        };
        setMessages((old) => {
          return [...old, newFinalMessage];
        });
        return;
      } else if (type === 'progress') {
        setLoadingSequenceText(text);
      } else if (type === 'step-started') {
        setLastMessageId(messageId);
        const newMessageFromUser: Message = {
          text: text,
          role: 'user',
          id: uuidV4(),
        };
        const newAssistantMessage: Message = {
          id: messageId,
          role: 'assistant',
          loading: true,
        };
        const allMessages = [newMessageFromUser, newAssistantMessage];
        setMessages((old) => {
          return [...old, ...allMessages];
        });
      }
    },
    [sequenceId, runId, setMessages, setLastMessageId, setLoadingSequence, setLoadingSequenceText],
  );

  useEffect(() => {
    socket.on('sequence-progress', sequenceProgress);

    return () => {
      socket.off('sequence-progress', sequenceProgress);
    };
  }, [socket, sequenceProgress]);
};
