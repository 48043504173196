import { Button, Modal } from '@tw/ui-components';
import { FreeQuery } from 'pages/FreeQuery/FreeQuery';

type FlowStepWithFullEditorProps = {
  opened: boolean;
  query: string;
  params: Record<string, string>;
  onClose: () => void;
  setQuery: (query: string) => void;
  setParams: (params: Record<string, string>) => void;
  onSave: (query: string, params: Record<string, string>) => void;
};

export const FlowStepWithFullEditor = ({
  opened,
  onClose,
  query,
  setQuery,
  params,
  setParams,
  onSave,
}: FlowStepWithFullEditorProps) => {
  return (
    <Modal.Root
      size="xl"
      centered
      fullScreen
      opened={opened}
      onClose={onClose}
      closeOnEscape={false}
    >
      <Modal.Overlay />
      <Modal.Content className="!flex flex-col w-full h-full">
        <Modal.Header>
          <Modal.Title>
            <span className=" text-3xl font-semibold">Edit Query</span>
          </Modal.Title>
          <Button variant="white" onClick={onClose}>
            Close
          </Button>
        </Modal.Header>
        <div className="w-full h-[1px] bg-[#E5E7EB] mt-5"></div>
        <Modal.Body className="flex flex-auto">
          <div className="flex w-full">
            <div className="w-full flex flex-col flex-auto">
              <FreeQuery
                initialQuery={query}
                queryChanged={setQuery}
                language="sql"
                queryVars={Object.keys(params)}
                queryVarsValues={Object.entries(params).reduce((acc, [key, value]) => {
                  return {
                    ...acc,
                    value,
                  };
                }, {})}
                queryVarsValuesChanged={setParams}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="flex justify-end gap-2 items-center px-4">
            <Button variant="white" onClick={onClose}>
              Cancel
            </Button>
            <Button
              onClick={() => {
                onSave(query, params);
                onClose();
              }}
            >
              Save
            </Button>
          </div>
        </Modal.Footer>
      </Modal.Content>
    </Modal.Root>
  );
};
