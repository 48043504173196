import { Flex, Text } from '@tw/ui-components';
import { FC, useMemo, useState } from 'react';
import { BuilderBadge } from './BuilderBadge';
import { SelectWithSearchInput } from './SelectWithSearchInput';

type ChooseTableSectionProps = {
  value?: string;
  data: { label: string; value: string }[];
  onChange: (value: string | null) => void;
};

export const RecommendedTable = 'blended_stats_tvf';

export const ChooseTableSection: FC<ChooseTableSectionProps> = ({ value, data, onChange }) => {
  const [selectClicked, setSelectClicked] = useState(false);
  const innerData = useMemo(
    () => data.map((d) => (d.value === RecommendedTable ? { ...d, label: 'Default Table' } : d)),
    [data],
  );

  return (
    <Flex direction="column" gap="sm">
      <Text weight={500} color="gray.8" size="sm">
        Choose a table
      </Text>
      {selectClicked || !value ? (
        <SelectWithSearchInput
          value={value}
          data={innerData}
          onChange={(val) => {
            onChange(val);
            setSelectClicked(false);
          }}
          width="target"
          onClose={() => setSelectClicked(false)}
          opened={selectClicked}
        />
      ) : (
        <BuilderBadge
          text={innerData.find((v) => v.value === value)?.label ?? ''}
          type="table"
          onClick={() => {
            setSelectClicked(true);
          }}
          recommended={value === RecommendedTable}
        />
      )}
    </Flex>
  );
};
