import {
  AnyClickModelMinor,
  DataDrivenModelMinor,
  FirstClickModelMinor,
  LastClickModelMinor,
  LastNonDirectClickModelMinor,
  LinearModelMinor,
  CheckoutMajor,
  BehaviorMinor,
} from '@shopify/polaris-icons';
import { AttributionModels } from '@tw/types/module/types/Pixel';
import { ServicesIds } from '@tw/types/module/services';
import React, { createContext, useEffect } from 'react';
import { RootState } from 'reducers/RootType';
import { createSelector } from 'reselect';
import { AttributionContextProps, PixelAttributionModel, PixelDateModel } from 'types/attribution';
import { useAppDispatch } from 'index';
import { Anchor, Box, Flex, Icon, Text } from '@tw/ui-components';
import { useSelector } from 'react-redux';
import { selectHasSomePpsInstalled } from 'utils/selectors';
import { InfoTab, InfoPanel } from 'components/attribution-new/AttributionMultipaneDropdown';
import { useNavigate, useLocation } from 'react-router-dom';

export const TW_SUMMARY_PIXEL_COLUMNS = 'TW_SUMMARY_PIXEL_COLUMNS';
export const MAX_SELECTED_ATTRIBUTIONS = 8;

export const DISABLE_SHOW_IN_CHART_MESSAGE = `You can only compare data from up to ${MAX_SELECTED_ATTRIBUTIONS} items at a time`;

export const AttributionPageContext = createContext<AttributionContextProps>(
  {} as AttributionContextProps,
);

export const UNMATCHED_ROWS_ID = 'tw_unmatched_row';
export const LOADING_ROW_ID = 'loading_row_not_real';
const SERVICES_TO_SHOW_STATUS_COLUMN: ServicesIds[] = [
  'facebook-ads',
  'google-ads',
  'tiktok-ads',
  'snapchat-ads',
  'pinterest-ads',
  'twitter-ads',
];
export function getServicesToShowStatusColumn() {
  return SERVICES_TO_SHOW_STATUS_COLUMN;
}

export const TRIPLE_ATTRIBUTION_LABEL = 'Triple Attribution';
export const TRIPLE_ATTRIBUTION_VALUE = 'lastPlatformClick-v2';

export const PIXEL_ATTRIBUTION_MODELS: PixelAttributionModel[] = [
  {
    label: (
      <InfoTab
        title="Last click"
        description="100% credit is given to the last Paid channel clicked."
        icon={<LastClickModelMinor className="w-8" />}
      />
    ),
    value: 'lastClick',
    shortLabel: 'LC',
    disableOnTypes: ['social'],
    order: 7,
    infoPanel: (
      <InfoPanel
        modelValues={[
          {
            title: 'Why use it:',
            description:
              'To optimize for direct response by understanding which final touchpoint directly resulted in conversion',
          },
          {
            title: 'When to use it:',
            description:
              'Optimizing marketing budgets and efforts towards channels that are interacted with directly before the purchase event',
          },
          {
            title: "Who it's for:",
            description: 'Direct response marketers focused on optimizing conversion rate',
          },
        ]}
        videoEmbed="https://www.loom.com/embed/cad67b4de9f84d7e9ef82fd48fbd2ef6"
      />
    ),
  },
  {
    label: (
      <InfoTab
        title="First click"
        description="100% credit is given to the first Paid channel clicked."
        icon={<FirstClickModelMinor className="w-8" />}
      />
    ),
    value: 'firstClick',
    shortLabel: 'FC',
    disableOnTypes: ['social'],
    order: 6,
    infoPanel: (
      <InfoPanel
        modelValues={[
          {
            title: 'Why use it:',
            description:
              'To understand the beginning of the journey and optimize for upper funnel awareness that may lead to downstream revenue',
          },
          {
            title: 'When to use it:',
            description:
              'Visualizing or optimizing towards the channels that are driving the first interaction with your brand',
          },
          {
            title: "Who it's for:",
            description: 'Growth marketers focused on launch strategies and top funnel metrics',
          },
        ]}
        videoEmbed="https://www.loom.com/embed/cad67b4de9f84d7e9ef82fd48fbd2ef6"
      />
    ),
  },
  {
    label: (
      <InfoTab
        title="Linear Paid"
        description="Divides credit equally among all Paid channels."
        icon={<LastNonDirectClickModelMinor className="w-8" />}
      />
    ),
    value: 'linear',
    shortLabel: 'L Paid',
    disableOnTypes: ['all', 'social', 'email'],
    order: 5,
    infoPanel: (
      <InfoPanel
        modelValues={[
          {
            title: 'Why use it:',
            description:
              'To directionally understand paid channel performance by normalizing for credit across channels',
          },
          {
            title: 'When to use it:',
            description:
              'When you want to visualize the attribution split of your paid media efforts without accounting for non-paid channel involvement',
          },
          {
            title: "Who it's for:",
            description: 'Paid media managers comparing channel performance',
          },
        ]}
        videoEmbed="https://www.loom.com/embed/cad67b4de9f84d7e9ef82fd48fbd2ef6"
      />
    ),
  },
  {
    label: (
      <InfoTab
        title="Linear All"
        description="Divides credit equally among all channels."
        icon={<LinearModelMinor className="w-8" />}
      />
    ),
    value: 'linearAll',
    shortLabel: 'L All',
    disableOnTypes: [],
    enableOnSources: ['all', 'tw_referrer'],
    order: 4,
    infoPanel: (
      <InfoPanel
        modelValues={[
          {
            title: 'Why use it:',
            description:
              'To holistically evaluate marketing mix contribution to the customer journey rather than giving credit to any particular touchpoint',
          },
          {
            title: 'When to use it:',
            description:
              'Visualizing how your stores revenue gets attributed when all marketing channels are given equal weight in the purchase journey',
          },
          {
            title: "Who it's for:",
            description: 'Full funnel analysts assessing entire customer journey',
          },
        ]}
        videoEmbed="https://www.loom.com/embed/cad67b4de9f84d7e9ef82fd48fbd2ef6"
      />
    ),
  },
  {
    label: (
      <InfoTab
        title={TRIPLE_ATTRIBUTION_LABEL}
        description="Each channel gets 100% credit. Similar to ad platform attribution."
        icon={<AnyClickModelMinor className="w-8" />}
      />
    ),
    value: 'lastPlatformClick',
    shortLabel: 'TA',
    disableOnTypes: [],
    order: 2,
    infoPanel: (
      <InfoPanel
        modelValues={[
          {
            title: 'Why use it:',
            description:
              "To granularly optimize channel-specific performance by isolating each channel's impact",
          },
          {
            title: 'When to use it:',
            description: 'When implementing ongoing channel-level programmatic optimization',
          },
          {
            title: "Who it's for:",
            description: 'Programmatic marketers optimizing within channels',
          },
        ]}
        videoEmbed="https://www.loom.com/embed/cad67b4de9f84d7e9ef82fd48fbd2ef6"
      />
    ),
  },
];

export const PIXEL_ATTRIBUTION_NEW_MODELS: PixelAttributionModel[] = [
  {
    label: (
      <InfoTab
        title="Last click"
        description="100% credit is given to the last channel clicked."
        icon={<LastClickModelMinor className="w-8" />}
      />
    ),
    value: 'fullLastClick-v2',
    shortLabel: 'LC',
    disableOnTypes: [],
    order: 7,
    infoPanel: (
      <InfoPanel
        modelValues={[
          {
            title: 'Why use it:',
            description:
              'To optimize for direct response by understanding which final touchpoint directly resulted in conversion',
          },
          {
            title: 'When to use it:',
            description:
              'Optimizing marketing budgets and efforts towards channels that are interacted with directly before the purchase event',
          },
          {
            title: "Who it's for:",
            description: 'Direct response marketers focused on optimizing conversion rate',
          },
        ]}
        videoEmbed="https://www.loom.com/embed/cad67b4de9f84d7e9ef82fd48fbd2ef6"
      />
    ),
    aka: 'lastClick',
  },
  {
    label: (
      <InfoTab
        title="First click"
        description="100% credit is given to the first channel clicked."
        icon={<FirstClickModelMinor className="w-8" />}
      />
    ),
    value: 'fullFirstClick-v2',
    shortLabel: 'FC',
    disableOnTypes: [],
    order: 6,
    infoPanel: (
      <InfoPanel
        modelValues={[
          {
            title: 'Why use it:',
            description:
              'To understand the beginning of the journey and optimize for upper funnel awareness that may lead to downstream revenue',
          },
          {
            title: 'When to use it:',
            description:
              'Visualizing or optimizing towards the channels that are driving the first interaction with your brand',
          },
          {
            title: "Who it's for:",
            description: 'Growth marketers focused on launch strategies and top funnel metrics',
          },
        ]}
        videoEmbed="https://www.loom.com/embed/cad67b4de9f84d7e9ef82fd48fbd2ef6"
      />
    ),
    aka: 'firstClick',
  },
  {
    label: (
      <InfoTab
        title="Linear Paid"
        description="Divides credit equally among all Paid channels."
        icon={<LastNonDirectClickModelMinor className="w-8" />}
      />
    ),
    value: 'linear-v2',
    shortLabel: 'L Paid',
    disableOnTypes: ['all', 'social', 'email'],
    order: 5,
    infoPanel: (
      <InfoPanel
        modelValues={[
          {
            title: 'Why use it:',
            description:
              'To directionally understand paid channel performance by normalizing for credit across channels',
          },
          {
            title: 'When to use it:',
            description:
              'When you want to visualize the attribution split of your paid media efforts without accounting for non-paid channel involvement',
          },
          {
            title: "Who it's for:",
            description: 'Paid media managers comparing channel performance',
          },
        ]}
        videoEmbed="https://www.loom.com/embed/cad67b4de9f84d7e9ef82fd48fbd2ef6"
      />
    ),
    aka: 'linear',
  },
  {
    label: (
      <InfoTab
        title="Linear All"
        description="Divides credit equally among all channels."
        icon={<LinearModelMinor className="w-8" />}
      />
    ),
    value: 'linearAll-v2',
    shortLabel: 'L All',
    disableOnTypes: [],
    order: 4,
    infoPanel: (
      <InfoPanel
        modelValues={[
          {
            title: 'Why use it:',
            description:
              'To holistically evaluate marketing mix contribution to the customer journey rather than giving credit to any particular touchpoint',
          },
          {
            title: 'When to use it:',
            description:
              'Visualizing how your stores revenue gets attributed when all marketing channels are given equal weight in the purchase journey',
          },
          {
            title: "Who it's for:",
            description: 'Full funnel analysts assessing entire customer journey',
          },
        ]}
        videoEmbed="https://www.loom.com/embed/cad67b4de9f84d7e9ef82fd48fbd2ef6"
      />
    ),
    aka: 'linearAll',
  },
  {
    label: (
      <InfoTab
        title={TRIPLE_ATTRIBUTION_LABEL}
        description="Each channel gets 100% credit. Similar to ad platform attribution."
        icon={<AnyClickModelMinor className="w-8" />}
      />
    ),
    value: TRIPLE_ATTRIBUTION_VALUE,
    shortLabel: 'TA',
    disableOnTypes: [],
    order: 2,
    infoPanel: (
      <InfoPanel
        modelValues={[
          {
            title: 'Why use it:',
            description:
              "To granularly optimize channel-specific performance by isolating each channel's impact",
          },
          {
            title: 'When to use it:',
            description: 'When implementing ongoing channel-level programmatic optimization',
          },
          {
            title: "Who it's for:",
            description: 'Programmatic marketers optimizing within channels',
          },
        ]}
        videoEmbed="https://www.loom.com/embed/cad67b4de9f84d7e9ef82fd48fbd2ef6"
      />
    ),
    aka: 'lastPlatformClick',
  },
];

export const PIXEL_ATTRIBUTION_NEW_MODELS_MODES: any[] = [
  {
    label: (
      <InfoTab
        title="Current Generation"
        description="The current generation of attribution models. Default attribution window is Lifetime."
      />
    ),
    value: 'current',
    shortLabel: 'Current',
    disableOnTypes: [],
    order: 1,
  },
  {
    label: (
      <InfoTab
        title="Next Generation (BETA)"
        description="The next generation of attribution models using ID Graph. Default attribution window is 28 days."
      />
    ),
    value: 'nextgen',
    shortLabel: 'Next Gen',
    disableOnTypes: ['creativeCockpit'],
    order: 2,
  },
];

export const selectAllAttributionModels = createSelector(
  [(state: RootState) => state.ppsStatus?.hasPpsData],
  (hasPpsData) => {
    const models: PixelAttributionModel[] = PIXEL_ATTRIBUTION_NEW_MODELS.concat([
      {
        value: 'lastPlatformClickPlusViews' as AttributionModels,
        label: (
          <InfoTab
            title="Triple Attribution + FB Views"
            description="Adds View-Through sales measured by Facebook."
            icon={<AnyClickModelMinor className="w-8" />}
          />
        ),
        shortLabel: 'TA+V',
        disableOnTypes: ['social', 'email'],
        enableOnSources: ['all', 'facebook-ads'],
        order: 3,
        infoPanel: (
          <InfoPanel
            modelValues={[
              {
                title: 'Why use it:',
                description:
                  'To understand the total impact of Facebook ads including views and clicks',
              },
              {
                title: 'When to use it:',
                description: 'When analyzing the full value of your Facebook ad efforts',
              },
              {
                title: "Who it's for:",
                description: 'Facebook marketers who leverage video and engagement ads',
              },
            ]}
            videoEmbed="https://www.loom.com/embed/cad67b4de9f84d7e9ef82fd48fbd2ef6"
          />
        ),
      },
      {
        label: (
          <InfoTab
            title={
              <span className="flex items-center gap-2">
                <span>Total Impact</span>
                <span className="rounded-full py-1 px-3 bg-[#D1FAEE] text-xl">Recommended</span>
              </span>
            }
            description={<TotalImpactDescription hasPpsData={hasPpsData} />}
            icon={<DataDrivenModelMinor className="w-8" />}
          />
        ),
        value: 'ppsViews',
        shortLabel: 'TI',
        disableOnTypes: [],
        disabled: !hasPpsData,
        order: 1,
        infoPanel: (
          <InfoPanel
            topElement={() => {
              const navigate = useNavigate();
              const hasPpsInstalled = useSelector(selectHasSomePpsInstalled);

              if (hasPpsInstalled) return null;

              return (
                <Flex bg="yellow.0" borderRadius="6px">
                  <Box pt="sm" pb="xs" px="sm">
                    <Icon name="exclamation" color="yellow.4" />
                  </Box>
                  <Flex direction="column">
                    <Text py="xs" fw={500} lh="20px" color="yellow.8">
                      Post-purchase Survey needed to use the Total Impact Model.
                    </Text>
                    <Text pb="xs" lh="20px" color="yellow.7">
                      Please install PPS to get accurate data and <br />
                      <Anchor
                        as="button"
                        onClick={() => navigate('/post-purchase-survey/get-started')}
                        underline="always" // TODO: Double check this
                        fw={450}
                        color="yellow.7"
                      >
                        Click Here to Install
                      </Anchor>
                    </Text>
                  </Flex>
                </Flex>
              );
            }}
            modelValues={[
              {
                title: 'Why use it:',
                description:
                  'To allocate budget based on algorithmic determination of influence rather than last interaction',
              },
              {
                title: 'When to use it:',
                description: 'During marketing mix modeling and budgeting processes',
              },
              {
                title: "Who it's for:",
                description: 'Executives and stakeholders managing marketing spend',
              },
            ]}
          />
        ),
      },
    ]);

    return models.sort((a, b) => a.order - b.order);
  },
);

const TotalImpactDescription: React.FC<{ hasPpsData?: boolean }> = ({ hasPpsData }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { hash } = useLocation();
  useEffect(() => {
    if (hash === '#show_total_impact_modal') {
      navigate('#');

      dispatch({
        type: 'SHOW_TOTAL_IMPACT_MODAL',
        show: true,
      });
    }
  }, [hash, dispatch, navigate]);

  return (
    <div>
      A model powered by AI that uses a blend of first and zero party data.{' '}
      <a
        className="text-gray-500 hover:text-gray-800 cursor-pointer"
        target={hasPpsData ? '_blank' : ''}
        href={
          hasPpsData
            ? 'https://kb.triplewhale.com/en/articles/7128379-the-total-impact-attribution-model'
            : '#show_total_impact_modal'
        }
      >
        Learn more about Attribution 2.0
      </a>
    </div>
  );
};

export const PIXEL_ATTRIBUTION_DATE_MODELS: PixelDateModel[] = [
  {
    label: (
      <InfoTab
        title="Event Date"
        description="The date the purchase event occurred."
        icon={<CheckoutMajor className="w-6 flex" />}
      />
    ),
    value: 'eventDate',
    shortLabel: 'Event',
    disableOnTypes: [],
    order: 1,
  },
  {
    label: (
      <InfoTab
        title="Click Date"
        description="The date the click occurred."
        icon={<BehaviorMinor className="w-8 flex" />}
      />
    ),
    value: 'clickDate',
    shortLabel: 'Click',
    disableOnTypes: [],
    order: 2,
  },
];

export const TW_LS_ATTRIBUTION_MODEL_KEY = 'TW_LS_ATTRIBUTION_MODEL_KEY';
export const TW_LS_ATTRIBUTION_DATE_MODEL_KEY = 'TW_LS_ATTRIBUTION_DATE_MODEL_KEY';
export const TW_LS_ATTRIBUTION_INCLUDE_ONE_DAY_VIEW = 'TW_LS_ATTRIBUTION_INCLUDE_ONE_DAY_VIEW';
export const TW_LS_ATTRIBUTION_WRAP_TABLE_LINES = 'TW_LS_ATTRIBUTION_WRAP_TABLE_LINES';
export const TW_LS_ATTRIBUTION_SHOW_ACTIVE_CAMPAIGNS_ONLY =
  'TW_LS_ATTRIBUTION_SHOW_ACTIVE_CAMPAIGNS_ONLY';
export const TW_LS_ATTRIBUTION_INCLUDE_CUSTOM_AD_SPEND =
  'TW_LS_ATTRIBUTION_INCLUDE_CUSTOM_AD_SPEND';
export const TW_LS_WORKFLOW_TO_RUN = 'TW_LS_WORKFLOW_TO_RUN';
export const TW_LS_ATTRIBUTION_WINDOW = 'TW_LS_ATTRIBUTION_WINDOW';
export const TW_LS_ATTRIBUTION_SUBSCRIPTION_FILTER = 'TW_LS_ATTRIBUTION_SUBSCRIPTION_FILTER';
export const TW_LS_PPS_LOOK_BACK_WINDOW = 'TW_LS_PPS_LOOK_BACK_WINDOW';

export enum ATTRIBUTION_COLUMNS_KEYS {
  // ATTRIBUTION_ADS_COLUMNS_ORDER = 'ATTRIBUTION_ADS_COLUMNS_ORDER',
  // ATTRIBUTION_EMAIL_COLUMNS_ORDER = 'ATTRIBUTION_EMAIL_COLUMNS_ORDER',
  // ATTRIBUTION_ORGANIC_COLUMNS_ORDER = 'ATTRIBUTION_ORGANIC_COLUMNS_ORDER',
  ATTRIBUTION_ALL_PAGE_COLUMNS_ORDER = 'ATTRIBUTION_ALL_PAGE_COLUMNS_ORDER',
}

export const localStoragePixelColumnsKeys: Partial<Record<'all', ATTRIBUTION_COLUMNS_KEYS>> = {
  all: ATTRIBUTION_COLUMNS_KEYS.ATTRIBUTION_ALL_PAGE_COLUMNS_ORDER,
  // ads: ATTRIBUTION_COLUMNS_KEYS.ATTRIBUTION_ADS_COLUMNS_ORDER,
  // email: ATTRIBUTION_COLUMNS_KEYS.ATTRIBUTION_EMAIL_COLUMNS_ORDER,
  // social: ATTRIBUTION_COLUMNS_KEYS.ATTRIBUTION_ORGANIC_COLUMNS_ORDER,
};

export const ATTRIBUTION_COLORS = [
  '#10B981',
  '#EA643D',
  '#B0446A',
  '#3159F3',
  '#10ABA4',
  '#7C5FF8',
  '#ffc107',
  '#9c27b0',
];

export type ChartData = 'metrics' | 'periods';
export type ChartMode = 'line' | 'bar';
