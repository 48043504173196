import { Flex, IconName, Loader, Text, Title } from '@tw/ui-components';
import { Fragment, useMemo, useState } from 'react';
import { WillyPrompt } from './types/willyTypes';
import { WillySearchInput } from './WillySearchInput';
import { PromptCategorySelect } from './PromptCategorySelect';
import { PromptTile } from './PromptTile';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSamplePrompts } from './useSamplePrompts';

export const PROMPT_SUBCATS: { name: string; icon?: IconName }[] = [
  { name: 'Featured', icon: 'stars' },
  { name: 'All Prompts' },
  { name: 'Popular' },
  { name: 'Saved Prompts' },
];

export type PromptSubCategory = {
  subCategory: string;
  prompts: WillyPrompt[];
};

export type PromptsCategory = {
  name: string;
  subCategories: { name: string; icon?: IconName }[];
};

type PromptLibrayPageProps = {
  onSelectPrompt: (value: WillyPrompt) => void;
};

export const PromptLibrayPage: React.FC<PromptLibrayPageProps> = ({ onSelectPrompt }) => {
  const {
    promptsToShow,
    promptTopics,
    activePromptCategory,
    setActivePromptCategory,
    searchPrompt,
    setSearchPrompt,
    loadingPrompts,
    hasMore,
    loadNextPage,
  } = useSamplePrompts();

  return (
    <div
      className="h-full overflow-auto no-scrollbar max-w-[85%] m-auto flex flex-col"
      id="prompt-library"
    >
      <Flex direction="column" bg="white" gap="xl">
        <div className="flex flex-col gap-6 sticky top-0 bg-white z-10 pb-4">
          <Flex direction="column" gap="sm">
            <Title order={5} fw={600} color="gray.8">
              Prompt Library
            </Title>
            <Text fz={18}>Explore prompts that unlock even more powerful ways to use Chat.</Text>
          </Flex>
          <div className="flex flex-col md:flex-row gap-6">
            <div className="flex  md:w-[400px] ">
              <PromptCategorySelect
                activePromptCategory={activePromptCategory}
                setActivePromptCategory={setActivePromptCategory}
                promptTopics={promptTopics}
              />
            </div>
            <WillySearchInput
              value={searchPrompt}
              onChange={setSearchPrompt}
              placeholder="Search Prompts"
              className="!p-0 overflow-visible w-full"
            />
          </div>

          <InfiniteScroll
            scrollableTarget="prompt-library"
            scrollThreshold={0.95}
            dataLength={promptsToShow.length}
            hasMore={hasMore}
            next={() => loadNextPage()}
            loader={
              <div className="flex items-center justify-center h-12">
                <Loader size="sm" />
              </div>
            }
          >
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 pb-[30px] @3xl:!px-40 ">
              {promptsToShow.map((prompt) => {
                return (
                  <Fragment key={prompt.id}>
                    <PromptTile
                      prompt={prompt}
                      onSuggestionClick={(prompt) => onSelectPrompt(prompt)}
                      promptTopics={promptTopics}
                    />
                  </Fragment>
                );
              })}
            </div>
          </InfiniteScroll>
          {loadingPrompts && (
            <div className="flex items-center justify-center h-12">
              <Loader size="sm" />
            </div>
          )}
        </div>
      </Flex>
    </div>
  );
};
