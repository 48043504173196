import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Icon, Text, TextInput, confirm } from '@tw/ui-components';
import { toast } from 'react-toastify';
import { $currentShopId } from '$stores/$shop';
import { $user, $userId } from '$stores/$user';
import { $categoriesList, $dashboardsMap, getDashboardCategoryManager } from './stores';
import type { CategoryListItem } from './types';
import {
  categoryDoc,
  createCategoryItemFromDash,
  createCategoryListItem,
  createDefaultChildItem,
  createFormattedCategoryChildMetaData,
  formatChildren,
} from './utils';
import { FieldValue } from 'utils/DB';
import { resetChangeFolderModal, updateChangeFolderModal } from './ChangeCategoryModal';

export async function initCategoryCreatorModal() {
  let categoryName = '';
  const ok = await confirm({
    title: (
      <Text fz="lg" fw={500}>
        New Folder
      </Text>
    ),
    message: (() => {
      const [_newName, set_NewName] = useState('');
      return (
        <TextInput
          mb="md"
          value={categoryName}
          onChange={(val) => {
            set_NewName(val);
            categoryName = val;
          }}
        />
      );
    }) as React.FC,
    reverseOrder: true,
    confirmText: 'Create',
    modalProps: { size: 'xs' },
  });

  if (!ok) return;

  try {
    return await createNewDashboardCategory(categoryName);
  } catch (err) {
    console.error('Error creating new category:>>', err);
  }
}

export async function createNewDashboardCategory(categoryName: string) {
  const { list } = $categoriesList.get();
  for (const { id } of list) {
    if (id !== categoryName) continue;
    toast.warn(`A category with name "${categoryName}" already exists`);
  }

  const newId = uuidv4();
  const newItem = createCategoryListItem({
    id: newId,
    depth: 0,
    type: 'folder',
    value: categoryName,
    metadata: createFormattedCategoryChildMetaData({
      isOpen: false,
      category: newId,
    }),
    children: createDefaultChildItem(newId),
  });

  try {
    await categoryDoc().set({ categories: FieldValue.arrayUnion(newItem) }, { merge: true });
    toast.success(`Report group: "${categoryName}" created successfully`);
    return newItem;
  } catch (err) {
    console.error(err);
    toast.error(`Error creating report group: "${categoryName}"`);
  }
}

export async function deleteDashboardCategory(category: CategoryListItem) {
  const confirmed = await confirm({
    title: (
      <div className="flex items-center">
        <Icon name="exclamation" color="red.6" size={20} />
        <Text ml="sm" fz="xl" fw={600} ta="center">
          Delete Folder
        </Text>
      </div>
    ),
    message: (
      <Text mb="lg">
        Are you sure you want to delete your folder? All of your reports within the folder will be
        permanently removed. This action cannot be undone.
      </Text>
    ),
    confirmText: 'Delete',
  });

  if (!confirmed) return;

  // we need to delete the category and move all the children
  // that were in that category to the top of the list
  const { list } = $categoriesList.get();

  // remove current category object from list
  const filtered = list.filter((c) => c.id !== category.id);

  // update all depths to -1 of what they previously were
  const newOrphans = category.children
    .filter((i) => !i.metadata?.isDefaultItem)
    .map((c) => ({
      ...c,
      depth: 0,
      children: c.children.map((c) => ({ ...c, depth: 1 })),
      ...(c.metadata && { metadata: createFormattedCategoryChildMetaData({ ...c.metadata }) }),
    })) satisfies CategoryListItem[];

  try {
    await categoryDoc().set({ categories: newOrphans.concat(filtered) }, { merge: true });
    toast.success(`Report folder "${category.value}" has been successfully deleted`);
  } catch (err) {
    console.error(`Error deleting dashboard folder "${category.value}"`, err);
    toast.error(`There was an error deleting report folder "${category.value}"`);
  }
}

export async function renameDashboardCategory(id: string) {
  const { list } = $categoriesList.get();
  const categoryIndex = list.findIndex((c) => c.id === id);
  if (categoryIndex === -1) {
    toast.error(`Category with id ${id} doesn't exist`);
    return;
  }

  const category = list[categoryIndex];
  const oldName = category.value;
  let newName = oldName || 'Unnamed Folder';

  const confirmed = await confirm({
    title: (
      <Text fz="xl" fw={500} ta="center">
        Rename Folder
      </Text>
    ),
    message: (() => {
      const [_newName, set_NewName] = useState('');
      return (
        <TextInput
          mb="md"
          value={newName}
          onChange={(val) => {
            set_NewName(val);
            newName = val;
          }}
        />
      );
    }) as React.FC,
    confirmText: 'Save',
    reverseOrder: true,
    modalProps: { size: 'sm' },
  });

  if (!confirmed) return;

  try {
    list[categoryIndex].value = newName;
    // update categories in sub collection
    await categoryDoc().set({ categories: list }, { merge: true });

    toast.success(`Dashboard group "${oldName}" has been successfully renamed to ${newName}`);
  } catch (err) {
    console.error(`Error renaming group "${oldName}"`, err);
    toast.error(`There was an error deleting report group "${oldName}"`);
  }
}

export async function updateDashboardCategories(
  updatedCats: CategoryListItem[],
  timestamp: number,
  withToast = false,
) {
  const currentShop = $currentShopId.get();
  const userId = $userId.get();

  if (!currentShop || !userId) {
    toast.error('No current shop set');
    return;
  }

  try {
    await categoryDoc().set({ categories: updatedCats }, { merge: true });

    if (withToast) toast.success(`Successfully updated reports folders`);
  } catch (err) {
    console.error('Error updating dashboard categories: ', err);
    if (withToast) toast.error(`Error updating reports folders: ${err.message}`);
  }
}

export type MoveDashboardToCategoryArgs = {
  dashboardId: string;
  newCategoryId: string;
};
export async function moveDashboardToCategory({
  dashboardId: dId,
  newCategoryId,
}: MoveDashboardToCategoryArgs) {
  const dashboard = $dashboardsMap.get().get(dId);
  if (!dashboard) return;

  updateChangeFolderModal({ loading: true });

  const cats = structuredClone($categoriesList.get().list);
  const newCategory = cats
    .filter((item) => item.type === 'folder')
    .find((item) => item.id === newCategoryId);

  if (!newCategory) {
    updateChangeFolderModal({ loading: false });
    toast.error(`The category you're trying to move this dashboard to doesn't exist.`);
    return;
  }

  const currIndex = cats.findIndex((i) => i.id === dId || i.children.some((c) => c.id === dId));
  // nothing to move - should be impossible
  if (currIndex === -1) {
    updateChangeFolderModal({ loading: false });
    toast.error(`The item you're trying to move doesn't exist.`);
    return;
  }

  const currentItem = cats[currIndex];
  let itemToMove: CategoryListItem | null = null;
  if (currentItem.type === 'item' && currentItem.depth === 0) {
    itemToMove = cats.splice(currIndex, 1)[0];
  } else if (currentItem.type === 'folder' || currentItem.depth > 0) {
    const childIndex = currentItem.children.findIndex((child) => child.id === dId);
    if (childIndex !== -1) itemToMove = currentItem.children.splice(childIndex, 1)[0];
  }

  if (itemToMove) itemToMove.depth = 1;
  itemToMove ??= createCategoryItemFromDash({
    augmentedDash: dashboard,
    type: 'item',
    depth: 1,
  });

  newCategory.children.push(itemToMove);
  const formattedChildren = formatChildren(newCategory);
  if (formattedChildren) newCategory.children = formattedChildren;

  try {
    await categoryDoc().set({ categories: cats }, { merge: true });

    toast.success(
      `Successfully moved dashboard: ${dashboard.name} to category ${newCategory.value}`,
    );
  } catch (err) {
    toast.error(
      `Error moving dashboard: ${dashboard.name} to category ${newCategory.value} ` + err.message,
    );
    console.error(`Error moving dashboard:`, err);
  }

  resetChangeFolderModal();
}
