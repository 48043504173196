import { $dialect, $userId } from '$stores/$user';
import { $derived, $effect, $observer, $store } from '@tw/snipestate';
import { $currentShopId, $shop } from '$stores/$shop';
import _db, { toArray, userDb } from 'utils/DB';
import { $ffStore } from 'feature-flag-system';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import type {
  WillyDashboardElement,
  WillyFieldElement,
  WillyWidgetElement,
} from 'components/Willy/types/willyTypes';
import { $shopDashboards } from './$shopDashboards';
import { PINNED_SECTION_ID, STANDARD_DASHBOARDS } from 'components/Willy/constants';
import { $globalDashboards } from './$globalDashboards';
import {
  getCustomViewDashboardCollectionRef,
  updateDashboardWidget,
} from '../../components/Willy/utils/willyUtils';
import { toast } from 'react-toastify';
import { WillyElementType } from 'components/Willy/types/willyTypes';

const $customViewsSnapshot = $observer(
  { loading: true, data: [] as WillyDashboardElement[] },
  (get, set) => {
    const shopId = get($currentShopId);
    const userId = get($userId);
    if (!shopId || !userId) return;

    return userDb(userId)
      .collection('willy')
      .doc(shopId)
      .collection('dashboards')
      .onSnapshot((querySnapshot) => {
        const data = toArray(querySnapshot).sort((a, b) => {
          if (a.name > b.name) return 1;
          if (a.name < b.name) return -1;
          return 0;
        });

        set({ loading: false, data });
      });
  },
);

const $snapshotDone = $store<number | null>(null);
export const $customViews = $observer<WillyDashboardElement[]>([], (get, set) => {
  const { loading, data: snapshotData } = get($customViewsSnapshot);
  const shopDashboards = get($shopDashboards);

  if (snapshotData.length && shopDashboards.length) {
    const globalDashboards = get($globalDashboards);

    const shopDashboardsMap = shopDashboards.reduce(
      (acc, x) => ((acc[x.id] = x), acc),
      {} as Record<string, WillyDashboardElement>,
    );

    const data: WillyDashboardElement[] = snapshotData
      .map((x) => {
        let isLocked = false;
        let isProviderLocked = false;

        if (x.isCustomViewOfDashboardId) {
          const dash = shopDashboardsMap[x.isCustomViewOfDashboardId];
          if (
            dash?.globalDashboardId &&
            x.globalDashboardId &&
            globalDashboards.some((g) => g.id === x.globalDashboardId)
          ) {
            const globalDashboard = globalDashboards.find((d) => d.id === dash.globalDashboardId);
            if (globalDashboard) {
              isLocked = globalDashboard.isLocked ?? false;
              isProviderLocked = globalDashboard.isProviderLocked ?? false;
            }
          }
        }

        return {
          ...x,
          isLocked,
          isProviderLocked,
          canEdit: true,
          canView: true,
          canDelete: true,
          isGlobal: false,
          type: 'dashboard' as WillyElementType,
          isCustomView: true,
        };
      })
      .filter(
        (d) =>
          !!d.name &&
          !d.isProviderLocked &&
          (d.isCustomViewOfDashboardId ?? '') in shopDashboardsMap,
      );

    set(data);
  }

  if ($snapshotDone.get() === null && !loading) {
    $snapshotDone.set(get().length);
  }
});

export const $customViewsIds = $derived((get) => new Set(get($customViews).map((ds) => ds.id)));
export const $customViewsLoading = $store(true);
$effect((unsub, get) => {
  const snapDone = get($snapshotDone);
  const dashIds = get($customViewsIds);
  if (snapDone !== null && snapDone === dashIds.size) {
    unsub();
    $customViewsLoading.set(false);
  }
});

export const createCustomViewFromDashboardId = async (dashboardId: string, name: string) => {
  const dashboard = await _db().collection('willy_dashboards').doc(dashboardId).get();
  if (!dashboard.exists) {
    toast.error('Report does not exist');
  }

  const dashboardData = { ...dashboard.data(), id: dashboardId } as WillyDashboardElement;
  const widgets = toArray(
    await _db().collection('willy_dashboards').doc(dashboardId).collection('widgets').get(),
  ) as WillyWidgetElement[];
  const fields = toArray(
    await _db().collection('willy_dashboards').doc(dashboardId).collection('fields').get(),
  ) as WillyFieldElement[];

  return createCustomViewFromDashboard(dashboardData, name, widgets, fields);
};

export const createCustomViewFromDashboard = async (
  dashboard: WillyDashboardElement,
  name: string,
  widgets: WillyWidgetElement[],
  fields: WillyFieldElement[],
) => {
  const { isCustomView, isGlobal, users, widgetIds, layout, ...rest } = dashboard;
  const parsedLayout: ReactGridLayout.Layout[] =
    typeof layout === 'string' || !layout ? JSON.parse(layout ?? '[]') : layout;
  const newLayout: ReactGridLayout.Layout[] = [
    {
      w: 12,
      h: 15,
      x: 0,
      y: 0,
      i: PINNED_SECTION_ID,
      minW: 2,
      maxW: 12,
      minH: 10,
      moved: false,
      static: false,
    },
    ...parsedLayout.map((l) => ({ ...l, y: l.y === 0 ? 1 : l.y })),
  ];

  const newDashboard: Partial<WillyDashboardElement> = {
    ...rest,
    name,
    widgetIds: [PINNED_SECTION_ID, ...widgetIds],
    layout: JSON.stringify(newLayout),
  };
  const res = await getCustomViewDashboardCollectionRef().add({
    ...newDashboard,
    isCustomViewOfDashboardId: dashboard.id,
    isCustomViewOfShopId: $currentShopId.get(),
  });

  const pinnedSection: WillyWidgetElement = {
    queryId: PINNED_SECTION_ID,
    title: 'Pinned',
    type: 'tile',
    metrics: [],
    withoutMainQuery: true,
    pinnedSection: true,
    stacked: false,
    incrementedStacked: false,
    wrapText: false,
    hidden: true,
    dialect: $dialect.get(),
  };

  widgets = [pinnedSection, ...widgets];

  await Promise.all(
    widgets.map(async (w) => {
      if (!w.queryId) return;
      await updateDashboardWidget(
        { ...newDashboard, id: res.id, isCustomView: true },
        w,
        w.queryId,
      );
    }),
  );
  await Promise.all(
    fields.map(
      async (f) =>
        await getCustomViewDashboardCollectionRef()
          .doc(res.id)
          .collection('fields')
          .doc(f.id)
          .set(f),
    ),
  );

  return res.id;
};

export const $combineShopAndCustomViewDashboards = $derived((get) => {
  const combinedList: WillyDashboardElement[] = [];
  const shopDashboards = get($shopDashboards);
  const customViews = get($customViews);

  shopDashboards.forEach((dashboard) => {
    combinedList.push(dashboard); // Add the shop dashboard first

    // Find and add custom views for this dashboard
    const relatedCustomViews = customViews.filter(
      (cv) => cv.isCustomViewOfDashboardId === dashboard.id,
    );
    combinedList.push(...relatedCustomViews); // Spread operator to add each custom view individually
  });

  return combinedList;
});

export const $shopCustomViewAndDefaultDashboards = $derived((get) => {
  const shopDashboards = get($shopDashboards);
  const customViews = get($customViews);

  return [
    ...shopDashboards,
    ...customViews,
    ...STANDARD_DASHBOARDS.map((d) => ({
      ...d,
      isStandardDashboard: true,
    })),
  ];
});

export const $allowedShopCustomViewAndDefaultDashboards = $derived((get) => {
  const shopDashboards = get($shopDashboards);
  const customViews = get($customViews);
  const ffComputer = get($ffStore);

  // must repeat for type inference
  // @TODO make this more DRY
  return [
    ...shopDashboards,
    ...customViews,
    ...STANDARD_DASHBOARDS.map((d) => ({
      ...d,
      isStandardDashboard: true,
    })).filter((d) => {
      if (d.dependsOnFFSystem) {
        const { shouldNotBeSeen } = ffComputer.getConfigById(d.dependsOnFFSystem as FeatureFlag);
        return !shouldNotBeSeen ? d : null;
      }

      return d;
    }),
  ];
});
