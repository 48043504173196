import { Avatar } from '@shopify/polaris';
import { Tooltip } from '@tw/ui-components';
import React, { useEffect, useMemo, useState } from 'react';
import axiosInstance from '../../utils/axiosInstance';
import { baseURL } from '../../config';
import { $store, useComputedValue, useStoreValue } from '@tw/snipestate';
import { $isAdminClaim } from '../../$stores/$user';
import { $currentShopId } from '../../$stores/$shop';
import copyToClipboard from '../../utils/copyToClipboard';

const $avatarImageCache = $store<Record<string, string | null>>({});

async function fetchAndSaveImage(userId?: string) {
  if (!userId) return;

  const existingUrl = $avatarImageCache.get()[userId];
  if (typeof existingUrl !== 'undefined') return existingUrl;

  try {
    const imageUrl = `${baseURL}/v2/media/profile-image/${userId}`;
    const response = await axiosInstance.get(imageUrl, {
      responseType: 'blob',
    });
    if (response.status === 200) {
      const url = URL.createObjectURL(response.data);
      $avatarImageCache.set((x) => ({ ...x, [userId]: url }));
    }
  } catch (err) {
    $avatarImageCache.set((x) => ({ ...x, [userId]: null }));
  }
}

type WillyUserAvatarProps = {
  avatarSource?: string;
  userName?: string;
  userId?: string;
  loading?: boolean;
  round?: boolean;
  shadow?: boolean;
  size?: 'extraSmall' | 'small' | 'medium' | 'large';
};

export const WillyUserAvatar: React.FC<WillyUserAvatarProps> = ({
  userName,
  userId,
  round,
  shadow,
  size = 'medium',
}) => {
  const [forceAvatar, setForceAvatar] = useState(false);
  const [email, setEmail] = useState('');
  const imageURL = useComputedValue($avatarImageCache, (c) => (!userId ? null : c[userId]));

  const isAdmin = useStoreValue($isAdminClaim);

  useEffect(() => {
    fetchAndSaveImage(userId);
    if (isAdmin && userId) {
      (async () => {
        const res = (
          await axiosInstance.get(
            `/v2/account-manager/shops/users/${$currentShopId.get()}?noFilter=true`,
          )
        ).data;
        setEmail(res.find((x) => x.id === userId)?.email);
      })();
    }
  }, [userId, isAdmin]);

  const acronymsName = useMemo(() => {
    return `${userName?.charAt(0).toUpperCase() || ''}`;
  }, [userName]);

  return (
    <div
      onClick={() => isAdmin && copyToClipboard(email, false)}
      className={`${round ? 'rounded-full' : 'rounded-[0.4rem] overflow-hidden'}`}
    >
      <Tooltip label={`${userName} ${isAdmin ? `(${email})` : ''}`} position="right">
        <div className={`${shadow ? 'shadow-md' : ''} rounded-full`}>
          {!imageURL || forceAvatar ? (
            <Avatar
              size={size}
              shape={round ? 'round' : 'square'}
              name={userName}
              initials={acronymsName}
            />
          ) : (
            <div className="rounded-full">
              <div className="shadow-md rounded-full">
                <span
                  aria-label={userName}
                  role="img"
                  className={`Polaris-Avatar Polaris-Avatar--size${
                    size.charAt(0).toUpperCase() + size.slice(1)
                  } Polaris-Avatar--imageHasLoaded Polaris-Avatar--shape${
                    round ? 'Round' : 'Square'
                  }`}
                >
                  <img
                    alt={userName}
                    src={imageURL}
                    onError={() => setForceAvatar(true)}
                    className="Polaris-Avatar__Image"
                    role="presentation"
                  />
                </span>
              </div>
            </div>
          )}
        </div>
      </Tooltip>
    </div>
  );
};
