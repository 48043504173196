import { Badge, Flex, Icon, Tooltip, Text } from '@tw/ui-components';
import { WillyDashImage } from '../WillyDashImage';
import { WillyBaseMainElement } from 'components/Willy/types/willyTypes';
import { useNavigate } from 'react-router-dom';
import LockedFeatureIndicator from 'components/library/LockedFeatures/LockedFeatureIndicator';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { ReactComponent as AddedToBoard } from 'components/Icons/checkmark.svg';
import { useStoreValue } from '@tw/snipestate';
import { $dashboardCredits } from '$stores/willy/$credits';

type TemplateCardProps = {
  dashboard: WillyBaseMainElement;
  closeCreateModal?: () => void;
  variant?: 'default' | 'short';
};
export const TemplateCard: React.FC<TemplateCardProps> = ({
  dashboard,
  closeCreateModal,
  variant = 'default',
}) => {
  const navigate = useNavigate();
  const { credits } = useStoreValue($dashboardCredits);
  return (
    <div
      onClick={async () => {
        navigate({
          pathname: `/templates/${dashboard.id}`,
          search: window.location.search,
        });
        closeCreateModal?.();
      }}
      className="relative cursor-pointer w-full h-full flex flex-col gap-2 border border-solid border-gray-200 rounded-lg shadow-sm bg-white hover:bg-[var(--mantine-color-gray-0)] hover:shadow-md transition duration-300 ease-in-out overflow-hidden group"
      key={dashboard.id}
    >
      <div className="z-[12] absolute top-[14px] left-[14px]">
        {dashboard.isLocked && (
          <LockedFeatureIndicator
            featureFlag={FeatureFlag.TEMPLATES_FF}
            iconOnly
            iconSize={26}
            iconBorderColor="#C3DDFF"
          />
        )}
      </div>
      <div className="z-[11] absolute top-[14px] right-[14px]">
        {!dashboard.isLocked && credits !== Infinity && (
          <Badge color={dashboard.credits ? 'one.0' : 'green.1'} uppercase={false}>
            <Text fw={500} size={'xs'} color={dashboard.credits ? 'one.6' : 'green.6'}>
              {dashboard.credits
                ? `${dashboard.credits} Credit${dashboard.credits > 1 ? 's' : ''}`
                : 'Free'}
            </Text>
          </Badge>
        )}
        {dashboard.isBeta && (
          <Badge color={'one.0'}>
            <Text fw={500} size={'xs'} color={'one.5'}>
              Beta
            </Text>
          </Badge>
        )}
      </div>

      <div className="relative overflow-hidden">
        <div
          className="hidden absolute top-0 left-0 z-10 w-full h-full group-hover:block"
          style={{ background: 'rgba(40, 40, 40, 0.40)' }}
        />
        <WillyDashImage dashboard={dashboard} />
      </div>
      <div className="flex flex-col gap-2 p-4 sm:px-8 flex-grow mb-2">
        <Flex gap="md" align="start" justify="space-between">
          <Text weight="500" lh="1.15">
            {dashboard.name}
          </Text>
          <div className={'flex justify-center items-center gap-3'}>
            <Flex gap="sm" align={'center'}>
              <Tooltip label={'Users'}>
                <Flex justify={'center'} align={'center'}>
                  <Icon size={14} color={'gray.4'} name={'user-single'} />
                  <Text size="sm" ml={2}>
                    {dashboard.installCount}
                  </Text>
                </Flex>
              </Tooltip>
            </Flex>
            {dashboard.existsInStore && (
              <Tooltip
                label={`Added To Your ${dashboard.type === 'dashboard' ? 'Reports' : 'Workflows'} `}
              >
                <div className={'ml-3'}>
                  <AddedToBoard width="16" />
                </div>
              </Tooltip>
            )}
          </div>
        </Flex>
        <Flex gap="sm" align="center" justify="space-between">
          <Text size="xs">by {dashboard.users?.[0] || 'Triple Whale'}</Text>
        </Flex>
        {variant !== 'short' && (
          <div className="flex flex-col justify-between w-full h-full gap-4">
            <p className="line-clamp-4 flex-1 mt-4">
              <Text weight="500" size="sm">
                {dashboard.description}
              </Text>
            </p>
          </div>
        )}
      </div>
    </div>
  );
};
