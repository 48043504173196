import { $userDisplayName } from '$stores/$user';
import { useStoreValue } from '@tw/snipestate';
import { ActionIcon, colors, NumRange, Text } from '@tw/ui-components';
import { useEffect, useMemo, useState } from 'react';
import { WillyPrompt } from './types/willyTypes';
import { $favoritePrompts } from '$stores/willy/$favoritePrompts';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';
import { AlanLoaderLite } from 'components/AlanLoader';
import { PromptCategorySelect } from './PromptCategorySelect';
import { PromptTile } from './PromptTile';
import { useAppSelector } from 'reducers/RootType';
import { getPaginatedPrompts } from './utils/willyUtils';
import { services } from '@tw/types/module/services';
import { $shopWithSensory } from '$stores/$shopWithSensory';
import { useSamplePrompts } from './useSamplePrompts';

type WillyChatLPProps = {
  onSuggestionClick: (suggestion?: string) => void;
};

export const WillyChatLP: React.FC<WillyChatLPProps> = ({ onSuggestionClick }) => {
  const userName = useStoreValue($userDisplayName);
  const isSmall = useIsSmall();
  const favoritePrompts = useStoreValue($favoritePrompts);
  const [currentDisplayPage, setCurrentDisplayPage] = useState(0);
  const {
    promptsToShow,
    promptTopics,
    loadNextPage,
    totalItemCount,
    activePromptCategory,
    setActivePromptCategory,
    loadingPrompts,
  } = useSamplePrompts();

  const itemsPerDisplayPage = isSmall ? 48 : 6;

  const pages = Math.ceil(totalItemCount / itemsPerDisplayPage);

  const shouldLoadNextPageAhead =
    (currentDisplayPage + 2) * itemsPerDisplayPage >= promptsToShow.length;
  const nextPageIsLoaded = (currentDisplayPage + 1) * itemsPerDisplayPage <= promptsToShow.length;

  const onNextPage = () => {
    if (shouldLoadNextPageAhead) {
      // If the current page reaches the end of the available data, load more data from the server
      loadNextPage();
    }
    setCurrentDisplayPage((prev) => prev + 1);
  };

  const displayedPrompts = promptsToShow.slice(
    currentDisplayPage * itemsPerDisplayPage,
    (currentDisplayPage + 1) * itemsPerDisplayPage,
  );

  return (
    <div className="flex flex-col md:pt-20 items-center h-full justify-end">
      <Text fw={600} fz={isSmall ? 18 : 26} pb={28}>
        Hi {userName}! How can I help you today?
      </Text>
      <div className="pb-[25px] md:pb-[40px]">
        <PromptCategorySelect
          activePromptCategory={activePromptCategory}
          setActivePromptCategory={(cat) => {
            setActivePromptCategory(cat);
            setCurrentDisplayPage(0);
          }}
          mainPage
          promptTopics={promptTopics}
        />
      </div>
      {promptsToShow.length === 0 && !loadingPrompts && (
        <div className="flex justify-center">
          <Text color="gray.4">Nothing here yet</Text>
        </div>
      )}

      <div className="max-w-full overflow-hidden">
        <div className="flex items-stretch auto-rows-min h-full md:grid grid-cols-1 md:grid-cols-3 gap-4 pb-[30px] p-4 @3xl:!px-40 overflow-scroll md:overflow-hidden no-scrollbar">
          {displayedPrompts.map((prompt, i) => {
            return (
              <span
                key={`prompt.prompt-${i}`}
                className="w-[85vw] min-w-[85vw] md:w-auto md:min-w-[auto] h-full"
              >
                <PromptTile
                  prompt={prompt}
                  onSuggestionClick={(prompt) => onSuggestionClick(prompt.prompt)}
                  promptTopics={promptTopics}
                />
              </span>
            );
          })}
        </div>
      </div>
      {!isSmall && pages > 1 && (
        <div className="flex gap-4 items-center">
          <span className={`${currentDisplayPage === 0 ? 'opacity-0' : 'opacity-100'}`}>
            <ActionIcon
              variant="activator"
              icon="chevron-left-minor"
              iconSize={9}
              color="gray.4"
              size="sm"
              disabled={currentDisplayPage === 0}
              onClick={() => {
                setCurrentDisplayPage((prev) => prev - 1);
              }}
            />
          </span>
          <Text fw={500} fz={14}>
            <span className="flex gap-[1px]">
              <span>{currentDisplayPage + 1}</span>
              <span>/</span>
              <span>{pages}</span>
            </span>
          </Text>
          <span className={`${currentDisplayPage === pages ? 'opacity-0' : 'opacity-100'}`}>
            <ActionIcon
              variant="activator"
              icon="chevron-right-minor"
              iconSize={9}
              color="gray.4"
              size="sm"
              disabled={!nextPageIsLoaded}
              onClick={() => {
                onNextPage();
              }}
            />
          </span>
        </div>
      )}
    </div>
  );
};
