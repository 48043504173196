import { Badge, Text } from '@tw/ui-components';

export const PromptTile = ({ prompt, onSuggestionClick, promptTopics }) => {
  // const promptTopics = useStoreValue($samplePromptTopics);
  const promptTopic =
    promptTopics.find((topic) => topic.topic === prompt?.category) ??
    promptTopics.find((topic) => topic.topic === 'Yours');
  const labelColor = promptTopic?.labelColor || 'yellow.1';
  const badgeColor = promptTopic?.badgeColor || 'yellow.1';
  return (
    <div
      className="border border-solid border-gray-200 rounded bg-white p-6 flex flex-col justify-between gap-4 cursor-pointer w-full h-full"
      onClick={() => onSuggestionClick(prompt)}
    >
      <Text lineClamp={2} fz={13} fw={500}>
        {prompt.prompt}
      </Text>
      <Badge radius="sm" uppercase={false} color={labelColor}>
        <Text fz={11} fw={500} color={badgeColor}>
          {prompt.subCategory}
        </Text>
      </Badge>
    </div>
  );
};
